import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { VIEW_ONLY_RESTRICTED_LIST,VIEW_ONLY_RESTRICTED_UNIT_LIST } from '../../types/index'


const initialState = {
    ViewOnlyRestrictedList:{
        viewOnlyRestList:'',
        viewOnlyRestUnitList:'',
        viewOnlyRestCompDetails:'',
        access_info:''
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case VIEW_ONLY_RESTRICTED_LIST: {
            return {
                ...state,
                ViewOnlyRestrictedList: {
                    ...state.ViewOnlyRestrictedList,
                    viewOnlyRestList:payload[1].ul_users
                },
            };
        }

        case VIEW_ONLY_RESTRICTED_UNIT_LIST: {
            return {
                ...state,
                ViewOnlyRestrictedList: {
                    ...state.ViewOnlyRestrictedList,
                    viewOnlyRestUnitList:payload[1].unt_details,
                    viewOnlyRestCompDetails:payload[1].unt_comp_details,
                    access_info:payload[1].access_info
                },
            };
        }
      
        default:
            return state;
    }
}