import { LOGINTRACEREPORTFILTER, LOGIN_TRACE_REPORT_SHOW,LOGIN_TRACE_REPORT_EXPORT,LOGIN_TRIAL_REPORT_EXPORT_RESET} from './../../types/index'
const initialState = {
    logintracereport: {
        logintracefilter: [],
        logintraceshow: [],
        logintraceexport:[]
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGINTRACEREPORTFILTER:
            return {
                ...state,
                logintracereport: {
                    ...state.logintracereport,
                    logintracefilter: payload,
                },
            };
        case LOGIN_TRACE_REPORT_SHOW:
            return {
                ...state,
                logintracereport: {
                    ...state.logintracereport,
                    logintraceshow: payload,
                },
            };
            case LOGIN_TRACE_REPORT_EXPORT:
                return {
                    ...state,
                    logintracereport: {
                        ...state.logintracereport,
                        logintraceexport: payload,
                    },
                };
                case LOGIN_TRIAL_REPORT_EXPORT_RESET:
                    return {
                        ...state,
                        logintracereport: {
                            ...state.logintracereport,
                            logintraceexport: [],
                        },
                    };
        default:
            return state;
    }
}