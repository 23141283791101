import React from 'react'
import moment from 'moment';
import { Switch, Tag } from 'antd';
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Space, Button, Table, Tooltip, Modal, Descriptions, Empty } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ArrowRightOutlined, CheckOutlined, CloseOutlined, CloseCircleOutlined, ArrowLeftOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getComparativechart, getCommonData, getTimeZoneData } from '../../Store/Action/Dashboard/ComparativeTrendChart';
import { toast } from 'react-toastify';

// import "core-js/stable";
// import "regenerator-runtime/runtime";

const mapStateToProps = (state) => ({
    commonData: state.ComparativeTrend.comparativeTrendChart.Data,
    data: state.ComparativeTrend.comparativeTrendChart.chartData,
    time: state.ComparativeTrend.comparativeTrendChart.time,
    timeZoneData: state.ComparativeTrend.comparativeTrendChart.timeZoneData,

})

const ComparativeTrend = ({
    getFilterValue,
    getComparativechart,
    getCommonData,
    data,
    commonData,
    time,
    getTimeZoneData,
    timeZoneData
    // tableData
}) => {
    console.log(data, 'data12345');
    const _ = require("lodash");
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [dataValue, setDataValue] = useState([])
    const [notcomplieddata, setNotcomplieddata] = useState([])
    const [delayedData, setDelayedData] = useState([])
    const [toggleButton, setToggleButton] = useState('Month')
    const [taskDistributionTable, setTaskDistributionTable] = useState([])
    const [filterData, setFilterData] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const { Panel } = Collapse;
    const [chartId, setChartId] = useState()
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],

    })
    const [type, setType] = useState('')
    const [legalEntityArray, setLegalEntityArray] = useState([])


    const [open, setOpen] = useState(false)
    const [table, setTable] = useState(false)
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [fieldType, setFieldType] = useState("")
    console.log(fieldType, 'fieldType');

    const [unit, setUnit] = useState([])
    const chart = useRef(null);
    const { Option } = Select;
    const [year, setYear] = useState(moment().year())
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const [chartType, setChartType] = useState("complied")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const [entityData, setEntityData] = useState([])
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    console.log(commonData, 'commonData');
    const location = useLocation();
    const [paramid, setParamId] = useState("")
    const [generatedOn, setGeneratedOn] = useState(false)
    const [filterDataNames, setFilterDataNames] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const [go, setGo] = useState(false)
    const [domainName, setDomainName] = useState()
    const { OptGroup } = Select;
    const [domainListData, setDomainListData] = useState([])
    const [divisionListData, setDivisionListData] = useState([])
    const [categoryListData, setCategoryListData] = useState([])
    const [unitListData, setUnitListData] = useState([])
    const [bgIds, setBgIds] = useState([])
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [checkBox, setCheckBox] = useState(true)
    const [countryDomain, setCountryDomain] = useState([])
    const [dIds, setDIds] = useState([])
    const country_info = sessionParsedValue.country_info


    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            the_arr.pop();
            let a = the_arr.join('/')
            setParamId(a)
        }
    }, []);
    console.log(data, 'datadatadata');
    console.log(go, 'goooooo');
    // useEffect(() => {
    //     if (tableData) {
    //         setTaskDistributionTable(tableData.r_drill_down_data)
    //     }

    // }, [tableData])
    // console.log(tableData, 'taskDistributionTable');

    useEffect(() => {
        if (commonData) {
            let unique = []
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                unique = _.uniqBy(commonData && commonData.bg_groups, function (e) {
                    return e.bg_id;
                });
            }
            setfield({
                ...field,
                domain: commonData && commonData.d_info,
                legalEntity: commonData && commonData.le_did_infos,
                country: commonData && commonData.countries,
                business: unique
            })
            let uniqueDiv = []
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                uniqueDiv = _.uniqBy(commonData && commonData.div_infos, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)
            setCategory(commonData && commonData.cat_info)
            setUnit(commonData && commonData.chart_units)
        }

    }, [isAuth, entityid, commonData])

    console.log(field, 'fieldfield');
    console.log(commonData, ' commonDatacommonDatacommonData');

    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])

    console.log(data, 'data');

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {
            if (data && data.length > 0) {
                let temp = []
                let temp2 = []
                let temp3 = []
                console.log(data,'data6767');
                for (let i in data) {
                    temp.push({
                        "month": data && data.length > 0 && data[i].chart_year,
                        "value": data && data.length > 0 && data[i].complied_compliances_count,
                        "total": data && data.length > 0 && data[i].total_compliances,
                        "valuecount": data && data.length > 0 && data[i].complied_compliances_count
                    })
                    temp2.push({
                        "month": data && data.length > 0 && data[i].chart_year,
                        "value": data && data.length > 0 && data[i].not_complied_count,
                        "total": data && data.length > 0 && data[i].total_compliances,
                        "valuecount": data && data.length > 0 && data[i].not_complied_count

                    })
                    temp3.push({
                        "month": data && data.length > 0 && data[i].chart_year,
                        "value": data && data.length > 0 && data[i].delayed_count,
                        "total": data && data.length > 0 && data[i].total_compliances,
                        "valuecount": data && data.length > 0 && data[i].delayed_count
                    })
                }
                // sortByMonth(temp);



                // function sortByMonth(arr) {
                //     console.log(arr, 'snehaaaaaaaa');
                //     var months = ["Jan 2022", "Feb 2022", "Mar 2022", "Apr 2022", "May 2022", "Jun 2022",
                //         "Jul 2022", "Aug 2022", "Sep 2022", "Aug 2022", "Nov 2022", "Dec 2022"];
                //     let data = arr.sort(function (a, b) {
                //         return months.indexOf(a.month
                //         )
                //             - months.indexOf(b.month
                //             );
                //     });
                //     console.log(data,'data5555');
                // }
                console.log(temp, 'temp12345');
                setDataValue(temp)
                setNotcomplieddata(temp2)
                setDelayedData(temp3)
            }
        }

    }, [data, localStorage.getItem('SelectedEntity')])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            if (data && data.length > 0) {
                let temparr = []
                let temparr2 = []
                let temp = []
                let temp2 = []
                let temp3 = []
                let notcompliedTemp = []
                let delayedTemp = []
                let totalTemp = []
                for (let i in data) {

                    let uniqueObjArray = [...new Map(data && data.length > 0 && data.map((item) => [item["chart_year"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item.chart_year
                    })
                    temp = uniqueIds
                    console.log(uniqueIds, 'uniqueIds');

                    // temp.push({
                    //     "month": data && data.length > 0 && data[i].chart_year,
                    //     "value": data && data.length > 0 && data[i].complied_compliances_count,
                    //     "total": data && data.length > 0 && data[i].total_compliances,
                    //     "values": data && data.length > 0 && data[i].complied_compliances_count
                    // })
                    // temp2.push({
                    //     "month": data && data.length > 0 && data[i].chart_year,
                    //     "value": data && data.length > 0 && data[i].not_complied_count,
                    //     "total": data && data.length > 0 && data[i].total_compliances,
                    //     "values": data && data.length > 0 && data[i].not_complied_count

                    // })
                    // temp3.push({
                    //     "month": data && data.length > 0 && data[i].chart_year,
                    //     "value": data && data.length > 0 && data[i].delayed_count,
                    //     "total": data && data.length > 0 && data[i].total_compliances,
                    //     "values": data && data.length > 0 && data[i].delayed_count
                    // })
                }
                for (let k in temp) {
                    if (data[k].chart_year == temp[k]) {
                        let filterValue = _.filter(data, { chart_year: temp[k] })
                        console.log(filterValue, 'aishhhhhhhhhh');
                        let compliedCount = filterValue.map((item, i) => {
                            return item.complied_compliances_count
                        })
                        let compliedCounts = compliedCount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        let notCount = filterValue.map((item, i) => {
                            return item.not_complied_count
                        })
                        let notcounts = notCount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        let deyaledcount = filterValue.map((item, i) => {
                            return item.delayed_count
                        })
                        let deyaledcounts = deyaledcount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        let totalcount = filterValue.map((item, i) => {
                            return item.total_compliances
                        })
                        let totalcounts = totalcount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        temp2.push(compliedCounts)
                        notcompliedTemp.push(notcounts)
                        delayedTemp.push(deyaledcounts)
                        totalTemp.push(totalcounts)


                    }
                }
                for (let v in temp) {
                    temparr.push({
                        "month": data && data.length > 0 && data[v].chart_year,
                        "value": temp2 && temp2.length > 0 && temp2[v],
                        "total": totalTemp && totalTemp.length > 0 && totalTemp[v],
                        "valuecount": temp2 && temp2.length > 0 && temp2[v],

                    })
                    temparr2.push({
                        "month": data && data.length > 0 && data[v].chart_year,
                        "value": notcompliedTemp && notcompliedTemp.length > 0 && notcompliedTemp[v],
                        "total": totalTemp && totalTemp.length > 0 && totalTemp[v],
                        "valuecount": notcompliedTemp && notcompliedTemp.length > 0 && notcompliedTemp[v],

                    })
                    temp3.push({
                        "month": data && data.length > 0 && data[v].chart_year,
                        "value": delayedTemp && delayedTemp.length > 0 && delayedTemp[v],
                        "total": totalTemp && totalTemp.length > 0 && totalTemp[v],
                        "valuecount": delayedTemp && delayedTemp.length > 0 && delayedTemp[v],

                    })
                }
                sortByMonth(temparr);



                function sortByMonth(arr) {
                    console.log(arr, 'arrrrrrrrr');
                    var months = ["Jan 2022", "Feb 2022", "Mar 2022", "Apr 2022", "May 2022", "Jun 2022",
                        "Jul 2022", "Aug 2022", "Sep 2022", "Aug 2022", "Nov 2022", "Dec 2022"];
                    arr.sort(function (a, b) {
                        return months.indexOf(a.month
                        )
                            - months.indexOf(b.month
                            );
                    });
                }
                console.log(temparr, 'complied');
                console.log(temparr2, 'notcomplied');
                console.log(temp3, 'delayedcomplied');
                console.log(totalTemp, 'totalcomplied');
                console.log(temp, 'temp');
                setDataValue(temparr)
                setNotcomplieddata(temparr2)
                setDelayedData(temp3)

                // setDataValue(temp)
                // setNotcomplieddata(temp2)
                // setDelayedData(temp3)
            }
        }


    }, [data, localStorage.getItem('SelectedEntity')])




    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityid])

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            setDomainName(domainNameArray)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    useEffect(() => {
        if (type === 'Group')
            setGroupCheckBox(checkBox)
    }, [type, checkBox])

    useEffect(() => {
        if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request":
                        ["GetComparativeChart",

                            {
                                "c_ids": [
                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                ],
                                "d_ids": dIds,
                                "filter_type": "Group",
                                "filter_ids": [
                                    1
                                ],
                                "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
                                "country_domains": countryDomain,
                                "year": `${year}`,
                                "chart_types": "Month"
                            }
                        ]
                }
            ]
            if (paramid != "") {
                getComparativechart({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid, commonData, entityData, year, countryDomain, dIds])


    const dataSource = [
        {
            key: '1',
            name: 'Rule 18',
            task: 'Trail Task117',
            description: 'test',
            p_cons: "Shall be punishable with imprisonment for a term which shall not be less than six months but which may extend to three years and with fine which shall not be less than fifty thousand rupees but which may extend to two lakh rupees - Section 63",
            address: '10 Downing Street',
            frequency: 'periodical',
            task_status: 'opted',
            trigger_before_days: 10,
            task: 'Trail Task119',
            summary: "Repeats every 1 Month(s) "
        },
        {
            key: '2',
            name: 'Rule 3',
            description: 'Online application can be submitted for one musical license covering all branches of pan India by paying prescribed fees. Music license is applicable for music played for public in TV, radio, live performances, DJ by internet or even copied in CD, DVD or pen drives/hard disc',
            address: '10 Downing Street',
            frequency: 'Review',
            task_status: 'Not opted',
            task: 'Trail Task',
            summary: "Repeats every 6 Month(s) ",
            trigger_before_days: 20,
            p_cons: "Section 23 - Imprisonment up to 3 months or with fine up to Rs.1000/- or with both. If the contravention is continued fine may extend to Rs.100/- for each day",
        },
        {
            key: '3',
            name: 'Rule 12',
            description: 'test',
            frequency: 'OnOccurence',
            address: '10 Downing Street',
            p_cons: "test",
            task: 'Trail Task55',
            task_status: 'opted',
            trigger_before_days: 25,
            summary: "Repeats every 1 Month(s) "
        },
    ];
    const onClose = () => {
        setOpen(false);
    };
    const filterChange = () => {
        setDataValue([])
        setNotcomplieddata([])
        setGo(true)
        setGeneratedOn(false)
        setDelayedData([])
        if (groupCheckBox === true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComparativeChart",
                        {
                            "c_ids": [
                                filterData && filterData.country ? Number(filterData && filterData.country) : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": filterData && filterData.domain ? filterData.domain : dIds,
                            "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                            "filter_ids": [
                                (fieldType == "LegalEntity" && type === '') ? filterData.legalentity :
                                    (fieldType == 'Division' && type === '') ? Number(filterData.division) :
                                        (fieldType == 'Category' && type === '') ? Number(filterData.category) :
                                            (fieldType == 'Unit' && type === '') ? Number(filterData.unit) :
                                                (fieldType == 'BusinessGroup' && type === '') ? Number(filterData.business) :
                                                    (fieldType != "" && type === 'Group') ? 1 :
                                                        1
                            ],

                            "le_ids":
                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                            ,
                            "country_domains": countryDomain,
                            "year": `${year}`,
                            "chart_types": "Month"
                        }
                    ]
                }
            ]

            getComparativechart({
                payload: payload,
                paramid: paramid
            })
        }
        else {
            toast.error('Please Choose Groups')

        }
        setOpen(false);
    }
    const columns = [
        {
            title: 'Statutory',
            dataIndex: 's_prov',
            key: 's_prov',
        },
        {
            title: 'Compliance Task',
            dataIndex: 'comp_name',
            key: 'comp_name',
        },
        {
            title: 'Criticality',
            dataIndex: 'criticality',
            key: 'criticality',
        },
        {
            title: 'Compliance Description',
            dataIndex: 'descp',
            key: 'descp',
        },
        {
            title: 'Panel Conseqeunces',
            dataIndex: 'p_cons',
            key: 'p_cons',
        },
        {
            title: 'Compliance Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
        },
        {
            title: 'Repeats',
            dataIndex: 'summary',
            key: 'summary',
        },
        {
            title: 'Trigger Before',
            dataIndex: 'statu_dates',
            key: 'statu_dates',
            render: (record) => {
                console.log(record && record.length > 0 && record[0].trigger_before_days, 'record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days');
                return <p>{record && record.length > 0 && record[0].trigger_before_days
                }</p>
            }
        },
    ];

    useEffect(() => {
        if (dataValue && dataValue.length > 0) {
            console.log('test');
            if (table == false) {
                if (chartType == 'complied') {
                    // Themes begin
                    am4core.useTheme(am4themes_animated);
                    // var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
                    // Create a container
                    var container = am4core.create("container", am4core.Container);
                    container.width = am4core.percent(100);
                    container.height = am4core.percent(100);
                    container.layout = "vertical";
                    container.background.fill = am4core.color("#000");
                    container.background.fillOpacity = 0.1;
                    container.background.stroke = am4core.color("#000");
                    container.background.strokeOpacity = 0.2;
                    container.background.strokeWidth = 2;


                    function addChart(data) {
                        var chart = container.createChild(am4charts.XYChart3D);
                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        chart.exporting.menu.align = "right";
                        chart.exporting.menu.verticalAlign = "top";
                        // Add data
                        chart.data = data
                        chart.height = 550

                        // Create axes
                        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                        dateAxis.renderer.minGridDistance = 2
                        dateAxis.title.text = "Month-Year";
                        // Create value axis
                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        valueAxis.calculateTotals = true;
                        valueAxis.min = 0;
                        valueAxis.max = 100;
                        valueAxis.renderer.minGridDistance = 10;
                        valueAxis.title.text = "Compliance(%)";
                        // Create series
                        var lineSeries = chart.series.push(new am4charts.LineSeries());
                        lineSeries.dataFields.valueY = "value";
                        lineSeries.dataFields.dateX = "month";

                        lineSeries.name = ((fieldType == "LegalEntity" && go == true) ? filterDataNames.legalentity : (
                            (fieldType == "Division" && go == true) ? filterDataNames.division : (
                                (fieldType == "Category" && go == true) ? filterDataNames.category : (
                                    (fieldType == "Unit" && go == true) ? filterDataNames.unit : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name
                                ))
                        ))
                        lineSeries.dataFields.value = "totalPercent";
                        lineSeries.calculatePercent = true;
                        lineSeries.calculateTotals = true;
                        lineSeries.tooltipText = "{valueX}"
                        lineSeries.strokeWidth = 2;
                        lineSeries.dataFields.valueXShow = "totalPercent";
                        lineSeries.dataItems.template.locations.categoryY = 0.5;

                        // Add simple bullet
                        var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
                        bullet.circle.stroke = am4core.color("#fff");
                        bullet.circle.strokeWidth = 2;
                        bullet.tooltipText = "{name}: {valueY.formatNumber('#.00')}[/]% : ({valuecount} out of {total}[/]}";
                        chart.legend = new am4charts.Legend();

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        var title = chart.titles.create();
                        title.text = generatedOn == true ? `Generated on : ${timeZoneData.tz_name} ${timeZone}` : `Generated on : ${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'
                        title.marginBottom = '2%'

                        // var title1 = chart.titles.create();
                        // title1.text = 'Complied'
                        // title1.disabled = false;
                        // title1.align = 'center'
                        // title1.marginTop = '2%'
                        // title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        var title1 = chart.titles.create();
                        title1.text =
                            ((fieldType == "LegalEntity" && go == true && type === '') ? `Complied of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                (fieldType == "Division" && go == true && type === '') ? `Complied of Division [bold]${filterDataNames.division}[/] (${year})` : (
                                    (fieldType == "Category" && go == true && type === '') ? `Complied of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                        (fieldType == "BusinessGroup" && go == true && type === '') ? `Complied of Business Group [bold]${filterDataNames.business}[/] (${year})` : (
                                            (fieldType == "Unit" && go == true && type === '') ? `Complied of Unit [bold]${filterDataNames.unit}[/] (${year})` : `Complied (${year})`
                                        )))
                            ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'

                    }

                    addChart(dataValue);

                }
                else if (chartType == "notComplied") {
                    // Themes begin
                    am4core.useTheme(am4themes_animated);
                    // var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
                    // Create a container
                    var container = am4core.create("container", am4core.Container);
                    container.width = am4core.percent(100);
                    container.height = am4core.percent(100);
                    container.layout = "vertical";
                    container.background.fill = am4core.color("#000");
                    container.background.fillOpacity = 0.1;
                    container.background.stroke = am4core.color("#000");
                    container.background.strokeOpacity = 0.2;
                    container.background.strokeWidth = 2;


                    function addChart(data) {
                        var chart = container.createChild(am4charts.XYChart3D);
                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        chart.exporting.menu.align = "right";
                        chart.exporting.menu.verticalAlign = "top";
                        // Add data
                        chart.data = data
                        // Create axes
                        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                        dateAxis.renderer.minGridDistance = 1
                        dateAxis.title.text = "Month-Year";
                        // Create value axis
                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        valueAxis.calculateTotals = true;
                        valueAxis.min = 0;
                        valueAxis.max = 100;
                        valueAxis.renderer.minGridDistance = 10;
                        valueAxis.title.text = "Compliance(%)";
                        // Create series
                        var lineSeries = chart.series.push(new am4charts.LineSeries());
                        lineSeries.dataFields.valueY = "value";
                        lineSeries.dataFields.dateX = "month";

                        lineSeries.name = ((fieldType == "LegalEntity" && go == true) ? filterDataNames.legalentity : (
                            (fieldType == "Division" && go == true) ? filterDataNames.division : (
                                (fieldType == "Category" && go == true) ? filterDataNames.category : (
                                    (fieldType == "Unit" && go == true) ? filterDataNames.unit : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name
                                ))
                        ))
                        lineSeries.dataFields.value = "totalPercent";
                        lineSeries.calculatePercent = true;
                        lineSeries.calculateTotals = true;
                        lineSeries.tooltipText = "{valueX}"
                        lineSeries.strokeWidth = 2;
                        lineSeries.dataFields.valueXShow = "totalPercent";
                        // Add simple bullet
                        var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
                        bullet.circle.stroke = am4core.color("#fff");
                        bullet.circle.strokeWidth = 2;
                        bullet.tooltipText = "{name}: {valueY.formatNumber('#.00')}[/]% : ({valuecount} out of {total}[/]}";
                        chart.legend = new am4charts.Legend();

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        var title = chart.titles.create();
                        title.text = generatedOn === true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)}`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'
                        title.marginBottom = '2%'

                        // var title1 = chart.titles.create();
                        // title1.text = 'Not Complied'
                        // title1.disabled = false;
                        // title1.align = 'center'
                        // title1.marginTop = '2%'
                        // title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        var title1 = chart.titles.create();
                        title1.text =
                            ((fieldType == "LegalEntity" && go == true && type === '') ? `Not Complied of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                (fieldType == "Division" && go == true && type === '') ? `Not Complied of Division [bold]${filterDataNames.division}[/] (${year})` : (
                                    (fieldType == "Category" && go == true && type === '') ? `Not Complied of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                        (fieldType == "BusinessGroup" && go == true && type === '') ? `Not Complied of Business Group [bold]${filterDataNames.business}[/] (${year})` : (
                                            (fieldType == "Unit" && go == true && type === '') ? `Not Complied of Unit [bold]${filterDataNames.unit}[/] (${year})` : `Not Complied (${year})`
                                        )))
                            ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'
                    }
                    addChart(notcomplieddata);
                }
                else if (chartType == "delayedComplied") {

                    am4core.useTheme(am4themes_animated);
                    // var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
                    // Create a container
                    var container = am4core.create("container", am4core.Container);
                    container.width = am4core.percent(100);
                    container.height = am4core.percent(100);
                    container.layout = "vertical";
                    container.background.fill = am4core.color("#000");
                    container.background.fillOpacity = 0.1;
                    container.background.stroke = am4core.color("#000");
                    container.background.strokeOpacity = 0.2;
                    container.background.strokeWidth = 2;


                    function addChart(data) {
                        var chart = container.createChild(am4charts.XYChart3D);
                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        chart.exporting.menu.align = "right";
                        chart.exporting.menu.verticalAlign = "top";
                        // Add data
                        chart.data = data
                        // Create axes
                        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                        dateAxis.renderer.minGridDistance = 1
                        dateAxis.title.text = "Month-Year";
                        // Create value axis
                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        valueAxis.calculateTotals = true;
                        // valueAxis.min = 0;
                        // valueAxis.max = 100;
                        // valueAxis.renderer.minGridDistance = 10;
                        valueAxis.min = 0;
                        valueAxis.max = 100;
                        valueAxis.renderer.minGridDistance = 10;
                        valueAxis.renderer.cellStartLocation = 0.2;
                        valueAxis.renderer.cellEndLocation = 0.6;
                        valueAxis.title.text = "Compliance(%)";
                        // Create series
                        var lineSeries = chart.series.push(new am4charts.LineSeries());
                        lineSeries.dataFields.valueY = "value";
                        lineSeries.dataFields.dateX = "month";
                        lineSeries.name = ((fieldType == "LegalEntity" && go == true) ? filterDataNames.legalentity : (
                            (fieldType == "Division" && go == true) ? filterDataNames.division : (
                                (fieldType == "Category" && go == true) ? filterDataNames.category : (
                                    (fieldType == "Unit" && go == true) ? filterDataNames.unit : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name
                                ))
                        ))
                        lineSeries.dataFields.value = "totalPercent";
                        lineSeries.calculatePercent = true;
                        lineSeries.calculateTotals = true;
                        lineSeries.tooltipText = "{valueX}"
                        lineSeries.strokeWidth = 2;
                        lineSeries.dataFields.valueXShow = "totalPercent";
                        // Add simple bullet
                        var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
                        bullet.circle.stroke = am4core.color("#fff");
                        bullet.circle.strokeWidth = 2;
                        bullet.tooltipText = "{name}: {valueY.formatNumber('#.00')}[/]% : ({valuecount} out of {total}[/]}";
                        chart.legend = new am4charts.Legend();

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        var title = chart.titles.create();
                        title.text = timeZoneData && timeZoneData.length > 0 ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'
                        title.marginBottom = '2%'

                        // var title1 = chart.titles.create();
                        // title1.text = 'Delayed Compliance'
                        // title1.disabled = false;
                        // title1.align = 'center'
                        // title1.marginTop = '2%'
                        // title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        var title1 = chart.titles.create();
                        title1.text =
                        ((fieldType == "LegalEntity" && go == true && type === '') ? `Delayed Compliance of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                            (fieldType == "Division" && go == true && type === '') ? `Delayed Compliance of Division [bold]${filterDataNames.division}[/] (${year})` : (
                                (fieldType == "Category" && go == true && type === '') ? `Delayed Compliance of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                    (fieldType == "BusinessGroup" && go == true && type === '') ? `Delayed Compliance of Business Group [bold]${filterDataNames.business}[/] (${year})` : (
                                        (fieldType == "Unit" && go == true && type === '') ? `Delayed Compliance Unit [bold]${filterDataNames.unit}[/] (${year})` : `Delayed Compliance (${year})`
                                    )))
                        ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'
                    }
                    addChart(delayedData);
                }
            }
        }
    }, [dataValue, table, timeZone, chartType, timeZoneData, localStorage.getItem('chart')])

    //Use effects for filter

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
            let arrunit = [];
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-domain');
                if (country_info && country_info[0].c_id == subunitlist.c_id) {
                    entityArrayunit = {

                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'is_active': subunitlist.is_active,
                        'month_from': subunitlist.month_from,
                        'month_to': subunitlist.month_to,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setDomainListData(countrylisttempdataunit)
        }
    }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if (commonData) {
            let temp = []
            for (let i in commonData.d_info) {
                temp.push(commonData.d_info[i].d_id)
            }
            setFilterData({
                ...filterData,
                country: `${country_info[0].c_id}`,
                domain: temp
            })
        }
    }, [commonData])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            let arrunit = [];
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-domain');
                if (country_info && country_info[0].c_id == subunitlist.c_id) {
                    entityArrayunit = {

                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'is_active': subunitlist.is_active,
                        'month_from': subunitlist.month_from,
                        'month_to': subunitlist.month_to,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setDomainListData(countrylisttempdataunit)
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            console.log('hiii');
            if ((commonData && commonData.bg_groups && commonData.bg_groups.length === 0) && (legalEntityArray && legalEntityArray.length > 0)) {
                console.log('hello');
                if (unit) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    unit && unit.length > 0 && unit.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-lee');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'address': subunitlist.address,
                                'business_group_id': subunitlist.business_group_id,
                                'category_id': subunitlist.category_id,
                                'country_id': subunitlist.country_id,
                                'd_ids': subunitlist.d_ids,
                                'division_id': subunitlist.division_id,
                                'le_id': subunitlist.le_id,
                                'postal_code': subunitlist.postal_code,
                                'u_id': subunitlist.u_id,
                                'u_name': subunitlist.u_name,
                                'label': subunitlist.u_name,
                                'values': subunitlist.u_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunitleee');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setUnitListData(countrylisttempdataunit)
                }
                if (division) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    division && division.length > 0 && division.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {

                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.div_name,
                                'values': subunitlist.div_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunit');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setDivisionListData(countrylisttempdataunit)
                }
                if (category) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    category && category.length > 0 && category.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-category');
                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'cat_id': subunitlist.cat_id,
                                'cat_name': subunitlist.cat_name,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.cat_name,
                                'values': subunitlist.cat_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunit');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                    setCategoryListData(countrylisttempdataunit)
                }
            }
        }

    }, [commonData, legalEntityArray, unit, division, category, localStorage.getItem('SelectedEntity') === "All Legal Entity"])


    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
                let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(commonData && commonData.bg_groups[0].bg_id) })
                console.log(filteredDivision, 'filteredDivision');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)
                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)
                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)

                }
            }
            else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                console.log('hello ais');
                let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: commonData.le_did_infos[0].le_id })
                console.log(filteredDivision, 'filteredDivision555');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)

                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)

                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)
                }
            }
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && bgIds.length > 0 && commonData) {
            if (bgIds.includes(null) === true) {
                console.log('snehaaa');
                setfield({
                    ...field,
                    legalEntity: commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos,
                })

            }
            else {
                let filteredLegalEntity = _.filter(commonData.le_did_infos, { bg_id: Number(commonData.bg_groups[0].bg_id) })
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                })
            }

        }
    }, [commonData, bgIds, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredCategory, 'filteredCategory111');
                let temp = []
                temp.push({
                    'bg_id': filteredCategory[0].bg_id,
                    'c_id': filteredCategory[0].c_id,
                    'cat_id': filteredCategory[0].cat_id,
                    'cat_name': filteredCategory[0].cat_name,
                    'd_id': filteredCategory[0].d_id,
                    'div_id': filteredCategory[0].div_id,
                    'le_id': filteredCategory[0].le_id,
                    'div_name': commonData && commonData.div_infos[0].div_name
                })
                setCategory(temp)
                let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredUnit, 'filteredUnit123');
                setUnit(filteredUnit)
            }
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity && filteredLegalEntity[0].bg_id })
                console.log(filteredBusinessGroup, 'filteredBusinessGroup');
                const unique = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                console.log(unique, 'unique');
                setfield({
                    ...field,
                    business: unique,
                    legalEntity: filteredLegalEntity
                })
            }

        }
    }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if ((commonData) && (division && division.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            division && division.length > 0 && division.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');

                if (commonData && commonData.le_did_infos[0].le_id == subunitlist.le_id) {

                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.div_name,
                        'values': subunitlist.div_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            console.log(totalListunit, 'totalListunit');

            countrylisttempdataunit.push(totalListunit)
            console.log(countrylisttempdataunit, 'countrylisttempdataunit');
            setDivisionListData(countrylisttempdataunit)
        }
    }, [commonData, division, field.legalEntity])

    useEffect(() => {
        if (field.legalEntity && field.legalEntity.length > 0) {
            if (division) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
            if (category) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                category && category.length > 0 && category.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-category');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setCategoryListData(countrylisttempdataunit)
            }
            if (unit) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                unit && unit.length > 0 && unit.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-unit');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'address': subunitlist.address,
                            'business_group_id': subunitlist.business_group_id,
                            'category_id': subunitlist.category_id,
                            'country_id': subunitlist.country_id,
                            'd_ids': subunitlist.d_ids,
                            'division_id': subunitlist.division_id,
                            'le_id': subunitlist.le_id,
                            'postal_code': subunitlist.postal_code,
                            'u_id': subunitlist.u_id,
                            'u_name': subunitlist.u_name,
                            'label': subunitlist.u_name,
                            'values': subunitlist.u_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                countrylisttempdataunit.push(totalListunit)
                setUnitListData(countrylisttempdataunit)
            }
        }
    }, [field.legalEntity, division, category, unit])

    useEffect(() => {
        if ((commonData) && (category && category.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            category && category.length > 0 && category.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-category');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }
            countrylisttempdataunit.push(totalListunit)
            setCategoryListData(countrylisttempdataunit)
        }

    }, [commonData, category && category.length > 0])

    useEffect(() => {
        if ((commonData) && (unit && unit.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            unit && unit.length > 0 && unit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-unit');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'address': subunitlist.address,
                        'business_group_id': subunitlist.business_group_id,
                        'category_id': subunitlist.category_id,
                        'country_id': subunitlist.country_id,
                        'd_ids': subunitlist.d_ids,
                        'division_id': subunitlist.division_id,
                        'le_id': subunitlist.le_id,
                        'postal_code': subunitlist.postal_code,
                        'u_id': subunitlist.u_id,
                        'u_name': subunitlist.u_name,
                        'label': subunitlist.u_name,
                        'values': subunitlist.u_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setUnitListData(countrylisttempdataunit)
        }
    }, [commonData, unit && unit.length > 0])

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
            }
            console.log(tempIds, 'tempIds');
            setDIds(tempIds)
            setCountryDomain(tempArr)
            console.log(tempArr, 'tempArr');
        }

    }, [commonData])

    const HandelChange = (obj) => {
        console.log(obj, 'objj');
        setFilterData({
            ...filterData,
            domain: obj
        })
        setType('')
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            domain: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
        let temp = []
        let bg = []
        let tempDiv = []
        let tempCat = []
        let tempUnit = []
        for (let i in obj) {
            console.log(obj[i], 'obj[i');
            console.log(i, 'i');
            if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity[0].bg_id })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            } else {
                let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(obj[i])))
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(obj[i]) })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            }
            let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(obj[i]) })
            let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
            let temp1 = []
            temp1.push({
                'bg_id': filteredCategory[0].bg_id,
                'c_id': filteredCategory[0].c_id,
                'cat_id': filteredCategory[0].cat_id,
                'cat_name': filteredCategory[0].cat_name,
                'd_id': filteredCategory[0].d_id,
                'div_id': filteredCategory[0].div_id,
                'le_id': filteredCategory[0].le_id,
                'div_name': division[0].div_name
            })
            tempCat = temp1
            let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
            tempUnit = filteredUnit
            tempDiv = filteredDivision
            let uniqueDiv = []
            if (filteredDivision && filteredDivision.length > 0) {
                uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)

            setCategory(temp1)
            setUnit(filteredUnit)

        }
        console.log(tempCat, 'tempCat');
    }
    console.log(dataValue, 'dataValue');

    return (
        <div>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <Link>Comparative Trend</Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <Select style={{ marginLeft: '99%', width: '100%' }} value={chartType} onChange={(data) => {
                                setChartType(data)
                            }}>
                                <Option key='complied'>Complied</Option>
                                <Option key='notComplied'>Not Complied</Option>
                                <Option key='delayedComplied'>Delayed Compliance</Option>
                            </Select>
                        </div>
                        <div className="col-md-2">
                            <p style={{ marginLeft: '95%', fontSize: '18px' }}> <Switch type='success' style={{ backgroundColor: toggleButton == 'Month' ? 'green' : 'grey', fontSize: '18px' }} checked={toggleButton === 'Month' ? true : false} checkedChildren="Month" unCheckedChildren="Year"
                                onChange={(e) => {
                                    setToggleButton(e === false ? 'Year' : 'Month')
                                    setDelayedData([])
                                    setNotcomplieddata([])
                                    setDataValue([])
                                    if (entityData && entityData.length > 0) {
                                        const payload = [
                                            authtoken,
                                            {
                                                "session_token": authtoken,
                                                "request":
                                                    ["GetComparativeChart",

                                                        {
                                                            "c_ids": [
                                                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                            ],
                                                            "d_ids": dIds,
                                                            "filter_type": "Group",
                                                            "filter_ids": [
                                                                1
                                                            ],
                                                            "le_ids":
                                                                localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid], "country_domains": countryDomain,
                                                            "year": `${year}`,
                                                            "chart_types": e === false ? 'Year' : 'Month'
                                                        }
                                                    ]
                                            }
                                        ]

                                        getComparativechart({
                                            payload: payload,
                                            paramid: paramid
                                        })

                                    } else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                                        const payload = [
                                            authtoken,
                                            {
                                                "session_token": authtoken,
                                                "request":
                                                    ["GetComparativeChart",

                                                        {
                                                            "c_ids": [
                                                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                            ],
                                                            "d_ids": dIds,
                                                            "filter_type": "Group",
                                                            "filter_ids": [
                                                                1
                                                            ],
                                                            "le_ids":
                                                                localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                            "country_domains": countryDomain,
                                                            "year": `${year}`,
                                                            "chart_types": e === false ? 'Year' : 'Month'
                                                        }
                                                        // {
                                                        //     "c_ids": [
                                                        //         commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                        //     ],
                                                        //     "d_ids": [
                                                        //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                        //     ],
                                                        //     "filter_type": "Group",
                                                        //     "filter_ids": [
                                                        //         1
                                                        //     ],
                                                        //     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                        //         commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                        //     ],
                                                        //     "country_domains": [
                                                        //         {
                                                        //             "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        //             "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        //             "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        //             "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                        //         }
                                                        //     ],
                                                        //     "year": year,
                                                        //     "chart_types": "Month"
                                                        // }
                                                    ]
                                            }
                                        ]

                                        getComparativechart({
                                            payload: payload,
                                            paramid: paramid
                                        })

                                    }

                                }}
                            /></p>
                        </div>
                        <div className="col-md-2">
                            <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '50%' }} onClick={() => {
                                setOpen(true)

                            }}
                                size='default' >
                                <Tooltip title='Filter'>Filter</Tooltip>
                            </Button>
                        </div>
                    </div>
                </div>
                {
                    table == true ?
                        <div className="container-fluid pt-1">


                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className="col-md-4">
                                        {/* <p><b>{`Compliance-Country:India,Status:${compliance}`}</b></p> */}
                                    </div>
                                    <div className="col-md-4">

                                    </div>
                                    <div className="col-md-4">
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F", marginLeft: '70%' }}
                                            icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                                setTable(false)
                                            }}>
                                            <Tooltip title='Back'>Back</Tooltip>
                                        </Button>
                                    </div>
                                </div>
                            </div><br />
                            <Collapse defaultActiveKey={['0']}>
                                {taskDistributionTable && taskDistributionTable.length > 0 &&
                                    taskDistributionTable.map((item) => {
                                        let unitName = item.ap_compliances
                                        let keys = Object.keys(unitName);
                                        let values = Object.values(unitName);
                                        let childData = values[0]
                                        console.log(values[0], 'values');
                                        return (
                                            <Panel header={item.level1_statutory_name}>
                                                <p><b>{keys[0]}</b></p>
                                                <Table
                                                    columns={columns}
                                                    dataSource={childData}
                                                    bordered
                                                    scroll={{ x: 1500 }}
                                                    pagination={false}
                                                    size={'small'}

                                                />

                                            </Panel>
                                        )
                                    })
                                }
                            </Collapse>


                            {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                    className='userprivclass' /> */}
                        </div> :
                        <div className="container-fluid pt-1">
                            <div className="row" >
                                <div className="col-md-12">

                                </div>
                                {data && data.length > 0 ?
                                    <>
                                        <div className="col-md-12">

                                            {/* <div className="card">
                                                <div className='card-body'> */}

                                            <>
                                                <div id="container" style={{ width: "100%", marginTop: '10px', height: "550px" }}></div>
                                                <div className="col-md-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-3">
                                                        </div>
                                                        <div className="col-md-6" style={{ marginLeft: '70%' }}>
                                                            <label htmlFor=""><b>Time Zone :</b></label>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                onChange={(data) => {
                                                                    setTimeZone(data)
                                                                    setGeneratedOn(true)
                                                                    const payload = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetDateTime_ByZone",
                                                                                {
                                                                                    "tz_name": data
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    getTimeZoneData({
                                                                        payload: payload,
                                                                        paramid: paramid
                                                                    })

                                                                }}
                                                                placeholder="Enter Time Zone"
                                                                style={{ marginLeft: '5%' }}
                                                            // value={compfie.user_Group}

                                                            >

                                                                {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                    return (

                                                                        <Option key={item.tz_name}>

                                                                            {item.tz_name}

                                                                        </Option>

                                                                    );

                                                                })}
                                                                {/* );
                                                })} */}
                                                            </Select>
                                                        </div>

                                                    </div>
                                                </div>

                                            </>



                                            {/* </div>
                                            </div> */}
                                        </div>

                                    </> :
                                    <p style={{ marginTop: '18%', marginLeft: '40%', marginBottom: '18%' }}>No Data to Display </p>
                                }
                                {data && data.length > 0 ?
                                <div className="col-md-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "25px" }}
                                                icon={<ArrowLeftOutlined />} size='default'
                                                onClick={() => {
                                                    setYear(year - 1)
                                                }}
                                            >
                                                Previous Year
                                            </Button>
                                        </div>
                                        <div className="col-md-8"></div>
                                        <div className="col-md-2">
                                            {year != moment().year() ?
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginRight: "25px" }}
                                                    size='default'
                                                    onClick={() => {
                                                        setYear(year + 1)
                                                    }}
                                                >
                                                    Next Year<ArrowRightOutlined />
                                                </Button>
                                                : false}
                                        </div>
                                    </div>
                                </div> : ''}

                            </div>
                        </div>
                }

<Drawer title="FILTER BY" placement="right" closable={false} onClose={false} visible={open}
                    extra={
                        <Space>
                            <CloseCircleOutlined style={{ marginTop: '2%', fontSize: '140%' }} onClick={onClose} />
                        </Space>
                    }
                >
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Country</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Country"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.country ? filterData.country : undefined}
                                    onChange={(data, values) => {
                                        setFilterData({
                                            ...filterData,
                                            country: data,
                                            domain: ''
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            country: values.children,
                                            domain: '',
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            business: '',
                                            category: '',
                                            unit: ''
                                        })
                                        setType('')

                                    }
                                    }
                                    onDeselect={(data) => {

                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {country_info && country_info.length > 0 && country_info.map((item, i) => {
                                        return (
                                            <Option key={item.c_id}>
                                                {item.c_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Domain</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.domain ? filterData.domain : undefined}
                                    // onChange={(data, values) => {
                                    //     console.log(data,'data-domain');
                                    //     let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(data) })
                                    //     let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(data) })
                                    //     // let filteredCategory = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     // let filteredUnit = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(data)))
                                    //     let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
                                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                    //     let temp = []
                                    //     temp.push({
                                    //         'bg_id': filteredCategory[0].bg_id,
                                    //         'c_id': filteredCategory[0].c_id,
                                    //         'cat_id': filteredCategory[0].cat_id,
                                    //         'cat_name': filteredCategory[0].cat_name,
                                    //         'd_id': filteredCategory[0].d_id,
                                    //         'div_id': filteredCategory[0].div_id,
                                    //         'le_id': filteredCategory[0].le_id,
                                    //         'div_name': division[0].div_name
                                    //     })
                                    //     setCategory(temp)
                                    //     setUnit(filteredUnit)
                                    //     setfield({
                                    //         ...field,
                                    //         legalEntity: filteredLegalEntity,
                                    //         business: filteredBusinessGroup
                                    //     })
                                    //     let temp1=[]
                                    //     for(let i in commonData.d_info){
                                    //         temp1.push(commonData.d_info[i].d_id)
                                    //     }
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         domain: temp1
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         domain: values.children,
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setDivision(filteredDivision)
                                    //     // let temp = []
                                    //     // temp.push({
                                    //     //     'bg_id': filteredCategory[0].bg_id,
                                    //     //     'c_id': filteredCategory[0].c_id,
                                    //     //     'cat_id': filteredCategory[0].cat_id,
                                    //     //     'cat_name': filteredCategory[0].cat_name,
                                    //     //     'd_id': filteredCategory[0].d_id,
                                    //     //     'div_id': filteredCategory[0].div_id,
                                    //     //     'le_id': filteredCategory[0].le_id,
                                    //     //     'div_name': filteredDivision[0].div_name
                                    //     // })
                                    //     // setCategory(temp)
                                    //     // setUnit(filteredUnit)
                                    // }
                                    // }
                                    onChange={(option) => HandelChange(option)}
                                    // onDeselect={(data) => {
                                    //     console.log(data,'data-deselect');
                                    //     filterData && filterData.domain.splice(data,1)
                                    // }}
                                    style={{ width: '100%' }}
                                    disabled={filterData.country == '' ? true : false}
                                >
                                    {domainListData && domainListData.length > 0 &&
                                        domainListData.map((finalList_unitlist) => (
                                            <OptGroup label={finalList_unitlist.label}>
                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                ))}
                                            </OptGroup>
                                        ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-1">
                                <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                    setType('Group')
                                    setCheckBox(!checkBox)
                                }}></input>
                            </div>
                            <div className="col-md-4">
                                <label><b>Group</b></label>
                            </div>
                            {/* <div className="col-md-2">

                        </div> */}
                            <div className="col-md-1">
                                {/* <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input> */}
                            </div>
                            <div className="col-md-4">
                                {/* <label><b>Consolidated</b></label> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-6">

                                <label><b>From Date</b></label>
                                <DatePicker
                                    disabled={true}
                                    allowClear={() => {
                                        setFilterData({
                                            ...filterData,
                                            from_date: null,
                                        })
                                    }}
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        setFilterData({
                                            ...filterData,
                                            from_date: dateString,
                                        })

                                    }}

                                />

                            </div>
                            <div className="col-md-6">

                                <label><b>To Date</b></label>

                                <DatePicker
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    // onChange={(date, dateString) => {

                                    //     console.log(dateString, 'dateString');
                                    //     setConsolidatedData({
                                    //         ...consolidatedData,
                                    //         from_date: dateString,
                                    //         to_date: moment(dateString).add(3, 'months')
                                    //     })

                                    // }}
                                    disabled={true}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        if (dateString != undefined) {
                                            setFilterData({
                                                ...filterData,
                                                to_date: dateString,
                                            })
                                        }
                                        else {
                                            setFilterData({
                                                ...filterData,
                                                to_date: null,
                                            })
                                        }


                                    }}

                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    value={filterDataNames.business ? filterDataNames.business : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { bg_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        setfield({
                                            ...field,
                                            legalEntity: filteredEntity
                                        })
                                        setFieldType('BusinessGroup')
                                        setFilterData({
                                            ...filterData,
                                            business: data,
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            business: values.children,
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        setUnit(filteredUnit)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}
                                >
                                    {field && field.business && field.business.length && field.business.map((item, i) => {
                                        return (
                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>
                                        );
                                    })}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    value={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    placeholder="Enter Legal Entity"
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        // let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: data })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { le_id: data })
                                        let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        setLegalEntityArray(filteredLegalEntity)
                                        // setfield({
                                        //     ...field,
                                        //     legalEntity: filteredLegalEntity
                                        // })
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                            setUnit(filteredUnit)
                                        }
                                        else {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: data })
                                            console.log(filteredUnit, 'filteredUnit555');
                                            setUnit(filteredUnit)
                                        }

                                        console.log(filteredDivision, 'filteredDivisionLeg');
                                        setFilterData({
                                            ...filterData,
                                            legalentity: data
                                        })
                                        setFieldType('LegalEntity')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            legalentity: values.children,
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        // setUnit(filteredUnit)
                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                        return (
                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    value={filterDataNames.division ? filterDataNames.division : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.find(commonData && commonData.div_infos, { div_id: Number(data) })
                                        let getLEname = _.find(commonData && commonData.le_did_infos, { le_id: filteredDivision.le_id })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(data) })
                                        setFilterData({
                                            ...filterData,
                                            division: data
                                        })

                                        // let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        // setDivisionObj(filteredDivision)
                                        // setLEObj(getLEname)
                                        // setCategory(subData)
                                        setFieldType('Division')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            division: values.children,
                                            unit: '',
                                            category: '',
                                        })
                                        setUnit(filteredUnit)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision.div_name
                                        })
                                        setCategory(temp)

                                        console.log(category, 'catStateeeee');

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {divisionListData && divisionListData.length > 0 &&
                                        divisionListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.div_id}>{subUnitData.div_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }

                                        }
                                        )}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    value={filterDataNames.category ? filterDataNames.category : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            category: data,
                                            unit: '',
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            category: values.children
                                        })
                                        let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                        setUnit(subData)
                                        setFieldType('Category')
                                        setGo(false)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {categoryListData && categoryListData.length > 0 &&
                                        categoryListData.map((finalList_unitlist) => {
                                            console.log(finalList_unitlist, 'finalList_unitlist');
                                            if (finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Unit"
                                    value={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            unit: data
                                        })
                                        setFieldType('Unit')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            unit: values.children
                                        })

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {unitListData && unitListData.length > 0 &&
                                        unitListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '15%', marginTop: '10%' }}
                        onClick={filterChange}
                        size='default' >
                        Go
                    </Button>
                    {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                        onClick={() => {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusChart",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "filter_type": "Group",
                                                "filter_ids": [
                                                    1
                                                ],
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                    commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                ],
                                                "country_domains": [
                                                    {
                                                        "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                    }
                                                ],
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getCriticalitychart({
                                payload: payload,
                                paramid: paramid
                            })
                            setOpen(false)

                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                country: '',
                                domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: ''
                            })
                        }}
                        size='default' >
                        Reset
                    </Button> */}
                </Drawer>
            </div>
        </div>

    )


}

export default connect(mapStateToProps, {
    getCommonData,
    getComparativechart,
    getTimeZoneData
})(ComparativeTrend);

