import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import { Collapse, Select, Input, DatePicker, Card, Modal, Button, Table, Tooltip, Pagination } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ExclamationCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { ReassignedHistoryReportFilters, ReassignedHistoryReport } from './../../../Store/Action/Report/ReassignReport';

const mapStateToProps = (state) => ({
    reassignreports: state.ReassignReport.reassignreport,
})

const ReassignReports = ({
    ReassignedHistoryReportFilters,
    ReassignedHistoryReport,
    reassignreports: { reassignreports, Reassign_history_show }
}) => {
    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid').replaceAll('"', ''));
    const paramid = location.pathname;
    const _ = require("lodash");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [legalstate, Setlegalstate] = useState(false)
    const [tableshow, setTableShow] = useState(false)
    const [categoryList, setCategoryList] = useState([])
    const [act, setAct] = useState([])
    const [division, setDivision] = useState([])
    const [domain, setDomain] = useState([])
    const [users, setUsers] = useState([])
    const [unit, setUnit] = useState([])
    const [titledata, Settitledata] = useState([])
    console.log(titledata, 'titledata');
    const [key, setKey] = useState("1")
    let [count, setcount] = useState(0)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [paginationarray, setPaginationArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [unitdata, setUnitData] = useState({
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        country: '',
        countryName: '',
        domain: 0,
        domain_name: '',
        user: null,
        user_name: '',
        act: 0,
        act_name: '',
        from_date: "",
        division: 0,
        division_name: '',
        compliance_task: null,
        to_date: "",
        category: 0,
        category_name: '',
        unit: 0,
        unit_name: '',
    })
    const [disableButton, setDisableButton] = useState(false)

    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                if (entityid != "null") {
                    ReassignedHistoryReportFilters({
                        payload: [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetReassignedHistoryReportFilters",
                                    {
                                        "le_id": entityid
                                    }
                                ]
                            }
                        ],
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }
    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        if (entityid != null) {
            setUnitData({
                ...unitdata,
                country: sessionArr[0].c_id
            })
        }
    }, [entityid != null])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setUnitData({
                ...unitdata,
                legal_entity_id: localStorage.getItem('SelectedEntityid'),
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {

        if (Reassign_history_show && Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length > 0) {

            var array = Reassign_history_show.reassigned_history_list.slice((current - 1) * pageSize, (current * pageSize))

            setPaginationArray(array)

        }

    }, [Reassign_history_show.reassigned_history_list, pageSize, current])
    useEffect(() => {
        if (pageState == true) {
            ReassignedHistoryReport({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetReassignedHistoryReport",
                            {
                                "c_id": Number(unitdata.country),
                                "le_id": entityid,
                                "division_id": Number(unitdata.division),
                                "category_id": Number(unitdata.category),
                                "unit_id": Number(unitdata.unit),
                                "d_id": Number(unitdata.domain),
                                "parent_id": Number(unitdata.act),
                                "compliance_task": unitdata.compliance_task,
                                // "usr_id": Number(unitdata.user),
                                "usr_id": null,
                                "from_date": moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY'),
                                "to_date": moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY'),
                                "csv": false,
                                "f_count": Math.max(((current - 1) * pageSize), 0) + 1,
                                "t_count": current != 0 ? current * pageSize : pageSize,
                                "count_qry": true
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])

    useEffect(() => {
        if (reassignreports) {
            setAct(reassignreports && reassignreports.acts)
            setCategoryList(reassignreports && reassignreports.categories_list)
            setUsers(reassignreports && reassignreports.legal_entity_users)
            setDivision(reassignreports && reassignreports.divisions_list)
            setDomain(reassignreports && reassignreports.domains)
            setUnit(reassignreports && reassignreports.units)
        }
    }, [reassignreports])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    // useEffect(() => {
    //     if (Reassign_history_show && Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length > 0) {
    //         let uniqueObjArray = [...new Map(Reassign_history_show && Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length && userwisefilter.user_compliances.map((item) => [item["unit_id"], item])).values()];
    //         const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
    //             return item.unit_id
    //         })

    //         let tempArr = []
    //         for (let i in uniqueIds) {
    //             let filteredArr = _.filter(userwisefilter && userwisefilter.user_compliances && userwisefilter.user_compliances.length && userwisefilter.user_compliances, { unit_id: uniqueIds[i] })
    //             tempArr.push(filteredArr)
    //         }
    //         setFilteredArray([...filteredArray, tempArr])
    //     }

    // }, [userwisefilter && userwisefilter.user_compliances])

    // useEffect(() => {
    //     if (filteredArray.length) {
    //         // let temp = []
    //         filteredArray && filteredArray.map((item, i) => {
    //             return (
    //                 setfilteredArrayState(item)
    //             )
    //         })
    //     }
    // }, [filteredArray])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (paginationarray && paginationarray.length) {
            const ab = paginationarray && paginationarray.length && paginationarray.map(function (item) {
                if ((array.indexOf(item.act_name) < 0))
                    array.push(item.act_name)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationarray && paginationarray.length && paginationarray.map((child, childIndex) => {
                        if (data === child.act_name) {
                            entityArray = {
                                "ac_id": child.ac_id,
                                "act_name": child.act_name,
                                "assigned_on": child.assigned_on,
                                "c_opt_status": child.c_opt_status,
                                "category_name": child.category_name,
                                "compliance_description": child.compliance_description,
                                "compliance_id": child.compliance_id,
                                "compliance_task": child.compliance_task,
                                "country_id": child.country_id,
                                "division_name": child.division_name,
                                "domain_id": child.domain_id,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "legal_entity_id": child.legal_entity_id,
                                "new_user": child.new_user,
                                "o_date": child.o_date,
                                "old_user": child.old_user,
                                "remarks": child.remarks,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "unit": child.unit,
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [paginationarray])

    useEffect(() => {
        if (unitdata.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [unitdata.legal_entity_name])

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            ReassignedHistoryReport({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetReassignedHistoryReport",
                            {
                                "c_id": Number(unitdata.country),
                                "le_id": entityid,
                                "division_id": Number(unitdata.division),
                                "category_id": Number(unitdata.category),
                                "unit_id": Number(unitdata.unit),
                                "d_id": Number(unitdata.domain),
                                "parent_id": Number(unitdata.act),
                                "compliance_task": unitdata.compliance_task,
                                // "usr_id": Number(unitdata.user),
                                "usr_id": Number(unitdata.user) ? Number(unitdata.user) : null,
                                "from_date": moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY'),
                                "to_date": moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY'),
                                "csv": false,
                                "f_count": 1,
                                "t_count": 25,
                                "count_qry": true
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
        }
    }

    useEffect(() => {
        if (pageState === true) {
            setcount(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const disablecondition = (e) => {
        var currentToDate = moment(unitdata.from_date).add(3, 'months')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }

    const onChange = (key) => {
        // console.log(key);
    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Reassign Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" style={{ position: 'relative', padding: "15px 5px" }}>
                            <Collapse defaultActiveKey={["1"]} onChange={onChange} >
                                <Panel header="Reassign Report" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select allowClear={false}
                                                                placeholder='Enter Country'
                                                                size="default"
                                                                onChange={(value, data) => {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        country: value,
                                                                        countryName: data.children,
                                                                        legal_entity_id: "",
                                                                        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                        domain: 0,
                                                                        domain_name: '',
                                                                        user: null,
                                                                        user_name: '',
                                                                        act: 0,
                                                                        act_name: '',
                                                                        from_date: "",
                                                                        division: 0,
                                                                        division_name: '',
                                                                        compliance_task: null,
                                                                        to_date: "",
                                                                        category: 0,
                                                                        category_name: '',
                                                                        unit: 0,
                                                                        unit_name: '',
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                value={unitdata.countryName || undefined}
                                                            >
                                                                {country_info && country_info.length > 0 && country_info.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'country',
                                                                unitdata.countryName,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{sessionArr[0].c_name}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Category:</b></label>
                                                <Select allowClear={false}
                                                    disabled={unitdata.domain == 0 ? true : false}
                                                    value={unitdata.category_name || undefined}
                                                    // onClick={() => {
                                                    //     if (unitdata.domain == 0) {
                                                    //         toast.warning("Domain Required")
                                                    //     }
                                                    // }}
                                                    onChange={(value, data) => {
                                                        // let unit = _.filter(userwisereports.users_units_list, { category_id: Number(value) })
                                                        // setUnit(unit)
                                                        // let unit = _.filter(unitreport.unit_legal_entity, { category_id: Number(value) })
                                                        // setUnit(unit)
                                                        setUnitData({
                                                            ...unitdata,
                                                            category: value,
                                                            category_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Category"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                >
                                                    {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>User:</b> </label>
                                                <Select allowClear={false}
                                                    value={unitdata.user_name || undefined}
                                                    disabled={unitdata.domain == 0 ? true : (unitdata.unit == 0 ? true : false)}
                                                    // onClick={() => {
                                                    //     if (unitdata.domain == 0) {
                                                    //         toast.warning("Domain Required")
                                                    //     } else if (unitdata.unit == 0) {
                                                    //         toast.warning("Vendor Required")

                                                    //     }
                                                    // }}
                                                    onChange={(value, data) => {
                                                        // let domain = _.filter(userwisereports.user_domains_list, { domain_id: Number(value) })
                                                        // setDomain(domain)
                                                        setUnitData({
                                                            ...unitdata,
                                                            user: value,
                                                            user_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter User"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                >
                                                    <Option key='Administartor'>Administrator</Option>
                                                    {users && users.length > 0 && users.map((item, i) => {
                                                        return (
                                                            <Option key={item.employee_code}>
                                                                {item.employee_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            disabled={unitdata.countryName === '' ? true : false}
                                                            onChange={(data, value) => {
                                                                Setlegalstate(true)
                                                                setCurrentEntity(data)
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    legal_entity_id: data,
                                                                    legal_entity_name: value.children,
                                                                    domain: 0,
                                                                    domain_name: '',
                                                                    user: null,
                                                                    user_name: '',
                                                                    act: 0,
                                                                    act_name: '',
                                                                    from_date: "",
                                                                    division: 0,
                                                                    division_name: '',
                                                                    compliance_task: null,
                                                                    to_date: "",
                                                                    category: 0,
                                                                    category_name: '',
                                                                    unit: 0,
                                                                    unit_name: '',
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={unitdata.legal_entity_name || undefined}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legal_entity_id',
                                                            unitdata.legal_entity_name,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Vendor:</b> <span style={{ color: "red" }}>*</span></label>
                                                <Select allowClear={false}
                                                    value={unitdata.unit_name || undefined}
                                                    disabled={unitdata.domain == 0 ? true : false}
                                                    // onClick={() => {
                                                    //     if (unitdata.domain == 0) {
                                                    //         toast.warning("Domain Required")
                                                    //     }
                                                    // }}
                                                    onChange={(value, data) => {
                                                        // let user = _.filter(unitreport.compliance_users, { unit_id: Number(value) })
                                                        // setUsers(user)
                                                        setUnitData({
                                                            ...unitdata,
                                                            unit: value,
                                                            unit_name: data.children,
                                                            user: null,
                                                            user_name: '',
                                                            act: 0,
                                                            act_name: '',
                                                            from_date: "",
                                                            compliance_task: null,
                                                            to_date: "",
                                                        })
                                                    }}
                                                    placeholder="Enter Vendor"
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    {unit && unit.length > 0 && unit.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {item.unit_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'unit_name',
                                                    unitdata.unit_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Vendor Required ',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>From Date:</b> <span style={{ color: "red" }} value={unitdata.from_date ? moment(unitdata.from_date) : ''}>*</span></label>
                                                <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}
                                                    value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                    onChange={(date, dateString) => {

                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: dateString,
                                                            to_date: moment(dateString).add(3, 'months')
                                                        })
                                                    }}
                                                />
                                                {/* <DatePicker style={{ width: '280px' }}
                                                    onChange={(date, dateString) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: moment(new Date(dateString)).format('DD-MMM-YYYY')
                                                        })
                                                    }}
                                                /> */}
                                                {validator.current.message(
                                                    'from_date',
                                                    unitdata.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Domain:</b><span style={{ color: "red" }}>*</span></label>
                                                <Select allowClear={false}
                                                    value={unitdata.domain_name || undefined}
                                                    disabled={unitdata.legal_entity_id === '' ? true : false}

                                                    // disabled={unitdata.user == 0 ? true : false}
                                                    // onClick={() => {
                                                    //     if (unitdata.user == 0) {
                                                    //         toast.warning("User Required")
                                                    //     }
                                                    // }}
                                                    onChange={(value, data) => {
                                                        // let unit = _.filter(userwisereports.users_units_list, { domain_id: Number(value) })
                                                        // setUnit(unit)
                                                        // let division = _.filter(userwisereports.divisions_list, { le_id: entityid })
                                                        // setDivision(division)
                                                        let act = _.filter(reassignreports.acts, { d_id: Number(value) })
                                                        setAct(act)
                                                        setUnitData({
                                                            ...unitdata,
                                                            domain: value,
                                                            domain_name: data.children,
                                                            user: null,
                                                            user_name: '',
                                                            act: 0,
                                                            act_name: '',
                                                            from_date: "",
                                                            division: 0,
                                                            division_name: '',
                                                            compliance_task: null,
                                                            to_date: "",
                                                            category: 0,
                                                            category_name: '',
                                                            unit: 0,
                                                            unit_name: '',
                                                        })
                                                    }}
                                                    placeholder="Enter Domain"

                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    {domain && domain.length > 0 && domain.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'domain_name',
                                                    unitdata.domain_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Act:</b></label>
                                                <Select allowClear={false}
                                                    value={unitdata.act_name || undefined}
                                                    disabled={unitdata.domain == 0 ? true : (unitdata.unit == 0 ? true : false)}
                                                    // onClick={() => {
                                                    //     if (unitdata.domain == 0) {
                                                    //         toast.warning("Domain Required")
                                                    //     } else if (unitdata.unit == 0) {
                                                    //         toast.warning("Vendor Required")

                                                    //     }
                                                    // }}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            act: value,
                                                            act_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Act"
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    {act && act.length > 0 && act.map((item, i) => {
                                                        return (
                                                            <Option key={item.parent_id}>
                                                                {item.act}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date:</b> <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}
                                                    value={unitdata.to_date || undefined}
                                                    onChange={(date, dateString) => {
                                                        disablecondition(dateString)
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: dateString._i
                                                        })
                                                    }}
                                                    disabledDate={(current) => {
                                                        return moment(unitdata.from_date).add(1, 'days') >= current ||
                                                            moment(unitdata.from_date).add(1, 'year') < current;
                                                    }}
                                                />
                                                {/* <label>To Date <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker style={{ width: '280px' }}
                                                    onChange={(date, dateString) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: moment(new Date(dateString)).format('DD-MMM-YYYY')
                                                        })
                                                    }}
                                                /> */}
                                                {validator.current.message(
                                                    'to_date',
                                                    unitdata.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Division:</b></label>
                                                <Select allowClear={false}
                                                    value={unitdata.division_name || undefined}
                                                    disabled={unitdata.domain == 0 ? true : false}
                                                    // onClick={() => {
                                                    //     if (unitdata.domain == 0) {
                                                    //         toast.warning("Domain Required")
                                                    //     }
                                                    // }}
                                                    onChange={(value, data) => {
                                                        // let unit = _.filter(userwisereports.users_units_list, { div_id: Number(value) })
                                                        // setUnit(unit)
                                                        let category = _.filter(reassignreports.categories_list, { div_id: Number(value) })
                                                        setCategoryList(category)
                                                        setUnitData({
                                                            ...unitdata,
                                                            division: value,
                                                            division_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Division"
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task:</b></label>
                                                <Input allowClear={false} placeholder="Enter Compliance Task"
                                                    value={unitdata.compliance_task || undefined}
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                    onChange={(e) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            compliance_task: e.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <Button disabled={disableButton} type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "37%", marginRight: "10px", marginTop: '5px' }}
                                                    onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>

                            {tableshow == true ?
                                <><div className='col-md-12 mt-3 mb-3' >
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Legal Entity :</b> {unitdata.legal_entity_name}</label>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Country :</b> {unitdata.countryName ? unitdata.countryName : sessionArr[0].c_name}</label>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Domain :</b> {unitdata.domain_name}</label>
                                        </div>
                                    </div>
                                </div>
                                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content fh userprivclass1'>
                                                    <table className='widthHeighreassign table-fixed'>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className='ant-table-thead'>
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: '10px' }}> # </th>
                                                                <th className='ant-table-cell' style={{ width: '200px' }} >Compliance Task</th>
                                                                <th className='ant-table-cell' style={{ width: '200px' }}>Opted Status</th>
                                                                <th className='ant-table-cell' style={{ width: '200px' }}>Updated on</th>
                                                                <th className='ant-table-cell' style={{ width: '200px' }}><center>Last Due Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '200px' }} >Assigned Date</th>
                                                                <th className='ant-table-cell' style={{ width: '200px' }}><center>Assigned</center></th>
                                                                <th className='ant-table-cell' style={{ width: '200px' }}><center>Reason</center></th>
                                                            </tr>
                                                        </thead>
                                                        {titledata && titledata.length > 0 ?
                                                            titledata.map((itemdata, i) => {
                                                                return (<tbody className='ant-table-tbody'>
                                                                    <tr className="bg-lightdark">
                                                                        <td colSpan="100%">
                                                                            <table className='white' style={{ tableLayout: 'auto' }}>
                                                                                <tbody className='ant-table-tbody'>
                                                                                    <div className='row m-3'>
                                                                                        <div className='col-md-5' >
                                                                                            <label><b>Vendor :</b> {itemdata.child[0].unit}</label>
                                                                                        </div>
                                                                                        <div className='col-md-5' >
                                                                                            <label><b>Division :</b> {itemdata.child[0].division_name}</label>
                                                                                        </div>
                                                                                        <div className='col-md-2' >
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row m-3'>
                                                                                        <div className='col-md-5' >
                                                                                            <label><b>Category :</b> {itemdata.child[0].category_name}</label>
                                                                                        </div>
                                                                                        <div className='col-md-5' >
                                                                                            <label><b>Time Zone :</b> {itemdata.child[0].tz_name}</label>
                                                                                        </div>
                                                                                        <div className='col-md-2' >
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <tr className='ant-table-row ant-table-row-level-0'>
                                                                                    <td className='ant-table-cell w6'><b>Vendor:</b></td>
                                                                                    <td className='ant-table-cell w15'>{unitdata.unit_name}</td>
                                                                                    <td className='ant-table-cell w6'><b>Division :</b></td>
                                                                                    <td className='ant-table-cell w15' >{unitdata.division_name}</td>
                                                                                </tr>
                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                    <td className='ant-table-cell w6'><b>Category:</b></td>
                                                                                    <td className='ant-table-cell w40'><span className="category-name">{unitdata.category_name}</span></td>
                                                                                    <td className='ant-table-cell w6'><b>Time Zone:</b></td>
                                                                                    <td className='ant-table-cell w40'><span className="category-name">{"Asia-Kolkata"}</span></td>
                                                                                </tr> */}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    {itemdata.child.map((items) => {
                                                                        return <Fragment>
                                                                            <tr className='ant-table-row ant-table-row-level-0'>
                                                                                <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='ant-table-cell'><center>{count + 1}</center></td>
                                                                                <td title={items.compliance_task} className='ant-table-cell ellipsis'><Tooltip title={items.compliance_description}>
                                                                                    <ExclamationCircleTwoTone />
                                                                                </Tooltip> &nbsp;{items.compliance_task}</td>
                                                                                <td className='ant-table-cell'>{items.c_opt_status}</td>
                                                                                <td className='ant-table-cell'>{ }</td>
                                                                                <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                <td className='ant-table-cell'><center><Tooltip title={items.assigned_on}>
                                                                                    <ExclamationCircleTwoTone />
                                                                                </Tooltip> &nbsp;{items.assigned_on}</center></td>
                                                                                <td className='ant-table-cell'><Tooltip title={items.old_user}>
                                                                                    <ExclamationCircleTwoTone />
                                                                                </Tooltip> &nbsp;{items.new_user}</td>
                                                                                <td className='ant-table-cell'>{items.remarks}</td>
                                                                                <span hidden>{count = count + 1}</span>
                                                                            </tr>
                                                                        </Fragment>
                                                                    })
                                                                    }
                                                                </tbody>)
                                                            })
                                                            : <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td style={{ paddingTop: '5%', fontSize: '14px', fontWeight: 'bold', paddingBottom: '5%' }}>No Records Found</td>
                                                            </tr>}
                                                    </table>
                                                    <br />
                                                    <div>
                                                        {/* <button
                                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                                    onClick={submitrec}>Submit</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {count != 0 ?
                                        <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                            <label style={{ marginLeft: "28px" }}>Showing {pageSize * (current - 1) + 1} to {count} of {Reassign_history_show.total_count} entries</label>
                                        </div> : ''}
                                    <div
                                        // className="btn btn-light-success rounded-pill px-4 text-success"
                                        style={{ position: 'absolute', bottom: '-25px', right: 0 }}
                                    // onClick={submitrec}
                                    >

                                        <Pagination
                                            current={current}
                                            showSizeChanger={true}
                                            showQuickJumper={true}
                                            onShowSizeChange={() => {
                                                setCurrent(1)
                                                // setpageState(true)
                                            }}
                                            pageSizeOptions={[25, 50, 100]} hideOnSinglePage={true} defaultPageSize={25}
                                            onChange={(page, pageSizes) => {
                                                setpageState(true)
                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                setPageSize(pageSizes)
                                            }} total={Reassign_history_show.total_count}
                                        // showTotal={(total) => `Showing 1 to ${count} of ${Reassign_history_show.total_count} entries`}
                                        />


                                    </div>
                                </> : false}
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}
export default connect(mapStateToProps, {
    ReassignedHistoryReportFilters,
    ReassignedHistoryReport
})(ReassignReports);