import { TASKHISTORY_DATA,TASK_HISTORY_REPORT,TASK_HISTORY_EXPORT } from '../../types/index'

const initialState = {
    taskHistoryReport: {
        data: [],
        getTableData:[],
        downloadPath:[]
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TASKHISTORY_DATA: {
            return {
                ...state,
                taskHistoryReport: {
                    ...state.taskHistoryReport,
                    data:payload
                },
            };
        }
        case TASK_HISTORY_REPORT: {
            return {
                ...state,
                taskHistoryReport: {
                    ...state.taskHistoryReport,
                    getTableData:payload
                },
            };
        }
        case TASK_HISTORY_EXPORT: {
            return {
                ...state,
                taskHistoryReport: {
                    ...state.taskHistoryReport,
                    downloadPath:payload
                },
            };
        }
        default:
            return state;
    }
}
