import React from 'react'
import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { Modal, Input, Card, Collapse, Button, Table } from 'antd';
import _, { entries, forIn, indexOf } from 'lodash';
import DataTable from "react-data-table-component";
import { UserAddOutlined, EditOutlined, ArrowLeftOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { settingsfilterlist, settingslist, addsettings } from './../../Store/Action/Transcations/Criticalitysettings'
import { Select } from 'antd';
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';


const { Option } = Select;
const mapStateToProps = (state) => ({
    filterdatas: state.CriticalitySettings.criticalitysettings.filterdata,
    lists: state.CriticalitySettings.criticalitysettings.list
});

const CriticalitySetting = ({
    settingsfilterlist,
    settingslist,
    filterdatas,
    lists,
    addsettings

}) => {
    console.log(lists, 'listslistslists');
    const [show, setShow] = useState(false)
    const [selectvalue, setSelectValue] = useState("")
    const [indexvalues, setIndexValues] = useState(undefined)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const validator = useRef(new SimpleReactValidator());
    const formValidator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Search } = Input;
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [Settings, SetSettings] = useState({
        le_id: '',
        domain_id: '',
        statutory_nature: 'All',
        sm_record: '',
        bg_id: ''
    })
    const [criticalitylist, setCriticalityList] = useState([])
    const [legal, setLegal] = useState(false)
    const [compid, setCompId] = useState([])
    const [finallegalEntity, setFinallegalentity] = useState()
    const [statutorydata, setStatutorydata] = useState([])
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [remarksFormSubmit, setRemarksFormSubmit] = useState(false)
    const [category, setCategory] = useState([])
    const [titledata, Settitledata] = useState({
        displauvalue: ''
    })
    const [tempState, setTempState] = useState([])
    const [collapseindex, setCollapseIndex] = useState([])
    const [listChild, setListChild] = useState([])
    const [updateRemarks, setUpdateRemarks] = useState([])
    const [updateSelectValue, setUpdateSelectValue] = useState([])
    console.log(updateSelectValue, 'updateSelectValue')
    const [collapseChange, setCollapseChange] = useState('')
    const [count, setCount] = useState()
    const [entires, setEntries] = useState(0)
    const [data, setData] = useState();
    const [finalArray, setFinalArray] = useState([])
    const [showMore, setShowMore] = useState(false)
    const [arrayLength, setArrayLength] = useState()
    const [totalValue, setTotalValue] = useState(0)
    const [childIndex, setChildIndex] = useState()
    const [businessGroup, setBusinessGroup] = useState({
        bg_id: '',
        bg_name: ''
    })
    const [editshow, seteditshow] = useState(false);
    const [listshow, setlistshow] = useState(true);
    const [checkdata, setCheckdata] = useState([])
    const [lastIndex, setLastIndex] = useState(0);
    const [lastChildIndex, setLastChildIndex] = useState(0);
    const prevCountRef = useRef();
    const prevCountRefChild = useRef();
    const [tempStaticArray, setTempStaticArray] = useState([])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (SelectedEntity != 'All Legal Entity') {
            let businessName = _.filter(sessionArr, { le_name: SelectedEntity })
            setBusinessGroup({
                ...businessGroup,
                bg_id: businessName && businessName.length > 0 && businessName[0].bg_id,
                bg_name: businessName && businessName.length > 0 && businessName[0].bg_name
            })
        }
    }, [SelectedEntity])
    const previous = () => {
        seteditshow(false);
        setlistshow(true);
    }

    const [hidden, setHidden] = useState(true)
    const [gettemp, setGetTemp] = useState([]);
    const hiddenfunction = (criticality, compliance_id, current) => {
        if (criticality == current) {
            gettemp[compliance_id] = true
        } else {
            gettemp[compliance_id] = false
        }
        setGetTemp([...gettemp])
    }
    const columns = [
        {
            title: 'S.No',
            dataIndex: false,
            align: 'center',
            width: '20px',
            render: (text, record, index) => {
                if (record.is_new) {
                    return <span style={{ color: '#0000FF' }}>{record.childId + 1}</span>
                } else {
                    return record.childId + 1
                }

            }
        },
        {
            title: "Statutory Provision",
            ellipsis: true,
            width: '40px',
            dataIndex: false,
            key: false,
            render: (record) => {
                if (record.is_new) {
                    return <span className='cut-text' style={{ color: '#0000FF' }}>{record.statutory_provision}</span>
                } else {
                    return record.statutory_provision
                }
            }
        },
        {
            title: "Compliance Task",
            // ellipsis: true,
            // selector: (row) => row.compliance_task,
            width: '40px',
            dataIndex: false,
            key: false,
            ellipsis: true,
            render: (record) => {
                if (record.is_new) {
                    return <span className='cut-text' style={{ color: '#0000FF' }}>{record.compliance_task}</span>
                } else {
                    return record.compliance_task
                }
            }
        },
        {
            title: "Compliance Frequency",
            ellipsis: true,
            width: '45px',
            ellipsis: true,
            dataIndex: false,
            key: false,
            render: (record) => {
                if (record.is_new) {
                    return <span style={{ color: '#0000FF' }}>{record.frequency}</span>
                } else {
                    return record.frequency
                }
            }
        },
        {
            title: "Penal Consequences",
            width: '45px',
            ellipsis: true,
            dataIndex: false,
            key: false,
            render: (record) => {
                if (record.is_new) {
                    return <span className='cut-text' style={{ color: '#0000FF' }}>{record.penal_consequences}</span>
                } else {
                    return record.penal_consequences
                }
            }
        },
        {
            title: "Applicable/Total vendors",
            width: '45px',
            dataIndex: false,
            key: false,
            render: (record) => {
                if (record.is_new) {
                    return <span style={{ color: '#0000FF' }}>{record.unit_id}</span>
                } else {
                    return record.unit_id
                }
            }
        },
        {
            title: <UserAddOutlined />,
            sortable: true,
            width: '20px',
            align: 'center',
            dataIndex: false,
            key: false,
            render: (record) => {
                if (record.is_new) {
                    return <span style={{ color: '#0000FF' }}>{record.criticality}</span>
                } else {
                    return record.criticality
                }
            }

        },
        {
            title: <EditOutlined />,
            sortable: true,
            width: '40px',
            fixed: 'right',
            render: (record, rowIndex) => {

                return (
                    <>
                        <Select style={{ width: '100%', height: "35px" }} defaultValue={updateSelectValue[record.compliance_id]} key={updateSelectValue[record.compliance_id]}
                            onChange={(e, i) => {
                                if (record.compliance_id in updateSelectValue) {
                                    let index = updateSelectValue.indexOf(record)
                                    updateSelectValue[record.compliance_id] = [];
                                    if (index == -1) {
                                        updateSelectValue[record.compliance_id] = e;
                                    }
                                } else {
                                    updateSelectValue[record.compliance_id] = [];
                                    updateSelectValue[record.compliance_id] = e
                                }
                                setUpdateSelectValue([...updateSelectValue])
                                let tempArray = []
                                tempState && tempState.length > 0 && tempState.map((item, i) => {
                                    if (item.compliance_id === record.compliance_id) {
                                        tempState.splice(i, 1)
                                        setTempState([...tempState, ...tempArray])
                                    }
                                })
                                tempArray.push({
                                    "compliance_id": record.compliance_id,
                                    "criticality_id": updateSelectValue[record.compliance_id] === 'A' ? 1 : updateSelectValue[record.compliance_id] === 'B' ? 2 : 3,
                                    "remarks": record.criticality === updateSelectValue[record.compliance_id] ? '' : updateRemarks[record.compliance_id]
                                })
                                setTempState([...tempState, ...tempArray])
                                setCollapseChange('')
                                // setCollapseChange('afterCollapseChange')                               
                                // setGetTemp([...gettemp]);
                                //  hiddenfunction(record.criticality,record.compliance_id,e)

                            }}>
                            {record.is_new == true ?
                                <Option key="">Select</Option>
                                : ''}
                            {lists.criticality_list.map((item, i) => {
                                return (
                                    <Option key={item.criticality_name[0]}>
                                        {item.criticality_name[0]}
                                    </Option>
                                );
                            })}


                        </Select>
                    </>
                )

            },
            dataIndex: false,
            key: false,
        },
        {
            title: "Remarks",
            sortable: true,
            width: '45px',
            fixed: 'right',
            render: (record, rowIndex) => {
                return (

                    <div>
                        {record.is_new == false ?
                            <Input name='remarks' style={{ width: "180px", height: "35px" }} className="form-control" placeholder='Enter Remarks' autoComplete={'off'}
                                hidden={record.criticality === updateSelectValue[record.compliance_id]} //record.criticality === updateSelectValue[record.compliance_id] ? true : false
                                defaultValue={updateRemarks[record.compliance_id]}
                                key={updateRemarks[record.compliance_id]}
                                disabled={collapseChange === 'collapse' ? true : false}
                                onBlur={(e, i) => {
                                    let temp1 = []
                                    temp1.push(e.target.value)
                                    if (record.compliance_id in updateRemarks) {
                                        let index = updateRemarks.indexOf(record)
                                        updateRemarks[record.compliance_id] = [];
                                        if (index == -1) {
                                            updateRemarks[record.compliance_id] = e.target.value;
                                        }
                                    } else {
                                        updateRemarks[record.compliance_id] = [];
                                        updateRemarks[record.compliance_id] = e.target.value
                                    }
                                    setUpdateRemarks([...updateRemarks])
                                    let tempArray = []
                                    tempState && tempState.length > 0 && tempState.map((item, i) => {
                                        if (item.compliance_id === record.compliance_id) {
                                            tempState.splice(i, 1)
                                            setTempState([...tempState, ...tempArray])
                                        }
                                    })
                                    tempArray.push({
                                        "compliance_id": record.compliance_id,
                                        "criticality_id": updateSelectValue[record.compliance_id] === 'A' ? 1 : updateSelectValue[record.compliance_id] === 'B' ? 2 : 3,
                                        "remarks": record.criticality === updateSelectValue[record.compliance_id] ? '' : updateRemarks[record.compliance_id]
                                    })
                                    setTempState([...tempState, ...tempArray])

                                }}
                            >
                            </Input>
                            : record.is_new == true && updateSelectValue[record.compliance_id] ? <Input name='remarks' style={{ width: "180px", height: "35px" }} className="form-control" placeholder='Enter Remarks' autoComplete={'off'}
                                hidden={record.criticality === updateSelectValue[record.compliance_id]} //record.criticality === updateSelectValue[record.compliance_id] ? true : false
                                defaultValue={updateRemarks[record.compliance_id]}
                                key={updateRemarks[record.compliance_id]}
                                disabled={collapseChange === 'collapse' ? true : false}
                                onBlur={(e, i) => {
                                    let temp1 = []
                                    temp1.push(e.target.value)
                                    if (record.compliance_id in updateRemarks) {
                                        let index = updateRemarks.indexOf(record)
                                        updateRemarks[record.compliance_id] = [];
                                        if (index == -1) {
                                            updateRemarks[record.compliance_id] = e.target.value;
                                        }
                                    } else {
                                        updateRemarks[record.compliance_id] = [];
                                        updateRemarks[record.compliance_id] = e.target.value
                                    }
                                    setUpdateRemarks([...updateRemarks])
                                    let tempArray = []
                                    tempState && tempState.length > 0 && tempState.map((item, i) => {
                                        if (item.compliance_id === record.compliance_id) {
                                            tempState.splice(i, 1)
                                            setTempState([...tempState, ...tempArray])
                                        }
                                    })
                                    tempArray.push({
                                        "compliance_id": record.compliance_id,
                                        "criticality_id": updateSelectValue[record.compliance_id] === 'A' ? 1 : updateSelectValue[record.compliance_id] === 'B' ? 2 : 3,
                                        "remarks": record.criticality === updateSelectValue[record.compliance_id] ? '' : updateRemarks[record.compliance_id]
                                    })
                                    setTempState([...tempState, ...tempArray])

                                }}
                            >
                            </Input> : ''}


                        {record.criticality != updateSelectValue[record.compliance_id] && record.is_new == false ?
                            formValidator.current.message(
                                'remarks',
                                updateRemarks[record.compliance_id],
                                'required',
                                {
                                    className: `invalid-feedback ${remarksFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Remarks Required',

                                    }
                                }) : ''}
                    </div>
                )
            },
            dataIndex: false,
            key: false
        },
    ];


    const arr = [];
    let statelist = [];
    const handleOk = () => {
        setLegal(false)
    };
    const legalmodel = () => {
        setLegal(true)
    }
    const handleCancel = () => {
        setLegal(false)
    };
    const domainchange = (e) => {
        if (filterdatas.nature_list.length > 0) {
            statelist = _.filter(filterdatas.nature_list, { domain_id: e });
            SetSettings({
                ...Settings,
                domain_id: e,
                statutory_nature: statelist
            })
        }
    }


    const showresult = (e) => {
        setAddFormSubmit(true)
        setCheckdata([])
        setArrayLength()
        setTotalValue(0)
        Settitledata({
            ...titledata,
            displauvalue: []
        })
        setFinalArray([])
        if (validator.current.allValid() && isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCriticalitySettings",
                        {
                            "le_id": entityid,
                            "domain_id": Settings.domain_id,
                            "statutory_nature": Settings.statutory_nature ? Settings.statutory_nature : "All",
                            "sm_record": 0
                        }
                    ]
                }
            ]
            settingslist({
                payload: payload,
                paramid: paramid
            })
            setShow(true)
        }
    }

    const showMoreresult = (e) => {
        setAddFormSubmit(true)
        setShowMore(true)
        if (validator.current.allValid() && isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCriticalitySettings",
                        {
                            "le_id": entityid,
                            "domain_id": Settings.domain_id,
                            "statutory_nature": Settings.statutory_nature,
                            "sm_record": checkdata.length
                        }
                    ]
                }
            ]
            settingslist({
                payload: payload,
                paramid: paramid
            })
            setShow(true)
        }
    }


    const addform = () => {
        // if (remarkstext != "") {
        setRemarksFormSubmit(true)
        if (formValidator.current.allValid()) {
            if (criticalitylist && criticalitylist.length > 0) {
                const pay = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveCriticalitySettings",
                            {
                                "le_id": entityid,
                                "criticality_list": criticalitylist
                            }
                        ]
                    }
                ]
                addsettings({
                    payload: pay,
                    paramid: paramid
                })
            }
            else {
                const pay = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveCriticalitySettings",
                            {
                                "le_id": entityid,
                                "criticality_list": tempStaticArray
                            }
                        ]
                    }
                ]
                addsettings({
                    payload: pay,
                    paramid: paramid
                })
            }
        }


    }

    const genextra = (index, List) => {
        return <> <div onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }} className="row">
            <div className={collapseindex && collapseindex.includes(List.index) ? 'col-md-6' : 'col-md-12'}>
                <div className="form-group">
                    <Select style={{ width: '100px', height: "100%" }} defaultValue='Select'
                        onChange={(e, i) => {
                            setSelectValue(e)
                            setListChild(List.child)
                            for (let i in List.child) {
                                if (List.child[i].compliance_id in updateSelectValue) {
                                    let index = updateSelectValue.indexOf(List.child[i].compliance_id)
                                    updateSelectValue[List.child[i].compliance_id] = [];
                                    if (index == -1) {
                                        updateSelectValue[List.child[i].compliance_id] = e;
                                    }
                                } else {
                                    updateSelectValue[List.child[i].compliance_id] = [];
                                    updateSelectValue[List.child[i].compliance_id] = e
                                }
                                setUpdateSelectValue([...updateSelectValue])
                            }
                            let temp = []
                            temp.push(index)
                            const uniqueChars = [...new Set([...collapseindex, ...temp])];
                            setCollapseIndex(uniqueChars)
                            setCollapseChange('collapse')
                        }}

                    >
                        <Option key="">Select</Option>
                        {lists.criticality_list.map((item, i) => {
                            return (
                                <>
                                    <Option key={item.criticality_name[0]}>
                                        {item.criticality_name[0]}
                                    </Option>
                                </>
                            );
                        })}
                    </Select>
                </div>
            </div>
            {collapseindex && collapseindex.includes(List.index) && selectvalue != "" ?
                <div className="col-md-6">
                    <div className="form-group">
                        <Input name='remarks' style={{ width: "100%", height: "100%" }} className="form-control" placeholder='Enter Remarks' autoComplete={'off'}
                            hidden={((List.child && List.child.length == 1) && (List.child[0].criticality == selectvalue))}
                            onBlur={(e, i) => {
                                let tempArray = []
                                setListChild(List.child)
                                for (let i in List.child) {
                                    if (List.child[i].compliance_id in updateRemarks) {
                                        let index = updateRemarks.indexOf(List.child[i].compliance_id)
                                        updateRemarks[List.child[i].compliance_id] = [];
                                        if (index == -1) {
                                            updateRemarks[List.child[i].compliance_id] = e.target.value;
                                        }
                                    } else {
                                        updateRemarks[List.child[i].compliance_id] = [];
                                        updateRemarks[List.child[i].compliance_id] = e.target.value
                                    }
                                    setUpdateRemarks([...updateRemarks])
                                }
                            }}
                        >
                        </Input>
                    </div>
                </div> : ''}

        </div>
        </>
    }

    // const getData = () => {
    //     let countrylisttempdataNew = [];
    //     let arrNew = [];
    //     lists.criticality_statu.map(function (item) {
    //         if ((arrNew.indexOf(item.statutory_name) < 0))
    //             arrNew.push(item.statutory_name);
    //     });

    //     arrNew.map((pmenu, index) => {
    //         let entityArrayNew = [];
    //         let tempArraryNew = [];
    //         if (pmenu) {
    //             lists.criticality_statu.map((subMenuList, childIndex) => {
    //                 if (pmenu === subMenuList.statutory_name) {
    //                     entityArrayNew = {
    //                         'compliance_id': subMenuList.compliance_id,
    //                         'compliance_task': subMenuList.compliance_task,
    //                         'criticality': subMenuList.criticality,
    //                         'criticality_id': subMenuList.criticality_id,
    //                         'frequency': subMenuList.frequency,
    //                         'is_new': subMenuList.is_new,
    //                         'penal_consequences': subMenuList.penal_consequences,
    //                         'remarks': subMenuList.remarks,
    //                         'statutory_id': subMenuList.statutory_id,
    //                         'statutory_mapping': subMenuList.statutory_mapping,
    //                         'statutory_name': subMenuList.statutory_name,
    //                         'statutory_provision': subMenuList.statutory_provision,
    //                         'unit_id': subMenuList.unit_id,
    //                         'parentId': index,
    //                         'childId': childIndex,
    //                     }
    //                     tempArraryNew.push(entityArrayNew)
    //                     updateRemarks[subMenuList.compliance_id] = subMenuList.remarks
    //                     updateSelectValue[subMenuList.compliance_id] = subMenuList.criticality_id === 1 ? 'A' : subMenuList.criticality_id === 2 ? 'B' : 'C'
    //                 }
    //             })
    //         }
    //         countrylisttempdataNew.push(tempArraryNew)
    //     })
    //     var merged = [].concat.apply([], countrylisttempdataNew);
    //     setData(merged)
    //     setUpdateRemarks([...updateRemarks])
    //     setUpdateSelectValue([...updateSelectValue])
    // }

    useEffect(() => {
        validator.current.showMessages()
        formValidator.current.showMessages()
    }, [])


    useEffect(() => {
        if (lists && lists.criticality_statu) {
            setArrayLength([...lists && lists.criticality_statu]
            )
            setTotalValue(lists.r_count)
            let temp = checkdata
            for (let i in lists && lists.criticality_statu) {
                temp.push(lists && lists.criticality_statu[i])
            }
            setCheckdata([...temp])
        }

    }, [lists && lists.criticality_statu])
    console.log(checkdata, 'checkdata');
    useEffect(() => {
        if (isAuth) {
            if (entityid !== '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "CriticalitySettingsFilters",
                            {
                                "le_id": entityid
                            }
                        ]
                    }
                ]
                if ((entityid != "null")) {
                    if (entityid != undefined) {
                        settingsfilterlist({
                            payload: payload,
                            paramid: paramid
                        })
                    }
                }
            }
        }
    }, [isAuth, entityid])

    useEffect(() => {
        if (titledata.displauvalue.length > 0) {
            let temps = finalArray
            setCount(lists.r_count)
            if (showMore === true) {
                for (let i in titledata.displauvalue) {
                    temps.push(titledata.displauvalue[i])
                }
                setFinalArray([...temps]);
                setEntries(lists.criticality_statu.concat(arrayLength).length)
            }
            else {
                for (let i in titledata.displauvalue) {
                    temps.push(titledata.displauvalue[i])
                }
                setFinalArray([...temps]);
                setEntries(lists && lists.criticality_statu.length)
            }


        }


    }, [titledata.displauvalue])

    useEffect(() => {
        if (titledata.displauvalue.length > 0) {
            setLastIndex(titledata.displauvalue[titledata.displauvalue.length - 1].index)
            setLastChildIndex(titledata.displauvalue[titledata.displauvalue.length - 1].child.length - 1)
            prevCountRef.current = titledata.displauvalue[titledata.displauvalue.length - 1].index
            prevCountRefChild.current = titledata.displauvalue[titledata.displauvalue.length - 1].child.length - 1
        }

    }, [titledata.displauvalue])

    useEffect(() => {
        let countrylisttempdata = [];
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let finalStaticArr = [];
        if (lists.criticality_statu) {
            const ab = lists.criticality_statu.map(function (item) {
                if ((array.indexOf(item.statutory_name) < 0))
                    array.push(item.statutory_name)

            })
            if (titledata.displauvalue.length == 0) {
                array.map((data, i) => {
                    let array2 = [];
                    let entityArrayNew = [];
                    let staticArray = [];
                    let temp = [];
                    let tempStatic = [];
                    if (data) {
                        lists.criticality_statu && lists.criticality_statu.map((subMenuList, childIndex) => {
                            if (data === subMenuList.statutory_name) {
                                entityArrayNew = {
                                    'compliance_id': subMenuList.compliance_id,
                                    'compliance_task': subMenuList.compliance_task,
                                    'criticality': subMenuList.criticality,
                                    'criticality_id': subMenuList.criticality_id,
                                    'frequency': subMenuList.frequency,
                                    'is_new': subMenuList.is_new,
                                    'penal_consequences': subMenuList.penal_consequences,
                                    'remarks': subMenuList.remarks,
                                    'statutory_id': subMenuList.statutory_id,
                                    'statutory_mapping': subMenuList.statutory_mapping,
                                    'statutory_name': subMenuList.statutory_name,
                                    'statutory_provision': subMenuList.statutory_provision,
                                    'unit_id': subMenuList.unit_id,
                                    'parentId': i,
                                    'childId': childIndex,
                                }
                                if (subMenuList.is_new == false) {
                                    staticArray = {
                                        'compliance_id': subMenuList.compliance_id,
                                        'criticality_id': subMenuList.criticality_id,
                                        'remarks': subMenuList.remarks
                                    }
                                    tempStatic.push(staticArray)
                                }

                                temp.push(entityArrayNew)

                                updateRemarks[subMenuList.compliance_id] = subMenuList.remarks
                                if (subMenuList.is_new == true) {
                                    updateSelectValue[subMenuList.compliance_id] = ''
                                } else {
                                    updateSelectValue[subMenuList.compliance_id] = subMenuList.criticality_id === 1 ? 'A' : subMenuList.criticality_id === 2 ? 'B' : 'C'
                                }
                                // updateSelectValue[subMenuList.compliance_id] = subMenuList.criticality_id === 1 ? 'A' : subMenuList.criticality_id === 2 ? 'B' : 'C'
                            }
                        })
                    }
                    countrylisttempdata.push(temp)
                    finalStaticArr.push(tempStatic)
                    datavalue = {
                        index: i,
                        paraent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
            } else {
                console.log(setLastIndex(prevState => (prevState + 1)), 'setLastIndex(prevState => (prevState+1))')
                console.log(titledata.displauvalue[titledata.displauvalue.length - 1].index, 'titledata.displauvalue[titledata.displauvalue.length - 1].index')
                console.log(titledata.displauvalue[titledata.displauvalue.length - 1].child.length - 1, 'children')
                var parentIndexId = lastIndex.lastParentIndex;
                let tempLastIndex = 0;
                tempLastIndex = prevCountRef.current;
                array.map((data, i) => {
                    let array2 = [];
                    let entityArrayNew = [];
                    let staticArrayNew = [];
                    let temp = [];
                    let tempStaticNew = [];
                    if (data) {
                        let tempLastChildIndex = 0;
                        tempLastChildIndex = prevCountRefChild.current

                        for (titledata.displauvalue.length in lists.criticality_statu) {

                            if (data === lists.criticality_statu[titledata.displauvalue.length].statutory_name) {
                                entityArrayNew = {
                                    'compliance_id': lists.criticality_statu[titledata.displauvalue.length].compliance_id,
                                    'compliance_task': lists.criticality_statu[titledata.displauvalue.length].compliance_task,
                                    'criticality': lists.criticality_statu[titledata.displauvalue.length].criticality,
                                    'criticality_id': lists.criticality_statu[titledata.displauvalue.length].criticality_id,
                                    'frequency': lists.criticality_statu[titledata.displauvalue.length].frequency,
                                    'is_new': lists.criticality_statu[titledata.displauvalue.length].is_new,
                                    'penal_consequences': lists.criticality_statu[titledata.displauvalue.length].penal_consequences,
                                    'remarks': lists.criticality_statu[titledata.displauvalue.length].remarks,
                                    'statutory_id': lists.criticality_statu[titledata.displauvalue.length].statutory_id,
                                    'statutory_mapping': lists.criticality_statu[titledata.displauvalue.length].statutory_mapping,
                                    'statutory_name': lists.criticality_statu[titledata.displauvalue.length].statutory_name,
                                    'statutory_provision': lists.criticality_statu[titledata.displauvalue.length].statutory_provision,
                                    'unit_id': lists.criticality_statu[titledata.displauvalue.length].unit_id,
                                    'parentId': tempLastIndex + 1,
                                    'childId': tempLastChildIndex + 1,
                                }
                                if (lists.criticality_statu[titledata.displauvalue.length].is_new == false) {
                                    staticArrayNew = {
                                        'compliance_id': lists.criticality_statu[titledata.displauvalue.length].compliance_id,
                                        'criticality_id': lists.criticality_statu[titledata.displauvalue.length].criticality_id,
                                        'remarks': lists.criticality_statu[titledata.displauvalue.length].remarks
                                    }
                                    tempStaticNew.push(staticArrayNew)
                                }
                                temp.push(entityArrayNew)

                                updateRemarks[lists.criticality_statu[titledata.displauvalue.length]] = lists.criticality_statu[titledata.displauvalue.length].remarks
                                if (lists.criticality_statu[titledata.displauvalue.length].is_new == true) {
                                    updateSelectValue[lists.criticality_statu[titledata.displauvalue.length]] = ''
                                } else {
                                    updateSelectValue[lists.criticality_statu[titledata.displauvalue.length]] = lists.criticality_statu[titledata.displauvalue.length].criticality_id === 1 ? 'A' : lists.criticality_statu[titledata.displauvalue.length].criticality_id === 2 ? 'B' : 'C'
                                }

                            }
                            tempLastChildIndex++;
                            setLastChildIndex(tempLastChildIndex)
                        }

                        // lists.criticality_statu && lists.criticality_statu.map((subMenuList, childIndex) => {
                        //     if (data === subMenuList.statutory_name) {
                        //         entityArrayNew = {
                        //             'compliance_id': subMenuList.compliance_id,
                        //             'compliance_task': subMenuList.compliance_task,
                        //             'criticality': subMenuList.criticality,
                        //             'criticality_id': subMenuList.criticality_id,
                        //             'frequency': subMenuList.frequency,
                        //             'is_new': subMenuList.is_new,
                        //             'penal_consequences': subMenuList.penal_consequences,
                        //             'remarks': subMenuList.remarks,
                        //             'statutory_id': subMenuList.statutory_id,
                        //             'statutory_mapping': subMenuList.statutory_mapping,
                        //             'statutory_name': subMenuList.statutory_name,
                        //             'statutory_provision': subMenuList.statutory_provision,
                        //             'unit_id': subMenuList.unit_id,
                        //             'parentId': setLastIndex(prevState => (prevState+1)),
                        //             'childId': childIndex,
                        //         }
                        //         temp.push(entityArrayNew)
                        //         updateRemarks[subMenuList.compliance_id] = subMenuList.remarks
                        //         updateSelectValue[subMenuList.compliance_id] = subMenuList.criticality_id === 1 ? 'A' : subMenuList.criticality_id === 2 ? 'B' : 'C'
                        //     }

                        // })

                    }

                    tempLastIndex++;
                    countrylisttempdata.push(temp)
                    finalStaticArr.push(tempStaticNew)
                    datavalue = {
                        index: tempLastIndex,
                        paraent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                    setLastIndex(tempLastIndex)
                })
            }
            var merged = [].concat.apply([], countrylisttempdata);
            var mergerdNew = [].concat.apply([], finalStaticArr);
            setTempStaticArray(mergerdNew)
            setData(merged)
            setUpdateRemarks([...updateRemarks])
            setUpdateSelectValue([...updateSelectValue])
            Settitledata({
                ...titledata,
                displauvalue: [...finalarray]
            })
            //getData()
        }


    }, [lists])

    useEffect(() => {

        if (tempState && tempState.length > 0) {
            setCriticalityList(tempState)
        }
    }, [tempState])

    useEffect(() => {
        if (collapseChange === 'collapse') {
            console.log('inserting in')
            if (listChild && listChild.length > 0) {
                let tempArray = []
                for (let i in listChild) {
                    setChildIndex(i)
                    if (tempState && tempState.length > 0) {
                        for (let j in tempState) {
                            if (listChild[i].compliance_id === tempState[j].compliance_id) {
                                tempState.splice(j, 1)
                                setTempState([...tempState, ...tempArray])
                            }
                        }
                    }
                    tempArray.push({
                        "compliance_id": listChild[i].compliance_id,
                        "criticality_id": updateSelectValue[listChild[i].compliance_id] === 'A' ? 1 : updateSelectValue[listChild[i].compliance_id] === 'B' ? 2 : 3,
                        "remarks": listChild[i].criticality === updateSelectValue[listChild[i].compliance_id] ? '' : updateRemarks[listChild[i].compliance_id]
                    })
                }
                setTempState([...tempState, ...tempArray])
            }
        }
    }, [updateSelectValue, updateRemarks])

    useEffect(() => {
        if (finalArray) {
            const index = finalArray.map((item) => {
                return item.index
            })
            setIndexValues(index.join().split(','))
        }
    }, [finalArray])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Transaction</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span style={{ color: '#1890ff' }}>Criticality Settings</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body" style={{ padding: "10px" }}>
                                        <div className="row">

                                            {show === false ?
                                                <>
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label><b>Business Group: </b></label>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                        <div className='row'>
                                                            <div className='col-md-6'>

                                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                    <div className="form-group">
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Select Business Group"
                                                                            onChange={(data, value) => {
                                                                                if (data !== undefined) {
                                                                                    let filteredEntity = _.filter(sessionArr, { bg_id: Number(data) })
                                                                                    setFinallegalentity(filteredEntity)
                                                                                    SetSettings({
                                                                                        ...Settings,
                                                                                        le_id: '',
                                                                                        domain_id: '',
                                                                                        statutory_nature: 'All',
                                                                                        bg_id: data
                                                                                    })
                                                                                    setCurrentEntity()
                                                                                }
                                                                                else {
                                                                                    setFinallegalentity([])
                                                                                    SetSettings({
                                                                                        ...Settings,
                                                                                        le_id: '',
                                                                                        domain_id: '',
                                                                                        bg_id: '',
                                                                                        statutory_nature: 'All'
                                                                                    })
                                                                                    setCurrentEntity()
                                                                                }
                                                                            }}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            value={Settings.bg_id || undefined}
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                                if (item.bg_name !== null) {
                                                                                    return (
                                                                                        <Option key={item.bg_id}>
                                                                                            {item.bg_name}
                                                                                        </Option>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </Select>
                                                                    </div> :
                                                                    <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                                                }
                                                            </div>
                                                            <div className="col-md-6">

                                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                    <div className="form-group">
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Select Legal Entity"
                                                                            onChange={(data, value) => {
                                                                                if (data !== undefined) {
                                                                                    setCurrentEntity(data)
                                                                                    SetSettings({
                                                                                        ...Settings,
                                                                                        le_id: data,
                                                                                        domain_id: '',
                                                                                        statutory_nature: 'All'
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    setCurrentEntity('')
                                                                                    SetSettings({
                                                                                        ...Settings,
                                                                                        le_id: '',
                                                                                        domain_id: '',
                                                                                        statutory_nature: 'All'
                                                                                    })
                                                                                }
                                                                            }}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            value={Settings.le_id || undefined}
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            {finallegalEntity && finallegalEntity.length > 0 ? finallegalEntity.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.le_id}>
                                                                                        {item.le_name}
                                                                                    </Option>
                                                                                );
                                                                            }) : sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.le_id}>
                                                                                        {item.le_name}
                                                                                    </Option>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                        {validator.current.message(
                                                                            'le_id',
                                                                            Settings.le_id,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Legal Entity Required',
                                                                                }
                                                                            })}
                                                                    </div> :
                                                                    <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                                                }

                                                            </div>
                                                        </div>
                                                    </div><br />

                                                    <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label><b>Domain: </b><span style={{ color: "red" }}>*</span></label>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label><b>Statutory Nature: </b></label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                        <div className='row'>
                                                            <div className="col-md-6">

                                                                <Select
                                                                    disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                                    size="default"
                                                                    placeholder="Select Domain"
                                                                    onChange={(data) => {
                                                                        if (data !== undefined) {
                                                                            let statutory = _.filter(filterdatas.nature_list, { domain_id: Number(data) })
                                                                            setStatutorydata(statutory)
                                                                            SetSettings({
                                                                                ...Settings,
                                                                                domain_id: data
                                                                            })
                                                                        }
                                                                        else {
                                                                            setStatutorydata([])
                                                                            SetSettings({
                                                                                ...Settings,
                                                                                domain_id: ''
                                                                            })
                                                                        }
                                                                    }}
                                                                    showSearch
                                                                    allowClear={true}
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%' }}
                                                                    value={Settings.domain_id || undefined}
                                                                >
                                                                    {filterdatas && filterdatas.domains_list.length > 0 && filterdatas.domains_list.map((item) => {
                                                                        return <Option value={item.d_id}>{item.d_name}</Option>
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'view only restricted',
                                                                    Settings.domain_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Domain Required ',
                                                                        }
                                                                    })}
                                                            </div>
                                                            <div className="col-md-6">

                                                                <Select
                                                                    // defaultValue='All'
                                                                    style={{ width: '100%' }}
                                                                    value={Settings.statutory_nature || undefined}
                                                                    placeholder="Select Statutory Nature"
                                                                    onChange={(data) => {
                                                                        SetSettings({
                                                                            ...Settings,
                                                                            statutory_nature: data
                                                                        })
                                                                        let CategoryData = _.filter(filterdatas && filterdatas.nature_list, { domain_id: Number(data) })
                                                                        setCategory(CategoryData)
                                                                    }}>
                                                                    <Option key='All'>All</Option>
                                                                    {Settings.domain_id ?
                                                                        statutorydata.map((item) => {
                                                                            return (
                                                                                <Option key={item.statutory_nature}>{item.statutory_nature}</Option>
                                                                            )
                                                                        })

                                                                        :
                                                                        ''
                                                                    }
                                                                    {/* {Settings && Settings.statutory_nature.length > 0 && Settings.statutory_nature.map((item) => {
                                                                            return <Option value={item.domain_id}>{item.statutory_nature}</Option>
                                                                        })} */}
                                                                </Select>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-lg-6 col-md-5 d-none d-md-flex align-items-center justify-content-end"> */}
                                                    {/* <Link className="btn btn-primary btn-md" onClick={showresult} style={{ float: "right" }}>Show</Link> */}
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-4' style={{ marginLeft: '36%' }}>
                                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "90px", marginTop: '35px' }}
                                                                    size='default' onClick={showresult}>
                                                                    Show
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* </div> */}

                                                </>
                                                :
                                                <div>
                                                    <>
                                                        <div>
                                                            {/* <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-md-3"></div>
                                                                    <div className="col-md-3"></div>
                                                                    <div className="col-md-3"></div>
                                                                    <div className="col-md-3">
                                                                        <Button
                                                                            type="primary"
                                                                            shape="round"
                                                                            className='addbutton'
                                                                            style={{
                                                                                marginBottom: "20px",
                                                                                background: "#0096FF",
                                                                                borderColor: "#0096FF",
                                                                                // marginRight: "10px"
                                                                                float: 'right'
                                                                            }}
                                                                            icon={<ArrowLeftOutlined />
                                                                            }
                                                                            size='default'
                                                                            onClick={() => {
                                                                                setShow(false)
                                                                            }}
                                                                        >
                                                                            Previous
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <Collapse defaultActiveKey={["0"]} >
                                                                {finalArray && finalArray.length > 0 && finalArray.map((List, i) => {
                                                                    return <Panel header={List.paraent} key={i} i
                                                                        extra={genextra(List.index, List)}
                                                                    >
                                                                        {/* <DataTable
                                                                            columns={columns}
                                                                            data={List.child}
                                                                        /> */}
                                                                        <Table
                                                                            size={'small'}
                                                                            // className='userprivclass'
                                                                            columns={columns}
                                                                            dataSource={List.child}
                                                                            bordered
                                                                            scroll={{ x: 1500 }}
                                                                            pagination={false} />

                                                                    </Panel>
                                                                })}

                                                            </Collapse>
                                                            <div className='row mt-3'>
                                                                <div className='col-md-12'>
                                                                    {/* <label style={{ marginLeft: "28px" }}>Showing <span>{checkdata.length}</span>  entries</label> */}
                                                                    <label style={{ marginLeft: "28px" }}>Showing 1 to {checkdata.length} of {totalValue} entries</label>
                                                                    <p style={{ marginLeft: "28px", color: '#0000FF' }}> * Compliance criticality to be assigned are marked in blue color</p>
                                                                </div>
                                                                <div className='row maringBottom'>
                                                                    <div className='col-md-4'  >
                                                                        <Button
                                                                            type="primary"
                                                                            shape="round"
                                                                            className='addbutton'
                                                                            style={{
                                                                                float: 'left',
                                                                                background: "#0096FF",
                                                                                borderColor: "#0096FF",

                                                                            }}
                                                                            icon={<ArrowLeftOutlined />
                                                                            }
                                                                            size='default'
                                                                            onClick={() => {
                                                                                setShow(false)
                                                                                setCheckdata([])
                                                                                setArrayLength()
                                                                                setTotalValue(0)
                                                                                Settitledata({
                                                                                    ...titledata,
                                                                                    displauvalue: []
                                                                                })
                                                                                setFinalArray([])
                                                                            }}
                                                                        >
                                                                            Previous
                                                                        </Button>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        {checkdata.length != count ?
                                                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '40%' }}
                                                                                size='default'
                                                                                onClick={showMoreresult}
                                                                            >Show More
                                                                            </Button>
                                                                            :
                                                                            ''

                                                                        }
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        {checkdata.length != count ?
                                                                            ''
                                                                            :
                                                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#198754", float: 'right', marginBottom: '50px' }}
                                                                                icon={<PlayCircleOutlined />} size='default'
                                                                                onClick={addform}
                                                                            >Submit
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div><br /><br />
                                                        </div></>


                                                    {/* <div className="col-lg-6 col-md-5 d-none d-md-flex align-items-center justify-content-end"> */}
                                                    {/* <button className="btn btn-primary btn-md" onClick={addform} style={{ float: 'right' }}>Submit</button> */}
                                                    {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right' }}
                                                            size='default' onClick={addform}>
                                                            Submit
                                                        </Button> */}
                                                    {/* </div> */}

                                                </div>

                                            }




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>
            {/* <Modal title="Domain" visible={domainModal} onOk={handleOk} onCancel={handleCancel}>
                <Search
                    placeholder="Search"
                    onSearch={onSearch}
                    style={{
                        width: 200,
                    }}
                />
            </Modal> */}
            <Modal title="Legal Entity" visible={legal} onOk={handleOk} onCancel={handleCancel}>
                <Search
                    placeholder="Search"
                // onSearch={onSearch}
                // style={{
                //     width: 200,
                // }}
                />
            </Modal>
            {/* <Modal title="Business Group" visible={business} onOk={handleOk} onCancel={handleCancel}>
                <Search
                    placeholder="Search"
                 onSearch={onSearch}
                 style={{
                     width: 200,
                 }}
                />
            </Modal> */}
        </Fragment>

    )
}

export default connect(mapStateToProps, {
    settingsfilterlist,
    settingslist,
    addsettings
})(CriticalitySetting);