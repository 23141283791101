import React from 'react'
import { Collapse, Select, Input, DatePicker, Card, Drawer, Button, Table, Progress, Modal, Pagination } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, Fragment } from 'react';
import { DoubleLeftOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { getFilterValues, getUnitData, getTaskwiseReportData, getTaskWiseExportData } from '../../../Store/Action/Report/TaskWiseReport';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import moment from 'moment';
const mapStateToProps = (state) => ({
    filterValue: state.TaskWiseReport.TaskWiseReports
})
const TaskWiseReport = (({
    getFilterValues,
    filterValue,
    getTaskwiseReportData,
    getTaskWiseExportData,
    getUnitData
}) => {
    const _ = require("lodash");
    let [index, setIndex] = useState(0)
    const [totalCount, setTotal] = useState(50)
    const validator = useRef(new SimpleReactValidator());
    console.log(filterValue, 'filterValue');
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    console.log(paginationArray, 'paginationArray');
    const [countrydata, setCountryData] = useState(null)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [statkey, setStatKey] = useState("0")
    const [key, setKey] = useState("1")
    const [titledata, Settitledata] = useState([])
    console.log(titledata, 'titledata');
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [exportButton, setExportButton] = useState(false)
    const [consolidatedData, setConsolidatedData] = useState({
        country: "",
        category: "",
        user_type: "All",
        legel_entity: "",
        entity_name: '',
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "",
        compliance_task_status: "All",
        division: "",
        domainName: "",
        countryName: '',
        complianceName: ""

    })

    console.log(consolidatedData, 'consolidatedData');
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [domain, setDomain] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [division, setDivision] = useState([])
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 7, 8, 9, 10, 31, 34])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ComplianceTask, setComplianceTask] = useState([])
    const [Act, setAct] = useState([])
    const [Acts, setActs] = useState([])
    console.log(Acts, 'Acts');
    const [complianceTaskStatus, setcomplianceTaskStatus] = useState([])
    const [user, setuser] = useState([])
    const [userType, setUserType] = useState([])
    const [frequency, setfrequency] = useState([])
    const [category, setCategoryList] = useState([])
    const [disableButton, setDisableButton] = useState(false)
    const [unitcode, setUnitcode] = useState([])
    const [units, setUnits] = useState([])
    const { Panel } = Collapse;
    const { Option } = Select;

    console.log(filterValue.TaskWiseReportList, 'filterValue.TaskWiseReportList');
    console.log(filterValue, 'filterValue');

    useEffect(() => {

        if (filterValue && filterValue.TaskWiseReportList && filterValue.TaskWiseReportList.task_compliances && filterValue.TaskWiseReportList.task_compliances.length > 0) {

            var array = filterValue && filterValue.TaskWiseReportList.task_compliances.slice((current - 1) * pageSize, (current * pageSize))
            console.log(array, 'array');
            setPaginationArray(array)
        }
        else {
            setPaginationArray([])
        }

    }, [filterValue && filterValue.TaskWiseReportList.task_compliances, pageSize, current])


    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };

    const handleOk = () => {
        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            // setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    // "request": [
                    //     "GetRiskReportData",
                    //     {
                    //         "country_id": Number(consolidatedData.country),
                    //         // "business_group_id": Number(businessGroup.bg_id),
                    //         "legal_entity_id": entityid,
                    //         "domain_id": Number(consolidatedData.domain),
                    //         "division_id": Number(consolidatedData.division),
                    //         "category_id": Number(consolidatedData.category) ? Number(consolidatedData.category) : 0,
                    //         "unit_id": Number(consolidatedData.unit) ? Number(consolidatedData.unit) : 0,
                    //         "parent_id": Number(consolidatedData.act) ? Number(consolidatedData.act) : 0,
                    //         "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                    //         "task_status": consolidatedData.task_status ? consolidatedData.task_status : 'All Exposed Risk',
                    //         "csv": true,
                    //         "from_count": 0,
                    //         "page_count": 25,
                    //         "list_check": checkboxValue
                    //     }
                    // ]
                    "request": [
                        "GetTaskWiseReport",
                        {
                            "country_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            // "statutory_mapping": consolidatedData.act,
                            // "comp_tsk_id": Number(consolidatedData.compliance_task),
                            "statutory_mapping": consolidatedData.act,
                            "comp_tsk_id": consolidatedData.compliance_task ? Number(consolidatedData.compliance_task) : 0,
                            "user_type": consolidatedData.user_type ? consolidatedData.user_type : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": consolidatedData.from_date ? moment(consolidatedData.from_date).format('DD-MMM-YYYY') : "",
                            "due_to_date": consolidatedData.to_date ? moment(consolidatedData.to_date._d).format('DD-MMM-YYYY') : "",
                            "task_status": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            getTaskWiseExportData({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
    };

    console.log(checkboxValue, 'checkboxValue');

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // const disablecondition = (e) => {
    //     var currentToDate = moment(consolidatedData.from_date).add(3, 'months')
    //     console.log(currentToDate, 'currentToDate')
    //     if (currentToDate < moment(e)) {
    //         setDisableButton(true)
    //     } else {
    //         setDisableButton(false)
    //     }
    // }

    const onshow = () => {
        setAddFormSubmit(true)
        // Settitledata([])
        setfilteredArrayState([])
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTaskWiseReport",
                        {
                            "country_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            // "statutory_mapping": "The Payment of Gratuity Act, 1973  >>  The Kerala Payment of Gratuity Rules, 1973",
                            // "comp_tsk_id": 184,
                            "statutory_mapping": consolidatedData.act,
                            "comp_tsk_id": consolidatedData.compliance_task ? Number(consolidatedData.compliance_task) : 0,
                            "user_type": consolidatedData.user_type ? consolidatedData.user_type : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": consolidatedData.from_date ? moment(consolidatedData.from_date).format('DD-MMM-YYYY') : "",
                            "due_to_date": consolidatedData.to_date ? moment(consolidatedData.to_date._d).format('DD-MMM-YYYY') : "",
                            "task_status": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": 0
                        }
                    ]
                }
            ]
            getTaskwiseReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }
    // useEffect(() => {
    //     if (pageState == true) {
    //         const payload = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,

    //                 "request": [
    //                     "GetTaskWiseReport",
    //                     {
    //                         "country_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
    //                         "legal_entity_id": entityid,
    //                         "domain_id": Number(consolidatedData.domain),
    //                         "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
    //                         "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
    //                         "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
    //                         // "statutory_mapping": "The Payment of Gratuity Act, 1973  >>  The Kerala Payment of Gratuity Rules, 1973",
    //                         // "comp_tsk_id": 184,
    //                         "statutory_mapping": consolidatedData.act,
    //                         "comp_tsk_id": consolidatedData.compliance_task ? Number(consolidatedData.compliance_task) : 0,
    //                         "user_type": consolidatedData.user_type ? consolidatedData.user_type : null,
    //                         "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
    //                         "due_from_date": consolidatedData.from_date ? moment(consolidatedData.from_date).format('DD-MMM-YYYY') : "",
    //                         "due_to_date": consolidatedData.to_date ? moment(consolidatedData.to_date._d).format('DD-MMM-YYYY') : "",
    //                         "task_status": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
    //                         "csv": false,
    //                         "from_count": Math.max(((current - 1) * pageSize), 0) + 1,
    //                         "page_count": current != 0 ? current * pageSize : pageSize,
    //                         "list_check": 0
    //                     }
    //                 ]



    //             }

    //         ]
    //         getTaskwiseReportData({
    //             payload: payload,
    //             paramid: paramid
    //         })
    //     }
    // }, [current, pageSize, pageState])
    useEffect(() => {
        // if (filterValue && filterValue.TaskWiseReportList && filterValue.TaskWiseReportList.task_compliances && filterValue.TaskWiseReportList.task_compliances.length > 0) {
        if (filterValue.TaskWiseReportList.task_compliances && filterValue.TaskWiseReportList.task_compliances.length > 0) {
            let uniqueObjArray = [...new Map(filterValue && filterValue.TaskWiseReportList && filterValue.TaskWiseReportList.task_compliances && filterValue.TaskWiseReportList.task_compliances.length > 0 && filterValue.TaskWiseReportList.task_compliances.map((item) => [item["unit_id"], item])).values()];
            console.log(uniqueObjArray, 'uniqueObjArray');
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(filterValue && filterValue.TaskWiseReportList && filterValue.TaskWiseReportList.task_compliances.length && filterValue.TaskWiseReportList.task_compliances, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [filterValue && filterValue.TaskWiseReportList.task_compliances])

    console.log(filterValue,'filterValue');

    const onClose = () => {
        setOpen(false);
    };
    const ComplianceTaskOnChange = (data, value) => {
        if (data !== undefined) {
            setConsolidatedData({
                ...consolidatedData,
                compliance_task: data,
                complianceName: value.children
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTaskWiseActReportFilters",
                        {
                            "country_id": sessionArr[0].c_id,
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidatedData.domain),
                            "comp_tsk_id": Number(data)
                        }
                    ]
                }
            ]
            getUnitData({
                payload: payload,
                paramid: paramid
            })
        }
        else {
            setConsolidatedData({
                ...consolidatedData,
                compliance_task: '',
                complianceName: ''
            })
        }
    }

    console.log(entityid, 'entityid');

    useEffect(() => {
        if (filteredArray.length) {
            let temp = []
            for (let i in filteredArray) {
                console.log(filteredArray[i], 'filteredArray[i]');
                temp = filteredArray[i]
            }
            setfilteredArrayState([...filteredArrayState, ...temp])
        }

    }, [filteredArray])
    console.log(open, 'open');
    console.log(userType, 'userTypeuserType');
    console.log(filteredArray, 'filterArray');
    console.log(Acts, 'Acts');
    const genextra = () => {
        return <div onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}>

            <Button type='primary' onClick={() => {
                setOpen(true)
            }}>
                <i class="bi bi-chevron-double-left"></i>
            </Button>


        </div>
    }


    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidatedData.legel_entity)
        }
    }, [entityid])

    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])

    console.log(paginationArray, 'paginationArray');


    useEffect(() => {
        if (isAuth) {
            if (entityid !== '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetTaskWiseReportFilters",
                            {
                                "country_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? Number(consolidatedData.country) : sessionArr[0].c_id,
                                "legal_entity_id": entityid
                            }
                        ]
                    }
                ]
                if (entityid != 'null') {
                    getFilterValues({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
        }
    }, [isAuth, authtoken, paramid, entityid])
    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (paginationArray && paginationArray.length > 0) {
            const ab = paginationArray && paginationArray.length && paginationArray.map(function (item) {
                if ((array.indexOf(item.act_name) < 0))
                    array.push(item.act_name)
            })

            console.log(array, 'array');
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.length > 0 && paginationArray.map((child, childIndex) => {
                        if (data === child.act_name) {
                            entityArray = {
                                "act_name": child.act_name,
                                "activity_on": child.activity_on,
                                "activity_status": child.activity_status,
                                "category_name": child.category_name,
                                "completion_date": child.completion_date,
                                "compliance_activity_id": child.compliance_activity_id,
                                "compliance_description": child.compliance_description,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_id": child.compliance_id,
                                "compliance_name": child.compliance_name,
                                "criticality_name": child.criticality_name,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "frequency_name": child.frequency_name,
                                "history_count": child.history_count,
                                "interim_count": child.interim_count,
                                "legal_entity_id": child.legal_entity_id,
                                "start_date": child.start_date,
                                "task_group_name": child.task_group_name,
                                "task_status": child.task_status,
                                "task_sub_group_name": child.task_sub_group_name,
                                "tz_name": child.tz_name,
                                "unit": child.unit,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "uploaded_document": child.uploaded_document,
                                "user_name": child.user_name,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [paginationArray])

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        console.log(ele, 'eleeeee');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                console.log(ele[i].value, 'ele[i].value');
                if (ele[i].type == 'checkbox') {
                    console.log(ele[i].type, 'ele[i].typeele[i].type');
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            console.log(tempAllIds, 'tempAllIds');
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElsetActsementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([])
        }
    }
    useEffect(() => {
        if (filterValue) {
            setUserType(filterValue && filterValue.unitData && filterValue.unitData.compliance_user_type)
            setComplianceTask(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list)
            setDomain(filterValue && filterValue.Data && filterValue.Data.domains)
            let uniqueObjArray = [...new Map(filterValue && filterValue.Data && filterValue.Data.tsk_act_legal_entity && filterValue.Data.tsk_act_legal_entity.length > 0 && filterValue.Data.tsk_act_legal_entity.map((item) => [item["statutory_mapping"], item])).values()];
            setAct(uniqueObjArray)
            setfrequency(filterValue && filterValue.Data && filterValue.Data.compliance_frequency)
            setCategoryList(filterValue && filterValue.unitData && filterValue.unitData.categories_list)
            setcomplianceTaskStatus(filterValue && filterValue.unitData && filterValue.unitData.compliance_task_status)
            setUnitcode(filterValue && filterValue.Data && filterValue.Data.unit_code_label_list)
            setUnits(filterValue && filterValue.unitData && filterValue.unitData.tsk_unit_legal_entity)
            setDivision(filterValue && filterValue.Data && filterValue.Data.divisions_list)
            setuser(filterValue && filterValue.unitData && filterValue.unitData.tsk_compliance_users)
        }


    }, [filterValue])

    console.log(user,'user');
    console.log(filterValue && filterValue.unitData && filterValue.unitData.tsk_compliance_users,'filterValue && filterValue.unitData && filterValue.unitData.tsk_compliance_users');
    console.log(filterValue,'filterValue');

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])


    console.log(finalResult, "finalResult");
    console.log(consolidatedData, 'consolidatedData');
    console.log(category, 'category');
    console.log(titledata, 'titledata');
    console.log(filterValue, 'filterValue');

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-0 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Task Wise Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-body"id='only-restricted' style={{ position: 'relative', padding: "15px 5px" }}>

                            <Collapse className='report-collapse' style={{ backgroundColor: '#5daae3'  }} accordion defaultActiveKey={["1"]} onChange={onChange}>
                                <Panel header="Task Wise Report" key={key} >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>


                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <div className="form-group">

                                                        <Select
                                                            // showSearch
                                                            // optionFilterProp="children"
                                                            // disabled={unit.legal_entity === "" ? true : false}
                                                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            value={consolidatedData.country || undefined}
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    console.log(value, 'valuevalue');
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        country: data,
                                                                        countryName: value.children,
                                                                        category: "",
                                                                        user_type: "All",
                                                                        legel_entity: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: ""
                                                                    })
                                                                }
                                                                else {
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        country: '',
                                                                        countryName: '',
                                                                        category: "",
                                                                        user_type: "All",
                                                                        legel_entity: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: ""
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            // value={compfie.user_Group}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                        >
                                                            {/* {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })} */}
                                                            {country_info && country_info.length && country_info.map((item, i) => {

                                                                return (

                                                                    <Option key={item.c_id}>

                                                                        {item.c_name}

                                                                    </Option>

                                                                );

                                                            })}
                                                        </Select>

                                                        {validator.current.message(
                                                            'Country',
                                                            consolidatedData.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{sessionArr && sessionArr.length && sessionArr[0].c_name}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Division : </b></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    disabled={consolidatedData.compliance_task == '' ? true : false}
                                                    value={consolidatedData.division || undefined}
                                                    placeholder="Enter Division"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            console.log(data, 'datadata');
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                division: data
                                                            })
                                                            let categorydata = _.filter(category, { div_id: Number(data) })
                                                            setCategoryList(categorydata)
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                division: ''
                                                            })

                                                            setCategoryList([])
                                                        }
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>

                                            <div className='col-md-4'>
                                                <label><b>User : </b></label>
                                                <Select
                                                    value={consolidatedData.user || undefined}
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    allowClear={true}
                                                    disabled={consolidatedData.act == '' ? true : false}
                                                    size="default"
                                                    placeholder="Enter User"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {user && user.length && user.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_id}>
                                                                {item.employee_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity :  </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <div className="form-group">

                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            value={consolidatedData.legel_entity || undefined}
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(data, value) => {
                                                                console.log(value, 'value');
                                                                // setCurrentEntity(data)
                                                                if (data !== undefined) {
                                                                    setConsolidatedData({

                                                                        ...consolidatedData,
                                                                        legel_entity: data,
                                                                        entity_name: value.children,
                                                                        category: "",
                                                                        user_type: "All",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: ""
                                                                    })
                                                                    setCurrentEntity(data)
                                                                }
                                                                else {
                                                                    setConsolidatedData({

                                                                        ...consolidatedData,
                                                                        legel_entity: '',
                                                                        entity_name: '',
                                                                        category: "",
                                                                        user_type: "All",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: ""
                                                                    })
                                                                    setCurrentEntity('')
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            disabled={consolidatedData.country == '' ? true : false}

                                                        >
                                                            [
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}


                                                        </Select>

                                                        {validator.current.message(
                                                            'legalEntity',
                                                            consolidatedData.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }

                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Category : </b></label>
                                                <Select
                                                    value={consolidatedData.category || undefined}
                                                    disabled={consolidatedData.compliance_task == '' ? true : false}
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter category"

                                                    onChange={(data) => {
                                                        if (data !== undefined) {

                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                category: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                category: ''
                                                            })
                                                        }
                                                        // let unitData = _.filter(units && units.length > 0 && units, { category_id: Number(data) })
                                                        // setUnits(unitData)
                                                    }
                                                    }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {category && category.length && category.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>From Date : </b> </label>
                                                <DatePicker format={'DD-MMM-YYYY'} allowClear={false} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}

                                                    value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                                    onChange={(date, dateString) => {

                                                        console.log(dateString, 'dateString');
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            from_date: dateString,
                                                            to_date: moment(dateString).add(3, 'months')
                                                        })

                                                    }}

                                                />

                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                {/* <Select
                                                        onChange={(value) => {
                                                            setConsolidatedDate({
                                                                ...consolidatedData,
                                                                domain: value
                                                            })
                                                        }}
                                                        placeholder="Enter Domain Name"
                                                        style={{ width: '280px' }}
                                                    >
                                                        <Option key="India">India</Option>
                                                    </Select> */}
                                                <Select
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    allowClear={true}
                                                    disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && consolidatedData.legel_entity == '') ? true : false}
                                                    size="default"
                                                    value={consolidatedData.domain || undefined}
                                                    placeholder="Enter Domain"
                                                    onChange={(data, value) => {
                                                        if (data !== undefined) {
                                                            console.log(data, 'data');
                                                            let filteredAct = _.filter(Act, { domain_id: Number(data) })
                                                            console.log(filteredAct, 'filteredAct');
                                                            setActs(filteredAct)
                                                            let filteredComplianceStatus = _.filter(filterValue && filterValue.unitData && filterValue.unitData.compliance_task_status, { domain_id: Number(data) })
                                                            setComplianceTask(filteredComplianceStatus)
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                domain: data,
                                                                domainName: value.children,
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                complianceName: ""
                                                            })
                                                        }
                                                        else {

                                                            setActs([])
                                                            setComplianceTask([])
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                domain: '',
                                                                domainName: '',
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                complianceName: ""
                                                            })
                                                        }
                                                        // let domainData = _.filter(taskCategory && taskCategory.length > 0 && taskCategory, { task_domain_id: Number(data) })
                                                        // setaskCategory(domainData)
                                                    }

                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {domain && domain.length && domain.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidatedData.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Unit : </b></label>
                                                <Select
                                                    value={consolidatedData.unit || undefined}
                                                    disabled={consolidatedData.compliance_task == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Unit"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                unit: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                unit: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {units && units.length > 0 && units.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {`${item.unit_code}-${item.unit_name}`}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date : </b> </label>
                                                <DatePicker format={'DD-MMM-YYYY'} allowClear={false} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}

                                                    onChange={(date, dateString) => {
                                                        console.log(dateString, 'dateString');
                                                        // disablecondition(dateString)
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            to_date: dateString
                                                        })
                                                    }}
                                                    value={consolidatedData.to_date || undefined}
                                                    disabledDate={(current) => {
                                                        return moment(consolidatedData.from_date).add(1, 'days') >= current ||
                                                            moment(consolidatedData.from_date).add(1, 'year') < current;
                                                    }}
                                                />

                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            {/* <div className='col-md-4'>
                                                <label>Task Category</label>
                                                <Select
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter Domain"
                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            task_category: data
                                                        })
                                                        let subData = _.filter(SubtaskCategory && SubtaskCategory.length > 0 && SubtaskCategory, { task_category_id: Number(data) })
                                                        setSubtaskCategory(subData)
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%' }}
                                                >
                                                    {taskCategory && taskCategory.length > 0 && taskCategory.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_category_id}>
                                                                {item.task_category_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div> */}
                                            <div className='col-md-4'>

                                                <label><b>Act/Rules : </b><span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    value={consolidatedData.act || undefined}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Act"
                                                    onChange={(data, value) => {
                                                        if (data !== undefined) {
                                                            // let filteredActObj = _.find(filterValue && filterValue.Data && filterValue.Data.tsk_act_legal_entity, { compliance_id: Number(data) })
                                                            // console.log(filteredActObj, 'filteredActObj');
                                                            console.log(data, 'datadata');
                                                            let filteredComplianceStatus = _.filter(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list, { statutory_mapping: data })
                                                            setComplianceTask(filteredComplianceStatus)
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                act: value.children,
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                complianceName: ""
                                                            })
                                                            // let compliancedata = _.filter(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list,{})
                                                            // setComplianceTask(compliancedata)
                                                        }
                                                        else {
                                                            setComplianceTask([])
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                act: '',
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                complianceName: ""
                                                            })
                                                            setComplianceTask([])
                                                        }
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {Acts && Acts.length > 0 && Acts.map((item, i) => {
                                                        return (
                                                            <Option key={item.statutory_mapping}>
                                                                {item.statutory_mapping}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>

                                                {validator.current.message(
                                                    'Act',
                                                    consolidatedData.act,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Act Required',
                                                        }
                                                    })}
                                            </div>

                                            <div className='col-md-4'>
                                                <label><b>User Type : </b></label>
                                                <Select
                                                    value={consolidatedData.user_type || undefined}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter UserType"

                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user_type: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user_type: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {userType && userType.length && userType.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_type}>
                                                                {item.user_type}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task Status : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.compliance_task_status || undefined}
                                                    onChange={(value) => {
                                                        if (value !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                compliance_task_status: value
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                compliance_task_status: ''
                                                            })
                                                        }
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_status}>
                                                                {item.task_status}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task : </b><span style={{ color: "red" }}>*</span></label>
                                                {/* <Input placeholder="Enter Compliance Task" style={{ width: '280px' }}
                                                // onChange={(e) => {
                                                //     setConsolidatedDate({
                                                //         ...consolidatedData,
                                                //         compliance_task: e.target.value
                                                //     })
                                                // }}
                                                /> */}
                                                <Select
                                                    allowClear={true}
                                                    disabled={consolidatedData.act == '' ? true : false}
                                                    value={consolidatedData.compliance_task || undefined}
                                                    size="default"
                                                    placeholder="Enter Compliance Task"
                                                    // onChange={(data) => {
                                                    //     setConsolidatedData({
                                                    //         ...consolidatedData,
                                                    //         task_category: data
                                                    //     })
                                                    //     let subData = _.filter(SubtaskCategory && SubtaskCategory.length > 0 && SubtaskCategory, { task_category_id: Number(data) })
                                                    //     setSubtaskCategory(subData)
                                                    // }
                                                    // }
                                                    onChange={(data, value) => {
                                                        ComplianceTaskOnChange(data, value)
                                                    }}
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {ComplianceTask && ComplianceTask.length > 0 && ComplianceTask.map((item, i) => {
                                                        return (
                                                            <Option key={item.compliance_id}>
                                                                {item.compliance_task}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidatedData.compliance_task,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Compliance Task Required',
                                                        }
                                                    })}



                                            </div>



                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='row'>



                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>

                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px", width: "100px" }} onClick={() => { onshow() }}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852", width: "100px" }} onClick={showModal}
                                                >
                                                    Export
                                                </Button>

                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div> */}
                                         <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                                <Button disabled={disableButton} type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "70px", marginRight: "10px" }} onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852" }}
                                                    onClick={showModal}>
                                                    Export
                                                </Button>

                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>

                                </Panel>
                            </Collapse>
                            {tableshow == true ? <Collapse className='report-collapse' defaultActiveKey={["1"]} onChange={onStatChange} style={{ marginTop: '1%' ,backgroundColor: '#5daae3'}} >
                                <Panel header="Overall Statistics" key={statkey}>
                                    <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                        <div className="row m-2">
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={30} width={90} format={(percent) => `${30}`} />
                                                <h4>Complied</h4>
                                            </div>
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={20} width={90} status="exception" format={(percent) => `${10}`} />
                                                <h4>Delayed Complied</h4>

                                            </div>
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={100} width={90} format={(percent) => `${120}`} />
                                                <h4>Inprogress</h4>

                                            </div>
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={80} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${50}`} />
                                                <h4>Not Complied</h4>

                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse> : false}
                            {/* <Drawer title="Basic Drawer" width={400} placement="right" visible={open} onClose={onClose}>
                                <Card>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>Country : <span style={{ fontWeight: 'bold' }}> {consolidatedData.country}</span> </label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Category : <span style={{ fontWeight: 'bold' }}>{consolidatedData.category}</span></label>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>User Type :<span style={{ fontWeight: 'bold' }}> {consolidatedData.user_type}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Legal Entity :<span style={{ fontWeight: 'bold' }}> {consolidatedData.legel_entity}</span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>Unit :<span style={{ fontWeight: 'bold' }}>{consolidatedData.unit}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>User : <span style={{ fontWeight: 'bold' }}>{consolidatedData.user}</span></label>
                                            </div>



                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <label>Domain:<span style={{ fontWeight: 'bold' }}> {consolidatedData.domain}</span></label>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Act : <span style={{ fontWeight: 'bold' }}>{consolidatedData.act}</span></label>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <label>Task Category : <span style={{ fontWeight: 'bold' }}>{consolidatedData.task_category}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Compliance Task : <span style={{ fontWeight: 'bold' }}>{consolidatedData.compliance_task}</span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <label>To Date :<span style={{ fontWeight: 'bold' }}> {consolidatedData.to_date}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>From Date : <span style={{ fontWeight: 'bold' }}>{consolidatedData.from_date}</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>Task Sub Category :<span style={{ fontWeight: 'bold' }}> {consolidatedData.task_sub_category}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Compliance Frequency : <span style={{ fontWeight: 'bold' }}>{consolidatedData.compliance_frequency}</span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <label>Compliance Task Status : <span style={{ fontWeight: 'bold' }}>{consolidatedData.compliance_task_status}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Division : <span style={{ fontWeight: 'bold' }}>{consolidatedData.division}</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Drawer> */}


                            {tableshow == true ?
                                <>

                                    {/* <h2 style={{ textAlign: 'center' }}>Consolidated Report</h2> */}
                                    <div className='col-md-12 mt-1' >
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ marginLeft: '2%' }}><b>Legal Entity :</b>{localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidatedData.entity_name : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ marginLeft: '10%' }}><b>Country :</b>{sessionArr && sessionArr[0].c_name}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain :</b>{consolidatedData.domainName}</label>
                                            </div>


                                        </div>
                                        <div className="col-md-12 mt-1">
                                            <div className="row">
                                                <div className='col-md-4 mt-1'>
                                                    <label style={{ marginLeft: '2%' }}><b>Act :</b>{consolidatedData.act}</label>
                                                </div>
                                                <div className="col-md-4"></div>
                                                <div className='col-md-4 mt-1'>
                                                    <label><b>Compliance Task </b>:{consolidatedData.complianceName}</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div >
                                        <div className='ant-table ant-table-bordered  ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content userprivclass1'>

                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className="ant-table-thead antable-head" >
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: '20px' }}>#</th>

                                                                <th className='ant-table-cell' style={{ width: '100px' }}>Frequency</th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}><center>Due Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}>Compliance Task Status</th>
                                                                <th className='ant-table-cell'><center>User Name</center></th>
                                                                <th className='ant-table-cell' style={{ width: '150px' }}><center>Activity Status</center></th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>Activity Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}><center>Uploaded Document</center></th>
                                                                <th className='ant-table-cell' style={{ width: '150px' }}><center>Task Completion Date/Document issued Date</center></th>
                                                            </tr>
                                                        </thead>
                                                        {filteredArrayState && filteredArrayState.length > 0 ?
                                                            filteredArrayState.map((item, i) => {
                                                                console.log(item, 'item');

                                                                return (
                                                                    <tbody className='ant-table-tbody'>
                                                                        <tr className="bg-lightdark">
                                                                            <td colSpan="100%">
                                                                                <table className='white'>
                                                                                    <tbody className='ant-table-tbody'><tr className='ant-table-row ant-table-row-level-0'>
                                                                                        <td className='ant-table-cell w6'><b>Unit :</b></td>
                                                                                        <td className='ant-table-cell w15'>{item[0].unit_name}</td>
                                                                                        <td className='ant-table-cell w6'><b></b></td>
                                                                                        <td className='ant-table-cell w10'><span className="division-name"></span></td>
                                                                                        <td className='ant-table-cell w6'><b>Category</b></td>
                                                                                        <td className='ant-table-cell w40'><span className="category-name">{item[0].category_name}</span></td>
                                                                                    </tr>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className='ant-table-cell w6'><b>Division :</b></td>
                                                                                            <td className='ant-table-cell w15' >{item[0].division_name}</td>
                                                                                            <td className='ant-table-cell w6'><b></b></td>
                                                                                            <td className='ant-table-cell w10'><span className="division-name"></span></td>
                                                                                            <td className='ant-table-cell w6'><b>Time Zone</b></td>
                                                                                            <td className='ant-table-cell w40'><span className="category-name">{item[0].tz_name}</span></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                        {titledata && titledata.length > 0 &&
                                                                            titledata.map((itemdata, i) => {
                                                                                console.log(itemdata, 'itemdata');
                                                                                console.log(itemdata.child, 'itemdata.child');
                                                                                let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                                console.log(data, 'datadatadatadata');
                                                                                if (data != undefined) {
                                                                                    if (data.act_name == itemdata.parent) {
                                                                                        return <Fragment>

                                                                                            <tr className='ant-table-row ant-table-row-level-0'>

                                                                                                <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                            </tr>

                                                                                            {itemdata && itemdata.child && itemdata.child.length > 0 && itemdata.child.map((items) => {
                                                                                                if (item[0].unit_id == items.unit_id) {
                                                                                                    return <tr>
                                                                                                        <td className='ant-table-cell'>
                                                                                                            {index + 1}


                                                                                                        </td>


                                                                                                        <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                        <td className='ant-table-cell'><center>{items.task_status}</center></td>
                                                                                                        <td className='ant-table-cell'><center>{items.assignee_name}</center></td>
                                                                                                        <td className='ant-table-cell'><center>{items.activity_status}</center></td>
                                                                                                        <td className='ant-table-cell'><center>{items.activity_date}</center></td>
                                                                                                        <td ><center>-</center></td>
                                                                                                        <td ><center>-</center></td>

                                                                                                        <span hidden>{index = index + 1}</span>

                                                                                                    </tr>
                                                                                                }

                                                                                            })

                                                                                            }
                                                                                        </Fragment>
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                )
                                                            }) :
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                            </tr>
                                                        }
                                                    </table>
                                                    {/* <br /> */}
                                                    <div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {index !== 0 ?
                                        <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                            <label style={{ marginLeft: "28px" }}>Showing {pageSize * (current - 1) + 1} to {index} of {filterValue && filterValue.count} entries</label>
                                        </div> : false}
                                    <div style={{ position: 'absolute', bottom: '-25px', right: 0 }}>
                                        <Pagination
                                            current={current}
                                            showSizeChanger={filterValue && filterValue.count > 25 ? false : true}
                                            // showQuickJumper={true}
                                            onShowSizeChange={() => {
                                                setCurrent(1)
                                                // setpageState(true)
                                            }}
                                            pageSizeOptions={[25, 50, 100]}
                                            hideOnSinglePage={true}
                                            defaultPageSize={25}
                                            onChange={(page, pageSizes) => {
                                                setpageState(true)
                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                setPageSize(pageSizes)
                                            }} total={filterValue && filterValue.count} />

                                    </div>
                                </>
                                : false}
                            {/* <Modal title="Select Fields" visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                                <div id="form">
                                    <input type="hidden" class="popup_mode" autocomplete="off" />
                                    <div id="form-list">
                                        <div class="portlet-body p-t-0">
                                            <div class="table-responsive">
                                                <table id="datatable-responsive2" class="table table-striped dt-responsive nowrap" cellspacing="0" width="50%">
                                                    <thead>
                                                        <tr class="custom-modal-title">
                                                            <th>
                                                                <input id="select_all" width="100%" type="checkbox" autocomplete="off" onClick={(e) => {
                                                                    let checked = e.target.checked
                                                                    selectAllCheckBox(checked)
                                                                }} /> Select All
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody id="tbody-form-list"><tr><th>
                                                        <input id="column_1" name='chk' type="checkbox" disabled="disabled" value='1' checked onChange={(e) => (e) => checkBoxOnChange(e)} /> Unit Code</th>
                                                        <th><input id="column_2" name='chk' type="checkbox" disabled="disabled" value='2' checkedonChange={(e) => checkBoxOnChange(e)} /> Unit Name</th></tr>
                                                        <tr><th><input id="column_3" name='chk' type="checkbox" value='3' onChange={(e) => checkBoxOnChange(e)} />Unit Code 1 </th>
                                                            <th><input id="column_4" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} />Unit Code 2 </th></tr><tr><th>
                                                                <input id="column_5" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} />  Unit Code 3</th><th>
                                                                <input id="column_6" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 4</th></tr>
                                                        <tr><th><input id="column_7" name='chk' type="checkbox" value='7' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} />Location</th>
                                                            <th><input id="column_8" name='chk' type="checkbox" disabled="disabled" value='8' checked onChange={(e) => checkBoxOnChange(e)} />Act / Rules </th></tr>
                                                        <tr><th><input id="column_9" name='chk' type="checkbox" disabled="disabled" value='9' checked onChange={(e) => checkBoxOnChange(e)} />Compliance Task </th>
                                                            <th><input id="column_10" name='chk' type="checkbox" disabled="disabled" value='10' checked onChange={(e) => checkBoxOnChange(e)} />Frequency </th></tr>
                                                        <tr><th><input id="column_11" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /> Periodicity </th>
                                                            <th><input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} />Criticality</th></tr>
                                                        <tr><th><input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Division </th>
                                                            <th><input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} />Category</th></tr>
                                                        <tr><th><input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} />Assigned By</th>
                                                            <th><input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assigned To </th></tr>
                                                        <tr><th><input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} />Assigned Date </th>
                                                            <th><input id="column_18" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} />Assignee</th></tr>
                                                        <tr><th><input id="column_19" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Completed On</th>
                                                            <th><input id="column_20" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} />Concurrer </th></tr>
                                                        <tr><th><input id="column_21" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} />Concurrer On </th>
                                                            <th><input id="column_22" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} />Approver </th></tr>
                                                        <tr><th><input id="column_23" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approver On </th>
                                                            <th><input id="column_24" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Activity Status </th></tr>
                                                        <tr><th><input id="column_25" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} />Start Date</th>
                                                            <th><input id="column_26" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} />Due Date</th></tr>
                                                        <tr><th><input id="column_27" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} />Extend Due Date </th>
                                                            <th><input id="column_28" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} />Task Completion Date /Document Issued Date</th></tr>
                                                        <tr><th><input id="column_29" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} />Month </th>
                                                            <th><input id="column_30" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> Validity Date</th></tr>
                                                        <tr><th><input id="column_31" name='chk' type="checkbox" value='31' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /> Statutory Status </th>
                                                            <th><input id="column_32" name='chk' type="checkbox" value='32' onChange={(e) => checkBoxOnChange(e)} /> Duration</th></tr>
                                                        <tr><th><input id="column_31" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number </th>
                                                            <th><input id="column_32" name='chk' type="checkbox" value='34' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /> Remarks</th></tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions">
                                    <Button type="primary" shape="round" className='addbutton'
                                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '40%' }}
                                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                    </Button>
                                </div>
                            </Modal> */}
                            <Modal title="Select Fields" visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                                <div className='row'>
                                    <div className='col-lg-3'><input id="select_all" width="100%" type="checkbox" autocomplete="off"
                                        checked={checkboxValue.length === 34 ? true : false}
                                        onClick={(e) => {
                                            let checked = e.target.checked
                                            selectAllCheckBox(checked)
                                        }} /> Select All</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> Unit Code</div>
                                    <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /> Unit Name</div>
                                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='3' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 1</div>
                                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2</div>

                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2 </div>
                                    <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2 </div>
                                    <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" value='7' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} />Act / Rules</div>
                                    <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" value='8' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Frequency</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='9' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Task Category</div>
                                    <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='10' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Task sub Category </div>
                                    <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /> Periodicity </div>
                                    <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Criticality</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Division</div>
                                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Category</div>
                                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Assigned By</div>
                                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assignee To</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date</div>
                                    <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assignee</div>
                                    <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Completed On</div>
                                    <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Concurrer </div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurrer On </div>
                                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Approver </div>
                                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approver On </div>
                                    <div className='col-lg-3'><input id="column_25" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Activity Status</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_26" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Start Date</div>
                                    <div className='col-lg-3'><input id="column_27" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Due Date</div>
                                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} />Extend Due Date </div>
                                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date / Document Issued Date </div>

                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /> Month</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> Validity Date</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='31' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Statutory Status</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='32' onChange={(e) => checkBoxOnChange(e)} /> Duration</div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='34' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Remarks</div>

                                </div>
                                <div className="form-actions">
                                    <Button type="primary" shape="round" className='addbutton'
                                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '40%' }}
                                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                    </Button>
                                </div>

                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
})
export default connect(mapStateToProps, {
    getFilterValues,
    getUnitData,
    getTaskwiseReportData,
    getTaskWiseExportData
})(TaskWiseReport);