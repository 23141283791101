import { TASK_CATEGORY_REPORT,GET_TABLE_DATA,TOGGLE_COMMON_LOADER } from '../../types/index'

const initialState = {
    taskCategoryReport: {
        data: [],
        getTableData:[]
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TASK_CATEGORY_REPORT: {
            console.log("HIIIIII");
            console.log(payload, "payloadred");
            return {
                ...state,
                taskCategoryReport: {
                    ...state.taskCategoryReport,
                    data:payload
                },
            };
        }
        case GET_TABLE_DATA: {
            console.log("HIIIIII");
            console.log(payload, "payloadred");
            return {
                ...state,
                taskCategoryReport: {
                    ...state.taskCategoryReport,
                    getTableData:payload
                },
            };
        }
        default:
            return state;
    }
}
