import { Collapse, Select, Card, Drawer, Button, DatePicker, Tooltip, Pagination } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { MonthReportData, MonthReportTable, MonthReportExport } from "../../../Store/Action/Report/CV_MonthWiseStatusReport"
import { connect, useDispatch } from 'react-redux';
import { EyeOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { ExclamationCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CV_MONTH_WISE_REPORT_EXPORT_RESET } from './../../../Store/types/index'


const mapStateToProps = (state) => ({
    reportlist: state.CV_MonthWiseStatusReport.monthwisestatusreport
})
const MonthWiseStatusReport = ({
    MonthReportData,
    reportlist,
    MonthReportTable,
    MonthReportExport
}) => {
    console.log(reportlist, 'reportlist');
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [domain, setDomain] = useState([])
    const [unit, setUnit] = useState([])
    const [check, setCheck] = useState([])
    const [currentYear, setCurrentYear] = useState(moment().format('YYYY'))
    const [categorylist, setCategoryList] = useState([])
    const [division, setDivision] = useState([])
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const [open, setOpen] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    const [countrydata, setCountryData] = useState(null)
    const dispatch = useDispatch();
    const [ids, setIds] = useState({
        d_id: null,
        c_id: null
    })
    const [titledata, Settitledata] = useState(undefined)
    let [index, setindex] = useState(0)
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [getreportlist, setGetReportList] = useState([])
    const [tabledata, setTableData] = useState([])
    const [totaldata, setTotalData] = useState({
        domainlist: [],
        divisionlist: [],
        categorylist: [],
        unitlist: []
    })
    const [monthwisereport, setMonthWiseReport] = useState({
        country: "",
        legel_entity: "",
        domain: "",
        division: "",
        category: "",
        unit: "",
        period: "",
        domain_id: null,
        division_id: null,
        category_id: null,
        unit_id: null,
        from_month: '',
        to_month: ''
    })
    const [monthlyArray, setMonthlyArray] = useState([])

    useEffect(() => {
        if (reportlist.export && reportlist.export.link) {
            window.open('https://13.234.100.64/' + reportlist.export.link);
            dispatch({
                type: CV_MONTH_WISE_REPORT_EXPORT_RESET
            });
        }

    }, [reportlist.export])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (reportlist.tabledata) {
            setTableData(reportlist && reportlist.tabledata && reportlist.tabledata.monthly_compliances)
        }
    }, [reportlist.tabledata])

    useEffect(() => {
        if (reportlist && reportlist.tabledata && reportlist.tabledata.monthly_compliances) {
            var array = reportlist && reportlist.tabledata && reportlist.tabledata.monthly_compliances.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }

    }, [reportlist && reportlist.tabledata && reportlist.tabledata.monthly_compliances, pageSize, current])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (monthwisereport.division_id) {
            const divid = totaldata.divisionlist.filter((item) => {
                return item.div_id === monthwisereport.division_id
            })
        }
    }, [monthwisereport])

    useEffect(() => {
        if (isAuth && entityid != "null") {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceWiseReportFilters",
                        {
                            "country_id": countrydata && countrydata[0].c_id,
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            MonthReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth, authtoken, paramid, entityid, countrydata])

    useEffect(() => {
        if (reportlist.compliancewisereport) {
            setGetReportList(reportlist && reportlist.compliancewisereport)
            let Domaindata = _.filter(reportlist && reportlist.compliancewisereport.domains, { le_id: entityid })
            setTotalData({
                ...totaldata,
                domainlist: Domaindata
            })
        }
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {
            setMonthWiseReport({
                ...monthwisereport,
                country: countrydata && countrydata[0].c_id,
                legel_entity: localStorage.getItem('SelectedEntity')
            })
        }
        if (monthwisereport.period === "") {
            setMonthWiseReport({
                ...monthwisereport,
                period: "All",
            })
        }
    }, [reportlist && reportlist.compliancewisereport, entityid, countrydata])

    useEffect(() => {
        if (totaldata) {
            setCategoryList(reportlist && reportlist.compliancewisereport.categories_list)
            setDivision(reportlist && reportlist.compliancewisereport.divisions_list)
            setDomain(reportlist && reportlist.compliancewisereport.domains)
            setUnit(reportlist && reportlist.compliancewisereport.unit_legal_entity)
        }
    }, [totaldata])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tabledata) {
            const ab = paginationArray && paginationArray.map(function (item) {
                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.map((child, childIndex) => {
                        if (data === child.statutory_mapping) {
                            entityArray = {
                                "Criticality": child.Criticality,
                                "ac_id": child.ac_id,
                                "category_name": child.category_name,
                                "compliance_description": child.compliance_description,
                                "compliance_task": child.compliance_task,
                                "division_name": child.division_name,
                                "due_date": child.due_date,
                                "months": child.months,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [paginationArray])

    useEffect(() => {
        if (tabledata) {
            let uniqueObjArray = [...new Map(tabledata && tabledata.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tabledata && tabledata, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [tabledata])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        let monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (moment().format('MMM') == "Jan") {
            const index = monthArray.findIndex(element => {
                if (element == moment().format('MMM')) {
                    return true;
                }
            })
            let currentYear = moment().format('YYYY') - 1
            monthArray = monthArray.filter((month, idx) => idx < index)
            let tempMonthArray = [];
            for (let i in monthArray) {
                tempMonthArray.push(monthArray[i] + '-' + currentYear)
            }
            setMonthlyArray(tempMonthArray)
        } else {
            let temp = false
            let temparray = []
            for (let i in monthArray) {
                if (temp) {
                    temparray.push(monthArray[i])
                } else {
                    if (monthArray[i] == moment(monthwisereport.from_month).format('MMM')) {
                        temparray.push(monthArray[i])
                        temp = true
                    }
                }
            }
            let check = []
            const index = temparray.findIndex(element => {
                check.push(element)
                // if (element == moment(monthwisereport.from_month).format('MMM')) {
                if (element == moment(monthwisereport.to_month).format('MMM')) {
                    return true;
                }
                // }
            })
            setCheck(check)
            // console.log(check,'check');
            let currentYear = moment().format('YYYY');
            temparray = temparray.filter((month, idx) => idx <= index)
            let tempMonthArray = [];
            for (let i in temparray) {
                tempMonthArray.push(temparray[i] + '-' + currentYear)
            }
            setMonthlyArray(tempMonthArray)
            // let check = tempMonthArray.split('-')
            // console.log(check[0],'check[0]');
        }

    }, [monthwisereport.to_month])

    useEffect(() => {
        if (pageState === true) {
            setindex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const columns = [
        {
            title: 'Compliance Ttask',
            dataIndex: 'compliance_task',
            key: 'compliance_task',
            width: "300px",
            ellipsis: true,
        },
        {
            title: 'Jan-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'January' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
            // render: (record) => record && record.months === 'January' ? record &&record.task_status : "-"
        },
        {
            title: 'Feb-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'February' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'February' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Mar-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'March' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'March' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Apr-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'April' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'April' ? record && record.task_status : "-"}</p>
                    </>
                )
            }

        },
        {
            title: 'May-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'May' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'May' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Jun-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'June' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Jul-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'July' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'July' ? record && record.task_status : "-"}</p>
                    </>
                )
            }

        },
        {
            title: 'Aug-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'August' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'August' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Sep-2022',
            dataIndex: 'comp_name',
            key: 'comp_name',
            width: "80px",
            ellipsis: true,
            // render: (record) => record && record.months === 'September' ? record && record.task_status : "-"
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.months === 'September' ? record && record.task_status : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Next Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            width: "100px",
            ellipsis: true,
        }
    ]

    console.log(index, 'indexindex');

    const onChange = (key) => {
        // console.log(key);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceWiseReport",
                        {
                            "country_id": countrydata && countrydata[0].c_id,
                            "legal_entity_id": entityid,
                            "domain_id": Number(monthwisereport.domain_id),
                            "division_id": monthwisereport.division_id ? Number(monthwisereport.division_id) : 0,
                            "category_id": monthwisereport.category_id ? Number(monthwisereport.category_id) : 0,
                            "unit_id": monthwisereport.unit_id ? Number(monthwisereport.unit_id) : 0,
                            // "months": monthwisereport.period,
                            "from_date": moment(monthwisereport.from_month).format("MMMM-YYYY"),
                            "to_date": moment(monthwisereport.to_month).format("MMMM-YYYY"),
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0
                        }
                    ]
                }
            ]
            MonthReportTable({
                payload: payload1,
                paramid: paramid
            })
        }
    }

    const onexport = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(false)
            setKey(0)
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceWiseReport",
                        {
                            "country_id": countrydata && countrydata[0].c_id,
                            "legal_entity_id": entityid,
                            "domain_id": Number(monthwisereport.domain_id),
                            "division_id": monthwisereport.division_id ? Number(monthwisereport.division_id) : 0,
                            "category_id": monthwisereport.category_id ? Number(monthwisereport.category_id) : 0,
                            "unit_id": monthwisereport.unit_id ? Number(monthwisereport.unit_id) : 0,
                            // "months": monthwisereport.period,
                            "from_date": moment(monthwisereport.from_month).format("MMMM-YYYY"),
                            "to_date": moment(monthwisereport.to_month).format("MMMM-YYYY"),
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0
                        }
                    ]

                }
            ]
            MonthReportExport({
                payload: payload2,
                paramid: paramid
            })
        }
    }

    const genextra = () => {
        return <div onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}>
            {/* <Button type='primary' onClick={() => {
                setOpen(true)
            }}> */}
            {/* <EyeOutlined size='default' onClick={() => {
                setOpen(true)
            }} /> */}
            {/* </Button> */}
        </div>
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ color: '#1890ff' }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ color: '#1890ff' }}>Compliance Reports</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ color: '#1890ff' }}>Month Wise Status Report</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" style={{ position: 'relative', padding: "15px 5px" }}>
                                {/* <div className="card-body" style={{ padding: 0 }}> */}
                                <Collapse defaultActiveKey={["1"]} onChange={onChange}  >
                                    <Panel header="Month Wise Status Report" key={key} extra={tableshow == true ? genextra() : false}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <Select
                                                            placeholder="Enter Country"
                                                            onChange={(value) => {
                                                                setMonthWiseReport({
                                                                    ...monthwisereport,
                                                                    country: value
                                                                })
                                                            }}
                                                            style={{ width: '100%', marginTop: '5px' }}

                                                            value={monthwisereport.country || undefined}
                                                            showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    
                                                        >
                                                            {countrydata && countrydata.length && countrydata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        : <p>{sessionArr[0].c_name}</p>}
                                                    {validator.current.message(
                                                        'country',
                                                        monthwisereport.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Required',
                                                            }
                                                        })}

                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division : </b></label>
                                                    <Select
                                                        disabled={monthwisereport.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            let category = _.filter(reportlist && reportlist.compliancewisereport.categories_list, { div_id: Number(value) })
                                                            setCategoryList(category)
                                                            let unit = _.filter(reportlist && reportlist.compliancewisereport.unit_legal_entity, { division_id: Number(value) })
                                                            setUnit(unit)
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                division_id: value,
                                                                division: data.children,
                                                                category_id: null,
                                                                category: '',
                                                                unit: '',
                                                                unit_id: null
                                                            })
                                                        }}
                                                        placeholder="Enter Division Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={monthwisereport.division || undefined}
                                                    >
                                                        {division && division.length > 0 && division.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>From Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker style={{ width: '100%', marginTop: '5px' }}
                                                        disabled={monthwisereport.domain == "" ? true : false}
                                                        allowClear={false} picker="month"
                                                        // disabledDate={(current) => {
                                                        //     return moment(unitdata.from_date) >= current || current > Date.now();
                                                        // }}
                                                        // disabledDate={(current) => {
                                                        //     console.log(moment(current).format('YYYY'),'currentcurrent');
                                                        //     return (current && current < moment(currentYear, 'YYYY')) || (current && current > moment(currentYear, 'YYYY'))
                                                        //     return moment().format('YYYY') <= current 
                                                        //     ||
                                                        //     currentYear.add(1, 'year') <= current;
                                                        // }}
                                                        value={monthwisereport.from_month ? moment(monthwisereport.from_month) : ''}
                                                        onChange={(date, dateString) => {
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                from_month: dateString,
                                                                to_month: moment(dateString).add(2, 'months')
                                                            })
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'from_month',
                                                        monthwisereport.from_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'From Month Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity  : </b><span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <Select
                                                            placeholder='Enter Legal Entity'
                                                            onChange={(value, data) => {
                                                                setCurrentEntity(value)
                                                                setMonthWiseReport({
                                                                    ...monthwisereport,
                                                                    legel_entity: data.children,
                                                                    domain: "",
                                                                    division: "",
                                                                    category: "",
                                                                    unit: '',
                                                                    domain_id: null,
                                                                    division_id: null,
                                                                    category_id: null,
                                                                    unit_id: null,
                                                                    from_month: '',
                                                                    to_month: ''
                                                                })
                                                            }}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={monthwisereport.legel_entity || undefined}
                                                            disabled={monthwisereport.country == "" ? true : false}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}</Select>
                                                        : <p>{localStorage.getItem('SelectedEntity')}</p>}

                                                    {validator.current.message(
                                                        'legalentity',
                                                        monthwisereport.legel_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entiy Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Category : </b></label>
                                                    <Select
                                                        disabled={monthwisereport.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            let unit = _.filter(reportlist && reportlist.compliancewisereport.unit_legal_entity, { category_id: Number(value) })
                                                            setUnit(unit)
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                category_id: value,
                                                                category: data.children,
                                                                unit: '',
                                                                unit_id: null
                                                            })
                                                        }}
                                                        placeholder="Enter Category Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={monthwisereport.category || undefined}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        {categorylist && categorylist.length > 0 && categorylist.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>To Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker style={{ width: '100%', marginTop: '5px' }}
                                                        disabled={monthwisereport.domain == "" ? true : false}
                                                        allowClear={false} picker="month"
                                                        value={monthwisereport.to_month ? moment(monthwisereport.to_month) : ''}
                                                        onChange={(date, dateString) => {
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                to_month: dateString
                                                            })
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'to_month',
                                                        monthwisereport.to_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Month Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        onChange={(value, data) => {
                                                            let division = _.filter(reportlist && reportlist.compliancewisereport.divisions_list, { le_id: entityid })
                                                            setDivision(division)
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                domain_id: value,
                                                                domain: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={monthwisereport.domain || undefined}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && monthwisereport.legel_entity == '') ? true : false}
                                                    >
                                                        {domain && domain.length > 0 && domain.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        monthwisereport.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Vendor : </b></label>
                                                    <Select
                                                        disabled={monthwisereport.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                unit_id: value,
                                                                unit: `${data.children[0]}-${data.children[2]}`
                                                            })
                                                        }}
                                                        placeholder="Enter Vendor"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={monthwisereport.unit || undefined}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        {unit && unit.length > 0 && unit.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_code} - {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                {/* <div className='col-md-4'>
                                                    <label><b>Period : </b></label>
                                                    <Select
                                                        defaultValue="All"
                                                        onChange={(value) => {
                                                            setMonthWiseReport({
                                                                ...monthwisereport,
                                                                period: value
                                                            })
                                                        }}
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={monthwisereport.period || undefined}
                                                    >
                                                        <Option key="All">All</Option>
                                                        {monthlyArray && monthlyArray.length > 0 && monthlyArray.map((item) => {
                                                            return (
                                                                <Option key={item}>{item}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4'>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px", width: "100px", marginTop: '5px' }} onClick={onshow}
                                                    >
                                                        Show
                                                    </Button>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852", width: "100px", marginTop: '5px' }} onClick={onexport}
                                                    >
                                                        Export
                                                    </Button>
                                                    {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Drawer title="Month Wise Status Report" width={400} placement="right" visible={open} onClose={onClose}>
                                    <Card style={{ padding: 0 }}>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Country : <span style={{ fontWeight: 'bold' }}> {monthwisereport.country}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Legal Entity : <span style={{ fontWeight: 'bold' }}>{monthwisereport.legel_entity}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Domain : <span style={{ fontWeight: 'bold' }}> {monthwisereport.domain}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Division : <span style={{ fontWeight: 'bold' }}>{monthwisereport.division}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0' >
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Category : <span style={{ fontWeight: 'bold' }}> {monthwisereport.category}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Vendor : <span style={{ fontWeight: 'bold' }}>{monthwisereport.unit}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Period : <span style={{ fontWeight: 'bold' }}> {monthwisereport.period}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Drawer>
                                {tableshow == true ?

                                    filteredArrayState.length > 0 ?
                                        // <Card>
                                        <>
                                            <>
                                                {/* <h2 style={{ textAlign: 'center' }}>Month Wise Status Report</h2>
                                                <div className='col-md-12' style={{ marginTop: "30px", marginBottom: "15px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label>Legal Entity : <span style={{ fontWeight: 'bold' }}>{monthwisereport.legel_entity}</span></label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Country : <span style={{ fontWeight: 'bold' }}>{monthwisereport.country}</span></label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Domain : <span style={{ fontWeight: 'bold' }}>{monthwisereport.domain}</span></label>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                {/* <Table
                                                    columns={columns}
                                                    scroll={{ x: 1500, Y:400 }}
                                                    dataSource={tabledata}
                                                    pagination
                                                /> */}

                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content fh userprivclass1'>
                                                                <h3 style={{ fontWeight: 'bold', textAlign: 'center', padding: '2%' }}>Month Wise Status Report</h3>
                                                                {console.log(countrydata.filter((item) => item.c_id == monthwisereport.country)[0].c_name, 'Country name')}
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <div className='p-1'>
                                                                        <label style={{ fontWeight: 'bold' }}>Legal Entity : <span style={{ fontWeight: 'bold' }}>{monthwisereport.legel_entity}</span></label>
                                                                    </div>
                                                                    <div className='p-1'>
                                                                        <label style={{ fontWeight: 'bold' }}>Country : <span style={{ fontWeight: 'bold' }}> {countrydata.filter((item) => item.c_id == monthwisereport.country)[0].c_name}</span> </label>
                                                                    </div>
                                                                    <div className='p-1'>
                                                                        <label style={{ fontWeight: 'bold' }}>Domain : <span style={{ fontWeight: 'bold' }}> {monthwisereport.domain}</span> </label>
                                                                    </div>
                                                                </div>
                                                                <table className='widthHeigh table-fixed'>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead' >
                                                                        <tr>
                                                                            <th className='ant-table-cell' style={{ width: '10px' }}> # </th>
                                                                            <th className='ant-table-cell' style={{ width: "120px" }}>Compliance Task</th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Criticality</center></th>
                                                                            {monthlyArray && monthlyArray.length > 0 && monthlyArray.map((item) => {
                                                                                return (
                                                                                    <th className='ant-table-cell' style={{ width: "90px" }}><center>{item}</center></th>
                                                                                )

                                                                            })}
                                                                            {/* <th className='ant-table-cell' style={{ width: "90px" }}><center>Jan-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Feb-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Mar-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Apr-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>May-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Jun-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }} ><center>Jul-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Aug-2022</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Sep-2022</center></th> */}
                                                                            <th className='ant-table-cell' style={{ width: "200px" }}><center>Next Due Date</center></th>
                                                                        </tr>
                                                                    </thead>
                                                                    {filteredArrayState && filteredArrayState.length && filteredArrayState.map((item) => {
                                                                        return (
                                                                            <tbody className='ant-table-tbody' style={{ padding: 0 }}>
                                                                                <tr className="bg-lightdark">
                                                                                    <td colSpan="100%" style={{ padding: 0 }}>
                                                                                        <table className='white' style={{ tableLayout: 'auto' }}>
                                                                                            <tbody className='ant-table-tbody' style={{ padding: "5px" }}>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td className='ant-table-cell w6'><b>Vendor :</b></td>
                                                                                                    <td className='ant-table-cell w15' title={item[0].unit_name}>{item[0].unit_name}</td>
                                                                                                    <td className='ant-table-cell w6'><b>Category :</b></td>
                                                                                                    <td className='ant-table-cell w40'><span className="category-name">{item[0].category_name}</span></td>
                                                                                                </tr>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td className='ant-table-cell w6'><b>Division :</b></td>
                                                                                                    <td className='ant-table-cell w15' >{item[0].division_name}</td>
                                                                                                    <td className='ant-table-cell w6'><b>Time Zone :</b></td>
                                                                                                    <td className='ant-table-cell w40'><span className="category-name">{item[0].tz_name}</span></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                {titledata && titledata.map((itemdata, i) => {
                                                                                    let data = _.find(itemdata.child, { unit_id: Number(item[0].unit_id) })
                                                                                    console.log(itemdata.child, 'itemdata.child');
                                                                                    if (data != undefined) {
                                                                                        if (data.statutory_mapping == itemdata.parent) {
                                                                                            let temparray = []

                                                                                            return <>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td colSpan={12} className='ant-table-cell'><b style={{ backgroundColor: "lightyellow" }}>{itemdata.parent}</b></td>
                                                                                                </tr>
                                                                                                {itemdata.child.map((items, i) => {
                                                                                                    if (Number(item[0].unit_id) == items.unit_id) {
                                                                                                        let monthData = items && items.months.split(',')
                                                                                                        let taskStatus = items && items.task_status.split(',')
                                                                                                        var newArray = monthData.map((e, i) => [e, taskStatus[i]]);
                                                                                                        let result = Object.fromEntries(newArray)
                                                                                                        return <tr>
                                                                                                            <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                            <td title={items.compliance_task} className='ant-table-cell ellipsis'><Tooltip title={items.compliance_description} >
                                                                                                                <ExclamationCircleTwoTone /> <span style={{ marginLeft: "10px" }}>{items.compliance_task} </span>
                                                                                                            </Tooltip> &nbsp;</td>
                                                                                                            <td className='ant-table-cell'><center>{items.Criticality}</center></td>
                                                                                                            {check.includes("Jan") ?
                                                                                                                <td className='ant-table-cell'><center>{result.hasOwnProperty("January") ? result.January : "-"}</center></td> : ''}
                                                                                                            {check.includes("Feb") ?
                                                                                                                <td className='ant-table-cell'><center>{result.hasOwnProperty("January") ? result.February : "-"}</center></td> : ''}
                                                                                                            {check.includes("Mar") ?
                                                                                                                <td className='ant-table-cell'><center>{result.hasOwnProperty("March") ? result.March : "-"}</center></td> : ''}
                                                                                                            {check.includes("Apr") ?
                                                                                                                <td className='ant-table-cell'><center>{result.hasOwnProperty("April") ? result.April : "-"}</center></td> : ''}
                                                                                                            {check.includes("May") ?
                                                                                                                <td className='ant-table-cell'><center>{result.hasOwnProperty("May") ? result.May : "-"}</center></td> : ''}
                                                                                                            {check.includes("Jun") ?
                                                                                                                <td className='ant-table-cell'><center>{result.hasOwnProperty("June") ? result.June : "-"}</center></td> : ''}
                                                                                                            {check.includes("Jul") ?
                                                                                                                <td className='ant-table-cell'><center>{result.hasOwnProperty("July") ? result.July : "-"}</center></td> : ''}
                                                                                                            {check.includes("Aug") ?
                                                                                                                <td className='ant-table-cell'><center>{result.hasOwnProperty("August") ? result.August : "-"}</center></td> : ''}
                                                                                                            {check.includes("Sep") ?
                                                                                                                <td className='ant-table-cell'><center>{result.hasOwnProperty("September") ? result.September : "-"}</center></td> : ''}
                                                                                                            {check.includes("Oct") ?
                                                                                                                <td className='ant-table-cell'><center>{result.hasOwnProperty("October") ? result.October : "-"}</center></td> : ''}
                                                                                                            <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                            <span hidden>{index = index + 1}</span>
                                                                                                            {/* <td ><center>-</center></td>
                                                                                                        <td ><center>-</center></td> */}

                                                                                                            {/* </tr> */}

                                                                                                        </tr>
                                                                                                        // }




                                                                                                    }

                                                                                                })

                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                    }
                                                                                })
                                                                                }
                                                                            </tbody>
                                                                        )
                                                                    })}
                                                                </table>
                                                                <br />

                                                                <div>
                                                                    {/* <button
                                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                                    onClick={submitrec}>Submit</button> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                            {index === 0 ? '' :
                                                <div className='col-md-12 mt-2' style={{ position: 'absolute', marginTop: '10px', bottom: '-25px', left: 0 }}>
                                                    <label style={{ marginLeft: "13px" }}>Showing {pageSize * (current - 1) + 1} to {index} of {reportlist && reportlist.tabledata && reportlist.tabledata.total_count} entries</label>
                                                </div>
                                            }
                                            <div style={{ position: 'absolute', bottom: '-25px', right: 0 }}>
                                                <Pagination
                                                    current={current}
                                                    showSizeChanger={true}
                                                    // showQuickJumper={true}
                                                    onShowSizeChange={() => {
                                                        setCurrent(1)
                                                        // setpageState(true)
                                                    }}
                                                    pageSizeOptions={[25, 50, 100]}
                                                    hideOnSinglePage={false}
                                                    defaultPageSize={25}
                                                    onChange={(page, pageSizes) => {
                                                        setpageState(true)
                                                        setCurrent(pageSize !== pageSizes ? 1 : page);
                                                        setPageSize(pageSizes)
                                                    }}
                                                    total={reportlist && reportlist.tabledata && reportlist.tabledata.total_count}
                                                // showTotal={(total) => `Showing ${reportlist && reportlist.tabledata && reportlist.tabledata.total_count} entries`}
                                                />
                                            </div>



                                        </>
                                        // </Card>


                                        : <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4> : ""}
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default connect(mapStateToProps, {
    MonthReportData,
    MonthReportTable,
    MonthReportExport
})(MonthWiseStatusReport);