import HTTP from '../../Libs/http'
import { Toaster } from '../../Libs/Toaster'
import url, { getUrl } from './../../Libs/URL'
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import { encode as base64_encode, decode as base64_decode } from 'base-64';
import { LOGINSUCCESS, TOGGLE_COMMON_LOADER, NOTIFICATIONS, ESCALATION, REMAINDER, MESSAGE, NOTIFICATIONSHOWALL, NOTIFICATIONMODAL, LOGINVENDOR, LOGINLITIGATION, LOGINCOMPFIEPRODUCT } from './../types/index';
import alertmessage from './../../Libs/Altermessage'
//const url = getUrl()

export const login = ({ payload, navigate, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const loginpayload = [
            payload.groupName, [
                'Login', {
                    'login_type': 'Web',
                    'password': payload.password,
                    'short_name': payload.groupName,
                    'username': payload.userName,

                }
            ]
        ]
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded
            },
        }
        const data1 = EncryptDecrypt.encryptdata(loginpayload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)


        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 0)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error('Invalid Credentials')
            // setTimeout(() => {
            //     window.location.reload(false);
            // }, 2000);
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'UserLoginSuccess') {
                dispatch({
                    type: LOGINSUCCESS,
                    payload: decryptData[1],
                });
                navigate.push('/home')
            } else if (decryptData[0] == 'InvalidCredentials') {

                Toaster.error("Invalid Credentials")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] === 'UserLocked') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Your account has been locked due to few unsuccessful login attempts,please try after some times.')
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


export const sessionLogout = ({ payload, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let token = localStorage.getItem('authToken');
        const loginpayload = [
            token, [
                "Logout",
                {
                    "session_token": token
                }
            ]
        ]
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded
            },
        }
        const data1 = EncryptDecrypt.encryptdata(loginpayload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'LogoutSuccess') {
                localStorage.clear();
                window.location.reload(false);
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Invalid Error")
            }
        }



    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
}

export const notifications = ({ payload, caller, executeCancel }) => async dispatch => {
    console.log(caller, "callercaller");
    try {
        let urlValue;
        if (localStorage.getItem('isVendor') == "true") {
            urlValue = `${url.VendorURL}`
        } else {
            urlValue = `${url.Login}`
        }
        console.log(urlValue, 'urlValue')
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: urlValue + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'GetStatutorySuccess') {
                dispatch({
                    type: NOTIFICATIONS,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Escalations = ({ payload, caller, executeCancel }) => async dispatch => {
    try {
        let urlValue;
        if (localStorage.getItem('isVendor') == "true") {
            urlValue = `${url.VendorURL}`
        } else {
            urlValue = `${url.Login}`
        }
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: urlValue + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'GetEscalationsSuccess') {
                dispatch({
                    type: ESCALATION,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const remainder = ({ payload, caller, executeCancel }) => async dispatch => {
    try {
        let urlValue;
        if (localStorage.getItem('isVendor') == "true") {
            urlValue = `${url.VendorURL}`
        } else {
            urlValue = `${url.Login}`
        }
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: urlValue + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'GetRemindersSuccess') {
                dispatch({
                    type: REMAINDER,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Message = ({ payload, caller, executeCancel }) => async dispatch => {
    try {
        let urlValue;
        if (localStorage.getItem('isVendor') == "true") {
            urlValue = `${url.VendorURL}`
        } else {
            urlValue = `${url.Login}`
        }
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: urlValue + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'GetMessagesSuccess') {
                dispatch({
                    type: MESSAGE,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const notificationshowall = ({ payload, caller, executeCancel }) => async dispatch => {
    try {
        let urlValue;
        if (localStorage.getItem('isVendor') == "true") {
            urlValue = `${url.VendorURL}`
        } else {
            urlValue = `${url.Login}`
        }
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: urlValue + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'GetRemindersSuccess' || decryptData[0] === 'GetEscalationsSuccess' || decryptData[0] === 'GetMessagesSuccess' || decryptData[0] === 'GetStatutorySuccess') {
                dispatch({
                    type: NOTIFICATIONSHOWALL,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const updatenotification = ({ payload, paramid, executeCancel }) => async dispatch => {
    try {
        let urlValue;
        if (localStorage.getItem('isVendor') == "true") {
            urlValue = `${url.VendorURL}`
        } else {
            urlValue = `${url.Login}`
        }
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': paramid
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: urlValue + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'UpdateNotificationStatusSuccess') {
                window.location.reload()
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const updatenotificationnew = ({ payload, paramid, executeCancel }) => async dispatch => {
    try {
        let urlValue;
        if (localStorage.getItem('isVendor') == "true") {
            urlValue = `${url.VendorURL}`
        } else {
            urlValue = `${url.Login}`
        }
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': paramid
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: urlValue + `/api/client_notifications`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'UpdateNotificationStatusSuccess' || decryptData[0] === 'UpdateStatutoryNotificationStatusSuccess') {
                dispatch({
                    type: NOTIFICATIONMODAL,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Loginvendor = ({ payload, executeCancel, paramid, navigate }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/login`,
            headers: head,
            executeCancel,
            body: data1
        })
        console.log(createRequest, "createRequest");
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData111222");
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: LOGINVENDOR,
                    payload: decryptData,
                });
                window.location.href = decryptData[1][0].Static[1].form_url
            }
        }
    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err);
    }
};

export const LoginCompfieproduct = ({ payload, executeCancel, paramid, navigate }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/login`,
            headers: head,
            executeCancel,
            body: data1
        })
        console.log(createRequest, "createRequest111");

        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptDataproduct");
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: LOGINCOMPFIEPRODUCT,
                    payload: decryptData,
                });
                window.location.href = decryptData[1][0].Static[1].form_url
            }
        }
    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err);
    }
};

export const LoginLitigation = ({ payload, executeCancel, paramid, navigate }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData[1][0].Static[1].form_url, 'staticMenu')
                dispatch({
                    type: LOGINLITIGATION,
                    payload: decryptData,
                });
                window.location.href = decryptData[1][0].Static[1].form_url
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err);
    }
};