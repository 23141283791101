import {COMPLIANCE_TREND_CHART,COMMON_DATA,COMPLIANCE_TREND_TABLE_DATA,TIME_ZONE_DATA,SHOW_MORE_TABLE_DATA } from './../../types/index'
const initialState = {
    complianceTrendChart: {
       chartData:[],
       time:0,
       Data:[],
       tableData:[],
       timeZoneData:[],
       showMoreData:[],
       chartData1:[]
       
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case COMPLIANCE_TREND_CHART: {
            console.log(payload,"payload4444");
            return {
                ...state,
                complianceTrendChart: {
                    ...state.complianceTrendChart,
                    chartData: payload,
                    time:payload.current_date,
                    chartData1:payload.compliance_data
                },
            };
        }
        case COMMON_DATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                complianceTrendChart: {
                    ...state.complianceTrendChart,
                    Data: payload,
                },
            };
        }
        case COMPLIANCE_TREND_TABLE_DATA:{
            return {
                ...state,
                complianceTrendChart: {
                    ...state.complianceTrendChart,
                    tableData: payload,
                },
            };
        }
        case TIME_ZONE_DATA:{
            return {
                ...state,
                complianceTrendChart: {
                    ...state.complianceTrendChart,
                    timeZoneData: payload,
                },
            };
        }
        case SHOW_MORE_TABLE_DATA:{
            return {
                ...state,
                complianceTrendChart: {
                    ...state.complianceTrendChart,
                    showMoreData: payload,
                },
            };
        }
      
      
        default:
            return state;
    }
}
