import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url,{getUrl} from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { MONTHLYCOMPLIANCEFILTER, MONTHLYCOMPLIANCETABLEDATA, TOGGLE_COMMON_LOADER } from './../../types/index'
import alertmessage from '../../../Libs/Altermessage';
import { toast } from 'react-toastify';
const urls = getUrl()

export const MonthlyComplianceFilterReport = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_reports`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
            console.log(decryptData,"decryptData123");

                dispatch({
                    type: MONTHLYCOMPLIANCEFILTER,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const MonthlyComplianceTable = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_reports`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData,"decryptDataatable");
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: MONTHLYCOMPLIANCETABLEDATA,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const MonthlyComplianceExport = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_reports`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                Toaster.success("Export process initiated. You will receive Messages once file generated.")
                //  dispatch({
                //      type: REVIEWSAVECOMPLIANCE,
                //      payload: decryptData[1],
                //  });
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);

            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const Downloadfile = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/files`,
            headers: head,
            executeCancel,
            body: data1,
            responseType: "blob" // important

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            // const response = HTTP.parseResponse(executeRequest)
            var blob = dataURItoBlob(executeRequest.data, 'application/octet-stream');
            console.log(blob, 'blobs')
            let fileReader = new FileReader();
            fileReader.readAsDataURL(blob)
            fileReader.onload = function (e) {
                console.log(e.target.result,'e.target.result')
                var link = document.createElement('a');
                link.href = 'https://13.234.100.64/'+e.target.result;
                link.setAttribute(
                    'download',
                );
                link.click();
                link.parentNode.removeChild(link);
            };
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

function dataURItoBlob(dataURI, contentType) {
    console.log(contentType, 'blobs')
    var str = dataURI.replace(/\s/g, '');
    var binary = base64_decode(str);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: contentType });
}
