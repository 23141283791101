import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { COMPLIANCE_TASK_DETAILS_DATA, COMPLIANCE_UPCOMING_TASK, COMPLIANCE_TASK_CALENDER_VIEW, COMPLIANCE_CURRENT_TASK, COMPLIANCE_FILTER_SHOW, COMPLIANCE_VIEW } from '../../types/index'


const initialState = {
    complianceTaskDetails: {
        compliancefilter: [],
        compliancefiltershow: [],
        currentCompliance: [],
        upcomingTask: []

    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case COMPLIANCE_TASK_DETAILS_DATA:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        compliancefilter: payload[1],
                    },
                };
            }
        case COMPLIANCE_TASK_CALENDER_VIEW:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        compliancefiltershow: payload[1],
                    },
                };
            }
        case COMPLIANCE_CURRENT_TASK:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        currentCompliance: payload[1],
                    },
                };
            }
        case COMPLIANCE_UPCOMING_TASK:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        upcomingTask: payload[1],
                    },
                };
            }
        default:
            return state;
    }
}