import {SETTINGSDATA,SETTINGSDATALIST,SETTINGSDATATABLE,SETTINGSAPPROVALDATA,TOGGLE_COMMON_LOADER } from './../types/index'
const initialState = {
    settingsdata: {
       getdata:[],
       getdatalist:[],
       tabledata:[],
       approvaldata:[]
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SETTINGSDATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                settingsdata: {
                    ...state.settingsdata,
                    getdata: payload,
                },
            };
        }
        case SETTINGSDATALIST: {
            console.log(payload,"payload4444");
            return {
                ...state,
                settingsdata: {
                    ...state.settingsdata,
                    getdatalist: payload,
                },
            };
        }
        case SETTINGSDATATABLE: {
            console.log(payload,"payload4444");
            return {
                ...state,
                settingsdata: {
                    ...state.settingsdata,
                    tabledata: payload,
                },
            };
        }
        case SETTINGSAPPROVALDATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                settingsdata: {
                    ...state.settingsdata,
                    approvaldata: payload,
                },
            };
        }
        default:
            return state;
    }
}
