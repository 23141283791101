import React, { Fragment, useState, useRef, useEffect } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Popover } from 'antd';
// import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { AssigneewiseComplianceFilters, AssigneeWiseCompliancesChart, AssigneeWiseCompliancesChartexport, AssigneeWiseComplianceDrillDown, AssigneewiseYearwiseCompliances } from '../../../src/Store/Action/Dashboard/Assigneewisecompliance';
import { connect, useDispatch } from 'react-redux';
import { RightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { ASSIGNEE_WISE_COMPLIANCE_EXPORT_RESET } from './../../../src/Store/types/index'

const mapStateToProps = (state) => ({
    domain_data: state.Assigneescorecard.assigneescoredata.domain_data,
    tableData: state.Assigneescorecard.assigneescoredata.getTableData,
    assigneewisecompliancefilter: state.AssigneeWiseCompliance.assigneewisecompliance,
})

const AssigneeWiseCompliance = ({
    AssigneewiseComplianceFilters,
    AssigneeWiseCompliancesChart,
    AssigneeWiseCompliancesChartexport,
    AssigneewiseYearwiseCompliances,
    AssigneeWiseComplianceDrillDown,
    assigneewisecompliancefilter: { assigneewisecompliancefilters, assigneewisecomplianceshow, assigneewisecompliancedrilldown, assigneeyearwisecompliance, assigneewisecomplianceexport }
}) => {
    const _ = require("lodash");
    const dispatch = useDispatch();
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    const [yearlyModalOpen, setYearlyModalOpen] = useState(false);
    const [historytab, setHistorytab] = useState(false);
    const [division, setDivision] = useState([])
    const [unit, setUnit] = useState([])
    console.log(unit, 'unitunitunitunit');
    const [assigneeName, setAssigneeName] = useState('')
    const [user, setUser] = useState([])
    // const paramid = location.pathname;
    let [count, setcount] = useState(0)
    let [historycount, setHistorycount] = useState(0)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    const [businessGroup, setBusinessGroup] = useState({
        bg_id: '',
        bg_name: ''
    })
    const [compliedmap, setCompliedmap] = useState([])
    const [delayedmap, setDelayedmap] = useState([])
    const [inprogressmap, setInprogressmap] = useState([])
    const [inprogressoverduemap, setInprogressoverduemap] = useState([])
    const [notcompliedmap, setNotcompliedmap] = useState([])
    const [assigneewisecompliance, setAssigneewisecompliance] = useState({
        country: '',
        countryName: '',
        bg: '',
        bgname: '',
        division: '',
        divisionName: '',
        unit: '',
        unitName: '',
        user: '',
        userName: '',
        legal_entity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        le_id: '',
        domain: '',
        domainName: ''
    })
    const [entityData, setEntityData] = useState([])
    const [yearlyview, setYearlyview] = useState({
        user_id: '',
        domain_id: '',
        domain_name: '',
        unit_id: '',
        year: ''
    })
    const [paramid, setParamId] = useState("")

    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;

    console.log(assigneewisecompliancefilters, 'assigneewisecompliancefilters');
    useEffect(() => {
        if (assigneewisecompliancefilters) {
            setDivision(assigneewisecompliancefilters && assigneewisecompliancefilters.client_divisions)
            // setUser(assigneewisecompliancefilters && assigneewisecompliancefilters.users)
            // setUnit(assigneewisecompliancefilters && assigneewisecompliancefilters.units)
        }
    }, [assigneewisecompliancefilters])

    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])

    useEffect(() => {
        if (assigneewisecomplianceexport && assigneewisecomplianceexport.link) {
            window.open('https://13.234.100.64/' + assigneewisecomplianceexport.link);
            dispatch({
                type: ASSIGNEE_WISE_COMPLIANCE_EXPORT_RESET
            });
        }

    }, [assigneewisecomplianceexport])

    const handleOk = () => {
        setYearlyModalOpen(false);
    };
    const handleCancel = () => {
        setYearlyModalOpen(false);
    };

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {

            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssigneeWiseCompliancesChart",
                        {
                            "c_id": Number(assigneewisecompliance.country),
                            "bg_id": assigneewisecompliance.bg ? Number(assigneewisecompliance.bg) : null,
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "div_id": assigneewisecompliance.division ? Number(assigneewisecompliance.division) : null,
                            "unit_id": assigneewisecompliance.unit ? Number(assigneewisecompliance.unit) : null,
                            "usr_id": assigneewisecompliance.user ? Number(assigneewisecompliance.user) : null,
                            "csv": false
                        }
                    ]
                }
            ]
            AssigneeWiseCompliancesChart({
                payload: payload,
                paramid: paramid
            })
        }
    }
    const download = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssigneeWiseCompliancesChart",
                        {
                            "c_id": Number(assigneewisecompliance.country),
                            "bg_id": assigneewisecompliance.bg ? Number(assigneewisecompliance.bg) : null,
                            "le_ids": [entityid],
                            "div_id": assigneewisecompliance.division ? Number(assigneewisecompliance.division) : null,
                            "unit_id": assigneewisecompliance.unit ? Number(assigneewisecompliance.unit) : null,
                            "usr_id": assigneewisecompliance.user ? Number(assigneewisecompliance.user) : null,
                            "csv": true
                        }
                    ]
                }
            ]

            AssigneeWiseCompliancesChartexport({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onChange = (key) => {
        console.log(key);
    };

    const yearview = (assignee_name, domain_name, unit_id, user_id, domain_id) => {
        console.log(assignee_name, 'assignee_name');
        setAssigneeName(assignee_name)
        setYearlyModalOpen(true)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    'GetAssigneewiseYearwiseCompliances', {
                        'c_id': Number(assigneewisecompliance.country),
                        'u_id': unit_id,
                        'usr_id': user_id,
                        'le_ids': [entityid],
                        'd_ids': [Number(domain_id)],

                    }
                ]
            }
        ]
        AssigneewiseYearwiseCompliances({
            payload: payload,
            paramid: paramid
        })
    }

    const historyview = (user_id, domain_id, unit_id) => {
        setHistorytab(true)
        setTableShow(false)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetAssigneeWiseComplianceDrillDown",
                    {
                        "c_id": Number(assigneewisecompliance.country),
                        "assignee_id": Number(user_id),
                        "d_ids": [Number(domain_id)],
                        "chart_year": 2022,
                        "unit_id": Number(unit_id),
                        "start_count": 0,
                        "le_ids": [entityid]
                    }
                ]

            }
        ]
        AssigneeWiseComplianceDrillDown({
            payload: payload,
            paramid: paramid
        })
    }

    const historyviewnew = (year) => {
        setHistorytab(true)
        setTableShow(false)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetAssigneeWiseComplianceDrillDown",
                    {
                        "c_id": Number(assigneewisecompliance.country),
                        "assignee_id": Number(yearlyview.user_id),
                        "d_ids": [Number(yearlyview.domain_id)],
                        "chart_year": Number(year),
                        "unit_id": Number(yearlyview.unit_id),
                        "start_count": 0,
                        "le_ids": [entityid]
                    }
                ]

            }
        ]
        AssigneeWiseComplianceDrillDown({
            payload: payload,
            paramid: paramid
        })
    }

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down) {
            let compliedval = Object.values(assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.complied_map)
            if (compliedval.length > 0) {
                setCompliedmap([assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.complied_map])
            }
            let delayedval = Object.values(assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.delayed_map)
            if (delayedval.length > 0) {
                setDelayedmap([assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.delayed_map])
            }
            let inprogressval = Object.values(assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.inprogress_map)
            if (inprogressval.length > 0) {
                setInprogressmap([assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.inprogress_map])
            }
            let inprogressoverdueval = Object.values(assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.inprogress_overdue_map)
            if (inprogressoverdueval.length > 0) {
                setInprogressoverduemap([assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.inprogress_overdue_map])
            }
            let notcompliedval = Object.values(assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.not_complied_map)
            if (notcompliedval.length > 0) {
                setNotcompliedmap([assigneewisecompliancedrilldown && assigneewisecompliancedrilldown.assignee_wise_drill_down && assigneewisecompliancedrilldown.assignee_wise_drill_down.not_complied_map])
            }
        }
    }, [assigneewisecompliancedrilldown])

    useEffect(() => {
        if (SelectedEntity != 'All Legal Entity') {
            let businessName = _.filter(sessionArr, { le_name: SelectedEntity })
            setBusinessGroup({
                ...businessGroup,
                bg_id: businessName && businessName.length > 0 && businessName[0].bg_id,
                bg_name: businessName && businessName.length > 0 && businessName[0].bg_name
            })
        }
    }, [SelectedEntity])

    useEffect(() => {
        if (isAuth) {
            // if (legalstate === true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssigneewiseComplianesFilters",
                        {}
                    ]

                }
            ]
            if (paramid != '') {

                AssigneewiseComplianceFilters({
                    payload: payload,
                    paramid: paramid
                })
            }
            // Setlegalstate(false)
            // }
        }

    }, [isAuth, entityid, paramid])

    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            the_arr.pop();
            let a = the_arr.join('/')
            setParamId(a)
        }
    }, []);

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(assigneewisecompliance.legal_entity)
            let unit = _.filter(assigneewisecompliancefilters.units, { legal_entity_id: localStorage.getItem('SelectedEntityid') })
            setUnit(unit)
        }
    }, [entityid])

    useEffect(() => {
        if (entityid != null) {
            setAssigneewisecompliance({
                ...assigneewisecompliance,
                country: sessionArr[0].c_id,
                // countryName:sessionArr[0].c_name
            })
        }
    }, [entityid != null])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            console.log(assigneewisecompliancefilters, "runnnnn");
            let unit = _.filter(assigneewisecompliancefilters.units, { legal_entity_id: localStorage.getItem('SelectedEntityid') })
            setUnit(unit)
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity', assigneewisecompliancefilters])

    useEffect(() => {

        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid'),])

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ color: '#1890ff' }}>Dashboard</span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ color: '#1890ff' }}>Assignee Wise Compliance</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        {historytab == true ? <>
                            <div className="col-lg-4 col-md-6 align-self-center">
                                <Button title='Back' type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right' }}
                                    icon={<ArrowLeftOutlined />} size='default' onClick={() => { window.location.reload() }}>
                                    Back
                                </Button>
                            </div>
                        </> : ''}
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" style={{ padding: "15px 5px" }}>
                                {historytab == false ? <>
                                    <Collapse defaultActiveKey={["1"]} onChange={onChange} >
                                        <Panel header="Assignee Wise Compliance" key={key}>
                                            <div className="col-md-12" style={{ marginTop: "5px" }}>
                                                <div className="row">
                                                    <div className='col-md-6'>
                                                        <label htmlFor=""><b>Country:</b> <span style={{ "color": "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select style={{ marginTop: "5px", width: "100%" }}
                                                                    allowClear={false}
                                                                    size="default"
                                                                    placeholder="Enter Country"
                                                                    onChange={(value, data) => {
                                                                        // let filteredEntity = _.filter(legalEntity, { c_id: Number(value) })
                                                                        // setFinallegalentity(filteredEntity)
                                                                        setAssigneewisecompliance({
                                                                            ...assigneewisecompliance,
                                                                            country: value,
                                                                            countryName: data.children
                                                                        })
                                                                    }}
                                                                    value={assigneewisecompliance.countryName || undefined}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    display="none"
                                                                >
                                                                    {country_info && country_info.length > 0 && country_info.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'country',
                                                                    assigneewisecompliance.countryName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p style={{ marginLeft: "10px" }}>{sessionArr[0].c_name}</p>
                                                        }
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label style={{ marginBottom: "5px" }}><b>Business Group:</b></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={false}
                                                                    size="default"
                                                                    disabled={assigneewisecompliance.country == "" ? true : false}
                                                                    placeholder="Select Business Group"
                                                                    onChange={(data, value) => {
                                                                        setAssigneewisecompliance({
                                                                            ...assigneewisecompliance,
                                                                            bg: value,
                                                                            bgname: data.children
                                                                        })
                                                                        setCurrentEntity()
                                                                    }}

                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={assigneewisecompliance.bgname || undefined}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                        if (item.bg_name !== null) {
                                                                            return (
                                                                                <Option key={item.bg_id}>
                                                                                    {item.bg_name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </Select>
                                                            </div> :
                                                            <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: "5px" }}>
                                                <div className="row">
                                                    <div className='col-md-6'>
                                                        <label htmlFor=""><b>Legal Entity:</b> <span style={{ "color": "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select style={{ marginTop: "5px", width: "100%" }}
                                                                    allowClear={false}
                                                                    size="default"
                                                                    disabled={assigneewisecompliance.countryName == "" ? true : false}
                                                                    placeholder="Select Legal Entity"
                                                                    onChange={(data, value) => {
                                                                        let users = _.filter(assigneewisecompliancefilters.users, { le_id: data })
                                                                        setUser(users)
                                                                        let unit = _.filter(assigneewisecompliancefilters.units, { legal_entity_id: data })
                                                                        setUnit(unit)
                                                                        setCurrentEntity(data)
                                                                        setAssigneewisecompliance({
                                                                            ...assigneewisecompliance,
                                                                            le_id: data,
                                                                            legal_entity: value.children,
                                                                            unit: '',
                                                                            unitName: '',
                                                                            division: '',
                                                                            divisionName: '',
                                                                            user: '',
                                                                            userName: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={assigneewisecompliance.legal_entity || undefined}
                                                                    display="none"
                                                                >
                                                                    {assigneewisecompliancefilters && assigneewisecompliancefilters.legal_entities && assigneewisecompliancefilters.legal_entities.length > 0 && assigneewisecompliancefilters.legal_entities.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'legalentity',
                                                                    assigneewisecompliance.le_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Legal Entity Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p style={{ marginLeft: "10px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                        }
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label><b>Division:</b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                disabled={assigneewisecompliance.legal_entity == "" ? true : false}
                                                                size="default"
                                                                placeholder="Enter Division"
                                                                onChange={(value, data) => {
                                                                    // let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                    // setCategory(CategoryData)
                                                                    setAssigneewisecompliance({
                                                                        ...assigneewisecompliance,
                                                                        division: value,
                                                                        divisionName: data.children
                                                                    })
                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneewisecompliance.divisionName || undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}
                                                            >
                                                                {division && division.length > 0 && division.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.div_id}>
                                                                            {item.div_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: "5px" }}>
                                                <div className="row">
                                                    <div className='col-md-6'>
                                                        <label><b>Unit:</b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                disabled={assigneewisecompliance.legal_entity == "" ? true : false}
                                                                size="default"
                                                                placeholder="Enter Unit"
                                                                onChange={(value, data) => {

                                                                    // let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                    // setCategory(CategoryData)
                                                                    setAssigneewisecompliance({
                                                                        ...assigneewisecompliance,
                                                                        unit: value,
                                                                        unitName: data.children,
                                                                        division: '',
                                                                        divisionName: '',
                                                                        user: '',
                                                                        userName: '',
                                                                    })

                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneewisecompliance.unitName || undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}
                                                            >
                                                                {unit && unit.length > 0 && unit.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.unit_id}>
                                                                            {item.unit_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label><b>User:</b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                disabled={assigneewisecompliance.legal_entity == "" ? true : false}
                                                                size="default"
                                                                placeholder="Enter User"
                                                                onChange={(value, data) => {
                                                                    //     let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                    //     setCategory(CategoryData)
                                                                    setAssigneewisecompliance({
                                                                        ...assigneewisecompliance,
                                                                        user: value,
                                                                        userName: data.children
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneewisecompliance.userName || undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}
                                                            >
                                                                {user && user.length > 0 && user.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.user_id}>
                                                                            {item.employee_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    {/* <div className='col-md-4'></div>
                        <div className='col-md-4'></div> */}
                                                    <div className='col-md-4' style={{ marginLeft: '35%' }}>
                                                        {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px", width: "100px" }}
                                                    // onClick={onshow}
                                                    >
                                                        Show
                                                    </Button> */}
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px" }}
                                                            onClick={() => { onshow() }}
                                                        >
                                                            Show
                                                        </Button>
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852" }}
                                                            onClick={() => { download() }}
                                                        >
                                                            Export
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>

                                    {tableshow == true ?
                                        <>
                                            <Card>
                                                <p>User Selected : {assigneewisecompliance.userName}</p>
                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content fh userprivclass1'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className='ant-table-cell' style={{ width: "300px" }} rowspan="2">Assignee</th>
                                                                            <th className='ant-table-cell' style={{ width: "100px" }} rowspan="2">Total Compliance</th>
                                                                            <th className='ant-table-cell' style={{ width: "100px" }} rowspan="2">Complied</th>
                                                                            <th className='ant-table-cell' style={{ width: "100px" }} rowspan="2"><center>Compliance Delayed</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "100px" }} colspan="2">In progress</th>
                                                                            <th className='ant-table-cell' style={{ width: "100px" }} rowspan="2">Not Complied</th>
                                                                            <th className='ant-table-cell' style={{ width: "100px" }} rowspan="2">History</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className='ant-table-cell' style={{ width: "100px" }}>Within Due Date</th>
                                                                            <th className='ant-table-cell' style={{ width: "100px" }}>OverDue</th>
                                                                        </tr>
                                                                    </thead>
                                                                    {assigneewisecomplianceshow && assigneewisecomplianceshow.assingee_data && assigneewisecomplianceshow.assingee_data.length > 0 ?
                                                                        assigneewisecomplianceshow.assingee_data.map((item, i) => {
                                                                            return <>
                                                                                <tbody className='ant-table-tbody'>
                                                                                    <tr>
                                                                                        <td colspan='3'>
                                                                                            <label>Unit Name :</label>{item.unit_name}
                                                                                        </td>
                                                                                        <td colspan='5'>
                                                                                            <label>Domain : </label>{item.assignee_wise_details[0].domain_wise_details[0].domain_name}
                                                                                        </td>
                                                                                    </tr>

                                                                                    {/* <Card style={{ "width": "100%" }}>
                                                                            <label>Unit Name :</label>{item.unit_name}
                                                                        </Card> */}
                                                                                    {item.assignee_wise_details.map((items, i) => {
                                                                                        return <><tr>
                                                                                            <td><a className='ant-table-cell' onClick={() => {
                                                                                                setYearlyview({
                                                                                                    ...yearlyview,
                                                                                                    user_id: items.user_id,
                                                                                                    domain_id: items.domain_wise_details[0].domain_id,
                                                                                                    domain_name: items.domain_wise_details[0].domain_name,
                                                                                                    unit_id: item.unit_id
                                                                                                })
                                                                                                yearview(items.assignee_name, items.domain_wise_details[0].domain_name, item.unit_id, items.user_id, items.domain_wise_details[0].domain_id)
                                                                                            }}>{items.assignee_name}</a></td>
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].total_compliances}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].complied_count}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].assigned_count}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].inprogress_compliance_count}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].inprogress_overdue}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.domain_wise_details[0].not_complied_count}</center></td>
                                                                                            <td className='ant-table-cell' onClick={(() => {
                                                                                                setYearlyview({
                                                                                                    ...yearlyview,
                                                                                                    user_id: items.user_id,
                                                                                                    domain_id: items.domain_wise_details[0].domain_id,
                                                                                                    unit_id: item.unit_id
                                                                                                })
                                                                                                historyview(items.user_id, items.domain_wise_details[0].domain_id, item.unit_id)
                                                                                            })}><center>{<RightOutlined />}</center></td>
                                                                                        </tr>
                                                                                        </>
                                                                                    })}
                                                                                </tbody>
                                                                            </>
                                                                        }) :
                                                                        <tbody>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td style={{ paddingTop: '1%', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '1%' }}>No Records Found</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    }
                                                                    {/* // <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>} */}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </>
                                        : ''}
                                    <Modal title={assigneeName} visible={yearlyModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                                        <>
                                            <div className='ant-table-wrapper'>
                                                <div className='ant-table ant-table-bordered'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content userprivclass1'>
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className='ant-table-thead'>
                                                                    <tr>
                                                                        <th className='ant-table-cell w10px' rowspan="2">#</th>
                                                                        <th className='ant-table-cell w10px' rowspan="2">Year</th>
                                                                        <th className='ant-table-cell custom-width1' rowspan="2">Total</th>
                                                                        <th className='ant-table-cell custom-width1' rowspan="2">Complied</th>
                                                                        <th className='ant-table-cell custom-width1' rowspan="2"><center>Delayed</center></th>
                                                                        <th className='ant-table-cell custom-width1' colspan="2">In progress</th>
                                                                        <th className='ant-table-cell custom-width1' rowspan="2">Not Complied</th>
                                                                        <th className='ant-table-cell custom-width1' rowspan="2">History</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className='ant-table-cell custom-width1'>Within Due Date</th>
                                                                        <th className='ant-table-cell custom-width1'>OverDue</th>
                                                                    </tr>
                                                                </thead>
                                                                {assigneeyearwisecompliance && assigneeyearwisecompliance.year_wise_data && assigneeyearwisecompliance.year_wise_data.length > 0 ? assigneeyearwisecompliance.year_wise_data.map((items, i) => {
                                                                    return <>
                                                                        <tbody className='ant-table-tbody'>
                                                                            <tr>
                                                                                <td className='ant-table-cell'>{count + 1}</td>
                                                                                <td className='ant-table-cell'><center>{items.year}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.total_compliances}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.complied_count}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.delayed_compliance_count}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.inprogress_compliance_count}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.inprogressoverdue_count}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.not_complied_count}</center></td>
                                                                                {/* <td className='ant-table-cell'><center>{<RightOutlined />}</center></td> */}
                                                                                <td className='ant-table-cell' onClick={(() => {
                                                                                    setYearlyview({
                                                                                        ...yearlyview,
                                                                                        year: items.year
                                                                                    })
                                                                                    historyviewnew(items.year)
                                                                                })}><center>{<RightOutlined />}</center></td>
                                                                                <span hidden>{count = count + 1}</span>
                                                                            </tr>
                                                                        </tbody>
                                                                    </>
                                                                }) : ''}

                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </Modal>
                                </> : ''}
                                {historytab == true ? <>
                                    {compliedmap && compliedmap.length > 0 ? compliedmap.map((item, i) => {
                                        let keys = Object.keys(item);
                                        let values = Object.values(item);
                                        let k = []
                                        for (let i in values) {
                                            for (let j in values[i]) {
                                                k.push(values[i][j])
                                            }
                                        }
                                        return <Collapse defaultActiveKey={["0"]} onChange={onChange} >
                                            <Panel header="Complied" key={key}>
                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content fh userprivclass1'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className='ant-table-cell w10px'>#</th>
                                                                            <th className='ant-table-cell custom-width1'>Compliance Task</th>
                                                                            <th className='ant-table-cell custom-width1'>Start Date</th>
                                                                            <th className='ant-table-cell custom-width1'><center>Actual Due Date</center></th>
                                                                            <th className='ant-table-cell custom-width1'>Task Completion Date/ Document issued Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        {k.map((items, j) => {
                                                                            return <tr>
                                                                                <td className='ant-table-cell'> {historycount + 1}</td>
                                                                                <td className='ant-table-cell'>
                                                                                    <div className="ant-table-cell">
                                                                                        <Popover content={<table style={{ width: "500px", display: 'block', height: '300px', overflowY: 'scroll' }} class="table table-striped" >
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th>Unit Name</th>
                                                                                                    <td>{items.unit_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Division Name</th>
                                                                                                    <td>{items.division_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Category Name</th>
                                                                                                    <td>{items.category_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Act/Rules</th>
                                                                                                    <td>{items.statutory_mapping}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Compliance Description</th>
                                                                                                    <td>{items.description}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Penal Consequqnces</th>
                                                                                                    <td>{items.penal_consequences}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>} title="">
                                                                                            <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                                                                        </Popover>{items.compliance_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='ant-table-cell'><center>{items.assigned_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                <span hidden>{historycount = historycount + 1}</span>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    }) : ''}
                                    {delayedmap && delayedmap.length > 0 ? delayedmap.map((item, i) => {
                                        let keys = Object.keys(item);
                                        let values = Object.values(item);
                                        let k = []
                                        for (let i in values) {
                                            for (let j in values[i]) {
                                                k.push(values[i][j])
                                            }
                                        }
                                        return <Collapse defaultActiveKey={["0"]} onChange={onChange} >
                                            <Panel header="Delayed" key={key}>
                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content fh userprivclass1'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className='ant-table-cell w10px'>#</th>
                                                                            <th className='ant-table-cell custom-width1'>Compliance Task</th>
                                                                            <th className='ant-table-cell custom-width1'>Start Date</th>
                                                                            <th className='ant-table-cell custom-width1'><center>Actual Due Date</center></th>
                                                                            <th className='ant-table-cell custom-width1'>Task Completion Date/ Document issued Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        {k.map((items, j) => {
                                                                            return <tr>
                                                                                <td className='ant-table-cell'> {historycount + 1}</td>
                                                                                <td className='ant-table-cell'>
                                                                                    <div className="mb-3">
                                                                                        <Popover content={<table style={{ width: "600px", display: 'block', height: '250px', overflowY: 'scroll' }} class="table table-striped" >
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th>Unit Name</th>
                                                                                                    <td>{items.unit_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Division Name</th>
                                                                                                    <td>{items.division_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Category Name</th>
                                                                                                    <td>{items.category_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Act/Rules</th>
                                                                                                    <td>{items.statutory_mapping}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Compliance Description</th>
                                                                                                    <td>{items.description}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Penal Consequqnces</th>
                                                                                                    <td>{items.penal_consequences}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>} title="">
                                                                                            <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                                                                        </Popover>{items.compliance_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='ant-table-cell'><center>{items.assigned_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                <span hidden>{historycount = historycount + 1}</span>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    }) : ''}
                                    {inprogressmap && inprogressmap.length > 0 ? inprogressmap.map((item, i) => {
                                        let keys = Object.keys(item);
                                        let values = Object.values(item);
                                        let k = []
                                        for (let i in values) {
                                            for (let j in values[i]) {
                                                k.push(values[i][j])
                                            }
                                        }
                                        return <Collapse defaultActiveKey={["0"]} onChange={onChange} >
                                            <Panel header="Inprogress" key={key}>
                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content fh userprivclass1'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className='ant-table-cell w10px'>#</th>
                                                                            <th className='ant-table-cell custom-width1'>Compliance Task</th>
                                                                            <th className='ant-table-cell custom-width1'>Start Date</th>
                                                                            <th className='ant-table-cell custom-width1'><center>Actual Due Date</center></th>
                                                                            <th className='ant-table-cell custom-width1'>Task Completion Date/ Document issued Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        {k.map((items, j) => {
                                                                            return <tr>
                                                                                <td className='ant-table-cell'> {historycount + 1}</td>
                                                                                <td className='ant-table-cell'>
                                                                                    <div className="ant-table-cell">
                                                                                        <Popover content={<table style={{ width: "600px", display: 'block', height: '250px', overflowY: 'scroll' }} class="table table-striped" >
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th>Unit Name</th>
                                                                                                    <td>{items.unit_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Division Name</th>
                                                                                                    <td>{items.division_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Category Name</th>
                                                                                                    <td>{items.category_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Act/Rules</th>
                                                                                                    <td>{items.statutory_mapping}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Compliance Description</th>
                                                                                                    <td>{items.description}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Penal Consequqnces</th>
                                                                                                    <td>{items.penal_consequences}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>} title="">
                                                                                            <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                                                                        </Popover>{items.compliance_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='ant-table-cell'><center>{items.assigned_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                <span hidden>{historycount = historycount + 1}</span>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    }) : ''}
                                    {inprogressoverduemap && inprogressoverduemap.length > 0 ? inprogressoverduemap.map((item, i) => {
                                        let keys = Object.keys(item);
                                        let values = Object.values(item);
                                        let k = []
                                        for (let i in values) {
                                            for (let j in values[i]) {
                                                k.push(values[i][j])
                                            }
                                        }
                                        return <Collapse defaultActiveKey={["0"]} onChange={onChange} >
                                            <Panel header="Inprogress Overdue" key={key}>
                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content fh userprivclass1'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className='ant-table-cell w10px'>#</th>
                                                                            <th className='ant-table-cell custom-width1'>Compliance Task</th>
                                                                            <th className='ant-table-cell custom-width1'>Start Date</th>
                                                                            <th className='ant-table-cell custom-width1'><center>Actual Due Date</center></th>
                                                                            <th className='ant-table-cell custom-width1'>Task Completion Date/ Document issued Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        {k.map((items, j) => {
                                                                            return <tr>
                                                                                <td className='ant-table-cell'> {historycount + 1}</td>
                                                                                <td className='ant-table-cell'>
                                                                                    <div className="mb-3">
                                                                                        <Popover content={<table style={{ width: "600px", display: 'block', height: '250px', overflowY: 'scroll' }} class="table table-striped" >
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th>Unit Name</th>
                                                                                                    <td>{items.unit_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Division Name</th>
                                                                                                    <td>{items.division_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Category Name</th>
                                                                                                    <td>{items.category_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Act/Rules</th>
                                                                                                    <td>{items.statutory_mapping}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Compliance Description</th>
                                                                                                    <td>{items.description}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Penal Consequqnces</th>
                                                                                                    <td>{items.penal_consequences}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>} title="">
                                                                                            <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                                                                        </Popover>{items.compliance_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='ant-table-cell'><center>{items.assigned_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                <span hidden>{historycount = historycount + 1}</span>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    }) : ''}
                                    {notcompliedmap && notcompliedmap.length > 0 ? notcompliedmap.map((item, i) => {
                                        let keys = Object.keys(item);
                                        let values = Object.values(item);
                                        let k = []
                                        for (let i in values) {
                                            for (let j in values[i]) {
                                                k.push(values[i][j])
                                            }
                                        }
                                        return <Collapse defaultActiveKey={["0"]} onChange={onChange} >
                                            <Panel header="Not Complied" key={key}>
                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content fh userprivclass1'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className='ant-table-cell w10px'>#</th>
                                                                            <th className='ant-table-cell custom-width1'>Compliance Task</th>
                                                                            <th className='ant-table-cell custom-width1'>Start Date</th>
                                                                            <th className='ant-table-cell custom-width1'><center>Actual Due Date</center></th>
                                                                            <th className='ant-table-cell custom-width1'>Task Completion Date/ Document issued Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        {k.map((items, j) => {
                                                                            return <tr>
                                                                                <td className='ant-table-cell'> {historycount + 1}</td>
                                                                                <td className='ant-table-cell'>
                                                                                    <div className="mb-3">
                                                                                        <Popover content={<table style={{ width: "600px", display: 'block', height: '250px', overflowY: 'scroll' }} class="table table-striped" >
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <th>Unit Name</th>
                                                                                                    <td>{items.unit_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Division Name</th>
                                                                                                    <td>{items.division_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Category Name</th>
                                                                                                    <td>{items.category_name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Act/Rules</th>
                                                                                                    <td>{items.statutory_mapping}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Compliance Description</th>
                                                                                                    <td>{items.description}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Penal Consequqnces</th>
                                                                                                    <td>{items.penal_consequences}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>} title="">
                                                                                            <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                                                                        </Popover>{items.compliance_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='ant-table-cell'><center>{items.assigned_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                <span hidden>{historycount = historycount + 1}</span>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    }) : ''}
                                    {compliedmap && compliedmap.length == 0 && delayedmap && delayedmap.length == 0 && inprogressmap && inprogressmap.length == 0
                                        && inprogressoverduemap && inprogressoverduemap.length == 0 && notcompliedmap && notcompliedmap.length == 0 ? <>
                                        {/* <div className='ant-table-wrapper'> */}
                                        {/* <div className='ant-table ant-table-bordered'> */}
                                        {/* <div className='ant-table-container'> */}
                                        <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                                        {/* </div> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                    </> : ''
                                    }
                                </> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}


export default connect(mapStateToProps, {
    AssigneewiseComplianceFilters,
    AssigneeWiseCompliancesChart,
    AssigneeWiseComplianceDrillDown,
    AssigneewiseYearwiseCompliances,
    AssigneeWiseCompliancesChartexport
})(AssigneeWiseCompliance);