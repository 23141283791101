import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons';
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Pagination, Button, Descriptions, Tooltip, Popover } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useRef, useEffect, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getData, getVendorTempTabledata, getVendorTempExport } from '../../../Store/Action/Report/CV_VendorTempClosureReport';

const mapStateToProps = (state) => ({
    allData: state.VendorTempClosureReport.userwisereport.userwisereports,
    tableData: state.VendorTempClosureReport.userwisereport.userwisefilter,
    // downloadedPath:state.RiskReport.riskReport.downloadPath
})

const VendorTempClosureReport = ({
    getData,
    getVendorTempTabledata,
    getVendorTempExport,
    allData,
    tableData,
    downloadedPath,
}) => {
    console.log(allData, "allData123");
    console.log(tableData, "tableData123");

    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    const [titledata, Settitledata] = useState()
    console.log(titledata, "titledata");
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [childCompliceId, setChildComplinaceId] = useState([])
    let [index, setIndex] = useState(0)
    const [uniqueData, setUniqueData] = useState([])
    const [valueModal, setValueModal] = useState({
        concurrer_name: '',
        Approver: ''
    })
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [consolidateddata, setConsolidatedDate] = useState({
        country: "",
        category: '',
        legel_entity: "",
        legel_entity_name: "",
        unit: '',
        division: '',
        unit_name: '',
        division_name: '',
        category_name: '',
        bg_id: '',
        bg_name: ''

    })
    const validator = useRef(new SimpleReactValidator());
    const [exportButton, setExportButton] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [divisionList, setDivisionList] = useState([])
    const [vendorlist, setVendorlist] = useState([])
    console.log(vendorlist, "vendorlist");
    const [categoryList, setCategoryList] = useState([])
    const [legalentityfilter, setlegalentityfilter] = useState([])
    const [actLegalEntity, setActLegalEntity] = useState([])
    const [actName, setActName] = useState([])
    const [actNameFilteredState, setActNameFilteredState] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [showValueModal, setShowValueModal] = useState(false)
    const country_info = sessionParsedValue.country_info
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    const [businessGroup, setBusinessGroup] = useState({
        bg_id: '',
        bg_name: ''
    })
    const [checkboxValue, setCheckBoxValue] = useState([0, 1, 2, 3, 4, 5])

    console.log(checkboxValue.length,'checkboxValue');

    const { Panel } = Collapse;
    const { Option } = Select;
    const columns = [
        {
            title: 'User Name',
            ellipsis: true,
            // width: '30px',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: 'Due Date',
            ellipsis: true,
            // width: '30px',
            dataIndex: 'due_date',
            key: 'due_date',
        },


    ];
    useEffect(() => {
        if (pageState == true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettingsUnitWise",
                        {
                            "c_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidateddata.country),
                            "bg_id": consolidateddata.bg_id ? Number(consolidateddata.bg_id) : null,
                            "le_id": entityid,
                            "d_id": Number(consolidateddata.domain),
                            "unit_id": consolidateddata.unit ? Number(consolidateddata.unit) : null,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "parent_id": consolidateddata.act ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? Number(consolidateddata.compliance_task) : null,
                            "frequency_id": consolidateddata.compliance_frequency ? Number(consolidateddata.compliance_frequency) : 0,
                            "status_name": "All",
                            "csv": false,
                            "f_count": Math.max(((current - 1) * pageSize), 0) + 1,
                            "t_count": current != 0 ? current * pageSize : pageSize,
                            "count_qry": true,
                            "list_check": null
                        }
                    ]

                }

            ]
            getVendorTempTabledata({
                payload: payload,
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        let tempArr = []
        if (tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length > 25) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {

                let uniqueObjArray = [...new Map(item && item.length && item.map((items) => [items["compliance_id"], items])).values()];

                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.compliance_id
                })


                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { compliance_id: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })


            setUniqueData(tempArr)

        }
    }, [filteredArrayState])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length > 25) {
            // setTempeditdata(editdata)
            if (uniqueData && uniqueData.length > 0) {
                const ab = uniqueData && uniqueData.length && uniqueData.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })


                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        uniqueData && uniqueData.length && uniqueData.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "act_name": child.act_name,
                                    "approver_name": child.approver_name,
                                    "assignee_name": child.assignee_name,
                                    "category_name": child.category_name,
                                    "compliance_description": child.compliance_description,
                                    "compliance_id": child.compliance_id,
                                    "compliance_task": child.compliance_task,
                                    "concurrer_name": child.concurrer_name,
                                    " criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    " document_name": child.document_name,
                                    "download_url": child.download_url,
                                    "due_date": child.due_date,
                                    " extend_due_date": child.extend_due_date,
                                    "frequency": child.frequency,
                                    "task_status": child.task_status,
                                    " tz_name": child.tz_name,
                                    "unit": child.unit,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    "user_name": child.user_name,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)

            }
        }
        else {

            if (tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length > 0) {
                const ab = tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })
                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "act_name": child.act_name,
                                    "approver_name": child.approver_name,
                                    "assignee_name": child.assignee_name,
                                    "category_name": child.category_name,
                                    "compliance_description": child.compliance_description,
                                    "compliance_id": child.compliance_id,
                                    "compliance_task": child.compliance_task,
                                    "concurrer_name": child.concurrer_name,
                                    " criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    " document_name": child.document_name,
                                    "download_url": child.download_url,
                                    "due_date": child.due_date,
                                    " extend_due_date": child.extend_due_date,
                                    "frequency": child.frequency,
                                    "task_status": child.task_status,
                                    " tz_name": child.tz_name,
                                    "unit": child.unit,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    "user_name": child.user_name,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)
            }
        }
    }, [tableData && tableData.vendor_temporary_closure_report_list, uniqueData])
    useEffect(() => {
        if (tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length > 0) {
            let uniqueObjArray = [...new Map(tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list.map((item) => [item["unit_id"], item])).values()];

            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [tableData && tableData.vendor_temporary_closure_report_list])
    useEffect(() => {
        if (filteredArray.length) {
            let temp = []
            for (let i in filteredArray) {

                temp = filteredArray[i]
            }
            setfilteredArrayState([...temp])
        }

    }, [filteredArray])
    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidateddata.legel_entity)
        }
    }, [entityid])
    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidateddata.legel_entity)
        }
    }, [entityid])
    useEffect(() => {
        if (SelectedEntity != 'All Legal Entity') {
            let businessName = _.filter(sessionArr, { le_name: SelectedEntity })
            setBusinessGroup({
                ...businessGroup,
                bg_id: businessName && businessName.length > 0 && businessName[0].bg_id,
                bg_name: businessName && businessName.length > 0 && businessName[0].bg_name
            })
        }
    }, [SelectedEntity])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVendorTemporaryReportFilters",
                        {
                            "c_id": 1,
                            "le_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                getData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth, authtoken, entityid])

    useEffect(() => {
        if (allData) {

            setCategoryList(allData && allData.cat_infos)
            setVendorlist(allData && allData.ven_names)
            setDivisionList(allData && allData.div_infos)
        }

    }, [allData])

    const onshow = () => {
        setAddFormSubmit(true)
        Settitledata([])
        setfilteredArrayState([])
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVendorTemporaryClosure",
                        {
                            "bg_id": null,
                            "le_id": entityid,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "unit_id": consolidateddata.unit ? Number(consolidateddata) : null,
                            "csv": false,
                            "f_count": 1,
                            "t_count": 25,
                            "count_qry": true,
                            "list_check": null
                        }
                    ]
                }
            ]
            getVendorTempTabledata({
                payload: payload,
                paramid: paramid
            })
        }
    }
    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };
    const handleOk = () => {
        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVendorTemporaryClosure",
                        {
                            "bg_id": null,
                            "le_id": entityid,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "unit_id": consolidateddata.unit ? Number(consolidateddata) : null,
                            "csv": true,
                            "f_count": 1,
                            "t_count": 25,
                            "count_qry": true,
                            "list_check": checkboxValue,
                        }
                    ]

                }
            ]
            getVendorTempExport({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
    };
    const chilData = (id, unitId) => {
        setIsModalVisible(true)
        if (tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length > 25) {
            let tempArr = []
            for (let i in tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list) {
                if (tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i].compliance_id == id) {
                    if (tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i].unit_id == unitId) {
                        tempArr.push(tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i])
                    }
                }
            }

            setChildComplinaceId(tempArr)

        }
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        setShowValueModal(false)
        setIsModalVisible(false)
    };
    const showValues = () => {
        setShowValueModal(true);
    };
    const onChange = (key) => {
        console.log(key);
    };

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {

                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;
            }
            setCheckBoxValue([])
        }
    }


    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>

                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Vendor Reports</span>
                                </li>

                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Vendor Temporary Closure Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <Modal title="Select Fields" visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                <div className='row'>
                    <div className='col-lg-3'><input id="select_all"
                        checked={checkboxValue.length === 19 ? true : false}
                    width="100%" type="checkbox" autocomplete="off" onClick={(e) => {
                        let checked = e.target.checked
                        selectAllCheckBox(checked)
                    }} /> Select All</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} />Business Group</div>
                    <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /> Legal Entity </div>
                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Division </div>
                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='4' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Category </div>

                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='5' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Vendor Name </div>
                    <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code </div>
                    <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} />Vendor Code 1</div>
                    <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" value='8' onChange={(e) => checkBoxOnChange(e)} />Vendor Code 2</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='9' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 3</div>
                    <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='10' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 4 </div>
                    <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /> Location</div>
                    <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Postal Code </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Domain</div>
                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} />Organization Type</div>
                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Date of Temporary Closure</div>
                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Temporary Closure done by</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} />Remarks for Temporary Closure</div>
                    <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} />Date Reactivated from</div>
                    <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} />Reactivated By</div>
                </div>
                <br />
                <div className="form-actions text-center">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754" }}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                </div>

            </Modal>
            <Modal visible={isModalVisible} onCancel={handleCancel} onOk={() => {
                setIsModalVisible(false)
            }} >
                <Table
                    className='userprivclass'
                    columns={columns}
                    dataSource={childCompliceId}
                    bordered
                    pagination={false} />
            </Modal>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" style={{ padding: "15px 5px" }}>
                            <Collapse defaultActiveKey={["1"]} onChange={onChange} >
                                <Panel header="Vendor Temporary Closure Report" key={key}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            {/* <div className='col-md-1'></div> */}
                                            {/* <div className="col-md-5">
                                                <label><b>Business Group:</b></label><br />
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Business Group"
                                                            onChange={(data, value) => {
                                                                // setCurrentEntity(data)
                                                                // SetSettings({
                                                                //     ...Settings,
                                                                //     le_id: data
                                                                // })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '5px' }}

                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                if(item.bg_id !== null){
                                                                return (
                                                                    <Option key={item.bg_id}>
                                                                        {item.bg_name}
                                                                    </Option>
                                                                );
                                                                }
                                                            })}
                                                        </Select>
                                                    </div> :
                                                    <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                                }
                                            </div> */}
                                            <div className='col-md-5'>
                                                <label><b>Business Group: </b>  </label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Entry Business Group"
                                                            onChange={(data, value) => {

                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    bg_id: data,
                                                                    bg_name: value.children,
                                                                    division_name: '',
                                                                    legel_entity_name: '',
                                                                    category_name: '',
                                                                    unit_name: ''
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={consolidateddata.bg_name || undefined}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {

                                                                if (item.bg_name !== null) {
                                                                    return (
                                                                        <Option key={item.bg_id}>
                                                                            {item.bg_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            })}
                                                        </Select>

                                                    </div> :
                                                    <p> - </p>
                                                }

                                            </div>
                                            <div className='col-md-1'></div>
                                            <div className="col-md-5">
                                                <label><b>Category:</b></label><br />
                                                <Select
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            category: value,
                                                            category_name: data.children,
                                                            unit_name: ''
                                                        })

                                                    }}
                                                    placeholder="Enter Category Name"
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                    value={consolidateddata.category_name || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {categoryList && categoryList.length && categoryList.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>

                                            {/* <div className='col-md-1'></div> */}


                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            {/* <div className='col-md-1'></div> */}

                                            <div className='col-md-5'>
                                                <label><b>Legal Entity:</b> <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            // disabled={consolidateddata.country == "" ? true : false}
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(data, value) => {

                                                                setCurrentEntity(data)
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    legel_entity: data,
                                                                    legel_entity_name: value.children,
                                                                    division_name: '',
                                                                    category_name: '',
                                                                    unit_name: ''
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '5px' }}

                                                            value={consolidateddata.legel_entity_name || undefined}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            consolidateddata.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-1'></div>
                                            <div className='col-md-5'>
                                                <label><b>Vendor</b></label><br />
                                                <Select
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            unit: value,
                                                            unit_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Vendor"
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                    value={consolidateddata.unit_name || undefined}
                                                // disabled={consolidateddata.entityid == '' ? true : false}
                                                >
                                                    {vendorlist && vendorlist.length && vendorlist.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {`${item.unit_code}-${item.unit_name}`}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            {/* <div className='col-md-1'></div> */}



                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            {/* <div className='col-md-1'></div> */}

                                            <div className="col-5">
                                                <label htmlFor=""><b>Division:</b></label><br />
                                                <Select
                                                    onChange={(value, data) => {

                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            division: value,
                                                            division_name: data.children,
                                                            category_name: '',
                                                            unit_name: ''
                                                        })
                                                        let filteredCategory = _.filter(allData && allData.cat_infos, { div_id: Number(value) })
                                                        setCategoryList(filteredCategory)
                                                        let filteredUnit = _.filter(allData && allData.ven_names, { division_id: Number(value) })
                                                        setVendorlist(filteredUnit)

                                                    }}
                                                    placeholder="Enter Division Name"
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                    value={consolidateddata.division_name || undefined}
                                                // disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {divisionList && divisionList.length && divisionList.map((item, i) => {

                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-5'></div>

                                            <div className='col-md-1'></div>

                                        </div>
                                    </div>

                                    <div className='col-md-12' style={{ marginTop: "5%" }}>
                                        <div className='row'>
                                            {/* <div className='col-md-4'></div>
                                            <div className='col-md-4'></div> */}
                                            <div className='col-md-4' style={{ marginLeft: '35%' }}>
                                                <Button type="primary" shape="round" className='addbutton' style={{ marginLeft: "39px", marginRight: "10px" }}
                                                    onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                                >
                                                    Export
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>

                            {tableshow == true ?
                                <Card>
                                    <div className='col-md-12 mt-2'>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            {/* <div className='col-md-4'>
                                                <h2>Domain Report</h2>
                                            </div> */}
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'
                                        style={{ marginTop: "15px", marginBottom: "15px" }}
                                    >
                                        {/* <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity :</b>{localStorage.getItem('SelectedEntity') === "All Legal Entity" ? consolidateddata.legel_entity_name : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Country :</b>{sessionArr && sessionArr[0].c_name}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain :</b>{consolidateddata.domainName}</label>
                                            </div>
                                        </div> */}
                                    </div>
                                    
                                        <>
                                            <div>
                                                <div className='ant-table ant-table-bordered ant-table-scroll-horizontal'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content fh'>
                                                            <table className='widthHeigh table-fixed'>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className='ant-table-thead'>
                                                                    <tr>
                                                                        <th className='ant-table-cell' style={{ width: '10px' }}><center>#</center></th>
                                                                        <th className='ant-table-cell' style={{ width: '80px' }}>Vendor Code</th>
                                                                        <th className='ant-table-cell' style={{ width: '70px' }}>Location</th>
                                                                        <th className='ant-table-cell' style={{ width: '70px' }}>Postal Code</th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Domain</th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Organization Type</th>
                                                                        <th className='ant-table-cell' style={{ width: '80px' }}>Date of Temporary Closure</th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Temporary Closure Done by</th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Remarks for Temporary Closure</th>
                                                                        <th className='ant-table-cell' style={{ width: '70px' }}>Date Reactivated from</th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Reactivated By</th>


                                                                    </tr>
                                                                </thead>
                                                                {filteredArrayState && filteredArrayState.length ?
                                                                    filteredArrayState.map((item, i) => {


                                                                        return (
                                                                            <tbody className='ant-table-tbody'>
                                                                                <tr className="bg-lightdark">
                                                                                </tr>
                                                                                {/* {titledata && titledata.map((itemdata, i) => {
                                                                                let data = _.find(itemdata.child, { unit_id: item[0].unit_id })

                                                                                if (data != undefined) {
                                                                                    if (data.act_name == itemdata.parent) {
                                                                                        return <Fragment>
                                                                                            <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                            </tr>
                                                                                            {itemdata.child.map((items) => {
                                                                                                if (item[0].unit_id == items.unit_id) {
                                                                                                    let array = []
                                                                                                    for (let i in tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list) {
                                                                                                        if (tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i].compliance_id == items.compliance_id) {
                                                                                                            if (tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i].unit_id == items.unit_id) {
                                                                                                                array.push(tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i])
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    return <tr>
                                                                                                        <td className='ant-table-cell'>
                                                                                                            {index + 1}


                                                                                                        </td>

                                                                                                        <td title={items.compliance_task} className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                            <ExclamationCircleTwoTone />
                                                                                                        </Tooltip> &nbsp;{array.length > 1 ? <a href='javascript:;' onClick={() => {
                                                                                                            chilData(items.compliance_id, items.unit_id)
                                                                                                        }}>{items.compliance_task}</a> : <a >{items.compliance_task}</a>}</td>
                                                                                                        <td className='ant-table-cell'>{items[" criticality_name"]}</td>
                                                                                                        <td className='ant-table-cell'>{items.frequency}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                        <td className='ant-table-cell'><center>{items.task_status}</center></td>
                                                                                                        <td className='ant-table-cell'><center>  <>
                                                                                                       

                                                                                                        </></center></td>
                                                                                                        <td className='ant-table-cell'><center>{
                                                                                                            <a href={`${'https://13.234.100.64/compliance_format/' + items.download_url}`} target="_blank" >
                                                                                                                {items[" document_name"]}
                                                                                                            </a>}</center></td>
                                                                                                      
                                                                                                        <span hidden>{index = index + 1}</span>

                                                                                                    </tr>
                                                                                                }

                                                                                            })

                                                                                            }
                                                                                        </Fragment>
                                                                                    }
                                                                                }
                                                                            })
                                                                            } */}
                                                                                {tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.map((itemdata, i) => {
                                                                                    console.log(itemdata, "itemdata123");


                                                                                    // if (data != undefined) {
                                                                                    // if (data.act_name == itemdata.parent) {

                                                                                    return <Fragment>

                                                                                        <tr>
                                                                                            <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                            <td className='ant-table-cell'>{itemdata.unit_code}</td>
                                                                                            <td className='ant-table-cell'>{itemdata.address}</td>
                                                                                            <td className='ant-table-cell'>{itemdata.postal_code}</td>
                                                                                            <td className='ant-table-cell'>{itemdata.domain_name}</td>
                                                                                            <td className='ant-table-cell'>{itemdata.organisation_name}</td>
                                                                                            <td className='ant-table-cell'>{itemdata.vendor_closed_date}</td>
                                                                                            <td className='ant-table-cell'>{itemdata.closed_by}</td>
                                                                                            <td className='ant-table-cell'><center>{itemdata.vendor_closed_remarks}</center></td>
                                                                                            <td className='ant-table-cell'><center>{itemdata.reopen_by}</center></td>
                                                                                            <td className='ant-table-cell'><center>{itemdata.vendor_activation_date}</center></td>
                                                                                            <span hidden>{index = index + 1}</span>

                                                                                        </tr>

                                                                                    </Fragment>
                                                                                    // }
                                                                                    // }
                                                                                })
                                                                                }


                                                                            </tbody>
                                                                        )
                                                                    }) : 
                                                                    <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td style={{ paddingTop: '5%', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '5%' }}>No Records Found</td>
    
                                                                </tr>}
                                                            </table>
                                                            <br />


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-md-12 mt-2' style={{ position: 'absolute',marginTop:'10px', bottom: '-25px', left: 0 }}>
                                                <label style={{ marginLeft: "13px" }}>Showing {pageSize * (current - 1) + 1} to {index} of {tableData && tableData.total_count} entries</label>
                                            </div>
                                            <div style={{ position: 'absolute', bottom: '-25px', right: 0 }}>
                                                <Pagination
                                                    current={current}
                                                    showSizeChanger={true}
                                                    // showQuickJumper={true}
                                                    onShowSizeChange={() => {
                                                        setCurrent(1)
                                                        // setpageState(true)
                                                    }}
                                                    pageSizeOptions={[25, 50, 100]}
                                                    hideOnSinglePage={true}
                                                    defaultPageSize={25}
                                                    onChange={(page, pageSizes) => {
                                                        setpageState(true)
                                                        setCurrent(pageSize !== pageSizes ? 1 : page);
                                                        setPageSize(pageSizes)
                                                    }} total={tableData.total_count} />


                                            </div>
                                        </>
                                        
                                    
                                </Card>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default connect(mapStateToProps, {
    getData,
    getVendorTempTabledata,
    getVendorTempExport,
})(VendorTempClosureReport);