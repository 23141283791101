import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { MAIL_MAPPING, MAIL_MAPPING_SHOW, EMAIL_SAVE, VERIFY_PASSWORD, UPDATE_EMAIL } from '../../types/index'


const initialState = {

    mailmapping: {
        Mailmapping: '',
        Mailmappingshow: '',
        mailsave: '',
        passwordresult: '',
        updaterecord: ''

    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case MAIL_MAPPING:
            {
                return {
                    ...state,
                    mailmapping: {
                        ...state.mailmapping,
                        Mailmapping: payload[1]
                    },
                };
            }
        case MAIL_MAPPING_SHOW:
            {
                return {
                    ...state,
                    mailmapping: {
                        ...state.mailmapping,
                        Mailmappingshow: payload[1]
                    },
                };
            }
        case EMAIL_SAVE:
            {
                return {
                    ...state,
                    mailmapping: {
                        ...state.mailmapping,
                        mailsave: payload[1]
                    },
                };
            }
        case VERIFY_PASSWORD:
            {
                return {
                    ...state,
                    mailmapping: {
                        ...state.mailmapping,
                        passwordresult: payload[0]
                    },
                };
            }
        case UPDATE_EMAIL:
            {
                return {
                    ...state,
                    mailmapping: {
                        ...state.mailmapping,
                        updaterecord: payload[0]
                    },
                };
            }

        default:
            return state;
    }
}