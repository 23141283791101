import { VCSFILTERS, VCSEXPORT } from './../../types/index'
const initialState = {
    vendorcompliancescore: {
        vendorcompliancescorefilter: [],
        vendorcompliancescoreexport: []

    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case VCSFILTERS:
            {
                return {
                    ...state,
                    vendorcompliancescore: {
                        ...state.vendorcompliancescore,
                        vendorcompliancescorefilter: payload,
                    },
                };
            }
        case VCSEXPORT:
            {
                return {
                    ...state,
                    vendorcompliancescore: {
                        ...state.vendorcompliancescore,
                        vendorcompliancescoreexport: payload,
                    },
                };
            }
        default:
            return state;
    }
}