import { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Input, Row, Col, Button, Card } from 'antd';
import SimpleReactValidator from "simple-react-validator";
import { UserProfile, UpdateProfile, Updatepassword } from './../../src/Store/Action/Profile';
import { toast } from 'react-toastify';
const _ = require("lodash");


const mapStateToProps = (state) => ({
    profiles: state.Profile.profile,

});
const MyProfile = ({
    UserProfile,
    UpdateProfile,
    Updatepassword,
    profiles: { profiledata }

}) => {
    const { TextArea } = Input;
    const location = useLocation();
    const validator = useRef(new SimpleReactValidator());
    const formvalidator = useRef(new SimpleReactValidator());
    const [changepassword, Setchangepassword] = useState();
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    // const [passwordcard, setPasswordcard] = useState(false);
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const paramid = location.pathname;
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const login = callername.login_response.menu['My Accounts'];
    const [password, setPassword] = useState({
        current_password: '',
        new_password: '',
        confirm_password: '',
    })
    const [profile, setProfile] = useState({
        email_id: '',
        emp_contact_country_code: '',
        emp_contact_code: '',
        emp_contact_no: '',
        mobile_no_country_code: '',
        mobile_no: '',
        address: ''
    })
    const [formdata, Setformdata] = useState({
        form_url: '',
        form_key: ''
    });
    const [number, Setnumber] = useState({
        emp_contact_country_code: '',
        emp_contact_code: '',
        emp_contact_no: '',
        mobile_no_country_code: '',
        mobile_no: '',
        address: ''

    });

    // const [Entity, setEntity] = useState("All Legal Entity")
    const onUserDetailOnChange = (e) => {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value
        })
        Setnumber({
            ...number,
            [e.target.name]: e.target.value
        })
    }

    // const passwordmodel = (e) => {
    //     setPasswordcard(true)

    // }

    useEffect(() => {
        if (isAuth && formdata.form_key) {

            UserProfile({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetUserProfile",
                            {}
                        ]
                    }
                ],
                paramid: paramid,
                formkey: formdata.form_key
            })
        }
    }, [isAuth, formdata.form_key])

    useEffect(() => {
        if (profiledata && profiledata.user_profile && profiledata.user_profile.length > 0) {
            let text = profiledata.user_profile[0].mob_no;
            const myArray = text.split("-");
            let text1 = profiledata.user_profile[0].con_no;
            const temparr = text1.split("-");
            Setnumber({
                ...number,
                emp_contact_country_code: temparr[0],
                emp_contact_code: temparr[1],
                emp_contact_no: temparr[2],
                mobile_no_country_code: myArray[0],
                mobile_no: myArray[1]
            })

        }
    }, [profiledata])

    useEffect(() => {
        if (isAuth) {
            const getUrl = callername.login_response.menu['My Accounts']
            const profiles = _.filter(getUrl, { form_name: 'View Profile' })
            const ChangePassword = _.filter(getUrl, { form_name: 'Change Password' })
            // Setformurl(profile[0].form_url)
            Setchangepassword(ChangePassword[0].form_url)
            Setformdata({
                ...formdata,
                form_url: profiles[0].form_url,
                form_key: profiles[0].form_key
            })
        }
    }, [isAuth])

    useEffect(() => {
        validator.current.showMessages()
        formvalidator.current.showMessages()
    }, [])

    // const SubmitPassword = () => {
    //     setAddFormSubmit1(true);
    //     if (formvalidator.current.allValid()) {
    //         if (password.new_password === password.confirm_password) {
    //             Updatepassword({
    //                 payload: [
    //                     authtoken,
    //                     [
    //                         "ChangePassword",
    //                         {
    //                             "session_token": "undefined-" + authtoken,
    //                             "current_password": password.current_password,
    //                             "new_password": password.new_password,
    //                             "confirm_password": password.confirm_password
    //                         }
    //                     ]
    //                 ],
    //                 paramid: paramid,
    //                 formkey: formdata.form_key
    //             })
    //         } else {
    //             toast.error("New password and Confirn password Should Match")
    //         }
    //     }
    // }

    const userDetailOnSubmit = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            UpdateProfile({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateUserProfile",
                            {
                                "email_id": profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].email_id : '',
                                "con_no": number.emp_contact_country_code + "-" + number.emp_contact_code + "-" + number.emp_contact_no,
                                "mob_no": number.mobile_no_country_code + "-" + number.mobile_no,
                                "address": number.address ? number.address : (profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].address : ''),
                                "emp_code": profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].emp_code : '',
                                "emp_name": profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].emp_name : ''
                            }
                        ]
                    }
                ],
                paramid: paramid,
                formkey: formdata.form_key
            })
        }
    }

    const homescreen = ()=>{
        window.location.href= '/dashboard'
    }

    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-1">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>My Accounts</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large' }}>
                                            <Link>My Profile</Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="
        col-lg-4 col-md-6
        d-none d-md-flex
        align-items-center
        justify-content-end
      "
                            >
                            </div>
                        </div>
                    </div>
                    {/* {passwordcard == false ? */}
                        <div className="page-titles  pb-2 pt-1">
                            <div className="container-fluid pt-1">
                                <div className="row">
                                    <div className="col-12">
                                        <div className='card'>
                                            <div className="card-body">
                                                <div className="col-md-12">
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label><b>Employee Name</b></label>
                                                                <p>{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].emp_name : ''}</p>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label><b>Employee ID</b></label>
                                                                <p>{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].emp_code : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12' >
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label><b>Short Name</b></label>
                                                                <p>{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].short_name : ''}</p>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label><b>User ID</b></label>
                                                                <p>{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].user_name : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label><b>Email ID</b><span style={{ "color": "red" }}>*</span></label>
                                                                <p>{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].email_id : ''}</p>
                                                                {/* {validator.current.message(
                                                            'emailId',
                                                            profile.email_id,
                                                            ['required', {
                                                                regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            }],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Required Field Missing',
                                                                    regex: 'Enter valid Email'
                                                                }
                                                            })} */}
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label><b>User Group</b></label>
                                                                <p>{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].u_g_name : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label><b>Contact No</b></label>
                                                                <Input.Group size="default">
                                                                    <Row gutter={8}>
                                                                        <Col span={4}>
                                                                            <Input placeholder='+00' value={number.emp_contact_country_code} name='emp_contact_country_code' onChange={onUserDetailOnChange} />
                                                                            {validator.current.message(
                                                                                'emp_contact_country_code',
                                                                                number.emp_contact_country_code,
                                                                                [{ regex: /^(\+?\d{1,3}|\d{1,4})$/ }],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        regex: 'Enter valid mobile number'
                                                                                    }
                                                                                })}
                                                                        </Col>
                                                                        <Col span={4}>
                                                                            <Input placeholder='000' value={number.emp_contact_code} name='emp_contact_code' onChange={onUserDetailOnChange} />
                                                                            {validator.current.message(
                                                                                'emp_contact_code',
                                                                                profile.emp_contact_code,
                                                                                [{ regex: /^(\+\d{1,3}[- ]?)?\d{3}$/ }],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        regex: 'Enter valid mobile number'
                                                                                    }
                                                                                })}
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <Input placeholder='1234567890' value={number.emp_contact_no} name='emp_contact_no' onChange={onUserDetailOnChange} />
                                                                            {validator.current.message(
                                                                                'emp_contact_no',
                                                                                profile.emp_contact_no,
                                                                                [{ regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        regex: 'Enter valid mobile number'
                                                                                    }
                                                                                })}
                                                                        </Col>
                                                                    </Row>
                                                                </Input.Group>
                                                                [+91 452 1234567]
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label><b>Address</b></label>
                                                                <TextArea rows={3} name="address" id="" value={number.address} className='form-control' onChange={onUserDetailOnChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label htmlFor=""><b>Mobile No</b></label> <span style={{ "color": "red" }}>*</span>
                                                                <Input.Group size="default">
                                                                    <Row gutter={8}>
                                                                        <Col span={4}>
                                                                            <Input placeholder='+00' name='mobile_no_country_code' onChange={onUserDetailOnChange} value={number.mobile_no_country_code} />
                                                                            {validator.current.message(
                                                                                'mobile_no_country_code',
                                                                                number.mobile_no_country_code,
                                                                                ['required', { regex: /^(\+?\d{1,3}|\d{1,4})$/ }],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Required Field Missing',
                                                                                        regex: 'Enter valid mobile number'
                                                                                    }
                                                                                })}
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <Input placeholder='1234567890'
                                                                                value={number.mobile_no}
                                                                                name='mobile_no'
                                                                                onChange={onUserDetailOnChange} />
                                                                            {validator.current.message(
                                                                                'mobile_no',
                                                                                number.mobile_no,
                                                                                ['required', { regex:/^[0-9\b]+$/ }, `min:10`, `max:10`],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Required Field Missing',
                                                                                        regex: 'Enter Numbers Only',
                                                                                        min: 'Mobile Number should be 10 Numbers',
                                                                                        max: 'Mobile Number should be 10 Numbers'
                                                                                    }
                                                                                })}
                                                                        </Col>

                                                                    </Row>
                                                                </Input.Group>
                                                                [+91 9876543210]
                                                            </div>
                                                            <div className='col-md-6'>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><br />
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-3'></div>
                                                        <div className='col-md-6'>
                                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px", width: "100px" }} onClick={userDetailOnSubmit}
                                                            >
                                                                Submit
                                                            </Button>
                                                            {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852", width: "150px" }}
                                                                onClick={passwordmodel}
                                                            >
                                                                Change password
                                                            </Button> */}
                                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852", width: "150px" }}>
                                                            <Link to={changepassword ? changepassword : ''}
                                                                >
                                                                        Change Password
                                                                   
                                                            </Link>
                                                            </Button>
                                                        </div>
                                                        <div className='col-md-3'></div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-5 d-none d-md-flex">
                                                    <Link className="btn btn-light-primary text-primary btn-lg px-4 fs-4" onClick={userDetailOnSubmit}
                                                    >Submit</Link>
                                                </div>
                                                <div className="col-lg-6 col-md-5 d-none d-md-flex">
                                                    <Link to='/changePassword' className="btn btn-light-primary text-primary btn-lg px-4 fs-4"
                                                        style={{ 'marginRight': '50%' }}
                                                    >Change Password</Link>
                                                </div>
                                            </div>
                                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* : 
                        <div className="page-titles  pb-2 pt-1">
                            <Card title='Change Password'>
                                <div className="container-fluid pt-1">
                                    <div className="row">
                                        <div className="col-12">
                                             <div className="card" >
                                             <div className="card-body" > 
                                            <div className="col-md-12">
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label><b>Current Password</b><span style={{ color: "red" }}>*</span></label>
                                                            <input onmouseenter="hjdsbfdjsfb" placeholder="Enter Current Password"
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    setPassword({
                                                                        ...password,
                                                                        current_password: e.target.value
                                                                    })
                                                                }} />
                                                            {formvalidator.current.message(
                                                                'Password',
                                                                password.current_password,
                                                                ['required', { regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        regex: 'Enter Strong Password'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-4'>
                                                        </div>
                                                        <div className='col-md-6'>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label><b>New Password</b><span style={{ color: "red" }}>*</span></label>
                                                            <input onmouseenter="hjdsbfdjsfb" placeholder="Enter New Password" style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    setPassword({
                                                                        ...password,
                                                                        new_password: e.target.value
                                                                    })
                                                                }} />
                                                            {formvalidator.current.message(
                                                                'Password',
                                                                password.new_password,
                                                                ['required', { regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        regex: 'Enter Strong Password'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label><b>Confirm Password</b><span style={{ color: "red" }}>*</span></label>
                                                            <input placeholder="Enter Confirm Password" style={{ width: '100%' }} onChange={(e) => {
                                                                setPassword({
                                                                    ...password,
                                                                    confirm_password: e.target.value
                                                                })
                                                            }} />
                                                            {formvalidator.current.message(
                                                                'Password',
                                                                password.confirm_password,
                                                                ['required', { regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        regex: 'Enter Strong Password'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-6'>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                        </div>

                                                        <div className='col-md-6'>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><br />
                                            <div className='col-md-12' >
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4'>
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px", width: "100px" }} onClick={SubmitPassword}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     </div> 
                                     </div> 

                                </div>
                            </Card>
                        </div> */}

                </div >

            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    UserProfile,
    UpdateProfile,
    Updatepassword
})(MyProfile);