import { COMPLIANCE_STATUS_CHART, COMMON_DATA, TOGGLE_COMMON_LOADER,SHOW_MORE_TABLE_DATA, GET_CRITICALITY_STATUS, COMPLIANCE_STATUS_TABLE,TIME_ZONE_DATA } from './../../types/index'
const initialState = {
    complianceStatusChart: {
        chartData: [],
        time: 0,
        Data: [],
        criticality: [],
        Table: [],
        timeZoneData:[],
        showMoreData:[]

    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case COMPLIANCE_STATUS_CHART: {
            console.log(payload, "payload4444");
            return {
                ...state,
                complianceStatusChart: {
                    ...state.complianceStatusChart,
                    chartData: payload.chart_data,
                    
                    time: payload.current_date
                },
            };
        }
        case COMPLIANCE_STATUS_TABLE: {
            console.log(payload, "payload4444");
            return {
                ...state,
                complianceStatusChart: {
                    ...state.complianceStatusChart,
                    Table: payload.drill_down_data


                    ,
                },
            };
        }
        case COMMON_DATA: {
            console.log(payload, "payload4444");
            return {
                ...state,
                complianceStatusChart: {
                    ...state.complianceStatusChart,
                    Data: payload,
                },
            };
        }
        case GET_CRITICALITY_STATUS: {
            console.log(payload, "payload4444");
            return {
                ...state,
                complianceStatusChart: {
                    ...state.complianceStatusChart,
                    chartData: payload.chart_data
                    ,
                    time: payload.current_date
                },
            };
        }
        case TIME_ZONE_DATA:{
            return {
                ...state,
                complianceStatusChart: {
                    ...state.complianceStatusChart,
                    timeZoneData: payload,
                },
            };
        }
        case SHOW_MORE_TABLE_DATA:{

            return {

                ...state,

                complianceStatusChart: {

                    ...state.complianceStatusChart,

                    showMoreData: payload,

                },

            };

        }
        default:
            return state;
    }
}
