import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { APPROVE_TASK_RECALL,APPROVE_TASK_RECALL_LIST } from '../../types/index'

const initialState = {
    AssignTaskRecall: {
        unitdata: '',
        domain: "",
       frequency:"",
       approverecallcompliance: '',
       approverecallcompliancestatus: '',
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case APPROVE_TASK_RECALL: {
            return {
                ...state,
                AssignTaskRecall: {
                    ...state.AssignTaskRecall,
                    unitdata:payload.user_units,
                    domain:payload.domains_list,
                   frequency:payload.frequency_list
                    
                },
            };
        }
        case APPROVE_TASK_RECALL_LIST: {
            return {
                ...state,
                AssignTaskRecall: {
                    ...state.AssignTaskRecall,
                    approverecallcompliance:payload.recall_compliances,
                //     domain:payload.domains_list,
                //    frequency:payload.frequency_list
                    
                },
            };
        }
       
        default:
            return state;
    }
}