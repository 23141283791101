import {APPROVERSCORECARDDATA,APPROVERSCORECARDTABLEDATA,TOGGLE_COMMON_LOADER } from './../../types/index'
const initialState = {
    approverdata: {
       getapproverdata:[],
       tabledata:[]
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case APPROVERSCORECARDDATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                approverdata: {
                    ...state.approverdata,
                    getapproverdata: payload,
                },
            };
        }
        case APPROVERSCORECARDTABLEDATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                approverdata: {
                    ...state.approverdata,
                    tabledata: payload,
                },
            };
        }
        default:
            return state;
    }
}
