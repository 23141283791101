import { RISK_REPORT,GET_TABLE_DATA,EXPORT_DATA,DOWNLOADPATH,RISK_DOWNLOAD,AUDIT_TRAIL_REPORT_EXPORT_RESET } from '../../types/index'

const initialState = {
    riskReport: {
        data: [],
        getTableData:[],
        downloadPath:[],
        downloadFile:[]
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case RISK_REPORT: {
            return {
                ...state,
                riskReport: {
                    ...state.riskReport,
                    data:payload
                },
            };
        }
        case GET_TABLE_DATA: {
            return {
                ...state,
                riskReport: {
                    ...state.riskReport,
                    getTableData:payload
                },
            };
        }
        case EXPORT_DATA: {
            return {
                ...state,
                riskReport: {
                    ...state.riskReport,
                    downloadPath:payload
                },
            };
        }
        case DOWNLOADPATH:
            {
                return {
                    ...state,
                    riskReport: {
                        ...state.riskReport,
                        downpath: payload[1],
                    },
                };
            }

            case RISK_DOWNLOAD:
            return {
                ...state,
                riskReport: {
                    ...state.riskReport,
                    downloadFile: payload,
                },
            };
            case AUDIT_TRAIL_REPORT_EXPORT_RESET:
                return {
                    ...state,
                    riskReport: {
                        ...state.riskReport,
                        downloadFile: [],
                    },
                };
        default:
            return state;
    }
}
