import { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { RadialBar, Pie, Line } from '@ant-design/plots';


const mapStateToProps = (state) => ({
})

const LandingPage3 = ({
}) => {
    const data = [
        { name: 'Low', star: 200, },
        { name: 'Medium', star: 500, },
        { name: 'High', star: 800, },];

    const config =
    {
        data, xField: 'name', yField: 'star', maxAngle: 270, radius: 0.8,
        innerRadius: 0.2, tooltip: {
            formatter: (datum) => { return { name: datum.name, value: datum.star, }; },
        },
        colorField: 'star', color: ({ star }) => {
            if (star < 250) { return '#C04000'; } else if (star < 550) { return '#A52A2A'; } else { return '#FF0000'; }
        },
    };

    useEffect(() => {
        var chart = am4core.create("chartdiv", am4charts.XYChart);


        // Add data
        chart.data = [{
            "year": "India",
            "Complied": 19,
            "DelayedCompliance": 22,
            "Inprogress": 25,
            "NotComplied": 15,
        }, {
            "year": "Srilanka",
            "Complied": 30,
            "DelayedCompliance": 45,
            "Inprogress": 20,
            "NotComplied": 12,
        }, {
            "year": "Australia",
            "Complied": 25,
            "DelayedCompliance": 14,
            "Inprogress": 45,
            "NotComplied": 35,
        }];
        chart.colors.list = [am4core.color("#003B46"), am4core.color("#07575B"), am4core.color("#66A5AD"), am4core.color("#C4DFE6")];
        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "year";
        categoryAxis.renderer.grid.template.location = 0;


        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.min = 0;

        // Create series
        function createSeries(field, name) {

            // Set up series
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.name = name;
            series.dataFields.valueY = field;
            series.dataFields.categoryX = "year";
            series.sequencedInterpolation = true;

            // Make it stacked
            series.stacked = true;

            // Configure columns
            series.columns.template.width = am4core.percent(60);
            series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

            // Add label
            var labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.text = "{valueY}";
            labelBullet.locationY = 0.5;
            labelBullet.label.hideOversized = true;

            return series;
        }

        createSeries("Complied", "Complied");
        createSeries("DelayedCompliance", "Delayed Compliance");
        createSeries("Inprogress", "Inprogress");
        createSeries("NotComplied", "Not Complied");

        // Legend
        chart.legend = new am4charts.Legend();


        //Comprative Trend
        var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart);

        Comprativechart.data = [{
            "year": "2018",
            "india": 23.5,
            "lineColor": Comprativechart.colors.next()
        }, {
            "year": "2019",
            "india": 26.2,
        }, {
            "year": "2020",
            "india": 30.1,
        }, {
            "year": "2021",
            "india": 20.5,
        }, {
            "year": "2022",
            "india": 30.6,
            "lineColor": Comprativechart.colors.next()
        }];

        var ComprativecategoryAxis = Comprativechart.xAxes.push(new am4charts.CategoryAxis());
        ComprativecategoryAxis.renderer.grid.template.location = 0;
        ComprativecategoryAxis.renderer.ticks.template.disabled = true;
        ComprativecategoryAxis.renderer.line.opacity = 0;
        ComprativecategoryAxis.renderer.grid.template.disabled = true;
        ComprativecategoryAxis.renderer.minGridDistance = 40;
        ComprativecategoryAxis.dataFields.category = "year";
        ComprativecategoryAxis.startLocation = 0.4;
        ComprativecategoryAxis.endLocation = 0.6;


        var ComprativevalueAxis = Comprativechart.yAxes.push(new am4charts.ValueAxis());
        ComprativevalueAxis.tooltip.disabled = true;
        ComprativevalueAxis.renderer.line.opacity = 0;
        ComprativevalueAxis.renderer.ticks.template.disabled = true;
        ComprativevalueAxis.min = 0;

        var ComprativelineSeries = Comprativechart.series.push(new am4charts.LineSeries());
        ComprativelineSeries.dataFields.categoryX = "year";
        ComprativelineSeries.dataFields.valueY = "india";
        ComprativelineSeries.tooltipText = "india: {valueY.value}";
        ComprativelineSeries.fillOpacity = 0.5;
        ComprativelineSeries.strokeWidth = 3;
        ComprativelineSeries.propertyFields.stroke = "lineColor";
        ComprativelineSeries.propertyFields.fill = "lineColor";

        var Comprativebullet = ComprativelineSeries.bullets.push(new am4charts.CircleBullet());
        Comprativebullet.circle.radius = 6;
        Comprativebullet.circle.fill = am4core.color("#fff");
        Comprativebullet.circle.strokeWidth = 3;

        Comprativechart.cursor = new am4charts.XYCursor();
        Comprativechart.cursor.behavior = "panX";
        Comprativechart.cursor.lineX.opacity = 0;
        Comprativechart.cursor.lineY.opacity = 0;

        Comprativechart.scrollbarX = new am4core.Scrollbar();
        Comprativechart.scrollbarX.parent = chart.bottomAxesContainer;

        //Hidden Risk
        var hiddenchart = am4core.create("chartdivHidden", am4charts.XYChart3D);
        hiddenchart.data = [{
            "year": 'Unassigned',
            "income": 2356,
            "color": '#FF0000'
        }, {
            "year": 'Not Opted',
            "income": 2660,
            "color": '#A52A2A'
        }, {
            "year": 'Not Applicable',
            "income": 27,
            "color": '#E97451'
        }];

        // Create axes
        var hiddencategoryAxis = hiddenchart.yAxes.push(new am4charts.CategoryAxis());
        hiddencategoryAxis.dataFields.category = "year";
        hiddencategoryAxis.numberFormatter.numberFormat = "#";
        hiddencategoryAxis.renderer.inversed = true;

        var valueAxis = hiddenchart.xAxes.push(new am4charts.ValueAxis());

        // Create series
        var series = hiddenchart.series.push(new am4charts.ColumnSeries3D());
        series.dataFields.valueX = "income";
        series.dataFields.categoryY = "year";
        series.name = "Income";
        series.columns.template.propertyFields.fill = "color";
        series.columns.template.tooltipText = "{valueX}";
        series.columns.template.column3D.stroke = am4core.color("#fff");
        series.columns.template.column3D.strokeOpacity = 0.2;

        //exposed Risk
        var exposedchart = am4core.create("chartdivexpose", am4charts.XYChart);
        exposedchart.data = [{
            "year": 'Not Complied',
            "income": 562,
            "color": '#AA336A'
        }, {
            "year": 'Delayed Compliance',
            "income": 235,
            "color": '#3F00FF'
        }];

        // Create axes
        var exposedcategoryAxis = exposedchart.yAxes.push(new am4charts.CategoryAxis());
        exposedcategoryAxis.dataFields.category = "year";
        exposedcategoryAxis.numberFormatter.numberFormat = "#";
        exposedcategoryAxis.renderer.inversed = true;

        var valueAxis = exposedchart.xAxes.push(new am4charts.ValueAxis());

        // Create series
        var exposedseries = exposedchart.series.push(new am4charts.ColumnSeries3D());
        exposedseries.dataFields.valueX = "income";
        exposedseries.dataFields.categoryY = "year";
        exposedseries.name = "Income";
        exposedseries.columns.template.propertyFields.fill = "color";
        exposedseries.columns.template.tooltipText = "{valueX}";
        exposedseries.columns.template.column3D.stroke = am4core.color("#fff");
        exposedseries.columns.template.column3D.strokeOpacity = 0.2;

    }, [])

    const piedata = [
        {
            type: 'Assigned',
            value: 835,
        },
        {
            type: 'Unassigned',
            value: 2356,
        },
        {
            type: 'Not Opted',
            value: 2660,
        },
        {
            type: 'Not Applicable',
            value: 27,
        },
    ];

    const pieconfig = {
        appendPadding: 30,
        data: piedata,
        angleField: 'value',
        colorField: 'type',
        radius: 0.75,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{value}',
        },
        legend: {
            position: 'bottom'
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    };

    const compliancedata = [
        {
            "name": "Complied",
            "year": "2018",
            "gdp": 50
        },
        {
            "name": "Complied",
            "year": "2019",
            "gdp":  130
        },
        {
            "name": "Complied",
            "year": "2020",
            "gdp": 80
        },
        {
            "name": "Complied",
            "year": "2021",
            "gdp": 70
        },
        {
            "name": "Complied",
            "year": "2022",
            "gdp": 180
        },        
        {
            "name": "Delayed Compliance",
            "year": "2018",
            "gdp":80
        },
        {
            "name": "Delayed Compliance",
            "year": "2019",
            "gdp": 100
        },
        {
            "name": "Delayed Compliance",
            "year": "2020",
            "gdp": 60
        },
        {
            "name": "Delayed Compliance",
            "year": "2021",
            "gdp": 200
        },
        {
            "name": "Delayed Compliance",
            "year": "2022",
            "gdp": 150
        },        
        {
            "name": "Not Complied",
            "year": "2018",
            "gdp": 20
        },
        {
            "name": "Not Complied",
            "year": "2019",
            "gdp": 80
        },
        {
            "name": "Not Complied",
            "year": "2020",
            "gdp": 70
        },
        {
            "name": "Not Complied",
            "year": "2021",
            "gdp": 140
        },
        {
            "name": "Not Complied",
            "year": "2022",
            "gdp": 140
        },        
    ];

    const complianceconfig = {
        data: compliancedata,
        xField: 'year',
        yField: 'gdp',
        seriesField: 'name',
        yAxis: 'gdp',
        legend: {
            position: 'bottom',
        },
        smooth: true,        
        animation: {
            appear: {
                animation: 'path-in',
                duration: 10000,
            },
        },
    };

    return (
        <div className="page-wrapper" >
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                        </nav>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="card" style={{ height: '520px' }}>
                                        <div className="card-header">
                                            <h3 className="card-title">Compliance Trend</h3>
                                        </div>
                                        <div className="card-body">
                                            <Line {...complianceconfig} />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Comparative Trend</h3>
                                        </div>
                                        <div className="card-body">
                                            <div id="Comprativechartdiv" style={{ height: '400px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Compliance Status</h3>
                                        </div>
                                        <div className="card-body">
                                            <div id="chartdiv" style={{ height: '400px' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Task Distribution</h3>
                                        </div>
                                        <div className="card-body">
                                            <Pie {...pieconfig} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className="card" style={{ height: '350px' }}>
                                        <div className="card-header">
                                            <h3 className="card-title">Over Due Compliance</h3>
                                        </div>
                                        <div className="card-body">
                                            <canvas id="bar-chart" height="150"></canvas>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="card" style={{ height: '350px' }}>
                                        <div className="card-header">
                                            <h3 className="card-title">Criticality Chart</h3>
                                        </div>
                                        <div className="card-body analytics-info">
                                            <RadialBar {...config} height={270} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='card'>
                                <div className="card-header mb-3">
                                    <h3 className="card-title">
                                        <span>Risk Chart- Hidden Risk</span>
                                        <span style={{ marginLeft: '380px' }}>Risk Chart- Exposed Risk</span>
                                    </h3>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div id="chartdivHidden" style={{ "height": "300px" }}></div>
                                        </div>
                                        <div className='col-6'>
                                            <div id="chartdivexpose" style={{ "height": "300px" }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, {
})(LandingPage3);