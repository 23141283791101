import { ASSIGNEESCOREFILTER,ASSIGNEESCORETABLE} from '../../types/index'

const initialState = {
    assigneescoredata: {
        domain_data: [],
        getTableData:[],
        exportData:[]
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ASSIGNEESCOREFILTER: {
         
            
            return {
                ...state,
                assigneescoredata: {
                    ...state.assigneescoredata,
                    domain_data:payload.domains
                },
            };
        }

        case ASSIGNEESCORETABLE: {
            console.log("HIIIIII");
            console.log(payload, "assigneescoredata");
            return {
                ...state,
                assigneescoredata: {
                    ...state.assigneescoredata,
                    getTableData:payload
                },
            };
        }
        default:
            return state;
    }
}
