import { NOTIFICATIONREPORT,NOTIFICATIONTABLEREPORT, TOGGLE_COMMON_LOADER } from './../../types/index'
const initialState = {
    notificationreport: {
        getdata: [],
        tabledata:[]
    }
}
export default function (state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
        case NOTIFICATIONREPORT: {
            return {
                ...state,
                notificationreport: {
                    ...state.notificationreport,
                    getdata: payload,
                },
            };
        }
        case NOTIFICATIONTABLEREPORT: {
            return {
                ...state,
                notificationreport: {
                    ...state.notificationreport,
                    tabledata: payload,
                },
            };
        }
        default:
            return state;
    }
}
