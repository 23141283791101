import React from 'react'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Popover, Drawer, Button, Table, Tooltip, Modal, Descriptions, Empty, Space } from 'antd';
import DataTable from "react-data-table-component";
import moment from 'moment';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ArrowRightOutlined, CheckOutlined, CloseOutlined, CloseCircleOutlined, ArrowLeftOutlined, BookTwoTone, PlayCircleOutlined, ExclamationCircleTwoTone, SearchOutlined, FilterFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getCommonData, getRiskchart, getHiddenRiskShowMore, getRiskChartTableData, getRiskChartDelayedTableData, getTimeZoneData, getRemarksHistoryData, getRiskExposedTrendShowMoreTableData } from '../../Store/Action/Dashboard/RiskChart';
import { toast } from 'react-toastify';

// import "core-js/stable";
// import "regenerator-runtime/runtime";

const mapStateToProps = (state) => ({
    data: state.RiskChart.riskChart.chartData,
    time: state.RiskChart.riskChart.time,
    commonData: state.RiskChart.riskChart.Data,
    tableData: state.RiskChart.riskChart.tableData,
    tableData1: state.RiskChart.riskChart.delayedTableData,
    timeZoneData: state.RiskChart.riskChart.timeZoneData,
    remarksHistoryData: state.RiskChart.riskChart.remarksHistoryData,
    showMoreData: state.RiskChart.riskChart.showMoreData



})

const RiskChart = ({
    // getFilterValue,
    getRiskchart,
    getCommonData,
    data,
    commonData,
    getRiskChartTableData,
    getHiddenRiskShowMore,
    getRiskChartDelayedTableData,
    time,
    // getCriticalityTableData,
    tableData,
    tableData1,
    getTimeZoneData,
    timeZoneData,
    remarksHistoryData,
    getRemarksHistoryData,
    getRiskExposedTrendShowMoreTableData,
    showMoreData

}) => {
    const _ = require("lodash");
    const [open, setOpen] = useState(false)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [chartId, setChartId] = useState()
    const [table, setTable] = useState(false)
    const chart = useRef(null);
    const { Option } = Select;
    const { Panel } = Collapse;
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const [entityData, setEntityData] = useState([])
    const [legalEntityArray, setLegalEntityArray] = useState([])
    const [showmoreCount, setShowMoreCount] = useState(0)
    const [dataCount, setDataCount] = useState()
    const [criticalityTable, setCriticalityTable] = useState([])
    const [delayedChart, setDelayedChart] = useState([])
    const [criticalityValue, setCriticalityValue] = useState()
    const [year, setYear] = useState(moment().year())
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const [generatedOn, setGeneratedOn] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    const location = useLocation();
    // const paramid = location.pathname;
    const [paramid, setParamId] = useState("")
    const [filterData, setFilterData] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],

    })
    const [filterDataNames, setFilterDataNames] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    var [count, setCount] = useState(1)
    const [go, setGo] = useState(false)
    const [countValue, setCountValue] = useState(0)
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [unit, setUnit] = useState([])
    const [hiddenRiskData, setHiddenRiskData] = useState([])
    const [exposedRiskData, setExposedRiskData] = useState([])
    const [chartValue, setChartValue] = useState('')
    const [reviewerReportValue, setReviewerReportValue] = useState([])
    const [show, setShow] = useState(false)
    const [domainName, setDomainName] = useState()
    const [fieldType, setFieldType] = useState("")
    const { OptGroup } = Select;
    const [domainListData, setDomainListData] = useState([])
    const [divisionListData, setDivisionListData] = useState([])
    const [categoryListData, setCategoryListData] = useState([])
    const [unitListData, setUnitListData] = useState([])
    const [bgIds, setBgIds] = useState([])
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [checkBox, setCheckBox] = useState(true)
    const [countryDomain, setCountryDomain] = useState([])
    const [columnState, setCoulmnState] = useState(false)
    const [dIds, setDIds] = useState([])
    const country_info = sessionParsedValue.country_info
    const [type, setType] = useState('')

    console.log(showMoreData.r_drill_down_data, 'showMoreData');
    console.log(criticalityTable, 'criticalityTable');
    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            the_arr.pop();
            let a = the_arr.join('/')
            setParamId(a)
        }

    }, []);

    useEffect(() => {
        if (data) {
            setHiddenRiskData(data.risk_chart_data_nu)
            setExposedRiskData(data.risk_chart_data_dn)
        }
    }, [data])

    console.log(data, 'data12345');

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            setDomainName(domainNameArray)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    useEffect(() => {
        if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApplicabilityStatusChart",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": dIds,
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
                            "country_domains": countryDomain,
                            "chart_year": year
                        }
                    ]
                }
            ]

            if (paramid != "") {
                getRiskchart({
                    payload: payload,
                    paramid: paramid
                })
            }

        }

    }, [entityid, entityData, countryDomain, year, dIds, commonData])

    useEffect(() => {
        if (commonData) {
            let unique = []
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                unique = _.uniqBy(commonData && commonData.bg_groups, function (e) {
                    return e.bg_id;
                });
            }
            setfield({
                ...field,
                domain: commonData && commonData.d_info,
                legalEntity: commonData && commonData.le_did_infos,
                country: commonData && commonData.countries,
                business: unique
            })
            let uniqueDiv = []
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                uniqueDiv = _.uniqBy(commonData && commonData.div_infos, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)
            setCategory(commonData && commonData.cat_info)
            setUnit(commonData && commonData.chart_units)
        }

    }, [isAuth, entityid, commonData])


    useEffect(() => {
        if (chartValue == 'hiddenRisk') {
            if (criticalityTable && criticalityTable.length > 0) {
                let count = []
                for (let i in criticalityTable) {
                    for (let j in criticalityTable[i].ap_compliances) {
                        let a = criticalityTable[i].ap_compliances[j]
                        let data = Object.values(a)


                        count.push(data.length)

                        // count.push(data[i])
                        console.log(data, ' taskDistributionTable[i].ap_compliances');

                    }
                }
                console.log(count, 'count');
                let countValues = count.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                setShowMoreCount(countValues)
                console.log(countValues, 'countValue9999');
            }
        }
        else {
            if (delayedChart && delayedChart.length > 0) {
                let count = []
                for (let i in delayedChart) {
                    for (let j in delayedChart[i].drill_compliances) {
                        let a = delayedChart[i].drill_compliances[j]
                        let data = Object.values(a)


                        count.push(data.length)

                        // count.push(data[i])
                        console.log(data, ' taskDistributionTable[i].ap_compliances');

                    }
                }
                console.log(count, 'count');
                let countValues = count.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                setShowMoreCount(countValues)
                console.log(countValues, 'countValue9999');
            }
        }

    }, [criticalityTable, delayedChart])



    useEffect(() => {
        if (chartValue == 'hiddenRisk') {
            // if (showMoreData && showMoreData.r_drill_down_data && showMoreData.r_drill_down_data.length > 0) {
            //     let temp = []
            //     let temp2 = []
            //     console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data');
            //     for (let i in criticalityTable) {
            //         for (let j in showMoreData.r_drill_down_data) {

            //             // console.log(taskDistributionTable[i].level1_statutory_name == showMoreData.r_drill_down_data[j].level1_statutory_name, 'taskDistributionTable[i].level1_statutory_name == showMoreData.r_drill_down_data[j].level1_statutory_name');
            //             if (criticalityTable[i].level1_statutory_name == showMoreData.r_drill_down_data[j].level1_statutory_name) {
            //                 let obj = criticalityTable[i].ap_compliances
            //                 let objdata = showMoreData.r_drill_down_data[j].ap_compliances
            //                 let newArray = { ...obj, ...objdata }
            //                 // var newArray = Object.assign({}, taskDistributionTable[i].ap_compliances, showMoreData.r_drill_down_data[j].ap_compliances);
            //                 criticalityTable[i].ap_compliances = newArray
            //                 temp2.push(criticalityTable[i].level1_statutory_name)
            //                 // setTaskDistributionTable(showMoreData.r_drill_down_data)
            //             }

            //         }
            //     }
            //     console.log(temp2, 'temp2');
            //     for (let i in showMoreData.r_drill_down_data) {

            //         if ((temp2.includes(showMoreData.r_drill_down_data[i].level1_statutory_name)) == false) {
            //             // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
            //             console.log(showMoreData.r_drill_down_data[i], 'datadata');
            //             temp.push(showMoreData.r_drill_down_data[i])
            //         }



            //     }

            //     setCriticalityTable([...criticalityTable, ...temp])



            // }

            if (showMoreData && showMoreData.r_drill_down_data && showMoreData.r_drill_down_data.length > 0) {
                let temp = []
                let temp2 = []
                console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data');
                for (let i in criticalityTable) {
                    for (let j in showMoreData.r_drill_down_data) {
                        if (criticalityTable[i].level1_statutory_name == showMoreData.r_drill_down_data[j].level1_statutory_name) {
                            for (let s in criticalityTable[i].ap_compliances) {
                                for (let a in showMoreData.r_drill_down_data[j].ap_compliances) {
                                    console.log(s, 'taskDistributionTable[i].ap_compliances[s]');
                                    console.log(showMoreData.r_drill_down_data[j].ap_compliances[a], 'showMoreData.r_drill_down_data[j].ap_compliances[a]');
                                    if (s == a) {
                                        let arr1 = criticalityTable[i].ap_compliances[s]
                                        let arr2 = showMoreData.r_drill_down_data[j].ap_compliances[a]
                                        let arr3 = arr1.concat(arr2)
                                        console.log(arr3, 'arr3');
                                        criticalityTable[i].ap_compliances[s] = arr3
                                        console.log(s, 'sneha');
                                    }
                                    else {
                                        criticalityTable[i].ap_compliances[a] = showMoreData.r_drill_down_data[j].ap_compliances[a]
                                    }
                                }
                            }
                            // let newArray = { ...obj, ...objdata }

                            // var newArray = Object.assign({}, taskDistributionTable[i].ap_compliances, showMoreData.r_drill_down_data[j].ap_compliances);
                            // taskDistributionTable[i].ap_compliances = newArray
                            temp2.push(criticalityTable[i].level1_statutory_name)
                            // setTaskDistributionTable(showMoreData.r_drill_down_data)
                        }

                    }
                }
                console.log(temp2, 'temp2');
                for (let i in showMoreData.r_drill_down_data) {

                    if ((temp2.includes(showMoreData.r_drill_down_data[i].level1_statutory_name)) == false) {
                        // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
                        console.log(showMoreData.r_drill_down_data[i], 'datadata');
                        temp.push(showMoreData.r_drill_down_data[i])
                    }



                }

                setCriticalityTable([...criticalityTable, ...temp])



            }


        }
    }, [showMoreData])
    useEffect(() => {
        if (chartValue == 'exposedRisk') {
            let temp = []
            let temp2 = []
            // if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
            //     for (let i in delayedChart) {
            //         for (let j in showMoreData.drill_down_data) {
            //             // if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
            //             //     var newArray = Object.assign({}, delayedChart[i].drill_compliances, showMoreData.drill_down_data[j].drill_compliances);
            //             //     console.log(newArray, 'newArray');
            //             //     delayedChart[i].drill_compliances = newArray
            //             // }
            //             if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
            //                 let array = delayedChart[i].drill_compliances
            //                 var newArray = Object.assign({}, delayedChart[i].drill_compliances, showMoreData.drill_down_data[j].drill_compliances);
            //                 delayedChart[i].drill_compliances = newArray
            //                 temp2.push(delayedChart[i].u_name)
            //             }
            //             for (let i in showMoreData.drill_down_data) {

            //                 if ((temp2.includes(showMoreData.drill_down_data[i].u_name)) == false) {
            //                     // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
            //                     console.log(showMoreData.drill_down_data[i], 'datadata');
            //                     temp.push(showMoreData.drill_down_data[i])
            //                 }



            //             }

            //             setDelayedChart([...delayedChart, ...temp])

            //             // else if (delayedChart[i].u_name != showMoreData.drill_down_data[j].u_name) {
            //             //     let temp = []
            //             //     temp.push(delayedChart[i], showMoreData.drill_down_data[j])
            //             // }
            //         }
            //     }
            //     // setDelayedChart(delayedChart)
            // }
            if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in delayedChart) {
                    for (let j in showMoreData.drill_down_data) {
                        if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
                            for (let s in delayedChart[i].drill_compliances) {
                                for (let a in showMoreData.drill_down_data[j].drill_compliances) {
                                    console.log(s, 'taskDistributionTable[i].ap_compliances[s]');
                                    // console.log(showMoreData.r_drill_down_data[j].ap_compliances[a], 'showMoreData.r_drill_down_data[j].ap_compliances[a]');
                                    if (s == a) {
                                        let arr1 = delayedChart[i].drill_compliances[s]
                                        let arr2 = showMoreData.drill_down_data[j].drill_compliances[a]
                                        let arr3 = arr1.concat(arr2)
                                        console.log(arr3, 'arr3');
                                        delayedChart[i].drill_compliances[s] = arr3
                                        console.log(s, 'sneha');
                                    }
                                    else {
                                        delayedChart[i].drill_compliances[a] = showMoreData.drill_down_data[j].drill_compliances[a]
                                    }
                                }
                            }
                            // let newArray = { ...obj, ...objdata }

                            // var newArray = Object.assign({}, taskDistributionTable[i].ap_compliances, showMoreData.r_drill_down_data[j].ap_compliances);
                            // taskDistributionTable[i].ap_compliances = newArray
                            temp2.push(delayedChart[i].u_name)
                            // setTaskDistributionTable(showMoreData.r_drill_down_data)
                        }

                    }
                }
                console.log(temp2, 'temp2');
                for (let i in showMoreData.drill_down_data) {

                    if ((temp2.includes(showMoreData.drill_down_data[i].u_name)) == false) {
                        // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
                        console.log(showMoreData.drill_down_data[i], 'datadata');
                        temp.push(showMoreData.drill_down_data[i])
                    }



                }
                setDelayedChart([...delayedChart, ...temp])



            }

        }

    }, [showMoreData, show, year])
    console.log(criticalityTable, 'criticalityTable');


    console.log(sessionArr, 'commonDatacommonData');
    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityid])

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        var chart = am4core.create("chartdiv", am4charts.PieChart3D);
        // chart.legend = new am4charts.Legend();

        let total = (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count)

        // Add data
        chart.data = [
            {
                "country": "Not Applicable",
                "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
                "color": am4core.color("#808080")
            },
            {
                "country": "Not Opted",
                "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
                "color": am4core.color("#dd070c")
            },
            {
                "country": "Unassigned",
                "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
                "color": am4core.color("#ff9c80")
            },
        ];
        // Set inner radius
        chart.innerRadius = am4core.percent(35);
        // chart.height=200
        // chart.legend = new am4charts.Legend();
        // chart.current = x;
        // x.legend = new am4charts.Legend();

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries3D());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.current = chart;
        chart.legend = new am4charts.Legend();
        pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;



        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        chart.legend.valueLabels.template.disabled = true;
        var marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        marker.strokeWidth = 2;
        marker.strokeOpacity = 1;
        marker.stroke = am4core.color("#ccc");
        var label = chart.createChild(am4core.Label);
        label.text = "Country-Domain";
        label.fontSize = 14;
        label.paddingTop = '10%';
        label.align = "left";
        label.paddingLeft = '10%';
        label.marginBottom = '2%'
        label.fontWeight = 'bold'

        // pieSeries.slices.template.stroke = am4core.color("#fff");
        // pieSeries.slices.template.strokeWidth = 2;
        // pieSeries.slices.template.strokeOpacity = 1;

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;


        pieSeries.slices.template.events.on("hit", function (ev) {
            setCountValue(0)
            setCriticalityValue(ev.target.dataItem.category)
            setShowMoreCount(0)
            setDataCount(ev.target.dataItem._dataContext.litres
            )
            setTable(true)
            setChartValue('hiddenRisk')
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApplicabilityStatusDrillDown",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": [
                                commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                            ],
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "chart_year": 2022,
                            "applicability_status": ev.target.dataItem.category,
                            "record_count": 0,
                            "le_ids": [
                                commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                            ],
                            "country_domains": [
                                {
                                    "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                    "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                    "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                    "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                }
                            ]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getRiskChartTableData({
                    payload: payload,
                    paramid: paramid
                })
            }
        })

    }); 

    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        var chart = am4core.create("chartdiv1", am4charts.PieChart3D);

        let total = (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count) + (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count)

        // Add data
        chart.data = [{
            "country": "Delayed Compliance",
            "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
            "color": am4core.color("#3c78d8")
        }, {
            "country": "Not Complied",
            "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
            "color": am4core.color("#fe4365")
        },
        ];
        // Set inner radius
        chart.innerRadius = am4core.percent(35);

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries3D());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.slices.template.propertyFields.fill = "color";
        chart.current = chart;
        chart.legend = new am4charts.Legend();
        // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
        pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;

        // pieSeries.slices.template.stroke = am4core.color("#fff");
        // pieSeries.slices.template.strokeWidth = 2;
        // pieSeries.slices.template.strokeOpacity = 1;

        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        chart.legend.valueLabels.template.disabled = true;
        var marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        marker.strokeWidth = 2;
        marker.strokeOpacity = 1;
        marker.stroke = am4core.color("#ccc");

        // pieSeries.slices.template.stroke = am4core.color("#fff");
        // pieSeries.slices.template.strokeWidth = 2;
        // pieSeries.slices.template.strokeOpacity = 1;

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        pieSeries.slices.template.events.on("hit", function (ev) {
            setCountValue(0)
            setCriticalityValue(ev.target.dataItem.category)
            setShowMoreCount(0)
            setDataCount(ev.target.dataItem._dataContext.litres
            )
            setTable(true)
            setChartValue('exposedRisk')
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApplicabilityStatusDrillDown",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": [
                                commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                            ],
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "chart_year": 2022,
                            "applicability_status": ev.target.dataItem.category,
                            "record_count": 0,
                            "le_ids": [
                                commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                            ],
                            "country_domains": [
                                {
                                    "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                    "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                    "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                    "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                }
                            ]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getRiskChartDelayedTableData({
                    payload: payload,
                    paramid: paramid
                })
            }

        })

    }); // end am4core.ready()

    // useEffect(() => {
    //     if ((hiddenRiskData && hiddenRiskData.length > 0) && (exposedRiskData && exposedRiskData.length > 0)) {
    //         am4core.useTheme(am4themes_animated);
    //         // Create a container
    //         var container = am4core.create("container", am4core.Container);
    //         container.width = am4core.percent(100);
    //         container.height = am4core.percent(100);
    //         container.layout = "horizontal";
    //         container.background.fill = am4core.color("#000");
    //         container.background.fillOpacity = 0.1;
    //         container.background.stroke = am4core.color("#000");
    //         container.background.strokeOpacity = 0.2;
    //         container.background.strokeWidth = 2;





    //         // title.marginTop = '5%'
    //         // title.marginLeft = '40%'
    //         // title.fontWeight = 'bold'


    //         function addChart(data) {

    //             let total = (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count)


    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data;
    //             chart.width = am4core.percent(50);

    //             chart.innerRadius = am4core.percent(35);
    //             // chart.legend = new am4charts.Legend();
    //             // chart.current = x;
    //             // x.legend = new am4charts.Legend();
    //             chart.height = 325
    //             chart.paddingTop = '50px'

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "litres";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             // chart.height= 250
    //             // chart.paddingTop = '20%'
    //             chart.legend = new am4charts.Legend();
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");


    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;


    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.litres
    //                 )
    //                 setTable(true)
    //                 setChartValue('hiddenRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }
    //             })


    //             var label = chart.createChild(am4core.Label);
    //             label.text = "Country-Domain";
    //             label.fontSize = 14;
    //             label.align = "left";
    //             label.marginLeft = '2%';
    //             label.marginBottom = '2%'
    //             label.fontWeight = 'bold'
    //             var label1 = chart.createChild(am4core.Label);
    //             label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
    //             label1.fontSize = 14;
    //             label1.align = "left";
    //             label1.marginLeft = '2%';
    //             label1.marginBottom = '5%'

    //             // var title = chart.titles.create();
    //             // title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time}${timeZone}`
    //             // title.disabled = false;
    //             // title.align = 'left'
    //             // title.marginTop = '2%'
    //             // title.marginLeft = '2%'

    //             // var title1 = chart.titles.create();
    //             // title1.text = 'Over Due Compliance of Lulu Groups'
    //             // title1.disabled = false;
    //             // title1.align = 'center'
    //             // title1.marginTop = '5%'
    //             // title1.marginLeft = '2%'
    //             // title1.fontWeight = 'bold'

    //             // // <b><p>Country-Domain</p></b>
    //             // // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

    //             // var label = chart.createChild(am4core.Label);
    //             // label.text = "Country-Domain";
    //             // label.fontSize = 14;
    //             // label.align = "left";
    //             // label.marginLeft = '2%';
    //             // label.marginBottom = '2%'
    //             // label.fontWeight = 'bold'

    //             // var label1 = chart.createChild(am4core.Label);
    //             // label1.text = `${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}-${commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}`
    //             // label1.fontSize = 14;
    //             // label1.align = "left";
    //             // label1.marginLeft = '2%';
    //             // label1.marginBottom = '5%'



    //             // var title2 = chart.titles.create();
    //             // title2.text = 'Country-Domain'
    //             // title2.disabled = false;
    //             // title2.align = 'bottom'
    //             // title2.marginTop = '2%'
    //             // title2.marginLeft = '2%'

    //             // title2.fontWeight = 'bold'

    //             // Enable title on export
    //             // chart.exporting.events.on("exportstarted", function (ev) {
    //             //     title.disabled = false;
    //             //     title.parent.invalidate();
    //             // });

    //             // // Disable title when export finishes
    //             // chart.exporting.events.on("exportfinished", function (ev) {
    //             //     title.disabled = true;
    //             // });
    //         }

    //         addChart([
    //             (hiddenRiskData && hiddenRiskData[0].not_applicable_count > 0 ?
    //                 {
    //                     "country": "Not Applicable",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
    //                     "color": am4core.color("#808080")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].not_opted_count > 0 ?
    //                 {
    //                     "country": "Not Opted",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
    //                     "color": am4core.color("#dd070c")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].unassign_count > 0 ?
    //                 {
    //                     "country": "Unassigned",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
    //                     "color": am4core.color("#ff9c80")
    //                 } : {})]);

    //         var title = container.createChild(am4core.Label)
    //         // title.text = 'Risk Chart'
    //         title.text =
    //             ((fieldType == "LegalEntity" && go == true) ? `Risk Chart of Legal Entity ${filterDataNames.legalentity}` : (
    //                 (fieldType == "Division" && go == true) ? `Risk Chart of Division ${filterDataNames.division}` : (
    //                     (fieldType == "Category" && go == true) ? `Risk Chart of Category ${filterDataNames.category}` : (
    //                         (fieldType == "Unit" && go == true) ? `Risk Chart of Unit ${filterDataNames.unit}` : `Risk Chart of ${commonData && commonData.g_name} ${year}`
    //                     ))
    //             ))
    //         title.disabled = false;
    //         title.align = 'center'
    //         title.fontWeight = 'bold'
    //         title.marginLeft = '1%'
    //         title.paddingTop = '20px'
    //         function addChart1(data1) {

    //             let total = (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count) + (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count)

    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data1;
    //             chart.height = 225
    //             chart.width = am4core.percent(50);

    //             chart.paddingTop = '50px'
    //             chart.innerRadius = am4core.percent(35);

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "litres";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             chart.legend = new am4charts.Legend();
    //             // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;

    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.litres
    //                 )
    //                 setTable(true)
    //                 setChartValue('exposedRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartDelayedTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }

    //             })

    //             // var title = chart.titles.create();
    //             // title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time}${timeZone}`
    //             // title.disabled = false;
    //             // title.align = 'left'
    //             // title.marginTop = '2%'
    //             // title.marginLeft = '2%'

    //             // var title1 = chart.titles.create();
    //             // title1.text = 'Over Due Compliance of Lulu Groups'
    //             // title1.disabled = false;
    //             // title1.align = 'left'
    //             // title1.marginTop = '5%'
    //             // title1.marginLeft = '2%'
    //             // title1.fontWeight = 'bold'

    //             // // <b><p>Country-Domain</p></b>
    //             // // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

    //             // var label = chart.createChild(am4core.Label);
    //             // label.text = "Country-Domain";
    //             // label.fontSize = 14;
    //             // label.align = "left";
    //             // label.marginLeft = '2%';
    //             // label.marginBottom = '2%'
    //             // label.fontWeight = 'bold'

    //             // var label1 = chart.createChild(am4core.Label);
    //             // label1.text = `${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}-${commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}`
    //             // label1.fontSize = 14;
    //             // label1.align = "left";
    //             // label1.marginLeft = '2%';
    //             // label1.marginBottom = '5%'

    //             // title2.text = 'Country-Domain'
    //             // title2.disabled = false;
    //             // title2.align = 'bottom'
    //             // title2.marginTop = '2%'
    //             // title2.marginLeft = '2%'

    //             // title2.fontWeight = 'bold'

    //             // Enable title on export
    //             // chart.exporting.events.on("exportstarted", function (ev) {
    //             //     title.disabled = false;
    //             //     title.parent.invalidate();
    //             // });

    //             // // Disable title when export finishes
    //             // chart.exporting.events.on("exportfinished", function (ev) {
    //             //     title.disabled = true;
    //             // });
    //         }

    //         addChart1([
    //             (exposedRiskData && exposedRiskData[0].delayed_compliance_count > 0 ?
    //                 {
    //                     "country": "Delayed Compliance",
    //                     "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
    //                     "color": am4core.color("#3c78d8")
    //                 } : {}),
    //             (exposedRiskData && exposedRiskData[0].not_complied_count > 0 ?
    //                 {
    //                     "country": "Not Complied",
    //                     "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
    //                     "color": am4core.color("#fe4365")
    //                 } : {}
    //             )]);

    //     }

    // }, [exposedRiskData, hiddenRiskData, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    console.log(exposedRiskData, 'exposedRiskData');
    console.log(hiddenRiskData, 'hiddenRiskData');

    // useEffect(() => {
    //     if ((hiddenRiskData && hiddenRiskData.length > 0) && (exposedRiskData && exposedRiskData.length > 0)) {
    //         am4core.useTheme(am4themes_animated);
    //         // Create a container
    //         var container = am4core.create("container", am4core.Container);
    //         container.width = am4core.percent(100);
    //         container.height = am4core.percent(100);
    //         container.layout = "horizontal";
    //         container.background.fill = am4core.color("#000");
    //         container.background.fillOpacity = 0.1;
    //         container.background.stroke = am4core.color("#000");
    //         container.background.strokeOpacity = 0.2;
    //         container.background.strokeWidth = 2;

    //         // var title = container.createChild(am4core.Label)
    //         // title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
    //         // title.disabled = false;
    //         // title.align = 'left'
    //         // title.paddingTop = '13%'
    //         // title.paddingLeft = generatedOn == true ? '3%' : '2%'

    //         var title3 = container.createChild(am4core.Label)
    //         title3.text = "Hidden Risk"
    //         title3.disabled = false;
    //         title3.align = 'left'
    //         title3.paddingTop = '22%'
    //         title3.paddingLeft = '-9%'
    //         title3.fontWeight = 'bold'
    //         title3.fontSize = '14'


    //         var title4 = container.createChild(am4core.Label)
    //         title4.text = "Exposed Risk"
    //         title4.disabled = false;
    //         title4.align = 'right'
    //         title4.paddingTop = '22%'
    //         title4.paddingRight = '-49%'
    //         title4.paddingLeft = '37%'
    //         title4.fontWeight = 'bold'
    //         title4.fontSize = '14'



    //         var title2 = container.createChild(am4core.Label)
    //         title2.text = "Country-Domain"
    //         title2.disabled = false;
    //         title2.align = 'left'
    //         title2.paddingTop = '85%'
    //         title2.paddingLeft = '-53%'
    //         title2.fontWeight = 'bold'

    //         var title2 = container.createChild(am4core.Label)
    //         title2.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
    //         title2.disabled = false;
    //         title2.align = 'left'
    //         title2.paddingTop = '90%'
    //         title2.paddingLeft = '-53%'

    //         function addChart(data) {

    //             let total = (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count)


    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data;
    //             chart.width = am4core.percent(50);

    //             chart.innerRadius = am4core.percent(35);
    //             // chart.legend = new am4charts.Legend();
    //             // chart.current = x;
    //             // x.legend = new am4charts.Legend();
    //             chart.height = 375
    //             chart.paddingTop = '29%'
    //             chart.paddingLeft = '-83%'

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "litres";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             // chart.height= 250
    //             // chart.paddingTop = '20%'
    //             chart.legend = new am4charts.Legend();
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");


    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;


    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.litres
    //                 )
    //                 setTable(true)
    //                 setChartValue('hiddenRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }
    //             })
    //         }

    //         addChart([
    //             (hiddenRiskData && hiddenRiskData[0].not_applicable_count > 0 ?
    //                 {
    //                     "country": "Not Applicable",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
    //                     "color": am4core.color("#808080")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].not_opted_count > 0 ?
    //                 {
    //                     "country": "Not Opted",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
    //                     "color": am4core.color("#dd070c")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].unassign_count > 0 ?
    //                 {
    //                     "country": "Unassigned",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
    //                     "color": am4core.color("#ff9c80")
    //                 } : {})]);


    //         function addChart1(data1) {

    //             let total = (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count) + (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count)

    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data1;
    //             chart.height = 375
    //             chart.paddingTop = '29%'
    //             chart.width = am4core.percent(50);

    //             chart.paddingLeft = '-33%'
    //             chart.innerRadius = am4core.percent(35);

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "litres";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             chart.legend = new am4charts.Legend();
    //             // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;

    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.litres
    //                 )
    //                 setTable(true)
    //                 setChartValue('exposedRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartDelayedTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }

    //             })

    //         }


    //         addChart1([
    //             (exposedRiskData && exposedRiskData[0].delayed_compliance_count > 0 ?
    //                 {
    //                     "country": "Delayed Compliance",
    //                     "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
    //                     "color": am4core.color("#3c78d8")
    //                 } : {}),
    //             (exposedRiskData && exposedRiskData[0].not_complied_count > 0 ?
    //                 {
    //                     "country": "Not Complied",
    //                     "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
    //                     "color": am4core.color("#fe4365")
    //                 } : {}
    //             )]);

    //         var title1 = container.createChild(am4core.Label)
    //         title1.text =
    //             ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
    //                 (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}[/]` : (
    //                     (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}[/]` : (
    //                         (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}[/]` : (
    //                             (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}[/]` : `Risk Chart of ${commonData && commonData.g_name}`
    //                         )))
    //             ))
    //         title1.disabled = false;
    //         title1.paddingLeft = (fieldType == "LegalEntity" && go == true && type === '') ? '-9%' : '10%'
    //         // title1.align = 'center'
    //         title1.paddingTop = '3%'
    //         title1.paddingLeft = '5%'
    //         // title1.align = 'right'
    //         // // title1.align = 'center'


    //     }

    // }, [exposedRiskData, hiddenRiskData, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    // useEffect(() => {
    //     if ((hiddenRiskData && hiddenRiskData.length > 0) && (exposedRiskData && exposedRiskData.length > 0)) {
    //         am4core.useTheme(am4themes_animated);
    //         // Create a container
    //         var container = am4core.create("container", am4core.Container);
    //         container.width = am4core.percent(100);
    //         container.height = am4core.percent(100);
    //         container.layout = "horizontal";
    //         container.background.fill = am4core.color("#000");
    //         container.background.fillOpacity = 0.1;
    //         container.background.stroke = am4core.color("#000");
    //         container.background.strokeOpacity = 0.2;
    //         container.background.strokeWidth = 2;





    //         var title3 = container.createChild(am4core.Label)
    //         title3.text = "Hidden Risk"
    //         title3.disabled = false;
    //         title3.align = 'left'
    //         title3.paddingTop = '22%'
    //         title3.paddingLeft = '19%'
    //         title3.fontWeight = 'bold'
    //         title3.fontSize = '14'


    //         var title4 = container.createChild(am4core.Label)
    //         title4.text = "Exposed Risk"
    //         title4.disabled = false;
    //         title4.align = 'right'
    //         title4.paddingTop = '22%'
    //         title4.paddingRight = '-69%'
    //         title4.paddingLeft = '37%'
    //         title4.fontWeight = 'bold'
    //         title4.fontSize = '14'


    //         var title2 = container.createChild(am4core.Label)
    //         title2.text = "Country-Domain"
    //         title2.disabled = false;
    //         title2.align = 'left'
    //         title2.paddingTop = '85%'
    //         title2.paddingLeft = '-25%'
    //         title2.fontWeight = 'bold'

    //         var title2 = container.createChild(am4core.Label)
    //         title2.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
    //         title2.disabled = false;
    //         title2.align = 'left'
    //         title2.paddingTop = '90%'
    //         title2.paddingLeft = '-25%'

    //         var title = container.createChild(am4core.Label)
    //         title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
    //         title.disabled = ((fieldType != '') && (go == true)) ? false : true;
    //         title.align = 'left'
    //         title.paddingTop = '13%'
    //         title.height = '2%'
    //         title.paddingLeft = '2%'


    //         if (fieldType == '') {
    //             console.log('Mukilan');
    //             var title1 = container.createChild(am4core.Label)
    //             title1.text =
    //                 ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
    //                     (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}[/]` : (
    //                         (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}[/]` : (
    //                             (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}[/]` : (
    //                                 (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}[/]` : `Risk Chart of ${commonData && commonData.g_name}`
    //                             )))
    //                 ))
    //             title1.disabled = ((fieldType != '') && (go == true)) ? true : false;
    //             // title1.align = 'center'
    //             title1.paddingTop = '3%'
    //             title1.paddingLeft = ((fieldType != '') && (go == true)) ? '3%' : '8%'
    //             // title1.align = 'right'
    //             // // title1.align = 'center'
    //         }
    //         function addChart(data) {

    //             let total = (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count)


    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data;
    //             chart.width = am4core.percent(50);

    //             chart.innerRadius = am4core.percent(35);
    //             // chart.legend = new am4charts.Legend();
    //             // chart.current = x;
    //             // x.legend = new am4charts.Legend();
    //             chart.height = 375
    //             chart.paddingTop = '29%'
    //             chart.paddingLeft = '-83%'

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "litres";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             // chart.height= 250
    //             // chart.paddingTop = '20%'
    //             chart.legend = new am4charts.Legend();
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");


    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;


    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.litres
    //                 )
    //                 setTable(true)
    //                 setChartValue('hiddenRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }
    //             })
    //         }

    //         addChart([
    //             (hiddenRiskData && hiddenRiskData[0].not_applicable_count > 0 ?
    //                 {
    //                     "country": "Not Applicable",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
    //                     "color": am4core.color("#808080")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].not_opted_count > 0 ?
    //                 {
    //                     "country": "Not Opted",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
    //                     "color": am4core.color("#dd070c")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].unassign_count > 0 ?
    //                 {
    //                     "country": "Unassigned",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
    //                     "color": am4core.color("#ff9c80")
    //                 } : {})]);

    //         function addChart1(data1) {

    //             let total = (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count) + (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count)

    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data1;
    //             chart.height = 375
    //             chart.paddingTop = '29%'
    //             chart.width = am4core.percent(50);

    //             chart.paddingLeft = '-33%'
    //             chart.innerRadius = am4core.percent(35);

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "litres";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             chart.legend = new am4charts.Legend();
    //             // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;

    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.litres
    //                 )
    //                 setTable(true)
    //                 setChartValue('exposedRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartDelayedTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }

    //             })

    //         }

    //         addChart1([
    //             (exposedRiskData && exposedRiskData[0].delayed_compliance_count > 0 ?
    //                 {
    //                     "country": "Delayed Compliance",
    //                     "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
    //                     "color": am4core.color("#3c78d8")
    //                 } : {}),
    //             (exposedRiskData && exposedRiskData[0].not_complied_count > 0 ?
    //                 {
    //                     "country": "Not Complied",
    //                     "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
    //                     "color": am4core.color("#fe4365")
    //                 } : {}
    //             )]);

    //         var title = container.createChild(am4core.Label)
    //         title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
    //         title.disabled = ((fieldType != '') && (go == true)) ? true : false;
    //         title.align = 'left'
    //         title.paddingTop = '13%'
    //         title.height = '2%'
    //         title.paddingLeft = '-95%'

    //         // var title1 = container.createChild(am4core.Label)
    //         // title1.text =
    //         //     ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
    //         //         (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}[/]` : (
    //         //             (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}[/]` : (
    //         //                 (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}[/]` : (
    //         //                     (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}[/]` : `Risk Chart of ${commonData && commonData.g_name}`
    //         //                 )))
    //         //     ))
    //         // title1.disabled = ((fieldType != '') && (go == true)) ? false : true;
    //         // // title1.align = 'center'
    //         // title1.paddingTop = '3%'
    //         // title1.paddingLeft = ((fieldType != '') && (go == true)) ? '3%' : '8%'
    //         var title1
    //         if (fieldType != '') {
    //             console.log('Mukilan222222');

    //             title1 = container.createChild(am4core.Label)
    //             title1.text =
    //                 ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
    //                     (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}[/]` : (
    //                         (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}[/]` : (
    //                             (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}[/]` : (
    //                                 (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}[/]` : `Risk Chart of ${commonData && commonData.g_name}`
    //                             )))
    //                 ))
    //             title1.disabled = ((fieldType != '') && (go == true)) ? true : false;
    //             // title1.align = 'center'
    //             title1.paddingTop = '3%'
    //             title1.paddingLeft = ((fieldType != '') && (go == true)) ? '3%' : '8%'
    //             // title1.align = 'right'
    //             // // title1.align = 'center'
    //         }
    //     }

    // }, [exposedRiskData, hiddenRiskData, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    useEffect(() => {
        if ((hiddenRiskData && hiddenRiskData.length > 0) && (exposedRiskData && exposedRiskData.length > 0)) {
            var container = am4core.create("container", am4core.Container);
            container.width = am4core.percent(100);
            container.height = am4core.percent(100);
            container.layout = "horizontal";
            container.background.fill = am4core.color("#000");
            container.background.fillOpacity = 0.1;
            container.background.stroke = am4core.color("#000");
            container.background.strokeOpacity = 0.2;
            container.background.strokeWidth = 2;

            var title3 = container.createChild(am4core.Label)
            title3.text = "Hidden Risk"
            title3.disabled = false;
            title3.align = 'left'
            title3.paddingTop = '22%'
            title3.paddingLeft = '19%'
            title3.fontWeight = 'bold'
            title3.fontSize = '14'


            var title4 = container.createChild(am4core.Label)
            title4.text = "Exposed Risk"
            title4.disabled = false;
            title4.align = 'right'
            title4.paddingTop = '22%'
            title4.paddingRight = '-69%'
            title4.paddingLeft = '35%'
            title4.fontWeight = 'bold'
            title4.fontSize = '14'


            var title2 = container.createChild(am4core.Label)
            title2.text = "Country-Domain"
            title2.disabled = false;
            title2.align = 'left'
            title2.paddingTop = '85%'
            title2.paddingLeft = '-25%'
            title2.fontWeight = 'bold'

            var title2 = container.createChild(am4core.Label)
            title2.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
            title2.disabled = false;
            title2.align = 'left'
            title2.paddingTop = '90%'
            title2.paddingLeft = '-25%'

            if (generatedOn === false) {
                var title = container.createChild(am4core.Label)
                title.text = `Generated on : ${time} (UTC)`
                title.disabled = false;
                title.align = 'left'
                title.paddingTop = '13%'
                title.paddingLeft = '-25%'
            }

            function addChart(data) {

                let total = (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count)


                var chart = container.createChild(am4charts.PieChart3D);

                // Add data
                chart.data = data;
                chart.width = am4core.percent(50);

                chart.innerRadius = am4core.percent(35);
                // chart.legend = new am4charts.Legend();
                // chart.current = x;
                // x.legend = new am4charts.Legend();
                chart.height = 375
                chart.paddingTop = '29%'
                chart.paddingLeft = '-46%'

                // Add and configure Series
                var pieSeries = chart.series.push(new am4charts.PieSeries3D());
                pieSeries.dataFields.value = "litres";
                pieSeries.dataFields.category = "country";
                pieSeries.slices.template.propertyFields.fill = "color";
                chart.current = chart;
                // chart.height= 250
                // chart.paddingTop = '20%'
                chart.legend = new am4charts.Legend();
                pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;

                chart.legend = new am4charts.Legend();
                chart.legend.useDefaultMarker = true;
                chart.legend.valueLabels.template.disabled = true;
                var marker = chart.legend.markers.template.children.getIndex(0);
                marker.cornerRadius(12, 12, 12, 12);
                marker.strokeWidth = 2;
                marker.strokeOpacity = 1;
                marker.stroke = am4core.color("#ccc");


                // pieSeries.slices.template.stroke = am4core.color("#fff");
                // pieSeries.slices.template.strokeWidth = 2;
                // pieSeries.slices.template.strokeOpacity = 1;

                // This creates initial animation
                pieSeries.hiddenState.properties.opacity = 1;
                pieSeries.hiddenState.properties.endAngle = -90;
                pieSeries.hiddenState.properties.startAngle = -90;


                pieSeries.slices.template.events.on("hit", function (ev) {
                    setCountValue(0)
                    setCriticalityValue(ev.target.dataItem.category)
                    setDataCount(ev.target.dataItem._dataContext.litres
                    )
                    setTable(true)
                    setChartValue('hiddenRisk')
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetComplianceApplicabilityStatusDrillDown",
                                {
                                    "c_ids": [
                                        commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                    ],
                                    "d_ids": dIds,
                                    "filter_type": fieldType != "" ? fieldType : "Group",
                                    "filter_ids": [
                                        fieldType == "LegalEntity" ? filterData.legalentity :
                                            fieldType == 'Division' ? Number(filterData.division) :
                                                fieldType == 'Category' ? Number(filterData.category) :
                                                    fieldType == 'Unit' ? Number(filterData.unit) :
                                                        fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                            1
                                    ],
                                    "chart_year": year,
                                    "applicability_status": ev.target.dataItem.category,
                                    "record_count": 0,
                                    "le_ids":
                                        filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                    ,
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    if (paramid != "") {
                        getRiskChartTableData({
                            payload: payload,
                            paramid: paramid
                        })
                    }
                })
            }

            addChart([
                (hiddenRiskData && hiddenRiskData[0].not_applicable_count > 0 ?
                    {
                        "country": "Not Applicable",
                        "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
                        "color": am4core.color("#808080")
                    } : {}),
                (hiddenRiskData && hiddenRiskData[0].not_opted_count > 0 ?
                    {
                        "country": "Not Opted",
                        "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
                        "color": am4core.color("#dd070c")
                    } : {}),
                (hiddenRiskData && hiddenRiskData[0].unassign_count > 0 ?
                    {
                        "country": "Unassigned",
                        "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
                        "color": am4core.color("#ff9c80")
                    } : {})]);

            function addChart1(data1) {

                let total = (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count) + (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count)

                var chart = container.createChild(am4charts.PieChart3D);

                // Add data
                chart.data = data1;
                chart.height = 375
                chart.paddingTop = '29%'
                chart.width = am4core.percent(50);

                chart.paddingLeft = '-33%'
                chart.innerRadius = am4core.percent(35);

                // Add and configure Series
                var pieSeries = chart.series.push(new am4charts.PieSeries3D());
                pieSeries.dataFields.value = "litres";
                pieSeries.dataFields.category = "country";
                pieSeries.slices.template.propertyFields.fill = "color";
                chart.current = chart;
                chart.legend = new am4charts.Legend();
                // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
                pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total}`;

                // pieSeries.slices.template.stroke = am4core.color("#fff");
                // pieSeries.slices.template.strokeWidth = 2;
                // pieSeries.slices.template.strokeOpacity = 1;

                chart.legend = new am4charts.Legend();
                chart.legend.useDefaultMarker = true;
                chart.legend.valueLabels.template.disabled = true;
                var marker = chart.legend.markers.template.children.getIndex(0);
                marker.cornerRadius(12, 12, 12, 12);
                marker.strokeWidth = 2;
                marker.strokeOpacity = 1;
                marker.stroke = am4core.color("#ccc");

                // pieSeries.slices.template.stroke = am4core.color("#fff");
                // pieSeries.slices.template.strokeWidth = 2;
                // pieSeries.slices.template.strokeOpacity = 1;

                // This creates initial animation
                pieSeries.hiddenState.properties.opacity = 1;
                pieSeries.hiddenState.properties.endAngle = -90;
                pieSeries.hiddenState.properties.startAngle = -90;

                pieSeries.slices.template.events.on("hit", function (ev) {
                    setCountValue(0)
                    setCriticalityValue(ev.target.dataItem.category)
                    setDataCount(ev.target.dataItem._dataContext.litres
                    )
                    setTable(true)
                    setChartValue('exposedRisk')
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetComplianceApplicabilityStatusDrillDown",
                                {
                                    "c_ids": [
                                        commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                    ],
                                    "d_ids": dIds,
                                    "filter_type": fieldType != "" ? fieldType : "Group",
                                    "filter_ids": [
                                        fieldType == "LegalEntity" ? filterData.legalentity :
                                            fieldType == 'Division' ? Number(filterData.division) :
                                                fieldType == 'Category' ? Number(filterData.category) :
                                                    fieldType == 'Unit' ? Number(filterData.unit) :
                                                        fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                            1
                                    ],
                                    "chart_year": year,
                                    "applicability_status": ev.target.dataItem.category,
                                    "record_count": 0,
                                    "le_ids":
                                        filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                    ,
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    if (paramid != "") {
                        getRiskChartDelayedTableData({
                            payload: payload,
                            paramid: paramid
                        })
                    }

                })

            }

            addChart1([
                (exposedRiskData && exposedRiskData[0].delayed_compliance_count > 0 ?
                    {
                        "country": "Delayed Compliance",
                        "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
                        "color": am4core.color("#3c78d8")
                    } : {}),
                (exposedRiskData && exposedRiskData[0].not_complied_count > 0 ?
                    {
                        "country": "Not Complied",
                        "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
                        "color": am4core.color("#fe4365")
                    } : {}
                )]);

            if (generatedOn === false) {

                var title1 = container.createChild(am4core.Label)
                title1.text =
                    ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}(${year})[/]` : (
                        (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}(${year})[/]` : (
                            (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}(${year})[/]` : (
                                (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}(${year})[/]` : (
                                    (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}(${year})[/]` : `Risk Chart of ${commonData && commonData.g_name} (${year})`
                                )))
                    ))
                title1.disabled = false;
                title1.paddingLeft = (fieldType == "LegalEntity" && go == true && type === '') ? '-9%' : '10%'
                // title1.align = 'center'
                title1.paddingTop = '3%'
                title1.paddingLeft = ((fieldType != '') && (go === true)) ? '-73%' : '-60%'
                // title1.align = 'right'
                // // title1.align = 'center'
            }

            if (generatedOn === true) {
                var title = container.createChild(am4core.Label)
                title.text = `Generated on : ${timeZoneData.tz_name} ${timeZone}`
                title.disabled = false;
                title.align = 'left'
                title.paddingTop = '13%'
                title.paddingLeft = '-97%'
            }

            if (generatedOn === true) {

                var title1 = container.createChild(am4core.Label)
                title1.text =
                    ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
                        (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}[/]` : (
                            (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}[/]` : (
                                (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}[/]` : (
                                    (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}[/]` : `Risk Chart of ${commonData && commonData.g_name}`
                                )))
                    ))
                title1.disabled = false;
                title1.paddingLeft = (fieldType == "LegalEntity" && go == true && type === '') ? '-9%' : '10%'
                // title1.align = 'center'
                title1.paddingTop = '3%'
                title1.paddingLeft = ((fieldType != '') && (go === true)) ? '-65%' : '-60%'
            }



        }
    }, [exposedRiskData, hiddenRiskData, table, timeZone, timeZoneData, localStorage.getItem('chart')])


    useEffect(() => {
        if (type === 'Group')
            setGroupCheckBox(checkBox)
    }, [type, checkBox])

    useEffect(() => {
        if (tableData) {
            setCriticalityTable(tableData.r_drill_down_data)
        }

    }, [tableData])

    useEffect(() => {
        if (tableData1 && tableData1.length > 0) {
            setDelayedChart(tableData1)
        }
    }, [tableData1])
    console.log(criticalityTable, 'criticalityTable');
    console.log(showMoreData.drill_down_data, ' showMoreData.drill_down_data ');
    useEffect(() => {
        if (chartValue == 'exposedRisk') {
            let temp = []
            let temp2 = []
            // if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
            //     for (let i in delayedChart) {
            //         for (let j in showMoreData.drill_down_data) {
            //             // if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
            //             //     var newArray = Object.assign({}, delayedChart[i].drill_compliances, showMoreData.drill_down_data[j].drill_compliances);
            //             //     console.log(newArray, 'newArray');
            //             //     delayedChart[i].drill_compliances = newArray
            //             // }
            //             if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
            //                 let array = delayedChart[i].drill_compliances
            //                 var newArray = Object.assign({}, delayedChart[i].drill_compliances, showMoreData.drill_down_data[j].drill_compliances);
            //                 delayedChart[i].drill_compliances = newArray
            //                 temp2.push(delayedChart[i].u_name)
            //             }
            //             for (let i in showMoreData.drill_down_data) {

            //                 if ((temp2.includes(showMoreData.drill_down_data[i].u_name)) == false) {
            //                     // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
            //                     console.log(showMoreData.drill_down_data[i], 'datadata');
            //                     temp.push(showMoreData.drill_down_data[i])
            //                 }



            //             }

            //             setDelayedChart([...delayedChart, ...temp])

            //             // else if (delayedChart[i].u_name != showMoreData.drill_down_data[j].u_name) {
            //             //     let temp = []
            //             //     temp.push(delayedChart[i], showMoreData.drill_down_data[j])
            //             // }
            //         }
            //     }
            //     // setDelayedChart(delayedChart)
            // }
            if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in delayedChart) {
                    for (let j in showMoreData.drill_down_data) {
                        if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
                            for (let s in delayedChart[i].drill_compliances) {
                                for (let a in showMoreData.drill_down_data[j].drill_compliances) {
                                    console.log(s, 'taskDistributionTable[i].ap_compliances[s]');
                                    // console.log(showMoreData.r_drill_down_data[j].ap_compliances[a], 'showMoreData.r_drill_down_data[j].ap_compliances[a]');
                                    if (s == a) {
                                        let arr1 = delayedChart[i].drill_compliances[s]
                                        let arr2 = showMoreData.drill_down_data[j].drill_compliances[a]
                                        let arr3 = arr1.concat(arr2)
                                        console.log(arr3, 'arr3');
                                        delayedChart[i].drill_compliances[s] = arr3
                                        console.log(s, 'sneha');
                                    }
                                    else {
                                        delayedChart[i].drill_compliances[a] = showMoreData.drill_down_data[j].drill_compliances[a]
                                    }
                                }
                            }
                            // let newArray = { ...obj, ...objdata }

                            // var newArray = Object.assign({}, taskDistributionTable[i].ap_compliances, showMoreData.r_drill_down_data[j].ap_compliances);
                            // taskDistributionTable[i].ap_compliances = newArray
                            temp2.push(delayedChart[i].u_name)
                            // setTaskDistributionTable(showMoreData.r_drill_down_data)
                        }

                    }
                }
                console.log(temp2, 'temp2');
                for (let i in showMoreData.drill_down_data) {

                    if ((temp2.includes(showMoreData.drill_down_data[i].u_name)) == false) {
                        // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
                        console.log(showMoreData.drill_down_data[i], 'datadata');
                        temp.push(showMoreData.drill_down_data[i])
                    }



                }
                setDelayedChart([...delayedChart, ...temp])



            }

        }

    }, [showMoreData, show])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
            let arrunit = [];
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-domain');
                if (country_info && country_info[0].c_id == subunitlist.c_id) {
                    entityArrayunit = {

                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'is_active': subunitlist.is_active,
                        'month_from': subunitlist.month_from,
                        'month_to': subunitlist.month_to,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setDomainListData(countrylisttempdataunit)
        }
    }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if (commonData) {
            let temp = []
            for (let i in commonData.d_info) {
                temp.push(commonData.d_info[i].d_id)
            }
            setFilterData({
                ...filterData,
                country: `${country_info[0].c_id}`,
                domain: temp
            })
        }
    }, [commonData])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            let arrunit = [];
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-domain');
                if (country_info && country_info[0].c_id == subunitlist.c_id) {
                    entityArrayunit = {

                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'is_active': subunitlist.is_active,
                        'month_from': subunitlist.month_from,
                        'month_to': subunitlist.month_to,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setDomainListData(countrylisttempdataunit)
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            console.log('hiii');
            if ((commonData && commonData.bg_groups && commonData.bg_groups.length === 0) && (legalEntityArray && legalEntityArray.length > 0)) {
                console.log('hello');
                if (unit) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    unit && unit.length > 0 && unit.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-lee');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'address': subunitlist.address,
                                'business_group_id': subunitlist.business_group_id,
                                'category_id': subunitlist.category_id,
                                'country_id': subunitlist.country_id,
                                'd_ids': subunitlist.d_ids,
                                'division_id': subunitlist.division_id,
                                'le_id': subunitlist.le_id,
                                'postal_code': subunitlist.postal_code,
                                'u_id': subunitlist.u_id,
                                'u_name': subunitlist.u_name,
                                'label': subunitlist.u_name,
                                'values': subunitlist.u_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunitleee');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setUnitListData(countrylisttempdataunit)
                }
                if (division) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    division && division.length > 0 && division.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {

                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.div_name,
                                'values': subunitlist.div_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunit');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setDivisionListData(countrylisttempdataunit)
                }
                if (category) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    category && category.length > 0 && category.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-category');
                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'cat_id': subunitlist.cat_id,
                                'cat_name': subunitlist.cat_name,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.cat_name,
                                'values': subunitlist.cat_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunit');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                    setCategoryListData(countrylisttempdataunit)
                }
            }
        }

    }, [commonData, legalEntityArray, unit, division, category, localStorage.getItem('SelectedEntity') === "All Legal Entity"])


    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
                let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(commonData && commonData.bg_groups[0].bg_id) })
                console.log(filteredDivision, 'filteredDivision');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)
                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)
                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)

                }
            }
            else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                console.log('hello ais');
                let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: commonData.le_did_infos[0].le_id })
                console.log(filteredDivision, 'filteredDivision555');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)

                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)

                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)
                }
            }
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && bgIds.length > 0 && commonData) {
            if (bgIds.includes(null) === true) {
                console.log('snehaaa');
                setfield({
                    ...field,
                    legalEntity: commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos,
                })

            }
            else {
                let filteredLegalEntity = _.filter(commonData.le_did_infos, { bg_id: Number(commonData.bg_groups[0].bg_id) })
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                })
            }

        }
    }, [commonData, bgIds, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredCategory, 'filteredCategory111');
                let temp = []
                temp.push({
                    'bg_id': filteredCategory[0].bg_id,
                    'c_id': filteredCategory[0].c_id,
                    'cat_id': filteredCategory[0].cat_id,
                    'cat_name': filteredCategory[0].cat_name,
                    'd_id': filteredCategory[0].d_id,
                    'div_id': filteredCategory[0].div_id,
                    'le_id': filteredCategory[0].le_id,
                    'div_name': commonData && commonData.div_infos[0].div_name
                })
                setCategory(temp)
                let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredUnit, 'filteredUnit123');
                setUnit(filteredUnit)
            }
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity && filteredLegalEntity[0].bg_id })
                console.log(filteredBusinessGroup, 'filteredBusinessGroup');
                const unique = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                console.log(unique, 'unique');
                setfield({
                    ...field,
                    business: unique,
                    legalEntity: filteredLegalEntity
                })
            }

        }
    }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if ((commonData) && (division && division.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            division && division.length > 0 && division.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');

                if (commonData && commonData.le_did_infos[0].le_id == subunitlist.le_id) {

                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.div_name,
                        'values': subunitlist.div_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            console.log(totalListunit, 'totalListunit');

            countrylisttempdataunit.push(totalListunit)
            console.log(countrylisttempdataunit, 'countrylisttempdataunit');
            setDivisionListData(countrylisttempdataunit)
        }
    }, [commonData, division, field.legalEntity])

    useEffect(() => {
        if (field.legalEntity && field.legalEntity.length > 0) {
            if (division) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
            if (category) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                category && category.length > 0 && category.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-category');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setCategoryListData(countrylisttempdataunit)
            }
            if (unit) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                unit && unit.length > 0 && unit.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-unit');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'address': subunitlist.address,
                            'business_group_id': subunitlist.business_group_id,
                            'category_id': subunitlist.category_id,
                            'country_id': subunitlist.country_id,
                            'd_ids': subunitlist.d_ids,
                            'division_id': subunitlist.division_id,
                            'le_id': subunitlist.le_id,
                            'postal_code': subunitlist.postal_code,
                            'u_id': subunitlist.u_id,
                            'u_name': subunitlist.u_name,
                            'label': subunitlist.u_name,
                            'values': subunitlist.u_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                countrylisttempdataunit.push(totalListunit)
                setUnitListData(countrylisttempdataunit)
            }
        }
    }, [field.legalEntity, division, category, unit])

    useEffect(() => {
        if ((commonData) && (category && category.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            category && category.length > 0 && category.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-category');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }
            countrylisttempdataunit.push(totalListunit)
            setCategoryListData(countrylisttempdataunit)
        }

    }, [commonData, category && category.length > 0])

    useEffect(() => {
        if ((commonData) && (unit && unit.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            unit && unit.length > 0 && unit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-unit');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'address': subunitlist.address,
                        'business_group_id': subunitlist.business_group_id,
                        'category_id': subunitlist.category_id,
                        'country_id': subunitlist.country_id,
                        'd_ids': subunitlist.d_ids,
                        'division_id': subunitlist.division_id,
                        'le_id': subunitlist.le_id,
                        'postal_code': subunitlist.postal_code,
                        'u_id': subunitlist.u_id,
                        'u_name': subunitlist.u_name,
                        'label': subunitlist.u_name,
                        'values': subunitlist.u_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setUnitListData(countrylisttempdataunit)
        }
    }, [commonData, unit && unit.length > 0])

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
            }
            console.log(tempIds, 'tempIds');
            setDIds(tempIds)
            setCountryDomain(tempArr)
            console.log(tempArr, 'tempArr');
        }

    }, [commonData])

    const HandelChange = (obj) => {
        console.log(obj, 'objj');
        setFilterData({
            ...filterData,
            domain: obj
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            domain: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
        let temp = []
        let bg = []
        let tempDiv = []
        let tempCat = []
        let tempUnit = []
        for (let i in obj) {
            console.log(obj[i], 'obj[i');
            console.log(i, 'i');
            if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity[0].bg_id })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            } else {
                let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(obj[i])))
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(obj[i]) })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            }
            let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(obj[i]) })
            let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
            let temp1 = []
            temp1.push({
                'bg_id': filteredCategory[0].bg_id,
                'c_id': filteredCategory[0].c_id,
                'cat_id': filteredCategory[0].cat_id,
                'cat_name': filteredCategory[0].cat_name,
                'd_id': filteredCategory[0].d_id,
                'div_id': filteredCategory[0].div_id,
                'le_id': filteredCategory[0].le_id,
                'div_name': division[0].div_name
            })
            tempCat = temp1
            let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
            tempUnit = filteredUnit
            tempDiv = filteredDivision
            let uniqueDiv = []
            if (filteredDivision && filteredDivision.length > 0) {
                uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)

            setCategory(temp1)
            setUnit(filteredUnit)

        }
        console.log(tempCat, 'tempCat');
        console.log(bg, 'Aishuuuu');
        console.log(temp, 'SnehaaaaaaaaAsss');

    }


    const showmore = () => {
        setShow(true)
        setCountValue(countValue + 100)
        const payload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApplicabilityStatusDrillDown",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": [
                                commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                            ],
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "chart_year": 2022,
                            "applicability_status": criticalityValue,
                            "record_count": countValue + 100,
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": [
                                {
                                    "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                    "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                    "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                    "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                }
                            ],
                        }
                    ]
                }
            ]

        if (paramid != "") {
            getRiskExposedTrendShowMoreTableData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const hiddenRiskShowmore = () => {
        setShow(true)
        setCountValue(countValue + 100)
        const payload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApplicabilityStatusDrillDown",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": [
                                commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                            ],
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "chart_year": 2022,
                            "applicability_status": criticalityValue,
                            "record_count": countValue + 100,
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": [
                                {
                                    "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                    "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                    "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                    "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                }
                            ],
                        }
                    ]
                }
            ]

        if (paramid != "") {
            getHiddenRiskShowMore({
                payload: payload,
                paramid: paramid
            })
        }
    }
    const onClose = () => {
        setOpen(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const filterChange = () => {
        setGeneratedOn(false)
        setGo(true)
        if (groupCheckBox === true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApplicabilityStatusChart",
                        {
                            "c_ids": [
                                filterData && filterData.country ? Number(filterData && filterData.country) : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": filterData && filterData.domain ? filterData.domain : dIds,
                            "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                            "filter_ids": [
                                (fieldType == "LegalEntity" && type === '') ? filterData.legalentity :
                                    (fieldType == 'Division' && type === '') ? Number(filterData.division) :
                                        (fieldType == 'Category' && type === '') ? Number(filterData.category) :
                                            (fieldType == 'Unit' && type === '') ? Number(filterData.unit) :
                                                (fieldType == 'BusinessGroup' && type === '') ? Number(filterData.business) :
                                                    (fieldType != "" && type === 'Group') ? 1 :
                                                        1
                            ],
                            "le_ids":
                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                            ,
                            "country_domains": countryDomain,
                            "chart_year": year
                        }
                    ]
                }
            ]

            getRiskchart({
                payload: payload,
                paramid: paramid
            })
        }
        else {
            toast.error('Please select the filter')
        }
        setOpen(false);
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder='Search'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };


    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            width: 10,
            align: 'center'
        },

        {
            title: "Statutory",
            ...getColumnSearchProps('s_prov'),
            dataIndex: false,
            key: 's_prov',
            width: 20,
            filteredValue: filteredInfo.s_prov || null,
            render: (record) => {
                return <p title={record.s_prov} className='cut-text'>
                    {record.s_prov}</p>
            }
        },
        {
            title: 'Compliance Task',
            ...getColumnSearchProps('comp_name'),
            dataIndex: false,
            key: 'comp_name',
            ellipsis: false,
            width: 30,
            render: (row, record) => {
                if (record.download_url_list) {
                    return <p title={record.comp_name} className='cut-text'><a href={`${'https://13.234.100.64/compliance-approval/' + record.download_url_list}`}>{record.comp_name}</a></p>
                }
                else {
                    return <p title={record.comp_name} className='cut-text'>{record.comp_name}</p>
                }
            },
            filteredValue: filteredInfo.comp_name || null,

        },
        {
            title: 'Criticality',
            ...getColumnSearchProps('criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            width: 17,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,

        },
        {
            title: 'Compliance Description',
            ...getColumnSearchProps('descp'),
            dataIndex: false,
            key: 'descp',
            ellipsis: false,
            width: 30,
            filteredValue: filteredInfo.descp || null,
            render: (record) => {
                return <p title={record.descp} className='cut-text'>{record.descp}</p>
            }

        },
        {
            title: 'Penal Conseqeunces',
            ...getColumnSearchProps('p_cons'),
            dataIndex: false,
            key: 'p_cons',
            ellipsis: false,
            width: 30,
            filteredValue: filteredInfo.p_cons || null,
            render: (record) => {
                return <p title={record.p_cons} className='cut-text'>{record.p_cons}</p>
            }

        },
        {
            title: "Compliance Frequency",
            ...getColumnSearchProps('frequency'),
            dataIndex: 'frequency',
            key: 'frequency',
            ellipsis: false,
            width: 30,
            filteredValue: filteredInfo.frequency || null,

        },
        {
            title: 'Repeats',
            ...getColumnSearchProps('summary'),
            dataIndex: false,
            key: 'summary',
            ellipsis: false,
            width: 20,
            filteredValue: filteredInfo.summary || null,
            render: (record) => {
                return <p title={record.summary} className='cut-text'>{record.summary}</p>
            }

        },

        {
            title: 'Trigger Before',
            ...getColumnSearchProps('statu_dates'),
            // dataIndex: 'false',
            // key: 'false',
            ellipsis: false,
            width: 25,
            dataIndex: false,
            key: 'statu_dates',
            render: (record) => {
                console.log(record && record.length > 0 && record[0].trigger_before_days, 'record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days');
                return <p title={record && record.length > 0 && record[0].trigger_before_days} className='cut-text'>{record && record.length > 0 && record[0].trigger_before_days}</p>

            },
            filteredValue: filteredInfo.statu_dates || null,
        },
    ];


    // const columns = [
    //     {
    //         title: 'Statutory',
    //         dataIndex: 's_prov',
    //         key: 's_prov',
    //     },
    //     {
    //         title: 'Compliance Task',
    //         dataIndex: false,
    //         key: false,
    //         render: (row, record) => {
    //             if (record.download_url_list === null) {
    //                 if (record.remarks) {
    //                     return <p><Tooltip title={record.remarks}><ExclamationCircleTwoTone /></Tooltip>&nbsp;{record.comp_name}</p>
    //                 } else {
    //                     return <p>{record.comp_name}</p>
    //                 }
    //             }
    //             else {
    //                 return <a href={`${'https://13.234.100.64/compliance_format/' + record.download_url_list}`}>{record.comp_name}</a>

    //             }
    //         }
    //     },
    //     {
    //         title: 'Criticality',
    //         dataIndex: 'criticality',
    //         key: 'criticality',
    //     },
    //     {
    //         title: 'Compliance Description',
    //         dataIndex: 'descp',
    //         key: 'descp',
    //     },
    //     {
    //         title: 'Penal Conseqeunces',
    //         dataIndex: 'p_cons',
    //         key: 'p_cons',
    //     },
    //     {
    //         title: 'Compliance Frequency',
    //         dataIndex: 'frequency',
    //         key: 'frequency',
    //     },
    //     {
    //         title: 'Repeats',
    //         dataIndex: 'summary',
    //         key: 'summary',
    //     },
    //     {
    //         title: 'Trigger Before',
    //         dataIndex: false,
    //         key: false,
    //         render: (row) => {
    //             // return <p>{row.statu_dates && row.statu_dates[0].trigger_before_days}</p>
    //             return <p>{row.statu_dates && row.statu_dates.length > 0 ? `${row.statu_dates && row.statu_dates[0].trigger_before_days} days` : ''}</p>

    //         }
    //     },
    // ];
    // const columns1 = [
    //     {
    //         title: 'Business Group',
    //         // dataIndex: 'name',
    //         // key: 'name',
    //         width: '50px'

    //     },
    //     {
    //         title: 'Legal Entity',
    //         // dataIndex: 'legal_entity_id',
    //         // key: 'legal_entity_id',
    //         render: () => {
    //             return (<p>{localStorage.getItem('SelectedEntity')}</p>)
    //         },
    //         width: '120px'
    //     },
    //     {
    //         title: 'Division',
    //         dataIndex: 'description',
    //         key: 'description',
    //         width: '50px'

    //     },
    //     {
    //         title: "Compliance",
    //         dataIndex: 'comp_name',
    //         key: 'comp_name',

    //         // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
    //         render: (row, record) => {
    //             console.log(record, 'record');
    //             if (record.remarks_count == 1) {
    //                 const content = (
    //                     <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
    //                         <tbody>
    //                             <tr>
    //                                 <th>Compliance Description</th>
    //                                 <td>{record.descp}</td>
    //                             </tr>
    //                             <tr>
    //                                 <th>Document Reference Number</th>
    //                                 <td>{record.doc_ref_num}</td>
    //                             </tr>
    //                         </tbody>
    //                     </table>
    //                 );
    //                 return (
    //                     <>
    //                         <div>
    //                             <div className="col-md-12">
    //                                 <div className="row">
    //                                     <div className="col-md-2">
    //                                         <Popover content={content} title="">
    //                                             <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
    //                                         </Popover>
    //                                     </div>
    //                                     <div className="col-md-10" style={{ marginTop: '2%' }}>
    //                                         <p>
    //                                             <Tooltip style={{ marginRight: '20%' }} title='Click here to View Remarks History' onClick={() => {
    //                                                 showModal()
    //                                                 const payload = [
    //                                                     authtoken,
    //                                                     {
    //                                                         "session_token": authtoken,
    //                                                         "request": [
    //                                                             "GetComplianceRemarksHistoryData",
    //                                                             {
    //                                                                 "legal_entity_id": record.legal_entity_id,
    //                                                                 "unit_id": record.unit_id,
    //                                                                 "compliance_history_id": record.compliance_history_id
    //                                                             }
    //                                                         ]
    //                                                     }
    //                                                 ]
    //                                                 getRemarksHistoryData({
    //                                                     payload: payload,
    //                                                     paramid: paramid
    //                                                 })
    //                                             }}><ExclamationCircleTwoTone /></Tooltip>&nbsp;{record.comp_name}</p>
    //                                     </div>
    //                                 </div>
    //                             </div>

    //                         </div>

    //                     </>
    //                 )
    //             }
    //             const content = (
    //                 <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
    //                     <tbody>
    //                         <tr>
    //                             <th>Compliance Description</th>
    //                             <td>{record.descp}</td>
    //                         </tr>
    //                         <tr>
    //                             <th>Document Reference Number</th>
    //                             <td>{record.doc_ref_num}</td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //             );
    //             return (
    //                 <>
    //                     <div>
    //                         <div className="mb-3">
    //                             <Popover content={content} title="">
    //                                 <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
    //                             </Popover>{record.comp_name}
    //                         </div>
    //                     </div>

    //                 </>
    //             )
    //         },
    //         width: '180px',
    //         // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
    //         // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
    //     },
    //     {
    //         title: 'Criticality',
    //         dataIndex: 'criticality',
    //         key: 'criticality',
    //         width: '80px'

    //     },
    //     {
    //         title: 'Assigned To',
    //         dataIndex: 'assignee_name',
    //         key: 'assignee_name',
    //         width: '80px'

    //     },
    //     {
    //         title: 'Over Due',
    //         dataIndex: 'ageing',
    //         key: 'ageing',
    //         width: '80px'

    //     },

    // ];

    const columns2 = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 6,
            align: 'center'
        },
        ...((fieldType === 'LegalEntity' && go === true) || (fieldType === 'Category' && go === true) || (fieldType === 'Unit' && go === true) ?
            [] :
            [{
                title: "Business Group",
                ...getColumnSearchProps('bg_name'),
                dataIndex: 'bg_name',
                key: 'bg_name',
                ellipsis: false,
                width: 18,
                filteredValue: filteredInfo.bg_name || null,
            },
            {
                title: 'Legal Entity',
                ...getColumnSearchProps('le_name'),
                dataIndex: false,
                key: 'le_name',
                ellipse: false,
                width: 15,
                filteredValue: filteredInfo.le_name || null,
                render: (record) => {
                    return <p title={record.le_name} className='cut-text'>
                        {record.le_name}</p>
                }
            }
            ]
        ),
        {
            title: 'Division',
            ...getColumnSearchProps('div_name'),
            dataIndex: false,
            key: 'div_name',
            width: 14,
            ellipsis: false,
            filteredValue: filteredInfo.div_name || null,
            render: (record) => {
                return <p title={record.div_name} className='cut-text'>
                    {record.div_name}</p>
            }

        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: false,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.div_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>{record.comp_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: 20,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 15,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name'),
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 15,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Over Due",
            ...getColumnSearchProps('ageing'),
            dataIndex: 'ageing',
            key: 'ageing',
            ellipsis: false,
            render: (row, record) => {
                return (
                    <>
                        <p>{record.ageing}</p>
                    </>
                )
            },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ];

    const columns3 = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 7,
            align: 'center'
        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: true,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.comp_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>{record.comp_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: 25,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name'),
            dataIndex: false,
            key: 'assignee_name',
            ellipsis: false,
            render: (record) => {
                return <p title={record.assignee_name} className='cut-text'>
                    {record.assignee_name}</p>
            },
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 13,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Over Due",
            ...getColumnSearchProps('ageing'),
            dataIndex: 'ageing',
            key: 'ageing',
            ellipsis: false,
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ]


    const RemarksColumn = [
        {
            title: '#',
            dataIndex: 's_prov',
            key: 's_prov',
        },
        {
            title: 'Activity Date',
            dataIndex: 'activity_on',
            key: 'activity_on',
        },
        {
            title: 'User Type',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'Reviewer Report',
            dataIndex: 'documents',
            key: 'documents',
            render: (row, record) => {
                console.log(record.documents, 'record.documents');
                let string = record.documents
                var fields = string.split('||');
                console.log(fields.toString(), 'fields');
                var newString = fields.toString()
                // var splitedWord = newString.split(',')
                let values = []
                return <a href="">{newString}</a>
                // if (fields && fields.length > 0) {
                //     for (let i in fields) {
                //         // console.log(i, 'iiiii');
                //         values = fields[i]
                //         // console.log(values, 'values');
                //         // return (
                //         //     <li>{fields[i]}</li>
                //         // )
                //     }
                //     return (
                //         <li>{values}</li>
                //     )
                // }

            }
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            // render: (row, record) => {
            //     return <textarea rows={3} cols={25}>{record.remarks}</textarea>
            // },
            width: '100px'
        },
    ];


    // useEffect(() => {
    //     if (data.length > 0) {
    //         console.log('test');
    //         if (table == false) {

    //             let x = am4core.create("chartdiv", am4charts.PieChart3D);
    //             am4core.useTheme(am4themes_animated);
    //             x.paddingRight = 20;
    //             x.data = [{
    //                 "criticality": "(A) High",
    //                 "count": data && data.length > 0 && data[0].a_count,
    //                 "color": am4core.color("#fe4365")
    //             }, {
    //                 "criticality": "(B) Medium",
    //                 "count": data && data.length > 0 && data[0].b_count,
    //                 "color": am4core.color("#ff6054")
    //             },
    //             {
    //                 "criticality": "(C) Low",
    //                 "count": data && data.length > 0 && data[0].c_count,
    //                 "color": am4core.color("#f2746b")
    //             },
    //             ];

    //             var pieSeries = x.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "count";
    //             pieSeries.dataFields.category = "criticality";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             // x.background.fill = '#d1d1d1'
    //             // x.background.width = '100%'
    //             // x.background.height = '100%'
    //             chart.current = x;
    //             x.legend = new am4charts.Legend();

    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 console.log(ev.target.dataItem.category, 'eventttt');
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setTable(true)
    //                 const payload = [

    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetCriticalityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": [
    //                                     commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                 ],
    //                                 "filter_type": "Group",
    //                                 "filter_ids": [
    //                                     1
    //                                 ],
    //                                 "le_ids": [
    //                                     commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
    //                                 ],
    //                                 "country_domains": [
    //                                     {
    //                                         "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                         "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                         "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                         "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                     }
    //                                 ],
    //                                 "chart_year": 2022,
    //                                 "record_count": 0,
    //                                 "criticality_applicability_status": "(B) Medium",
    //                             }
    //                         ]
    //                     }

    //                 ]

    //                 const payloads = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetCriticalityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": [
    //                                     commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                 ],
    //                                 "filter_type": "Group",
    //                                 "filter_ids": [
    //                                     1
    //                                 ],
    //                                 "chart_year": 2022,
    //                                 "criticality_applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids": [
    //                                     commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
    //                                 ],
    //                                 "country_domains": [
    //                                     {
    //                                         "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                         "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                         "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                         "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                     }
    //                                 ]
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getCriticalityTableData({
    //                         payload: payloads,
    //                         paramid: paramid
    //                     })
    //                 }

    //             });

    //         }
    //     }
    // }, [data, table, localStorage.getItem('chart')])

    console.log(commonData, 'commonData');
    console.log(tableData, 'tableData');
    console.log(criticalityTable, 'cricTable');
    console.log(data, 'datadata');
    console.log(tableData1, 'tableData1');
    console.log(chartValue, 'chartValue');

    // return (
    //     <div>
    //         <div className="page-wrapper">
    //             <div className="container-fluid pt-1">
    //                 <div className="col-md-12" style={{ marginBottom: '1%', marginTop: '2%' }}>
    //                     <div className="row">
    //                         <div className="col-md-6">
    //                             <h4><b>Risk Chart</b></h4>
    //                         </div>
    //                         <div className="col-md-6">
    //                             <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '80%' }} onClick={() => {
    //                                 setOpen(true)
    //                             }}
    //                                 size='default' >
    //                                 Filter
    //                             </Button>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 {/* {data && data.length > 0 ? */}
    //                 <>
    //                     <div className="row" >
    //                         <div className="col-12">
    //                             <div className="card">
    //                                 <div className='card-body' style={{height:'100%',width:'100%' }}>
    //                                     {table == false ?
    //                                         <>
    //                                             <div style={{ marginLeft: '2%', marginTop: '2%',height:'100%',width:'100%' }}>
    //                                                 <b> <p style={{ textAlign: 'center' }}>Risk Chart of Lulu Groups(2022)</p></b>
    //                                                 {/* <p>{`Generated on :${time}${timeZone}`}</p> */}
    //                                                 <div className="col-md-12">
    //                                                     <div className="row">
    //                                                         <div className="col-md-6">
    //                                                             <div id="chartdiv" style={{marginTop:'10%', width: "220px", height: "200px" }}></div>
    //                                                         </div>
    //                                                         <div className="col-md-6">
    //                                                             <div id="chartdiv1" style={{ width: "220px", height: "200px" }}></div>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="col-md-12">
    //                                                     <div className="row">
    //                                                         <div className="col-md-6">
    //                                                             <b><p>Country-Domain</p></b>
    //                                                             <b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>
    //                                                         </div>
    //                                                         <div className="col-md-6">
    //                                                             <Select
    //                                                                 allowClear={true}
    //                                                                 size="default"
    //                                                                 onChange={(data) => {
    //                                                                     setTimeZone(data)
    //                                                                 }}
    //                                                                 placeholder="Enter Time Zone"
    //                                                                 style={{ marginLeft: '50%' }}
    //                                                             // value={compfie.user_Group}

    //                                                             >

    //                                                                 {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

    //                                                                     return (

    //                                                                         <Option key={item.tz_name}>

    //                                                                             {item.tz_name}

    //                                                                         </Option>

    //                                                                     );

    //                                                                 })}
    //                                                                 {/* );
    //                                                         })} */}
    //                                                             </Select>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>

    //                                         </> :
    //                                         // <div >
    //                                         //     <div className='col-md-12'>
    //                                         //         <div className='row'>
    //                                         //             <div className="col-md-6">
    //                                         //                 <p>Lulu Groups-(A)High Criticaltity</p>
    //                                         //             </div>
    //                                         //             <div className="col-md-2" style={{ marginLeft: '85%' }}>
    //                                         //                 <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F" }}
    //                                         //                     icon={<ArrowLeftOutlined />} size='default' onClick={() => {
    //                                         //                         setTable(false)
    //                                         //                     }}>
    //                                         //                     Back
    //                                         //                 </Button>
    //                                         //             </div>
    //                                         //         </div>
    //                                         //     </div><br />

    //                                         //     {/* <div className="col-md-12 mt-3">
    //                                         //         <div className="row"> */}
    //                                         //             <Collapse defaultActiveKey={['0']}>
    //                                         //                 {criticalityTable && criticalityTable.length > 0 &&
    //                                         //                     criticalityTable.map((item) => {
    //                                         //                         let unitName = item.ap_compliances
    //                                         //                         let keys = Object.keys(unitName);
    //                                         //                         let values = Object.values(unitName);
    //                                         //                         let childData = values[0]
    //                                         //                         console.log(values[0],'values');
    //                                         //                         return (
    //                                         //                             <Panel header={item.level1_statutory_name}>
    //                                         //                                 <h5>{keys[0]}</h5>
    //                                         //                                 <Table
    //                                         //                                     columns={columns}
    //                                         //                                     dataSource={childData}
    //                                         //                                     bordered
    //                                         //                                     scroll={{ x: 1500 }}
    //                                         //                                     pagination={false}


    //                                         //                                 />

    //                                         //                             </Panel>
    //                                         //                         )
    //                                         //                     })
    //                                         //                 }
    //                                         //             </Collapse>

    //                                         //         {/* </div>
    //                                         //     </div> */}
    //                                         //     {/* <Table dataSource={dataSource} columns={columns} pagination={false}
    //                                         //         className='userprivclass' /> */}
    //                                         // </div>

    //                                         <Card>
    //                                             <div className="col-md-12">
    //                                                 <div className="row">
    //                                                     <div className="col-md-4">
    //                                                         <b>Lulu Groups-{criticalityValue} Criticaltity</b>
    //                                                     </div>
    //                                                     <div className="col-md-4">

    //                                                     </div>
    //                                                     <div className="col-md-4">
    //                                                         <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F", marginLeft: '70%', marginBottom: '2%' }}
    //                                                             icon={<ArrowLeftOutlined />} size='default' onClick={() => {
    //                                                                 setTable(false)
    //                                                             }}>
    //                                                             Back
    //                                                         </Button>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                             <div className="col-md-12">
    //                                                 <Collapse defaultActiveKey={['0']}>
    //                                                     {criticalityTable && criticalityTable.length > 0 &&
    //                                                         criticalityTable.map((item) => {
    //                                                             let unitName = item.ap_compliances
    //                                                             let keys = Object.keys(unitName);
    //                                                             let values = Object.values(unitName);
    //                                                             let childData = values[0]
    //                                                             console.log(values[0], 'values');
    //                                                             return (
    //                                                                 <Panel header={item.level1_statutory_name}>
    //                                                                     <p><b>{keys[0]}</b></p>
    //                                                                     <Table
    //                                                                         columns={columns}
    //                                                                         dataSource={childData}
    //                                                                         bordered
    //                                                                         scroll={{ x: 1500 }}
    //                                                                         pagination={false}


    //                                                                     />

    //                                                                 </Panel>
    //                                                             )
    //                                                         })
    //                                                     }
    //                                                 </Collapse>
    //                                             </div>
    //                                         </Card>
    //                                     }
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <Drawer title="FILTER BY" placement="right" onClose={onClose} visible={open}>
    //                         <div className="col-md-12">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label><b>Country</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Country"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 country: data
    //                                             })
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {field && field.country && field.country.length && field.country.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.c_id}>
    //                                                     {item.c_name}
    //                                                 </Option>
    //                                             );
    //                                         })}

    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label><b>Domain</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Domain"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 domain: data
    //                                             })
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {field && field.domain && field.domain.length && field.domain.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.d_id}>
    //                                                     {item.d_name}
    //                                                 </Option>
    //                                             );
    //                                         })}

    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-1">
    //                                     <input type='checkbox'></input>
    //                                 </div>
    //                                 <div className="col-md-4">
    //                                     <label><b>Group</b></label>
    //                                 </div>

    //                             </div>
    //                         </div>

    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label><b>Business Group</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Business Group"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 business: data
    //                                             })
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {field && field.business && field.business.length && field.business.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.bg_id}>
    //                                                     {item.bg_name}
    //                                                 </Option>
    //                                             );
    //                                         })}


    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label style={{ color: fieldType == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Legal Entity"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 legalentity: data
    //                                             })
    //                                             setFieldType('LegalEntity')
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >

    //                                         {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.le_id}>
    //                                                     {item.le_name}
    //                                                 </Option>
    //                                             );
    //                                         })}

    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label style={{ color: fieldType == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Division"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 division: data
    //                                             })
    //                                             let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
    //                                             console.log(subData, 'subDatasubData');
    //                                             setCategory(subData)
    //                                             setFieldType('Division')
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {division && division.length && division.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.div_id}>
    //                                                     {item.div_name}
    //                                                 </Option>
    //                                             );
    //                                         })}


    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label style={{ color: fieldType == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Category"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 category: data
    //                                             })
    //                                             let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
    //                                             setUnit(subData)
    //                                             setFieldType('Category')
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {category && category.length && category.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.cat_id}>
    //                                                     {item.cat_name}
    //                                                 </Option>
    //                                             );
    //                                         })}


    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label style={{ color: fieldType == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Unit"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 unit: data
    //                                             })
    //                                             setFieldType('Unit')
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {unit && unit.length > 0 && unit.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.u_id}>
    //                                                     {item.u_name}
    //                                                 </Option>
    //                                             );
    //                                         })}


    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
    //                             onClick={filterChange}
    //                             size='default' >
    //                             Go
    //                         </Button>
    //                     </Drawer>
    //                 </>
    //                 {/* : <p style={{ marginTop: '18%', marginLeft: '35%' }}>No Data to Display </p>} */}
    //             </div>
    //         </div>
    //     </div>
    // )

    console.log(timeZoneData, 'timeZoneData');
    console.log(remarksHistoryData, 'remarksHistoryData');

    return (
        <div>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Dashboard</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span style={{ color: '#1890ff' }}>Risk Chart</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        {table == false ?
                            <div className="col-md-2" style={{ marginTop: '1%' }}>
                                <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '50%' }} onClick={() => {
                                    setOpen(true)
                                }}
                                    size='default' >
                                    <Tooltip title='Filter'>Filter</Tooltip>
                                </Button>
                            </div> : ''}
                    </div>
                </div>
                {
                    table == true ?
                        <div className="container-fluid pt-1">
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <p><b>{commonData && commonData.g_name} - {criticalityValue} Compliance</b></p>
                                    </div>
                                    <div className="col-md-4">

                                    </div>
                                    <div className="col-md-4">
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginLeft: '70%' }}
                                            icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                                setTable(false)
                                            }}>
                                            <Tooltip title='Back'>Back</Tooltip>
                                        </Button>
                                    </div>
                                </div>
                            </div><br />
                            <div className="col-md-12">
                                <Collapse defaultActiveKey={['0']}>
                                    {chartValue == 'hiddenRisk' ?
                                        criticalityTable && criticalityTable.length > 0 &&
                                        criticalityTable.map((item) => {
                                            let unitName = item.ap_compliances
                                            let keys = Object.keys(unitName);
                                            let values = Object.values(unitName);
                                            let childData = values[0]
                                            console.log(values[0], 'values');
                                            return (
                                                <Panel header={item.level1_statutory_name}>
                                                    {keys && keys.length > 0 && keys.map((items, j) => {
                                                        console.log(values[j], 'values[j]');
                                                        let tempArr = []
                                                        if (values && values.length > 0) {
                                                            for (let m in values[j]) {
                                                                tempArr.push({
                                                                    'index': count,
                                                                    'ageing': values[j][m].ageing,
                                                                    'assignee_name': values[j][m].assignee_name,
                                                                    'bg_name': values[j][m].bg_name,
                                                                    'comp_name': values[j][m].comp_name,
                                                                    'comp_id': values[j][m].comp_id,
                                                                    'frequency': values[j][m].frequency,
                                                                    'compfielaw_url': values[j][m].compfielaw_url,
                                                                    'criticality': values[j][m].criticality,
                                                                    'descp': values[j][m].descp,
                                                                    'is_active': values[j][m].is_active,
                                                                    'div_name': values[j][m].div_name,
                                                                    'p_cons': values[j][m].p_cons,
                                                                    'remarks': values[j][m].remarks,
                                                                    's_prov': values[j][m].s_prov,
                                                                    'statu_dates': values[j][m].statu_dates,
                                                                    'summary': values[j][m].summary,
                                                                    // 'index': values[j][m].,
                                                                    'le_name': values[j][m].le_name,
                                                                    'download_url_list': values[j][m].download_url_list

                                                                })
                                                                { <span hidden>{count = count + 1}</span> }
                                                                // childDatas[i] = []
                                                            }
                                                            console.log(tempArr, 'tempArr');
                                                        }
                                                        values[j] = tempArr
                                                        return (
                                                            <>
                                                                <p><b>{keys[j]}</b></p>

                                                                <Table
                                                                    size={'small'}
                                                                    columns={columns}
                                                                    dataSource={values[j]}
                                                                    bordered
                                                                    scroll={{ x: 1500 }}
                                                                    pagination={false}
                                                                    onChange={handleChange}


                                                                />
                                                            </>
                                                        )

                                                    })}


                                                </Panel>
                                            )
                                        }) :
                                        chartValue == 'exposedRisk' ?
                                            delayedChart && delayedChart.length > 0 &&
                                            delayedChart.map((items) => {
                                                console.log('exposedRiskkkk');
                                                let unitName = items && items.drill_compliances
                                                console.log(unitName, 'drill');
                                                let keys = Object.keys(unitName);
                                                // let childData = values[]
                                                console.log(keys, 'keys');
                                                // console.log(childData,);
                                                return (
                                                    <Panel header={`${items.le_name}-${items.u_name}`}>
                                                        {keys && keys.length > 0 && keys.map((item, i) => {
                                                            let values = Object.values(unitName);
                                                            console.log(values[i], 'values');
                                                            let childDatas = values
                                                            let tempArr = []
                                                            if (childDatas && childDatas.length > 0) {
                                                                for (let m in childDatas[i]) {
                                                                    console.log(m, 'inndexxx');
                                                                    console.log(childDatas[i][m].comp_name, 'childDatas');
                                                                    tempArr.push({
                                                                        'bg_name': items.bg_name,
                                                                        'le_name': items.le_name,
                                                                        'legal_entity_id': items.legal_entity_id,
                                                                        'div_name': items.div_name,
                                                                        'comp_name': childDatas && childDatas[i][m].comp_name,
                                                                        'criticality': childDatas && childDatas[i][m].criticality,
                                                                        'assignee_name': childDatas && childDatas[i][m].assignee_name,
                                                                        'descp': childDatas && childDatas[i][m].descp,
                                                                        'compfielaw_url': childDatas && childDatas[i][m].compfielaw_url,
                                                                        'ageing': childDatas && childDatas[i][m].ageing,
                                                                        'index': count

                                                                    })
                                                                    { <span hidden>{count = count + 1}</span> }
                                                                    // childDatas[i] = []
                                                                }
                                                                console.log(tempArr, 'tempArr');
                                                            }
                                                            childDatas[i] = tempArr


                                                            return (
                                                                <>
                                                                    <h6 className='m-2 ml-2 mb-2'>{keys[i]}</h6>

                                                                    <Table
                                                                        size={'small'}
                                                                        columns={columnState === true ? columns3 : columns2}
                                                                        dataSource={childDatas[i]}
                                                                        bordered
                                                                        scroll={{ x: 1500 }}
                                                                        pagination={false}
                                                                        onChange={handleChange}

                                                                    />
                                                                </>
                                                            )

                                                        })}

                                                    </Panel>
                                                )
                                            }) : ''
                                    }
                                </Collapse>
                            </div>
                            {dataCount == showmoreCount ? false :
                                <div className='col-md-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-md-4'></div>
                                        <div className='col-md-4'>
                                            {chartValue == 'hiddenRisk' ?
                                                <button className="btn btn-success" style={{ float: "center", marginLeft: "50px" }}
                                                    onClick={hiddenRiskShowmore}
                                                >Show More</button> :
                                                chartValue == 'exposedRisk' ?
                                                    <button className="btn btn-success" style={{ float: "center", marginLeft: "50px" }}
                                                        onClick={showmore}
                                                    >Show More</button> : ''}
                                        </div>
                                        <div className='col-md-4'></div>
                                    </div>
                                </div>
                            }
                            {/* <Table dataSource={dataSource} columns={columns} pagination={false}
              className='userprivclass' /> */}
                        </div> :
                        <div className="container-fluid pt-1">
                            <div className="row" >
                                {/* {(data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0)) ? */}

                                {/* <p style={{ marginTop: '18%', marginLeft: '35%' }}>No Data to Display </p> : */}
                                <>
                                    <div className="col-md-12 mt-3">

                                        {/* <div className="card">
                                            <div className='card-body'> */}

                                        <>
                                            {/* <b> <p style={{ textAlign: 'center' }}>Risk Chart of Lulu Groups</p></b>
                                            {generatedOn == true ?
                                                <p>{`Generated on :${timeZoneData.tz_name} ${timeZone}`}</p>
                                                :
                                                <p>{`Generated on :${time} (UTC)`}</p>
                                            } */}
                                            <div className="col-md-12">
                                                <div id="container" style={{ width: "100%", height: "500px" }}></div>
                                                {/* <div className="row">
                                                            <div className="col-md-6">
                                                                <b style={{ marginLeft: '42%' }}>Hidden Risk</b>
                                                                <div id="chartdiv" style={{ width: "100%", marginTop: '10px', height: "220px" }}></div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <b style={{ marginLeft: '42%' }}>Exposed Risk</b>
                                                                <div id="chartdiv1" style={{ width: "100%", marginTop: '10px', marginRight: '20%', height: "220px" }}></div>
                                                            </div>
                                                        </div> */}
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="row">
                                                    {/* <div className="col-md-3">
                                                        <b><p>Country-Domain</p></b>
                                                        <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>
                                                    </div> */}
                                                    <div className="col-md-3">
                                                        {/* <b><p>{`Total Task:${(data && data.length > 0 && data[0].assigned_count) + (data && data.length > 0 && data[0].not_opted_count) + (data && data.length > 0 && data[0].unassign_count) + (data && data.length > 0 && data[0].not_applicable_count)}`}</p></b> */}
                                                    </div>
                                                    <div className="col-md-6" style={{ marginLeft: '70%' }}>
                                                        <label htmlFor=""><b>Time Zone :</b></label>
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            onChange={(data) => {
                                                                setTimeZone(data)
                                                                setGeneratedOn(true)
                                                                const payload = [
                                                                    authtoken,
                                                                    {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "GetDateTime_ByZone",
                                                                            {
                                                                                "tz_name": data
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                                getTimeZoneData({
                                                                    payload: payload,
                                                                    paramid: paramid
                                                                })

                                                            }}
                                                            placeholder="Enter Time Zone"
                                                            style={{ marginLeft: '5%' }}
                                                        // value={compfie.user_Group}

                                                        >

                                                            {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                return (

                                                                    <Option key={item.tz_name}>

                                                                        {item.tz_name}

                                                                    </Option>

                                                                );

                                                            })}
                                                            {/* );
                                                })} */}
                                                        </Select>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "25px" }}
                                                                    icon={<ArrowLeftOutlined />} size='default'
                                                                    onClick={() => {
                                                                        setYear(year - 1)
                                                                    }}
                                                                >
                                                                    Previous Year
                                                                </Button>
                                                            </div>
                                                            <div className="col-md-8"></div>
                                                            <div className="col-md-2">
                                                                {year != moment().year() ?
                                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginRight: "25px" }}
                                                                        size='default'
                                                                        onClick={() => {
                                                                            setYear(year + 1)
                                                                        }}
                                                                    >
                                                                        Next Year<ArrowRightOutlined />
                                                                    </Button>
                                                                    : false}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>



                                        {/* </div>
                                        </div> */}
                                    </div>

                                </>
                                {/* } */}
                            </div>
                        </div>
                }
                <Drawer title="FILTER BY" placement="right" closable={false} onClose={false} visible={open}
                    extra={
                        <Space>
                            <CloseCircleOutlined style={{ marginTop: '2%', fontSize: '140%' }} onClick={onClose} />
                        </Space>
                    }
                >
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Country</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Country"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.country ? filterData.country : undefined}
                                    onChange={(data, values) => {
                                        setFilterData({
                                            ...filterData,
                                            country: data,
                                            domain: ''
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            country: values.children,
                                            domain: '',
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            business: '',
                                            category: '',
                                            unit: ''
                                        })
                                        setType('')

                                    }
                                    }
                                    onDeselect={(data) => {

                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {country_info && country_info.length > 0 && country_info.map((item, i) => {
                                        return (
                                            <Option key={item.c_id}>
                                                {item.c_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Domain</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.domain ? filterData.domain : undefined}
                                    // onChange={(data, values) => {
                                    //     console.log(data,'data-domain');
                                    //     let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(data) })
                                    //     let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(data) })
                                    //     // let filteredCategory = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     // let filteredUnit = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(data)))
                                    //     let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
                                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                    //     let temp = []
                                    //     temp.push({
                                    //         'bg_id': filteredCategory[0].bg_id,
                                    //         'c_id': filteredCategory[0].c_id,
                                    //         'cat_id': filteredCategory[0].cat_id,
                                    //         'cat_name': filteredCategory[0].cat_name,
                                    //         'd_id': filteredCategory[0].d_id,
                                    //         'div_id': filteredCategory[0].div_id,
                                    //         'le_id': filteredCategory[0].le_id,
                                    //         'div_name': division[0].div_name
                                    //     })
                                    //     setCategory(temp)
                                    //     setUnit(filteredUnit)
                                    //     setfield({
                                    //         ...field,
                                    //         legalEntity: filteredLegalEntity,
                                    //         business: filteredBusinessGroup
                                    //     })
                                    //     let temp1=[]
                                    //     for(let i in commonData.d_info){
                                    //         temp1.push(commonData.d_info[i].d_id)
                                    //     }
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         domain: temp1
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         domain: values.children,
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setDivision(filteredDivision)
                                    //     // let temp = []
                                    //     // temp.push({
                                    //     //     'bg_id': filteredCategory[0].bg_id,
                                    //     //     'c_id': filteredCategory[0].c_id,
                                    //     //     'cat_id': filteredCategory[0].cat_id,
                                    //     //     'cat_name': filteredCategory[0].cat_name,
                                    //     //     'd_id': filteredCategory[0].d_id,
                                    //     //     'div_id': filteredCategory[0].div_id,
                                    //     //     'le_id': filteredCategory[0].le_id,
                                    //     //     'div_name': filteredDivision[0].div_name
                                    //     // })
                                    //     // setCategory(temp)
                                    //     // setUnit(filteredUnit)
                                    // }
                                    // }
                                    onChange={(option) => HandelChange(option)}
                                    // onDeselect={(data) => {
                                    //     console.log(data,'data-deselect');
                                    //     filterData && filterData.domain.splice(data,1)
                                    // }}
                                    style={{ width: '100%' }}
                                    disabled={filterData.country == '' ? true : false}
                                >
                                    {domainListData && domainListData.length > 0 &&
                                        domainListData.map((finalList_unitlist) => (
                                            <OptGroup label={finalList_unitlist.label}>
                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                ))}
                                            </OptGroup>
                                        ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-1">
                                <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                    setType('Group')
                                    setCheckBox(!checkBox)
                                }}></input>
                            </div>
                            <div className="col-md-4">
                                <label><b>Group</b></label>
                            </div>
                            {/* <div className="col-md-2">

                        </div> */}
                            <div className="col-md-1">
                                {/* <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input> */}
                            </div>
                            <div className="col-md-4">
                                {/* <label><b>Consolidated</b></label> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-6">

                                <label><b>From Date</b></label>
                                <DatePicker
                                    disabled={true}
                                    allowClear={() => {
                                        setFilterData({
                                            ...filterData,
                                            from_date: null,
                                        })
                                    }}
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        setFilterData({
                                            ...filterData,
                                            from_date: dateString,
                                        })

                                    }}

                                />

                            </div>
                            <div className="col-md-6">

                                <label><b>To Date</b></label>

                                <DatePicker
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    // onChange={(date, dateString) => {

                                    //     console.log(dateString, 'dateString');
                                    //     setConsolidatedData({
                                    //         ...consolidatedData,
                                    //         from_date: dateString,
                                    //         to_date: moment(dateString).add(3, 'months')
                                    //     })

                                    // }}
                                    disabled={true}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        if (dateString != undefined) {
                                            setFilterData({
                                                ...filterData,
                                                to_date: dateString,
                                            })
                                        }
                                        else {
                                            setFilterData({
                                                ...filterData,
                                                to_date: null,
                                            })
                                        }


                                    }}

                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    value={filterDataNames.business ? filterDataNames.business : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { bg_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        setfield({
                                            ...field,
                                            legalEntity: filteredEntity
                                        })
                                        setFieldType('BusinessGroup')
                                        setFilterData({
                                            ...filterData,
                                            business: data,
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            business: values.children,
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        setUnit(filteredUnit)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}
                                >
                                    {field && field.business && field.business.length && field.business.map((item, i) => {
                                        return (
                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>
                                        );
                                    })}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    value={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    placeholder="Enter Legal Entity"
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: data })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { le_id: data })
                                        let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        setLegalEntityArray(filteredLegalEntity)
                                        // setfield({
                                        //     ...field,
                                        //     legalEntity: filteredLegalEntity
                                        // })
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                            setUnit(filteredUnit)
                                        }
                                        else {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: data })
                                            console.log(filteredUnit, 'filteredUnit555');
                                            setUnit(filteredUnit)
                                        }

                                        console.log(filteredDivision, 'filteredDivisionLeg');
                                        setFilterData({
                                            ...filterData,
                                            legalentity: data
                                        })
                                        setFieldType('LegalEntity')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            legalentity: values.children,
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        // setUnit(filteredUnit)
                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                        return (
                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    value={filterDataNames.division ? filterDataNames.division : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.find(commonData && commonData.div_infos, { div_id: Number(data) })
                                        let getLEname = _.find(commonData && commonData.le_did_infos, { le_id: filteredDivision.le_id })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(data) })
                                        setFilterData({
                                            ...filterData,
                                            division: data
                                        })

                                        // let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        // setDivisionObj(filteredDivision)
                                        // setLEObj(getLEname)
                                        // setCategory(subData)
                                        setFieldType('Division')
                                        setCoulmnState(true)
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            division: values.children,
                                            unit: '',
                                            category: '',
                                        })
                                        setUnit(filteredUnit)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision.div_name
                                        })
                                        setCategory(temp)

                                        console.log(category, 'catStateeeee');

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {divisionListData && divisionListData.length > 0 &&
                                        divisionListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.div_id}>{subUnitData.div_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }

                                        }
                                        )}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    value={filterDataNames.category ? filterDataNames.category : undefined}
                                    onChange={(data, values) => {
                                        setCoulmnState(true)
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            category: data,
                                            unit: '',
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            category: values.children
                                        })
                                        let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                        setUnit(subData)
                                        setFieldType('Category')
                                        setGo(false)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {categoryListData && categoryListData.length > 0 &&
                                        categoryListData.map((finalList_unitlist) => {
                                            console.log(finalList_unitlist, 'finalList_unitlist');
                                            if (finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Unit"
                                    value={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    onChange={(data, values) => {
                                        setCoulmnState(true)
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            unit: data
                                        })
                                        setFieldType('Unit')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            unit: values.children
                                        })

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {unitListData && unitListData.length > 0 &&
                                        unitListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '15%', marginTop: '10%' }}
                        onClick={filterChange}
                        size='default' >
                        Go
                    </Button>
                    {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                        onClick={() => {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusChart",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "filter_type": "Group",
                                                "filter_ids": [
                                                    1
                                                ],
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                    commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                ],
                                                "country_domains": [
                                                    {
                                                        "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                    }
                                                ],
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getCriticalitychart({
                                payload: payload,
                                paramid: paramid
                            })
                            setOpen(false)

                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                country: '',
                                domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: ''
                            })
                        }}
                        size='default' >
                        Reset
                    </Button> */}
                </Drawer>
                <Modal title="Remarks History" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Table
                        size={'small'}
                        columns={RemarksColumn}
                        dataSource={remarksHistoryData.remarks_history}
                        bordered
                        scroll={{ x: 1500 }}
                        pagination={false}

                    />
                </Modal>

            </div>
        </div>
    )


}

export default connect(mapStateToProps, {
    getRiskchart,
    getCommonData,
    getRiskChartTableData,
    getRiskChartDelayedTableData,
    getTimeZoneData,
    getRemarksHistoryData,
    getRiskExposedTrendShowMoreTableData,
    getHiddenRiskShowMore
})(RiskChart);