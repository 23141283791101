import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { sessionLogout, notifications, Escalations, remainder, Message, Loginvendor } from './../Store/Action/Login';


const mapStateToProps = (state) => ({
    loginvendor: state.Login.loginvendor
})

const Homevendor = ({
    loginvendor
}) => {  
  
    return (
     <h1>hai</h1>
    )

}

export default connect(mapStateToProps, {
    Loginvendor
})(Homevendor);