import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination, Popover } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import moment from 'moment';
import { MonthlyComplianceFilterReport, MonthlyComplianceTable, MonthlyComplianceExport, Downloadfile } from '../../../Store/Action/Report/CV_MonthlyComplianceReport';
import { toast } from 'react-toastify';
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, PlayCircleOutlined, BookTwoTone } from '@ant-design/icons';

const mapStateToProps = (state) => ({
    filerunitdatas: state.MonthlyComplianceReport.unitlistreports.unitlist,
    tabledatas: state.MonthlyComplianceReport.unitlistreports.tabledata
})

const MonthlyComplianceReport = ({
    MonthlyComplianceFilterReport,
    MonthlyComplianceTable,
    MonthlyComplianceExport,
    Downloadfile,
    filerunitdatas,
    tabledatas


}) => {

    console.log(tabledatas, "tabledatas111");
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info

    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    const [type, setType] = useState('')
    const [division, setDivision] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [domain, setDomain] = useState([])
    const [vendorlist, setVendorlist] = useState([])

    const [tabledata, setTableData] = useState([])
    const [page, setPage] = useState(1);
    const [titledata, Settitledata] = useState()
    console.log(titledata, "titledata");

    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [paginationdata, setPaginationdata] = useState([])
    const [count, setCount] = useState([])
    let [index, setindex] = useState(0)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [checkboxValue, setCheckBoxValue] = useState([0, 1, 2, 7, 8, 9, 10, 29, 31])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [legalstate, Setlegalstate] = useState(false)
    const [tablecount, setTablecount] = useState([])


    const [vendorComplianceScore, setVendorComplianceScore] = useState({
        country: '',
        countryName: '',
        division: '',
        divisionName: '',
        typeid: '',
        typeName: '',
        category: '',
        categoryName: '',
        from_month: '',
        to_month: '',
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        legal_entity_id: '',
        domain: '',
        domainName: '',
        vendor_id: '',
        vendor_name: ''
    })
    const [Documentdata, setDocumentdata] = useState([{
        assignee_document: '',
        approver_document: '',
        concurrer_document: '',
        startdate: ''
    }])
    const [TempDocumentdata, setTempDocumentdata] = useState([])
    console.log(Documentdata.startdate, "v1113333333");
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };
    const handleOk = () => {
        setIsModalOpen(true);
        if (validator.current.allValid()) {
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceReport",
                        {
                            "country_id": vendorComplianceScore.country ? Number(vendorComplianceScore.country) : 1,
                            "legal_entity_id": entityid,
                            "domain_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
                            "division_id": vendorComplianceScore.division ? Number(vendorComplianceScore.division) : 0,
                            "category_id": vendorComplianceScore.category ? Number(vendorComplianceScore.category) : 0,
                            "unit_id": vendorComplianceScore.vendor_id ? Number(vendorComplianceScore.vendor_id) : 0,
                            "from_date": moment(vendorComplianceScore.from_month).format("MMMM-YYYY"),
                            "to_date": moment(vendorComplianceScore.to_month).format("MMMM-YYYY"),
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            MonthlyComplianceExport({
                payload: payload2,
                paramid: paramid
            })
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalVisible(false)
    };

    const onshow = () => {
        setAddFormSubmit(true)

        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceReport",
                        {
                            "country_id": vendorComplianceScore.country ? Number(vendorComplianceScore.country) : 1,
                            "legal_entity_id": entityid,
                            "domain_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
                            "division_id": vendorComplianceScore.division ? Number(vendorComplianceScore.division) : 0,
                            "category_id": vendorComplianceScore.category ? Number(vendorComplianceScore.category) : 0,
                            "unit_id": vendorComplianceScore.vendor_id ? Number(vendorComplianceScore.vendor_id) : 0,
                            "from_date": moment(vendorComplianceScore.from_month).format("MMMM-YYYY"),
                            "to_date": moment(vendorComplianceScore.to_month).format("MMMM-YYYY"),
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": null
                        }
                    ]
                }
            ]
            MonthlyComplianceTable({
                payload: payload1,
                paramid: paramid
            })

        }
    }

    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (vendorComplianceScore.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [vendorComplianceScore.legal_entity_name])

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetMonthlyComplianceReportFilters",
                            {
                                "country_id": Number(vendorComplianceScore.country),
                                "legal_entity_id": entityid
                            }
                        ]
                    }
                ]
                if (entityid != 'null') {

                    MonthlyComplianceFilterReport({
                        payload: payload,
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }

    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(vendorComplianceScore.legal_entity_id)
        }
    }, [entityid])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setVendorComplianceScore({
                ...vendorComplianceScore,
                le_id: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (filerunitdatas) {
            setCategoryList(filerunitdatas && filerunitdatas.categories_list)
            setDivision(filerunitdatas && filerunitdatas.divisions_list)
            setDomain(filerunitdatas && filerunitdatas.domains)
            setVendorlist(filerunitdatas && filerunitdatas.unit_legal_entity)
        }
    }, [filerunitdatas, entityid])
    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 3, 4, 5, 6, 11])
        }
    }
    // useEffect(() => {
    //     if (tabledatas) {
    //         setTableData(tabledatas && tabledatas.monthly_compliances)
    //     }
    //     if (tabledatas.total_count) {
    //         setCount(tabledata && tabledatas.total_count)
    //     }
    // }, [tabledatas, tabledatas.monthly_compliances, tabledatas.total_count, count])

    useEffect(() => {
        if (tabledatas && tabledatas.monthly_compliances && tabledatas.monthly_compliances.length > 0) {
            var array = tabledatas && tabledatas.monthly_compliances.slice((current - 1) * pageSize, (current * pageSize))
            setTableData(array)
        }

    }, [tabledatas && tabledatas.monthly_compliances, pageSize, current])

    useEffect(() => {
        let temp = [];
        if (tabledata && tabledata.length > 0) {
            for (let i in tabledata) {
                temp.push(tabledata[i])
            }
            setTablecount([...temp]);
        }
    }, [tabledata])
    useEffect(() => {
        if (tabledata && tabledata && tabledata.length > 0) {
            let uniqueObjArray = [...new Map(tabledata && tabledata.length && tabledata.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tabledata && tabledata && tabledata.length && tabledata, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])
        }
    }, [tabledata])


    useEffect(() => {

        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tabledata && tabledata.length && tabledata) {
            const ab = tabledata && tabledata.length && tabledata.map(function (item) {
                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    tabledata && tabledata.length && tabledata.map((child, childIndex) => {
                        if (data === child.statutory_mapping) {
                            entityArray = {
                                "ac_id": child.ac_id,
                                "activity_date": child.activity_date,
                                "activity_status": child.activity_status,
                                "approval_document_names": child.approval_document_names,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "completion_date": child.completion_date,
                                "compliance_activity_id": child.compliance_activity_id,
                                "compliance_description": child.compliance_description,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_id": child.compliance_id,
                                "compliance_task": child.compliance_task,
                                "concur_document_name": child.concur_document_name,
                                "country_id": child.country_id,
                                "criticality_name": child.criticality_name,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "document_name": child.document_name,
                                "domain_id": child.domain_id,
                                "due_date": child.unit_id,
                                "extend_due_date": child.extend_due_date,
                                "frequency_name": child.frequency_name,
                                "history_count": child.history_count,
                                "interim_count": child.interim_count,
                                "legal_entity_id": child.legal_entity_id,
                                "month_name": child.month_name,
                                "remarks_count": child.remarks_count,
                                "start_date": child.start_date,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_name": child.unit_name,
                                "unit_id": child.unit_id,
                                "url": child.url,
                                "user_name": child.user_name,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
        }
    }, [tabledata && tabledata.length && tabledata])


    useEffect(() => {
        if (pageState === true) {
            setindex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const chilData = (document_name, approval_document_names, concur_document_name, start_date) => {

        setIsModalVisible(true)
        setTempDocumentdata([document_name, approval_document_names, concur_document_name])
        setDocumentdata([{
            assignee_document: document_name,
            approver_document: approval_document_names,
            concurrer_document: concur_document_name,
            startdate: start_date,
        }])
    }
    const download = (item) => {
        console.log(item, "0000000000000000");
        const downloadpayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFile",
                        {
                            "le_id": entityid,
                            "c_id": vendorComplianceScore.country ? Number(vendorComplianceScore.country) : 1,
                            "d_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
                            "u_id": 38625,
                            "start_date": "22-Feb-2022",
                            "file_name": item,
                        }
                    ]
                }
            ]
        Downloadfile({
            payload: downloadpayload,
            paramid: paramid
        });
        if (item) {
            console.log(item, "item11334455");
            // window.open('https:/' + item);
            window.open('https://13.234.100.64/cv' + item);

        }
    }

    const columns = [
        {
            title: "#",
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            },
            sortable: true,
            reorder: true,
            dataIndex: false,
            width: '30px',
            align: 'center',

        },
        {
            title: 'Assignee',
            ellipsis: true,
            width: '50px',
            dataIndex: 'false',
            key: 'false',
            render: (row, record) => {
                console.log(record, "112233");
                console.log(row, "11223344444444");

                return (
                    <a href={`${'https://13.234.100.64/compliance_format/' + record.assignee_document}`} target="_blank" onClick={(e) => { download(record.assignee_document) }} >
                        {record.assignee_document}
                    </a>
                )
            },
        },
        {
            title: 'Approver',
            ellipsis: true,
            width: '50px',
            dataIndex: 'false',
            key: 'false',
            render: (row, record) => {
                console.log(record, "112233");
                console.log(row, "11223344444444");

                return (
                    <a href={`${'https://13.234.100.64/compliance_format/' + record.approver_document}`} target="_blank" onClick={(e) => { download(record.approver_document) }} >
                        {record.approver_document}
                    </a>
                )
            },

        },
        {
            title: 'Concurrer',
            ellipsis: true,
            width: '50px',
            dataIndex: 'false',
            key: 'false',
            render: (row, record) => {
                console.log(record, "112233");
                console.log(row, "11223344444444");

                return (
                    <a href={`${'https://13.234.100.64/compliance_format/' + record.concurrer_document}`} target="_blank" onClick={(e) => { download(record.concurrer_document) }} >
                        {record.concurrer_document}
                    </a>
                )
            },
        },
    ];

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ color: '#1890ff' }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ color: '#1890ff' }}>Compliance Reports</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ color: '#1890ff' }}>Monthly Compliance Report</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" style={{ position: 'relative', padding: "15px 5px" }}>
                                <Collapse defaultActiveKey={["1"]} onChange={onChange} >
                                    <Panel header="Monthly Compliance Report" key={key}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={false}
                                                                    size="default"
                                                                    placeholder="Enter Country"
                                                                    onChange={(value, data) => {
                                                                        setVendorComplianceScore({
                                                                            ...vendorComplianceScore,
                                                                            country: value,
                                                                            countryName: data.children,
                                                                            divisionName: '',
                                                                            categoryName: '',
                                                                            from_month: '',
                                                                            to_month: '',
                                                                            legal_entity_name: '',
                                                                            domainName: '',
                                                                            vendor_name: ''


                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                    value={vendorComplianceScore.countryName || undefined}
                                                                >
                                                                    {country_info && country_info.length && country_info.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'countryName',
                                                                    vendorComplianceScore.countryName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{sessionArr[0].c_name}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division:</b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(value, data) => {
                                                                let category = _.filter(filerunitdatas.categories_list, { div_id: Number(value) })
                                                                setCategoryList(category)
                                                                let vendor = _.filter(filerunitdatas.unit_legal_entity, { division_id: Number(value) })
                                                                setVendorlist(vendor)

                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    division: value,
                                                                    divisionName: data.children,
                                                                    categoryName: '',
                                                                    vendor_name: ''
                                                                })
                                                            }}
                                                            placeholder="Select Division"
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={vendorComplianceScore.divisionName || undefined}
                                                            disabled={vendorComplianceScore.entityid == '' ? true : false}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            
                                                        >
                                                            {division && division.length > 0 && division.map((item, i) => {
                                                                return (
                                                                    <Option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>From Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker style={{ width: '100%', marginTop: '5px' }} picker="month" format={'MMMM-YYYY'}
                                                        value={vendorComplianceScore.from_month ? moment(vendorComplianceScore.from_month) : ''}
                                                        disabled={vendorComplianceScore.legal_entity_name ? false : true}
                                                        onChange={(date, dateString) => {
                                                            setVendorComplianceScore({
                                                                ...vendorComplianceScore,
                                                                from_month: dateString,
                                                                to_month: moment(dateString).add(2, 'months')
                                                            })
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'from_month',
                                                        vendorComplianceScore.from_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'From Month Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: "15px" }}>
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity:</b>  <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onChange={(data, value) => {
                                                                    Setlegalstate(true)
                                                                    setCurrentEntity(data)
                                                                    setVendorComplianceScore({
                                                                        ...vendorComplianceScore,
                                                                        legal_entity_id: data,
                                                                        legal_entity_name: value.children,
                                                                        divisionName: '',
                                                                        categoryName: '',
                                                                        from_month: '',
                                                                        to_month: '',
                                                                        domainName: '',
                                                                        vendor_name: ''

                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%', marginTop: '5px' }}

                                                                value={vendorComplianceScore.legal_entity_name || undefined}
                                                                disabled={vendorComplianceScore.country == '' ? true : false}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                vendorComplianceScore.legal_entity_name,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Category:</b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(value, data) => {
                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    category: value,
                                                                    categoryName: data.children,
                                                                    from_month: '',
                                                                    to_month: '',
                                                                    vendor_name: ''
                                                                })
                                                            }}
                                                            placeholder="Select Category"
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={vendorComplianceScore.categoryName || undefined}
                                                            disabled={vendorComplianceScore.entityid == '' ? true : false}
                                                            showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    
                                                        >
                                                            {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.cat_id}>
                                                                        {item.cat_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                {console.log('ENTITY ID FOR FROM AND TO MONTH :', vendorComplianceScore.entityi)}
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>To Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker format={'MMMM-YYYY'} style={{ width: '100%', marginTop: '5px' }} picker="month"
                                                        value={vendorComplianceScore.to_month ? moment(vendorComplianceScore.to_month) : ''}
                                                        disabled={vendorComplianceScore.legal_entity_name ? false : true}
                                                    // onChange={(date, dateString) => {
                                                    //     setVendorComplianceScore({
                                                    //         ...vendorComplianceScore,
                                                    //         from_month: dateString,
                                                    //         to_month: moment(dateString).add(2, 'months')
                                                    //     })
                                                    // }}
                                                    />
                                                    {validator.current.message(
                                                        'to_month',
                                                        vendorComplianceScore.to_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Month Required',
                                                            }
                                                        })}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: "15px" }}>
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Domain:</b>  <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(value, data) => {
                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    domain: value,
                                                                    domainName: data.children,
                                                                    divisionName: '',
                                                                    categoryName: '',
                                                                    from_month: '',
                                                                    to_month: '',
                                                                    vendor_name: ''
                                                                })
                                                            }}
                                                            placeholder="Enter Domain Name"
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={vendorComplianceScore.domainName || undefined}
                                                            disabled={entityid == '' ? true : false}
                                                            showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    

                                                        >
                                                            {domain && domain.length > 0 && domain.map((item, i) => {

                                                                if (item != undefined) {
                                                                    return (
                                                                        <Option key={item.d_id}>
                                                                            {item.d_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            })}
                                                        </Select>
                                                    </div>
                                                    {validator.current.message(
                                                        'domain',
                                                        vendorComplianceScore.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Vendor:</b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(value, data) => {
                                                                setVendorComplianceScore({
                                                                    ...vendorComplianceScore,
                                                                    vendor_id: value,
                                                                    vendor_name: data.children
                                                                })
                                                            }}
                                                            placeholder="Select Vendor"
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={vendorComplianceScore.vendor_name || undefined}
                                                            disabled={vendorComplianceScore.domainName == '' ? true : false}
                                                            showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    
                                                        >
                                                            {vendorlist && vendorlist.length && vendorlist.map((item, i) => {

                                                                return (
                                                                    <Option key={item.unit_id}>
                                                                        {item.unit_code} - {item.unit_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4'>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ marginLeft: "39px", marginRight: "10px" }} onClick={onshow}
                                                    >
                                                        Show
                                                    </Button>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                                    >
                                                        Export
                                                    </Button>

                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>


                                    </Panel>
                                </Collapse>
                                {tableshow == true ?

                                    <>
                                        <div className='col-md-12 mt-3 mb-3' >
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label><b>Legal Entity :</b>{vendorComplianceScore.legal_entity_name}</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label><b>Country :</b>{sessionArr && sessionArr[0].c_name}</label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain :</b>{vendorComplianceScore.domainName}</label>
                                                </div>

                                            </div>
                                        </div>


                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content fh userprivclass1'>
                                                        <table className='widthHeigh table-fixed'>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className='ant-table-thead' >
                                                                <tr>
                                                                    <th className='ant-table-cell'> <centre>#</centre> </th>
                                                                    <th title='Compliance Task' className='ant-table-cell' style={{ width: '200px' }} >Act</th>
                                                                    <th className='ant-table-cell' style={{ width: '150px' }}  ><centre>Compliance Task</centre></th>
                                                                    <th className='ant-table-cell' style={{ width: '100px' }} ><centre>Criticality</centre></th>
                                                                    <th className='ant-table-cell' style={{ width: '150px' }} ><center>User Name</center></th>
                                                                    <th className='ant-table-cell' style={{ width: '150px' }} >Activity Status</th>
                                                                    <th className='ant-table-cell' style={{ width: '150px' }}  ><center>Due Date</center></th>
                                                                    <th className='ant-table-cell' style={{ width: '150px' }} ><center>Compliance Status</center></th>
                                                                    <th className='ant-table-cell' style={{ width: '150px' }} ><center>Uploaded Document</center></th>


                                                                </tr>
                                                            </thead>
                                                            {filteredArrayState && filteredArrayState.length ? filteredArrayState.map((item, i) => {


                                                                return (
                                                                    <tbody className='ant-table-tbody'>
                                                                        <tr className="bg-lightdark">
                                                                            <td colSpan="100%">
                                                                                <table className='white' style={{ tableLayout: 'auto' }}>
                                                                                    <tbody className='ant-table-tbody'><tr className='ant-table-row ant-table-row-level-0'>
                                                                                        <td className='ant-table-cell w6'><b>Vendor :</b></td>
                                                                                        <td className='ant-table-cell w15' title={item[0].unit}>{item[0].unit_name}</td>
                                                                                        <td className='ant-table-cell w6'><b></b></td>
                                                                                        <td className='ant-table-cell w10'><span className="division-name"></span></td>
                                                                                        <td className='ant-table-cell w6'><b>Category</b></td>
                                                                                        <td className='ant-table-cell w40' ><span className="category-name" title={item[0].category_name}>{item[0].category_name}</span></td>
                                                                                    </tr>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className='ant-table-cell w6'><b>Division :</b></td>
                                                                                            <td className='ant-table-cell w15' >{item[0].division_name}</td>
                                                                                            <td className='ant-table-cell w6'><b></b></td>
                                                                                            <td className='ant-table-cell w10'><span className="division-name"></span></td>
                                                                                            <td className='ant-table-cell w6'><b>Time Zone</b></td>
                                                                                            <td className='ant-table-cell w40'><span className="category-name">{item[0].tz_name}</span></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>

                                                                        {titledata && titledata.map((itemdata, i) => {

                                                                            // let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                            // if (data != undefined) {
                                                                            // if (data.act_name == itemdata.parent) {
                                                                            return <Fragment>
                                                                                {itemdata.child.map((items) => {
                                                                                    if (item[0].unit_id == items.unit_id) {
                                                                                        return <tr>
                                                                                            <td className='ant-table-cell'><centre>{index + 1}</centre></td>
                                                                                            <td className='ant-table-cell'>{items.statutory_mapping}</td>

                                                                                            <td title={items.compliance_task} className='ant-table-cell ellipsis'>
                                                                                                <>
                                                                                                    <Tooltip title={items.compliance_description} >
                                                                                                        <ExclamationCircleTwoTone />
                                                                                                    </Tooltip> &nbsp;
                                                                                                    {items.compliance_task}
                                                                                                </></td>
                                                                                            <td className='ant-table-cell'>{items.criticality_name}</td>
                                                                                            <td className='ant-table-cell'><center>{items.user_name}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.activity_status}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                            <td className='ant-table-cell'><center>{items.task_status}</center></td>

                                                                                            <td className='ant-table-cell'>
                                                                                                <center>{
                                                                                                    <a target="_blank" onClick={() => {
                                                                                                        chilData(items.document_name, items.approval_document_names, items.concur_document_name, items.start_date)
                                                                                                    }}>
                                                                                                        {items.document_name ? <BookTwoTone /> : ''}
                                                                                                    </a>

                                                                                                }
                                                                                                </center></td>
                                                                                            <Modal visible={isModalVisible} onCancel={handleCancel} onOk={() => {
                                                                                                setIsModalVisible(false)
                                                                                            }} >
                                                                                                <Table
                                                                                                    className='userprivclass'
                                                                                                    columns={columns}
                                                                                                    dataSource={Documentdata}
                                                                                                    bordered
                                                                                                    pagination={false} />
                                                                                            </Modal>
                                                                                            <span hidden>{index = index + 1}</span>

                                                                                        </tr>
                                                                                    }

                                                                                })

                                                                                }

                                                                            </Fragment>

                                                                            // }
                                                                            // }
                                                                        })
                                                                        }

                                                                    </tbody>
                                                                )
                                                            }) :
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td style={{ paddingTop: '5%', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '5%' }}>No Records Found</td>

                                                                </tr>
                                                            }
                                                        </table>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div>
                                            {index === 0 ? '' :
                                                <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                                    <label style={{ marginLeft: "15px" }}>Showing {pageSize * (current - 1) + 1} to {index} of {tabledatas && tabledatas.total_count} entries</label>
                                                </div>
                                            }

                                            <div
                                                // className="btn btn-light-success rounded-pill px-4 text-success"
                                                style={{ position: 'absolute', bottom: '-25px', right: 0 }}
                                            // onClick={submitrec}
                                            >
                                                <div style={{ float: 'right' }}>


                                                    <Pagination
                                                        current={current}
                                                        showSizeChanger={true}
                                                        // showQuickJumper={true}
                                                        onShowSizeChange={() => {
                                                            setCurrent(1)
                                                            // setpageState(true)
                                                        }}
                                                        pageSizeOptions={[25, 50, 100]}
                                                        hideOnSinglePage={true}
                                                        defaultPageSize={25}
                                                        onChange={(page, pageSizes) => {
                                                            setpageState(true)
                                                            setCurrent(pageSize !== pageSizes ? 1 : page);
                                                            setPageSize(pageSizes)
                                                        }}
                                                        total={tabledatas && tabledatas.total_count}
                                                    />


                                                </div>

                                            </div>
                                        </div>
                                    </>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Select Fields" visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                <div className='row'>
                    <div className='col-lg-3'><input id="select_all"
                    checked={checkboxValue.length === 34 ? true : false}
                    width="100%" type="checkbox" autocomplete="off" onClick={(e) => {
                        let checked = e.target.checked
                        selectAllCheckBox(checked)
                    }} /> Select All</div>
                </div>
                <br />

                <div className='row'>
                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='0' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Vendor Code</div>
                    <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" value='1' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Vendor Name </div>
                    <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" value='2' onChange={(e) => checkBoxOnChange(e)} />Vendor Code 1</div>
                    <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" value='3' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 2</div>
                </div>
                <br />

                <div className='row'>
                    <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 3</div>
                    <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 4</div>
                    <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='6' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Location</div>
                    <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='7' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Act / Rules</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='8' onChange={(e) => (e) => checkBoxOnChange(e)} /> Compliance Task</div>
                    <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='9' onChange={(e) => checkBoxOnChange(e)} />Frequency </div>
                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='10' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Periodicity</div>
                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='11' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Criticality</div>

                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Division</div>
                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Category</div>
                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Assigned By</div>
                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Assigned To </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date</div>
                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assignee</div>
                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Completed On</div>
                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Concurrer </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Concurred On</div>
                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Approver</div>
                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Approved On</div>
                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Activity Status </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Start Date</div>
                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Due Date</div>
                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Extend Due Date</div>
                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date / Document Issued Date </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> Month</div>
                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /> Validity Date</div>
                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> Statutory Status</div>
                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='31' onChange={(e) => checkBoxOnChange(e)} /> Duration </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='32' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number</div>
                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} /> Remarks</div>

                </div>
                <br />

                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '40%' }}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                </div>

            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    MonthlyComplianceFilterReport,
    MonthlyComplianceTable,
    MonthlyComplianceExport,
    Downloadfile,
})(MonthlyComplianceReport);