import { UNITREPORT, UNITWISEREPORT } from './../../types/index'
const initialState = {
    unitwisereport: {
        unitreport: [],
        unitwisereports: []

    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case UNITREPORT:
            {
                return {
                    ...state,
                    unitwisereport: {
                        ...state.unitwisereport,
                        unitreport: payload,
                    },
                };
            }
        case UNITWISEREPORT:
            {
                return {
                    ...state,
                    unitwisereport: {
                        ...state.unitwisereport,
                        unitwisereports: payload[1],
                    },
                };
            }
        default:
            return state;
    }
}