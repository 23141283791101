import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, {getUrl} from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { OVERDUE_CHART, TOGGLE_COMMON_LOADER, COMMON_DATA, OVERDUE_CHART_TABLE_DATA, TIME_ZONE_DATA,SHOW_MORE_TABLE_DATA } from './../../types/index';
import alertmessage from './../../../Libs/Altermessage';
const urls = getUrl()


export const getCommonData = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, "payload5555");
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_master_filters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();

        }
        else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: COMMON_DATA,
                    payload: decryptData[1],
                });
            }
            // }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getOverduechart = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, "payload5555");
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_dashboard`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();

        }
        else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: OVERDUE_CHART,
                    payload: decryptData[1],
                });
            }
            // }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getOverDueChartTableData = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, "payload5555");
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_dashboard`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();

        }
        else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: OVERDUE_CHART_TABLE_DATA,
                    payload: decryptData[1],
                });
            }
            // }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getTimeZoneData = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, "payload5555");
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_master_filters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();

        }
        else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TIME_ZONE_DATA,
                    payload: decryptData[1],
                });
            }
            // }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getOverDueShowMoreTableData = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, "payload5555");
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_dashboard`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        }
        else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: SHOW_MORE_TABLE_DATA,
                    payload: decryptData[1],
                });
            }
            // }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


// export const getRiskChartDelayedTableData = ({ payload, executeCancel, paramid }) => async dispatch => {
//     console.log(payload, "payload5555");
//     try {
//         dispatch({
//             type: TOGGLE_COMMON_LOADER
//         });
//         const encryptkey = localStorage.getItem('formkey')
//         const head = {
//             headers: {
//                 'Caller-Name': paramid
//             },
//         }
//         let encoded = base64_decode(encryptkey);
//         const data1 = EncryptDecrypt.encryptdata(payload, encoded);
//         const createRequest = HTTP.commonpost({
//             url: `${url.CommonURL}/api/client_dashboard`,
//             headers: head,
//             executeCancel,
//             body: data1

//         })
//         const executeRequest = await HTTP.execute(createRequest)
//         if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
//             executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
//             (executeRequest.status === 400 || executeRequest.status === 401)) {
//             dispatch({
//                 type: TOGGLE_COMMON_LOADER
//             });
//             Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
//         }
//         else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
//             executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
//             (executeRequest.status === 401 || executeRequest.status === 503)) {
//             dispatch({
//                 type: TOGGLE_COMMON_LOADER
//             });
//             // Toaster.error("Service Unavailable")
//             alertmessage.sweetalert();

//         }
//         else {
//             const response = HTTP.parseResponse(executeRequest)
//             const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
//             console.log(decryptData, "decryptData");
//             // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
//             dispatch({
//                 type: TOGGLE_COMMON_LOADER
//             });
//             if (decryptData[0] === 'InvalidSessionToken') {
//                 dispatch({
//                     type: TOGGLE_COMMON_LOADER
//                 });
//                 alertmessage.sweetalert();

//             } else {
//                 dispatch({
//                     type: RISK_CHART_DELAYED_TABLE_DATA,
//                     payload: decryptData[1],
//                 });
//             }
//             // }
//         }


//     } catch (err) {
//         Toaster.error(err)
//         dispatch({
//             type: TOGGLE_COMMON_LOADER
//         });
//     }
// };
