import { CERTIFICATELABELLIST } from '../../types/index'


const initialState = {
    list: ''

}
export default function (state = initialState, action) {
    const { type, payload } = action;
    
    switch (type) {
        case CERTIFICATELABELLIST: {
            return {
                ...state,
                list:  payload[1],
            };
        }
        default:
            return state;
    }
}
