export const blockInvalidChar = e =>
  ["e", "E", "-"].includes(e.key) && e.preventDefault();

export const allowAlpha = e => {
  var k = e.which;
  const ok = k >= 65 && k <= 90 || // A-Z
    k >= 96 && k <= 105 || // a-z
    k == 8 || k == 9 || k == 32 ||
    k >= 35 && k <= 40; // arrows
  if (!ok) {
    e.preventDefault();
  }
  // regex.includes(e.key) && e.preventDefault();
}
export const allowNum = e => {
  var key = window.event ? e.keyCode : e.which;
  if (e.keyCode === 8 || e.keyCode === 46) {
    e.preventDefault();
  } else if ( key < 48 || key > 57 ) {
      e.preventDefault();
  } else {
    e.preventDefault();
  }

}

