import { STATOTORYLIST, STATUTORYSEDIT, STATUTORYFILTERDATA} from '../../types/index'


const initialState = {
    statutorysettings: {
        statutorylist: '',
        editdatas:'',
        filterdata:'',
        totaldata: ''
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
     
    switch (type) {
        case STATOTORYLIST: {
            return {
                ...state,
                statutorysettings: {
                    ...state.statutorysettings,
                    statutorylist: payload[1].statutories,
                },
            };
        }

        case STATUTORYSEDIT:{
            return {
                ...state,
                statutorysettings: {
                    ...state.statutorysettings,
                    editdatas: payload[1].applicable_statu,
                    totaldata: payload[1].r_count
                },
            };   
        }
        case STATUTORYFILTERDATA:{
            return {
                ...state,
                statutorysettings: {
                    ...state.statutorysettings,
                    filterdata: payload[1],
                },
            };   
        }
        default:
            return state;
    }
}