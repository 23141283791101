import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import {CERTIFICATE_LEVEL_LIST,CERTIFICATE_LEVEL_ADD} from '../../types/index'


const initialState = {
   
    Certificate:{
        CertificateTableList:'',
        CertificateTableAdd:''
        
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CERTIFICATE_LEVEL_LIST: {
            console.log(payload,"payload111");
            return {
                ...state,
                Certificate: {
                    ...state.Certificate,
                    CertificateTableList:payload[1]
                },
            };
        }
        case CERTIFICATE_LEVEL_ADD: {
            console.log(payload,"payload777");
            return {
                ...state,
                Certificate: {
                    ...state.Certificate,
                    CertificateTableAdd:payload[1]
                },
            };
        }
      
        default:
            return state;
    }
}