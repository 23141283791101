import {CRITICALITY_CHART,COMMON_DATA, TOGGLE_COMMON_LOADER,CRITICALITY_CHART_TABLE_DATA,TIME_ZONE_DATA } from './../../types/index'
const initialState = {
    criticalityChart: {
       chartData:[],
       time:0,
       Data:[],
       tableData:[],
       timeZoneData:[]
       
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CRITICALITY_CHART: {
            console.log(payload,"payload4444");
            return {
                ...state,
                criticalityChart: {
                    ...state.criticalityChart,
                    chartData: payload.criticality_chart_data_nu,
                    time:payload.current_date
                },
            };
        }
        case COMMON_DATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                criticalityChart: {
                    ...state.criticalityChart,
                    Data: payload,
                },
            };
        }
        case CRITICALITY_CHART_TABLE_DATA:{
            return {
                ...state,
                criticalityChart: {
                    ...state.criticalityChart,
                    tableData: payload,
                },
            };
        }
        case TIME_ZONE_DATA:{
            return {
                ...state,
                criticalityChart: {
                    ...state.criticalityChart,
                    timeZoneData: payload,
                },
            };
        }
        default:
            return state;
    }
}
