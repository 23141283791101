import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { VENDOR_CLOSURE_LIST } from '../../types/index'


const initialState = {

    vendorClosure: {
        VendorClosureList: '',

    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case VENDOR_CLOSURE_LIST:
            {
                console.log(payload, "payload111");
                return {
                    ...state,
                    vendorClosure: {
                        ...state.vendorClosure,
                        VendorClosureList: payload[1]
                    },
                };
            }

        default:
            return state;
    }
}