import { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Select } from 'antd';
import { Link } from 'react-router-dom';
import { Area, Line } from '@ant-design/plots';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";



const mapStateToProps = (state) => ({

})
const LandingPage = ({
}) => {
    const { Option } = Select;
    const data =
        [
            { "Year": "2022", "date": 'Jan', "value": 1390 },
            { "Year": "2022", "date": 'Feb', "value": 1469 },
            { "Year": "2022", "date": 'Mar', "value": 1521 },
            { "Year": "2022", "date": 'Apr', "value": 1615 },
            { "Year": "2022", "date": 'May', "value": 1703 },
            { "Year": "2022", "date": 'Jun', "value": 1767 },
            { "Year": "2022", "date": 'Jul', "value": 1806 },
            { "Year": "2022", "date": 'Aug', "value": 1903 },
            { "Year": "2022", "date": 'Sep', "value": 1986 },
            { "Year": "2022", "date": 'Oct', "value": 1952 },
            { "Year": "2022", "date": 'Nov', "value": 1910 },
            { "Year": "2022", "date": 'Dec', "value": 2015 },
            { "Year": "2023", "date": 'Jan', "value": 1000 },
            { "Year": "2023", "date": 'Feb', "value": 1100 },
            { "Year": "2023", "date": 'Mar', "value": 1200 },
            { "Year": "2023", "date": 'Apr', "value": 1300 },
            { "Year": "2023", "date": 'May', "value": 1541 },
            { "Year": "2023", "date": 'Jun', "value": 1364 },
            { "Year": "2023", "date": 'Jul', "value": 3541 },
            { "Year": "2023", "date": 'Aug', "value": 1955 },
            { "Year": "2023", "date": 'Sep', "value": 5410 },
            { "Year": "2023", "date": 'Oct', "value": 1345 },
            { "Year": "2023", "date": 'Nov', "value": 2052 },
            { "Year": "2023", "date": 'Dec', "value": 3546 },
        ]
    const dataline =
        [
            {
                "year": "Jan",
                "value": 30,
                "category": "Complied"
            },
            {
                "year": "Jan",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jan",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Feb",
                "value": 110,
                "category": "Complied"
            },
            {
                "year": "Feb",
                "value": 94,
                "category": "Delayed Compliance"
            },
            {
                "year": "Feb",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Mar",
                "value": 75,
                "category": "Complied"
            },
            {
                "year": "Mar",
                "value": 45,
                "category": "Delayed Compliance"
            },
            {
                "year": "Mar",
                "value": 56,
                "category": "Not Complied"
            },
            {
                "year": "Apr",
                "value": 40,
                "category": "Complied"
            },
            {
                "year": "Apr",
                "value": 44,
                "category": "Delayed Compliance"
            },
            {
                "year": "Apr",
                "value": 64,
                "category": "Not Complied"
            },
            {
                "year": "May",
                "value": 65,
                "category": "Complied"
            },
            {
                "year": "May",
                "value": 75,
                "category": "Delayed Compliance"
            },
            {
                "year": "May",
                "value": 65,
                "category": "Not Complied"
            },
            {
                "year": "Jun",
                "value": 74,
                "category": "Complied"
            },
            {
                "year": "Jun",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jun",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Jul",
                "value": 80,
                "category": "Complied"
            },
            {
                "year": "Jul",
                "value": 70,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jul",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Aug",
                "value": 75,
                "category": "Complied"
            },
            {
                "year": "Aug",
                "value": 46,
                "category": "Delayed Compliance"
            },
            {
                "year": "Aug",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Sep",
                "value": 65,
                "category": "Complied"
            },
            {
                "year": "Sep",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Sep",
                "value": 84,
                "category": "Not Complied"
            },
            {
                "year": "Oct",
                "value": 76,
                "category": "Complied"
            },
            {
                "year": "Oct",
                "value": 45,
                "category": "Delayed Compliance"
            },
            {
                "year": "Oct",
                "value": 96,
                "category": "Not Complied"
            },
            {
                "year": "Nov",
                "value": 60,
                "category": "Complied"
            },
            {
                "year": "Nov",
                "value": 74,
                "category": "Delayed Compliance"
            },
            {
                "year": "Nov",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Dec",
                "value": 110,
                "category": "Complied"
            },
            {
                "year": "Dec",
                "value": 90,
                "category": "Delayed Compliance"
            },
            {
                "year": "Dec",
                "value": 70,
                "category": "Not Complied"
            },

        ]
    const configs = {
        data: data,
        xField: 'date',
        yField: 'value',
        seriesField: 'Year',
        legend: {
            position: 'bottom'
        }
    };

    const configline = {
        data: dataline,
        xField: 'year',
        yField: 'value',
        seriesField: 'category',
        yAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        color: ['#1979C9', '#D62A0D', '#FAA219'],
        legend: {
            position: 'bottom',
        },
        smooth: false,

    };

    useEffect(() => {
        am4core.useTheme(am4themes_animated);

        //overduechart
        var chart = am4core.create("chartdiv", am4charts.RadarChart);
        chart.data = [{
            "category": "0-30 days",
            "value": 40,
            "full": 100,
        }, {
            "category": "31-60 days",
            "value": 75,
            "full": 100,
            color: '#1E0000'
        }, {
            "category": "61-90 days",
            "value": 80,
            "full": 100,
            color: '#1E0000'
        }, {
            "category": "Above 90 days",
            "value": 95,
            "full": 100,
            color: '#1E0000'
        }];
        chart.startAngle = -90;
        chart.endAngle = 180;
        chart.innerRadius = am4core.percent(20);
        chart.numberFormatter.numberFormat = "#.#'%'";
        chart.colors.list = [am4core.color("#89CFF0"), am4core.color("#6699CC"), am4core.color("#0000FF"), am4core.color("#0066b2")];
        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.fontWeight = 500;
        categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
            return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
        });
        categoryAxis.renderer.minGridDistance = 10;

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.strokeOpacity = 0;
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;

        // Create series
        var series1 = chart.series.push(new am4charts.RadarColumnSeries());
        series1.dataFields.valueX = "full";
        series1.dataFields.categoryY = "category";
        series1.clustered = false;
        series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        series1.columns.template.fillOpacity = 0.08;
        series1.columns.template.cornerRadiusTopLeft = 20;
        series1.columns.template.strokeWidth = 0;
        series1.columns.template.radarColumn.cornerRadius = 20;


        var series2 = chart.series.push(new am4charts.RadarColumnSeries());
        series2.dataFields.valueX = "value";
        series2.dataFields.categoryY = "category";
        series2.clustered = false;
        series2.columns.template.strokeWidth = 0;
        series2.columns.template.tooltipText = "{category}: [bold]{value}[/]";
        series2.columns.template.radarColumn.cornerRadius = 20;

        series2.columns.template.adapter.add("fill", function (fill, target) {
            return chart.colors.getIndex(target.dataItem.index);
        });

        // Add cursor
        chart.cursor = new am4charts.RadarCursor();



        //TaskDistribution
        var taskchart = am4core.create("taskchartdiv", am4charts.XYChart);
        taskchart.data = [{
            "country": "Assigned",
            "visits": 370,
            color: taskchart.colors.getIndex(3),
        }, {
            "country": "Not Opted",
            "visits": 308,
            color: taskchart.colors.getIndex(5),

        }, {
            "country": "Unassigned",
            "visits": 500,
            color: taskchart.colors.getIndex(9),

        }, {
            "country": "Not Applicable",
            "visits": 463,
            color: taskchart.colors.getIndex(11),
        }
        ];
        taskchart.padding(40, 40, 40, 40);
        taskchart.colors.list = [am4core.color("#004D47"), am4core.color("#128277"), am4core.color("#52958B")];
        var categoryAxis = taskchart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "country";
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;
        var valueAxis = taskchart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.extraMax = 0.1;
        //valueAxis.rangeChangeEasing = am4core.ease.linear;
        //valueAxis.rangeChangeDuration = 1500;
        var taskseries = taskchart.series.push(new am4charts.ColumnSeries());
        taskseries.dataFields.categoryX = "country";
        taskseries.dataFields.valueY = "visits";
        taskseries.tooltipText = "{valueY.value}"
        taskseries.columns.template.strokeOpacity = 0;
        taskseries.columns.template.column.cornerRadiusTopRight = 10;
        taskseries.columns.template.column.cornerRadiusTopLeft = 10;
        taskseries.columns.template.propertyFields.fill = 'color';

        //series.interpolationDuration = 1500;
        //series.interpolationEasing = am4core.ease.linear;
        var labelBullet = taskseries.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.verticalCenter = "bottom";
        labelBullet.label.dy = -10;
        labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";
        taskchart.zoomOutButton.disabled = true;
        // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
        taskseries.columns.template.adapter.add("fill", function (fill, target) {
            return taskchart.colors.getIndex(target.dataItem.index);
        });
        setInterval(function () {
            am4core.array.each(taskchart.data, function (item) {
                item.visits += Math.round(Math.random() * 200 - 100);
                item.visits = Math.abs(item.visits);
            })
            taskchart.invalidateRawData();
        }, 2000)
        categoryAxis.sortBySeries = taskseries;

        //criticality chart
        var criticalitychart = am4core.create("criticality", am4charts.XYChart);
        criticalitychart.hiddenState.properties.opacity = 0; // this creates initial fade-in         
        criticalitychart.paddingBottom = 20;
        criticalitychart.data = [{
            "name": "High",
            "steps": 85,
            "href": "High.png",
            color: '#9e1b32'
        }, {
            "name": "Medium",
            "steps": 55,
            "href": "Medium.png",
            color: '#E32636'
        }, {
            "name": "Low",
            "steps": 45,
            "href": "Low.png",
            color: '#E52B50'
        }];
        var criticalitycategoryAxis = criticalitychart.xAxes.push(new am4charts.CategoryAxis());
        criticalitycategoryAxis.dataFields.category = "name";
        criticalitycategoryAxis.renderer.grid.template.strokeOpacity = 0;
        criticalitycategoryAxis.renderer.minGridDistance = 5;
        criticalitycategoryAxis.renderer.labels.template.dy = 30;
        criticalitycategoryAxis.renderer.tooltip.dy = 30; var criticalityvalueAxis = criticalitychart.yAxes.push(new am4charts.ValueAxis());
        criticalityvalueAxis.renderer.inside = true;
        criticalityvalueAxis.renderer.labels.template.fillOpacity = 0.5;
        criticalityvalueAxis.renderer.grid.template.strokeOpacity = 0;
        criticalityvalueAxis.min = 0;
        criticalityvalueAxis.cursorTooltipEnabled = false;
        criticalityvalueAxis.renderer.baseGrid.strokeOpacity = 0; var scriticalityseries = criticalitychart.series.push(new am4charts.ColumnSeries);
        scriticalityseries.dataFields.valueY = "steps";
        scriticalityseries.dataFields.categoryX = "name";
        scriticalityseries.tooltipText = "{valueY.value}";
        scriticalityseries.tooltip.pointerOrientation = "vertical";
        scriticalityseries.tooltip.dy = - 6;
        scriticalityseries.columnsContainer.zIndex = 100;
        scriticalityseries.columns.template.propertyFields.fill = "color"; var columnTemplate = scriticalityseries.columns.template;
        columnTemplate.width = am4core.percent(50);
        columnTemplate.maxWidth = 55;
        columnTemplate.column.cornerRadius(60, 60, 10, 10);
        columnTemplate.strokeOpacity = 0; scriticalityseries.heatRules.push({ target: columnTemplate, property: "fill", dataField: "valueY", min: am4core.color("#e5dc36"), max: am4core.color("#5faa46") });
        scriticalityseries.mainContainer.mask = undefined; var cursor = new am4charts.XYCursor();
        criticalitychart.cursor = cursor;
        // criticalitychart.lineX.disabled = true;
        // criticalitychart.lineY.disabled = true;
        criticalitychart.behavior = "none"; var bullet = columnTemplate.createChild(am4charts.CircleBullet);
        bullet.circle.radius = 30;
        bullet.valign = "bottom";
        bullet.align = "center";
        bullet.isMeasured = true;
        bullet.mouseEnabled = false;
        bullet.verticalCenter = "bottom";
        bullet.interactionsEnabled = false; var hoverState = bullet.states.create("hover");
        var outlineCircle = bullet.createChild(am4core.Circle);
        outlineCircle.adapter.add("radius", function (radius, target) {
            var circleBullet = target.parent;
            return circleBullet.circle.pixelRadius + 10;
        })
        var image = bullet.createChild(am4core.Image);
        image.width = 60;
        image.height = 60;
        image.horizontalCenter = "middle";
        image.verticalCenter = "middle";
        image.propertyFields.href = "href"; image.adapter.add("mask", function (mask, target) {
            var circleBullet = target.parent;
            return circleBullet.circle;
        })
        var previousBullet;
        criticalitychart.cursor.events.on("cursorpositionchanged", function (event) {
            var dataItem = scriticalityseries.tooltipDataItem; if (dataItem.column) {
                var bullet = dataItem.column.children.getIndex(1); if (previousBullet && previousBullet != bullet) {
                    previousBullet.isHover = false;
                } if (previousBullet != bullet) {
                    var hs = bullet.states.getKey("hover");
                    hs.properties.dy = -bullet.parent.pixelHeight + 30;
                    bullet.isHover = true; previousBullet = bullet;
                }
            }
        })

        //Compliance Status        
        var compliancestatus = am4core.create("compliancestatusdiv", am4charts.XYChart);
        compliancestatus.height = '500'
        compliancestatus.data = [{ "year": "India", "Complied": 25, "Delayed_Compliance": 35, "In_Progress": 15, "Not_Complied": 25 }, { "year": "Srilanka", "Complied": 10, "Delayed_Compliance": 15, "In_Progress": 36, "Not_Complied": 12 }, { "year": "Australia", "Complied": 32, "Delayed_Compliance": 51, "In_Progress": 16, "Not_Complied": 56 }]
        compliancestatus.colors.list = [am4core.color("#0de580"), am4core.color("#3c78d8"), am4core.color("#f9c80e"), am4core.color("#fe4365"),];
        var compliancestatuscategoryAxis = compliancestatus.yAxes.push(new am4charts.CategoryAxis());
        compliancestatuscategoryAxis.dataFields.category = "year";
        compliancestatuscategoryAxis.numberFormatter.numberFormat = "#";
        compliancestatuscategoryAxis.renderer.inversed = true;
        var compliancestatusvalueAxis = compliancestatus.xAxes.push(new am4charts.ValueAxis());
        var legend = compliancestatus.children.push(new am4charts.Legend());
        function makeSeries(name, fieldName, color) {
            var compliancestatusseries = compliancestatus.series.push(new am4charts.ColumnSeries3D());
            compliancestatusseries.dataFields.valueX = name;
            compliancestatusseries.dataFields.categoryY = "year";
            compliancestatusseries.dataFields.color = color;
            compliancestatusseries.name = fieldName;
            compliancestatusseries.stacked = true;
            compliancestatusseries.columns.template.propertyFields.fill = "color";
            compliancestatusseries.columns.template.tooltipText = "{name}, {valueX}";
            compliancestatusseries.columns.template.column3D.stroke = am4core.color("#fff");
            compliancestatusseries.columns.template.column3D.strokeOpacity = 0.2;
            legend.data.push(compliancestatusseries);
        }
        makeSeries("Complied", "Complied", "#00FF00");
        makeSeries("Delayed_Compliance", "Delayed Compliance", "#0000FF");
        makeSeries("In_Progress", "In Progress", "#FFFF00");
        makeSeries("Not_Complied", "Not Complied", "#FF0000");


        //Comprative trend
        var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart3D);
        Comprativechart.data = [{
            "country": "Jan",
            "year2022": 88,
            "year2021": 95
        }, {
            "country": "Feb",
            "year2022": 70,
            "year2021": 80
        }, {
            "country": "Mar",
            "year2022": 70,
            "year2021": 65
        }, {
            "country": "Apr",
            "year2022": 40,
            "year2021": 55
        }, {
            "country": "May",
            "year2022": 82,
            "year2021": 90
        }, {
            "country": "June",
            "year2022": 71,
            "year2021": 75
        }, {
            "country": "July",
            "year2022": 47,
            "year2021": 32
        }, {
            "country": "Aug",
            "year2022": 65,
            "year2021": 80
        }, {
            "country": "Sep",
            "year2022": 45,
            "year2021": 25
        },
        {
            "country": "Oct",
            "year2022": 74,
            "year2021": 62
        },
        {
            "country": "Nov",
            "year2022": 80,
            "year2021": 90
        },
        {
            "country": "Dec",
            "year2022": 81,
            "year2021": 85
        }];
        Comprativechart.colors.list = [am4core.color("#0de580"), am4core.color("#3c78d8")];
        Comprativechart.legend = new am4charts.Legend();
        var ComprativeAxis = Comprativechart.xAxes.push(new am4charts.CategoryAxis());
        ComprativeAxis.dataFields.category = "country";
        ComprativeAxis.renderer.grid.template.location = 0;
        ComprativeAxis.renderer.minGridDistance = 30;

        var ComprativevalueAxis = Comprativechart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Compliance %";
        valueAxis.renderer.labels.template.adapter.add("text", function (text) {
            return text;
        });

        var Comprativeseries = Comprativechart.series.push(new am4charts.ColumnSeries3D());
        Comprativeseries.dataFields.valueY = "year2022";
        Comprativeseries.dataFields.categoryX = "country";
        Comprativeseries.name = "Year 2022";
        Comprativeseries.clustered = false;
        Comprativeseries.columns.template.tooltipText = "India{category} (2022): [bold]{valueY}[/]";
        Comprativeseries.columns.template.fillOpacity = 0.9;

        var Comprativeseries2 = Comprativechart.series.push(new am4charts.ColumnSeries3D());
        Comprativeseries2.dataFields.valueY = "year2021";
        Comprativeseries2.dataFields.categoryX = "country";
        Comprativeseries2.name = "Year 2021";
        Comprativeseries2.clustered = false;
        Comprativeseries2.columns.template.tooltipText = "India{category} (2021): [bold]{valueY}[/]";


        //Compliance trend

        var Compliancechart = am4core.create("Compliancechartdiv", am4charts.XYChart);
        Compliancechart.data = [{
            "year": "2018",
            "Complied": 2148,
            "Delayed Compliance": 1814,
            "Not Complied": 1945
        }, {
            "year": "2019",
            "Complied": 2233,
            "Delayed Compliance": 1845,
            "Not Complied": 2045
        }, {
            "year": "2020",
            "Complied": 2321,
            "Delayed Compliance": 2056,
            "Not Complied": 1687
        }, {
            "year": "2021",
            "Complied": 1894,
            "Delayed Compliance": 2100,
            "Not Complied": 1624
        }, {
            "year": "2022",
            "Complied": 1988,
            "Delayed Compliance": 999,
            "Not Complied": 788
        }];
        Compliancechart.colors.list = [am4core.color("#00FF7F"), am4core.color("#6A5ACD"), am4core.color("#FF0000")];
        var ComplianceAxis = Compliancechart.xAxes.push(new am4charts.CategoryAxis());
        ComplianceAxis.dataFields.category = "year";
        ComplianceAxis.renderer.opposite = true;

        var CompliancevalueAxis = Compliancechart.yAxes.push(new am4charts.ValueAxis());
        CompliancevalueAxis.title.text = "Total Value";
        CompliancevalueAxis.renderer.minLabelPosition = 0.01;

        var complianceseries1 = Compliancechart.series.push(new am4charts.LineSeries());
        complianceseries1.dataFields.valueY = "Complied";
        complianceseries1.dataFields.categoryX = "year";
        complianceseries1.name = "Complied";
        complianceseries1.bullets.push(new am4charts.CircleBullet());
        complianceseries1.tooltipText = "{name} in {categoryX}: {valueY}";
        complianceseries1.legendSettings.valueText = "{valueY}";
        complianceseries1.visible = false;

        var complianceseries2 = Compliancechart.series.push(new am4charts.LineSeries());
        complianceseries2.dataFields.valueY = "Delayed Compliance";
        complianceseries2.dataFields.categoryX = "year";
        complianceseries2.name = 'Delayed Compliance';
        complianceseries2.bullets.push(new am4charts.CircleBullet());
        complianceseries2.tooltipText = "{name} in {categoryX}: {valueY}";
        complianceseries2.legendSettings.valueText = "{valueY}";

        var complianceseries3 = Compliancechart.series.push(new am4charts.LineSeries());
        complianceseries3.dataFields.valueY = "Not Complied";
        complianceseries3.dataFields.categoryX = "year";
        complianceseries3.name = 'Not Complied';
        complianceseries3.bullets.push(new am4charts.CircleBullet());
        complianceseries3.tooltipText = "{name} in {categoryX}: {valueY}";
        complianceseries3.legendSettings.valueText = "{valueY}";

        Compliancechart.cursor = new am4charts.XYCursor();
        Compliancechart.cursor.behavior = "zoomY";

        let hs1 = complianceseries1.segments.template.states.create("hover")
        hs1.properties.strokeWidth = 5;
        complianceseries1.segments.template.strokeWidth = 1;

        let hs2 = complianceseries2.segments.template.states.create("hover")
        hs2.properties.strokeWidth = 5;
        complianceseries2.segments.template.strokeWidth = 1;

        let hs3 = complianceseries3.segments.template.states.create("hover")
        hs3.properties.strokeWidth = 5;
        complianceseries3.segments.template.strokeWidth = 1;

        Compliancechart.legend = new am4charts.Legend();
        Compliancechart.legend.itemContainers.template.events.on("over", function (event) {
            var segments = event.target.dataItem.dataContext.segments;
            segments.each(function (segment) {
                segment.isHover = true;
            })
        })
        Compliancechart.legend.itemContainers.template.events.on("out", function (event) {
            var segments = event.target.dataItem.dataContext.segments;
            segments.each(function (segment) {
                segment.isHover = false;
            })
        })


        //Risk Chart
        var riskchart = am4core.create("Riskchartdiv", am4charts.PieChart);
        riskchart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        riskchart.data = [
            {
                country: "Not Opted",
                visits: 308,
            },
            {
                country: "Unassigned",
                visits: 3421,
            },
            {
                country: "Not Applicable",
                visits: 496,
            },
        ];
        riskchart.radius = am4core.percent(70);
        riskchart.innerRadius = am4core.percent(40);
        riskchart.startAngle = 180;
        riskchart.endAngle = 360;
        var riskseries = riskchart.series.push(new am4charts.PieSeries());
        riskseries.dataFields.value = "visits";
        riskseries.dataFields.category = "country";

        riskseries.slices.template.cornerRadius = 10;
        riskseries.slices.template.innerCornerRadius = 7;
        riskseries.slices.template.draggable = true;
        riskseries.slices.template.inert = true;
        riskseries.alignLabels = false;
        riskseries.labels.template.text = "{value.percent.formatNumber('#.0')}%";

        riskseries.hiddenState.properties.startAngle = 90;
        riskseries.hiddenState.properties.endAngle = 90;

        riskchart.legend = new am4charts.Legend();

        //Exposed Risk
        var ExposedRiskchart = am4core.create("ExposedRiskchartdiv", am4charts.XYChart3D);
        ExposedRiskchart.hiddenState.properties.opacity = 0;
        ExposedRiskchart.data = [
            {
                country: "Not Complied",
                visits: 25,
            },
            {
                country: "Delayed Compliance",
                visits: 6,
            },
        ];
        ExposedRiskchart.colors.list = [am4core.color("#1F9DF5"), am4core.color("#F6A6F5")];
        var ExposedRiskcategoryAxis = ExposedRiskchart.xAxes.push(new am4charts.CategoryAxis());
        ExposedRiskcategoryAxis.renderer.grid.template.location = 0;
        ExposedRiskcategoryAxis.dataFields.category = "country";
        ExposedRiskcategoryAxis.renderer.minGridDistance = 40;
        ExposedRiskcategoryAxis.fontSize = 11;
        ExposedRiskcategoryAxis.renderer.labels.template.dy = 5;

        var ExposedvalueAxis = ExposedRiskchart.yAxes.push(new am4charts.ValueAxis());
        ExposedvalueAxis.min = 0;
        ExposedvalueAxis.renderer.minGridDistance = 30;
        ExposedvalueAxis.renderer.baseGrid.disabled = true;


        var ExposedvalueAxisRiskseries = ExposedRiskchart.series.push(new am4charts.ColumnSeries3D());
        ExposedvalueAxisRiskseries.dataFields.categoryX = "country";
        ExposedvalueAxisRiskseries.dataFields.valueY = "visits";
        ExposedvalueAxisRiskseries.columns.template.tooltipText = "{country}" + ':' + "{valueY.value}";
        ExposedvalueAxisRiskseries.columns.template.tooltipY = 0;
        ExposedvalueAxisRiskseries.columns.template.strokeOpacity = 0;
        ExposedvalueAxisRiskseries.columns.template.adapter.add("fill", function (fill, target) {
            return ExposedRiskchart.colors.getIndex(target.dataItem.index);
        });

    }, [])


    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                        </nav>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-3'>
                                    <div className="card bg-success card-hover text-white ">
                                        <div className="card-body p-2">
                                            <div className="d-flex flex-row align-items-center">
                                                <div className="
                                        round
                                        rounded-circle
                                        text-success
                                        d-inline-block
                                        text-center
                                        bg-white
                                        d-flex
                                        justify-content-center
                                        align-items-center
                                    ">
                                                    <i data-feather='clipboard'></i>
                                                </div>
                                                <div className="ms-3 align-self-center">
                                                    <h3 className="mb-0 text-white fw-bold h1">40%</h3>
                                                    <span className="text-white h5">Complied</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-3'>
                                    <div className="card bg-warning card-hover text-white">
                                        <div className="card-body p-2">
                                            <div className="d-flex flex-row align-items-center">
                                                <div className="
                                        round
                                        rounded-circle
                                        text-warning
                                        d-inline-block
                                        text-center
                                        bg-white
                                        d-flex
                                        justify-content-center
                                        align-items-center
                                    ">
                                                    <i data-feather='clock'></i>
                                                </div>
                                                <div className="ms-3 align-self-center">
                                                    <h3 className="mb-0 text-white fw-bold h1">55%</h3>
                                                    <span className="text-white h5">Delayed Compliance</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="card bg-secondary card-hover text-white">
                                        <div className="card-body p-2">
                                            <div className="d-flex flex-row align-items-center">
                                                <div className="
                                        round
                                        rounded-circle
                                        text-secondary
                                        d-inline-block
                                        text-center
                                        bg-white
                                        d-flex
                                        justify-content-center
                                        align-items-center
                                    ">
                                                    <i data-feather='check-circle'></i>
                                                </div>
                                                <div className="ms-3 align-self-center">
                                                    <h3 className="mb-0 text-white fw-bold h1">23%</h3>
                                                    <span className="text-white h5">InProgress</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="card  bg-danger card-hover text-white">
                                        <div className="card-body p-2">
                                            <div className="d-flex flex-row align-items-center">
                                                <div className="
                                        round
                                        rounded-circle
                                        text-danger
                                        d-inline-block
                                        text-center
                                        bg-white
                                        d-flex
                                        justify-content-center
                                        align-items-center
                                    ">
                                                    <i data-feather='minus-circle'></i>
                                                </div>
                                                <div className="ms-3 align-self-center">
                                                    <h3 className="mb-0 text-white fw-bold h1">72%</h3>
                                                    <span className="text-white h5">NotComplied</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div class="card border-danger">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">Compliance Status</h3>
                                            </div>
                                            <div className="card-body">
                                                <div id="compliancestatusdiv" style={{ height: '480px' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="card border-danger">  {/* c3-area.js */}
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    Comparitive Trend
                                                </h3>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className="card-body">
                                                        <div id="Comprativechartdiv" style={{ height: '480px' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-7'>
                                    <div class="card border-danger">
                                        <div className="card">
                                            <div className='card-header'>
                                                <h3 className="card-title">
                                                    Over Due Compliance
                                                </h3>
                                            </div>
                                            <div className="card-body">
                                                <div id="chartdiv" style={{ height: '400px' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div class="card border-danger">
                                        <div className='card'>
                                            <div className='card-header'>
                                                <h5 className='card-title'>
                                                    Criticality Chart
                                                </h5>
                                            </div>
                                            <div className='card-body'>
                                                <div id="criticality" style={{ height: '400px' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="card-group">
                                        <div class="card border-danger">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">
                                                        Task Distribution
                                                    </h3>
                                                </div>
                                                <div className='card-body'>
                                                    <div id="taskchartdiv" style={{ height: '400px' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="card border-danger">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    Compliance Trend
                                                </h3>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className="card-body">
                                                        <div id="Compliancechartdiv" style={{ height: '400px' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div class="card border-danger">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    <span>Risk Chart- Hidden Risk</span>
                                                    <span style={{ "margin-left": "380px" }}>Risk Chart- Exposed Risk</span>
                                                </h3>
                                            </div>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div id='Riskchartdiv' style={{ height: '400px' }}></div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div id='ExposedRiskchartdiv' style={{ height: '400px' }}></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default connect(mapStateToProps, {
})(LandingPage);