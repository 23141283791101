import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from './header';
import Sidenav from './sidenav';
import RoutesLink from '../Components/Routes';

const mapStateToProps = (state) => ({

});
const Full = () => {

    return (
        <Fragment>
            <div id="main-wrapper">
                <Header></Header>
                <Sidenav></Sidenav>
                <RoutesLink></RoutesLink>
                <footer className="footer d-flex justify-content-center">
                    2022© Aparajitha Software Services Private Limited
                    <span className="ms-2" style={{ color: "black" }}>Privacy · Terms of Use </span>
                </footer>
            </div>
        </Fragment>
    );
};

export default connect(mapStateToProps, {})(Full);

