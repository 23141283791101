import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { domainCardData, GetDomainCardData } from '../../../Store/Action/Report/DomainCard';
import { connect } from 'react-redux';
import { filter } from 'lodash';

const mapStateToProps = (state) => ({
    domain_data: state.DomainCard.domainCardReport.domain_data,
    tableData: state.DomainCard.domainCardReport.getTableData
})

const DomainCard = ({
    domainCardData,
    GetDomainCardData,
    domain_data,
    tableData

}) => {

    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    let [index, setIndex] = useState(0)
    const [type, setType] = useState('')
    const [total, setTotal] = useState(0)
    const [filteredArray, setFilteredArray] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [completedModal, setCompletedModal] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [domainCard, setDomainCard] = useState({
        country: '',
        countryName: '',
        legel_entity: '',
        le_id: '',
        category: '',
        categoryName: '',
        businessGroup: '',
        businessGroupName: '',
        division: '',
        divisionName: '',
        domain: '',
        domainName: ''
    })
    const [divisonList, setDivisionList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [domainList, setDomainList] = useState([])
    // const [totalCount,setInProgressCount] = useState()
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    const validator = useRef(new SimpleReactValidator());
    const { Column, ColumnGroup } = Table;
    const { Panel } = Collapse;
    const { Option } = Select;
    const [newDataArray, setNewDataArray] = useState([])
    // let inProgressCount;
    // let compliedCount;
    // let delayedCount;
    // let notCompliedCount;
    // let unAssignedCount;
    // let notOptedCount;
    // let notApplicableCount;
    // let totalCount;


    const onChange = (key) => {
        console.log(key);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onshow = () => {
        console.log("hii123");
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            // const payload = [
            //     authtoken,
            //     {
            //         "session_token": authtoken,
            //         "request": [
            //             "GetLEWiseScoreCard",
            //             {
            //                 "c_id": Number(legalEntityScoreCard.country),
            //                 "le_id": entityid,
            //                 "d_id": Number(legalEntityScoreCard.domain),
            //                 "csv": false
            //             }
            //         ]
            //     }
            // ]
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetDomainScoreCard",
                        {
                            "c_id": Number(domainCard.country),
                            "bg_id": Number(domainCard.businessGroup) ? Number(domainCard.businessGroup) : null,
                            "le_id": entityid,
                            "d_id": Number(domainCard.domain) ? Number(domainCard.domain) : null,
                            "div_id": Number(domainCard.division) ? Number(domainCard.division) : null,
                            "cat_id": Number(domainCard.category) ? Number(domainCard.category) : null,
                            "csv": false
                        }
                    ]
                }
            ]
            console.log(payload, 'payyyyy');
            GetDomainCardData({
                payload: payload,
                paramid: paramid
            })
        }
    }


    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetDomainScoreCardFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                domainCardData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(domainCard.le_id)
        }
    }, [entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setDomainCard({
                ...domainCard,
                country: Number(sessionArr[0].c_id),
                le_id: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (domain_data) {
            setCategoryList(domain_data && domain_data.cat_infos)
            setDivisionList(domain_data && domain_data.div_infos)
            setDomainList(domain_data && domain_data.domains)
        }

    }, [domain_data])

    useEffect(() => {
        let temp = []
        if (tableData && tableData.domain_score_card_list && tableData.domain_score_card_list.length > 0) {
            tableData.domain_score_card_list.map((item, i) => {
                temp.push(item.units_wise_count)
            })
        }
        setFilteredArray(temp)

    }, [tableData])

    console.log(filteredArray, 'filteredArray');

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            setDataSource(filteredArray && filteredArray[0])
            if (type === 'domainOnClick') {
                let temp = []
                let temp1 = []
                let temp2 = []
                let temp3 = []
                let temp4 = []
                let temp5 = []
                let temp6 = []
                let temp7 = []
                for (let i in filteredArray[0]) {
                    console.log(filteredArray[0], 'filteredArray[0]');
                    console.log(filteredArray[i], 'filteredArray[i]');
                    temp.push(Number(filteredArray[0][i].inprogress_count))
                    temp1.push(Number(filteredArray[0][i].complied_count))
                    temp2.push(Number(filteredArray[0][i].delayed_count))
                    temp3.push(Number(filteredArray[0][i].overdue_count))
                    temp4.push(Number(filteredArray[0][i].unassigned_count))
                    temp5.push(Number(filteredArray[0][i].not_opted_count))
                    temp6.push(Number(filteredArray[0][i].not_applicable_count))
                }
                console.log(temp, 'temptemp');
                let inProgressCountValue = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let compliedCountValue = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let delayedCountValue = temp2.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let notCompliedCountValue = temp3.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let unAssignedCountValue = temp4.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let notOptedCountValue = temp5.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let notApplicableCountValue = temp6.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                // temp7.push({
                //     unit: <b>Total</b>,
                //     inprogress_count: <b>{inProgressCountValue}</b> ,
                //     complied_count:  <b>{compliedCountValue}</b> ,
                //     delayed_count:   <b>{delayedCountValue}</b> ,
                //     overdue_count:  <b>{notCompliedCountValue}</b> ,
                //     unassigned_count:   <b>{unAssignedCountValue}</b> ,
                //     not_opted_count:   <b>{notOptedCountValue}</b> ,
                //     not_applicable_count:   <b>{notApplicableCountValue}</b> ,
                //     total:<b>{Number(inProgressCountValue)+Number(compliedCountValue)+Number(delayedCountValue)+Number(notCompliedCountValue)+Number(unAssignedCountValue)+Number(notOptedCountValue)+Number(notApplicableCountValue)}</b>
                // })
                temp7.push({
                    unit: 'Total',
                    inprogress_count: inProgressCountValue,
                    complied_count: compliedCountValue,
                    delayed_count: delayedCountValue,
                    overdue_count: notCompliedCountValue,
                    unassigned_count: unAssignedCountValue,
                    not_opted_count: notOptedCountValue,
                    not_applicable_count: notApplicableCountValue,
                    // total:<b>{Number(inProgressCountValue)+Number(compliedCountValue)+Number(delayedCountValue)+Number(notCompliedCountValue)+Number(unAssignedCountValue)+Number(notOptedCountValue)+Number(notApplicableCountValue)}</b>
                })
                setNewDataArray(temp7)
                console.log(temp7, 'temp7');
            }
        }
    }, [filteredArray, type])

    console.log(categoryList, 'categoryList');

    useEffect(() => {
        if (newDataArray && newDataArray.length > 0) {
            if (type === 'domainOnClick') {
                // const newData = newDataArray
                setDataSource([...filteredArray[0], ...newDataArray])
            }
        }

    }, [newDataArray, type])


    console.log(filteredArray, 'filteredArray');
    console.log(dataSource, 'dataSource');

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ color: '#1890ff' }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ color: '#1890ff' }}>Score Cards</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ color: '#1890ff' }}>Domain Score Card</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <Modal title='Domain Card Table' className='class' visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} width={750}>
                    {tableData && tableData.domain_score_card_list && tableData.domain_score_card_list.length > 0 && tableData.domain_score_card_list.map((item, i) => {
                        return (
                            <Table
                                dataSource={dataSource}
                            >
                                <Column title="Vendor" dataIndex="unit" key="unit" />
                                <ColumnGroup title="Assigned">
                                    <Column title="Inprogress" dataIndex="inprogress_count" key="inprogress_count" />
                                    <Column title="Complied" dataIndex="complied_count" key="complied_count" />
                                    <Column title="Delayed Complied" dataIndex="delayed_count" key="delayed_count" />
                                    <Column title="Not Complied" dataIndex="overdue_count" key="overdue_count" />
                                </ColumnGroup>
                                <Column title="Un Assigned" dataIndex="unassigned_count" key="unassigned_count" />
                                <Column title="Not Opted" dataIndex="not_opted_count" key="not_opted_count" />
                                <Column title="Not Applicable" dataIndex="not_applicable_count" key="not_applicable_count" />
                                <Column title="Total" dataIndex={false} key={false}
                                    render={(row, record) => Number(record.inprogress_count) + Number(record.complied_count) + Number(record.delayed_count) + Number(record.overdue_count) + Number(record.unassigned_count) + Number(record.not_opted_count) + Number(record.not_applicable_count)}
                                />
                            </Table>
                        )
                    })}

                </Modal>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" style={{ padding: "15px 5px" }}>
                                <Collapse defaultActiveKey={["1"]} style={{ backgroundColor: "#73C4E9" }} onChange={onChange} >
                                    <Panel header="Domain Score Card" key={key}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={false}
                                                                    size="default"
                                                                    placeholder="Enter Country"
                                                                    onChange={(value, data) => {
                                                                        console.log(data, 'daaataa');
                                                                        setDomainCard({
                                                                            ...domainCard,
                                                                            country: value,
                                                                            countryName: data.children,
                                                                            legel_entity: '',
                                                                            le_id: '',
                                                                            category: '',
                                                                            categoryName: '',
                                                                            businessGroup: '',
                                                                            businessGroupName: '',
                                                                            division: '',
                                                                            divisionName: '',
                                                                            domain: '',
                                                                            domainName: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //value={Settings.le_id}
                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                    value={domainCard.countryName || undefined}
                                                                >
                                                                    {country_info && country_info.length && country_info.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'countryName',
                                                                    domainCard.countryName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{sessionArr[0].c_name}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity : </b>  <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onChange={(data, value) => {
                                                                    setCurrentEntity(data)
                                                                    setDomainCard({
                                                                        ...domainCard,
                                                                        le_id: data,
                                                                        legel_entity: value.children,
                                                                        category: '',
                                                                        categoryName: '',
                                                                        businessGroup: '',
                                                                        businessGroupName: '',
                                                                        division: '',
                                                                        divisionName: '',
                                                                        domain: '',
                                                                        domainName: ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                value={domainCard.legel_entity || undefined}
                                                                disabled={domainCard.country == '' ? true : false}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                domainCard.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division : </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                         allowClear={false}
                                                            onChange={(value, data) => {
                                                                setDomainCard({
                                                                    ...domainCard,
                                                                    division: value,
                                                                    divisionName: data.children
                                                                })
                                                                let filteredCategory = _.filter(domain_data.cat_infos, { div_id: Number(value) })
                                                                console.log(filteredCategory, 'filteredCategory');
                                                                setCategoryList(filteredCategory)
                                                            }}
                                                            placeholder="Enter Division"
                                                            style={{ width: '100%', marginTop: '5px' }}

                                                            value={domainCard.divisionName || undefined}
                                                            disabled={domainCard.le_id == '' ? true : false}


                                                        >
                                                            {divisonList && divisonList.length && divisonList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                    {/* {validator.current.message(
                                                        'divisionName',
                                                        domainCard.divisionName,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Business Group : </b></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={false}
                                                                    size="default"
                                                                    placeholder="Enter Business Group"
                                                                    onChange={(value, data) => {
                                                                        console.log(data, 'daaataa');
                                                                        setDomainCard({
                                                                            ...domainCard,
                                                                            businessGroup: value,
                                                                            businessGroupName: data.children
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={domainCard.businessGroupName || undefined}
                                                                    style={{ width: '100%', marginTop: '5px' }}

                                                                    disabled={domainCard.le_id == '' ? true : false}

                                                                // value={domainCard.countryName}
                                                                >
                                                                    {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                        if (item.bg_id != null) {
                                                                            return (
                                                                                <Option key={item.bg_id}>
                                                                                    {item.bg_name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </Select>
                                                                {/* {validator.current.message(
                                                                    'businessGroupName',
                                                                    domainCard.businessGroupName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Business Group Required',
                                                                        }
                                                                    })} */}
                                                            </div> : ''
                                                            // <p>{sessionArr[0].c_name}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Category : </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Category"
                                                            onChange={(data, value) => {
                                                                setDomainCard({
                                                                    ...domainCard,
                                                                    category: data,
                                                                    categoryName: value.children
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            businessGroupName
                                                            value={domainCard.categoryName || undefined}
                                                            disabled={domainCard.le_id == '' ? true : false}
                                                            style={{ width: '100%', marginTop: '5px' }}

                                                        >
                                                            {categoryList && categoryList.length && categoryList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.cat_id}>
                                                                        {item.cat_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                         allowClear={false}
                                                            onChange={(value, data) => {
                                                                setDomainCard({
                                                                    ...domainCard,
                                                                    domain: value,
                                                                    domainName: data.children
                                                                })
                                                            }}
                                                            placeholder="Enter Domain"
                                                            businessGroupName
                                                            value={domainCard.domainName || undefined}
                                                            disabled={domainCard.le_id == '' ? true : false}
                                                            style={{ width: '100%', marginTop: '5px' }}

                                                        >
                                                            {domainList && domainList.length && domainList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                    {/* {validator.current.message(
                                                        'domain',
                                                        domainCard.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                {/* <div className='col-md-4'></div>
                        <div className='col-md-4'></div> */}
                                                <div className='col-md-4' style={{ marginLeft: '30%' }}>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px", width: "100px" }}
                                                        onClick={onshow}
                                                    >
                                                        Show
                                                    </Button>
                                                    {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852", width: "100px" }}
                                                    // onClick={showModal}
                                                    >
                                                        Export
                                                    </Button> */}
                                                </div>
                                            </div>
                                        </div>


                                    </Panel>
                                </Collapse>

                                {tableshow == true ?
                                    <>
                                        <div className='col-md-12 mt-3 mb-3 ml-2' >
                                            <div className='row'>
                                                <div className='col-md-6 ml-2'>
                                                    <label><b>Legal Entity :</b>{localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? domainCard.legel_entity : localStorage.getItem('SelectedEntity')}</label>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label><b>Country :</b>{sessionArr && sessionArr[0].c_name}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content fh userprivclass1'>
                                                        <table className='table-fixed'>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className='ant-table-thead'>
                                                                <tr>
                                                                    <th className='ant-table-cell w10px'>#</th>
                                                                    <th className='ant-table-cell custom-width1'>Domain</th>
                                                                    <th className='ant-table-cell custom-width1'>Assigned</th>
                                                                    <th className='ant-table-cell custom-width1'><center>Un Assigned</center></th>
                                                                    <th className='ant-table-cell custom-width1'>Not Opted</th>
                                                                    <th className='ant-table-cell custom-width1'>Not Applicable</th>
                                                                    <th className='ant-table-cell custom-width1'>Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                {tableData && tableData.domain_score_card_list && tableData.domain_score_card_list.length > 0 && tableData.domain_score_card_list.map((item, i) => {
                                                                    return (
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>1</center></td>
                                                                            <td className='ant-table-cell'><center><a href="javascript:;" onClick={() => {
                                                                                showModal()
                                                                                setType('domainOnClick')
                                                                                setTotal(Number(item.assigned_count) + Number(item.unassigned_count) + Number(item.not_opted_count) + Number(item.not_applicable_count))
                                                                            }}>{item.domain_name}</a></center></td>
                                                                            <td className='ant-table-cell'><center>{item.assigned_count}</center></td>
                                                                            <td className='ant-table-cell'><center>{item.unassigned_count}</center></td>
                                                                            <td className='ant-table-cell'><center>{item.not_opted_count}</center></td>
                                                                            <td className='ant-table-cell'><center>{item.not_applicable_count}</center></td>
                                                                            <td className='ant-table-cell'><center>{Number(item.assigned_count) + Number(item.unassigned_count) + Number(item.not_opted_count) + Number(item.not_applicable_count)}</center></td>

                                                                            {/* <td className='ant-table-cell'><center><Button type="primary"
                                                                            onClick={() => {
                                                                                setType('Unit_inProgress')
                                                                                showModal()
                                                                            }}
                                                                        >View</Button></center></td>
                                                                        <td className='ant-table-cell'><center><Button type="primary"
                                                                            onClick={() => {
                                                                                setType('User_inProgress')
                                                                                showModal()
                                                                            }}>View</Button></center></td> */}
                                                                        </tr>
                                                                    )
                                                                })}
                                                                {/* <tr>
                                                                <td className='ant-table-cell'><center>1</center></td>
                                                                <td className='ant-table-cell'><center>In Progress</center></td>
                                                                <td className='ant-table-cell'><center>{tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].inprogress_count}</center></td>
                                                                <td className='ant-table-cell'><center><Button type="primary"
                                                                    onClick={() => {
                                                                        setType('Unit_inProgress')
                                                                        showModal()
                                                                    }}
                                                                >View</Button></center></td>
                                                                <td className='ant-table-cell'><center><Button type="primary"
                                                                    onClick={() => {
                                                                        setType('User_inProgress')
                                                                        showModal()
                                                                    }}>View</Button></center></td>
                                                            </tr> */}
                                                            </tbody>

                                                        </table>
                                                        <br />
                                                        <div>
                                                            <div
                                                                // className="btn btn-light-success rounded-pill px-4 text-success"
                                                                className='col-md-12'
                                                                style={{ marginLeft: '90%', marginBottom: '5%' }}
                                                            // onClick={submitrec}
                                                            >
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : false}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(mapStateToProps, {
    domainCardData,
    GetDomainCardData
})(DomainCard);