import {CRITICALITYSETTINGS,CRITICALITYLIST} from '../../types/index';

const initialState = {
    criticalitysettings: {
        filterdata: '',
        list:''
    }
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CRITICALITYSETTINGS: {
            return {
                ...state,
                criticalitysettings: {
                    ...state.criticalitysettings,
                    filterdata: payload[1],
                },
            };
        }
        case CRITICALITYLIST: {
            return {
                ...state,
                criticalitysettings: {
                    ...state.criticalitysettings,
                    list: payload[1],
                },
            };
        }

        default:
            return state;
    }
}