import { Collapse, Select, Card, Drawer, Button, DatePicker, Tooltip, Modal, Table, Pagination } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { MonthReportExport } from "../../../src/Store/Action/Report/CV_MonthWiseStatusReport"
import { CertificateComplianceWiseReportFilters, ComplianceCertificateWiseReport, CertificatePdf, ComplianceCertificateExport, DownloadFile } from "../../../src/Store/Action/Report/CV_ComplianceCertificate"
import { connect, useDispatch } from 'react-redux';
import { EyeOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { PlusCircleOutlined, FileTextOutlined, PlayCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { PDF_RESET, FILE_DOWNLOAD, CV_COMPLIANCE_CERTIFICATE_EXPORT_RESET } from './../../../src/Store/types/index'

const mapStateToProps = (state) => ({
    reportlist: state.CV_MonthWiseStatusReport.monthwisestatusreport,
    compliancecertificate: state.CV_ComplianceCertificateReport.certificatecompliancereport
})

const CV_ComplianceCertificateReport = ({
    CertificateComplianceWiseReportFilters,
    reportlist,
    CertificatePdf,
    DownloadFile,
    ComplianceCertificateWiseReport,
    MonthReportExport,
    ComplianceCertificateExport,
    compliancecertificate: { certificatecompliancereportfilter, filedownload, certificatecompliancereportfiltershow, pdf, certificatecomplianceexport }
}) => {
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [domain, setDomain] = useState([])
    const [unit, setUnit] = useState([])
    const [check, setCheck] = useState([])
    const [currentYear, setCurrentYear] = useState(moment().format('YYYY'))
    const [categorylist, setCategoryList] = useState([])
    const [division, setDivision] = useState([])
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const [open, setOpen] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [invoicemodal, setInvoiceModal] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Documentmodal, setDocumentModal] = useState(false)
    const [invoicedata, setInvoiceData] = useState([])
    const [documentview, setDocumentView] = useState([])
    var [page, setPage] = useState(0);
    const [key, setKey] = useState("1")
    const [countrydata, setCountryData] = useState(null)
    const dispatch = useDispatch();
    const [titledata, Settitledata] = useState(undefined)
    const [childState, setChildState] = useState([])
    const [childValue, setChildValue] = useState([])
    let [index, setindex] = useState(0)
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [getreportlist, setGetReportList] = useState([])
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 7, 8, 9, 10, 31, 34])
    const [tabledata, setTableData] = useState([])
    const [totaldata, setTotalData] = useState({
        domainlist: [],
        divisionlist: [],
        categorylist: [],
        unitlist: []
    })
    const [compliancecertificate, setComplianceCertificate] = useState({
        country: "",
        legel_entity: "",
        domain: "",
        division: "",
        category: "",
        unit: "",
        period: "",
        domain_id: null,
        division_id: null,
        category_id: null,
        unit_id: null,
        from_month: '',
        to_month: ''
    })
    const [monthlyArray, setMonthlyArray] = useState([])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (certificatecompliancereportfiltershow) {
            setTableData(certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.certification_compliances)
        }
    }, [certificatecompliancereportfiltershow])

    useEffect(() => {
        if (certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.certification_compliances) {
            var array = certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.certification_compliances.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }

    }, [certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.certification_compliances, pageSize, current])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (compliancecertificate.division_id) {
            const divid = totaldata.divisionlist.filter((item) => {
                return item.div_id === compliancecertificate.division_id
            })
        }
    }, [compliancecertificate])

    useEffect(() => {
        if (isAuth && entityid != "null" && countrydata != null) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCertificateComplianceWiseReportFilters",
                        {
                            "country_id": countrydata && countrydata[0].c_id,
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            CertificateComplianceWiseReportFilters({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth, authtoken, paramid, entityid, countrydata])

    useEffect(() => {
        if (reportlist.compliancewisereport) {
            setGetReportList(reportlist && reportlist.compliancewisereport)
            let Domaindata = _.filter(reportlist && reportlist.compliancewisereport.domains, { le_id: entityid })
            setTotalData({
                ...totaldata,
                domainlist: Domaindata
            })
        }
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {
            setComplianceCertificate({
                ...compliancecertificate,
                country: countrydata && countrydata[0].c_id,
                legel_entity: localStorage.getItem('SelectedEntity')
            })
        }
        if (compliancecertificate.period === "") {
            setComplianceCertificate({
                ...compliancecertificate,
                period: "All",
            })
        }
    }, [reportlist && reportlist.compliancewisereport, entityid, countrydata])

    useEffect(() => {
        if (certificatecompliancereportfilter) {
            setCategoryList(certificatecompliancereportfilter && certificatecompliancereportfilter.categories_list)
            setDivision(certificatecompliancereportfilter && certificatecompliancereportfilter.divisions_list)
            setDomain(certificatecompliancereportfilter && certificatecompliancereportfilter.domains)
            setUnit(certificatecompliancereportfilter && certificatecompliancereportfilter.unit_legal_entity)
        }
    }, [certificatecompliancereportfilter])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tabledata) {
            const ab = paginationArray && paginationArray.map(function (item) {
                if ((array.indexOf(item.period) < 0))
                    array.push(item.period)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.map((child, childIndex) => {
                        if (data === child.period) {
                            entityArray = {
                                "ac_id": child.ac_id,
                                "actity_date": child.actity_date,
                                "activity_status": child.activity_status,
                                "approval_document_names": child.approval_document_names,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "completion_date": child.completion_date,
                                "compliance_activity_id": child.compliance_activity_id,
                                "compliance_description": child.compliance_description,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_id": child.compliance_id,
                                "compliance_task": child.compliance_task,
                                "concur_document_name": child.concur_document_name,
                                "country_id": child.country_id,
                                "criticality_name": child.criticality_name,
                                "current_status": child.current_status,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "document_name": child.document_name,
                                "domain_id": child.domain_id,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "history_count": child.history_count,
                                "interim_count": child.interim_count,
                                "legal_entity_id": child.legal_entity_id,
                                "logo_url": child.logo_url,
                                "period": child.period,
                                "remarks_count": child.remarks_count,
                                "start_date": child.start_date,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                "url": child.url,
                                "user_name": child.user_name,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [paginationArray])

    useEffect(() => {
        if (tabledata) {
            let uniqueObjArray = [...new Map(tabledata && tabledata.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tabledata && tabledata, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [tabledata])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        let monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (moment().format('MMM') == "Jan") {
            const index = monthArray.findIndex(element => {
                if (element == moment().format('MMM')) {
                    return true;
                }
            })
            let currentYear = moment().format('YYYY') - 1
            monthArray = monthArray.filter((month, idx) => idx < index)
            let tempMonthArray = [];
            for (let i in monthArray) {
                tempMonthArray.push(monthArray[i] + '-' + currentYear)
            }
            setMonthlyArray(tempMonthArray)
        } else {
            let temp = false
            let temparray = []
            for (let i in monthArray) {
                if (temp) {
                    temparray.push(monthArray[i])
                } else {
                    if (monthArray[i] == moment(compliancecertificate.from_month).format('MMM')) {
                        temparray.push(monthArray[i])
                        temp = true
                    }
                }
            }
            let check = []
            const index = temparray.findIndex(element => {
                check.push(element)
                // if (element == moment(compliancecertificate.from_month).format('MMM')) {
                if (element == moment(compliancecertificate.to_month).format('MMM')) {
                    return true;
                }
                // }
            })
            setCheck(check)
            let currentYear = moment().format('YYYY');
            temparray = temparray.filter((month, idx) => idx <= index)
            let tempMonthArray = [];
            for (let i in temparray) {
                tempMonthArray.push(temparray[i] + '-' + currentYear)
            }
            setMonthlyArray(tempMonthArray)
            // let check = tempMonthArray.split('-')
        }

    }, [compliancecertificate.to_month])

    useEffect(() => {
        if (pdf && pdf.length > 0) {
            window.open('https://13.234.100.64/' + pdf);
            dispatch({
                type: PDF_RESET
            });
        }
    }, [pdf])

    useEffect(() => {
        if (certificatecomplianceexport && certificatecomplianceexport.length > 0) {
            window.open('https://13.234.100.64/' + certificatecomplianceexport);
            dispatch({
                type: CV_COMPLIANCE_CERTIFICATE_EXPORT_RESET
            });
        }
    }, [certificatecomplianceexport])

    useEffect(() => {
        if (filedownload && filedownload.length > 0) {
            window.open('https://13.234.100.64/' + filedownload);
            dispatch({
                type: FILE_DOWNLOAD
            });
        }
    }, [filedownload])

    const columns = [
        {
            title: 'Invoice Number',
            dataIndex: 'false',
            key: 'false',
            width: "100px",
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.inv_no ? record.inv_no : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Certificate',
            dataIndex: 'certificate',
            key: 'certificate',
            width: "80px",
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <a href="javascript:;" onClick={() => { certificatedownload(record) }} >{record && record.certificate}</a>
                    </>
                )
            }
            // render: (record) => record && record.months === 'January' ? record &&record.task_status : "-"
        },
        {
            title: 'Uploaded document',
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: "100px",
            ellipsis: true,
            render: (text, record) => {
                return (<>{record.certificate_doc_name == null ? '' : <FileTextOutlined onClick={() => { uploadeddocument(record) }} />}</>)
            }
        }
    ]

    const column = [
        {
            title: '#',
            dataIndex: 'false',
            key: 'false',
            width: "30px",
            align: "center",
            ellipsis: true,
            render: (text, record, index) => {
                // return (
                //     <>
                //         <p>{record && record.inv_no ? record.inv_no : "-"}</p>
                //     </>
                // )
                // render: (text, record, index) => {
                return (
                    <>
                        <span>{page + 1}</span>
                        <span hidden>{page = page + 1}</span>
                    </>
                )
            }
        },
        {
            title: 'User',
            dataIndex: 'false',
            key: 'false',
            width: "80px",
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <span>{record && record.doc_upload_role}</span>
                    </>
                )
            }
            // render: (record) => record && record.months === 'January' ? record &&record.task_status : "-"
        },
        {
            title: 'Attachment',
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: "100px",
            ellipsis: true,
            render: (text, record) => {
                return (<>{record.certificate_doc_name == null ? '' : <a href="javascript:;" onClick={() => { downloadattachment(record) }} >{record.certificate_doc_name}</a>}</>)
            }
        }
    ]

    const onChange = (key) => {
        // console.log(key);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setAddFormSubmit(true)
        setTableShow(true)
        setKey(0)
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceCertificateWiseReport",
                    {
                        "country_id": countrydata && countrydata[0].c_id,
                        "legal_entity_id": entityid,
                        "domain_id": Number(compliancecertificate.domain_id),
                        "division_id": compliancecertificate.division_id ? Number(compliancecertificate.division_id) : 0,
                        "category_id": compliancecertificate.category_id ? Number(compliancecertificate.category_id) : 0,
                        "unit_id": compliancecertificate.unit_id ? Number(compliancecertificate.unit_id) : 0,
                        "from_date": moment(compliancecertificate.from_month).format("MMMM-YYYY"),
                        "to_date": moment(compliancecertificate.to_month).format("MMMM-YYYY"),
                        "csv": true,
                        "from_count": 0,
                        "page_count": 0,
                        "list_check": checkboxValue
                    }
                ]
            }
        ]
        ComplianceCertificateExport({
            payload: payload1,
            paramid: paramid
        })

    };

    const certificatedownload = (record) => {
        const myArray = record.certificate.split("-");
        let temp = myArray[1] + " - " + myArray[2]

        CertificatePdf({
            payload: [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCertificatePdf",
                        {
                            "country_id": countrydata && countrydata[0].c_id,
                            "legal_entity_id": entityid,
                            "domain_id": Number(compliancecertificate.domain_id),
                            "cert_id": record.cert_id,
                            "unit_id": record.vendor_id,
                            "random_number": temp
                        }
                    ]
                }
            ],
            paramid: paramid
        })
    }

    const downloadattachment = (record) => {
        DownloadFile({
            payload: [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFileCert",
                        {
                            "le_id": entityid,
                            "upload_type": 1,
                            "u_id": record.vendor_id,
                            "file_value": record.certificate_doc_name,
                            "c_id": countrydata && countrydata[0].c_id
                        }
                    ]
                }
            ],
            paramid: paramid
        })
    }

    const uploadeddocument = (record) => {
        let entityArray = [];
        let temp = []
        setDocumentModal(true)
        setInvoiceModal(false)
        const myArray = record.doc_upload_role.split(",");
        const myArray1 = record.certificate_doc_name.split("|");
        for (let i in myArray) {
            entityArray = {
                "cert_id": record.cert_id,
                "certificate_doc_name": myArray1[i],
                "date": record.date,
                "doc_upload_role": myArray[i],
                "inv_no": record.inv_no,
                "vendor_id": record.vendor_id,
            }
            temp.push(entityArray)
        }
        setDocumentView(temp)
    }

    const invoice = (period) => {
        let temp = []
        let entityArray = [];
        let count = 1
        {
            certificatecompliancereportfiltershow.certification_details.map((item) => {
                if (item.date == period) {
                    const myArray = item.date.split("-");
                    entityArray = {
                        "cert_id": item.cert_id,
                        "certificate_doc_name": item.certificate_doc_name,
                        "date": item.date,
                        "doc_upload_role": item.doc_upload_role,
                        "inv_no": item.inv_no,
                        "vendor_id": item.vendor_id,
                        "certificate": "Certificate-" + myArray[0] + '-' + count
                    }
                    temp.push(entityArray)
                    count = count + 1
                    // temp.push(item)
                }
            })
        }
        setInvoiceData(temp)
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 7, 8, 9, 10, 31, 34])
        }
    }

    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceCertificateWiseReport",
                        {
                            "country_id": countrydata && countrydata[0].c_id,
                            "legal_entity_id": entityid,
                            "domain_id": Number(compliancecertificate.domain_id),
                            "division_id": compliancecertificate.division_id ? Number(compliancecertificate.division_id) : 0,
                            "category_id": compliancecertificate.category_id ? Number(compliancecertificate.category_id) : 0,
                            "unit_id": compliancecertificate.unit_id ? Number(compliancecertificate.unit_id) : 0,
                            // "months": compliancecertificate.period,
                            "from_date": moment(compliancecertificate.from_month).format("MMMM-YYYY"),
                            "to_date": moment(compliancecertificate.to_month).format("MMMM-YYYY"),
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0
                        }
                    ]
                }
            ]
            ComplianceCertificateWiseReport({
                payload: payload1,
                paramid: paramid
            })
        }
    }

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
            setTableShow(true)
        }
    };

    const onexport = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(false)
            setKey(0)
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceWiseReport",
                        {
                            "country_id": countrydata && countrydata[0].c_id,
                            "legal_entity_id": entityid,
                            "domain_id": Number(compliancecertificate.domain_id),
                            "division_id": compliancecertificate.division_id ? Number(compliancecertificate.division_id) : 0,
                            "category_id": compliancecertificate.category_id ? Number(compliancecertificate.category_id) : 0,
                            "unit_id": compliancecertificate.unit_id ? Number(compliancecertificate.unit_id) : 0,
                            // "months": compliancecertificate.period,
                            "from_date": moment(compliancecertificate.from_month).format("MMMM-YYYY"),
                            "to_date": moment(compliancecertificate.to_month).format("MMMM-YYYY"),
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0
                        }
                    ]
                }
            ]
            MonthReportExport({
                payload: payload2,
                paramid: paramid
            })
        }
    }

    const handleCancel = () => {
        setInvoiceModal(false);
        setIsModalOpen(false);
    };

    const handledocumentCancel = () => {
        setDocumentModal(false)
    }

    const genextra = () => {
        return <div onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}>
            {/* <Button type='primary' onClick={() => {
                setOpen(true)
            }}> */}
            {/* <EyeOutlined size='default' onClick={() => {
                setOpen(true)
            }} /> */}
            {/* </Button> */}
        </div>
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ color: '#1890ff' }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ color: '#1890ff' }}>Compliance Certificate</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <Modal title="Select Fields" visible={isModalOpen} footer={null} onCancel={handleCancel} width={100}>
                    <div className='row'>
                        <div className='col-lg-3'><input id="select_all" width="100%" type="checkbox" autocomplete="off"
                            checked={checkboxValue.length === 34 ? true : false}
                            onClick={(e) => {
                                let checked = e.target.checked
                                selectAllCheckBox(checked)
                            }} /> Select All</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code</div>
                        <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" value='2' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Vendor Name</div>
                        <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='3' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 1</div>
                        <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 2 </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 3 </div>
                        <div className='col-lg-3'><input id="column_6" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} />Vendor Code 4</div>
                        <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='7' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Location</div>
                        <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='8' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Act / Rules</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" value='9' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Compliance Task </div>
                        <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" value='10' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Frequency</div>
                        <div className='col-lg-3'><input id="column_11" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /> Periodicity</div>
                        <div className='col-lg-3'><input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Criticality</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3'><input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Division</div>
                        <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Category</div>
                        <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Assigned By</div>
                        <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assigned To</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date</div>
                        <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assignee </div>
                        <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Completed On </div>
                        <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Concurrer </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurrer On </div>
                        <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Approver</div>
                        <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approved On</div>
                        <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Activity Status</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3'><input id="column_25" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} />Start Date</div>
                        <div className='col-lg-3'><input id="column_26" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Due Date </div>
                        <div className='col-lg-3'><input id="column_27" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /> Extend Due Date</div>
                        <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date / Document Issued Date</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /> Month</div>
                        <div className='col-lg-3'><input id="column_30" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> Validity Date</div>
                        <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='31' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Statutory Status</div>
                        <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='32' onChange={(e) => checkBoxOnChange(e)} /> Duration</div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-lg-3'><input id="column_33" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number</div>
                        <div className='col-lg-3'><input id="column_34" name='chk' type="checkbox" value='34' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Remarks</div>
                    </div>
                    <div className="form-actions">
                        <Button type="primary" shape="round" className='addbutton'
                            style={{ background: "#198754", borderColor: "#198754", marginLeft: '40%' }}
                            icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                        </Button>
                    </div>

                </Modal>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" style={{ padding: "15px 5px" }}>
                                {/* <div className="card-body" style={{ padding: 0 }}> */}
                                <Collapse defaultActiveKey={["1"]} onChange={onChange}  >
                                    <Panel header="Compliance Certificate" key={key} extra={tableshow == true ? genextra() : false}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <Select
                                                            placeholder="Enter Country"
                                                            onChange={(value) => {
                                                                setComplianceCertificate({
                                                                    ...compliancecertificate,
                                                                    country: value
                                                                })
                                                            }}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={compliancecertificate.country || undefined}
                                                        >
                                                            {countrydata && countrydata.length && countrydata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        : <p>{localStorage.getItem('SelectedEntity')}</p>}
                                                    {validator.current.message(
                                                        'country',
                                                        compliancecertificate.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Required',
                                                            }
                                                        })}

                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division : </b></label>
                                                    <Select
                                                        disabled={compliancecertificate.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            let category = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.categories_list, { div_id: Number(value) })
                                                            setCategoryList(category)
                                                            let unit = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.unit_legal_entity, { division_id: Number(value) })
                                                            setUnit(unit)
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                division_id: value,
                                                                division: data.children,
                                                                category_id: null,
                                                                category: '',
                                                                unit: '',
                                                                unit_id: null
                                                            })
                                                        }}
                                                        placeholder="Enter Division Name"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={compliancecertificate.division || undefined}
                                                    >
                                                        {division && division.length > 0 && division.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>From Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker style={{ width: '100%', marginTop: '5px' }}
                                                        disabled={compliancecertificate.domain == "" ? true : false}
                                                        allowClear={false} picker="month"
                                                        // disabledDate={(current) => {
                                                        //     return moment(unitdata.from_date) >= current || current > Date.now();
                                                        // }}
                                                        // disabledDate={(current) => {
                                                        //     console.log(moment(current).format('YYYY'),'currentcurrent');
                                                        //     return (current && current < moment(currentYear, 'YYYY')) || (current && current > moment(currentYear, 'YYYY'))
                                                        //     return moment().format('YYYY') <= current 
                                                        //     ||
                                                        //     currentYear.add(1, 'year') <= current;
                                                        // }}
                                                        value={compliancecertificate.from_month ? moment(compliancecertificate.from_month) : ''}
                                                        onChange={(date, dateString) => {
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                from_month: dateString,
                                                                to_month: moment(dateString).add(2, 'months')
                                                            })
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'from_month',
                                                        compliancecertificate.from_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'From Month Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity  : </b><span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <Select
                                                            placeholder='Enter Legal Entity'
                                                            onChange={(value, data) => {
                                                                setCurrentEntity(value)
                                                                setComplianceCertificate({
                                                                    ...compliancecertificate,
                                                                    legel_entity: data.children,
                                                                    domain: "",
                                                                    division: "",
                                                                    category: "",
                                                                    unit: '',
                                                                    domain_id: null,
                                                                    division_id: null,
                                                                    category_id: null,
                                                                    unit_id: null,
                                                                    from_month: '',
                                                                    to_month: ''
                                                                })
                                                            }}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={compliancecertificate.legel_entity || undefined}
                                                            disabled={compliancecertificate.country == "" ? true : false}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}</Select>
                                                        : <p>{localStorage.getItem('SelectedEntity')}</p>}

                                                    {validator.current.message(
                                                        'legalentity',
                                                        compliancecertificate.legel_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entiy Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Category : </b></label>
                                                    <Select
                                                        disabled={compliancecertificate.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            let unit = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.unit_legal_entity, { category_id: Number(value) })
                                                            setUnit(unit)
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                category_id: value,
                                                                category: data.children,
                                                                unit: '',
                                                                unit_id: null
                                                            })
                                                        }}
                                                        placeholder="Enter Category Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={compliancecertificate.category || undefined}
                                                    >
                                                        {categorylist && categorylist.length > 0 && categorylist.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>To Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker style={{ width: '100%', marginTop: '5px' }}
                                                        disabled={compliancecertificate.domain == "" ? true : false}
                                                        allowClear={false} picker="month"
                                                        value={compliancecertificate.to_month ? moment(compliancecertificate.to_month) : ''}
                                                        onChange={(date, dateString) => {
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                to_month: dateString
                                                            })
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'to_month',
                                                        compliancecertificate.to_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Month Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        onChange={(value, data) => {
                                                            let division = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.divisions_list, { le_id: entityid })
                                                            setDivision(division)
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                domain_id: value,
                                                                domain: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={compliancecertificate.domain || undefined}
                                                        disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && compliancecertificate.legel_entity == '') ? true : false}
                                                    >
                                                        {domain && domain.length > 0 && domain.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        compliancecertificate.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Vendor : </b></label>
                                                    <Select
                                                        disabled={compliancecertificate.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                unit_id: value,
                                                                unit: `${data.children[0]}-${data.children[2]}`
                                                            })
                                                        }}
                                                        placeholder="Enter Unit"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={compliancecertificate.unit || undefined}
                                                    >
                                                        {unit && unit.length > 0 && unit.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_code} - {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4'>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px", marginTop: '5px' }} onClick={onshow}
                                                    >
                                                        Show
                                                    </Button>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852", marginTop: '5px' }} onClick={showModal}
                                                    >
                                                        Export
                                                    </Button>
                                                    {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Drawer title="Month Wise Status Report" width={400} placement="right" visible={open} onClose={onClose}>
                                    <Card style={{ padding: 0 }}>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Country : <span style={{ fontWeight: 'bold' }}> {compliancecertificate.country}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Legal Entity : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.legel_entity}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Domain : <span style={{ fontWeight: 'bold' }}> {compliancecertificate.domain}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Division : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.division}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0' >
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Category : <span style={{ fontWeight: 'bold' }}> {compliancecertificate.category}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Unit : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.unit}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Period : <span style={{ fontWeight: 'bold' }}> {compliancecertificate.period}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Drawer>
                                {tableshow == true ?

                                    filteredArrayState.length > 0 ?
                                        // <Card>
                                        <>
                                            <>
                                                {/* <h2 style={{ textAlign: 'center' }}>Month Wise Status Report</h2>
                                                <div className='col-md-12' style={{ marginTop: "30px", marginBottom: "15px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label>Legal Entity : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.legel_entity}</span></label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Country : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.country}</span></label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Domain : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.domain}</span></label>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content fh userprivclass1'>
                                                                <table className='widthHeigh table-fixed'>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead' >
                                                                        <tr>
                                                                            <th className='ant-table-cell' style={{ width: '10px' }}> # </th>
                                                                            <th title='Compliance Task' className='ant-table-cell' style={{ width: "120px" }}>Month</th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Invoice Number</center></th>
                                                                            {/* {monthlyArray && monthlyArray.length > 0 && monthlyArray.map((item) => {
                                                                            return (
                                                                                <th className='ant-table-cell' style={{ width: "90px" }}><center>{item}</center></th>
                                                                            )
                                                                        })} */}
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Act</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Compliance Task</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Criticality</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>User Name</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Activity Status</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }}><center>Due Date</center></th>
                                                                            <th className='ant-table-cell' style={{ width: "90px" }} ><center>Compliance Status</center></th>
                                                                            {/* <th className='ant-table-cell' style={{ width: "90px" }}><center>Certificate</center></th> */}
                                                                            {/* <th className='ant-table-cell' style={{ width: "90px" }}><center>Uploaded Document</center></th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    {filteredArrayState && filteredArrayState.length && filteredArrayState.map((item) => {
                                                                        return (
                                                                            <tbody className='ant-table-tbody' style={{ padding: 0 }}>
                                                                                <tr className="bg-lightdark">
                                                                                    <td colSpan="100%" style={{ padding: 0 }}>
                                                                                        <table className='white' style={{ tableLayout: 'auto' }}>
                                                                                            <tbody className='ant-table-tbody' style={{ padding: "5px" }}>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td className='ant-table-cell w6'><b>Vendor :</b></td>
                                                                                                    <td className='ant-table-cell w15' title={item[0].unit_name}>{item[0].unit_name}</td>
                                                                                                    <td className='ant-table-cell w6'><b>Category :</b></td>
                                                                                                    <td className='ant-table-cell w40'><span className="category-name">{item[0].category_name}</span></td>
                                                                                                </tr>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td className='ant-table-cell w6'><b>Division :</b></td>
                                                                                                    <td className='ant-table-cell w15' >{item[0].division_name}</td>
                                                                                                    <td className='ant-table-cell w6'><b>Time Zone :</b></td>
                                                                                                    <td className='ant-table-cell w40'><span className="category-name">{item[0].tz_name}</span></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                {titledata && titledata.map((itemdata, i) => {
                                                                                    let data = _.find(itemdata.child, { unit_id: Number(item[0].unit_id) })
                                                                                    if (data != undefined) {
                                                                                        if (data != undefined) {
                                                                                            if (data.period == itemdata.parent) {
                                                                                                let temparray = []
                                                                                                let temp = childState
                                                                                                let temparr = {}
                                                                                                let array = childValue
                                                                                                return <>
                                                                                                    <tr>
                                                                                                        <td className='ant-table-cell'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                        // onClick={() => {
                                                                                                        //     if (itemdata.parent[0].unit_count > 0) {
                                                                                                        //         if (childState[i] == true) {
                                                                                                        //             temp[i] = false;
                                                                                                        //         } else {
                                                                                                        //             temp[i] = true;
                                                                                                        //         }
                                                                                                        //         setChildState(temp)
                                                                                                        //     }
                                                                                                        // }}
                                                                                                        ><center></center></td>
                                                                                                        <td className='ant-table-cell'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            onClick={() => {
                                                                                                                const index = childValue.indexOf(itemdata.child[0].period);
                                                                                                                if (index > -1) {
                                                                                                                    array.splice(index, 1);
                                                                                                                } else {
                                                                                                                    array.push(itemdata.child[0].period)
                                                                                                                }
                                                                                                                setChildValue([...array])
                                                                                                                if (itemdata.child.length > 0) {
                                                                                                                    if (childState[i] == true) {
                                                                                                                        temp[i] = false;
                                                                                                                    } else { temp[i] = true; }
                                                                                                                    setChildState(temp)
                                                                                                                }
                                                                                                            }}
                                                                                                        ><center><b>{itemdata.child[0].period}</b></center></td>
                                                                                                        <td className='ant-table-cell'
                                                                                                            style={{ cursor: 'pointer' }}
                                                                                                            onClick={() => {
                                                                                                                invoice(itemdata.child[0].period)
                                                                                                                setInvoiceModal(true)
                                                                                                            }}
                                                                                                        ><center><PlusCircleOutlined /></center></td>
                                                                                                        <td className='ant-table-cell'></td>
                                                                                                        <td className='ant-table-cell'></td>
                                                                                                        <td className='ant-table-cell'></td>
                                                                                                        <td className='ant-table-cell'></td>
                                                                                                        <td className='ant-table-cell'></td>
                                                                                                        <td className='ant-table-cell'></td>
                                                                                                        <td className='ant-table-cell'></td>
                                                                                                    </tr>
                                                                                                    {/* {childState === true ? */}
                                                                                                    {itemdata.child.map((items, i) => {
                                                                                                        // if (childValue == items.period) {
                                                                                                        if (childValue.indexOf(items.period) > -1 ? true : false) {
                                                                                                            return <tr>
                                                                                                                <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                                <td className='ant-table-cell'><center>{ }</center></td>
                                                                                                                <td className='ant-table-cell'><center>{ }</center></td>
                                                                                                                <td className='ant-table-cell'><center>{items.statutory_mapping}</center></td>
                                                                                                                <td className='ant-table-cell'><center>{items.compliance_task}</center></td>
                                                                                                                <td className='ant-table-cell'><center>{items.criticality_name}</center></td>
                                                                                                                <td className='ant-table-cell'>{items.user_name}</td>
                                                                                                                <td className='ant-table-cell'><center>{items.activity_status}</center></td>
                                                                                                                <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                                <td className='ant-table-cell'><center>{items.current_status}</center></td>
                                                                                                                {/* <span hidden>{index1 = index1 + 1}</span> */}
                                                                                                                <span hidden>{index = index + 1}</span>
                                                                                                            </tr>
                                                                                                        }
                                                                                                    })}
                                                                                                    {/* : ''} */}


                                                                                                </>
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                                }
                                                                            </tbody>
                                                                        )
                                                                    })}
                                                                </table>
                                                                <br />

                                                                <div>
                                                                    {/* <button
                                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                                    onClick={submitrec}>Submit</button> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                            {/* <Pagination
                                                showTotal={(total) => `Showing ${reportlist && reportlist.tabledata && reportlist.tabledata.total_count} entries`}
                                                defaultPageSize={25}
                                                defaultCurrent={1}
                                            /> */}
                                            <Modal title="Invoice View" className="remarksClass" visible={invoicemodal} onCancel={handleCancel}
                                                onOk={() => { setInvoiceModal(false) }} footer={null}>
                                                <Table
                                                    className='userprivclass'
                                                    columns={columns}
                                                    dataSource={invoicedata}
                                                    bordered
                                                    pagination={false} />
                                            </Modal>

                                            {<Modal title="Attachment" className="remarksClass"
                                                visible={Documentmodal}
                                                onCancel={handledocumentCancel} onOk={() => {
                                                    setInvoiceModal(false)
                                                }} footer={null}>
                                                <Table
                                                    className='userprivclass'
                                                    columns={column}
                                                    dataSource={documentview}
                                                    bordered
                                                    pagination={false} />
                                            </Modal>}
                                            <Pagination style={{ marginLeft: '40%' }}
                                                current={current}
                                                showSizeChanger={true}
                                                // showQuickJumper={true}
                                                onShowSizeChange={() => {
                                                    setCurrent(1)
                                                    // setpageState(true)
                                                }}
                                                pageSizeOptions={[25, 50, 100]}
                                                hideOnSinglePage={false}
                                                defaultPageSize={25}
                                                onChange={(page, pageSizes) => {
                                                    setpageState(true)
                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                    setPageSize(pageSizes)
                                                }}
                                                total={reportlist && reportlist.tabledata && reportlist.tabledata.total_count}
                                                showTotal={(total) => `Showing ${certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.certification_compliances.length} entries`}
                                            />

                                            {/* <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                <div className='row'>

                                                    <div className='col-md-3' >
                                                        <label style={{ marginLeft: "28px" }}><b>Showing  </b><a href='javascript:;' style={{ fontSize: "18px" }}>{reportlist && reportlist.tabledata && reportlist.tabledata.total_count}</a>  entries</label>
                                                    </div>
                                                    <div className='col-md-3'></div>
                                                    <div className='col-md-3'></div>
                                                    <div className='col-md-3'>
                                                    </div>

                                                </div>
                                            </div> */}
                                        </>
                                        // </Card>


                                        : <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4> : ""}
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default connect(mapStateToProps, {
    CertificateComplianceWiseReportFilters,
    ComplianceCertificateWiseReport,
    MonthReportExport,
    CertificatePdf,
    DownloadFile,
    ComplianceCertificateExport
})(CV_ComplianceCertificateReport);