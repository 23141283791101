import { TOGGLE_COMMON_LOADER, COMMOM_WIDGET_DATA, COMMOM_WIDGET_DATA1, WIDGETS1, WIDGETS2, WIDGETS3, WIDGETS4, WIDGETS5, WIDGETS6, WIDGETS7, DOMAIN_SCORE_DATA, USER_SCORE_CARD, CALENDER_DATA,COMPFIELAW_DATA } from "../types/index";

const initialState = {
    widgetsChartsData: {
        commonwidgetsdata: [],
        commonwidgetsdata1: [],
        widgetsdata1: [],
        widgetsdata2: [],
        widgetsdata3: [],
        widgetsdata4: [],
        widgetsdata5: [],
        widgetsdata6: [],
        widgetsdata7: [],
        widgetsdomaindata: [],
        widgetuserscorecard: [],
        widgetCalenderdata:[],
        widgetCompfielaw:[]
    }
}


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case COMMOM_WIDGET_DATA:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        commonwidgetsdata: payload
                    },
                };

            }
        case COMMOM_WIDGET_DATA1:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        commonwidgetsdata1: payload
                    },
                };
            }
        case WIDGETS1:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        widgetsdata1: payload
                    },
                };
            }
        case WIDGETS2:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        widgetsdata2: payload
                    },
                };
            }
        case WIDGETS3:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        widgetsdata3: payload
                    },
                };
            }
        case WIDGETS4:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        widgetsdata4: payload
                    },
                };
            }
        case WIDGETS5:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        widgetsdata5: payload
                    },
                };
            }
        case WIDGETS6:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        widgetsdata6: payload
                    },
                };
            }
        case WIDGETS7:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        widgetsdata7: payload
                    },
                };
            }
        case DOMAIN_SCORE_DATA:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        widgetsdomaindata: payload
                    },
                };
            }
        case USER_SCORE_CARD:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        widgetuserscorecard: payload
                    },
                };
            }
            case CALENDER_DATA:
            {
                return {
                    ...state,
                    widgetsChartsData: {
                        ...state.widgetsChartsData,
                        widgetCalenderdata: payload
                    },
                };
            }
            case COMPFIELAW_DATA:
                {
                    return {
                        ...state,
                        widgetsChartsData: {
                            ...state.widgetsChartsData,
                            widgetCalenderdata: payload
                        },
                    };
                }
        default:
            return state;
    }
}