import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, { getUrl } from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { REMARKSMASTER, TOGGLE_COMMON_LOADER } from './../../types/index';
import alertmessage from './../../../Libs/Altermessage'
const urls = getUrl()



export const remarkslist = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData,'decryptDatadecryptData');
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: REMARKSMASTER,
                    payload: decryptData,
                });
            }
        }


    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
     
    }
};

export const remarksupdate = ({ payload, executeCancel, paramid,authtoken }) => async dispatch => {
    const payloads = [
        authtoken,
        {
            "session_token": authtoken,
            "request": [
                "GetRemarksData",
                {

                }
            ]
        }
    ]

    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            }
            else if (decryptData[0] === 'UpdateRemarksMasterSuccess') {
                Toaster.success('Remarks Updated Successfully')
                // setTimeout(() => {
                //     window.location.reload(false);
                // }, 2000);
               
            }
            else if (decryptData[0] === 'remarksAlreadyExists') {
                Toaster.error('Remarks already exist')
            }
            else {
                Toaster.error(decryptData[0])
            }
        }
      
        dispatch(

            remarkslist({
                payload: payloads,
                paramid: paramid
            })
        )

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
        
    }
};
