import {VENDORTEMPFILTER, VENDORTEMPTABLE, EXPORT_USER_WISE_REPORT } from './../../types/index'
const initialState = {
    userwisereport: {
        userwisereports: [],
        userwisefilter: [],
        exportuserwisereport: []
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case VENDORTEMPFILTER:
           
            return {
                ...state,
                userwisereport: {
                    ...state.userwisereport,
                    userwisereports: payload,
                },
            };
        case VENDORTEMPTABLE:
            console.log(payload,"payload[1]111");
            return {
                ...state,
                userwisereport: {
                    ...state.userwisereport,
                    userwisefilter: payload,
                },
            };
        case EXPORT_USER_WISE_REPORT:
            return {
                ...state,
                userwisereport: {
                    ...state.userwisereport,
                    exportuserwisereport: payload,
                },
            };
        default:
            return state;
    }
}