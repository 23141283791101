import { useLocation, Link } from 'react-router-dom';
import { Collapse, Select, Modal, Button, Table } from 'antd';
import { useState, useEffect, useRef } from 'react';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { getdata, Approvertablereport } from '../../../Store/Action/Report/ApproverScoreCard'
const mapStateToProps = (state) => ({
    reportlist: state.ApproverScoreCard.approverdata
})
const ApproverScoreCard = ({
    getdata,
    Approvertablereport,
    reportlist,
}) => {
    let completedInProgressSum;
    let completedUserInProgress;
    let delayedSum;
    let delayedUserSum;
    let completedOverdueSum;
    let completedUserOverdue;
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    const [type, setType] = useState('')
    const [type1, setType1] = useState('')
    const [tableshow, setTableShow] = useState(false)
    const [countrydata, setCountryData] = useState(null)
    const [approverdata, setApproverData] = useState(undefined)
    const [completedModal, setCompletedModal] = useState(false)
    const [completedModal1, setCompletedModal1] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [tabledata, setTableData] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [dataSource1, setDataSource1] = useState([])
    const [count, setCount] = useState(2);
    const [count1, setCount1] = useState(2);
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArray1, setFilteredArray1] = useState([])
    const [datalist, setDataList] = useState({
        totaldata: []
    })
    const [header, setHeader] = useState('')
    const [approverscorecard, setApproverScoreCard] = useState({
        country: "",
        legel_entity: "",
        legel_entity_name: "",
        domain: "",
        domain_id: null,
        user: "",
        user_id: null
    })
    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetApproverScoreCard",
                        {
                            "c_id": countrydata && countrydata[0].c_id,
                            "le_id": entityid,
                            "d_id": Number(approverscorecard.domain_id),
                            "u_id": Number(approverscorecard.user_id)
                        }
                    ]
                }
            ]
            Approvertablereport({
                payload: payload,
                paramid: paramid
            })
        }
    }
    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        if (isAuth && entityid !== "null") {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetApproverScoreCardFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            getdata({
                payload: payload,
                paramid: paramid
            })
        }
    }, [authtoken, isAuth, entityid])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {
            setApproverScoreCard({
                ...approverscorecard,
                country: sessionArr[0].c_name,
                legel_entity: sessionArr[0].le_name
            })
        }
    }, [localStorage.getItem('SelectedEntity') !== "All Legal Entity"])
    useEffect(() => {
        if (reportlist.getapproverdata) {
            setDataList({
                ...datalist,
                totaldata: reportlist && reportlist.getapproverdata && reportlist.getapproverdata.user_domains
            })
        }
    }, [reportlist.getapproverdata])
    useEffect(() => {
        if (reportlist.tabledata) {
            setTableData(reportlist && reportlist.tabledata && reportlist.tabledata.approver_score_card_list)
        }
    }, [reportlist.tabledata])


    useEffect(() => {
        if (datalist.totaldata) {
            const domaindata = datalist.totaldata.map((item) => {
                return item.d_name
            })
            const domain = [...new Set(domaindata)];
            const userdata = datalist.totaldata.map((item) => {
                return item.u_name
            })
            const user = [...new Set(userdata)];
            const userid = datalist.totaldata.map((item) => {
                return item.u_id
            })
            const userids = [...new Set(userid)];
            const domainid = datalist.totaldata.map((item) => {
                return item.d_id
            })
            const domainids = [...new Set(domainid)];
            let temp = []
            let entityArray = []
            for (let i in user) {
                entityArray = {
                    "d_name": domain[0],
                    "u_name": user[i],
                    "u_id": userids[i],
                    "d_id": domainids[0],
                    "is_active": true,
                    "le_id": entityid
                }
                temp.push(entityArray)
            }
            setApproverData(temp)
        }
    }, [datalist.totaldata, entityid])
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const showCompletedModal = () => {
        setCompletedModal(true);
    };
    const handleCompletedCancel = () => {
        setCompletedModal(false);
    };
    const columns = [
        {
            title: type === 'Unit_inProgress' || type === 'Unit_overdue' ? 'Vendor' : 'Users',
            ellipsis: true,
            width: '50px',
            align: 'center',
            dataIndex: type === 'Unit_inProgress' || type === 'Unit_overdue' || type === 'Unit_completed' ? 'vendor' : 'user_name',
            key: type === 'Unit_inProgress' || type === 'Unit_overdue' || type === 'Unit_completed' ? 'vendor' : 'user_name',
        },
        {
            title: type === 'Unit_inProgress' || type === 'Unit_inProgress' ? 'Inprogress' : type === 'Unit_completed' || type === 'User_completed' ? "Completed" : type === 'Unit_overdue' || type === 'User_overdue' ? "Over due" : "",
            ellipsis: true,
            width: '60px',
            align: 'center',
            dataIndex: type === 'Unit_inProgress' || type === 'User_inProgress' ? 'inprogress_count' : type === 'Unit_completed' || type === 'User_completed' ? "complied_count" : type === 'Unit_overdue' || type === 'User_overdue' ? "overdue_count" : "",
            key: type === 'Unit_inProgress' || type === 'User_inProgress' ? 'inprogress_count' : type === 'Unit_completed' || type === 'User_completed' ? "complied_count" : type === 'Unit_overdue' || type === 'User_overdue' ? "overdue_count" : "",
        },

    ];
    useEffect(() => {
        if (tabledata) {
            if (type != '') {
                if (type === 'Unit_inProgress') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_overdue_user_wise && tabledata[0].approver_overdue_user_wise[0] && tabledata[0].approver_overdue_user_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_inprogress_unit_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_inprogress_unit_wise)
                    }
                }
                else if (type === 'User_inProgress') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_inprogress_user_wise && tabledata[0].approver_inprogress_user_wise[0] && tabledata[0].approver_inprogress_user_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_inprogress_user_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_inprogress_user_wise)
                    }
                }
                else if (type === 'Unit_completed') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_completed_unit_wise && tabledata[0].approver_completed_unit_wise[0] && tabledata[0].approver_completed_unit_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_completed_unit_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_completed_unit_wise)
                    }
                }
                else if (type === 'User_completed') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_completed_user_wise && tabledata[0].approver_completed_user_wise[0] && tabledata[0].approver_completed_user_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_completed_user_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_completed_user_wise)
                    }
                }
                else if (type === 'Unit_overdue') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_overdue_unit_wise && tabledata[0].approver_overdue_unit_wise[0] && tabledata[0].approver_overdue_unit_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_overdue_unit_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_overdue_unit_wise)
                    }

                }
                else if (type === 'User_overdue') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_overdue_user_wise && tabledata[0].approver_overdue_user_wise[0] && tabledata[0].approver_overdue_user_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_overdue_user_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_overdue_user_wise)
                    }

                }
            }
        }

    }, [type, tabledata])
    useEffect(() => {
        if (filteredArray.length > 0) {
            setDataSource(filteredArray)
            if (type === 'Unit_inProgress') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].inprogress_count))
                }
                completedInProgressSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'User_inProgress') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].inprogress_count))
                }
                completedUserInProgress = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'Unit_completed') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].complied_count))
                }
                delayedSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'User_completed') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].complied_count))
                }
                delayedUserSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'Unit_overdue') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].overdue_count))
                }
                completedOverdueSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'User_overdue') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].overdue_count))
                }
                completedUserOverdue = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
        }
    }, [filteredArray])
    useEffect(() => {
        if (filteredArray) {
            if (type === 'Unit_inProgress') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    inprogress_count: completedInProgressSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'User_inProgress') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    inprogress_count: completedUserInProgress,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'Unit_completed') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    complied_count: delayedSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'User_completed') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    complied_count: delayedUserSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'Unit_overdue') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    overdue_count: completedOverdueSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'User_overdue') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    overdue_count: completedUserOverdue,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
        }
    }, [filteredArray, type])

    const showModal1 = () => {
        setIsModalOpen1(true);
    };
    const handleCancel1 = () => {
        setIsModalOpen1(false);
    };
    const showCompletedModal1 = () => {
        setCompletedModal1(true);
    };
    const handleCompletedCancel1 = () => {
        setCompletedModal1(false);
    };
    const columns1 = [
        {
            title: type1 === 'Unit_inProgress' || type1 === 'Unit_overdue' ? 'Vendor' : 'Users',
            ellipsis: true,
            width: '50px',
            align: 'center',
            dataIndex: type1 === 'Unit_inProgress' || type1 === 'Unit_overdue' || type1 === 'Unit_completed' ? 'vendor' : 'user_name',
            key: type1 === 'Unit_inProgress' || type1 === 'Unit_overdue' || type1 === 'Unit_completed' ? 'vendor' : 'user_name',
        },
        {
            title: type1 === 'Unit_inProgress' || type1 === 'Unit_inProgress' ? 'Inprogress' : type1 === 'Unit_completed' || type1 === 'User_completed' ? "Completed" : type1 === 'Unit_overdue' || type1 === 'User_overdue' ? "Over due" : "",
            ellipsis: true,
            width: '60px',
            align: 'center',
            dataIndex: type1 === 'Unit_inProgress' || type1 === 'User_inProgress' ? 'inprogress_count' : type1 === 'Unit_completed' || type1 === 'User_completed' ? "complied_count" : type1 === 'Unit_overdue' || type1 === 'User_overdue' ? "overdue_count" : "",
            key: type1 === 'Unit_inProgress' || type1 === 'User_inProgress' ? 'inprogress_count' : type1 === 'Unit_completed' || type1 === 'User_completed' ? "complied_count" : type1 === 'Unit_overdue' || type1 === 'User_overdue' ? "overdue_count" : "",
        },

    ];
    useEffect(() => {
        if (tabledata) {
            if (type1 != '') {
                if (type1 === 'Unit_inProgress') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_overdue_user_wise && tabledata[1].approver_overdue_user_wise[0] && tabledata[1].approver_overdue_user_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_inprogress_unit_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_inprogress_unit_wise)
                    }
                }
                else if (type1 === 'User_inProgress') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_inprogress_user_wise && tabledata[1].approver_inprogress_user_wise[0] && tabledata[1].approver_inprogress_user_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_inprogress_user_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_inprogress_user_wise)
                    }
                }
                else if (type1 === 'Unit_completed') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_completed_unit_wise && tabledata[1].approver_completed_unit_wise[0] && tabledata[1].approver_completed_unit_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_completed_unit_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_completed_unit_wise)
                    }
                }
                else if (type1 === 'User_completed') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_completed_user_wise && tabledata[1].approver_completed_user_wise[0] && tabledata[1].approver_completed_user_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_completed_user_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_completed_user_wise)
                    }
                }
                else if (type1 === 'Unit_overdue') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_overdue_unit_wise && tabledata[1].approver_overdue_unit_wise[0] && tabledata[1].approver_overdue_unit_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_overdue_unit_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_overdue_unit_wise)
                    }

                }
                else if (type1 === 'User_overdue') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_overdue_user_wise && tabledata[1].approver_overdue_user_wise[0] && tabledata[1].approver_overdue_user_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_overdue_user_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_overdue_user_wise)
                    }

                }
            }
        }

    }, [type1, tabledata])

    useEffect(() => {
        if (filteredArray1.length > 0) {
            setDataSource1(filteredArray1)
            if (type1 === 'Unit_inProgress') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].inprogress_count))
                }
                completedInProgressSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type1 === 'User_inProgress') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].inprogress_count))
                }
                completedUserInProgress = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type1 === 'Unit_completed') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].complied_count))
                }
                delayedSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type1 === 'User_completed') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].complied_count))
                }
                delayedUserSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type1 === 'Unit_overdue') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].overdue_count))
                }
                completedOverdueSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type1 === 'User_overdue') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].overdue_count))
                }
                completedUserOverdue = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
        }
    }, [filteredArray1])
    useEffect(() => {
        if (filteredArray1) {
            if (type1 === 'Unit_inProgress') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    inprogress_count: completedInProgressSum,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
            else if (type1 === 'User_inProgress') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    inprogress_count: completedUserInProgress,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
            else if (type1 === 'Unit_completed') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    complied_count: delayedSum,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
            else if (type1 === 'User_completed') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    complied_count: delayedUserSum,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
            else if (type1 === 'Unit_overdue') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    overdue_count: completedOverdueSum,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
            else if (type1 === 'User_overdue') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    overdue_count: completedUserOverdue,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
        }
    }, [filteredArray1, type1])


    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>

                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ color: '#1890ff' }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ color: '#1890ff' }}>Score Cards</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ color: '#1890ff' }}>Approver Score Card</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <Modal title={header === 'Unit' ? 'Vendor Wise Count' : 'Assignee Wise Count'} visible={isModalOpen} onCancel={handleCancel} footer={false}>
                    <Table
                        className='userprivclass'
                        columns={columns}
                        dataSource={dataSource}
                        bordered
                        // scroll={{ x: 1500 }}
                        pagination={false} />

                </Modal>
                <Modal title={header === 'Unit' ? 'Vendor Wise Count' : 'Assignee Wise Count'} visible={completedModal} onCancel={handleCompletedCancel} footer={false}>
                    <Table
                        className='userprivclass'
                        columns={columns}
                        dataSource={dataSource}
                        bordered
                        // scroll={{ x: 1500 }}
                        pagination={false} />

                </Modal>
                <Modal title={header === 'Unit' ? 'Vendor Wise Count' : 'Assignee Wise Count'} onCancel={handleCancel1} footer={false}>
                    <Table
                        className='userprivclass'
                        columns={columns1}
                        dataSource={dataSource1}
                        bordered
                        // scroll={{ x: 1500 }}
                        pagination={false} />

                </Modal>
                <Modal title={header === 'Unit' ? 'Vendor Wise Count' : 'Assignee Wise Count'} visible={completedModal1} onCancel={handleCompletedCancel1} footer={false}>
                    <Table
                        className='userprivclass'
                        columns={columns1}
                        dataSource={dataSource1}
                        bordered
                        // scroll={{ x: 1500 }}
                        pagination={false} />

                </Modal>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" style={{ padding: "15px 5px" }}>
                                <div className="card-body" style={{ padding: "5px 5px" }}>
                                    <Collapse defaultActiveKey={["1"]}  >
                                        <Panel header="Approver Score Card" style={{ backgroundColor: "#73C4E9" }} key={key}>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-2'>
                                                        <label style={{ float: "right" }}><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <Select
                                                            allowClear={false}
                                                                placeholder='Enter Country'
                                                                onChange={(value) => {
                                                                    setApproverScoreCard({
                                                                        ...approverscorecard,
                                                                        country: value,
                                                                        legel_entity: "",
                                                                        legel_entity_name: "",
                                                                        domain: "",
                                                                        domain_id: null,
                                                                        user: "",
                                                                        user_id: null
                                                                    })
                                                                }}
                                                                style={{ width: "100%" }}

                                                                value={approverscorecard.country || undefined}
                                                            >
                                                                {countrydata && countrydata.length && countrydata.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            : <p>{sessionArr[0].c_name}</p>}
                                                        {validator.current.message(
                                                            'country',
                                                            approverscorecard.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })}
                                                    </div>
                                                    {/* <div className='col-md-1'></div> */}
                                                    <div className='col-md-2'>
                                                        <label style={{ float: "right" }}><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <Select
                                                         allowClear={false}
                                                            onChange={(value, data) => {
                                                                setApproverScoreCard({
                                                                    ...approverscorecard,
                                                                    domain_id: value,
                                                                    domain: data.children,
                                                                    user: "",
                                                                    user_id: null
                                                                })
                                                            }}
                                                            placeholder="Enter Domain"
                                                            style={{ width: '100%' }}
                                                            value={approverscorecard.domain || undefined}
                                                            disabled={approverscorecard.legel_entity == '' ? true : false}
                                                        >

                                                            <Option key={approverdata && approverdata[0] && approverdata[0].d_id}>
                                                                {approverdata && approverdata[0] && approverdata[0].d_name}
                                                            </Option>

                                                        </Select>
                                                        {validator.current.message(
                                                            'domain',
                                                            approverscorecard.domain,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Domian Required',
                                                                }
                                                            })}
                                                    </div>

                                                    {/* <div className='col-md-1'></div> */}
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                <div className='row'>
                                                    <div className='col-md-2'>
                                                        <label style={{ float: "right" }}><b>Legal Entity : </b> <span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                            <Select
                                                            allowClear={false}
                                                                placeholder='Enter Legal Entity'
                                                                onChange={(value, data) => {
                                                                    setCurrentEntity(value)
                                                                    setApproverScoreCard({
                                                                        ...approverscorecard,
                                                                        legel_entity: data.children,
                                                                        domain: "",
                                                                        domain_id: null,
                                                                        user: "",
                                                                        user_id: null
                                                                    })
                                                                }}
                                                                style={{ width: '100%' }}
                                                                value={approverscorecard.legel_entity || undefined}
                                                                disabled={approverscorecard.country == '' ? true : false}

                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}                                                        </Select>
                                                            : <p>{localStorage.getItem('SelectedEntity')}</p>}

                                                        {validator.current.message(
                                                            'legalentity',
                                                            approverscorecard.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div>

                                                    {/* <div className='col-md-1'></div> */}
                                                    <div className='col-md-2'>
                                                        <label style={{ float: "right" }}><b>User : </b> <span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <Select
                                                         allowClear={false}
                                                            onChange={(value, data) => {
                                                                setApproverScoreCard({
                                                                    ...approverscorecard,
                                                                    user_id: value,
                                                                    user: data.children
                                                                })
                                                            }}
                                                            placeholder="Enter User"
                                                            style={{ width: '100%' }}
                                                            value={approverscorecard.user || undefined}
                                                            disabled={approverscorecard.domain == '' ? true : false}
                                                        >
                                                            {approverdata && approverdata.length && approverdata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.u_id}>
                                                                        {item.u_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'domain',
                                                            approverscorecard.domain,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'User Required',
                                                                }
                                                            })}
                                                    </div>
                                                    {/* <div className='col-md-1'></div> */}
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4'>
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "32%", marginRight: "10px", width: "100px" }}
                                                            onClick={onshow}
                                                        >
                                                            Show
                                                        </Button>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    {tableshow == true ?
                                        <>
                                            {/* <tr className='ant-table-row ant-table-row-level-0'>
                                                <td colSpan={12} className='ant-table-cell'><b style={{ fontSize: "18px", marginLeft: "385px"}}>Approver Details</b></td>
                                            </tr> */}
                                            <h3 className='mt-2'><b style={{ fontSize: "18px", marginLeft: "385px", marginTop: "20px" }}>Approver Score Card</b></h3>
                                            <div className='col-md-12 mt-3 mb-3 ml-2' >
                                                <div className='row'>
                                                    <div className='col-md-3 ml-2'>
                                                        <label><b>Legal Entity :</b>{localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? approverscorecard.legel_entity : localStorage.getItem('SelectedEntity')}</label>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label><b>Country :</b>{sessionArr && sessionArr[0].c_name}</label>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label><b>Domain :</b>{approverscorecard.domain}</label>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label><b>User :</b>{approverscorecard.user}</label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='ant-table-wrapper'>
                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                        <div className='ant-table-content fh userprivclass1'>
                                                            <table className=' table-fixed'>
                                                                <table style={{ boxShadow: "0px 0px 0px 1px grey" }}>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className='ant-table-cell w10px'>#</th>
                                                                            <th className='ant-table-cell custom-width1'>Task Status</th>
                                                                            <th className='ant-table-cell custom-width1'>Count</th>
                                                                            <th className='ant-table-cell custom-width1'><center>Vendor Wise</center></th>
                                                                            <th className='ant-table-cell custom-width1'>Assigne Wise</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>1</center></td>
                                                                            <td className='ant-table-cell' ><center>In Progress</center></td>
                                                                            <td className='ant-table-cell'><center>{tabledata && tabledata[0].inprogress_count}</center></td>
                                                                            <td className='ant-table-cell'><center><Button type="primary" disabled={tabledata && tabledata[0].inprogress_count == 0 ? true : false}
                                                                                onClick={() => {
                                                                                    setType('Unit_inProgress')
                                                                                    setHeader('Unit')
                                                                                    showModal()
                                                                                }}
                                                                            >View</Button></center></td>
                                                                            <td className='ant-table-cell'><center><Button type="primary" disabled={tabledata && tabledata[0].inprogress_count == 0 ? true : false}
                                                                                onClick={() => {
                                                                                    setType('User_inProgress')
                                                                                    setHeader('Assignee')
                                                                                    showModal()
                                                                                }}>View</Button></center></td>
                                                                        </tr>
                                                                        <tr className="bg-lightdark">
                                                                            <td className='ant-table-cell'><center>2</center></td>
                                                                            <td className='ant-table-cell'><center>Over due</center></td>
                                                                            <td className='ant-table-cell'><center>{tabledata && tabledata[0].overdue_count}</center></td>
                                                                            <td className='ant-table-cell'><center><Button type="primary" disabled={tabledata && tabledata[0].overdue_count == 0 ? true : false}
                                                                                onClick={() => {
                                                                                    setType('Unit_overdue')
                                                                                    setHeader('Unit')
                                                                                    showCompletedModal()
                                                                                }}
                                                                            >View</Button></center></td>
                                                                            <td className='ant-table-cell'><center><Button type="primary" disabled={tabledata && tabledata[0].overdue_count == 0 ? true : false}
                                                                                onClick={() => {
                                                                                    setType('User_overdue')
                                                                                    setHeader('Assignee')
                                                                                    showCompletedModal()
                                                                                }}
                                                                            >View</Button></center></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>3</center></td>
                                                                            <td className='ant-table-cell'><center>Completed</center></td>
                                                                            <td className='ant-table-cell'><center>{tabledata && tabledata[0].completed_count}</center></td>
                                                                            <td className='ant-table-cell'><center><Button type="primary" disabled={tabledata && tabledata[0].completed_count == 0 ? true : false}
                                                                                onClick={() => {
                                                                                    setType('Unit_completed')
                                                                                    setHeader('Unit')
                                                                                    showModal()
                                                                                }}
                                                                            >View</Button></center></td>
                                                                            <td className='ant-table-cell'><center><Button type="primary" disabled={tabledata && tabledata[0].completed_count == 0 ? true : false}
                                                                                onClick={() => {
                                                                                    setType('User_completed')
                                                                                    setHeader('Assignee')
                                                                                    showModal()
                                                                                }}>View</Button></center></td>
                                                                        </tr>
                                                                        <tr className="bg-lightdark">
                                                                            <td className='ant-table-cell'><center></center></td>
                                                                            <td className='ant-table-cell'><center><b>Total</b></center></td>
                                                                            <td className='ant-table-cell'><center>{(tabledata && tabledata[0].inprogress_count) + (tabledata && tabledata[0].overdue_count) + (tabledata && tabledata[0].completed_count)}</center></td>
                                                                            <td className='ant-table-cell'><center></center></td>
                                                                            <td className='ant-table-cell'><center></center></td>
                                                                        </tr>
                                                                    </tbody>

                                                                </table>
                                                            </table>
                                                            <br />
                                                        </div>
                                                </div>
                                            </div>
                                            {/* <tr className='ant-table-row ant-table-row-level-0'>
                                                <td colSpan={12} className='ant-table-cell'><b style={{ fontSize: "18px", marginLeft: "385px" }}>Assignee Details</b></td>
                                            </tr> */}
                                            <h3><b style={{ fontSize: "18px", marginLeft: "385px" }}>Assignee Details</b></h3>
                                            <div className='ant-table-wrapper'>
                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-content fh userprivclass1'>
                                                        <table className='table-fixed' >
                                                            <thead className='ant-table-thead'>
                                                                <tr>
                                                                    <th className='ant-table-cell w10px'>#</th>
                                                                    <th title='Compliance Task' style={{ width: "30px" }}>Task Status</th>
                                                                    <th style={{ width: "30px" }}>Count</th>
                                                                    <th style={{ width: "30px" }}><center>Vendor Wise</center></th>
                                                                    <th style={{ width: "30px" }}>Assigne Wise</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>1</center></td>
                                                                    <td className='ant-table-cell'><center>In Progress</center></td>
                                                                    <td className='ant-table-cell'><center>{tabledata && tabledata[1].inprogress_count}</center></td>
                                                                    <td className='ant-table-cell'><center><Button type="primary" disabled={tabledata && tabledata[1].inprogress_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType1('Unit_inProgress')
                                                                            setHeader('Unit')
                                                                            showCompletedModal1()
                                                                        }}
                                                                    >View</Button></center></td>
                                                                    <td className='ant-table-cell'><center><Button type="primary" disabled={tabledata && tabledata[1].inprogress_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType1('User_inProgress')
                                                                            setHeader('Assignee')
                                                                            showCompletedModal1()
                                                                        }}>View</Button></center></td>
                                                                </tr>
                                                                <tr className="bg-lightdark">
                                                                    <td className='ant-table-cell'><center>2</center></td>
                                                                    <td className='ant-table-cell'><center>Over due</center></td>
                                                                    <td className='ant-table-cell'><center>{tabledata && tabledata[1].overdue_count}</center></td>
                                                                    <td className='ant-table-cell'><center><Button type="primary" disabled={tabledata && tabledata[1].overdue_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType1('Unit_overdue')
                                                                            setHeader('Unit')
                                                                            showCompletedModal1()
                                                                        }}
                                                                    >View</Button></center></td>
                                                                    <td className='ant-table-cell'><center><Button type="primary" disabled={tabledata && tabledata[1].overdue_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType1('User_overdue')
                                                                            setHeader('Assignee')
                                                                            showCompletedModal1()
                                                                        }}
                                                                    >View</Button></center></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center></center></td>
                                                                    <td className='ant-table-cell'><center><b>Total</b></center></td>
                                                                    <td className='ant-table-cell'><center>{(tabledata && tabledata[1].inprogress_count) + (tabledata && tabledata[1].overdue_count) + (tabledata && tabledata[1].completed_count)}</center></td>
                                                                    <td className='ant-table-cell'><center></center></td>
                                                                    <td className='ant-table-cell'><center></center></td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : false}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    getdata,
    Approvertablereport
})(ApproverScoreCard);