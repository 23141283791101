import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom'
import { Modal, Input, Table, Button, Space, Tooltip, Select, DatePicker } from 'antd';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getVendorClosureList, saveVendorClosureData, saveVendorTempClosureData } from './../../Store/Action/Master/CV_VendorClosure';
import { InfoCircleOutlined, PlayCircleOutlined, PlusOutlined, SearchOutlined, AudioOutlined, FilterFilled } from '@ant-design/icons';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import SimpleReactValidator from 'simple-react-validator';
import { UNIT_CLOSURE_LIST } from '../../Store/types';
const mapStateToProps = (state) => ({
    unitClosureList: state.VendorClosure.vendorClosure.VendorClosureList,
    filterrecall: state.recallfilters.recallfilters

});
const VendorClosure = ({
    saveVendorClosureData,
    saveVendorTempClosureData,
    getVendorClosureList,
    unitClosureList,
    filterrecall: { recallremarks },
    GetRemarksData

}) => {
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [remarks, setRemarks] = useState('')
    //const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [show, setShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: '',
        selectedtype: '',
    })
    const [modalData1, setModalData1] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: '',
        selectedtype: '',
        date: ''
    })
    console.log(modalData,'MODAL DATA')
    console.log(modalData1,'MODAL1 DATA')
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [modalVisible, setModaVisible] = useState(false)
    const [modalVisible1, setModaVisible1] = useState(false)
    const [entityid, setCurrentEntity] = useState("");
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntity')])
    const { Search } = Input;
    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText({
        //     ...searchText,
        //     data: selectedKeys[0]
        // });
        // setSearchedColumn(dataIndex);
    };
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText({
            data: '',
            index: searchText.index + 1
        });
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div>
                <Input
                    ref={searchInput}
                    placeholder={'Search '+ placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterFilled
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });




    const columns = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '50px',
            // ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Business Group',
            dataIndex: 'business_group_name',
            key: 'business_group_name',
            width: '170px',
            ellipsis: true,
            align: 'center',
            ...getColumnSearchProps('business_group_name','Business Group'),
            sorter: (a, b) => a.business_group_name && b.business_group_name != null ? a.business_group_name.localeCompare(b.business_group_name) : "",
            render: (text, record) => {
                return (
                    <Fragment>
                        {text != null ? text : '-'}
                    </Fragment>
                )
            }
        },
        {
            title: 'Legal Entity',
            dataIndex: 'legal_entity_name',
            key: 'legal_entity_name',
            width: '150px',
            ellipsis: true,
            ...getColumnSearchProps('legal_entity_name','Legal Entity'),
            sorter: (a, b) => a.legal_entity_name.localeCompare(b.legal_entity_name),
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            align: 'center',
            // ellipsis: true,
            width: '120px',
            ...getColumnSearchProps('division_name','Division'),
            sorter: (a, b) => a.division_name.localeCompare(b.division_name),
            render: (text, record) => {
                return (
                    <Fragment>
                        {text != null ?
                            <Tooltip title={text}>{text}</Tooltip> : '-'}
                    </Fragment>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            width: '120px',
            align: 'center',
            // ellipsis: true,
            ...getColumnSearchProps('category_name','Category'),
            sorter: (a, b) => a.category_name.localeCompare(b.category_name),
        },
        {
            title: 'Vendor',
            dataIndex: 'unit_code',
            key: 'unit_code',
            align: 'center',
            // ellipsis: true,
            // align: 'left',
            ...getColumnSearchProps('unit_code','Vendor'),
            sorter: (a, b) => a.unit_code.localeCompare(b.unit_code),
            width: '120px',
            render: (text, record) => {
                return (
                    <Fragment>
                        <p style={{ whiteSpace: 'unset' }}><span><Tooltip title={`${record.address},${record.postal_code}`}><InfoCircleOutlined style={{ fontSize: "13px", marginRight: "5px", marginTop: "5px" }} /></Tooltip>&nbsp;</span>
                            <Tooltip title={`${text}-${record.unit_name}`}>{text}-{record.unit_name}</Tooltip></p>
                    </Fragment>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            ellipsis: true,
            width: '100px',
            align: 'center',
            filters: [
                {
                    text: 'Active',
                    value: 0,
                },
                {
                    text: 'Inactive',
                    value: 1,
                },
            ],
            onFilter: (value, record) => record.is_active === value,
            filterSearch: false,
            render: (text, record) => {
                return (
                    <Fragment>
                        {
                            text == 0 ? "Active" : "In Active"
                        }
                    </Fragment>
                )
            }
        },
        {
            title: 'Closure',
            dataIndex: 'is_active',
            // className: 'actiontd',
            width: '120px',
            align: 'center',
            filters: [
                {
                    text: 'Close',
                    value: 0,
                },
                {
                    text: 'Closed',
                    value: 2,
                },
                {
                    text: 'Reactivate',
                    value: 1,
                },
            ],
            onFilter: (value, record) => record.is_active === value,
            filterSearch: false,
            render: (text, record) => {
                return (
                    <Fragment>
                        {record.is_vendor_closed == '0' ?
                            <Button
                                type="primary"
                                style={{ padding: "5px", width: "80px", textAlign: "center" }}
                                onClick={() => {
                                    // setShow(true)
                                    closeById(record.unit_id, "closed");
                                }}
                                title=""
                            >Close
                            </Button> :
                            <Tooltip title={(30 - record.validity_days) + '-' + 'Days Left'} color='royalblue'>
                                <Button
                                    type="primary"
                                    style={{ padding: "5px", width: "80px", textAlign: "center" }}
                                    onClick={() => {
                                        setModalData({
                                            password: '',
                                            reason: ''
                                        })
                                        // setShow(true)
                                        closeById(record.unit_id, "reactive");
                                    }}
                                    title=""
                                >Reactivate
                                </Button>
                            </Tooltip>

                        }
                    </Fragment>
                )
            }
        },
        {
            title: 'Temporary Closure',
            dataIndex: 'is_vendor_closed',
            // className: 'actiontd',
            width: '120px',
            align: 'center',
            // width:"100px",
            filters: [
                {
                    text: 'Close',
                    value: 0,
                },
                {
                    text: 'Closed',
                    value: 2,
                },
                {
                    text: 'Reactivate',
                    value: 1,
                },
            ],
            onFilter: (value, record) => record.is_vendor_closed === value,
            filterSearch: false,
            render: (text, record) => {
                return (
                    <Fragment>
                        {text == 0 ?
                            <Button disabled={record.is_active == 1 ? true : false}
                                type="primary"
                                style={{ padding: "5px", width: "80px", textAlign: "center" }}
                                onClick={() => {
                                    setShow(true)
                                    closeById1(record.unit_id, "closed");
                                }}
                                title=""
                            >Close
                            </Button> :
                            <Tooltip title={(365 - record.validity_days) + '-' + 'Days Left'} color='royalblue'>
                                <Button disabled={record.is_active == 1 ? true : false}
                                    type="primary"
                                    style={{ padding: "5px", width: "80px", textAlign: "center" }}
                                    onClick={() => {
                                        setModalData1({
                                            password: '',
                                            reason: ''
                                        })
                                        setShow(true)
                                        closeById1(record.unit_id, "reactive");
                                    }}
                                    title=""
                                >Reactivate
                                </Button>
                            </Tooltip>

                        }
                    </Fragment>
                )
            }
        },
        {
            title: 'Pending Tasks',
            dataIndex: 'category_name',
            key: 'category_name',
            width: '100px',

            // ellipsis: true,
            // ...getColumnSearchProps('category_name'),
            // sorter: (a, b) => a.category_name.localeCompare(b.category_name),
        },

    ];

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                setRemarks(text)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]
    const onSubmitTable = () => {
        setFormSubmit(true)

        if (formValidator1.current.allValid()) {

            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVendorClosureData",
                        {
                            "legal_entity_id": entityid,

                        }
                    ]
                }
            ]
            if (entityid != "null") {
                getVendorClosureList({
                    payload: payload,
                    paramid: paramid
                })
            } else {
                setBaseData('')
            }
        }
    }
    const OnSubmited = () => {
        setAddFormSubmit(true)

        if (formValidator.current.allValid()) {
            setModaVisible(false)
            setModaVisible1(false)
            saveVendorClosureData({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveUnitClosureData",
                                {
                                    "password": modalData.password,
                                    "closed_remarks": remarks,
                                    "unit_id": Number(modalData.unit_id),
                                    "grp_mode": modalData.reason == 'closed' ? "close" : "reactive",
                                    "legal_entity_id": entityid
                                }
                            ]
                        }
                    ],
                paramid: paramid,
                entityid: entityid,
                type: modalData.selectedtype
            })
            setModalData({
                ...modalData,
                password: ""
            })
            setRemarks("")
            setAddFormSubmit(false)
        }
    }
    const OnSubmittemp = () => {
        setAddFormSubmit2(true)
        if (formValidator2.current.allValid()) {
            console.log(modalData1, 'MODAL DATA ')
            setModaVisible(false)
            setModaVisible1(false)
            saveVendorTempClosureData({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveVendorClosureData",
                                {
                                    "password": modalData1.password,
                                    "closed_remarks": remarks,
                                    "unit_id": Number(modalData1.unit_id),
                                    "grp_mode": modalData1.reason == 'closed' ? "close" : "reactive",
                                    "legal_entity_id": entityid,
                                    "date": "01-Jan-2023",
                                }
                            ]
                        }
                    ],
                paramid: paramid,
                entityid: entityid,
                type: modalData1.selectedtype
            })
            setModalData1({
                ...modalData1,
                password: ""
            })
            setRemarks("")
            setAddFormSubmit2(false)
        }
    }


    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
        formValidator2.current.showMessages()
    }, [])
    const passwordcancel = () => {
        setModaVisible(false)
        setModaVisible1(false)
        setModalData({
            ...modalData,
            password: ""
        })
        setRemarks("")
        setAddFormSubmit(false)
    }
    const passwordcancel1 = () => {
        setModaVisible(false)
        setModaVisible1(false)
        setModalData1({
            ...modalData1,
            password: ""
        })
        setRemarks("")
        setAddFormSubmit1(false)
    }
    const handleOk = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };

    const handleCancel = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    const closeById = (unit_id, type) => {
        type == "closed" ?
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to Close?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible1(true)
                    setModalData1({
                        ...modalData1,
                        unit_id: unit_id,
                        reason: type,
                        selectedtype: 'closed'
                    })
                }
            }) :
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to Activate?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible1(true)
                    setModalData1({
                        ...modalData1,
                        unit_id: unit_id,
                        reason: type,
                        selectedtype: 'activate'
                    })
                }
            })
    };

    const closeById1 = (unit_id, type) => {
        type == "closed" ?
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to Close?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setModalData1({
                        ...modalData1,
                        unit_id: unit_id,
                        reason: type,
                        selectedtype: 'closed'
                    })
                }
            }) :
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to Activate?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setModalData1({
                        ...modalData1,
                        unit_id: unit_id,
                        reason: type,
                        selectedtype: 'activate'
                    })
                }
            })
    };

    useEffect(() => {
        if (modalData.legal_entity == '' || null || undefined) {
            setModalData({
                ...modalData,
                legal_entity: entityid
            })

        }
    }, [modalData.legal_entity])


    useEffect(() => {
        if (modalData1.legal_entity == '' || null || undefined) {
            setModalData({
                ...modalData1,
                legal_entity: entityid
            })

        }
    }, [modalData1.legal_entity])


    const [baseData, setBaseData] = useState([]);

    console.log(baseData, 'VALUES OF TABLE')
    const dispatch = useDispatch()
    useEffect(() => {
        if (unitClosureList && unitClosureList.vendor_closure_units && unitClosureList.vendor_closure_units.length > 0) {
            setBaseData(unitClosureList.vendor_closure_units);
        }
    }, [unitClosureList.vendor_closure_units])

    // useEffect(() => {
    //     setBaseData([])
    // },[])

    useEffect(() => {
        return () => {
            dispatch({
                type: UNIT_CLOSURE_LIST,
                payload: ['', { unit_closure_units: [] }]
            })
        }
    }, [])

    const searchRecords = (e) => {
        if (e == 'clear') {
            setClearText('')
            setBaseData(unitClosureList.vendor_closure_units)
            setIconEnable(false)
        } else {
            setClearText(e.target.value)
            if (e.target.value.length > 0) {
                setIconEnable(true)
            } else {
                setIconEnable(false)
            }
            const filterTable = unitClosureList.vendor_closure_units.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
            setBaseData(filterTable)
        }

    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Vendor Closure</span>
                                        </li>
                                    </ol>
                                </nav>
                                <div style={{ "margin-top": "10px" }}>
                                    <label style={{ "font-weight": 'bold' }}>Legal Entity<span style={{ "color": "red" }}>*</span></label>
                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                        <div className="form-group" style={{ "margin-top": '-24px', "margin-left": '90px' }}>
                                            <Select
                                                allowClear={false}
                                                size="default"
                                                style={{ width: '300px', marginLeft: "20px" }}
                                                placeholder="Select Legal Entity"
                                                onChange={(data, value) => {
                                                    setCurrentEntity(data)
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                    return (
                                                        <Option key={item.le_id}>
                                                            {item.le_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {formValidator1.current.message(
                                                'Legal Entity',
                                                entityid,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${FormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Legal Entity Required',
                                                    }
                                                })}
                                        </div> :
                                        <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                    }
                                </div>

                            </div>
                            <div className="col-lg-2 align-self-center" style={{ marginTop: '3.75%', marginLeft: '2%' }}>

                                {/* <Link style={{ textAlign: "center" }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4" onClick={(e) => { */}
                                <Button type="primary" shape="round" className='addbutton' style={{ background: "royalblue", borderColor: "royalblue", textAlign: "center" }}

                                    onClick={onSubmitTable}
                                >Show
                                </Button>
                                {/* </Link> */}


                            </div>
                            {/* <div className="col-lg-3 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                                <div className="search-box" style={{ marginRight: '0px', marginTop: '30px' }}>
                                    <input className="search-txt" value={clearText} type="text" onChange={searchRecords} placeholder="Type to Search" />
                                    <a className="search-btn">
                                        {iconEnable == true ?
                                            <i class="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                            : <i class="fas fa-search"></i>}
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body" style={{ padding: '0px' }}>
                                        <Table
                                            columns={columns}
                                            className='userprivclass'
                                            dataSource={baseData}
                                            bordered
                                            scroll={{ x: 1200 }}
                                            pagination={baseData.length > 10 ? {
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger
                                            } : false}
                                        // onChange={handleChange} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>
            {/* <Modal visible={modalVisible} footer={null} className='usr_modal_class'
                onCancel={passwordcancel}>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor=""><b>Password:</b> <span style={{ "color": "red" }}>*</span></label>
                        <input
                            type="password"
                            id="password"
                            name='password'
                            className="form-control"
                            placeholder="Enter password"
                            value={modalData.password}
                            onChange={(e) => {
                                setModalData({
                                    ...modalData,
                                    password: e.target.value
                                })
                            }} />
                        {formValidator.current.message(
                            'password',
                            modalData.password,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required',
                                }
                            })}
                    </div>
                </div>
                <br />
                <div className="col-md-12">
                    <label htmlFor=""><b>Remarks:</b> <span style={{ "color": "red" }}>*</span></label>

                    <textarea className='form-control'
                        placeholder="Enter Reason"
                        row='1' cols='4' 
                        onChange={(e) => {
                            setRemarks(e.target.value)
                        }}
                        value={remarks}

                    ></textarea>
                    <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" style={{ "float": "right" }} onClick={() => { showRemarksModal() }}>
                        <PlusOutlined />
                    </span>

                    {formValidator.current.message(
                        'reason',
                        remarks,
                        'required',
                        {
                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Reason Required',
                            }
                        })}

                </div>
                <br />

                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={OnSubmited}
                    >
                        Submit
                    </Button>
                </div>

            </Modal> */}


            <Modal title='Enter Password and Reason' visible={modalVisible} footer={null} className='usr_modal_class'
                onCancel={passwordcancel1}>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor=""><b>Password:</b> <span style={{ "color": "red" }}>*</span></label>
                        <input
                            type="password"
                            id="password"
                            name='password'
                            className="form-control"
                            placeholder="Enter password"
                            defaultValue={modalData1.password}
                            key={modalData1?.password ? modalData1?.password : ''}
                            onChange={(e) => {
                                console.log(e.target.value, 'sneha');
                                setModalData1({
                                    ...modalData1,
                                    password: e.target.value
                                })
                                // setPassword(e.target.value)
                            }} />
                        {formValidator.current.message(
                            'password',
                            modalData1.password,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required',
                                }
                            })}
                    </div>
                </div>
                <br />
                <div className="col-md-12">
                    <label htmlFor=""><b>Reason:</b> <span style={{ "color": "red" }}>*</span></label>

                    <textarea className='form-control'
                        placeholder="Enter Reason"
                        row='1' cols='4' 
                        onChange={(e) => {
                            setRemarks(e.target.value)
                        }}
                        value={remarks}

                    ></textarea>
                    <div className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" style={{ "float": "right" }} onClick={() => { showRemarksModal() }}>
                        <PlusOutlined />
                    </div>

                    {formValidator.current.message(
                        'reason',
                        remarks,
                        'required',
                        {
                            className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Reason Required',
                            }
                        })}

                </div>
                <br />
                <div className="col-md-12">
                    <label><b> Date: </b> <span style={{ color: "red" }}>*</span></label>
                    <div className="form-group">
                        {/* <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}
                            value={moment(modalData1.date)}


                            onChange={(date, dateString) => {
                                setModalData1({
                                    ...modalData1,
                                    date: dateString
                                })
                            }}



                        /> */}
                        <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%' }} allowClear={false} value={modalData1.date ? moment(modalData1.date) : ''}
                            // value={consolidatedData.from_date}
                            onChange={(date, dateString) => {
                                setModalData1({
                                    ...modalData1,
                                    date: dateString
                                })

                            }}

                        />
                    </div>
                    {formValidator.current.message(
                        'date',
                        modalData1.date,
                        'required',
                        {
                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Date Required',
                            }
                        })}
                </div>
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%', marginTop: '20px' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={OnSubmited}
                    >
                        Submit
                    </Button>
                </div>

            </Modal>


            <Modal title='Enter Password and Reason' visible={modalVisible1} footer={null} className='usr_modal_class'
                onCancel={passwordcancel1}>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor=""><b>Password:</b> <span style={{ "color": "red" }}>*</span></label>
                        <input
                            type="password"
                            id="password"
                            name='password'
                            className="form-control"
                            placeholder="Enter password"
                            value={modalData1.password}
                            onChange={(e) => {
                                setModalData1({
                                    ...modalData1,
                                    password: e.target.value
                                })
                            }} />
                        {formValidator2.current.message(
                            'password',
                            modalData1.password,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required',
                                }
                            })}
                    </div>
                </div>
                <br />
                <div className="col-md-12">
                    <label htmlFor=""><b>Reason:</b> <span style={{ "color": "red" }}>*</span></label>

                    <textarea className='form-control'
                        placeholder="Enter Reason"
                        row='1' cols='4' 
                        onChange={(e) => {
                            setRemarks(e.target.value)
                        }}
                        value={remarks}

                    ></textarea>
                    <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" style={{ "float": "right" }} onClick={() => { showRemarksModal() }}>
                        <PlusOutlined />
                    </span>

                    {formValidator2.current.message(
                        'reason',
                        remarks,
                        'required',
                        {
                            className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Reason Required',
                            }
                        })}

                </div>
                <br />
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%', marginTop: '20px' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={OnSubmittemp}
                    >
                        Submit
                    </Button>
                </div>

            </Modal>


            <Modal title="Remarks List" visible={remarksModal}
                onOk={handleOk}
                onCancel={handleCancel} className="remarksClass" footer={false}>
                <Table
                    className='userprivclass'
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    bordered
                    pagination={{
                        pageSizeOptions: ['10', '30', '50'],
                        defaultPageSize: dataTableProperties.pagesize,
                        showSizeChanger: dataTableProperties.sizechanger,
                        hideOnSinglePage: true
                    }}

                />
            </Modal>
        </Fragment >
    )
}
export default connect(mapStateToProps, {
    getVendorClosureList,
    GetRemarksData,
    saveVendorClosureData,
    saveVendorTempClosureData,
})(VendorClosure);