const url = {
    Login: 'https://13.234.100.64',
    CommonURL: 'https://13.234.100.64',
    VendorURL: 'https://13.234.100.64/cv'
}

export const getUrl = () => {
    if (localStorage.getItem('isVendor') == "true")
        return url.VendorURL
    else
        return url.CommonURL
}

export default url
