import {CRITICALITYREPORT,CRITICALITYREPORTTABLE,SHOWMOREDATALIST} from '../../types/index';

const initialState = {
    criticalityreports: {
        filterdata: [],
        tabledata:[],
        showmorelist:[]
       
    }
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CRITICALITYREPORT: {
            return {
                ...state,
                criticalityreports: {
                    ...state.criticalityreports,
                    filterdata: payload[1],
                },
            };
        }
        case CRITICALITYREPORTTABLE: {
            return {
                ...state,
                criticalityreports: {
                    ...state.criticalityreports,
                    tabledata: payload[1],
                },
            };
        }
        case SHOWMOREDATALIST: {
            console.log(payload[1],"5555555555");
            return {
                ...state,
                criticalityreports: {
                    ...state.criticalityreports,
                    showmorelist: payload[1],
                },
            };
        }
        default:
            return state;
    }
}