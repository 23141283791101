import { UNITLISTREPORT,UNITLISTTABLEREPORT} from './../../types/index'
const initialState = {
    unitlistreports: {
        unitlist: [],
        tabledata:[]
    }
}
export default function (state = initialState, action) {
   
    const { type, payload } = action;
    switch (type) {
        case UNITLISTREPORT: {
            
            return {
                ...state,
                unitlistreports: {
                    ...state.unitlistreports,
                    unitlist: payload,
                },
            };
            
        }
        case UNITLISTTABLEREPORT: {
           console.log(payload,"payload12345");
            return {
                ...state,
                unitlistreports: {
                    ...state.unitlistreports,
                    tabledata: payload,
                },
            };
            
        }
        default:
            return state;
    }
}
