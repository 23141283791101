export const LOGINSUCCESS = 'LOGINSUCCESS'
export const STATOTORYLIST = 'STATOTORYLIST'
export const STATUTORYFILTERDATA = 'STATUTORYFILTERDATA'
export const STATUTORYSEDIT = 'STATUTORYSEDIT'
export const REVIEWLIST = 'REVIEWLIST'
export const REVIEWUNITLIST = 'REVIEWUNITLIST'
export const REASSIGNCOMPLAINCE = 'REASSIGNCOMPLAINCE'
export const REASSIGLISTAPI = 'REASSIGLISTAPI'
export const COMPLETED_TASK_LIST = 'COMPLETED_TASK_LIST'
export const STATUTORY_BY_UNIT = 'STATUTORY_BY_UNIT'
export const COMPLIANCE_APPROVAL_LIST = 'COMPLIANCE_APPROVAL_LIST'
export const COMPLIANCE_APPROVAL_FILTER_LIST = 'COMPLIANCE_APPROVAL_FILTER_LIST'
export const COMPLIANCE_APPROVAL_DOWNLOAD = 'COMPLIANCE_APPROVAL_DOWNLOAD'
export const COMPLIANCE_APPROVAL_REMOVE = 'COMPLIANCE_APPROVAL_REMOVE'
export const RECALLFILTERS = 'RECALLFILTERS'
export const TASKRECALLCOMPLIANCE = 'TASKRECALLCOMPLIANCE'
export const REVIEWSHOWSETTINGLIST = 'REVIEWSHOWSETTINGLIST'
export const ASSIGNCOMPLIANCE = "ASSIGNCOMPLIANCE"
export const UNITDATA = "UNITDATA"
export const ASSIGNEE = 'ASSIGNEE'
export const TABLEDATA = "TABLEDATA"
export const DOMAINDATA = 'DOMAINDATA'
export const ASSIGNCOMPLIANCEO = "ASSIGNCOMPLIANCEO"
export const REASSIGNTABLELISTAPI = 'REASSIGNTABLELISTAPI'
export const REASSIGNTABLELIST = 'REASSIGNTABLELIST'
export const CRITICALITYSETTINGS = 'CRITICALITYSETTINGS'
export const REMARKSDATA = 'REMARKSDATA'
export const UNIT_CLOSURE_LIST = 'UNIT_CLOSURE_LIST'
export const REMARKSMASTER = 'REMARKSMASTER'
export const CRITICALITYLIST = 'CRITICALITYLIST'
// export const TOGGLE_COMMON_LOADER = 'TOGGLE_COMMON_LOADER'
export const SAVEASSIGNCOMPLIANCE = 'SAVEASSIGNCOMPLIANCE'
export const TOGGLE_COMMON_LOADER = 'TOGGLE_COMMON_LOADER'
export const SERVICEPROVIDERLIST = 'SERVICEPROVIDERLIST'
export const REASSIGGETUSERCOMPLIANCE = 'REASSIGGETUSERCOMPLIANCE'
export const REASSIGNSAVECOMPLAINCE = 'REASSIGNSAVECOMPLAINCE'
export const VIEW_ONLY_RESTRICTED_LIST = 'VIEW_ONLY_RESTRICTED_LIST'
export const VIEW_ONLY_RESTRICTED_UNIT_LIST = 'VIEW_ONLY_RESTRICTED_UNIT_LIST'
export const PRIVILEGES = 'PRIVILEGES'
export const APPROVE_TASK_RECALL = 'APPROVE_TASK_RECALL'
export const APPROVE_TASK_RECALL_LIST = 'APPROVE_TASK_RECALL_LIST'
export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS'
export const USERMANAGEMENT_LIST = ' USERMANAGEMENT_LIST'
export const USERMANAGEMENT_CATEGORY_LIST = ' USERMANAGEMENT_CATEGORY_LIST'
export const REVIEWSAVECOMPLIANCE = 'REVIEWSAVECOMPLIANCE'
export const DOWNLOADLIST = 'DOWNLOADLIST'
export const DOWNLOADPATH = 'DOWNLOADPATH'
export const UPLOADCONTENT = 'UPLOADCONTENT'
export const SAVESTATUTORY = 'SAVESTATUTORY'
export const UPLOADERROR = 'UPLOADERROR'
export const GET_BY_UNIT = 'GET_BY_UNIT'
export const SYNCASSIGNCOMPLIANCE = 'SYNCASSIGNCOMPLIANCE'
export const DATALIST = 'DATALIST'
export const QUEUED = 'QUEUED'
export const QUEUEDPATH = 'QUEUEDPATH'
export const QUEUEDSTATUS = 'QUEUEDSTATUS'
export const TABLEDATALIST = 'TABLEDATALIST'
export const SHOWMOREDATA = 'SHOWMOREDATA'
export const UPLOADSUCCESS = 'UPLOADSUCCESS'
export const UNITLIST = 'UNITLIST'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const ESCALATION = 'ESCALATION'
export const REMAINDER = 'REMAINDER'
export const MESSAGE = 'MESSAGE'
export const NOTIFICATIONSHOWALL = 'NOTIFICATIONSHOWALL'
export const CRITICALITYREPORT = 'CRITICALITYREPORT'
export const CATEGORY_DATA = 'CATEGORY_DATA'
export const CONSOLIDATED_REPORT = 'CONSOLIDATED_REPORT'
export const TASKWISE_REPORT = 'TASKWISE_REPORT'
export const UNITWISEREPORT = 'UNITWISEREPORT'
export const USERWISEREPORT = 'USERWISEREPORT'
export const USERWISEFILTERREPORT = 'USERWISEFILTERREPORT'
export const EXPORT_USER_WISE_REPORT = 'EXPORT_USER_WISE_REPORT'
export const TASKWISE_REPORT_DATA = 'TASKWISE_REPORT_DATA'
export const EXPORT_CONSOLIDATED_REPORT = 'EXPORT_CONSOLIDATED_REPORT'
export const UNIT_DATA = 'UNIT_DATA'
export const MONTHWISEREPORT = 'MONTHWISEREPORT'
export const UNITREPORT = 'UNITREPORT'
export const DOMAINREPORT = 'DOMAINREPORT'
export const GET_TABLE_DATA = 'GET_TABLE_DATA'
export const TASK_CATEGORY_REPORT = 'TASK_CATEGORY_REPORT'
export const MONTHWISEREPORTTABLE = 'MONTHWISEREPORTTABLE'
export const MONTHWISEREPORTEXPORT = 'MONTHWISEREPORTEXPORT'
export const CRITICALITYREPORTTABLE = 'CRITICALITYREPORTTABLE'
export const CRITICALITYEXPORT = 'CRITICALITYEXPORT'
export const SHOWMOREDATALIST = 'SHOWMOREDATALIST'
export const EXPORT_DATA = 'EXPORT_DATA'
export const RISK_REPORT = 'RISK_REPORT'
export const FILTER_DATA = 'FILTER_DATA'
export const SERVICE_PROVIDER_DATA = 'SERVICE_PROVIDER_DATA'
export const TASKWISE_EXPORT_DATA = 'TASKWISE_EXPORT_DATA'
export const SERVICE_PROVIDER_EXPORT = 'SERVICE_PROVIDER_EXPORT'
export const DoWNLOAD_CONSOLIDATED_REPORT = 'DoWNLOAD_CONSOLIDATED_REPORT'
export const NOTIFICATIONMODAL = 'NOTIFICATIONMODAL'
export const UNITLISTREPORT = 'UNITLISTREPORT'
export const UNITLISTTABLEREPORT = 'UNITLISTTABLEREPORT'
export const UNITLISTEXPORT = 'UNITLISTEXPORT'
export const REASSIGNREPORT = 'REASSIGNREPORT'
export const GET_DOMAIN_DATA = 'GET_DOMAIN_DATA'
export const GET_LE_TABLE_DATA = 'GET_LE_TABLE_DATA'
export const REASSIGNREPORTSHOW = 'REASSIGNREPORTSHOW'
export const LEGALENTITYREPORT = 'LEGALENTITYREPORT'
export const LEGALENTITYTABLE = 'LEGALENTITYTABLE'
export const LEGALENTITYEXPORT = 'LEGALENTITYREPORT'
export const STATUTORY_UNITWISE_DATA = 'STATUTORY_UNITWISE_DATA'
export const STATUTORY_UNITWISE_REPORT = 'STATUTORY_UNITWISE_REPORT'
export const STATUTORY_UNITWISE_EXPORT = 'STATUTORY_UNITWISE_EXPORT'
export const GETDATA = 'GETDATA'
export const SPTABLEDATA = 'SPTABLEDATA'
export const GET_DOMAIN_CARD_DATA = 'GET_DOMAIN_CARD_DATA'
export const GET_DOMIAN_CARD_TABLE_DATA = 'GET_DOMIAN_CARD_TABLE_DATA'
export const AUDITTRAILREPORTFILTER = 'AUDITTRAILREPORTFILTER'
export const AUDIT_TRAIL_REPORT_SHOW = 'AUDIT_TRAIL_REPORT_SHOW'
export const NOTIFICATIONREPORT = 'NOTIFICATIONREPORT'
export const NOTIFICATIONTABLEREPORT = 'NOTIFICATIONTABLEREPORT'
export const ASSIGNEESCOREFILTER = 'ASSIGNEESCOREFILTER'
export const ASSIGNEESCORETABLE = 'ASSIGNEESCORETABLE'
export const APPROVERSCORECARDDATA = 'APPROVERSCORECARDDATA'
export const APPROVERSCORECARDTABLEDATA = 'APPROVERSCORECARDTABLEDATA'
export const AUDIT_TRAIL_REPORT_EXPORT = 'AUDIT_TRAIL_REPORT_EXPORT'
export const LOGINTRACEREPORTFILTER = 'LOGINTRACEREPORTFILTER'
export const LOGIN_TRACE_REPORT_SHOW = 'LOGIN_TRACE_REPORT_SHOW'
export const TASK_HISTORY_REPORT = 'TASK_HISTORY_REPORT'
export const TASK_HISTORY_EXPORT = 'TASK_HISTORY_EXPORT'
export const TASKHISTORY_DATA = 'TASKHISTORY_DATA'
export const AUDIT_TRAIL_REPORT_EXPORT_RESET = 'AUDIT_TRAIL_REPORT_EXPORT_RESET'
export const PROFILE = 'PROFILE'
export const UPDATEPROFILE = 'UPDATEPROFILE'
export const LOGIN_TRACE_REPORT_EXPORT = 'LOGIN_TRACE_REPORT_EXPORT'
export const LOGIN_TRIAL_REPORT_EXPORT_RESET = 'LOGIN_TRIAL_REPORT_EXPORT_RESET'
export const SETTINGSDATA = 'SETTINGSDATA'
export const SERVICE_PROVIDER_REMARKS = 'SERVICE_PROVIDER_REMARKS'
export const SETTINGSDATALIST = 'SETTINGSDATALIST'
export const SETTINGSDATATABLE = 'SETTINGSDATATABLE'
export const CRITICALITY_CHART = 'CRITICALITY_CHART'
export const COMMON_DATA = 'COMMON_DATA'
export const COMPLIANCE_STATUS_CHART = 'COMPLIANCE_STATUS_CHART'
export const CRITICALITY_CHART_TABLE_DATA = 'CRITICALITY_CHART_TABLE_DATA'
export const COMPLIANCE_TREND_CHART = 'COMPLIANCE_TREND_CHART'
export const COMPLIANCE_TREND_TABLE_DATA = 'COMPLIANCE_TREND_TABLE_DATA'
export const GET_CRITICALITY_STATUS = 'GET_CRITICALITY_STATUS'
export const COMPLIANCE_STATUS_TABLE = 'COMPLIANCE_STATUS_TABLE'
export const TASKDISTRIBUTION_CHART = 'TASKDISTRIBUTION_CHART'
export const TASK_DISTRIBUTION_TABLE = 'TASK_DISTRIBUTION_TABLE'
export const RISK_CHART = 'RISK_CHART'
export const RISK_CHART_TABLE_DATA = 'RISK_CHART_TABLE_DATA'
export const RISK_CHART_DELAYED_TABLE_DATA = 'RISK_CHART_DELAYED_TABLE_DATA'
export const OVERDUE_CHART = 'OVERDUE_CHART'
export const OVERDUE_CHART_TABLE_DATA = 'OVERDUE_CHART_TABLE_DATA'
export const TIME_ZONE_DATA = 'TIME_ZONE_DATA'
export const REMARKS_HISTORY_DATA = 'REMARKS_HISTORY_DATA'
export const COMPARATIVE_TRENT_CHART = 'COMPARATIVE_TRENT_CHART'
export const ASSIGNEE_WISE_COMPLIANCE = 'ASSIGNEE_WISE_COMPLIANCE'
export const ASSIGNEE_WISE_COMPLIANCE_SHOW = 'ASSIGNEE_WISE_COMPLIANCE_SHOW'
export const ASSIGNEE_WISE_COMPLIANCE_DRILL_DOWN = 'ASSIGNEE_WISE_COMPLIANCE_DRILL_DOWN'
export const ASSIGNEE_YEAR_WISE_COMPLIANCE = 'ASSIGNEE_YEAR_WISE_COMPLIANCE'
export const ASSIGNEE_WISE_COMPLIANCE_EXPORT = 'ASSIGNEE_WISE_COMPLIANCE_EXPORT'
export const ASSIGNEE_WISE_COMPLIANCE_EXPORT_RESET = 'ASSIGNEE_WISE_COMPLIANCE_EXPORT_RESET'
export const SHOW_MORE_TABLE_DATA = 'SHOW_MORE_TABLE_DATA'
export const VCSFILTERS = 'VCSFILTERS'
export const LOGINVENDOR = 'LOGINVENDOR'
export const VCSEXPORT = 'VCSEXPORT'
export const VENDOR_COMPLIANCE_SCORE_RESET = "VENDOR_COMPLIANCE_SCORE_RESET"
export const VENDORTEMPFILTER = 'VENDORTEMPFILTER'
export const VENDORTEMPTABLE = 'VENDORTEMPTABLE'
export const MONTHLYCOMPLIANCETABLEDATA = 'MONTHLYCOMPLIANCETABLEDATA'
export const MONTHLYCOMPLIANCEFILTER = 'MONTHLYCOMPLIANCEFILTER'
export const CVMONTHWISEREPORT = 'CVMONTHWISEREPORT'
export const COMPLIANCE_CERTIFICATE_FILTER = 'COMPLIANCE_CERTIFICATE_FILTER'
export const COMPLIANCE_CERTIFICATE_TABLE = 'COMPLIANCE_CERTIFICATE_TABLE'
export const COMPLIANCE_STATUS_MODAL = 'COMPLIANCE_STATUS_MODAL'
export const DETAILS_MODAL = 'DETAILS_MODAL'
export const MAPPING_DETAILS = 'MAPPING_DETAILS'
export const LABEL_LIST = 'LABEL_LIST'
export const SEARCH_COMPLIANCE = 'SEARCH_COMPLIANCE'
export const DOWNLOAD_COMPLIANCE = 'DOWNLOAD_COMPLIANCE'
export const RISK_DOWNLOAD = 'RISK_DOWNLOAD'
export const CVMONTHWISEREPORTTABLE = 'CVMONTHWISEREPORTTABLE'
export const CVMONTHWISEREPORTEXPORT = 'CVMONTHWISEREPORTEXPORT'
export const CV_MONTH_WISE_REPORT_EXPORT_RESET = 'CV_MONTH_WISE_REPORT_EXPORT_RESET'
export const CERTIFICATE_COMPLIANCE_WISE_REPORT = 'CERTIFICATE_COMPLIANCE_WISE_REPORT'
export const CERTIFICATE_COMPLIANCE_WISE_REPORT_SHOW = 'CERTIFICATE_COMPLIANCE_WISE_REPORT_SHOW'
export const CERTIFICATELABELLIST = 'CERTIFICATELABELLIST'
export const CERTIFICATE_PDF = 'CERTIFICATE_PDF'
export const PDF_RESET = 'PDF_RESET'
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
export const VENDOR_CLOSURE_LIST = 'VENDOR_CLOSURE_LIST'
export const APPROVE_COMPLIANCE_CERTIFICATE_FILTER = 'APPROVE_COMPLIANCE_CERTIFICATE_FILTER'
export const APPROVE_COMPLIANCE_CERTIFICATE_TABLE = 'APPROVE_COMPLIANCE_CERTIFICATE_TABLE'
export const APPROVE_COMPLIANCE_STATUS_MODAL = 'APPROVE_COMPLIANCE_STATUS_MODAL'
export const APPROVE_DETAILS_MODAL = 'APPROVE_DETAILS_MODAL'
export const FILE_DOWNLOAD = 'FILE_DOWNLOAD'
export const CV_COMPLIANCE_CERTIFICATE_EXPORT = 'CV_COMPLIANCE_CERTIFICATE_EXPORT'
export const CV_COMPLIANCE_CERTIFICATE_EXPORT_RESET = 'CV_COMPLIANCE_CERTIFICATE_EXPORT_RESET'
export const CERTIFICATE_LEVEL_LIST = 'CERTIFICATE_LEVEL_LIST'
export const MAIL_MAPPING = 'MAIL_MAPPING'
export const CERTIFICATE_LEVEL_ADD = 'CERTIFICATE_LEVEL_ADD'
export const CERTIFICATE_REMARKS_DATA = 'CERTIFICATE_REMARKS_DATA'
export const MAIL_MAPPING_SHOW = 'MAIL_MAPPING_SHOW'
export const CERTIFICATE_MAIL_DATA = 'CERTIFICATE_MAIL_DATA'
export const REMARK_HISTORY_MODAL = 'REMARK_HISTORY_MODAL'
export const DOWNLOADDATA = 'DOWNLOADDATA'
export const EMAIL_SAVE = 'EMAIL_SAVE'
export const VERIFY_PASSWORD = 'VERIFY_PASSWORD'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const USERMANAGEMENT_HAVE_COMPLIANCE = 'USERMANAGEMENT_HAVE_COMPLIANCE'
export const SETTINGSAPPROVALDATA = 'SETTINGSAPPROVALDATA'
export const INVALID_PASSWORD_STATUS = 'INVALID_PASSWORD_STATUS'
export const INVALID_PASSWORD_RESET = 'INVALID_PASSWORD_RESET'
export const SETTINGAPPROVERDATA = 'SETTINGAPPROVERDATA'
export const LOGINLITIGATION = 'LOGINLITIGATION'
export const COMPLIANCE_FILTER = 'COMPLIANCE_FILTER'
export const COMPLIANCE_FILTER_SHOW = 'COMPLIANCE_FILTER_SHOW'
export const COMPLIANCE_VIEW = 'COMPLIANCE_VIEW'
export const LOGINCOMPFIEPRODUCT = 'LOGINCOMPFIEPRODUCT'
export const TASKRECALLCOMPLIANCESHOWMORE = 'TASKRECALLCOMPLIANCESHOWMORE'
export const COMPLIANCE_TASK_DETAILS_DATA = 'COMPLIANCE_TASK_DETAILS_DATA'
export const COMPLIANCE_TASK_CALENDER_VIEW = 'COMPLIANCE_TASK_CALENDER_VIEW'
export const COMPLIANCE_CURRENT_TASK = 'COMPLIANCE_CURRENT_TASK'
export const COMPLIANCE_UPCOMING_TASK = 'COMPLIANCE_UPCOMING_TASK'
export const COMMOM_WIDGET_DATA = 'COMMON_WIDGET_DATA'
export const COMMOM_WIDGET_DATA1 = 'COMMOM_WIDGET_DATA1'
export const WIDGETS1 = 'WIDGETS1'
export const WIDGETS2 = 'WIDGETS2'
export const WIDGETS3 = 'WIDGETS3'
export const WIDGETS4 = 'WIDGETS4'
export const WIDGETS5 = 'WIDGETS5'
export const WIDGETS6 = 'WIDGETS6'
export const WIDGETS7 = 'WIDGETS7'
export const DOMAIN_SCORE_DATA = 'DOMAIN_SCORE_DATA'
export const USER_SCORE_CARD = 'USER_SCORE_CARD'
export const CALENDER_DATA = 'CALENDER_DATA'
export const COMPFIELAW_DATA = 'COMPFIELAW_DATA'
export const NEXT_RECORD = 'NEXT_RECORD'
export const CHANGE_THEME = 'CHANGE_THEME'
export const PASSWRD_MODAL_HANDLE = 'PASSWRD_MODAL_HANDLE'
export const RESET_VIEW_HISTORY = 'RESET_VIEW_HISTORY'
export const RESET_REMARK_HISTORY = 'RESET_REMARK_HISTORY'
export const SAVE_WIDGET_DATA = 'SAVE_WIDGET_DATA'
