import React from 'react'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Progress, Table, Tooltip, Modal, Descriptions, Empty } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, Fragment } from 'react';
import { ExclamationCircleTwoTone, InfoCircleOutlined, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { getFilterValue, getConsolidatedReportData, getConsolidatedExport, downloadConsolidatedReport } from '../../../../src/Store/Action/Report/ConsolidatedReports';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import SimpleReactValidator from "simple-react-validator";
import moment from 'moment';
import { getUnitData } from '../../../Store/Action/Report/TaskWiseReport';

const mapStateToProps = (state) => ({
    filterValue: state.ConsolidatedReports.ConsolidatedReports
})
const ConsolidatedReport = (({
    getFilterValue,
    filterValue,
    getConsolidatedReportData,
    getConsolidatedExport,
    downloadConsolidatedReport
}) => {
    console.log(filterValue, 'filterValue');
    const _ = require("lodash");
    const validator = useRef(new SimpleReactValidator());
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [isModalVisible, setIsModalVisible] = useState(false);
    let [index, setIndex] = useState(0)
    let [serialNo, setSerialNo] = useState(1)
    const [childCompliceId, setChildComplinaceId] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [complianceID, setComplianceID] = useState([])
    const [totalCount, setTotal] = useState(50)
    const [array, setArray] = useState([])
    const [downloadclick, setDownloadClick] = useState(false)
    const [uniqueData, setUniqueData] = useState([])
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [statkey, setStatKey] = useState("0")
    const [key, setKey] = useState("1")
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 7, 8, 11, 12, 32, 35])
    const [exportButton, setExportButton] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [titledata, Settitledata] = useState()
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [consolidatedData, setConsolidatedData] = useState({
        country: "",
        category: "",
        user_type: "All",
        legel_entity: "",
        legal_entity_name: '',
        unit: '',
        user: '',
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "All",
        compliance_frequency: "",
        compliance_task_status: "All",
        division: "",
        domainName: '',
        countryName: '',
        entityname: ''

    })
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const country_info = sessionParsedValue.country_info
    const [data, setData] = useState({
        le_name: '',
        domaindata: '',
        country: ''
    })
    const sessionArr = sessionParsedValue.entity_info
    const [domain, setDomain] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })

    const [division, setDivision] = useState([])
    const [taskCategory, setaskCategory] = useState([])
    const [Acts, setActs] = useState([])
    const [SubtaskCategory, setSubtaskCategory] = useState([])
    const [userData, setUserData] = useState([])
    console.log(userData, 'userData');
    const [frequency, setfrequency] = useState([])
    const [category, setCategoryList] = useState([])
    const [unitcode, setUnitcode] = useState([])
    const [units, setUnits] = useState([])
    const { Panel } = Collapse;
    const { Option } = Select;
    const [disableButton, setDisableButton] = useState(false)

    console.log(checkboxValue,'checkboxValue');

    const onshow = () => {
        setAddFormSubmit(true)
        Settitledata([])
        setfilteredArrayState([])
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatusReportConsolidated",
                        {
                            "c_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
                            "le_id": entityid,
                            "d_id": Number(consolidatedData.domain),
                            "task_category_id": consolidatedData.task_category ? Number(consolidatedData.task_category) : 0,
                            "task_sub_category_id": consolidatedData.task_sub_category ? Number(consolidatedData.task_sub_category) : 0,
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : null,
                            "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                            "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                            "frequency_id": consolidatedData.compliance_frequency ? Number(consolidatedData.compliance_frequency) : 0,
                            "user_type_id": consolidatedData.user_type ? Number(consolidatedData.user_type) : 0,
                            "status_name": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
                            "usr_id": consolidatedData.user ? Number(consolidatedData.user) : null,
                            "from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                            "to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                            "csv": false,
                            "f_count": 1,
                            "t_count": 25,
                            "count_qry": true,
                            "list_check": null
                        }
                    ]
                }
            ]
            getConsolidatedReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onClose = () => {
        setOpen(false);
    };
    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }

    };

    console.log(filteredArrayState, 'filteredArrayState');

    const Activate = () => {

        Swal.fire({
            title: 'Are You Sure?',
            text: `Total Selected Tasks : ${complianceID.length}`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                showModal()


                // Swal.fire('Closed.!', 'Closed...', 'success');
            }
        });
    };

    const handleOk = () => {
        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid() && downloadclick != true) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatusReportConsolidated",
                        {
                            "c_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
                            "le_id": entityid,
                            "d_id": Number(consolidatedData.domain),
                            "task_category_id": consolidatedData.task_category ? Number(consolidatedData.task_category) : 0,
                            "task_sub_category_id": consolidatedData.task_sub_category ? Number(consolidatedData.task_sub_category) : 0,
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : null,
                            "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                            "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                            "frequency_id": consolidatedData.compliance_frequency ? Number(consolidatedData.compliance_frequency) : 0,
                            "user_type_id": consolidatedData.user_type ? Number(consolidatedData.user_type) : 0,
                            "status_name": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
                            "usr_id": consolidatedData.user ? Number(consolidatedData.user) : null,
                            "from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                            "to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                            "csv": true,
                            "f_count": 1,
                            "t_count": 25,
                            "count_qry": true,
                            "list_check": checkboxValue
                        }
                    ]

                }
            ]
            getConsolidatedExport({
                payload: payload,
                paramid: paramid
            })
        }
        if (downloadclick === true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetBulkDocumentsDownload",
                        {
                            "c_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
                            "le_id": entityid,
                            "d_id": Number(consolidatedData.domain),
                            "task_category_id": consolidatedData.task_category ? Number(consolidatedData.task_category) : 0,
                            "task_sub_category_id": consolidatedData.task_sub_category ? Number(consolidatedData.task_sub_category) : 0,
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : null,
                            "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                            "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                            "frequency_id": consolidatedData.compliance_frequency ? Number(consolidatedData.compliance_frequency) : 0,
                            "user_type_id": consolidatedData.user_type ? Number(consolidatedData.user_type) : 0,
                            "status_name": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
                            "usr_id": consolidatedData.user ? Number(consolidatedData.user) : null,
                            "from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                            "to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                            "ch_ids": complianceID,
                            "csv_bulk": true,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            downloadConsolidatedReport({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
        setCheckBoxValue([1, 2, 7, 8, 11, 12, 32, 35])
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalVisible(false)
        setCheckBoxValue([1, 2, 7, 8, 11, 12, 32, 35])

    };
    const chilData = (id, unitId) => {
        setIsModalVisible(true)
        if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length > 25) {
            let tempArr = []
            for (let i in filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList) {
                if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList[i].compliance_id == id) {
                    if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList[i].unit_id == unitId) {
                        tempArr.push(filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList[i])
                    }
                }
            }
            setChildComplinaceId(tempArr)

        }
    }

    const downloadcheckBoxOnChange = (e, item) => {
        let temp = complianceID
        let tempIds = []
        if (temp.includes(Number(item.compliance_id)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(item.compliance_id)) {
                    temp.splice(i, 1)
                }
            }
            setComplianceID([...temp])
        } else {
            tempIds.push(Number(item.compliance_id))
            setComplianceID([...complianceID, ...tempIds])
        }
    }
    console.log(filterValue.ConsolidatedReportList,);
    const downloadselectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('checked');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                }
            }
            let ids = filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList.map((item) => {
                return item.compliance_id
            })
            setComplianceID(ids)
        }
        else {
            var ele = document.getElementsByName('checked');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setComplianceID([])
        }
    }

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([])
        }
    }
    useEffect(() => {
        if (pageState == true) {

            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatusReportConsolidated",
                        {
                            "c_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
                            "le_id": entityid,
                            "d_id": Number(consolidatedData.domain),
                            "task_category_id": consolidatedData.task_category ? Number(consolidatedData.task_category) : 0,
                            "task_sub_category_id": consolidatedData.task_sub_category ? Number(consolidatedData.task_sub_category) : 0,
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : null,
                            "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                            "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                            "frequency_id": consolidatedData.compliance_frequency ? Number(consolidatedData.compliance_frequency) : 0,
                            "user_type_id": consolidatedData.user_type ? Number(consolidatedData.user_type) : 0,
                            "status_name": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
                            "usr_id": consolidatedData.user ? Number(consolidatedData.user) : null,
                            "from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                            "to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                            "csv": false,
                            "f_count": Math.max(((current - 1) * pageSize), 0) + 1,
                            "t_count": current != 0 ? current * pageSize : pageSize,
                            "count_qry": true,
                            "list_check": null
                        }
                    ]
                }
            ]
            getConsolidatedReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])



    useEffect(() => {
        let tempArr = []
        if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length > 25) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length && item.map((items) => [items["compliance_id"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.compliance_id
                })

                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { compliance_id: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })

            setUniqueData(tempArr)

        }
    }, [filteredArrayState])
    const columns = [
        {
            title: 'User Name',
            ellipsis: true,
            width: '50px',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: 'Activity Status',
            ellipsis: true,
            width: '50px',
            dataIndex: 'activity_status',
            key: 'activity_status',
        },
        {
            title: 'Activity Date',
            ellipsis: true,
            width: '40px',
            dataIndex: 'activity_on',
            key: 'activity_on',
        },
        {
            title: 'Task Completion Date/Document issued Date',
            ellipsis: true,
            width: '120px',
            align: 'center',
            dataIndex: 'completion_date',
            key: 'completion_date',
        },

    ];

    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidatedData.legel_entity)
        }
    }, [entityid])

    useEffect(() => {
        if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList) {
            setArray([...array, ...filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList])
        }
    }, [filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList])

    useEffect(() => {
        if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length > 0) {
            let uniqueObjArray = [...new Map(filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [filterValue && filterValue.ConsolidatedReportList])
    const onChange = (key) => {
        console.log(key);
    };
    const onStatChange = (key) => {
        console.log(key);
    }
    const downloadOnChange = () => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetBulkDocumentsDownload",
                    {
                        "c_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
                        "le_id": entityid,
                        "d_id": Number(consolidatedData.domain),
                        "task_category_id": consolidatedData.task_category ? Number(consolidatedData.task_category) : 0,
                        "task_sub_category_id": consolidatedData.task_sub_category ? Number(consolidatedData.task_sub_category) : 0,
                        "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                        "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                        "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : null,
                        "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                        "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                        "frequency_id": consolidatedData.compliance_frequency ? Number(consolidatedData.compliance_frequency) : 0,
                        "user_type_id": consolidatedData.user_type ? Number(consolidatedData.user_type) : 0,
                        "status_name": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
                        "usr_id": consolidatedData.user ? Number(consolidatedData.user) : null,
                        "from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                        "to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                        "ch_ids": complianceID,
                        "csv_bulk": true,
                        "list_check": checkboxValue
                    }
                ]
            }
        ]
        downloadConsolidatedReport({
            payload: payload,
            paramid: paramid
        })
    }
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatusReportConsolidatedFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null' && entityid !== '') {
                getFilterValue({
                    payload: payload,
                    paramid: paramid
                })
            }

        }
    }, [isAuth, authtoken, paramid, entityid])
    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        if (filteredArray.length) {
            let temp = []
            for (let i in filteredArray) {
                console.log(i, 'aishuuu');
                temp = filteredArray[i]
            }
            setfilteredArrayState([...temp])
        }

    }, [filteredArray])
    useEffect(() => {
        if (filterValue) {
            setaskCategory(filterValue && filterValue.Data && filterValue.Data.task_category_list)
            setDomain(filterValue && filterValue.Data && filterValue.Data.domains)
            setActs(filterValue && filterValue.Data && filterValue.Data.acts)
            setfrequency(filterValue && filterValue.Data && filterValue.Data.compliance_frequency)
            setCategoryList(filterValue && filterValue.Data && filterValue.Data.categories_list)
            setSubtaskCategory(filterValue && filterValue.Data && filterValue.Data.task_sub_category_list)
            setUnitcode(filterValue && filterValue.Data && filterValue.Data.unit_code_label_list)
            setUnits(filterValue && filterValue.Data && filterValue.Data.units)
            setDivision(filterValue && filterValue.Data && filterValue.Data.divisions_list)
            setUserData(filterValue && filterValue.Data && filterValue.Data.legal_entity_users)
        }


    }, [filterValue])
    const addAllSelectedDataValues = (checked) => {

    }
    useEffect(() => {

        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setConsolidatedData({
                ...consolidatedData,
                legel_entity: localStorage.getItem('SelectedEntityid')

            })

        }

    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let group = []
        if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length > 25) {

            // setTempeditdata(editdata)
            if (uniqueData && uniqueData.length > 0) {
                const ab = uniqueData && uniqueData.length && uniqueData.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })

                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        uniqueData && uniqueData.length && uniqueData.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "act_name": child.act_name,
                                    "activity_on": child.activity_on,
                                    "activity_status": child.activity_status,
                                    "category_name": child.category_name,
                                    "completion_date": child.completion_date,
                                    "compliance_activity_id": child.compliance_activity_id,
                                    "compliance_description": child.compliance_description,
                                    "compliance_history_id": child.compliance_history_id,
                                    "compliance_id": child.compliance_id,
                                    "compliance_name": child.compliance_name,
                                    "criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    "doc_ref_num": child.doc_ref_num,
                                    "due_date": child.due_date,
                                    "extend_due_date": child.extend_due_date,
                                    "frequency_name": child.frequency_name,
                                    "history_count": child.history_count,
                                    "interim_count": child.interim_count,
                                    "legal_entity_id": child.legal_entity_id,
                                    "start_date": child.start_date,
                                    "task_group_name": child.task_group_name,
                                    "task_status": child.task_status,
                                    "task_sub_group_name": child.task_sub_group_name,
                                    "tz_name": child.tz_name,
                                    "unit": child.unit,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    "uploaded_document": child.uploaded_document,
                                    "user_name": child.user_name,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)

            }
        }
        else {
            let group = []
            if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length > 0) {
                const ab = filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })
                const group123 = filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList.map(function (item) {
                    return item.task_group_name
                })
                // const Gfliter = group123.map((item)=>{
                //     return item.task_group_name
                // })
                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "act_name": child.act_name,
                                    "activity_on": child.activity_on,
                                    "activity_status": child.activity_status,
                                    "category_name": child.category_name,
                                    "completion_date": child.completion_date,
                                    "compliance_activity_id": child.compliance_activity_id,
                                    "compliance_description": child.compliance_description,
                                    "compliance_history_id": child.compliance_history_id,
                                    "compliance_id": child.compliance_id,
                                    "compliance_name": child.compliance_name,
                                    "criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    "doc_ref_num": child.doc_ref_num,
                                    "due_date": child.due_date,
                                    "extend_due_date": child.extend_due_date,
                                    "frequency_name": child.frequency_name,
                                    "history_count": child.history_count,
                                    "interim_count": child.interim_count,
                                    "legal_entity_id": child.legal_entity_id,
                                    "start_date": child.start_date,
                                    "task_group_name": child.task_group_name,
                                    "task_status": child.task_status,
                                    "task_sub_group_name": child.task_sub_group_name,
                                    "tz_name": child.tz_name,
                                    "unit": child.unit,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    "uploaded_document": child.uploaded_document,
                                    "user_name": child.user_name,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp,
                        group_name: group123
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)

            }

        }

    }, [filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList, uniqueData])

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    // useEffect(() => {
    //     // setTempeditdata(editdata)
    //     let array = [];
    //     let finalarray = [];
    //     console.log(filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList, 'filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList');
    //     let datavalue = [];
    //     if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList > 25) {
    //         // setTempeditdata(editdata)
    //         if (uniqueData && uniqueData.length > 0) {
    //             const ab = uniqueData && uniqueData.length && uniqueData.map(function (item) {
    //                 if ((array.indexOf(item.act_name) < 0))
    //                     array.push(item.act_name)
    //             })

    //             console.log(array, 'array9999999999');
    //             array.map((data, i) => {
    //                 let temp = [];
    //                 let entityArray = [];
    //                 let datavalue = [];
    //                 if (data) {
    //                     uniqueData && uniqueData.length && uniqueData.map((child, childIndex) => {
    //                         if (data === child.act_name) {
    //                             entityArray = {
    //                                 "act_name": child.act_name,
    //                                 "activity_on": child.activity_on,
    //                                 "activity_status": child.activity_status,
    //                                 "category_name": child.category_name,
    //                                 "completion_date": child.completion_date,
    //                                 "compliance_activity_id": child.compliance_activity_id,
    //                                 "compliance_description": child.compliance_description,
    //                                 "compliance_history_id": child.compliance_history_id,
    //                                 "compliance_id": child.compliance_id,
    //                                 "compliance_name": child.compliance_name,
    //                                 "criticality_name": child.criticality_name,
    //                                 "division_name": child.division_name,
    //                                 "doc_ref_num": child.doc_ref_num,
    //                                 "due_date": child.due_date,
    //                                 "extend_due_date": child.extend_due_date,
    //                                 "frequency_name": child.frequency_name,
    //                                 "history_count": child.history_count,
    //                                 "interim_count": child.interim_count,
    //                                 "legal_entity_id": child.legal_entity_id,
    //                                 "start_date": child.start_date,
    //                                 "task_group_name": child.task_group_name,
    //                                 "task_status": child.task_status,
    //                                 "task_sub_group_name": child.task_sub_group_name,
    //                                 "tz_name": child.tz_name,
    //                                 "unit": child.unit,
    //                                 "unit_address": child.unit_address,
    //                                 "unit_id": child.unit_id,
    //                                 "uploaded_document": child.uploaded_document,
    //                                 "user_name": child.user_name,
    //                                 'childId': childIndex,
    //                                 'parentId': i
    //                             }
    //                             temp.push(entityArray)
    //                         }
    //                     })
    //                 }

    //                 datavalue = {
    //                     index: i,
    //                     parent: data,
    //                     child: temp
    //                 }
    //                 finalarray.push(datavalue)
    //             })
    //             Settitledata(finalarray)

    //         }
    //     }
    //     else {
    //         if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList) {
    //             const ab = filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList.map(function (item) {
    //                 if ((array.indexOf(item.act_name) < 0))
    //                     array.push(item.act_name)
    //             })

    //             console.log(array, 'array');
    //             array.map((data, i) => {
    //                 let temp = [];
    //                 let entityArray = [];
    //                 let datavalue = [];
    //                 if (data) {
    //                     filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList.map((child, childIndex) => {
    //                         if (data === child.act_name) {
    //                             entityArray = {
    //                                 "act_name": child.act_name,
    //                                 "activity_on": child.activity_on,
    //                                 "activity_status": child.activity_status,
    //                                 "category_name": child.category_name,
    //                                 "completion_date": child.completion_date,
    //                                 "compliance_activity_id": child.compliance_activity_id,
    //                                 "compliance_description": child.compliance_description,
    //                                 "compliance_history_id": child.compliance_history_id,
    //                                 "compliance_id": child.compliance_id,
    //                                 "compliance_name": child.compliance_name,
    //                                 "criticality_name": child.criticality_name,
    //                                 "division_name": child.division_name,
    //                                 "doc_ref_num": child.doc_ref_num,
    //                                 "due_date": child.due_date,
    //                                 "extend_due_date": child.extend_due_date,
    //                                 "frequency_name": child.frequency_name,
    //                                 "history_count": child.history_count,
    //                                 "interim_count": child.interim_count,
    //                                 "legal_entity_id": child.legal_entity_id,
    //                                 "start_date": child.start_date,
    //                                 "task_group_name": child.task_group_name,
    //                                 "task_status": child.task_status,
    //                                 "task_sub_group_name": child.task_sub_group_name,
    //                                 "tz_name": child.tz_name,
    //                                 "unit": child.unit,
    //                                 "unit_address": child.unit_address,
    //                                 "unit_id": child.unit_id,
    //                                 "uploaded_document": child.uploaded_document,
    //                                 "user_name": child.user_name,
    //                                 'childId': childIndex,
    //                                 'parentId': i
    //                             }
    //                             temp.push(entityArray)
    //                         }
    //                     })
    //                 }

    //                 datavalue = {
    //                     index: i,
    //                     parent: data,
    //                     child: temp
    //                 }
    //                 finalarray.push(datavalue)
    //             })
    //             Settitledata(finalarray)

    //         }
    //     }

    // }, [filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList, uniqueData])
    const addSelectedDataValues = (checked, u_id, act_name) => {

    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    const disablecondition = (e) => {
        var currentToDate = moment(consolidatedData.from_date).add(3, 'months')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }

    console.log(index, 'snehaa');

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-0 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Consolidated Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    {complianceID.length > 0 ?
                        <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }} icon={<DownloadOutlined />}
                                // onClick={downloadOnChange}
                                onClick={() => {
                                    Activate()
                                    // showModal()
                                    setDownloadClick(true)
                                }}
                            >
                                Download
                            </Button>
                        </div>
                        : false}
                </div>
            </div>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" id='only-restricted' style={{ position: 'relative', padding: "15px 5px" }}>

                            <Collapse className='report-collapse' style={{ backgroundColor: '#5daae3' }} accordion defaultActiveKey={["1"]} onChange={onChange} >
                                <Panel header=" Consolidated Report" key={key}
                                // extra={tableshow == true ? genextra() : false}
                                >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <div className="form-group">

                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            value={consolidatedData.country || undefined}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            onChange={(data, value) => {
                                                                if (data != undefined) {
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        country: data,
                                                                        countryName: value.children,
                                                                        category: "",
                                                                        user_type: "All",
                                                                        legel_entity: '',
                                                                        unit: '',
                                                                        user: '',
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "All",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: '',
                                                                        entityname: ''
                                                                    })
                                                                }
                                                                else {
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        country: '',
                                                                        countryName: '',
                                                                        category: "",
                                                                        user_type: "All",
                                                                        legel_entity: '',
                                                                        unit: '',
                                                                        user: '',
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "All",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: '',
                                                                        entityname: ''
                                                                    })
                                                                }

                                                            }
                                                            }
                                                            // value={compfie.user_Group}
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                        >

                                                            {country_info && country_info.length && country_info.map((item, i) => {

                                                                return (

                                                                    <Option key={item.c_id}>

                                                                        {item.c_name}

                                                                    </Option>

                                                                );

                                                            })}
                                                            {/* );
                                                            })} */}
                                                        </Select>

                                                        {validator.current.message(
                                                            'Country',
                                                            consolidatedData.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{sessionArr && sessionArr.length && sessionArr[0].c_name}</p>
                                                }


                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Category : </b></label>
                                                <Select

                                                    allowClear={true}
                                                    size="default"
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    placeholder="Enter Category"
                                                    value={consolidatedData.category || undefined}
                                                    onChange={(data) => {
                                                        if (data != undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                category: data
                                                            })
                                                            let unitData = _.filter(filterValue && filterValue.Data && filterValue.Data.units && filterValue && filterValue.Data && filterValue.Data.units.length > 0 && filterValue && filterValue.Data && filterValue.Data.units, { category_id: Number(data) })
                                                            setUnits(unitData)
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                category: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    {category && category.length && category.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>User Type : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    defaultValue="All"
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Usertype"
                                                    value={consolidatedData.user_type || undefined}
                                                    onChange={(data) => {
                                                        if (data != undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user_type: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user_type: ''
                                                            })
                                                        }
                                                    }
                                                    }

                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >

                                                    <Option key="0">
                                                        All
                                                    </Option>
                                                    <Option key="1">
                                                        Assignee
                                                    </Option>
                                                    <Option key="2">
                                                        Concurrence
                                                    </Option>
                                                    <Option key="3">
                                                        Approval
                                                    </Option>

                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity :  </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <div className="form-group">

                                                        <Select
                                                            disabled={consolidatedData.country == '' ? true : false}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Legal Entity"
                                                            onChange={(data, value) => {
                                                                // setCurrentEntity(data)
                                                                if (data != undefined) {
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        legel_entity: data,
                                                                        category: "",
                                                                        user_type: "All",
                                                                        unit: '',
                                                                        user: '',
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "All",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: '',
                                                                        entityname: ''
                                                                    })
                                                                    setData({
                                                                        ...data,
                                                                        le_name: value.children
                                                                    })
                                                                    setCurrentEntity(data)
                                                                }
                                                                else {
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        legel_entity: '',
                                                                        category: "",
                                                                        user_type: "All",
                                                                        unit: '',
                                                                        user: '',
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "All",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: '',
                                                                        entityname: ''
                                                                    })
                                                                    setData({
                                                                        ...data,
                                                                        le_name: ''
                                                                    })
                                                                    setCurrentEntity('')
                                                                }
                                                            }}
                                                            value={consolidatedData.legel_entity || undefined}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                        >
                                                            [
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}


                                                        </Select>

                                                        {validator.current.message(
                                                            'legalEntity',
                                                            consolidatedData.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }



                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Unit : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Unit"
                                                    value={consolidatedData.unit || undefined}
                                                    onChange={(data) => {
                                                        if (data != undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                unit: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                unit: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    {units && units.length > 0 && units.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {item.unit_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            {/* <div className='col-md-4'>
                                                <label><b>User : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter User"
                                                    value={consolidatedData.user || undefined}
                                                    onChange={(data) => {
                                                        if (data != undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    style={{ width: '100%' }}

                                                >
                                                    {/* <Option key="0">All</Option> */}
                                            {/* {userData && userData.length > 0 && userData.map((item, i) => {
                                                        // return (
                                                        <Option key={item.user_id}>
                                                            {item.employee_name}
                                                        </Option>
                                                        // );
                                                    })}

                                                </Select>
                                            </div> */}
                                            <div className='col-md-4'>
                                                <label><b>User : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    allowClear={true}
                                                    value={consolidatedData.user || undefined}
                                                    size="default"
                                                    placeholder="Enter User"
                                                    onChange={(data) => {
                                                        if (data != undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    <Option key="1">Administrator</Option>
                                                    {userData && userData.length && userData.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_id}>
                                                                {item.employee_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Domain :  </b><span style={{ color: "red" }}>*</span></label>

                                                <Select

                                                    allowClear={true}
                                                    // disabled={consolidatedData.country==''?true:false}
                                                    size="default"
                                                    placeholder="Enter Domain"
                                                    value={consolidatedData.domain || undefined}
                                                    disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && consolidatedData.legel_entity == '') ? true : false}
                                                    onChange={(data, value) => {
                                                        if (data != undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                domain: data,
                                                                domainName: value.children,
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: '',
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "All",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                entityname: ''
                                                            })

                                                            let domainData = _.filter(filterValue && filterValue.Data && filterValue.Data.task_category_list, { task_domain_id: Number(data) })
                                                            setaskCategory(domainData)
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                domain: '',
                                                                domainName: '',
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: '',
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "All",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                entityname: ''
                                                            })
                                                        }

                                                    }
                                                    }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    {domain && domain.length && domain.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidatedData.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Act : </b></label>

                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.act || undefined}
                                                    placeholder="Enter Act"
                                                    onChange={(data) => {
                                                        if (data != undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                act: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                act: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    {Acts && Acts.length > 0 && Acts.map((item, i) => {
                                                        return (
                                                            <Option key={item.parent_id}>
                                                                {item.act}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>From Date : </b><span style={{ color: "red" }}>*</span></label>
                                                <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%' }} allowClear={false} value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                                    // value={consolidatedData.from_date}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    onChange={(date, dateString) => {

                                                        setDisableButton(false)
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            from_date: dateString,
                                                            to_date: moment(dateString).add(3, 'months')
                                                        })

                                                    }}

                                                />
                                                {validator.current.message(
                                                    'Fromdate',
                                                    consolidatedData.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required',
                                                        }
                                                    })}
                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Task Category : </b></label>
                                                <Select
                                                    allowClear={true}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    size="default"
                                                    value={consolidatedData.task_category || undefined}
                                                    placeholder="Enter Task Category"
                                                    onChange={(data) => {
                                                        if (data != undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                task_category: data
                                                            })
                                                            let subData = _.filter(filterValue && filterValue.Data && filterValue.Data.task_sub_category_list, { task_category_id: Number(data) })
                                                            setSubtaskCategory(subData)
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                task_category: ''
                                                            })
                                                        }
                                                    }
                                                    }

                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    {taskCategory && taskCategory.length > 0 && taskCategory.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_category_id}>
                                                                {item.task_category_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task : </b></label>

                                                <Input placeholder="Enter Compliance Task"
                                                // onChange={(e) => {
                                                //     setConsolidatedDate({
                                                //         ...consolidateddata,
                                                //         compliance_task: e.target.value
                                                //     })
                                                // }}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date : </b> <span style={{ color: "red" }}>*</span></label><br />
                                                <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' allowClear={false} style={{ width: '100%' }}
                                                    onChange={(date, dateString) => {
                                                        disablecondition(dateString)
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            to_date: moment(dateString)
                                                        })
                                                    }}
                                                    value={consolidatedData.to_date}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    disabledDate={(current) => {
                                                        return moment(consolidatedData.from_date).add(1, 'days') >= current ||
                                                            moment(consolidatedData.from_date).add(1, 'year') < current;
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'To-Date',
                                                    consolidatedData.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required',
                                                        }
                                                    })}
                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Task Sub Category : </b></label>
                                                <Select
                                                    disabled={consolidatedData.task_category == '' ? true : false}
                                                    value={consolidatedData.task_sub_category || undefined}
                                                    allowClear={true} defaultValue='All'
                                                    size="default"
                                                    placeholder="Enter Subcategory"
                                                    onChange={(data) => {
                                                        if (data != undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                task_sub_category: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                task_sub_category: ''
                                                            })
                                                        }
                                                    }
                                                    }


                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    <Option key={0}>All</Option>
                                                    {SubtaskCategory && SubtaskCategory.length > 0 && SubtaskCategory.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_sub_category_id}>
                                                                {item.task_sub_category_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Frequency : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    allowClear={true}
                                                    value={consolidatedData.compliance_frequency || undefined}
                                                    size="default"
                                                    defaultValue={'All'}
                                                    placeholder="Enter Compliance Frequency"
                                                    onChange={(data) => {
                                                        if (data != undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                compliance_frequency: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                compliance_frequency: ''
                                                            })
                                                        }
                                                    }
                                                    }

                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    <Option key={0}>All</Option>

                                                    {frequency && frequency.length && frequency.map((item, i) => {
                                                        return (
                                                            <Option key={item.frequency_id}>
                                                                {item.frequency}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task Status : </b></label>
                                                <Select
                                                    defaultValue="All"
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    onChange={(value) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            compliance_task_status: value
                                                        })
                                                    }}
                                                    value={consolidatedData.compliance_task_status || undefined}
                                                    placeholder="Enter Compliance Task Status"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    <Option key="All">All</Option>
                                                    <Option key="Complied">Complied</Option>
                                                    <Option key="Delayed Compliance">Delayed Compliance</Option>
                                                    <Option key="In Progress">In Progress</Option>
                                                    <Option key="Not Complied">Not Complied</Option>
                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Division : </b></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.division || undefined}
                                                    placeholder="Enter Division"
                                                    onChange={(data) => {
                                                        if (data != undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                division: data
                                                            })
                                                            let categorydata = _.filter(filterValue && filterValue.Data && filterValue.Data.categories_listtegory, { div_id: Number(data) })
                                                            setCategoryList(categorydata)
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                division: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                                <Button disabled={disableButton} type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "70px", marginRight: "10px" }} onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852" }}
                                                    onClick={showModal}>
                                                    Export
                                                </Button>

                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>

                                </Panel>
                            </Collapse>
                            {tableshow == true ? <Collapse className='report-collapse' defaultActiveKey={["1"]} onChange={onStatChange} style={{ marginTop: '1%', backgroundColor: '#5daae3' }}>
                                <Panel header="Overall Statistics" key={statkey}>
                                    <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                        <div className="row m-2">
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={30} width={90} format={(percent) => `${120}`} />
                                                <h4>Complied</h4>
                                            </div>
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={20} width={90} status="exception" format={(percent) => `${50}`} />
                                                <h4>Delayed Complied</h4>

                                            </div>
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={100} width={90} format={(percent) => `${200}`} />
                                                <h4>Inprogress</h4>

                                            </div>
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={80} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${180}`} />
                                                <h4>Not Complied</h4>

                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse> : false}
                            {tableshow == true ?
                                <>
                                    <div className='col-md-12 mt-2 mb-2' >
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <label style={{ marginLeft: '15%' }}><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? data.le_name : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label style={{ marginLeft: '20%' }}><b>Country :</b> {sessionArr && sessionArr[0].c_name}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain :</b> {consolidatedData.domainName}</label>
                                            </div>


                                        </div>
                                    </div>
                                    {/* {filteredArrayState.length > 0 ? */}
                                    <div>
                                        <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content  userprivclass1'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className="ant-table-thead antable-head" >
                                                                <tr>
                                                                    {filterValue.ConsolidatedReportList.length == 0 ?
                                                                        "" : <th className='ant-table-cell' style={{ width: '10px' }}>   <input type="checkbox"
                                                                            checked={(filterValue.ConsolidatedReportList.length) == complianceID.length ? true : false}
                                                                            onChange={(e) => {
                                                                                downloadselectAllCheckBox(e.target.checked)
                                                                            }} /> </th>}
                                                                    <th className='ant-table-cell' style={{ width: '10px' }}> # </th>
                                                                    <th className='ant-table-cell' style={{ width: '120px' }}>Compliance Task</th>
                                                                    <th className='ant-table-cell' style={{ width: '50px' }}>Criticality</th>
                                                                    <th className='ant-table-cell' style={{ width: '90px' }}>Frequency</th>
                                                                    <th className='ant-table-cell' style={{ width: '150px' }}>Due Date</th>
                                                                    <th className='ant-table-cell' style={{ width: '120px' }}>Compliance Task Status</th>
                                                                    <th className='ant-table-cell' style={{ width: '100px' }}>User Name</th>
                                                                    <th className='ant-table-cell' style={{ width: '120px' }}>Activity Status</th>
                                                                    <th className='ant-table-cell' style={{ width: '100px' }}>Activity Date</th>
                                                                    <th className='ant-table-cell' style={{ width: '100px' }}>Uploaded Document</th>
                                                                    <th className='ant-table-cell' style={{ width: '100px' }}><Tooltip title='Task Completion Date/Document issued Date'>Date</Tooltip></th>
                                                                </tr>
                                                            </thead>
                                                            {filteredArrayState && filteredArrayState.length > 0 ?
                                                                filteredArrayState.map((item, i) => {
                                                                    return (
                                                                        <tbody className='ant-table-tbody '>


                                                                            <>
                                                                                <tr className="bg-lightdark">
                                                                                    <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                        <table className='bg-white' style={{ tableLayout: 'auto' }}>
                                                                                        <div className="col-md-12">
                                                                                                    <div className='row m-2'>
                                                                                                        <div className='col-md-3' >
                                                                                                            <label><b>Unit :</b> {item[0].unit}</label>
                                                                                                        </div>
                                                                                                        <div className='col-md-3' >
                                                                                                            <label><b>Category :</b> {item[0].category_name}</label>
                                                                                                        </div>
                                                                                                        <div className='col-md-3' >
                                                                                                            <label><b>Division :</b> {item[0].division_name}</label>
                                                                                                        </div>
                                                                                                        <div className='col-md-3' >
                                                                                                            <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            {/* <tbody className='ant-table-tbody'> */}
                                                                                             
                                                                                                {/* <div className='row m-3'>
                                                                                                    <div className='col-md-3' >
                                                                                                     
                                                                                                    </div>
                                                                                                    <div className='col-md-3 ' >
                                                                                                     
                                                                                                    </div>
                                                                                                    <div className='col-md-3' >
                                                                                                    </div>
                                                                                                    <div className='col-md-3' >
                                                                                                    </div>
                                                                                                </div> */}
                                                                                                {/* <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td className='ant-table-cell w6'><b>Unit :</b></td>
                                                                                                    <td className='ant-table-cell w15' title={item[0].unit}>{item[0].unit}</td>
                                                                                                    <td className='ant-table-cell w6'><b>Category :</b></td>
                                                                                                    <td className='ant-table-cell w40'><span className="category-name">{item[0].category_name}</span></td>
                                                                                                </tr> */}
                                                                                                {/* <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td className='ant-table-cell w6'><b>Division :</b></td>
                                                                                                    <td className='ant-table-cell w15' >{item[0].division_name}</td>
                                                                                                    <td className='ant-table-cell w6'><b>Time Zone :</b></td>
                                                                                                    <td className='ant-table-cell w40'><span className="category-name">{item[0].tz_name}</span></td>
                                                                                                </tr> */}
                                                                                            {/* </tbody> */}
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                {titledata && titledata.map((itemdata, i) => {
                                                                                    let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                                    if (data != undefined) {
                                                                                        if (data.act_name == itemdata.parent) {
                                                                                            return <Fragment>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                                </tr>
                                                                                                {itemdata.child.map((items) => {
                                                                                                    if (item[0].unit_id == items.unit_id) {
                                                                                                        let array = []

                                                                                                        for (let i in filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList) {
                                                                                                            if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList[i].compliance_id == items.compliance_id) {
                                                                                                                if (filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList[i].unit_id == items.unit_id) {
                                                                                                                    array.push(filterValue && filterValue.ConsolidatedReportList && filterValue.ConsolidatedReportList.length && filterValue.ConsolidatedReportList[i])
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        return <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                            <td className='ant-table-cell'>  <center><input type="checkbox" name='checked' onChange={(e) => {
                                                                                                                downloadcheckBoxOnChange(e, items)
                                                                                                            }} /></center></td>
                                                                                                            <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                            <td className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                                <ExclamationCircleTwoTone />
                                                                                                            </Tooltip> &nbsp;{array.length > 1 ? <a href='javascript:;' onClick={() => {
                                                                                                                chilData(items.compliance_id, items.unit_id)
                                                                                                            }}>{items.compliance_name}</a> : <a >{items.compliance_name}</a>}</td>
                                                                                                            {/* <td title={items.compliance_name} className='ant-table-cell'>{items.compliance_name}</td> */}
                                                                                                            <td className='ant-table-cell'><center>{items.criticality_name}</center></td>
                                                                                                            <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                                            <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                            <td className='ant-table-cell'>{items.task_status}</td>
                                                                                                            <td className='ant-table-cell'>{items.user_name}</td>
                                                                                                            <td className='ant-table-cell'>{items.activity_status}</td>
                                                                                                            <td className='ant-table-cell'><center>{items.activity_on}</center></td>
                                                                                                            <td className='ant-table-cell'>{items.uploaded_document}</td>
                                                                                                            <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                                            {/* </tr> */}
                                                                                                            <span hidden>{index = index + 1}</span>
                                                                                                        </tr>
                                                                                                    }

                                                                                                })

                                                                                                }
                                                                                            </Fragment>
                                                                                        }
                                                                                    }
                                                                                })
                                                                                }
                                                                            </>
                                                                        </tbody>
                                                                    )
                                                                }) :
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                </tr>}
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    {filteredArrayState && filteredArrayState.length > 0 ?
                                        <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                            <label style={{ marginLeft: "28px" }}>Showing {pageSize * (current - 1) + 1} to {index} of {filterValue && filterValue.count} entries</label>
                                        </div> : ""}
                                    <div style={{ position: 'absolute', bottom: '-25px', right: 0 }}>
                                        <Pagination
                                            current={current}
                                            showSizeChanger={true}
                                            // showQuickJumper={true}
                                            onShowSizeChange={() => {
                                                setCurrent(1)
                                                // setpageState(true)
                                            }}
                                            pageSizeOptions={[25, 50, 100]}
                                            hideOnSinglePage={filterValue && filterValue.count > 25 ? false : true}
                                            defaultPageSize={25}
                                            onChange={(page, pageSizes) => {
                                                setpageState(true)
                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                setPageSize(pageSizes)
                                            }} total={filterValue && filterValue.count}
                                        // showTotal={(total) => `Showing 1 to ${index} of ${filterValue && filterValue.count} entries`}
                                        />


                                    </div>

                                </>
                                : ''}


                            <Modal visible={isModalVisible} footer={null} onCancel={handleCancel} onOk={() => {
                                setIsModalVisible(false)
                            }} >
                                <Table
                                    style={{ marginTop: '2%' }}
                                    className='userprivclass'
                                    columns={columns}
                                    dataSource={childCompliceId}
                                    bordered
                                    pagination={false} />
                            </Modal>
                            <Modal title="Select Fields" visible={isModalOpen} footer={null} onOk={handleOk} okText='Submit' onCancel={handleCancel} width={100}>
                                {/* <input id="select_all" width="100%" type="checkbox" autocomplete="off" onClick={(e) => {
                                    let checked = e.target.checked
                                    selectAllCheckBox(checked)
                                }} /> Select All
                                <br /> */}
                                <div className='row'>
                                    <div className='col-lg-3'><input id="select_all" width="100%" type="checkbox" autocomplete="off"
                                        checked={checkboxValue.length === 35 ? true : false}
                                        onClick={(e) => {
                                            let checked = e.target.checked
                                            selectAllCheckBox(checked)
                                        }} /> Select All</div>
                                    <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" disabled="disabled" value='1' checked onChange={(e) => (e) => checkBoxOnChange(e)} /> Unit Code</div>
                                    <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" disabled="disabled" value='2' checked onChange={(e) => checkBoxOnChange(e)} /> Unit Name</div>
                                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='7' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /> Location</div>

                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" disabled="disabled" value='8' checked onChange={(e) => checkBoxOnChange(e)} /> Act / Rules</div>
                                    <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" disabled="disabled" value='11' checked onChange={(e) => checkBoxOnChange(e)} /> Compliance Task </div>
                                    <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" disabled="disabled" value='12' checked onChange={(e) => checkBoxOnChange(e)} /> Frequency </div>
                                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='32' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> ComplianceTask Status </div>
                                </div >
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='35' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Remarks </div>
                                    <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2 </div>
                                    <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 3</div>
                                    <div className='col-lg-3'><input id="column_6" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 4</div>

                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='3' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 1 </div>
                                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='9' onChange={(e) => checkBoxOnChange(e)} /> Task Category</div>
                                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='10' onChange={(e) => checkBoxOnChange(e)} /> Task  Sub Category </div>
                                    <div className='col-lg-3'><input id="column_11" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Periodicity</div>

                                </div> <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_12" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Division</div>
                                    <div className='col-lg-3'><input id="column_13" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Category </div>
                                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assigned by</div>
                                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned To</div>
                                </div> <br />

                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date </div>
                                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Assignee </div>
                                    <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Completed on</div>
                                    <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurrer</div>
                                </div> <br />

                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Concurred on </div>
                                    <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approver </div>
                                    <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Approved on </div>
                                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Activity Status </div>
                                </div> <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Start Date </div>
                                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /> Due Date</div>
                                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> Extend Due Date  </div>
                                    <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date/Document Issued Date  </div>
                                </div> <br />
                                <div className='row'>

                                    <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> Month </div>
                                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='31' onChange={(e) => checkBoxOnChange(e)} /> Validity Date </div>
                                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} /> Duration</div>
                                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='34' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number  </div>
                                </div> <br />
                                <div className="form-actions">
                                    <Button type="primary" shape="round" className='addbutton'
                                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '40%' }}
                                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                    </Button>
                                </div>

                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default connect(mapStateToProps, {
    getFilterValue,
    getConsolidatedExport,
    getConsolidatedReportData,
    downloadConsolidatedReport
})(ConsolidatedReport);