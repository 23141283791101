import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { LegalEntityScoreCardData, GetLEScoreReportData } from '../../../Store/Action/Report/LegalEntityScoreCard';
import { connect } from 'react-redux';
import { filter } from 'lodash';

const mapStateToProps = (state) => ({
    domain_data: state.LegalEntityScoreCard.legalEntityReport.domain_data.domains,
    tableData: state.LegalEntityScoreCard.legalEntityReport.getTableData
})


const LegalEntityScoreCard = ({
    LegalEntityScoreCardData,
    GetLEScoreReportData,
    domain_data,
    tableData
}) => {

    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    let [index, setIndex] = useState(0)
    const [type, setType] = useState('')
    const [filteredArray, setFilteredArray] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [completedModal, setCompletedModal] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [count, setCount] = useState(2);

    const [legalEntityScoreCard, setLegalEntityScoreCard] = useState({
        country: '',
        countryName: '',
        legel_entity: '',
        le_id: '',
        domain: '',
        domainName: ''
    })
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;
    let completedInProgressSum;
    let toConcurInProgressSum;
    let toApproveInProgressSum;
    let completedUserInProgress;
    let concurUserInProgress;
    let approveUserInProgress;
    let delayedSum;
    let compiledSum;
    let delayedUserSum;
    let compiledUserSum;
    let completedOverdueSum;
    let toConcurOverdueSum;
    let toApproveOverdueSum;
    let completedUserOverdue;
    let concurUserOverdue;
    let approveUserOverdue;



    // const onshow = () => {
    //   console.log("hii123");
    //   setAddFormSubmit(true)
    //   if (validator.current.allValid()) {
    //     [
    //       "164-d2aac8fe3c84455788998f05af741d21",
    //       {
    //         "session_token": "164-d2aac8fe3c84455788998f05af741d21",
    //         "request": [
    //           "GetLEWiseScoreCard",
    //           {
    //             "c_id": 1,
    //             "le_id": "zppd",
    //             "d_id": 1,
    //             "csv": false
    //           }
    //         ]
    //       }
    //     ]
    //     setTableShow(true)
    //     setKey(0)
    //   }
    // }

    const columns = [
        {
            title: type === 'Unit_inProgress' || type === 'unit_overdue' ? 'Units' : 'Users',
            ellipsis: true,
            width: '50px',
            dataIndex: type === 'Unit_inProgress' || type === 'unit_overdue' ? 'unit' : 'user_name',
            key: type === 'Unit_inProgress' || type === 'unit_overdue' ? 'unit' : 'user_name',
        },
        {
            title: 'To Complete',
            ellipsis: true,
            align: 'center',
            // selector: (row) => row.compliance_task,
            width: '60px',
            dataIndex: 'to_complete',
            key: 'to_complete',
        },
        {
            title: "To Concur",
            ellipsis: true,
            width: '50px',
            align: 'center',
            dataIndex: 'to_concur',
            key: 'to_concur',
        },
        {
            title: "To Approve",
            width: '50px',
            ellipsis: true,
            align: 'center',
            dataIndex: 'to_approve',
            key: 'to_approve',
        },
        {
            title: type === 'Unit_inProgress' || type === 'User_inProgress' ? 'Total(Task)' : '',
            width: type === 'Unit_inProgress' || type === 'User_inProgress' ? '50px' : '0px',
            ellipsis: true,
            render: (row) => type === 'Unit_inProgress' || type === 'User_inProgress' ? <b>{Number(row.to_complete) + Number(row.to_concur) + Number(row.to_approve)}</b> : '',
            align: 'center'
        },
    ];

    const completedColumns = [
        {
            title: type === 'unit_completed' ? 'Units' : 'Users',
            ellipsis: true,
            width: '50px',
            dataIndex: type === 'unit_completed' ? 'unit' : 'user_name',
            key: type === 'unit_completed' ? 'unit' : 'user_name',
        },
        {
            title: 'Delayed Count',
            ellipsis: true,
            // selector: (row) => row.compliance_task,
            width: '60px',
            dataIndex: 'delayed_count',
            key: 'delayed_count',
        },
        {
            title: "Complied Count",
            width: '50px',
            ellipsis: true,
            dataIndex: 'complied_count',
            key: 'to_approve',
        },
        {
            title: "Total(Task)",
            width: '50px',
            ellipsis: true,
            render: (row) => <b>{Number(row.delayed_count) + Number(row.complied_count)}</b>,
            align: 'center'

        },
    ];

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showCompletedModal = () => {
        setCompletedModal(true);
    };
    const handleCompletedOk = () => {
        setCompletedModal(false);
    };
    const handleCompletedCancel = () => {
        setCompletedModal(false);
    };

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetLEWiseScoreCard",
                        {
                            "c_id": Number(legalEntityScoreCard.country),
                            "le_id": entityid,
                            "d_id": Number(legalEntityScoreCard.domain),
                            "csv": false
                        }
                    ]
                }
            ]
            GetLEScoreReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetLEWiseScoreCardFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                LegalEntityScoreCardData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(legalEntityScoreCard.le_id)
        }
    }, [entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setLegalEntityScoreCard({
                ...legalEntityScoreCard,
                country: Number(sessionArr[0].c_id),
                le_id: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list.length > 0) {
            if (type != '') {
                if (type === 'Unit_inProgress') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].inprogress_unit_wise)
                }
                else if (type === 'User_inProgress') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].inprogress_user_wise)
                }
                else if (type === 'unit_completed') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].completed_unit_wise)
                }
                else if (type === 'user_completed') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].completed_user_wise)
                }
                else if (type === 'unit_overdue') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].overdue_unit_wise)
                }
                else if (type === 'user_overdue') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].overdue_user_wise)
                }
            }
        }

    }, [type])


    useEffect(() => {
        if (filteredArray.length > 0) {
            setDataSource(filteredArray)
            if (type === 'Unit_inProgress') {
                let temp = []
                let temp1 = []
                let temp2 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].to_complete))
                    temp1.push(Number(filteredArray[i].to_concur))
                    temp2.push(Number(filteredArray[i].to_approve))
                }
                completedInProgressSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                toConcurInProgressSum = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                toApproveInProgressSum = temp2.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'User_inProgress') {
                let temp = []
                let temp1 = []
                let temp2 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].to_complete))
                    temp1.push(Number(filteredArray[i].to_concur))
                    temp2.push(Number(filteredArray[i].to_approve))
                }
                completedUserInProgress = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                concurUserInProgress = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                approveUserInProgress = temp2.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'unit_completed') {
                let temp = []
                let temp1 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].delayed_count))
                    temp1.push(Number(filteredArray[i].complied_count))
                }
                delayedSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                compiledSum = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'user_completed') {
                let temp = []
                let temp1 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].delayed_count))
                    temp1.push(Number(filteredArray[i].complied_count))
                }
                delayedUserSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                compiledUserSum = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'unit_overdue') {
                let temp = []
                let temp1 = []
                let temp2 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].to_complete))
                    temp1.push(Number(filteredArray[i].to_concur))
                    temp2.push(Number(filteredArray[i].to_approve))
                }
                completedOverdueSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                toConcurOverdueSum = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                toApproveOverdueSum = temp2.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'user_overdue') {
                let temp = []
                let temp1 = []
                let temp2 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].to_complete))
                    temp1.push(Number(filteredArray[i].to_concur))
                    temp2.push(Number(filteredArray[i].to_approve))
                }
                completedUserOverdue = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                concurUserOverdue = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                approveUserOverdue = temp2.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
        }
    }, [filteredArray])

    useEffect(() => {
        if (filteredArray) {
            if (type === 'Unit_inProgress') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    to_complete: completedInProgressSum,
                    to_concur: toConcurInProgressSum,
                    to_approve: toApproveInProgressSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'User_inProgress') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    to_complete: completedUserInProgress,
                    to_concur: concurUserInProgress,
                    to_approve: approveUserInProgress,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'unit_completed') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    delayed_count: delayedSum,
                    complied_count: compiledSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'user_completed') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    delayed_count: delayedUserSum,
                    complied_count: compiledUserSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'unit_overdue') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    to_complete: completedOverdueSum,
                    to_concur: toConcurOverdueSum,
                    to_approve: toApproveOverdueSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'user_overdue') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    to_complete: completedUserOverdue,
                    to_concur: concurUserOverdue,
                    to_approve: approveUserOverdue,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }



        }
    }, [filteredArray, type])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="page-titles pb-0 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ color: '#1890ff' }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ color: '#1890ff' }}>Score Cards</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ color: '#1890ff' }}>Legal Entity Wise Score Card</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <Modal title={(type === 'Unit_inProgress' ? 'Unit Wise Inprogress Task Count' : (type === 'User_inProgress' ? 'User Wise Inprogress Task Count' : (type === 'unit_overdue' ? 'Unit Wise Overdue Task Count' : 'User Wise Overdue Task Count')))} visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
                    <Table
                        className='userprivclass'
                        columns={columns}
                        dataSource={dataSource}
                        bordered
                        // scroll={{ x: 1500 }}
                        pagination={false} />

                </Modal>
                <Modal title={type === 'unit_completed' ? 'Unit Wise Completed Task Count' : 'User Wise Completed Task Count'} visible={completedModal} footer={null} onCancel={handleCompletedCancel}>
                    <Table
                        className='userprivclass'
                        columns={completedColumns}
                        dataSource={dataSource}
                        bordered
                        // scroll={{ x: 1500 }}
                        pagination={false} />

                </Modal>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" id='only-restricted' style={{ padding: "10px 3px" }}>
                                <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onChange} style={{ marginLeft: '10%', marginRight: '10%',backgroundColor: '#5daae3' }}>
                                    <Panel header="Legal Entity Wise Score Card" key={key}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={false}
                                                                    size="default"
                                                                    placeholder="Enter Country"
                                                                    onChange={(value, data) => {
                                                                        setLegalEntityScoreCard({
                                                                            ...legalEntityScoreCard,
                                                                            country: value,
                                                                            countryName: data.children,
                                                                            legel_entity: '',
                                                                            le_id: '',
                                                                            domain: '',
                                                                            domainName: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //value={Settings.le_id}
                                                                    style={{ width: '100%', marginTop: '3px' }}
                                                                    value={legalEntityScoreCard.countryName || undefined}
                                                                >
                                                                    {country_info && country_info.length && country_info.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'legalentity',
                                                                    legalEntityScoreCard.legel_entity,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: ' Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{sessionArr[0].c_name}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity : </b>  <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onChange={(data, value) => {
                                                                    setCurrentEntity(data)
                                                                    setLegalEntityScoreCard({
                                                                        ...legalEntityScoreCard,
                                                                        le_id: data,
                                                                        legel_entity: value.children,
                                                                        domain: '',
                                                                        domainName: ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '3px' }}

                                                                value={legalEntityScoreCard.legel_entity || undefined}
                                                                disabled={legalEntityScoreCard.country == '' ? true : false}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                legalEntityScoreCard.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b>  <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select allowClear={false}
                                                            onChange={(value, data) => {
                                                                setLegalEntityScoreCard({
                                                                    ...legalEntityScoreCard,
                                                                    domain: value,
                                                                    domainName: data.children
                                                                })
                                                            }}
                                                            placeholder="Enter Domain"
                                                            style={{ width: '100%', marginTop: '3px' }}

                                                            value={legalEntityScoreCard.domainName || undefined}
                                                            disabled={legalEntityScoreCard.le_id == '' ? true : false}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                        >
                                                            {domain_data && domain_data.length && domain_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                    {validator.current.message(
                                                        'domain',
                                                        legalEntityScoreCard.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                {/* <div className='col-md-4'></div>
                        <div className='col-md-4'></div> */}
                                                <div className='col-md-4' style={{ marginLeft: '30%' }}>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "45%" }}
                                                        onClick={onshow}
                                                    >
                                                        Show
                                                    </Button>
                                                    {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852", width: "100px" }}
                          // onClick={showModal}
                          >
                            Export
                          </Button> */}
                                                </div>
                                            </div>
                                        </div>


                                    </Panel>
                                </Collapse>

                                {tableshow == true ?
                                    <>
                                        <div style={{ marginLeft: '10%', marginRight: '10%', marginTop: '1%' }}>
                                            <div className='col-md-12 mt-1 mb-1' style={{ marginLeft: '1%' }} >
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label style={{marginLeft:'4%'}}><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? legalEntityScoreCard.legel_entity : localStorage.getItem('SelectedEntity')}</label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label style={{marginLeft:'25%'}}><b>Country :</b> {sessionArr && sessionArr[0].c_name}</label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label style={{marginRight:'2%'}}><b>Domain :</b> {legalEntityScoreCard.domainName}</label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='ant-table-wrapper'>
                                                <div className='ant-table ant-table-bordered'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content userprivclass1'>
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className="ant-table-thead antable-head">
                                                                    <tr>
                                                                        <th className='ant-table-cell w10px'>#</th>
                                                                        <th title='Compliance Task' className='ant-table-cell custom-width1'>Task Status</th>
                                                                        <th className='ant-table-cell custom-width1'>Count</th>
                                                                        <th className='ant-table-cell custom-width1'><center>Unit Wise</center></th>
                                                                        <th className='ant-table-cell custom-width1'>User Wise</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='ant-table-tbody'>
                                                                    <tr>
                                                                        <td className='ant-table-cell'><center>1</center></td>
                                                                        <td className='ant-table-cell'><center>Inprogress</center></td>
                                                                        <td className='ant-table-cell'><center>{tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].inprogress_count}</center></td>
                                                                        <td className='ant-table-cell'><center><Button type="primary" disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].inprogress_count > 0) ? false : true}
                                                                            onClick={() => {
                                                                                setType('Unit_inProgress')
                                                                                showModal()
                                                                            }}
                                                                        >View</Button></center></td>
                                                                        <td className='ant-table-cell'><center><Button type="primary" disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].inprogress_count > 0) ? false : true}
                                                                            onClick={() => {
                                                                                setType('User_inProgress')
                                                                                showModal()
                                                                            }}>View</Button></center></td>
                                                                    </tr>
                                                                    <tr className="bg-lightdark">
                                                                        <td className='ant-table-cell'><center>2</center></td>
                                                                        <td className='ant-table-cell'><center>Completed</center></td>
                                                                        <td className='ant-table-cell'><center>{tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].completed_count}</center></td>
                                                                        <td className='ant-table-cell'><center><Button type="primary" disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].completed_count) > 0 ? false : true}
                                                                            onClick={() => {
                                                                                setType('unit_completed')
                                                                                showCompletedModal()
                                                                            }}
                                                                        >View</Button></center></td>
                                                                        <td className='ant-table-cell'><center><Button type="primary" disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].completed_count) > 0 ? false : true}
                                                                            onClick={() => {
                                                                                setType('user_completed')
                                                                                showCompletedModal()
                                                                            }}
                                                                        >View</Button></center></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='ant-table-cell'><center>3</center></td>
                                                                        <td className='ant-table-cell'><center>Over due</center></td>
                                                                        <td className='ant-table-cell'><center>{tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].overdue_count}</center></td>
                                                                        <td className='ant-table-cell'><center><Button type="primary" disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].overdue_count) > 0 ? false : true}
                                                                            onClick={() => {
                                                                                setType('unit_overdue')
                                                                                showModal()
                                                                            }}
                                                                        >View</Button></center></td>
                                                                        <td className='ant-table-cell'><center><Button type="primary" disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].overdue_count) > 0 ? false : true}
                                                                            onClick={() => {
                                                                                setType('user_overdue')
                                                                                showModal()
                                                                            }}>View</Button></center></td>
                                                                    </tr>
                                                                    <tr className="bg-lightdark">
                                                                        <td className='ant-table-cell'><center></center></td>
                                                                        <td className='ant-table-cell'><center><b>Total</b></center></td>
                                                                        <td className='ant-table-cell'><center><b>{tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].overdue_count + tableData.le_wise_score_card_list[0].completed_count + tableData.le_wise_score_card_list[0].inprogress_count}</b></center></td>
                                                                        <td className='ant-table-cell'><center></center></td>
                                                                        <td className='ant-table-cell'><center></center></td>
                                                                    </tr>
                                                                </tbody>

                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                    : false}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default connect(mapStateToProps, {
    LegalEntityScoreCardData,
    GetLEScoreReportData
})(LegalEntityScoreCard);