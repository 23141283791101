import { useLocation, Link } from 'react-router-dom';
import { Collapse, Select, Modal, Button, Table, Tabs, Progress, Input, Checkbox, Divider, Space } from 'antd';
import { useState, useEffect, useRef } from 'react';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { getdata, getdatalist, getdatatable, getapprovallist } from '../Store/Action/Settings'
import { toast } from 'react-toastify';
import { PlusOutlined, ExclamationCircleTwoTone, BookTwoTone, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, SearchOutlined, FilterFilled } from '@ant-design/icons';


const mapStateToProps = (state) => ({
    settingslist: state.Settings.settingsdata
})
const Settings = ({ getdata, getdatalist, getdatatable, settingslist, getapprovallist }) => {
    console.log(settingslist, "settingslist");

    const { TabPane } = Tabs;
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const [countrydata, setCountryData] = useState(null)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    const [days, setDays] = useState({
        sp_compliance: null,
        after_trigger: null,
        after_due_date: null
    })
    const [monthlydata, setMonthlyData] = useState({
        assignee: "",
        approver: ""
    })
    const [settingsdata, setSettingsData] = useState({
        legel_entity: ""
    })
    const [datalist, setDataList] = useState()
    console.log(datalist, "datalist111");
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const [formdata, Setformdata] = useState({
        form_url: '',
        form_key: ''
    });
    const [totaldata, setTotalData] = useState({
        domain: [],
        settings_details: [],
        settings_users: []
    })
    console.log(totaldata, "totaldata");
    const [task, setTask] = useState({
        rectify_task: "1",
        rejected_task: "1",
        reassigned_task: "1",
        assignee_task: "1",
        approver_task: "1",
        after_trigger: "1",
        after_due_date: "1"
    })
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const showModal1 = () => {
        setIsModalOpen1(true);
    };
    const handleOk1 = () => {
        setIsModalOpen1(false);
    };
    const handleCancel1 = () => {
        setIsModalOpen1(false);
    };

    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const showModal2 = () => {
        setIsModalOpen2(true);
    };
    const handleOk2 = () => {
        setIsModalOpen2(false);
    };
    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])
    useEffect(() => {
        if (isAuth) {
            const getUrl = callername.login_response.menu['My Accounts']
            const settings = _.filter(getUrl, { form_name: "Settings" })
            Setformdata({
                ...formdata,
                form_url: settings && settings[0] && settings[0].form_url,
                form_key: settings && settings[0] && settings[0].form_key
            })
        }
    }, [isAuth])
    useEffect(() => {
        if (settingslist && settingslist.getdata) {
            setTotalData({
                ...totaldata,
                domain: settingslist && settingslist.getdata && settingslist.getdata.dmn_unt_lst,
                settings_details: settingslist && settingslist.getdata && settingslist.getdata.settings_details,
                settings_users: settingslist && settingslist.getdata && settingslist.getdata.settings_users,
            })
        }
    }, [settingslist && settingslist.getdata])
    useEffect(() => {
        if (settingslist && settingslist.getdatalist) {
            setDataList(settingslist && settingslist.getdatalist && settingslist.getdatalist.settings_domains)
        }
    }, [settingslist && settingslist.getdatalist])
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder='Search'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterFilled
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };





    const columns = [
        {
            title: '#',
            align: "center",
            render: (row, record, index) => index + 1
        },
        {
            title: 'Employee',
            ...getColumnSearchProps('employee_name'),
            dataIndex: 'employee_name',
            align: "employee_name",
            key: "employee_name",
            align: "center",
            sorter: (a, b) => a.employee_name.length - b.employee_name.length,
            sortDirections: ['descend'],
        },
        {
            title: 'User Level',
            ...getColumnSearchProps('employee_name'),
            dataIndex: 'user_level_name',
            key: "user_level_name",
            defaultSortOrder: 'descend',
            align: "center",
            sorter: (a, b) => a.user_level_name - b.user_level_name,


        },
        {
            title: 'User Category',
            ...getColumnSearchProps('category_name'),
            dataIndex: 'category_name',
            align: "center",
            key: "category_name",
            sorter: (a, b) => a.category_name.length - b.category_name.length,
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
    };
    const columns1 = [
        {
            title: '#',
            align: "center",
            render: (row, record, index) => index + 1
        },
        {
            title: 'Employee',
            ...getColumnSearchProps('employee_name'),
            dataIndex: 'employee_name',
            align: "center",
            key: "employee_name",
            sorter: (a, b) => a.employee_name.length - b.employee_name.length,
            sortDirections: ['descend'],
        },
        {
            title: 'User Level',
            ...getColumnSearchProps('user_level_name'),
            dataIndex: 'user_level_name',
            key: "user_level_name",
            defaultSortOrder: 'descend',
            align: "center",
            sorter: (a, b) => a.user_level_name - b.user_level_name,

        },
        {
            title: 'User Category',
            ...getColumnSearchProps('category_name'),
            dataIndex: 'category_name',
            align: "center",
            key: "category_name",
            sorter: (a, b) => a.category_name.length - b.category_name.length,
        },
    ];

    const onChange1 = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra);
    };
    useEffect(() => {
        if (isAuth && entityid !== "null") {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetSettingsFormDetails",
                        {
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            getdata({
                payload: payload,
                paramid: paramid,
                formkey: formdata.form_key
            })
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetLegalEntityDomains",
                        {
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            getdatalist({
                payload: payload1,
                paramid: paramid,
                formkey: formdata.form_key
            })
        }
    }, [authtoken, isAuth, entityid, formdata])
    const onsubmit = () => {
        const obj1 = {
            1: unitid
        }
        const myJSON1 = JSON.stringify(obj1);
        const temp1 = JSON.stringify(myJSON1);

        const obj2 = {
            1: unitid1
        }
        const myJSON2 = JSON.stringify(obj2);
        const temp2 = JSON.stringify(myJSON2);

        const obj3 = {
            1: unitid2
        }
        const myJSON3 = JSON.stringify(obj3);
        const temp3 = JSON.stringify(myJSON3);
        const payload2 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "SaveSettingsFormDetails",
                    {
                        "legal_entity_id": entityid,
                        "legal_entity_name": settingsdata.legel_entity,
                        "two_level_approve": false,
                        "assignee_reminder": days.after_trigger,
                        "advance_escalation_reminder": 7,
                        "escalation_reminder": days.after_due_date,
                        "mail_trigger": false,
                        "assignee_mail_reminder_val": task.assignee_task == "1" ? 6 : task.assignee_task == "2" ? Number(monthlydata.assignee) : task.assignee_task === "3" ? 1 : "",
                        "assignee_mail_reminder": task.assignee_task == "1" ? "W" : task.assignee_task == "2" ? "M" : task.assignee_task == "3" ? "D" : "",
                        "approve_mail_reminder_val": task.approver_task == "1" ? 6 : task.approver_task == "2" ? Number(monthlydata.approver) : task.approver_task === "3" ? 1 : "",
                        "approve_mail_reminder": task.approver_task == "1" ? "W" : task.approver_task == "2" ? "M" : task.approver_task == "3" ? "D" : "",
                        "reassign_sp": days.sp_compliance,
                        "selected_units": "{}",
                        "mail_status_reject": true,
                        "mail_status_reassign": true,
                        "units_selected_reject": temp2,
                        "units_selected_reassign": temp3,
                        "reminder_assig_val": days.after_trigger,
                        "reminder_assig": 1,
                        "reminder_esc_val": days.after_due_date,
                        "reminder_esc": 1,
                        "units_selected_rectify": temp1,
                        "mail_status_rectify": true
                    }
                ]
            }
        ]
        getdatatable({
            payload: payload2,
            paramid: paramid,
            formkey: formdata.form_key
        })
    }

    const CheckboxGroup = Checkbox.Group;
    const filterdata = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children.map((item) => {
        return item.text
    })
    const plainOptions = filterdata
    const defaultCheckedList = filterdata
    const [unitid, setUnitId] = useState([])
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [checkAll, setCheckAll] = useState(false);
    const onChange2 = (list) => {
        let temp = []
        let filterdata1
        for (let i in list) {
            filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.filter((item) => {
                return item.text === list[i]
            })
        }
        temp.push(filterdata1 && filterdata1[0] && filterdata1[0].data && filterdata1[0].data.unit_id.toString())
        // setUnitId(Object.assign({},[[...unitid, ...temp]]))
        setUnitId([...unitid, ...temp])
        setCheckedList(list);
        setCheckAll(list.length === plainOptions.length);
    };
    const onCheckAllChange = (e) => {
        let temp = []
        const filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.map((item) => {
            return item && item.data && item.data.unit_id
        })
        temp.push(...filterdata1.toString())
        setUnitId([...unitid, ...temp])
        setCheckedList(e.target.checked ? plainOptions : []);
        setCheckAll(e.target.checked);
    };

    const plainOptions1 = filterdata
    const defaultCheckedLis1 = filterdata
    const [unitid1, setUnitId1] = useState([])
    const [checkedList1, setCheckedList1] = useState(defaultCheckedLis1);
    const [checkAll1, setCheckAll1] = useState(false);
    const onChange21 = (list) => {
        let temp = []
        let filterdata1
        for (let i in list) {
            filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.filter((item) => {
                return item.text === list[i]
            })
        }
        temp.push(filterdata1 && filterdata1[0] && filterdata1[0].data && filterdata1[0].data.unit_id.toString())
        setUnitId1([...unitid1, ...temp])
        setCheckedList1(list);
        setCheckAll1(list.length === plainOptions1.length);
    };
    const onCheckAllChange1 = (e) => {
        let temp = []
        const filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.map((item) => {
            return item && item.data && item.data.unit_id
        })
        temp.push(...filterdata1.toString())
        setUnitId1([...unitid1, ...temp])
        setCheckedList1(e.target.checked ? plainOptions1 : []);
        setCheckAll1(e.target.checked);
    };

    const plainOptions2 = filterdata
    const defaultCheckedList2 = filterdata
    const [unitid2, setUnitId2] = useState([])
    const [checkedList2, setCheckedList2] = useState(defaultCheckedList2);
    const [checkAll2, setCheckAll2] = useState(false);
    const onChange22 = (list) => {
        let temp = []
        let filterdata1
        for (let i in list) {
            filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.filter((item) => {
                return item.text === list[i]
            })
        }
        temp.push(filterdata1 && filterdata1[0] && filterdata1[0].data && filterdata1[0].data.unit_id.toString())
        setUnitId2([...unitid2, ...temp])
        setCheckedList2(list);
        setCheckAll2(list.length === plainOptions2.length);
    };
    const onCheckAllChange2 = (e) => {
        let temp = []
        const filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.map((item) => {
            return item && item.data && item.data.unit_id
        })
        temp.push(...filterdata1.toString())
        setUnitId2([...unitid2, ...temp])
        setCheckedList2(e.target.checked ? plainOptions2 : []);
        setCheckAll2(e.target.checked);
    };

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {
            setSettingsData({
                ...settingsdata,
                legel_entity: sessionArr[0].le_name
            })
        }
    }, [localStorage.getItem('SelectedEntity') !== "All Legal Entity"])
    useEffect(() => {
        if (totaldata && totaldata.settings_details && totaldata.settings_details) {
            setDays({
                ...days,
                sp_compliance: totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].reassign_sp,
                after_trigger: totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].assignee_reminder,
                after_due_date: totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].escalation_reminder
            })
        }
    }, [totaldata && totaldata.settings_details && totaldata.settings_details])
    const [baseData, setBaseData] = useState([]);

    useEffect(() => {
        if (totaldata && totaldata.settings_users) {
            setBaseData(totaldata && totaldata.settings_users);
        }
    }, [totaldata && totaldata.settings_users])

    // const searchRecords = (e) => {
    //     if (e == 'clear') {
    //         setClearText('')
    //         setBaseData(totaldata && totaldata.settings_users)
    //         setIconEnable(false)
    //     } else {
    //         setClearText(e.target.value)
    //         if (e.target.value.length > 0) {
    //             setIconEnable(true)
    //         } else {
    //             setIconEnable(false)
    //         }
    //         const filterTable = totaldata && totaldata.settings_users.filter(o =>
    //             Object.keys(o).some(k =>
    //                 String(o[k])
    //                     .toLowerCase()
    //                     .includes(e.target.value.toLowerCase())
    //             )
    //         );
    //         setBaseData(filterTable)
    //     }

    // };


    const namef = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.map((item) => {
        return item
    })
    console.log(namef, "namefnamef");
    // const json = JSON.parse(namef)
    // const value = Object.values(json)[0]
    // for (let i in value) {
    //     let division = _.filter(namef.data, { unit_id: Number(value[i]) })
    //     console.log(division, "divisiondivision");

    // }
    // console.log(namef, "namefnamefnamef");
    // // console.log(value, "jsonjsonjson");
    // console.log(unitid, "unitidunitid");

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <Link> My Accounts</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }} >
                                        <Link>Settings</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <Modal title="Unit Wise Status" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                        {totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].text}
                    </Checkbox>
                    <Divider />
                    <CheckboxGroup className="checkboxgroup" options={plainOptions} value={checkedList} onChange={onChange2} />
                </Modal>
                <Modal title="Unit Wise Status" visible={isModalOpen1} onOk={handleOk1} onCancel={handleCancel1}>
                    <Checkbox onChange={onCheckAllChange1} checked={checkAll1}>
                        {totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].text}
                    </Checkbox>
                    <Divider />
                    <CheckboxGroup className="checkboxgroup" options={plainOptions1} value={checkedList1} onChange={onChange21} />
                </Modal>
                <Modal title="Unit Wise Status" visible={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}>
                    <Checkbox onChange={onCheckAllChange2} checked={checkAll2}>
                        {totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].text}
                    </Checkbox>
                    <Divider />
                    <CheckboxGroup className="checkboxgroup" options={plainOptions2} value={checkedList2} onChange={onChange22} />
                </Modal>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body" style={{ padding: "5px 5px" }}>
                                    <div className='col-md-12' style={{ marginTop: "20px" }}>
                                        <div className='row'>
                                            <div className='col-md-2'>
                                                <label style={{ float: "right" }}><b>Legal Entity </b><span style={{ color: "red" }}>*</span></label>
                                            </div>
                                            <div className='col-md-4'>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <Select
                                                        onChange={(value, data) => {
                                                            setCurrentEntity(value)
                                                            setSettingsData({
                                                                ...settingsdata,
                                                                legel_entity: data.children
                                                            })
                                                        }}
                                                        style={{ width: '100%' }}
                                                        value={settingsdata.legel_entity || undefined}
                                                        placeholder="Enter Legal Entity"

                                                    >
                                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}                                                        </Select>
                                                    : <p >{localStorage.getItem('SelectedEntity')}</p>}
                                                {validator.current.message(
                                                    'legalentity',
                                                    settingsdata.legel_entity,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-3'>
                                                <label style={{ float: "right" }}> <b>Enable 2 levels of approval<span style={{ color: "red" }}>*</span></b> </label>
                                            </div>
                                            <div className='col-md-1'>
                                                <input type="radio" checked={totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].two_level_approve === true ? true : false} onClick={() => {
                                                    const payloads = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "HaveCompliancesLELevel",
                                                                {
                                                                    "le_id": [
                                                                        "z51Z"
                                                                    ],
                                                                    "product_id": "1"
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                    getapprovallist({
                                                        payload: payloads,
                                                        paramid: paramid,
                                                        formkey: formdata.form_key
                                                    })
                                                    // toast.warning("Request Failed Process")
                                                }} />
                                                <label style={{ marginLeft: "5px" }}>Yes</label>
                                            </div>
                                            <div className='col-md-1'>
                                                <input type="radio" checked={totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].two_level_approve === false ? true : false} />
                                                <label style={{ marginLeft: "5px" }}>No</label>
                                            </div>
                                            <div className='col-md-1'></div>
                                        </div>
                                    </div>
                                    {entityid != "null" ?
                                        <Tabs type="card" style={{ marginTop: "20px" }}>
                                            <TabPane tab="Mail Settings" key="1" >
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-2'></div>
                                                        <div className='col-md-3'>
                                                            <label style={{ float: "right", marginTop: "10px" }}><b>Rectify Task :</b><span style={{ color: "red" }}>*</span> </label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <input type="radio" value="1" checked={task.rectify_task === "1"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    rectify_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>On</label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <input type="radio" value="2" checked={task.rectify_task === "2"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    rectify_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>Off</label>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            {task.rectify_task === "1" ?
                                                                <Button style={{ float: "right" }} type="primary" onClick={showModal}>Settings</Button>
                                                                : ""}
                                                        </div>
                                                        <div className='col-md-3'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-2'></div>
                                                        <div className='col-md-3'>
                                                            <label style={{ float: "right", marginTop: "10px" }}><b>Rejected Task :</b><span style={{ color: "red" }}>*</span> </label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <input type="radio" value="1" checked={task.rejected_task === "1"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    rejected_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>On</label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <input type="radio" value="2" checked={task.rejected_task === "2"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    rejected_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>Off</label>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            {task.rejected_task === "1" ?
                                                                <Button style={{ float: "right" }} type="primary" onClick={showModal1}>Settings</Button>
                                                                : ""}
                                                        </div>
                                                        <div className='col-md-3'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-2'></div>
                                                        <div className='col-md-3'>
                                                            <label style={{ float: "right", marginTop: "10px" }}><b>Reassigned Task :</b><span style={{ color: "red" }}>*</span> </label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <input type="radio" value="1" checked={task.reassigned_task === "1"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    reassigned_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>On</label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <input type="radio" value="2" checked={task.reassigned_task === "2"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    reassigned_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>Off</label>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            {task.reassigned_task === "1" ?
                                                                <Button style={{ float: "right" }} type="primary" onClick={showModal2}>Settings</Button>
                                                                : ""}
                                                        </div>
                                                        <div className='col-md-3'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-2'></div>
                                                        <div className='col-md-3'>
                                                            <label style={{ float: "right", marginTop: "10px" }}><b>Assignee Task Calendar :</b><span style={{ color: "red" }}>*</span> </label>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <input type="radio" value="1" checked={task.assignee_task === "1"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    assignee_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>Weekly</label>
                                                        </div>
                                                        <div className={task.assignee_task != "2" ? 'col-md-2' : 'col-md-3'}>
                                                            <input type="radio" value="2" checked={task.assignee_task === "2"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    assignee_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>Monthly</label>
                                                            {task.assignee_task === "2" ?
                                                                <Select style={{ marginLeft: "40px", width: "100px" }}
                                                                    defaultValue={"Select"}
                                                                    value={monthlydata.assignee}
                                                                    onChange={(value) => {
                                                                        setMonthlyData({
                                                                            ...monthlydata,
                                                                            assignee: value
                                                                        })
                                                                    }}
                                                                >
                                                                    <Option value=""> Select </Option>
                                                                    <Option value="1">1</Option>
                                                                    <Option value="2">2</Option>
                                                                    <Option value="3">3</Option>
                                                                    <Option value="4">4</Option>
                                                                    <Option value="5">5</Option>
                                                                    <Option value="6">6</Option>
                                                                    <Option value="7">7</Option>
                                                                    <Option value="8">8</Option>
                                                                    <Option value="9">9</Option>
                                                                    <Option value="10">10</Option>
                                                                    <Option value="11">11</Option>
                                                                    <Option value="12">12</Option>
                                                                    <Option value="13">13</Option>
                                                                    <Option value="14">14</Option>
                                                                    <Option value="15">15</Option>
                                                                    <Option value="16">16</Option>
                                                                    <Option value="27">17</Option>
                                                                    <Option value="28">18</Option>
                                                                    <Option value="19">19</Option>
                                                                    <Option value="20">20</Option>
                                                                    <Option value="21">21</Option>
                                                                    <Option value="22">22</Option>
                                                                    <Option value="23">23</Option>
                                                                    <Option value="24">24</Option>
                                                                    <Option value="25">25</Option>
                                                                    <Option value="26">26</Option>
                                                                    <Option value="27">27</Option>
                                                                    <Option value="28">28</Option>
                                                                    <Option value="29">29</Option>
                                                                    <Option value="30">30</Option>
                                                                    <Option value="31">31</Option>
                                                                </Select> : ""}
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <input type="radio" value="3" checked={task.assignee_task === "3"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    assignee_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>Daily</label>
                                                        </div>
                                                        {task.assignee_task !== "2" ?
                                                            <div className='col-md-1'></div> : ''}
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-5'></div>
                                                        <div className='col-md-2'>
                                                            <label>(Every Sunday)</label>
                                                        </div>
                                                        <div className='col-md-5'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-2'></div>
                                                        <div className='col-md-3'>
                                                            <label style={{ float: "right", marginTop: "10px" }}><b>Approver Task Calendar :</b><span style={{ color: "red" }}>*</span> </label>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <input type="radio" value="1" checked={task.approver_task === "1"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    approver_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>Weekly</label>
                                                        </div>
                                                        <div className={task.approver_task != "2" ? 'col-md-2' : 'col-md-3'}>
                                                            <input type="radio" value="2" checked={task.approver_task === "2"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    approver_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>Monthly</label>
                                                            {task.approver_task == "2" ?
                                                                <Select style={{ marginLeft: "40px", width: "100px" }}
                                                                    defaultValue={"Select"}
                                                                    value={monthlydata.approver}
                                                                    onChange={(value) => {
                                                                        setMonthlyData({
                                                                            ...monthlydata,
                                                                            approver: value
                                                                        })
                                                                    }}
                                                                >
                                                                    <Option value=""> Select </Option>
                                                                    <Option value="1">1</Option>
                                                                    <Option value="2">2</Option>
                                                                    <Option value="3">3</Option>
                                                                    <Option value="4">4</Option>
                                                                    <Option value="5">5</Option>
                                                                    <Option value="6">6</Option>
                                                                    <Option value="7">7</Option>
                                                                    <Option value="8">8</Option>
                                                                    <Option value="9">9</Option>
                                                                    <Option value="10">10</Option>
                                                                    <Option value="11">11</Option>
                                                                    <Option value="12">12</Option>
                                                                    <Option value="13">13</Option>
                                                                    <Option value="14">14</Option>
                                                                    <Option value="15">15</Option>
                                                                    <Option value="16">16</Option>
                                                                    <Option value="27">17</Option>
                                                                    <Option value="28">18</Option>
                                                                    <Option value="19">19</Option>
                                                                    <Option value="20">20</Option>
                                                                    <Option value="21">21</Option>
                                                                    <Option value="22">22</Option>
                                                                    <Option value="23">23</Option>
                                                                    <Option value="24">24</Option>
                                                                    <Option value="25">25</Option>
                                                                    <Option value="26">26</Option>
                                                                    <Option value="27">27</Option>
                                                                    <Option value="28">28</Option>
                                                                    <Option value="29">29</Option>
                                                                    <Option value="30">30</Option>
                                                                    <Option value="31">31</Option>
                                                                </Select> : ""}
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <input type="radio" value="3" checked={task.approver_task === "3"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    approver_task: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "10px" }}>Daily</label>
                                                        </div>
                                                        {/* <div className='col-md-1'></div> */}
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-5'></div>
                                                        <div className='col-md-2'>
                                                            <label>(Every Sunday)</label>
                                                        </div>
                                                        <div className='col-md-5'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-5'></div>
                                                        <div className='col-md-2'>
                                                            <Button style={{ alignItems: "center" }} onClick={onsubmit} type="primary" >Submit</Button>
                                                        </div>
                                                        <div className='col-md-5'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-3'>
                                                            <label>Country : {totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].country_name}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Business Group : {totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].business_group_name}</label>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <label>Legal Entity : {totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].legal_entity_name}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-3'>
                                                            <label>Contract From : {totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].contract_from}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Contract To : {totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].contract_to}</label>
                                                        </div>
                                                        <div className='col-md-5'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4'>
                                                            <h3>Domain/Organization Details</h3>
                                                        </div>
                                                        <div className='col-md-4'></div>
                                                    </div>
                                                </div>
                                                <div className='ant-table ant-table-bordered ant-table-scroll-horizontal'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content'>
                                                            <table  >
                                                                <thead className='ant-table-thead'>
                                                                    <tr>
                                                                        <th style={{ width: "30px" }}>Domain</th>
                                                                        <th style={{ width: "30px" }}><center>Activation Date</center></th>
                                                                        <th style={{ width: "30px" }}>Organization</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='ant-table-tbody'>
                                                                    <tr>
                                                                        <td className='ant-table-cell'><center>{datalist && datalist[0] && datalist[0].domain_name}</center></td>
                                                                        <td className='ant-table-cell'><center>{datalist && datalist[0] && datalist[0].activity_date}</center></td>
                                                                        <td className='ant-table-cell'><center>{datalist && datalist[0] && datalist[0].organisation_name}</center></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-1'></div>
                                                        <div className='col-md-4'>
                                                            <label>File Space Used</label> <Progress percent={(((totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].used_file_space) / (totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].file_space_limit)) * 100).toFixed(2)} />

                                                        </div>
                                                        <div className='col-md-2'></div>
                                                        <div className='col-md-4'>
                                                            <label>File Space Available</label><Progress percent={(100 - (totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].used_file_space) / (totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].file_space_limit) * 100).toFixed(2)} status="active" />

                                                        </div>
                                                        <div className='col-md-1'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label><b>Licence Holders List</b></label>
                                                        </div>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4'>
                                                            <label>
                                                                <span>Total Licence(s): <span style={{ marginLeft: "5px", marginRight: "5px" }}>{totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].total_licence}</span></span>-
                                                                <span style={{ marginLeft: "10px" }}> Used: <span style={{ marginLeft: "5px", marginRight: "5px" }}>{totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].used_licence}</span></span>|
                                                                <span style={{ marginLeft: "10px" }}>Remaining:<span style={{ marginLeft: "5px" }}>{(totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].total_licence) - (totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].used_licence)}</span></span> </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-12' style={{ marginTop: "20px", marginBottom: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4'></div>
                                                        <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                                                            <div className="search-box" >
                                                                <input class="search-txt" value={clearText} onChange={searchRecords} style={{ marginLeft: "180px" }} type="text" placeholder="Type to Search" />
                                                                <a className="search-btn" >
                                                                    {iconEnable == true ?
                                                                        <i class="fas fa-close" style={{ marginLeft: "180px" }} onClick={(e) => { searchRecords('clear') }}></i>
                                                                        : <i className="fas fa-search" style={{ marginLeft: "180px" }}></i>}

                                                                </a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div> */}

                                                <Table style={{ marginTop: "30px" }} columns={columns} dataSource={baseData} onChange={onChange} />
                                            </TabPane>
                                            <TabPane tab="Reminders/Escalation Settings" key="2">
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label style={{ float: "right" }}><b>Reassign service provider compliance remainder to all:</b> <span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                        <div className='col-md-1' style={{ paddingRight: 0 }}>
                                                            <label>For Every</label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <Input type='text' style={{ width: "50px" }}
                                                                maxLength={2}
                                                                autoFocus={true}
                                                                // value={days.sp_compliance || undefined}
                                                                defaultValue={days.sp_compliance} key={days.sp_compliance}
                                                                onChange={(e) => {
                                                                    setDays({
                                                                        ...days,
                                                                        sp_compliance: Number(e.target.value)
                                                                    })
                                                                }}
                                                            ></Input>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <label style={{ marginTop: "5px" }}>Days</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label style={{ float: "right" }}><b>Remainder to assignee before due date after trigger:</b> <span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <input type="radio" value="1" checked={task.after_trigger === "1"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    after_trigger: e.target.value
                                                                })

                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "5px" }}>On</label>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <input type="radio" value="2" checked={task.after_trigger === "2"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    after_trigger: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "5px" }}>Off For Every</label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <Input type='text' style={{ width: "50px" }}
                                                                maxLength={2}
                                                                autoFocus={true}
                                                                disabled={task.after_trigger === "2"}
                                                                defaultValue={days.after_trigger} key={days.after_trigger}
                                                                onChange={(e) => {
                                                                    setDays({
                                                                        ...days,
                                                                        after_trigger: Number(e.target.value)
                                                                    })
                                                                }}
                                                            // value={days.after_trigger} 
                                                            ></Input>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <label style={{ marginTop: "5px" }}>Days</label>
                                                        </div>
                                                        <div className='col-md-1'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label style={{ float: "right" }}><b>Remainder to assignee with Escalation after due date:</b> <span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <input type="radio" value="1" checked={task.after_due_date === "1"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    after_due_date: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "5px" }}>On</label>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <input type="radio" value="2" checked={task.after_due_date === "2"} onClick={(e) => {
                                                                setTask({
                                                                    ...task,
                                                                    after_due_date: e.target.value
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "5px" }}>Off For Every</label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <Input type='text' style={{ width: "50px" }}
                                                                maxLength={2}
                                                                autoFocus={true}
                                                                disabled={task.after_due_date === "2"}
                                                                defaultValue={days.after_due_date} key={days.after_due_date}
                                                                onChange={(e) => {
                                                                    setDays({
                                                                        ...days,
                                                                        after_due_date: Number(e.target.value)
                                                                    })
                                                                }}
                                                                // value={days.after_due_date || undefined }
                                                            ></Input>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <label style={{ marginTop: "5px" }}>Days</label>
                                                        </div>
                                                        <div className='col-md-1'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-5'></div>
                                                        <div className='col-md-2'>
                                                            <Button style={{ alignItems: "center" }} onClick={onsubmit} type="primary" >Submit</Button>
                                                        </div>
                                                        <div className='col-md-5'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-3'>
                                                            <label>Country : {countrydata && countrydata[0].c_name}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Business Group : {totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].business_group_name}</label>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <label>Legal Entity : {totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].legal_entity_name}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-3'>
                                                            <label>Contract From : {totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].contract_from}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Contract To : {totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].contract_to}</label>
                                                        </div>
                                                        <div className='col-md-5'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4'>
                                                            <h3>Domain/Organization Details</h3>
                                                        </div>
                                                        <div className='col-md-4'></div>
                                                    </div>
                                                </div>
                                                <div className='ant-table ant-table-bordered ant-table-scroll-horizontal'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content'>
                                                            <table  >
                                                                <thead className='ant-table-thead'>
                                                                    <tr>
                                                                        <th style={{ width: "30px" }}>Domain</th>
                                                                        <th style={{ width: "30px" }}><center>Activation Date</center></th>
                                                                        <th style={{ width: "30px" }}>Organization</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='ant-table-tbody'>
                                                                    <tr>
                                                                        <td className='ant-table-cell'><center>{datalist && datalist[0] && datalist[0].domain_name}</center></td>
                                                                        <td className='ant-table-cell'><center>{datalist && datalist[0] && datalist[0].activity_date}</center></td>
                                                                        <td className='ant-table-cell'><center>{datalist && datalist[0] && datalist[0].organisation_name}</center></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-1'></div>
                                                        <div className='col-md-4'>

                                                            <label>File Space Used</label> <Progress percent={(((totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].used_file_space) / (totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].file_space_limit)) * 100).toFixed(2)} />



                                                        </div>
                                                        <div className='col-md-2'></div>
                                                        <div className='col-md-4'>

                                                            <label>File Space Available</label><Progress percent={(100 - (totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].used_file_space) / (totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].file_space_limit) * 100).toFixed(2)} status="active" />


                                                        </div>
                                                        <div className='col-md-1'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label><b>Licence Holders List</b></label>
                                                        </div>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4'>
                                                            <label>
                                                                <span>Total Licence(s): <span style={{ marginLeft: "5px", marginRight: "5px" }}>{totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].total_licence}</span></span>-
                                                                <span style={{ marginLeft: "10px" }}> Used: <span style={{ marginLeft: "5px", marginRight: "5px" }}>{totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].used_licence}</span></span>|
                                                                <span style={{ marginLeft: "10px" }}>Remaining:<span style={{ marginLeft: "5px" }}>{(totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].total_licence) - (totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].used_licence)}</span></span> </label>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-12' style={{ marginTop: "20px", marginBottom: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4'></div>
                                                        <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                                                            <div className="search-box" >
                                                                <input class="search-txt" value={clearText} onChange={searchRecords} style={{ marginLeft: "180px" }} type="text" placeholder="Type to Search" />
                                                                <a className="search-btn" >
                                                                    {iconEnable == true ?
                                                                        <i class="fas fa-close" style={{ marginLeft: "180px" }} onClick={(e) => { searchRecords('clear') }}></i>
                                                                        : <i className="fas fa-search" style={{ marginLeft: "180px" }}></i>}

                                                                </a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div> */}
                                                <Table style={{ marginTop: "30px" }} columns={columns1} dataSource={totaldata && totaldata.settings_users} onChange={onChange1} />
                                            </TabPane>
                                        </Tabs> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    getdata,
    getdatalist,
    getdatatable,
    getapprovallist
})(Settings);