import { REASSIGNREPORT, REASSIGNREPORTSHOW } from './../../types/index'
const initialState = {
    reassignreport: {
        reassignreports: [],
        logo_url: [],
        Reassign_history_show: [],
        total_count: []
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REASSIGNREPORT:
            return {
                ...state,
                reassignreport: {
                    ...state.reassignreport,
                    reassignreports: payload,
                },
            };
        case REASSIGNREPORTSHOW:
            return {
                ...state,
                reassignreport: {
                    ...state.reassignreport,
                    // logo_url: payload[1].logo_url,
                    Reassign_history_show: payload[1],
                    // total_count: payload[1].total_count
                },
            };
        default:
            return state;
    }
}