import { CHANGE_THEME } from "../../types";

const initialState = {
    current_theme : []
}

export default function (state = initialState , action) {
    const {type, payload} = action;
    switch (type) {
        case CHANGE_THEME : {
            console.log(typeof JSON.parse(localStorage.getItem('sessionValue')).login_response.theme, 'PAYLOAD THEMEEE')
            localStorage.setItem('sessionValue', localStorage.getItem('sessionValue').replace(JSON.parse(localStorage.getItem('sessionValue')).login_response.theme, payload.theme) )
            return {
                ...state,
                current_theme: payload
            }
        }
        default:
            return state;
    }
}