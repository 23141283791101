import React, { useEffect, useState, useRef, Fragment } from 'react'
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Collapse, Modal, Select, Tooltip, Card, DatePicker, Space, Descriptions, Button, Popover, Table, Typography, Input } from 'antd';
import { PlusOutlined, InfoCircleOutlined, PlusCircleOutlined, SearchOutlined, FilterFilled, PlayCircleOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import { TaskRecallFilters, GetTaskRecallList, GetRemarksData, UpdateTaskRecallStatus } from './../../Store/Action/Transcations/Taskrecall';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReactTooltip from "react-tooltip";

const mapStateToProps = (state) => ({
    filterrecall: state.recallfilters.recallfilters,
    filterdatas: state.Statutorysetting.statutorysettings.filterdata

})

const TaskRecall = ({
    TaskRecallFilters,
    GetTaskRecallList,
    GetRemarksData,
    UpdateTaskRecallStatus,
    filterrecall: { userunitsrecall, recallcompliance, recallremarks, recallcompliancestatus, count },
    filterdatas
}) => {
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const [page, setPage] = useState(1);
    const { TextArea } = Input;
    const formValidator2 = useRef(new SimpleReactValidator());
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [showValueModal, setShowValueModal] = useState(false)
    const [acccordionTrue, setAccordionTrue] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [passwordmodal, Setpasswordmodal] = useState(false);
    const [FormShow, setFormShow] = useState(false);
    const [checkedRow, setCheckedRow] = useState(false);
    const [mapFilter, setMapFilter] = useState([]);
    const [FormRecall, setFormRecall] = useState(false);
    const [filteredArray, setFilteredArray] = useState([])
    const [legalstate, Setlegalstate] = useState(false)
    const location = useLocation();
    const paramid = location.pathname;
    const authtoken = localStorage.getItem('authToken');
    //  const entityid = localStorage.getItem('SelectedEntityid');
    const isAuth = localStorage.getItem('isAuthenticated');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const { Option } = Select;
    const _ = require("lodash");
    const formValidator = useRef(new SimpleReactValidator());
    const recallSubmit = useRef(new SimpleReactValidator());
    const [entires, setEntires] = useState(0)
    const [countvalue, setCountvalue] = useState(false)
    const [remarksvalue, Setremarksvalue] = useState();
    const [showdata, Setshowdata] = useState({
        domain: '',
        unit: '',
        frequency: '',
        due_month: '',
        act: '',
        // legal_entity: '',
        legal_entity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),

    })
    const [temp, setTemp] = useState([]);
    const [titleDynamic, setTitleDynamic] = useState([]);

    const [temparr, setTemparr] = useState([])
    const [finalchild, Setfinalchild] = useState([])
    const [childlist, Setchildlist] = useState([])

    const [recalldata, Setrecalldata] = useState({
        action: '',
        remarks: ''
    })

    const [password, Setpassword] = useState({
        passwordvalue: '',
    })
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder='Search'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterFilled
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [entityid, setCurrentEntity] = useState("");
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != "null") {
            setCurrentEntity(localStorage.getItem('SelectedEntityid').replaceAll('"', ''))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    useEffect(() => {
        if (showdata.legal_entity == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [showdata.legal_entity])
    useEffect(() => {
        if (temp) {
            let tempARR = []

            for (let i in temp) {
                tempARR.push(temp[i].child)
            }
            Setchildlist(tempARR)
        }
    }, [temp])
    useEffect(() => {
        if (finalchild && finalchild.length > 0) {
            setEntires(finalchild.length)
        }
    }, [finalchild])
    useEffect(() => {
        let tempArr = []
        for (let k in childlist) {
            tempArr.push(...childlist[k])
            Setfinalchild(tempArr)

        }

    }, [childlist])
    const [valueModal, setValueModal] = useState({
        unit: '',
        address: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: '',
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: '',
        compliance_description: '',
        interim_details: ''
    })

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    const column = [
        {
            title: 'S.No.',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                Setrecalldata({ ...recalldata, remarks: record.r_description })
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },

    ]

    const columns = [
        {
            title: <Fragment>
                <input type="checkbox" name="allchecked" />
            </Fragment>,
            dataIndex: false,
            key: false,
            ellipsis: true,
            width: '7px',
            align: 'center',
            render: (text, record) => {
                return (
                    <Fragment>
                        <input type="checkbox"
                            name={'checked' + record.parentId}
                            onClick={(e) => {
                                let checked = e.target.checked;
                                addSelectedDataValues(
                                    checked,
                                    record.compliance_history_id,
                                );
                            }}
                        />
                    </Fragment>
                )
            }
        },
        {
            title: "Action",
            render: (row, record) => {

                if (record.compliance_frequency == 'Periodical') {
                    return <Tooltip title={record && record.compliance_frequency}><p><i style={{ color: "#008000" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (record.compliance_frequency == 'FlexiReview') {
                    return <Tooltip title={record && record.compliance_frequency}><p><i style={{ color: "#F4BDD5" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (record.compliance_frequency == 'Review') {
                    return <Tooltip title={record && record.compliance_frequency}><p><i style={{ color: "#FFA500" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
                else if (record.compliance_frequency == 'On Occurrence') {
                    return <Tooltip title={record && record.compliance_frequency}><p><i style={{ color: "#FAF329" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else {
                    return <Tooltip title={record && record.compliance_frequency}><p><i style={{ color: "#FF0000" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
            },
            width: '10px',
            align: 'center',
            // ...getColumnSearchProps('compliance_frequency'),
        },
        {
            title: "Assigned on",
            dataIndex: 'assigned_on',
            key: 'assigned_on',
            ellipsis: true,
            width: '15px',
            ...getColumnSearchProps('assigned_on'),

        },
        {
            title: "Compliance Task",
            dataIndex: 'compliance_name',
            key: 'compliance_name',
            ...getColumnSearchProps('compliance_name'),


            // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
            render: (row, record) => {

                const content = (
                    <table style={{ width: "600px", display: 'block', height: '250px', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Vendor Name</th>
                                <td>{record.unit_name}</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>{record.address}</td>
                            </tr>
                            <tr>
                                <th>Division</th>
                                <td>{record.division_name}</td>
                            </tr>
                            <tr>
                                <th>Category</th>
                                <td>{record.category_name}</td>
                            </tr>
                            <tr>
                                <th>Act/Rules</th>
                                <td>{record.statu}</td>
                            </tr>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.compliance_description}</td>
                            </tr>
                            <tr>
                                <th>Penal Consequqnces</th>
                                <td>{record.penal_consequences}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <span style={{ cursor: "pointer" }}
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.document_reference_number,
                                    next_due_date: record.next_due_date,
                                    address: record.address,
                                    compliance_description: record.compliance_description,
                                    uploaded_document: record.upload_docs,
                                    interim_details: record.interim_docs,
                                    remarks: record.remarks

                                })
                            }}>{record.statu}</span>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>{record.compliance_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: '50px',
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        },
        {
            title: "Assignee",
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            width: '20px',
            align: 'center',
            ...getColumnSearchProps('assignee_name'),


        },
        {
            title: "Compliance Status",
            dataIndex: 'task_status',
            key: 'task_status',
            width: '20px',
            ellipsis: true,
            ...getColumnSearchProps('task_status'),


        },
        {
            title: "Due Date",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '10px',
            ...getColumnSearchProps('due_date'),


        },
        {
            title: "Compliance Date",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '15px',
            ...getColumnSearchProps('due_date'),


        },
    ];
    const Active = () => {
        if (temparr.length > 0) {
            setFormRecall(true)
        }
        else {
            toast.error("Please Select Compliance to recall")
        }
        if (recallSubmit.current.allValid()) {
            if (temparr.length !== 0) {
                Swal.fire({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {

                        Setpasswordmodal(true)
                    }
                })
            }
        }
    }

    const addAllSelectedDataValues = (checked, currentParId, parentData) => {

        let array
        let tempArray = temparr
        var ele = document.getElementsByName('checked' + currentParId);
        if (checked === true) {
            setCheckedRow(true)
            array = _.filter(finalchild, { parentId: currentParId });
            for (let i in array) {
                tempArray.push(array[i])
            }
            setTemparr([...tempArray])
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            for (var x = tempArray.length - 1; x >= 0; x--) {
                if (tempArray[x].parentId === currentParId) {
                    tempArray.splice(x, 1)
                }
            }
            setTemparr([...tempArray])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }

    const addSelectedDataValues = (checked, compliance_history_id) => {
        var ele = document.getElementsByName('allchecked');
        let array
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {
                array = _.find(recallcompliance, { compliance_history_id: compliance_history_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].compliance_history_id === compliance_history_id) {
                        tempArray.splice(i, 1)
                    }
                    if (recallcompliance.length !== temparr.length) {
                        setCheckedRow(false)
                        for (var j = 0; j < ele.length; j++) {
                            if (ele[j].type === 'checkbox')
                                ele[j].checked = false;
                        }
                    }
                }
            }
            setTemparr([...tempArray])
        } else {
            if (checked === true) {
                array = _.find(recallcompliance, { compliance_history_id: compliance_history_id });
                tempArray.push(array)
                if (recallcompliance.length === temparr.length) {
                    setCheckedRow(true)
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }

            } else {
                for (let i in tempArray) {
                    if (tempArray[i].compliance_history_id === compliance_history_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }

    const submitpassword = (e) => {
        setAddFormSubmit2(true)

        e.preventDefault();
        if (formValidator2.current.allValid()) {

            let temp = []
            for (let i in temparr) {
                temp.push({
                    "compliance_id": temparr[i].compliance_id,
                    "compliance_history_id": temparr[i].compliance_history_id,
                    "unit_idl": temparr[i].unit_id,
                    "download_doc": temparr[i].download_url,
                    "reviewer_doc": temparr[i].reviwer_newdoc,
                    "interm_doc": temparr[i].interm_newdoc,
                    "interim_docs_size": Number(temparr[i].interim_docs_size),
                    "reviwer_docs_size": Number(temparr[i].reviwer_docs_size),
                    "document_size": temparr[i].document_size,
                    "recall_status": temparr[i].recall_status,
                    "recall_id": temparr[i].recall_id
                })
            }
            UpdateTaskRecallStatus({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateTaskRecallStatus",
                            {
                                "le_id": entityid,
                                "check_list": temp,
                                "action": recalldata.action,
                                "remarks": recalldata.remarks,
                                "password": password.passwordvalue,
                                "pwd_validate": true
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
        }
        //Setpasswordmodal(false);
    }

    const showValue = () => {
        setShowValueModal(true);
    };

    const passwordcancel = () => {
        Setpasswordmodal(false);
        setAddFormSubmit2(false)
        Setpassword({
            ...password,
            passwordvalue: ''
        })
    }

    const { Panel } = Collapse;

    const handleOk = () => {
        setRemarksModal(false)
    };

    const handleCancel = () => {
        setRemarksModal(false)
        setShowValueModal(false);
    };

    const gettaskrecalllist = () => {
        setFormShow(true)
        if (formValidator.current.allValid()) {
            setAccordionTrue(true)
            GetTaskRecallList({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetTaskRecallList",
                                {
                                    "le_id": entityid,
                                    "unit_id": (showdata.unit) ? Number(showdata.unit) : null,
                                    "d_id": Number(showdata.domain),
                                    "frequency_id": (showdata.frequency) ? Number(showdata.frequency) : 0,
                                    "compl_date": showdata.due_month,
                                    "parent_id": Number(showdata.act),
                                    "user_id": null,
                                    "start_count": entires,
                                    "task_status": "Task Recall"
                                }
                            ]
                        }
                    ],
                paramid: paramid
            })
        }
    }

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    useEffect(() => {
        formValidator.current.showMessages()
        recallSubmit.current.showMessages()
        formValidator2.current.showMessages()

    }, [])

    useEffect(() => {
        if (filteredArray.length > 0) {
            {
                filteredArray.map((item) => {
                    return (
                        setMapFilter(item)
                    )
                })
            }
        } else {
            setMapFilter([])
        }
    }, [filteredArray])

    useEffect(() => {
        if (recallcompliance && recallcompliance.length > 0) {
            let uniqueObjArray = [...new Map(recallcompliance && recallcompliance.length > 0 && recallcompliance.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(recallcompliance, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([tempArr])
            }
        } else {
            setFilteredArray([])
        }
    }, [recallcompliance])
    useEffect(() => {
        if (recallcompliance !== null) {
            let totalList = []
            let arr = [];
            let countrylisttempdata = [];
            let tempSetArray = [];
            let parId = []
            recallcompliance && recallcompliance.length > 0 && recallcompliance.map(function (user) {

                if ((arr.indexOf(user.unit_name) < 0))
                    arr.push(user.unit_name);
            });

            arr.map((pmenu, index) => {
                let entityArray = []
                let tempArray = []

                if (pmenu) {

                    recallcompliance && recallcompliance.map((subMenuList, childIndex) => {
                        if (pmenu === subMenuList.unit_name) {

                            entityArray = {
                                'address': subMenuList.address,
                                'approve_status': subMenuList.approve_status,
                                'approved_on': subMenuList.approved_on,
                                'assigned_on': subMenuList.assigned_on,
                                'assignee_name': subMenuList.assignee_name,
                                'category_name': subMenuList.category_name,
                                'compfielaw_url': subMenuList.compfielaw_url,
                                'completion_date': subMenuList.completion_date,
                                'compliance_description': subMenuList.compliance_description,
                                'compliance_frequency': subMenuList.compliance_frequency,
                                'compliance_history_id': subMenuList.compliance_history_id,
                                'compliance_id': subMenuList.compliance_id,
                                'compliance_name': subMenuList.compliance_name,
                                'country_id': subMenuList.country_id,
                                'division_name': subMenuList.division_name,
                                'document_reference_number': subMenuList.document_reference_number,
                                'document_size': subMenuList.document_size,
                                'domain_id': subMenuList.domain_id,
                                'domain_name': subMenuList.domain_name,
                                'download_url': subMenuList.download_url,
                                'due_date': subMenuList.due_date,
                                'duration_type': subMenuList.duration_type,
                                'extn_due_date': subMenuList.extn_due_date,
                                'file_names': subMenuList.file_names,
                                'format_file_name': subMenuList.format_file_name,
                                'history_count': subMenuList.history_count,
                                'interim_count': subMenuList.interim_count,
                                'interim_docs': subMenuList.interim_docs,
                                'interim_docs_size': subMenuList.interim_docs_size,
                                'interm_newdoc': subMenuList.interm_newdoc,
                                'next_due_date': subMenuList.next_due_date,
                                'occurrence_remarks': subMenuList.occurrence_remarks,
                                'penal_consequences': subMenuList.penal_consequences,
                                'recall_id': subMenuList.recall_id,
                                'recall_status': subMenuList.recall_status,
                                'remarks': subMenuList.remarks,
                                'remarks_count': subMenuList.remarks_count,
                                'reviwer_docs': subMenuList.reviwer_docs,
                                'reviwer_docs_size': subMenuList.reviwer_docs_size,
                                'reviwer_newdoc': subMenuList.reviwer_newdoc,
                                'start_date': subMenuList.start_date,
                                'statu': subMenuList.statu,
                                'system_remarks': subMenuList.system_remarks,
                                'task_status': subMenuList.task_status,
                                'tz_date': subMenuList.tz_date,
                                'tz_name': subMenuList.tz_name,
                                'unit_id': subMenuList.unit_id,
                                'unit_name': subMenuList.unit_name,
                                'upload_docs': subMenuList.upload_docs,
                                'validity_date': subMenuList.validity_date,
                                'validity_settings_days': subMenuList.validity_settings_days,
                                'parentId': index,
                                'childId': childIndex,


                            }
                            tempArray.push(entityArray)

                        }
                    })

                }
                totalList = {
                    'index': index,
                    'parentData': pmenu,
                    'child': tempArray
                }
                titleDynamic[index] = pmenu;
                countrylisttempdata.push(totalList)


            })
            setTitleDynamic([...titleDynamic])
            var merged = [].concat.apply([], countrylisttempdata);
            setTemp([...temp, ...merged])
            //  setData(tempSetArray)
            //getData()
        }
    }, [recallcompliance])

    useEffect(() => {
        if (isAuth) {
            if(entityid!==''){
            if (entityid != "null") {
                TaskRecallFilters({
                    payload: [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "TaskRecallFilters",
                                {
                                    "le_id": entityid
                                }
                            ]
                        }
                    ],
                    paramid: paramid
                })
            }
            }

        }
    }, [isAuth, entityid])

    // useEffect(() => {
    //     if (showdata.legal_entity == '' || null || undefined) {
    //         Setshowdata({
    //             ...showdata,
    //             legal_entity: entityid
    //         })

    //     }
    // }, [showdata.legal_entity])

    // const showmore = () => {

    //         GetTaskRecallList({
    //             payload:
    //                 [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetTaskRecallList",
    //                             {
    //                                 "le_id": entityid,
    //                                 "unit_id": (showdata.unit) ? Number(showdata.unit) : null,
    //                                 "d_id": Number(showdata.domain),
    //                                 "frequency_id": (showdata.frequency) ? Number(showdata.frequency) : 0,
    //                                 "compl_date": showdata.due_month,
    //                                 "parent_id": Number(showdata.act),
    //                                 "user_id": null,
    //                                 "start_count": entires,
    //                                 "task_status": "Task Recall"
    //                             }
    //                         ]
    //                     }
    //                 ],
    //             paramid: paramid
    //         })
    //     }

    // const showmore = () => {

    //     const payload10 = [
    //         authtoken,
    //         {
    //             "session_token": authtoken,
    //             "request": [
    //                 "GetComplianceForUnits",
    //                 {
    //                     "le_id": entityid && entityid,
    //                     "u_ids": unitid,
    //                     "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
    //                     "r_count": entires,
    //                     "f_ids": frequencyid,
    //                     "tz_name": data1.timezone
    //                 }
    //             ]

    //         }
    //     ]
    //     showmoredata({
    //         payload: payload10,
    //         paramid: paramid
    //     })
    // }
    const defaultTitle = () => 'Here is title';
    const tableProps = {
        title: defaultTitle
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-0 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Task Recall</span>
                                </li>
                            </ol>
                        </nav>
                        {/* <h2 className="mb-0 fw-bold">Task Recall</h2> */}
                    </div>


                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <Collapse accordion defaultActiveKey={['1']}>
                                <Panel header="Task Recall" key="1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4" >
                                                {/* <div className="col-md-8" > */}
                                                {/* <div className="mb-3"> */}
                                                {/* <label>Legal Entity:</label> */}
                                                <label htmlFor=""><b>Legal Entity: </b></label><span style={{ color: "red" }}>*</span><br />
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Legal Entity"
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    Setlegalstate(true)
                                                                    setCurrentEntity(data)
                                                                    Setshowdata({
                                                                        ...data,
                                                                        legal_entity: data,
                                                                        domain: '',
                                                                        unit: '',
                                                                        frequency: '',
                                                                        asignee: '',
                                                                        due_month: ''
                                                                    })
                                                                }
                                                                else {
                                                                    Setlegalstate(false)
                                                                    setCurrentEntity('')
                                                                    Setshowdata({
                                                                        ...data,
                                                                        legal_entity: '',
                                                                        domain: '',
                                                                        unit: '',
                                                                        frequency: '',
                                                                        asignee: '',
                                                                        due_month: ''
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.legal_entity || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'legal_entity',
                                                            showdata.legal_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                                }
                                                {/* </div> */}
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Domain: </b></label><span style={{ "color": "red" }}>*</span><br />
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        name="domain"
                                                        id="domain"
                                                        placeholder="Enter domain"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: data,
                                                                    unit: '',
                                                                    frequency: '',
                                                                    asignee: '',
                                                                    due_month: '',
                                                                    act: ''

                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: '',
                                                                    unit: '',
                                                                    frequency: '',
                                                                    asignee: '',
                                                                    due_month: '',
                                                                    act: ''

                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.domain || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                        disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        {userunitsrecall && userunitsrecall.domains_list && userunitsrecall.domains_list.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {formValidator.current.message(
                                                        'domain',
                                                        showdata.domain,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Vendor: </b></label><br />
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter unit"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    unit: data,
                                                                    frequency: '',
                                                                    due_month: '',
                                                                    act: ''
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    unit: '',
                                                                    frequency: '',
                                                                    due_month: '',
                                                                    act: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.unit || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                        disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        {userunitsrecall && userunitsrecall.user_units && userunitsrecall.user_units.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Frequency: </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter frequecy"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.frequency || undefined}

                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {userunitsrecall && userunitsrecall.frequency_list && userunitsrecall.frequency_list.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">

                                                <div className="form-group">

                                                    <label htmlFor=""><b>Due Month: </b></label> <span style={{ "color": "red" }}>*</span>
                                                    <Space className='date' id='date' direction="vertical" style={{ width: '100%', marginTop: "5px" }}>

                                                        <DatePicker disabled={showdata.domain === "" ? true : false} style={{ "width": "100%" }} onChange={(date, dateString) => {
                                                            Setshowdata({
                                                                ...showdata,
                                                                due_month: moment(dateString).format('MMMM-YYYY')
                                                            })
                                                        }} picker="month" />
                                                    </Space>
                                                    {formValidator.current.message(
                                                        'date',
                                                        showdata.due_month,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Due Month Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Act: </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter act"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    act: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    act: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.act || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                        disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        {userunitsrecall && userunitsrecall.act_list && userunitsrecall.act_list.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.statutory_mapping}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                        {/* <a href={() => false} style={{ marginLeft: '40%' }} onClick={gettaskrecalllist} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</a> */}
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '47%', marginTop: "5px" }}
                                            size='default' onClick={gettaskrecalllist}>Show
                                        </Button>
                                    </div>
                                </Panel>
                            </Collapse>
                            {acccordionTrue === true ?
                                <div className="mt-2">
                                    <Collapse defaultActiveKey={['0']} accordion>
                                        <Panel header="Compliance Table" style={{ backgroundColor: 'skyblue' }} key="0">
                                            {temp && temp.length > 0 ? temp.map((itemdata, i) => {
                                                return (
                                                    <>
                                                        <Table
                                                            // className='userprivclass'
                                                            size={'small'}
                                                            columns={columns}
                                                            dataSource={itemdata.child}
                                                            bordered
                                                            title={() => {
                                                                return itemdata.child[0].unit_name
                                                            }}
                                                            // {...tableProps}
                                                            scroll={{ x: 1500, y: 1000 }}
                                                            pagination={false}
                                                            onHeaderRow={(columns, index) => {
                                                                return {
                                                                    onClick: (e) => {
                                                                        let checked = e.target.checked;
                                                                        addAllSelectedDataValues(
                                                                            checked,
                                                                            itemdata.index
                                                                        );
                                                                    }

                                                                };
                                                            }}
                                                        />
                                                    </>
                                                )
                                            }) : <p style={{ textAlign: 'center' }} >No Compliance Available</p>}
                                        </Panel>
                                    </Collapse>
                                    <div className='row'>
                                        <div className='col-md-4'></div>
                                        <div className='col-md-4'>
                                            {entires != count ?
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '20%', marginTop: '5%' }}
                                                    size='default' onClick={gettaskrecalllist} >Show More
                                                </Button>
                                                : ""}
                                        </div>
                                        <div className='col-md-4'></div>
                                    </div>
                                    {temp && temp.length > 0 ?
                                        <>
                                            <div>
                                                <div className="col-md-12 m-5">
                                                    <div className="row">
                                                        <label htmlFor="">Total Compliance : {recallcompliance.length}</label>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 m-5">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="">Action</label>
                                                            <span style={{ color: "red" }}>*</span>
                                                            <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                defaultValue={"Please Select"}
                                                                onChange={(e) => {
                                                                    Setrecalldata({
                                                                        ...recalldata,
                                                                        action: e
                                                                    })
                                                                }}
                                                            >
                                                                <Option value=""> Please Select </Option>
                                                                <Option value="1"> Rectify & Retain Document </Option>
                                                                <Option value="2"> Rectify & Remove Document </Option>
                                                            </Select>
                                                            {recallSubmit.current.message(
                                                                'action',
                                                                recalldata.action,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${FormRecall ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Please select Action to Recall',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="">Remarks</label>
                                                            <span style={{ color: "red" }}>*</span>
                                                            <div class="d-flex align-items-end">
                                                                <TextArea name="remarks" className='form-control' placeholder='Enter Remarks'
                                                                    value={recalldata.remarks || undefined} maxLength={500} id="remarks" cols="5" rows="0"
                                                                    onChange={(e) => {
                                                                        Setrecalldata({
                                                                            ...recalldata,
                                                                            remarks: e.target.value
                                                                        })
                                                                    }}></TextArea>
                                                                <span className="ms-2 btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                                                    onClick={() => { showRemarksModal() }}>
                                                                    <PlusOutlined />
                                                                </span>
                                                            </div>
                                                            {recallSubmit.current.message(
                                                                'remarks',
                                                                recalldata.remarks,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${FormRecall ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Remarks Required',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-1 ml25">
                                                <PlusCircleOutlined onClick={() => { showRemarksModal() }} />
                                            </div> */}

                                                    <Modal title="Remarks List" visible={remarksModal} footer={null} onOk={handleOk} onCancel={handleCancel}>
                                                        <Table
                                                            // className='userprivclass'
                                                            size={'small'}
                                                            columns={column}
                                                            dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                                                            bordered
                                                            pagination={{
                                                                defaultPageSize: dataTableProperties.pagesize,
                                                                showSizeChanger: dataTableProperties.sizechanger,
                                                                pageSizeOptions: dataTableProperties.pageSizeOptions
                                                            }}
                                                        />
                                                    </Modal>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                                            {/* <a href={() => false} onClick={Active} style={{ marginLeft: '10%' }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Recall</a> */}
                                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '10%', marginTop: '8%', float: 'right' }}
                                                                size='default' onClick={Active}>Recall
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> </> : ''}
                                </div>
                                : ''}
                            <Modal title="Domain" visible={showValueModal} footer={false} onCancel={handleCancel}>
                                <Descriptions title="Other Details" layout="vertical" className='labelclass'>
                                    <Descriptions.Item label="Vendor"><Tooltip title={valueModal.address}>
                                        <InfoCircleOutlined />
                                    </Tooltip>&nbsp;{valueModal.unit}</Descriptions.Item>
                                    <Descriptions.Item label="Compliance Task"><Tooltip title={valueModal.compliance_description}>
                                        <InfoCircleOutlined />
                                    </Tooltip>&nbsp;
                                        {valueModal.compliance_task}</Descriptions.Item>
                                    <Descriptions.Item label="Compliance Frequency">{valueModal.compliance_frequency}</Descriptions.Item>
                                    <Descriptions.Item label="Uploaded File Size">-</Descriptions.Item>
                                    <Descriptions.Item label="Attached Documents">{valueModal.uploaded_document}</Descriptions.Item>
                                    <Descriptions.Item label="Task Completion Date/Document Issued Date">{valueModal.document_issued_date}</Descriptions.Item>
                                    <Descriptions.Item label="Document Reference Number">
                                        {valueModal.document_reference_number}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Remarks">
                                        {valueModal.remarks}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Interim details">
                                        {valueModal.interim_details}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Modal>
                            <Modal visible={passwordmodal} footer={null} onCancel={passwordcancel} className="newStyleModalPassword">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-6">
                                            <label className="control-label">Password:</label>
                                            <span style={{ "color": "red" }}>*</span>
                                            <input
                                                style={{ marginTop: "10px" }}
                                                type="password"
                                                id="password"
                                                name='passwordvalue'
                                                className="form-control"
                                                placeholder="Enter password"
                                                onChange={(e) => {
                                                    Setpassword({
                                                        ...password,
                                                        passwordvalue: e.target.value
                                                    })
                                                }}
                                                value={password.passwordvalue}
                                            />
                                            {formValidator2.current.message(
                                                'Password',
                                                password.passwordvalue,
                                                ['required'],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        regex: 'Password Required'
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="form-actions">

                                    <Button type="primary" shape="round" className='addbutton'
                                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%', marginTop: "10px" }}
                                        icon={<PlayCircleOutlined />} size='default'
                                        onClick={submitpassword}
                                    >Submit
                                    </Button>
                                    {/* <button
                                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start" onClick={passwordcancel}>
                                        <div className="d-flex align-items-center">
                                        <i data-feather="save" className="feather-sm me-1 fill-icon"></i>
                                        Cancel
                                        </div>
                                    </button> */}
                                </div>

                            </Modal>
                        </div>
                    </div>
                </div >
            </div >
        </div >

    )
}

export default connect(mapStateToProps, {
    TaskRecallFilters,
    GetTaskRecallList,
    GetRemarksData,
    UpdateTaskRecallStatus
})(TaskRecall)