import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { UNIT_CLOSURE_LIST } from '../../types/index'


const initialState = {
   
    UnitClosure:{
        UnitClosureList:'',
        
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UNIT_CLOSURE_LIST: {
            return {
                ...state,
                UnitClosure: {
                    ...state.UnitClosure,
                    UnitClosureList:payload[1].unit_closure_units
                },
            };
        }
      
        default:
            return state;
    }
}