import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import moment from 'moment';
import { Collapse, Select, Input, DatePicker, Card, Pagination, Popover, Drawer, Button, Table, Tooltip, Modal, Descriptions, Empty, Space } from 'antd';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ArrowRightOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, SearchOutlined, FilterFilled, ExclamationCircleTwoTone, ArrowLeftOutlined, PlayCircleOutlined, BookTwoTone } from '@ant-design/icons';
import { getCompliancestatuschart, getCriticalityFilter, getCommonData, getComplianceTrendShowMoreTableData, getCompliancestatusTable, getTimeZoneData } from '../../Store/Action/Dashboard/ComplianceStatusChart';
import { toast } from 'react-toastify';
const mapStateToProps = (state) => ({
    dataValue: state.ComplianceStatusChart.complianceStatusChart.chartData,
    // criticalityChart: state.ComplianceStatusChart.complianceStatusChart.criticality,
    time: state.ComplianceStatusChart.complianceStatusChart.time,
    commonData: state.ComplianceStatusChart.complianceStatusChart.Data,
    TableValue: state.ComplianceStatusChart.complianceStatusChart.Table,
    timeZoneData: state.ComplianceStatusChart.complianceStatusChart.timeZoneData,
    showMoreData: state.ComplianceStatusChart.complianceStatusChart.showMoreData,

})

const ComplianceStatusChart = ({
    getCompliancestatuschart,
    getCriticalityFilter,
    getCompliancestatusTable,
    getComplianceTrendShowMoreTableData,
    dataValue,
    commonData,
    TableValue,
    getCommonData,
    getTimeZoneData,
    timeZoneData,
    showMoreData,
    // criticalityChart,
    time
}) => {
    console.log(dataValue, 'dataValue');
    const _ = require("lodash");
    var [serialNo, setserialNo] = useState(1)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [chartType, setChartType] = useState('count')
    const [open, setOpen] = useState(false)
    console.log(open, 'open');
    const { Panel } = Collapse;
    let [counts, setcounts] = useState(0)
    const [chartId, setChartId] = useState()
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [checkBox, setCheckBox] = useState(true)
    const [compliance, setCompliance] = useState("")
    const [categoryName, setCategoryName] = useState()
    const [indexValue, setIndexValue] = useState([])
    const [chartName, setChartName] = useState("stacked")
    const [domainName, setDomainName] = useState()
    const [columChart, setCloumnChart] = useState([])
    console.log(columChart, 'columChart');
    const [country, setCountry] = useState([])
    const [domainListData, setDomainListData] = useState([])
    const [showmoreCount, setShowMoreCount] = useState(0)
    const [filterChartName, setfilterChartName] = useState('donut')
    const [dataCount, setDataCount] = useState()
    const [LegalEntityList, setLegalEntityList] = useState([]);
    const [EntityDatas, setEntityDatas] = useState([])
    const [DomainList, setDomainList] = useState([]);
    const [countryDomain, setCountryDomain] = useState([])
    console.log(countryDomain, "countryDomain");
    const [domainDatas, setDomainDatas] = useState([])
    const [multiSelectChart, setmultiSelectChart] = useState([])
    const [selectChart, setSelectChart] = useState([])
    const [multiSelect, setmultiSelect] = useState(false)
    const [businessGroupData, setBusinessGroupdata] = useState([])
    const [categoryList, setCategoryList] = useState([]);
    const [divisionList, setDivisionList] = useState([]);
    const [categoryDatas, setcategoryDatas] = useState([])
    const [unitList, setUnitList] = useState([]);
    const [unitDatas, setunitDatas] = useState([])
    console.log(dataCount, 'dataCount');
    const [countryId, setcountryId] = useState([])
    const [division, setDivision] = useState([])
    const [chartData, setchartData] = useState([])
    console.log(chartData, "chartDatachartData");
    const [go, setGo] = useState(false)
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '20', '30']
    })
    const [totalCount, setTotalCount] = useState(0)
    const [year, setYear] = useState(moment().year())
    const [count, setCount] = useState(0)
    const [columncount, setcolumncount] = useState(0)
    const [chordCount, setchordCount] = useState(0)
    const [chordChart, setChordChart] = useState([])
    const [chordChartData, setchordChartData] = useState([])
    const [countryselectcheckbox, Setcountryselectcheckbox] = useState('block');
    const [countryunselectcheckbox, Setcountryunselectcheckbox] = useState('none');
    const [domainselectcheckbox, Setdomainselectcheckbox] = useState('block');
    const [domainunselectcheckbox, Setdomainunselectcheckbox] = useState('none');
    const [domain, setDomain] = useState([])
    const [divisionselectcheckbox, Setdivisionselectcheckbox] = useState('block');
    const [divisionUnselectcheckbox, SetdivisionUnselectcheckbox] = useState('none');
    const [divisionDatas, setdivisionDatas] = useState([])
    const [divisionData, setdivisionData] = useState([])
    const [categoryselectcheckbox, Setcategoryselectcheckbox] = useState('block');
    const [categoryunselectcheckbox, SetcategoryUnselectcheckbox] = useState('none');
    const [categoryData, setcategoryData] = useState([])
    console.log(categoryData, 'categoryData');
    const [unitselectcheckbox, Setunitselectcheckbox] = useState('block');
    const [unitUnselectcheckbox, SetunitUnselectcheckbox] = useState('none');
    const [unitData, setunitData] = useState([])

    const [Entityselectcheckbox, SetEntityselectcheckbox] = useState('block');
    const [EntityUnselectcheckbox, SetEntityUnselectcheckbox] = useState('none');
    const [Entity, setEntity] = useState([])
    const [businessselectcheckbox, Setbusinessselectcheckbox] = useState('block');
    const [businessunselectcheckbox, Setbusinessunselectcheckbox] = useState('none');
    const [business, setBusiness] = useState([])
    const [businessIds, setBusinessIds] = useState([])
    const [businessgroupValue, setBusinessGroupValue] = useState([])
    const [domainIds, setDomainIds] = useState([])
    console.log(year, 'yearyear');
    const [category, setCategory] = useState([])
    const [table, setTable] = useState(false)
    const [dateState, setDateState] = useState(false)
    const [columnState, setCoulmnState] = useState(false)

    console.log(dateState, 'dateState');

    const searchInput = useRef(null);

    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const [unit, setUnit] = useState([])
    const [show, setShow] = useState(false)
    const [fieldType, setFieldType] = useState("")
    const [filterData, setFilterData] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: '',
        chartType: 'Group',
        from_date: null,
        to_date: null,
        from_year: null,
        from_month: null,
        from_date_only: null
    })
    console.log(multiSelectChart, 'multiSelectChart');
    console.log(dataValue, 'chartName');
    console.log(fieldType, 'fieldType');
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],
        unit: [],

    })
    const chart = useRef(null);
    const { Option, OptGroup } = Select;
    console.log(commonData, 'commonData');
    console.log(commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id, ' commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id');
    const [criticaltityStatus, setCriticalityStatus] = useState(false)
    const [criticality, setCriticality] = useState("A")
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const country_info = sessionParsedValue.country_info
    const [entityData, setEntityData] = useState([])
    const [tableData, setTableData] = useState([])
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    console.log(time, 'dataaassssss');
    console.log(TableValue, 'TableValue');
    console.log(domain, 'domain');
    const location = useLocation();
    const [generatedOn, setGeneratedOn] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [tempState, setTempState] = useState({
        bg: [],
        le: [],
        div: [],
        cat: [],
        unit: []
    })
    const [textValue, setTextValue] = useState()
    const [trueState, setTrueState] = useState([])
    const [divArrLen, setDivArrLen] = useState([])
    const [catArrLen, setCatArrLen] = useState([])
    const [unitArrLen, setUnitArrLen] = useState([])
    const [leLen, setLeLen] = useState([])
    const [divCount, setDivCount] = useState()
    const [catCount, setCatCount] = useState()
    const [unitCount, setUnitCount] = useState()
    const [leCount, setLeCount] = useState()
    const [uniqueCharacters, setUniqueCharacters] = useState()
    console.log(showmoreCount, 'showmoreCount');

    console.log(business, 'business');
    console.log(textValue, 'aishuuu');
    console.log(divArrLen, 'divArrLen');
    console.log(businessIds, 'businessIds');

    const [paramid, setParamId] = useState("")

    // useEffect(() => {
    //     if (fieldType != '') {
    //         // if (fieldType == 'BusinessGroup' && business.length > 0) {
    //         //     setTempState({
    //         //         ...tempState,
    //         //         bg: business
    //         //     })
    //         // }
    //         // else if (fieldType == 'LegalEntity' && Entity.length > 0) {
    //         //     setTempState({
    //         //         ...tempState,
    //         //         le: Entity
    //         //     })
    //         // }
    //         setTempState({
    //             bg: fieldType == 'BusinessGroup' && business.length > 0 ? business : [],
    //             le: fieldType == 'LegalEntity' && Entity.length > 0 ? Entity : []
    //         })
    //     }
    // }, [fieldType != ''])


    // useEffect(() => {
    //     if (trueState) {
    //         let uniqueChars = [...new Set(trueState && trueState.length > 0 && trueState)];
    //         console.log(uniqueChars, 'uniqueChars');
    //         setUniqueCharacters(uniqueChars)
    //     }
    // }, [trueState])

    // console.log(uniqueCharacters,'uniqueCharacters');


    useEffect(() => {
        if (divisionList && divisionList.length > 0) {
            let temp = []
            for (let i in divisionList) {
                console.log(divisionList[i].options, 'divisionList[i].options');
                temp.push(divisionList[i].options.length)
            }
            setDivArrLen([...temp])
        }
        if (categoryList && categoryList.length > 0) {
            let temp1 = []
            for (let i in categoryList) {
                console.log(categoryList[i].options, 'divisionList[i].options');
                temp1.push(categoryList[i].options.length)
            }
            setCatArrLen([...temp1])
        }
        if (unitList && unitList.length > 0) {
            let temp2 = []
            for (let i in unitList) {
                console.log(unitList[i].options, 'divisionList[i].options');
                temp2.push(unitList[i].options.length)
            }
            setUnitArrLen([...temp2])
        }
        if (LegalEntityList && LegalEntityList.length > 0) {
            let temp3 = []
            for (let i in LegalEntityList) {
                console.log(LegalEntityList[i].options, 'divisionList[i].options');
                temp3.push(LegalEntityList[i].options.length)
            }
            setLeLen([...temp3])
        }


    }, [divisionList, categoryList, unitList, LegalEntityList])

    useEffect(() => {
        if (divArrLen) {
            // for(let i in divArrLen){
            let divC = divArrLen.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setDivCount(divC)
            // }
        }
        if (catArrLen) {
            let catL = catArrLen.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setCatCount(catL)
        }
        if (unitArrLen) {
            let unitL = unitArrLen.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setUnitCount(unitL)
        }
        if (leLen) {
            let leL = leLen.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setLeCount(leL)
        }

    }, [divArrLen, catArrLen, unitArrLen])

    useEffect(() => {
        if (trueState && trueState.length) {
            console.log('hloooo');
            setTempState({
                bg: ((trueState && trueState.includes('Business')) && (business && business.length > 0)) ? business : [],
                le: ((trueState && trueState.includes('Legal')) && (Entity && Entity.length > 0)) ? Entity : [],
                div: ((trueState && trueState.includes('Division')) && (divisionDatas && divisionDatas.length > 0)) ? divisionDatas : [],
                cat: ((trueState && trueState.includes('Category')) && (categoryData && categoryData.length > 0)) ? categoryData : [],
                unit: ((trueState && trueState.includes('Unit')) && (unitData && unitData.length > 0)) ? unitData : [],
            })
        }

    }, [trueState.length, business, Entity, categoryData, divisionDatas, unitData])

    useEffect(() => {
        if (tempState) {
            let text1 = tempState && tempState.bg && tempState.bg.length > 0 ? `| Business Group : Selected ${tempState.bg.length} out of ${businessGroupData.length}` : ''
            let text2 = tempState && tempState.le && tempState.le.length > 0 ? ` | Legal Entity : Selected  ${tempState.le.length} out of ${Entity.length}` : ''
            let text3 = tempState && tempState.div && tempState.div.length > 0 ? ` | Division : Selected ${tempState.div.length} out of ${divCount}` : ''
            let text4 = tempState && tempState.cat && tempState.cat.length > 0 ? ` | Category : Selected ${tempState.cat.length} out of ${catCount}` : ''
            let text5 = tempState && tempState.unit && tempState.unit.length > 0 ? ` | Unit : Selected ${tempState.unit.length} out of ${unitCount}` : ''
            // let text3 = text1 + text2
            let text7 = text1 + text2 + text3 + text4 + text5
            setTextValue(text7)
            console.log(text7, 'text7');
        }

    }, [tempState, divCount, catCount, unitCount])

    useEffect(() => {
        if (country.length > 0) {
            let temp = []
            for (let i in country) {
                temp.push(Number(country[i]))
            }
            setcountryId(temp)
        }
    }, [country])
    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            let domainIds = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_id
            })
            setDomainName(domainNameArray)
            setDomainIds(domainIds)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    console.log(domainIds, 'domainIds')

    useEffect(() => {
        {

            var the_arr = location.pathname.split('/');

            the_arr.pop();

            let a = the_arr.join('/')

            setParamId(a)

        }

    }, []);
    console.log(tableData, 'tableData');
    useEffect(() => {
        if (tableData && tableData.length > 0) {
            let count = []
            for (let i in tableData) {
                for (let j in tableData[i].drill_compliances) {
                    let a = tableData[i].drill_compliances[j]
                    let data = Object.values(a)


                    count.push(data.length)

                    // count.push(data[i])
                    console.log(data, ' taskDistributionTable[i].ap_compliances');

                }
            }
            console.log(count, 'count');
            let countValues = count.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setShowMoreCount(countValues)
            console.log(countValues, 'countValue9999');
        }

    }, [tableData])
    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
            }
            console.log(tempIds, 'tempIds');
            // setDIds(tempIds)
            setCountryDomain(tempArr)
            console.log(tempArr, 'tempArr');
        }

    }, [commonData])

    console.log(divisionData, 'divisionData');

    useEffect(() => {

        if (chartName == "pie") {
            if (dataValue && dataValue.length > 0) {
                let datacount
                let temp = []
                let temp2 = []
                let temp3 = []
                for (let i in dataValue) {
                    temp.push(dataValue[i].c_data)
                }
                console.log(temp, 'temppp');
                for (let j in temp) {
                    temp2.push(temp[j][0])
                }
                for (let k in temp2) {
                    datacount = temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count
                    if (dataValue[k].filter_type == "LegalEntity") {

                        let data = _.find(commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos, { le_id: dataValue[k].filter_type_id_le })
                        let obj = {
                            "data": data.le_name,
                            "count": temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count,
                            "pieValue": [

                                {
                                    "value": temp2[k].complied_count,
                                    "title": "Complied",
                                    "color": "#0de580"
                                },
                                {
                                    "value": temp2[k].delayed_compliance_count,
                                    "title": "Delayed Compliance",
                                    "color": "#3c78d8"
                                },
                                {
                                    "value": temp2[k].inprogress_compliance_count,
                                    "title": "Inprogress",
                                    "color": "#f9c80e"
                                },
                                {
                                    "value": temp2[k].not_complied_count,
                                    "title": "Not Complied",
                                    "color": "#fe4365"
                                }
                            ]

                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Division") {
                        let data = _.find(commonData && commonData.div_infos.length > 0 && commonData.div_infos, { div_id: dataValue[k].filter_type_id })
                        let obj = {
                            "data": data.div_name,
                            "count": temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count,
                            "pieValue": [

                                {
                                    "value": temp2[k].complied_count,
                                    "title": "Complied",
                                    "color": "#0de580"
                                },
                                {
                                    "value": temp2[k].delayed_compliance_count,
                                    "title": "Delayed Compliance",
                                    "color": "#3c78d8"
                                },
                                {
                                    "value": temp2[k].inprogress_compliance_count,
                                    "title": "Inprogress",
                                    "color": "#f9c80e"
                                },
                                {
                                    "value": temp2[k].not_complied_count,
                                    "title": "Not Complied",
                                    "color": "#fe4365"
                                }
                            ]

                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Category") {
                        let data = _.find(commonData && commonData.cat_info.length > 0 && commonData.cat_info, { cat_id: dataValue[k].filter_type_id })
                        let obj = {
                            "data": data.cat_name,
                            "count": temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count,
                            "pieValue": [

                                {
                                    "value": temp2[k].complied_count,
                                    "title": "Complied",
                                    "color": "#0de580"
                                },
                                {
                                    "value": temp2[k].delayed_compliance_count,
                                    "title": "Delayed Compliance",
                                    "color": "#3c78d8"
                                },
                                {
                                    "value": temp2[k].inprogress_compliance_count,
                                    "title": "Inprogress",
                                    "color": "#f9c80e"
                                },
                                {
                                    "value": temp2[k].not_complied_count,
                                    "title": "Not Complied",
                                    "color": "#fe4365"
                                }
                            ]

                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Unit") {
                        let data = _.find(commonData && commonData.chart_units.length > 0 && commonData.chart_units, { u_id: dataValue[k].filter_type_id })
                        let obj = {
                            "data": data.u_name,
                            "count": temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count,
                            "pieValue": [

                                {
                                    "value": temp2[k].complied_count,
                                    "title": "Complied",
                                    "color": "#0de580"
                                },
                                {
                                    "value": temp2[k].delayed_compliance_count,
                                    "title": "Delayed Compliance",
                                    "color": "#3c78d8"
                                },
                                {
                                    "value": temp2[k].inprogress_compliance_count,
                                    "title": "Inprogress",
                                    "color": "#f9c80e"
                                },
                                {
                                    "value": temp2[k].not_complied_count,
                                    "title": "Not Complied",
                                    "color": "#fe4365"
                                }
                            ]

                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Group") {
                        console.log('testtttttttttt');
                        let data = _.find(commonData && commonData.countries.length > 0 && commonData.countries, { c_id: dataValue[k].filter_type_id })
                        let obj = {
                            "data": data.c_name,
                            "count": temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count,
                            "pieValue": [

                                {
                                    "value": temp2[k].complied_count,
                                    "title": "Complied",
                                    "color": "#0de580"
                                },
                                {
                                    "value": temp2[k].delayed_compliance_count,
                                    "title": "Delayed Compliance",
                                    "color": "#3c78d8"
                                },
                                {
                                    "value": temp2[k].inprogress_compliance_count,
                                    "title": "Inprogress",
                                    "color": "#f9c80e"
                                },
                                {
                                    "value": temp2[k].not_complied_count,
                                    "title": "Not Complied",
                                    "color": "#fe4365"
                                }
                            ]

                        }
                        temp3.push(obj)
                    }
                    setcolumncount(datacount)

                }
                setCloumnChart(temp3)
                console.log(temp2, 'temp2');
                console.log(temp3, 'temp3');


            }
        }

    }, [dataValue, chartName])

    useEffect(() => {

        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let entityArrayunit = []
        let tempArrayunit = []
        commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
            console.log(subunitlist, 'subunitlist-domain');


            if (country_info && country_info[0].c_id == subunitlist.c_id) {
                entityArrayunit = {

                    'c_id': subunitlist.c_id,
                    'd_id': subunitlist.d_id,
                    'd_name': subunitlist.d_name,
                    'is_active': subunitlist.is_active,
                    'month_from': subunitlist.month_from,
                    'month_to': subunitlist.month_to,
                    'label': subunitlist.d_name,
                    'values': subunitlist.d_id

                }
                tempArrayunit.push(entityArrayunit)
                console.log(entityArrayunit, 'entityArrayunit');
            }

        })
        totalListunit = {
            'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
            'options': tempArrayunit
        }

        countrylisttempdataunit.push(totalListunit)
        setDomainListData(countrylisttempdataunit)

    }, [commonData])





    useEffect(() => {
        let colors = {
            type: "skyblue",
            Complied: '#0de580',
            delayed: '#3c78d8',
            notComplied: "#f9c80e",
            inprogress: "#fe4365"
        }
        if (chartName == "chord") {
            if (dataValue && dataValue.length > 0) {
                let datacount
                let temp = []
                let temp2 = []
                let temp3 = []
                for (let i in dataValue) {
                    temp.push(dataValue[i].c_data)
                }
                console.log(temp, 'temppp');
                for (let j in temp) {
                    temp2.push(temp[j][0])
                }
                for (let k in temp2) {
                    datacount = temp2[k].complied_count + temp2[k].delayed_compliance_count + temp2[k].inprogress_compliance_count + temp2[k].not_complied_count
                    if (dataValue[k].filter_type == "LegalEntity") {

                        let data = _.find(commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos, { le_id: dataValue[k].filter_type_id_le })
                        let obj = [

                            { "from": data.le_name, "color": colors.type },


                            // real data
                            // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
                            { "from": "Complied", "to": data.le_name, "value": temp2[k].complied_count, "color": colors.Complied },
                            { "from": "Delayed Compliance", "to": data.le_name, "value": temp2[k].delayed_compliance_count, "color": colors.delayed },
                            { "from": "Inprogress", "to": data.le_name, "value": temp2[k].inprogress_compliance_count, 'color': colors.inprogress },
                            { "from": "Not Complied", "to": data.le_name, "value": temp2[k].not_complied_count, 'color': colors.notComplied }



                        ]
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Division") {
                        let data = _.find(commonData && commonData.div_infos.length > 0 && commonData.div_infos, { div_id: dataValue[k].filter_type_id })
                        let obj = [

                            { "from": data.div_name, "color": colors.type },


                            // real data
                            // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
                            { "from": "Complied", "to": data.div_name, "value": temp2[k].complied_count, "color": colors.Complied },
                            { "from": "Delayed Compliance", "to": data.div_name, "value": temp2[k].delayed_compliance_count, "color": colors.delayed },
                            { "from": "Inprogress", "to": data.div_name, "value": temp2[k].inprogress_compliance_count, 'color': colors.inprogress },
                            { "from": "Not Complied", "to": data.div_name, "value": temp2[k].not_complied_count, 'color': colors.notComplied }



                        ]
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Category") {
                        let data = _.find(commonData && commonData.cat_info.length > 0 && commonData.cat_info, { cat_id: dataValue[k].filter_type_id })
                        let obj = [

                            { "from": data.cat_name, "color": colors.type },


                            // real data
                            // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
                            { "from": "Complied", "to": data.cat_name, "value": temp2[k].complied_count, "color": colors.Complied },
                            { "from": "Delayed Compliance", "to": data.cat_name, "value": temp2[k].delayed_compliance_count, "color": colors.delayed },
                            { "from": "Inprogress", "to": data.cat_name, "value": temp2[k].inprogress_compliance_count, 'color': colors.inprogress },
                            { "from": "Not Complied", "to": data.cat_name, "value": temp2[k].not_complied_count, 'color': colors.notComplied }


                        ]
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Unit") {
                        let data = _.find(commonData && commonData.chart_units.length > 0 && commonData.chart_units, { u_id: dataValue[k].filter_type_id })
                        let obj = [
                            { "from": data.u_name, "color": colors.type },


                            // real data
                            // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
                            { "from": "Complied", "to": data.u_name, "value": temp2[k].complied_count, "color": colors.Complied },
                            { "from": "Delayed Compliance", "to": data.u_name, "value": temp2[k].delayed_compliance_count, "color": colors.delayed },
                            { "from": "Inprogress", "to": data.u_name, "value": temp2[k].inprogress_compliance_count, 'color': colors.inprogress },
                            { "from": "Not Complied", "to": data.u_name, "value": temp2[k].not_complied_count, 'color': colors.notComplied }


                        ]
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Group") {
                        console.log('testtttttttttt');
                        let data = _.find(commonData && commonData.countries.length > 0 && commonData.countries, { c_id: dataValue[k].filter_type_id })
                        let obj = [

                            { "from": data.c_name, "color": colors.type },


                            // real data
                            // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
                            { "from": "Complied", "to": data.c_name, "value": temp2[k].complied_count, "color": colors.Complied },
                            { "from": "Delayed Compliance", "to": data.c_name, "value": temp2[k].delayed_compliance_count, "color": colors.delayed },
                            { "from": "Inprogress", "to": data.c_name, "value": temp2[k].inprogress_compliance_count, 'color': colors.inprogress },
                            { "from": "Not Complied", "to": data.c_name, "value": temp2[k].not_complied_count, 'color': colors.notComplied }


                        ]
                        temp3.push(obj)
                    }


                }
                setChordChart(temp3)
                console.log(temp2, 'temp2');
                console.log(temp3, 'temp3');
                setchordCount(datacount)

            }
        }

    }, [dataValue, chartName])
    console.log(columncount, 'columncount');
    console.log(chordChart, 'chordChart');
    console.log(showMoreData, 'showMoreDatashowMoreData');
    console.log(tableData, 'tableData');
    console.log(chordCount, 'chordCount');

    useEffect(() => {
        let temp = []
        let temp2 = []
        if (chordChart && chordChart.length > 0) {
            for (let j in chordChart) {


                for (let k in chordChart[j]) {
                    temp2.push(chordChart[j][k])
                }
            }
            setchordChartData(temp2)
            console.log(temp2, 'temp2temp2');
        }
    }, [chordChart])
    console.log(tableData, 'tableData');
    useEffect(() => {
        console.log(tableData, 'tableData');
        console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data5555');
        if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
            let temp = []
            let temp2 = []
            for (let i in tableData) {
                for (let j in showMoreData.drill_down_data) {
                    if (tableData[i].u_name == showMoreData.drill_down_data[j].u_name) {
                        for (let s in tableData[i].drill_compliances) {
                            for (let a in showMoreData.drill_down_data[j].drill_compliances) {
                                console.log(s, 'taskDistributionTable[i].ap_compliances[s]');
                                // console.log(showMoreData.r_drill_down_data[j].ap_compliances[a], 'showMoreData.r_drill_down_data[j].ap_compliances[a]');
                                if (s == a) {
                                    let arr1 = tableData[i].drill_compliances[s]
                                    let arr2 = showMoreData.drill_down_data[j].drill_compliances[a]
                                    let arr3 = arr1.concat(arr2)
                                    console.log(arr3, 'arr3');
                                    tableData[i].drill_compliances[s] = arr3
                                    console.log(s, 'sneha');
                                }
                                else {
                                    tableData[i].drill_compliances[a] = showMoreData.drill_down_data[j].drill_compliances[a]
                                }
                            }
                        }
                        // let newArray = { ...obj, ...objdata }

                        // var newArray = Object.assign({}, taskDistributionTable[i].ap_compliances, showMoreData.r_drill_down_data[j].ap_compliances);
                        // taskDistributionTable[i].ap_compliances = newArray
                        temp2.push(tableData[i].u_name)
                        // setTaskDistributionTable(showMoreData.r_drill_down_data)
                    }

                }
            }
            console.log(temp2, 'temp2');
            for (let i in showMoreData.drill_down_data) {

                if ((temp2.includes(showMoreData.drill_down_data[i].u_name)) == false) {
                    // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
                    console.log(showMoreData.drill_down_data[i], 'datadata');
                    temp.push(showMoreData.drill_down_data[i])
                }



            }
            setTableData([...tableData, ...temp])



        }

    }, [showMoreData])
    // useEffect(() => {

    //     if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
    //         let temp = []
    //         let temp2 = []
    //         for (let i in tableData) {
    //             for (let j in showMoreData.drill_down_data) {
    //                 console.log(tableData, 'tableData');
    //                 console.log(showMoreData, 'showMoreData.drill_down_data');
    //                 console.log(showMoreData.drill_down_data, 'showMoreData');
    //                 console.log(tableData[i].u_name != showMoreData.drill_down_data[j].u_name, 'tableData[i].u_name != showMoreData.drill_down_data[j].u_name');
    //                 console.log(tableData[i].u_name == showMoreData.drill_down_data[j].u_name, 'tableData[i].u_name == showMoreData.drill_down_data[j].u_name');
    //                 if (tableData[i].u_name == showMoreData.drill_down_data[j].u_name) {

    //                     temp2.push(tableData[i].u_name)
    //                     let array = tableData[i].drill_compliances

    //                     var newArray = Object.assign({}, tableData[i].drill_compliances, showMoreData.drill_down_data[j].drill_compliances);
    //                     tableData[i].drill_compliances = newArray

    //                 }






    //                 // else {

    //                 //     console.log(tableData[i], 'aishuu');
    //                 //     console.log(showMoreData.drill_down_data[j], '=showMoreData.drill_down_data[j]');


    //                 //     tableData[i] = showMoreData.drill_down_data[j]

    //                 //     console.log(tableData, 'aishuuVignesh');

    //                 // }

    //             }

    //         }
    //         console.log(temp2, 'temp212355');
    //         for (let i in showMoreData.drill_down_data) {

    //             if ((temp2.includes(showMoreData.drill_down_data[i].u_name)) == false) {
    //                 // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
    //                 console.log(showMoreData.drill_down_data[i], 'datadata');
    //                 temp.push(showMoreData.drill_down_data[i])
    //             }



    //         }
    //         setTableData([...tableData, ...temp])
    //         // setTableData(tableData)
    //     }




    // }, [showMoreData])
    console.log(tableData, 'tableDatatableData');
    const onClose = () => {
        setOpen(false);
        setFilterOpen(false)
    };
    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])
    useEffect(() => {
        if (criticaltityStatus == false) {
            if (dataValue && dataValue.length > 0) {
                let temp = []
                let temp2 = []
                let temp3 = []
                for (let i in dataValue) {
                    temp.push(dataValue[i].c_data)
                }
                console.log(temp, 'temppp');
                for (let j in temp) {
                    temp2.push(temp[j][0])
                }
                for (let k in temp2) {

                    if (dataValue[k].filter_type == "LegalEntity") {
                        let data = _.find(commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos, { le_id: dataValue[k].filter_type_id_le })
                        let obj = {
                            "type": data.le_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "typeId": dataValue[k].filter_type_id_le
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Division") {
                        let data = _.find(commonData && commonData.div_infos.length > 0 && commonData.div_infos, { div_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.div_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "typeId": dataValue[k].filter_type_id
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Category") {
                        let data = _.find(commonData && commonData.cat_info.length > 0 && commonData.cat_info, { cat_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.cat_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "typeId": dataValue[k].filter_type_id
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Unit") {
                        let data = _.find(commonData && commonData.chart_units.length > 0 && commonData.chart_units, { u_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.u_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "typeId": dataValue[k].filter_type_id
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Group") {
                        console.log('testtttttttttt');
                        let data = _.find(commonData && commonData.countries.length > 0 && commonData.countries, { c_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.c_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count,
                            "typeId": dataValue[k].filter_type_id
                        }
                        temp3.push(obj)
                    }


                }
                setchartData(temp3)
                console.log(temp2, 'temp2');
                console.log(temp3, 'temp3');


            }
        }
        else if (criticaltityStatus == true) {
            if (dataValue && dataValue.length > 0) {
                let temp = []
                let temp2 = []
                let temp3 = []
                for (let i in dataValue) {
                    temp.push(dataValue[i].criticality_data)
                }
                console.log(temp, 'temppp');
                for (let j in temp) {
                    temp2.push(temp[j][0])
                }
                for (let k in temp2) {

                    if (dataValue[k].filter_type == "LegalEntity") {
                        let data = _.find(commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos, { le_id: dataValue[k].filter_type_id_le })
                        let obj = {
                            "type": data.le_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Division") {
                        let data = _.find(commonData && commonData.div_infos.length > 0 && commonData.div_infos, { div_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.div_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Category") {
                        let data = _.find(commonData && commonData.cat_info.length > 0 && commonData.cat_info, { cat_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.cat_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Unit") {
                        let data = _.find(commonData && commonData.chart_units.length > 0 && commonData.chart_units, { u_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.u_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count
                        }
                        temp3.push(obj)
                    }
                    else if (dataValue[k].filter_type == "Group") {
                        console.log('testtttttttttt');
                        let data = _.find(commonData && commonData.countries.length > 0 && commonData.countries, { c_id: dataValue[k].filter_type_id })
                        let obj = {
                            "type": data.c_name,
                            "complied_count": temp2[k].complied_count,
                            "delayed_compliance_count": temp2[k].delayed_compliance_count,
                            "inprogress_compliance_count": temp2[k].inprogress_compliance_count,
                            "not_complied_count": temp2[k].not_complied_count
                        }
                        temp3.push(obj)
                    }


                }
                setchartData(temp3)
                console.log(temp2, 'temp2');
                console.log(temp3, 'temp3');


            }
        }


    }, [dataValue])
    console.log(chartData, 'chartData');

    console.log(LegalEntityList, 'LegalEntityListLegalEntityList');
    console.log(EntityDatas, 'EntityDatasEntityDatas');
    console.log(Entity, 'EntityEntity');

    useEffect(() => {
        console.log(compliance, 'compliancecompliance');
        if (compliance == 'Not Complied') {
            setTotalCount(dataValue && dataValue.length && dataValue[0].not_complied_count)
        }
        else if (compliance == 'Inprogress') {
            setTotalCount(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)
        }
        else if (compliance == 'Complied') {
            setTotalCount(dataValue && dataValue.length && dataValue[0].complied_count)
        }
        else if (compliance == 'Delayed Compliance') {
            setTotalCount(dataValue && dataValue.length && dataValue[0].delayed_compliance_count)
        }
    }, [compliance])
    useEffect(() => {
        if (divisionDatas.length > 0) {
            let temp = []
            for (let i in divisionDatas) {
                let data = _.filter(commonData && commonData.cat_info, { div_id: Number(divisionDatas[i]) })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            setcategoryDatas(temp)
        }
        else {
            setcategoryDatas([])
        }
    }, [divisionDatas])

    useEffect(() => {
        if (categoryData.length > 0) {
            let temp = []
            for (let i in categoryData) {
                let data = _.filter(commonData && commonData.chart_units, { category_id: Number(categoryData[i]) })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            setunitDatas(temp)
        }
        else {
            setunitDatas([])
        }
    }, [categoryData])


    console.log(TableValue, 'TableValue');
    useEffect(() => {
        if (TableValue && TableValue.length > 0) {
            // if (show == true) {

            //     setTableData([...tableData, TableValue])
            // }
            // else {
            setTableData(TableValue)
            // }

        }
    }, [TableValue])
    useEffect(() => {
        if (commonData) {
            setfield({
                ...field,
                country: commonData && commonData.countries,
            })
            // setDivision(commonData && commonData.div_infos)
            // setCategory(commonData && commonData.cat_info)
            // setUnit(commonData && commonData.chart_units)
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const unique = _.uniqBy(commonData && commonData.bg_groups, function (e) {

                    return e.bg_id;

                });

                console.log(unique, 'unique');

                setBusinessGroupdata(unique)
            }
            // setBusinessGroupdata(commonData && commonData.bg_groups)
            setDomainDatas(commonData && commonData.d_info)
            setEntityDatas(commonData && commonData.le_did_infos)
            setcategoryDatas(commonData && commonData.cat_info)
            setdivisionData(commonData && commonData.div_infos)
            setunitDatas(commonData && commonData.chart_units)

        }

    }, [isAuth, entityid, commonData])

    useEffect(() => {
        if (commonData) {
            // setfield({
            //     ...field,
            //     country: commonData && commonData.countries,
            // })
            // // setDivision(commonData && commonData.div_infos)
            // // setCategory(commonData && commonData.cat_info)
            // // setUnit(commonData && commonData.chart_units)
            // setDomainDatas(commonData && commonData.d_info)
            // setEntityDatas(commonData && commonData.le_did_infos)
            // setcategoryDatas(commonData && commonData.cat_info)
            // setdivisionDatas(commonData && commonData.div_infos)
            // setunitDatas(commonData && commonData.chart_units)
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {

                let data = commonData && commonData.bg_groups && commonData.bg_groups.length && commonData.bg_groups.map((item, i) => {
                    return `${item.bg_id}`
                })
                setBusiness([...new Set(data)])
            }
            if (commonData && commonData.d_info && commonData.d_info.length > 0) {

                let data = commonData && commonData.d_info && commonData.d_info.length && commonData.d_info.map((item, i) => {
                    return item.d_id
                })
                setDomain([...new Set(data)])
            }
            if (country_info && country_info.length > 0) {

                let data = country_info && country_info.length && country_info.map((item, i) => {
                    return `${item.c_id}`
                })
                setCountry([...new Set(data)])
            }
            if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {

                let data = commonData && commonData.le_did_infos && commonData.le_did_infos.length && commonData.le_did_infos.map((item, i) => {
                    return item.le_id
                })
                setEntity(data)
            }

        }

    }, [isAuth, entityid, commonData])

    // useEffect(() => {
    //     if (business.length > 0) {
    //         setBusinessGroupValue([...new Set()])
    //     }

    // }, [business])
    console.log(business, 'businessValue');


    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        // entityValue && entityValue.length > 0 && entityValue.map(function (user) {

        //     console.log(EntityData, 'EntityData');
        //     console.log(user, 'user.le_id ');
        //     let entityData = _.filter(EntityData, { le_id: user })
        //     console.log(entityData, 'entityData');
        //     arrunit.push(entityData[0])

        // });
        let id = []
        if (business && business.length > 0) {


            for (let i in business) {
                let entityData = _.filter(businessGroupData && businessGroupData.length > 0 && businessGroupData, { bg_id: Number(business[i]) })
                console.log(entityData, 'entityData');
                arrunit.push(entityData[0])
            }
            console.log(arrunit, 'arrunit');

            arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
                let entityArrayunit = []
                let tempArrayunit = []

                EntityDatas && EntityDatas.length > 0 && EntityDatas.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');
                    if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                        entityArrayunit = {
                            'bg_id': subunitlist.bg_id,
                            'le_name': subunitlist.le_name,
                            'le_id': subunitlist.le_id,

                            'label': subunitlist.le_name,
                            'values': subunitlist.le_id
                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                })
                if (tempArrayunit && tempArrayunit.length > 0) {
                    for (let i in tempArrayunit) {
                        id.push(tempArrayunit[i].le_id)
                    }
                }
                totalListunit = {
                    'label': pmenu && pmenu.bg_name == null ? 'Others' : pmenu && pmenu.bg_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'aishwarya');
                console.log(totalListunit, 'unitListData')
                countrylisttempdataunit.push(totalListunit)
            })
            console.log(id, 'oooooooooo');
            setEntity(id)
            setLegalEntityList(countrylisttempdataunit)

        }
        // else {


        //     for (let i in businessGroupData) {
        //         let entityData = _.filter(businessGroupData && businessGroupData.length > 0 && businessGroupData, { bg_id: Number(businessGroupData[i].bg_id) })
        //         console.log(entityData, 'entityData');
        //         arrunit.push(entityData[0])
        //     }
        //     console.log(arrunit, 'arrunit');

        //     arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
        //         let entityArrayunit = []
        //         let tempArrayunit = []

        //         EntityData && EntityData.length > 0 && EntityData.map((subunitlist) => {
        //             console.log(subunitlist, 'subunitlist');
        //             if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
        //                 entityArrayunit = {
        //                     'bg_id': subunitlist.bg_id,
        //                     'le_name': subunitlist.le_name,
        //                     'le_id': subunitlist.le_id,
        //                     'label': subunitlist.le_name,
        //                     'values': subunitlist.le_id
        //                 }
        //                 tempArrayunit.push(entityArrayunit)
        //             }
        //         })

        //         totalListunit = {
        //             'label': pmenu && pmenu.bg_name == null ? 'Others' : pmenu && pmenu.bg_name,
        //             'options': tempArrayunit
        //         }
        //         console.log(totalListunit, 'aishwarya');
        //         console.log(totalListunit, 'unitListData')
        //         countrylisttempdataunit.push(totalListunit)
        //     })
        //     setLegalEntityList(countrylisttempdataunit)

        // }
    }, [business, EntityDatas])
    console.log(LegalEntityList, 'LegalEntityList');
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })

            }


        }
    }, [isAuth, authtoken, paramid, entityid])
    const showmore = () => {
        setShow(true)
        setCount(count + 100)
        const payload = [

            authtoken,

            {

                "session_token": authtoken,

                "request": [
                    "GetComplianceStatusDrillDownData",
                    {
                        "d_ids": domainIds,
                        "from_date": null,
                        "to_date": null,
                        "filter_type": "Group",
                        "filter_id": 1,
                        "compliance_status": compliance,
                        "chart_year": year,
                        "record_count": count + 100,
                        "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                        "country_domains": [
                            {
                                "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                            }
                        ],
                        "criticality": ""
                    }
                ]

            }

        ]



        getComplianceTrendShowMoreTableData({

            payload: payload,

            paramid: paramid

        })
    }

    console.log(fieldType, 'fieldType');



    const filterChange = () => {
        setGo(true)
        if (groupCheckBox == true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceStatusChart",
                        {
                            "c_ids": countryId.length > 0 ? countryId : [commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id],
                            "d_ids": domain.length > 0 ? domain : domainIds,
                            "from_date": (filterData && filterData.from_date == null) || (filterData && filterData.from_date == "") ? null : moment(filterData.from_date).format('DD-MMM-YYYY'),
                            "to_date": (filterData && filterData.to_date == null) || (filterData && filterData.to_date == "") ? null : moment(filterData.to_date).format('DD-MMM-YYYY'),
                            "filter_type": ((fieldType != "") && (fieldType != 'Date')) ? fieldType : filterData.chartType,
                            // filterData && filterData.chartType,
                            "filter_ids":
                                fieldType == "LegalEntity" ? Entity :
                                    fieldType == 'Division' ? divisionDatas :
                                        fieldType == 'Category' ? categoryData :
                                            fieldType == 'Unit' ? unitData :
                                                fieldType == 'BusinessGroup' ? businessIds :
                                                    fieldType == 'Date' ? null :
                                                        1
                            ,
                            "le_ids": Entity && Entity.length > 0 ? Entity : [entityid],
                            "country_domains": countryDomain,
                            "chart_year": year,
                            "criticality": ""
                        }
                    ]
                }
            ]

            getCompliancestatuschart({
                payload: payload,
                paramid: paramid
            })

        }
        else {
            toast.error('Please select the filter')
        }
        setOpen(false);

    }



    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let id = []
        for (let i in Entity) {
            let entityData = _.filter(EntityDatas && EntityDatas.length > 0 && EntityDatas, { le_id: Entity[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            let arr = []

            divisionData && divisionData.length > 0 && divisionData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'd_id': subunitlist.div_id,
                        'd_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.div_name,
                        'values': subunitlist.div_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }

            })

            console.log(tempArrayunit.length, 'tempArrayunit');

            if (tempArrayunit.length > 0) {
                const unique = _.uniqBy(tempArrayunit, function (e) {

                    return e.d_id;

                });
                arr = unique

            }
            if (arr.length > 0) {
                for (let i in arr) {
                    id.push(arr[i].d_id)
                }
            }

            console.log(arr, 'aishuVignesh');

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': arr
            }

            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })

        // if (localStorage.getItem('SelectedEntity') == 'All Legal Entity') {
        setdivisionDatas(id)
        // }

        setDivisionList(countrylisttempdataunit)


    }, [divisionData, EntityDatas, Entity])

    console.log(divisionData, 'divisionData');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let id = []
        // entityValue && entityValue.length > 0 && entityValue.map(function (user) {

        //     console.log(EntityData, 'EntityData');
        //     console.log(user, 'user.le_id ');
        //     let entityData = _.filter(EntityData, { le_id: user })
        //     console.log(entityData, 'entityData');
        //     arrunit.push(entityData[0])

        // });
        let divisionDatas = []

        // for (let j in DivisionValue) {
        //     let division = _.filter(divisionData, { d_id: DivisionValue[j] })
        //     divisionDatas.push(division[0])
        // }
        console.log(divisionDatas, 'divisionDatassssss');
        for (let i in Entity) {
            let entityData = _.filter(EntityDatas && EntityDatas.length > 0 && EntityDatas, { le_id: Entity[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])

            console.log(arrunit, 'arrunit');
        }
        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            let arr = []
            categoryDatas && categoryDatas.length > 0 && categoryDatas.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    if (divisionData.length > 0) {
                        let data = _.find(divisionData, { div_id: subunitlist.div_id })
                        console.log(data, 'datadatadatadata');
                        entityArrayunit = {
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'div_name': data && data.div_name,
                            'le_id': subunitlist.le_id,
                            'bg_id': subunitlist.bg_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id
                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                }
            })
            if (tempArrayunit.length > 0) {
                const unique = _.uniqBy(tempArrayunit, function (e) {

                    return e.cat_id;

                });
                arr = unique

            }
            if (arr.length > 0) {
                for (let i in arr) {
                    id.push(arr[i].cat_id)
                }
            }
            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': arr
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        // if (localStorage.getItem('SelectedEntity') == 'All Legal Entity') {
        setcategoryData(id)
        // }
        setCategoryList(countrylisttempdataunit)


    }, [categoryDatas, EntityDatas, Entity, divisionData])
    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
    //         if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
    //             let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
    //             console.log(filteredLegalEntity, 'filteredLegalEntity');
    //             let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity && filteredLegalEntity[0].bg_id })
    //             console.log(filteredBusinessGroup, 'filteredBusinessGroup');
    //             setBusinessGroupdata(filteredBusinessGroup)
    //             setBusiness(`${filteredBusinessGroup && filteredBusinessGroup.length > 0 && filteredBusinessGroup[0].bg_id}`)
    //             // const unique = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
    //             //     return e.bg_id;
    //             // });
    //             // console.log(unique, 'unique');
    //             // setfield({
    //             //     ...field,
    //             //     business: unique,
    //             //     legalEntity: filteredLegalEntity
    //             // })
    //         }
    //     }


    // }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    //     useEffect(()=>{
    // if(selectChart.length>0){}
    //     },[])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let id = []
        // entityValue && entityValue.length > 0 && entityValue.map(function (user) {

        //     console.log(EntityData, 'EntityData');
        //     console.log(user, 'user.le_id ');
        //     let entityData = _.filter(EntityData, { le_id: user })
        //     console.log(entityData, 'entityData');
        //     arrunit.push(entityData[0])

        // });
        let divisionDatas = []

        // for (let j in DivisionValue) {
        //     let division = _.filter(divisionData, { d_id: DivisionValue[j] })
        //     divisionDatas.push(division[0])
        // }
        console.log(divisionDatas, 'divisionDatassssss');
        for (let i in Entity) {
            let entityData = _.filter(EntityDatas && EntityDatas.length > 0 && EntityDatas, { le_id: Entity[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])

            console.log(arrunit, 'arrunit');
        }
        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            let arr = []
            unitDatas && unitDatas.length > 0 && unitDatas.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {


                    entityArrayunit = {
                        'cat_id': subunitlist.category_id,
                        // 'cat_name': subunitlist.cat_name,
                        // 'div_name': data && data.div_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.business_group_id,
                        'd_ids': subunitlist.d_ids,
                        'division_id': subunitlist.division_id,
                        'u_id': subunitlist.u_id,
                        'u_name': subunitlist.u_name,
                        'label': subunitlist.u_name,
                        'values': subunitlist.u_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }

            })
            if (tempArrayunit.length > 0) {
                for (let i in tempArrayunit) {
                    id.push(tempArrayunit[i].u_id)
                }
            }
            // if (tempArrayunit.length > 0) {
            //     const unique = _.uniqBy(tempArrayunit, function (e) {

            //         return e.cat_id;

            //     });
            //     arr = unique

            // }
            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        // if (localStorage.getItem('SelectedEntity') == 'All Legal Entity') {
        setunitData(id)
        // }
        setUnitList(countrylisttempdataunit)


    }, [unitDatas, EntityDatas, Entity])
    console.log(divisionList, 'divisionList');
    console.log(categoryList, 'categoryList');
    console.log(unitList, 'unitList77777');

    useEffect(() => {
        if ((commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id != undefined) && ((countryDomain && countryDomain.length > 0) && (domainIds && domainIds.length > 0)) && (entityData && entityData.length > 0)) {
            console.log(entityid, 'entttttt');
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request":
                        ["GetComplianceStatusChart",
                            {
                                "c_ids": [
                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                ],
                                "d_ids": domainIds,
                                "filter_type": "Group",
                                "from_date": null,
                                "to_date": null,
                                "criticality": "",
                                "filter_ids": [
                                    1
                                ],
                                "le_ids": entityData,
                                "country_domains": countryDomain,
                                "chart_year": year
                            }
                        ]
                }
            ]
            if (paramid != "") {
                getCompliancestatuschart({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid, commonData, entityData, entityid, year, countryDomain, domainIds])
    console.log(country, 'country');

    const CountryHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (country_info && country_info.length > 0) {
                let temp = []
                for (let i in country_info) {
                    temp.push(country_info[i].c_id)


                }
                setCountry(temp)

            }
            Setcountryselectcheckbox('none');
            Setcountryunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setDomain([])
            setEntity([])
            setBusiness([])
            setBusinessIds([])
            setdivisionDatas([])
            setcategoryData([])
            setunitData([])

            setCountry([])
            Setcountryselectcheckbox('block');
            Setcountryunselectcheckbox('none');
        } else {
            setCountry(obj)
            setDomain([])
            setEntity([])
            setBusiness([])
            setBusinessIds([])
            setdivisionDatas([])
            setcategoryData([])
            setunitData([])

        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    useEffect(() => {
        if (businessGroupData.length > 0 && business.length > 0) {
            if (business.length == businessGroupData.length) {
                Setbusinessselectcheckbox('none');
                Setbusinessunselectcheckbox('block');
            } else {
                Setbusinessselectcheckbox('block');
                Setbusinessunselectcheckbox('none');
            }
        }
        if ((EntityDatas && EntityDatas.length > 0) && (Entity && Entity.length > 0)) {
            if (Entity.length == EntityDatas.length) {
                SetEntityselectcheckbox('none');
                SetEntityUnselectcheckbox('block');
            } else {
                SetEntityselectcheckbox('block');
                SetEntityUnselectcheckbox('none');
            }
        }
        if ((divisionData && divisionData.length > 0) && (divisionDatas && divisionDatas.length > 0)) {
            if (divisionData.length == divisionDatas.length) {
                Setdivisionselectcheckbox('none');
                SetdivisionUnselectcheckbox('block');
            } else {
                Setdivisionselectcheckbox('block');
                SetdivisionUnselectcheckbox('none');
            }
        }
        if ((categoryDatas && categoryDatas.length > 0) && (categoryData && categoryData.length > 0)) {
            if (categoryDatas.length == categoryData.length) {
                Setcategoryselectcheckbox('none');
                SetcategoryUnselectcheckbox('block');
            } else {
                Setcategoryselectcheckbox('block')
                SetcategoryUnselectcheckbox('none');
            }
        }
        if ((unitData && unitData.length > 0) && (unitDatas && unitDatas.length > 0)) {
            if (unitData.length == unitDatas.length) {
                Setunitselectcheckbox('none');
                SetunitUnselectcheckbox('block');
            } else {
                Setunitselectcheckbox('block');
                SetunitUnselectcheckbox('none');
            }
        }
    }, [business, businessGroupData, EntityDatas, Entity, divisionDatas, divisionData, categoryData, categoryDatas, unitData, unitDatas])


    // useEffect(() => {
    //     if (Entity.length > 0) {

    //     }

    // }, [Entity])

    const BusinessHandelChange = (obj, data) => {
        console.log(obj, 'objjjj');
        setFieldType('BusinessGroup')
        setGroupCheckBox(true)
        setCheckBox(false)
        setGo(false)
        let temp = ['Business', 'Legal', 'Division', 'Category', 'Unit']
        setTrueState(temp)
        // let temp = 'Business'
        // trueState.push(temp)
        let allArray = [];
        if (obj.includes('all')) {
            if (businessGroupData && businessGroupData && businessGroupData.length > 0) {
                let temp = []
                let temp1 = []
                for (let i in businessGroupData) {
                    temp.push(`${businessGroupData[i].bg_id}`)
                    temp1.push(`${Number(businessGroupData[i].bg_id)}`)
                    allArray.push(businessGroupData[i].bg_id)

                }
                setBusiness(temp)
                setBusinessIds(temp1)
            }
            Setbusinessselectcheckbox('none');
            Setbusinessunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            setFieldType('BusinessGroup')

            let temp = [];
            setBusiness([]);
            setBusinessIds([])
            Setbusinessselectcheckbox('block');
            Setbusinessunselectcheckbox('none');
        } else {
            setBusiness(obj)
            setBusinessIds(Number(obj))
            setGroupCheckBox(true)
            setCheckBox(false)
            setFieldType('BusinessGroup')
            if (obj.length == businessGroupData.length) {
                Setbusinessselectcheckbox('none');
                Setbusinessunselectcheckbox('block');
            } else {
                Setbusinessselectcheckbox('block');
                Setbusinessunselectcheckbox('none');
            }
            // if (obj.length > 0) {
            //     let temp = []
            //     for (let i in obj) {
            //         let data = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { bg_id: Number(obj[i]) })

            //         for (let j in data) {
            //             temp.push(data[j])
            //         }
            //         // temp.push(data)
            //         console.log(temp, 'tempppp');
            //     }
            //     setEntityData(temp)
            // }
            // else {
            //     setEntityData(userManagementDetails && userManagementDetails.um_legal_entity)
            // }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };





    // const BusinessHandelChange = (obj) => {
    //     console.log(obj, 'objjjj');
    //     let allArray = [];
    //     if (obj.includes('all')) {
    //         if (businessGroupData && businessGroupData.length > 0) {
    //             let temp = []
    //             for (let i in businessGroupData) {
    //                 temp.push(businessGroupData[i].bg_id)


    //             }
    //             setBusiness(temp)
    //             // setfield({
    //             //     ...field,
    //             //     legalEntity: commonData && commonData.le_did_infos
    //             // })

    //         }
    //         Setbusinessselectcheckbox('none');
    //         Setbusinessunselectcheckbox('block');
    //     } else if (obj.includes('unselect')) {
    //         let temp = [];
    //         // setfield({
    //         //     ...field,
    //         //     legalEntity: commonData && commonData.le_did_infos
    //         // })
    //         setBusiness([])
    //         Setbusinessselectcheckbox('block');
    //         Setbusinessunselectcheckbox('none');
    //     } else {
    //         setBusiness(obj)
    //         // if (obj.length > 0) {
    //         //     let temp = []
    //         //     for (let i in obj) {
    //         //         let data = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(obj[i]) })

    //         //         for (let j in data) {
    //         //             temp.push(data[j])
    //         //         }
    //         //         // temp.push(data)
    //         //         console.log(temp, 'tempppp');
    //         //     }
    //         //     setfield({
    //         //         ...field,
    //         //         legalEntity: temp
    //         //     })
    //         // }

    //     }
    //     // setUserDetails({
    //     //     ...userDetails,
    //     //     units: (allArray && allArray.length > 0 ? allArray : obj)
    //     // })
    //     return obj;

    // };
    console.log(division, 'division');
    // const EntityHandleChane = (obj) => {
    //     console.log(field.legalEntity, 'objjfield.domainjj');
    //     let allArray = [];
    //     if (obj.includes('all')) {
    //         if (field.legalEntity && field.legalEntity.length > 0) {
    //             let temp = []
    //             for (let i in field.legalEntity) {
    //                 console.log(field.legalEntity[i].le_id, 'domain[i].d_id');
    //                 temp.push(field.legalEntity[i].le_id)


    //             }
    //             console.log(temp, 'temp');
    //             setEntity(temp)
    //             setFieldType("LegalEntity")

    //         }
    //         SetEntityselectcheckbox('none');
    //         SetEntityUnselectcheckbox('block');

    //     } else if (obj.includes('unselect')) {
    //         let temp = [];

    //         setEntity([])
    //         setFieldType("")
    //         SetEntityselectcheckbox('block');
    //         SetEntityUnselectcheckbox('none');
    //     } else {
    //         console.log(obj, 'obj');
    //         setFieldType("LegalEntity")
    //         setEntity(obj)
    //         // if (obj.length > 0) {
    //         //     let temp = []
    //         //     let temp2 = []
    //         //     for (let i in obj) {
    //         //         console.log(obj,'objobjobj');
    //         //         console.log(commonData && commonData.div_infos,'commonData && commonData.div_infos');
    //         //         let data = _.filter(commonData && commonData.div_infos, { le_id: Number(obj[i]) })
    //         //         console.log(data, 'data3333');
    //         //         for (let j in data) {
    //         //             temp.push(data[j])
    //         //         }
    //         //         let datas = _.filter(commonData && commonData.cat_info, { le_id: Number(obj[i]) })

    //         //         for (let j in datas) {
    //         //             temp2.push(datas[j])
    //         //         }
    //         //         // temp.push(data)
    //         //         console.log(temp, 'tempppp');
    //         //     }
    //         //     setDivision(temp)
    //         //     setCategory(temp2)
    //         // }
    //     }
    //     // setUserDetails({
    //     //     ...userDetails,
    //     //     units: (allArray && allArray.length > 0 ? allArray : obj)
    //     // })
    //     return obj;

    // };
    const EntityHandleChane = (obj, value) => {
        console.log(value, 'datadatadatadatadatadata');
        setGroupCheckBox(true)
        setGo(false)
        setCheckBox(false)
        setFieldType('LegalEntity')
        let temp = ['Business', 'Legal', 'Division', 'Category', 'Unit']
        setTrueState(temp)
        // let temp = 'Legal'
        // trueState.push(temp)
        let allArray = [];
        if (obj.includes('all')) {
            if (EntityDatas && EntityDatas && EntityDatas.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in EntityDatas) {
                    temp.push(EntityDatas[i].le_id)
                    allArray.push(EntityDatas[i].le_id)
                    // let data = _.find(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: EntityData[i].le_id })
                    // console.log(data, 'ppp');
                    // if (data != undefined) {
                    //     temp2.push(data)
                    // }

                }
                // (EntityData)
                setEntity(temp)
                // setDomainData(userManagementDetails && userManagementDetails.um_legal_domain)
            }
            SetEntityselectcheckbox('none');
            SetEntityUnselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            setFieldType('LegalEntity')

            let temp = [];
            setEntity([]);
            SetEntityselectcheckbox('block');
            SetEntityUnselectcheckbox('none');
        } else {
            console.log(obj, 'objobjobjobjobj');
            setFieldType('LegalEntity')
            setGroupCheckBox(true)
            setCheckBox(false)

            if (obj.length == EntityDatas.length) {
                SetEntityselectcheckbox('none');
                SetEntityUnselectcheckbox('block');
            } else {
                SetEntityselectcheckbox('block');
                SetEntityUnselectcheckbox('none');
            }


            // setLegalEntity(datas)
            // setDomainData(data)

            setEntity(obj)



        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };



    console.log(divisionDatas, 'divisionDatas');
    // const DivisionHandleChane = (obj) => {
    //     console.log(field.domain, 'objjfield.domainjj');
    //     let allArray = [];
    //     if (obj.includes('all')) {
    //         if (division && division.length > 0) {
    //             let temp = []
    //             for (let i in division) {
    //                 console.log(division[i].div_id, 'domain[i].d_id');
    //                 temp.push(division[i].div_id)


    //             }
    //             console.log(temp, 'temp');
    //             setdivisionDatas(temp)
    //             setFieldType("Division")
    //         }
    //         Setdivisionselectcheckbox('none');
    //         SetdivisionUnselectcheckbox('block');
    //     } else if (obj.includes('unselect')) {
    //         let temp = [];

    //         setdivisionDatas([])
    //         setFieldType("")
    //         Setdivisionselectcheckbox('block');
    //         SetdivisionUnselectcheckbox('none');
    //     } else {
    //         console.log(obj, 'obj');
    //         let temp = []
    //         // for (let i in obj) {
    //         //     temp.push({obj[i]}`)
    //         // }
    //         setFieldType("Division")
    //         setdivisionDatas(obj)

    //     }
    //     // setUserDetails({
    //     //     ...userDetails,
    //     //     units: (allArray && allArray.length > 0 ? allArray : obj)
    //     // })
    //     return obj;

    // };




    const DivisionHandleChane = (obj) => {
        setFieldType('Division')
        setGo(false)
        setGroupCheckBox(true)
        setCoulmnState(true)
        setCheckBox(false)
        // let temp = 'Division'
        // trueState.push(temp)
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (divisionData && divisionData.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in divisionData) {
                    temp.push(`${divisionData[i].d_id}`)
                    allArray.push(divisionData[i].div_id)


                }
                setdivisionDatas(allArray)
                // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            }
            Setdivisionselectcheckbox('none');
            SetdivisionUnselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            setFieldType('Division')
            let temp = [];
            setdivisionDatas([]);
            // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            Setdivisionselectcheckbox('block');
            SetdivisionUnselectcheckbox('none');
        } else {
            setFieldType('Division')
            setGroupCheckBox(true)
            setCheckBox(false)
            if (obj.length == divisionData.length) {
                Setdivisionselectcheckbox('none');
                SetdivisionUnselectcheckbox('block');
            } else {
                Setdivisionselectcheckbox('block');
                SetdivisionUnselectcheckbox('none');
            }
            let temp = []
            setdivisionDatas(obj)



            console.log(obj, 'obj');
            // setCategoryDatas(data)
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    // const UnitHandleChane = (obj) => {
    //     console.log(field.domain, 'objjfield.domainjj');
    //     let allArray = [];
    //     if (obj.includes('all')) {
    //         if (unit && unit.length > 0) {
    //             let temp = []
    //             for (let i in unit) {
    //                 console.log(unit[i].u_id, 'domain[i].d_id');
    //                 temp.push(unit[i].u_id)


    //             }
    //             console.log(temp, 'temp');
    //             setunitData(temp)
    //             setFieldType("Unit")
    //         }
    //         Setunitselectcheckbox('none');
    //         SetunitUnselectcheckbox('block');
    //     } else if (obj.includes('unselect')) {
    //         let temp = [];

    //         setunitData([])
    //         setFieldType("")
    //         Setunitselectcheckbox('block');
    //         SetunitUnselectcheckbox('none');
    //     } else {
    //         console.log(obj, 'obj');
    //         let temp = []
    //         // for (let i in obj) {
    //         //     temp.push(obj[i]}`)
    //         // }
    //         setFieldType("Unit")
    //         setunitData(obj)

    //     }
    //     // setUserDetails({
    //     //     ...userDetails,
    //     //     units: (allArray && allArray.length > 0 ? allArray : obj)
    //     // })
    //     return obj;

    // };
    // const CategoryHandleChane = (obj) => {
    //     console.log(field.domain, 'objjfield.domainjj');
    //     let allArray = [];
    //     if (obj.includes('all')) {
    //         if (category && category.length > 0) {
    //             let temp = []
    //             for (let i in category) {
    //                 console.log(category[i].cat_id, 'domain[i].d_id');
    //                 temp.push(category[i].cat_id)


    //             }
    //             console.log(temp, 'temp');
    //             setcategoryData(temp)

    //         }
    //         setFieldType("Category")
    //         Setcategoryselectcheckbox('none');
    //         SetcategoryUnselectcheckbox('block');
    //     } else if (obj.includes('unselect')) {
    //         let temp = [];

    //         setcategoryData([])
    //         Setcategoryselectcheckbox('block');
    //         SetcategoryUnselectcheckbox('none');
    //         setFieldType("")
    //     } else {
    //         console.log(obj, 'obj');
    //         let temp = []
    //         // for (let i in obj) {
    //         //     temp.push(obj[i]}`)
    //         // }
    //         setcategoryData(obj)
    //         setFieldType("Category")
    //     }
    //     // setUserDetails({
    //     //     ...userDetails,
    //     //     units: (allArray && allArray.length > 0 ? allArray : obj)
    //     // })
    //     return obj;

    // };
    const CategoryHandleChange = (obj) => {
        setFieldType('Category')
        setGroupCheckBox(true)
        setGo(false)
        setCoulmnState(true)
        setCheckBox(false)
        // let temp = 'Category'
        // trueState.push(temp)
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (categoryDatas && categoryDatas.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in categoryDatas) {
                    temp.push(`${categoryDatas[i].cat_id}`)
                    allArray.push(categoryDatas[i].cat_id)


                }
                setcategoryData(allArray)
                // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            }
            Setcategoryselectcheckbox('none');
            SetcategoryUnselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            setFieldType('Category')
            let temp = [];
            setcategoryData([]);
            // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            Setcategoryselectcheckbox('block');
            SetcategoryUnselectcheckbox('none');
        } else {
            setFieldType('Category')
            setGroupCheckBox(true)
            setCheckBox(false)
            if (obj.length == categoryDatas.length) {
                Setcategoryselectcheckbox('none');
                SetdivisionUnselectcheckbox('block');
            } else {
                Setcategoryselectcheckbox('block');
                SetcategoryUnselectcheckbox('none');
            }
            let temp = []
            setcategoryData(obj)



            console.log(obj, 'obj');
            // setCategoryDatas(data)
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    const UnitHandleChane = (obj) => {
        setFieldType('Unit')
        setGroupCheckBox(true)
        setGo(false)
        setCoulmnState(true)
        setCheckBox(false)
        // let temp = 'Unit'
        // trueState.push(temp)
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (unitDatas && unitDatas.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in unitDatas) {
                    temp.push(`${unitDatas[i].u_id}`)
                    allArray.push(unitDatas[i].u_id)


                }
                setunitData(allArray)
                // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            }
            Setunitselectcheckbox('none');
            SetunitUnselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            setFieldType('Unit')
            let temp = [];
            setunitData([]);
            // setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            Setunitselectcheckbox('block');
            SetunitUnselectcheckbox('none');
        } else {
            setFieldType('Unit')
            setGroupCheckBox(true)
            setCheckBox(false)
            if (obj.length == categoryDatas.length) {
                Setunitselectcheckbox('none');
                SetdivisionUnselectcheckbox('block');
            } else {
                Setunitselectcheckbox('block');
                SetunitUnselectcheckbox('none');
            }
            let temp = []
            setunitData(obj)



            console.log(obj, 'obj');
            // setCategoryDatas(data)
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };

    useEffect(() => {
        let temp = []
        if (tableData && tableData.length > 0) {
            tableData.map((item, i) => {
                temp.push(`${i}`)
            }
            )
            setIndexValue(temp)
        }
    }, [tableData])
    console.log(indexValue, 'indexValue');

    useEffect(() => {
        if (country.length > 0) {
            let temp
            if (commonData && commonData.d_info && commonData.d_info.length > 0) {
                for (let i in country) {
                    let data = _.filter(commonData && commonData.d_info, { c_id: Number(country[i]) })
                    console.log(data, 'dataaaaa');
                    temp = data
                }
                console.log(temp, 'vicky');
                if (temp && temp.length > 0) {
                    let id = temp && temp.map((item, i) => {
                        return item.d_id
                    })
                    console.log(id, 'ppppp');
                    setDomain(id)
                }

            }
        }
    }, [country])
    useEffect(() => {
        if (domain.length > 0) {
            let temparr = []
            let temp2 = []
            let id = []
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                for (let i in domain) {
                    let catData = _.filter(commonData && commonData.bg_groups, { d_id: Number(domain[i]) })
                    console.log(catData[0], 'Aishuuuuu');
                    // temp2.push(catData)
                    if (catData && catData.length > 0) {
                        for (let i in catData) {
                            temp2.push(catData[i])
                            id.push(`${catData[i].bg_id}`)
                        }
                    }
                }
            }
            else {
                for (let i in domain) {
                    let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(domain[i])))
                    console.log(filteredLegalEntity, 'filteredLegalEntity');
                    temparr = filteredLegalEntity
                }
            }
            if (temp2.length > 0) {
                const unique = _.uniqBy(temp2, function (e) {

                    return e.bg_id;

                });

                console.log(unique, 'unique');

                // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
                setBusiness([...new Set(id)])
                setBusinessGroupdata(unique)
                // }
            }
        }
    }, [domain])
    const DomainHandleChane = (obj) => {
        console.log(field.domain, 'objjfield.domainjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (domainDatas && domainDatas.length > 0) {
                let temp = []
                for (let i in domainDatas) {

                    temp.push(domainDatas[i].d_id)


                }
                console.log(temp, 'temp');
                setDomain(temp)

            }
            Setdomainselectcheckbox('none');
            Setdomainunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];

            setDomain([])
            Setdomainselectcheckbox('block');
            Setdomainunselectcheckbox('none');
        } else {
            console.log(obj, 'obj');
            let temp = []
            for (let i in obj) {
                temp.push(obj[i])
            }
            console.log(temp, 'temp');
            setDomain(temp)


            let temparr = []
            let temp2 = []
            let id = []
            for (let i in obj) {
                console.log(obj[i], 'obj[i');
                console.log(i, 'i');
                if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                    let catData = _.filter(commonData && commonData.bg_groups, { d_id: Number(obj[i]) })
                    console.log(catData[0], 'Aishuuuuu');
                    // temp2.push(catData)
                    if (catData && catData.length > 0) {
                        for (let i in catData) {
                            temp2.push(catData[i])
                            id.push(`${catData[i].bg_id}`)
                        }
                    }
                }
                else {
                    let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(obj[i])))
                    console.log(filteredLegalEntity, 'filteredLegalEntity');
                    temparr = filteredLegalEntity
                }
            }
            console.log(temp2, 'temp2temp2');
            console.log(id, 'id999999');
            if (temp2.length > 0) {
                const unique = _.uniqBy(temp2, function (e) {

                    return e.bg_id;

                });

                console.log(unique, 'unique');


                setBusiness([...new Set(id)])
                setBusinessGroupdata(unique)
            }
            // else {
            //     setEntityDatas(temparr)
            // }



        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    useEffect(() => {

        if ((criticaltityStatus == true) && (criticality != '')) {
            console.log(entityid, 'entttttt');
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request":
                        ["GetComplianceCriticalityStatusChart",
                            {
                                "c_ids": [
                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                ],
                                "d_ids": [
                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                ],
                                "filter_type": "Group",
                                "from_date": null,
                                "to_date": null,
                                "criticality": criticaltityStatus == true ? criticality : "",
                                "filter_ids": [
                                    1
                                ],
                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                "country_domains": countryDomain,
                                "chart_year": year
                            }
                        ]
                }
            ]

            getCriticalityFilter({
                payload: payload,
                paramid: paramid
            })

        }


    }, [isAuth, entityid, localStorage.getItem('SelectedEntity') == "All Legal Entity", criticaltityStatus, criticality])

    console.log(Table, 'TableTable');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder='Search'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };


    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 6,
            align: 'center'
        },
        ...((fieldType === 'LegalEntity' && go === true) || (fieldType === 'Category' && go === true) || (fieldType === 'Unit' && go === true) ?
            [] :
            [{
                title: "Business Group",
                ...getColumnSearchProps('bg_name'),
                dataIndex: 'bg_name',
                key: 'bg_name',
                ellipsis: false,
                width: 18,
                filteredValue: filteredInfo.bg_name || null,
            },
            {
                title: 'Legal Entity',
                ...getColumnSearchProps('le_name'),
                dataIndex: false,
                key: 'le_name',
                ellipse: false,
                width: 15,
                filteredValue: filteredInfo.le_name || null,
                render: (record) => {
                    return <p title={record.le_name} className='cut-text'>
                        {record.le_name}</p>
                }
            }
            ]
        ),
        {
            title: 'Division',
            ...getColumnSearchProps('div_name'),
            dataIndex: false,
            key: 'div_name',
            width: 14,
            ellipsis: false,
            filteredValue: filteredInfo.div_name || null,
            render: (record) => {
                return <p title={record.div_name} className='cut-text'>
                    {record.div_name}</p>
            }

        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: false,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.div_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>{record.comp_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: 20,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 15,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name'),
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 15,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Over Due",
            ...getColumnSearchProps('ageing'),
            dataIndex: 'ageing',
            key: 'ageing',
            ellipsis: false,
            render: (row, record) => {
                return (
                    <>
                        <p>{record.ageing}</p>
                    </>
                )
            },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ];

    const columns1 = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 7,
            align: 'center'
        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: true,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.comp_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>{record.comp_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: 25,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name'),
            dataIndex: false,
            key: 'assignee_name',
            ellipsis: false,
            render: (record) => {
                return <p title={record.assignee_name} className='cut-text'>
                    {record.assignee_name}</p>
            },
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 13,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Over Due",
            ...getColumnSearchProps('ageing'),
            dataIndex: 'ageing',
            key: 'ageing',
            ellipsis: false,
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ]







    console.log(dataValue, 'chartTypeee');


    useEffect(() => {
        if (chartName == 'stacked') {
            if ((table == false) && criticaltityStatus == true) {
                console.log('ppppppppppp');
                if (dataValue && dataValue.length > 0) {
                    if (chartType == 'count') {
                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;


                        function addChart(data) {

                            var chart = container.createChild(am4charts.XYChart3D);

                            // Add data
                            chart.data = data;
                            chart.colors.list = [
                                am4core.color("#0de580"),
                                am4core.color("#3c78d8"),
                                am4core.color("#f9c80e"),
                                am4core.color("#fe4365"),
                            ];
                            chart.legend = new am4charts.Legend();
                            chart.legend.position = "buttom";
                            chart.legend.useDefaultMarker = true;
                            chart.height = 600
                            var marker = chart.legend.markers.template.children.getIndex(0);
                            marker.cornerRadius(12, 12, 12, 12);
                            marker.strokeWidth = 1;
                            // marker.strokeOpacity = 0.5;
                            chart.scrollbarX = new am4core.Scrollbar();
                            chart.scrollbarY = new am4core.Scrollbar();
                            chart.scrollbarY.parent = chart.rightAxesContainer;
                            var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.dataFields.category = "type";
                            categoryAxis.title.text =
                                ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                    (fieldType == "Division" && go == true) ? `Division` : (
                                        (fieldType == "Category" && go == true) ? `Category` : (
                                            (fieldType == "Unit" && go == true) ? `Unit` : `Countries`
                                        ))
                                ))
                            var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                            valueAxis.calculateTotals = true;
                            valueAxis.title.text = "Total Compliance";
                            valueAxis.renderer.cellStartLocation = 0.2;
                            valueAxis.renderer.cellEndLocation = 0.8;
                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            chart.exporting.menu.align = "right";
                            chart.exporting.menu.verticalAlign = "top";
                            function createSeries(field, name) {
                                var series = chart.series.push(new am4charts.ColumnSeries3D());
                                series.dataFields.valueX = field;
                                series.dataFields.categoryY = "type";
                                // series.dataFields.valueXShow = "total";
                                series.stacked = true;
                                series.name = name;
                                // series.clustered = false;
                                var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                                labelBullet.label.text = "{valueX}";
                                labelBullet.locationX = 0.5;
                                series.columns.template.maxHeight = chartData.length > 1 ? 20 : 50
                                series.columns.events.on("hit", function (ev) {
                                    setCount(0)
                                    setCategoryName(ev.target.dataItem.categories.categoryY)
                                    setShowMoreCount(0)
                                    setDataCount(ev.target.dataItem.component.name == "Inprogress" ? ev.target.dataItem._dataContext.inprogress_compliance_count
                                        : ev.target.dataItem.component.name == "Complied" ? ev.target.dataItem._dataContext.complied_count
                                            : ev.target.dataItem.component.name == "Not Complied" ? ev.target.dataItem._dataContext.not_complied_count
                                                : ev.target.dataItem.component.name == "Delayed Compliance" ? ev.target.dataItem._dataContext.delayed_compliance_count : 0)
                                    console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                                    setCompliance(ev.target.dataItem.component.name);
                                    console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                                    const payload = [
                                        authtoken,
                                        {
                                            "session_token": authtoken,
                                            "request": [
                                                "GetComplianceStatusDrillDownData",
                                                {
                                                    "d_ids": [
                                                        commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                    ],
                                                    "from_date": null,
                                                    "to_date": null,
                                                    "filter_type": ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                                        (fieldType == "Division" && go == true) ? `Division` : (
                                                            (fieldType == "Category" && go == true) ? "Category" : (
                                                                (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                            ))
                                                    )),
                                                    "filter_id": ev.target.dataItem._dataContext.typeId,
                                                    "compliance_status": ev.target.dataItem.component.name,
                                                    "chart_year": year,
                                                    "record_count": 0,
                                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                    "country_domains": countryDomain,
                                                    "criticality": ""
                                                }
                                            ]
                                        }
                                    ]
                                    getCompliancestatusTable({
                                        payload: payload,
                                        paramid: paramid
                                    })
                                    setTable(true)
                                })
                                series.columns.template.height = am4core.percent(70);
                                // series.columns.template.column3D.stroke = am4core.color("#fff");
                                // series.columns.template.column3D.strokeOpacity = 0.2;
                                series.calculatePercent = true;
                                series.calculateTotals = true;
                                series.columns.template.tooltipText = "{name}: {valueX.totalPercent}[/]% : ({valueX} out of {valueX.total}[/])";
                            }

                            createSeries("complied_count", "Complied");
                            createSeries("delayed_compliance_count", "Delayed Compliance");
                            createSeries("inprogress_compliance_count", "Inprogress");
                            createSeries("not_complied_count", "Not Complied");
                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            var label7 = chart.createChild(am4core.Label);
                            label7.text = `${textValue}`
                            label7.fontSize = 14;
                            label7.align = "left";
                            label7.marginLeft = '1%';
                            label7.marginRight = '2%';
                            label7.marginTop = '2%';
                            label7.fontWeight = 'bold'
                            label7.height = '5%'
                            label7.width = '50%'
                            label7.wrap = true

                            if (dateState === true && go === true) {
                                var label2 = chart.createChild(am4core.Label)
                                label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                                label2.align = "left";
                                label2.marginLeft = '1%';
                                label2.marginRight = '2%';
                                label2.marginTop = '2%';
                                label2.marginBottom = '5%'
                            }


                            var label3 = chart.createChild(am4core.Label);
                            label3.text = criticaltityStatus === true ? `[bold]Criticality[/]-${criticality}` : ''
                            label3.fontSize = 14;
                            label3.align = "left";
                            label3.marginLeft = '2%';
                            label3.marginBottom = '5%'


                        }

                        addChart(chartData);

                    }
                }
            }
        }
    }, [dataValue, table, chartType, criticaltityStatus, chartData])

    // useEffect(() => {
    //     console.log('testttttttt');
    //     if ((table == false) && (criticaltityStatus == false)) {
    //         if (dataValue && dataValue.length > 0) {
    //             if (chartName == 'stacked') {
    //                 if (chartType == 'count') {
    //                     am4core.useTheme(am4themes_animated);
    //                     console.log(dataValue && dataValue.length && dataValue[0].complied_count, 'dataValue && dataValue');
    //                     var chart = am4core.create("chartdiv", am4charts.XYChart3D);
    //                     chart.data = [{
    //                         "country": "India",
    //                         "Complied": dataValue && dataValue.length && dataValue[0].complied_count,
    //                         "Delayed Compliance": dataValue && dataValue.length && dataValue[0].delayed_compliance_count,
    //                         "Inprogress": dataValue && dataValue.length && dataValue[0].inprogress_compliance_count,
    //                         "Not Complied": dataValue && dataValue.length && dataValue[0].not_complied_count,

    //                     }]
    //                     chart.colors.list = [
    //                         am4core.color("#0de580"),
    //                         am4core.color("#3c78d8"),
    //                         am4core.color("#f9c80e"),
    //                         am4core.color("#fe4365"),
    //                     ];
    //                     chart.legend = new am4charts.Legend();
    //                     chart.legend.position = "buttom";
    //                     chart.legend.useDefaultMarker = true;
    //                     var marker = chart.legend.markers.template.children.getIndex(0);
    //                     marker.cornerRadius(12, 12, 12, 12);
    //                     marker.strokeWidth = 1;
    //                     chart.scrollbarX = new am4core.Scrollbar();
    //                     chart.scrollbarY = new am4core.Scrollbar();
    //                     chart.scrollbarY.parent = chart.rightAxesContainer;
    //                     var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    //                     categoryAxis.dataFields.category = "country";
    //                     categoryAxis.title.text = "Countries";
    //                     var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    //                     valueAxis.calculateTotals = true;
    //                     valueAxis.title.text = "Total Compliance";
    //                     chart.exporting.menu = new am4core.ExportMenu();
    //                     chart.exporting.menu.align = "right";
    //                     chart.exporting.menu.verticalAlign = "top";
    //                     function createSeries(field, name) {
    //                         var series = chart.series.push(new am4charts.ColumnSeries3D());
    //                         series.dataFields.valueX = field;
    //                         series.dataFields.categoryY = "country";
    //                         // series.dataFields.valueXShow = "total";
    //                         series.stacked = true;
    //                         series.name = name;
    //                         // series.clustered = false;
    //                         var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    //                         labelBullet.label.text = "{valueX}";
    //                         labelBullet.locationX = 0.5;
    //                         series.columns.template.events.on("hit", function (ev) {
    //                             setCompliance(ev.target.dataItem.component.name);
    //                             console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
    //                             const payload = [

    //                                 authtoken,

    //                                 {

    //                                     "session_token": authtoken,

    //                                     "request": [
    //                                         "GetComplianceStatusDrillDownData",
    //                                         {
    //                                             "d_ids": [
    //                                                 commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                             ],
    //                                             "from_date": null,
    //                                             "to_date": null,
    //                                             "filter_type": "Group",
    //                                             "filter_id": 1,
    //                                             "compliance_status": ev.target.dataItem.component.name,
    //                                             "chart_year": year,
    //                                             "record_count": 0,
    //                                             "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                                             "country_domains": [
    //                                                 {
    //                                                     "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                                     "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                                     "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                                     "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                                 }
    //                                             ],
    //                                             "criticality": ""
    //                                         }
    //                                     ]

    //                                 }

    //                             ]



    //                             getCompliancestatusTable({

    //                                 payload: payload,

    //                                 paramid: paramid

    //                             })
    //                             setTable(true)
    //                         })

    //                         series.columns.template.height = am4core.percent(70);

    //                         // series.columns.template.column3D.stroke = am4core.color("#fff");
    //                         // series.columns.template.column3D.strokeOpacity = 0.2;
    //                         series.calculatePercent = true;
    //                         series.calculateTotals = true;
    //                         series.columns.template.tooltipText = "{name}: {valueX.totalPercent}[/]% : ({valueX} outof {valueX.total}[/]}";


    //                     }

    //                     createSeries("Complied", "Complied");
    //                     createSeries("Delayed Compliance", "Delayed Compliance");
    //                     createSeries("Inprogress", "Inprogress");
    //                     createSeries("Not Complied", "Not Complied");

    //                 }
    //                 else if (chartType == 'Percentage') {
    //                     am4core.useTheme(am4themes_animated);
    //                     console.log(dataValue && dataValue.length && dataValue[0].complied_count, 'dataValue && dataValue');
    //                     var chart = am4core.create("chartdiv", am4charts.XYChart3D);
    //                     chart.data = [{
    //                         "country": "India",
    //                         "Complied": dataValue && dataValue.length && dataValue[0].complied_count,
    //                         "Delayed Compliance": dataValue && dataValue.length && dataValue[0].delayed_compliance_count,
    //                         "Inprogress": dataValue && dataValue.length && dataValue[0].inprogress_compliance_count,
    //                         "Not Complied": dataValue && dataValue.length && dataValue[0].not_complied_count,

    //                     }]
    //                     chart.colors.list = [
    //                         am4core.color("#0de580"),
    //                         am4core.color("#3c78d8"),
    //                         am4core.color("#f9c80e"),
    //                         am4core.color("#fe4365"),
    //                     ];
    //                     chart.legend = new am4charts.Legend();
    //                     chart.legend.position = "buttom";
    //                     chart.legend.useDefaultMarker = true;
    //                     var marker = chart.legend.markers.template.children.getIndex(0);
    //                     marker.cornerRadius(12, 12, 12, 12);
    //                     marker.strokeWidth = 1;
    //                     chart.scrollbarX = new am4core.Scrollbar();
    //                     chart.scrollbarY = new am4core.Scrollbar();
    //                     chart.scrollbarY.parent = chart.rightAxesContainer;
    //                     var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    //                     categoryAxis.dataFields.category = "country";
    //                     categoryAxis.title.text = "Countries";
    //                     var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    //                     valueAxis.calculateTotals = true;
    //                     valueAxis.min = 0;
    //                     valueAxis.max = 100;
    //                     valueAxis.title.text = "Total Compliance";
    //                     chart.exporting.menu = new am4core.ExportMenu();
    //                     chart.exporting.menu.align = "right";
    //                     chart.exporting.menu.verticalAlign = "top";
    //                     function createSeries(field, name) {
    //                         var series = chart.series.push(new am4charts.ColumnSeries3D());
    //                         series.dataFields.valueX = field;
    //                         series.dataFields.categoryY = "country";
    //                         series.dataFields.valueXShow = "totalPercent";
    //                         series.stacked = true;
    //                         series.name = name;
    //                         // series.clustered = false;
    //                         var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    //                         labelBullet.label.text = "{valueX.totalPercent.formatNumber('#.00')}%";
    //                         labelBullet.locationX = 0.5;
    //                         series.columns.template.events.on("hit", function (ev) {
    //                             setCompliance(ev.target.dataItem.component.name);
    //                             console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
    //                             const payload = [

    //                                 authtoken,

    //                                 {

    //                                     "session_token": authtoken,

    //                                     "request": [
    //                                         "GetComplianceStatusDrillDownData",
    //                                         {
    //                                             "d_ids": [
    //                                                 commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                             ],
    //                                             "from_date": null,
    //                                             "to_date": null,
    //                                             "filter_type": "Group",
    //                                             "filter_id": 1,
    //                                             "compliance_status": ev.target.dataItem.component.name,
    //                                             "chart_year": year,
    //                                             "record_count": 0,
    //                                             "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                                             "country_domains": [
    //                                                 {
    //                                                     "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                                     "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                                     "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                                     "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                                 }
    //                                             ],
    //                                             "criticality": ""
    //                                         }
    //                                     ]

    //                                 }

    //                             ]



    //                             getCompliancestatusTable({

    //                                 payload: payload,

    //                                 paramid: paramid

    //                             })
    //                             setTable(true)
    //                         })

    //                         series.columns.template.height = am4core.percent(70);

    //                         // series.columns.template.column3D.stroke = am4core.color("#fff");
    //                         // series.columns.template.column3D.strokeOpacity = 0.2;
    //                         series.calculatePercent = true;
    //                         series.calculateTotals = true;
    //                         series.columns.template.tooltipText = "{name}: {valueX.totalPercent}[/]% : ({valueX} outof {valueX.total}[/]}";
    //                     }
    //                     createSeries("Complied", "Complied");
    //                     createSeries("Delayed Compliance", "Delayed Compliance");
    //                     createSeries("Inprogress", "Inprogress");
    //                     createSeries("Not Complied", "Not Complied");
    //                 }

    //             }

    //         }



    //     }
    // }, [dataValue, table, chartType, criticaltityStatus, chartName])

    useEffect(() => {
        if ((table == false) && (criticaltityStatus == false)) {
            if (multiSelect == false) {
                if (chartName == 'stacked') {
                    if (chartType == 'count') {
                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;

                        function addChart(data) {
                            console.log(data, 'aishuuuuuuuu');
                            var chart = container.createChild(am4charts.XYChart3D);
                            // Add data
                            chart.data = data;
                            chart.colors.list = [
                                am4core.color("#0de580"),
                                am4core.color("#3c78d8"),
                                am4core.color("#f9c80e"),
                                am4core.color("#fe4365"),
                            ];
                            chart.legend = new am4charts.Legend();
                            chart.legend.position = "buttom";
                            chart.legend.useDefaultMarker = true;
                            chart.height = 600
                            var marker = chart.legend.markers.template.children.getIndex(0);
                            marker.cornerRadius(12, 12, 12, 12);
                            marker.strokeWidth = 1;
                            chart.scrollbarX = new am4core.Scrollbar();
                            chart.scrollbarY = new am4core.Scrollbar();
                            chart.scrollbarY.parent = chart.rightAxesContainer;
                            var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());

                            categoryAxis.dataFields.category = "type";
                            categoryAxis.renderer.labels.template.events.on("hit", function (event) {
                                if (chartData.length > 1) {
                                    setmultiSelectChart(event.target.dataItem._dataContext)
                                    setmultiSelect(true)
                                }
                                console.log(event.target.dataItem._dataContext, 'event5555');

                            })
                            categoryAxis.title.text =
                                ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                    (fieldType == "Division" && go == true) ? `Division` : (
                                        (fieldType == "Category" && go == true) ? `Category` : (
                                            (fieldType == "BusinessGroup" && go == true) ? `Business Groups` : (
                                                (fieldType == "Unit" && go == true) ? `Unit` : `Countries`
                                            )))
                                ))

                            var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                            valueAxis.renderer.cellStartLocation = 0.2;
                            valueAxis.renderer.cellEndLocation = 0.8;
                            valueAxis.calculateTotals = true;
                            valueAxis.title.text = "Total Compliance";
                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            chart.exporting.menu.align = "right";
                            chart.exporting.menu.verticalAlign = "top";
                            function createSeries(field, name, id) {
                                console.log(field, 'field');
                                console.log(name, 'name');
                                console.log(id, 'idddd');
                                var series = chart.series.push(new am4charts.ColumnSeries3D());
                                series.dataFields.valueX = field;
                                series.dataFields.categoryY = "type";
                                series.dataFields.customValue = id
                                // series.columns.template.maxWidth = 10
                                series.columns.template.maxHeight = chartData.length > 1 ? 20 : 50
                                console.log(series.dataFields.customValue, ' series.dataFields.customValue');
                                // series.dataFields.valueXShow = "total";
                                series.stacked = true;
                                series.name = name;
                                // series.clustered = false;
                                var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                                labelBullet.label.text = "{valueX}";
                                labelBullet.locationX = 0.5;

                                series.columns.template.events.on("hit", function (ev) {
                                    console.log(ev.target.dataItem, 'ev.target.dataItem');
                                    setCategoryName(ev.target.dataItem.categories.categoryY)
                                    setCount(0)
                                    setCompliance(ev.target.dataItem.component.name);
                                    setShowMoreCount(0)
                                    setDataCount(ev.target.dataItem.component.name == "Inprogress" ? ev.target.dataItem._dataContext.inprogress_compliance_count
                                        : ev.target.dataItem.component.name == "Complied" ? ev.target.dataItem._dataContext.complied_count
                                            : ev.target.dataItem.component.name == "Not Complied" ? ev.target.dataItem._dataContext.not_complied_count
                                                : ev.target.dataItem.component.name == "Delayed Compliance" ? ev.target.dataItem._dataContext.delayed_compliance_count : 0)
                                    console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                                    console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                                    const payload = [

                                        authtoken,

                                        {

                                            "session_token": authtoken,

                                            "request": [
                                                "GetComplianceStatusDrillDownData",
                                                {
                                                    "d_ids": [
                                                        commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                    ],
                                                    "from_date": null,
                                                    "to_date": null,
                                                    "filter_type": ((fieldType == "LegalEntity" && go == true) ? `LegalEntity` : (
                                                        (fieldType == "Division" && go == true) ? `Division` : (
                                                            (fieldType == "Category" && go == true) ? "Category" : (
                                                                (fieldType == "BusinessGroup" && go == true) ? "BusinessGroup" : (
                                                                    (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                                )))
                                                    )),
                                                    "filter_id": ev.target.dataItem._dataContext.typeId,
                                                    "compliance_status": ev.target.dataItem.component.name,
                                                    "chart_year": year,
                                                    "record_count": 0,
                                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                    "country_domains": countryDomain,
                                                    "criticality": ""
                                                }
                                            ]
                                        }
                                    ]
                                    getCompliancestatusTable({
                                        payload: payload,
                                        paramid: paramid
                                    })
                                    setTable(true)
                                })
                                // series.columns.template.height = am4core.percent(70);
                                // series.columns.template.column3D.stroke = am4core.color("#fff");
                                // series.columns.template.column3D.strokeOpacity = 0.2;
                                series.calculatePercent = true;
                                series.calculateTotals = true;
                                series.columns.template.tooltipText = "{name}: {valueX.totalPercent.formatNumber('#.00')}[/]% : ({valueX} out of {valueX.total}[/])";


                            }

                            createSeries("complied_count", "Complied", "typeId");
                            createSeries("delayed_compliance_count", "Delayed Compliance", "typeId");
                            createSeries("not_complied_count", "Not Complied", "typeId");
                            createSeries("inprogress_compliance_count", "Inprogress", "typeId");

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '1%';
                            label.marginBottom = '1%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '1%';
                            // label1.marginBottom = '5%'

                            var label7 = chart.createChild(am4core.Label);
                            label7.text = `${textValue}`
                            label7.fontSize = 14;
                            label7.align = "left";
                            label7.marginLeft = '1%';
                            label7.marginRight = '2%';
                            label7.marginTop = '2%';
                            label7.fontWeight = 'bold'
                            label7.height = '5%'
                            label7.width = '50%'
                            label7.wrap = true

                            if (dateState === true && go === true) {
                                var label2 = chart.createChild(am4core.Label)
                                label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                                label2.align = "left";
                                label2.marginLeft = '1%';
                                label2.marginRight = '2%';
                                label2.marginTop = '2%';
                                label2.marginBottom = '5%'
                            }


                            // var label5 = chart.createChild(am4core.Label);
                            // label5.text =tempState && tempState.bg && tempState.bg.length > 0 ? '|Business Group : Selected 4 out of 4' : '' | `${tempState && tempState.le && tempState.le.length > 0}` ? '|Legal Entity Group : Selected 4 out of 4' : ''
                            // var label2 = chart.createChild(am4core.Label);
                            // label2.text = ((Entity.length > 0) ? `| Legal Entity: Selected ${Entity && Entity.length} out of${entityData && entityData.length}` : (
                            //     (divisionDatas && divisionDatas.length > 0) ? `| Division: Selected ${divisionDatas && divisionDatas.length} out of ${division && division.length}` : (
                            //         (categoryData && categoryData.length > 0) ? `| Category: Selected ${categoryData && categoryData.length}` : (
                            //             (unitData && unitData.length > 0) ? `| Unit Selected:Selected ${unitData && unitData.length} out of ${unit && unit.length}` : (
                            //                 (filterData.from_date && filterData.to_date ? `| Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                            //                     : null
                            //                 ))))
                            // ))
                            // label2.fontSize = 14;
                            // label2.align = "left";
                            // label2.marginLeft = '2%';
                            // label2.marginBottom = '5%'
                        }
                        addChart(chartData);
                    }
                    else if (chartType == 'Percentage') {
                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;

                        function addChart(data) {
                            var chart = container.createChild(am4charts.XYChart3D);
                            // Add data
                            chart.data = data;
                            chart.colors.list = [
                                am4core.color("#0de580"),
                                am4core.color("#3c78d8"),
                                am4core.color("#f9c80e"),
                                am4core.color("#fe4365"),
                            ];
                            chart.legend = new am4charts.Legend();
                            chart.legend.position = "buttom";
                            chart.legend.useDefaultMarker = true;
                            chart.height = 600
                            var marker = chart.legend.markers.template.children.getIndex(0);
                            marker.cornerRadius(12, 12, 12, 12);
                            marker.strokeWidth = 1;
                            chart.scrollbarX = new am4core.Scrollbar();
                            chart.scrollbarY = new am4core.Scrollbar();
                            chart.scrollbarY.parent = chart.rightAxesContainer;
                            var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.dataFields.category = "type";
                            categoryAxis.title.text =
                                ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                    (fieldType == "Division" && go == true) ? `Division` : (
                                        (fieldType == "Category" && go == true) ? `Category` : (
                                            (fieldType == "Unit" && go == true) ? `Unit` : `Countries`
                                        ))
                                ))
                            var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                            valueAxis.calculateTotals = true;
                            valueAxis.renderer.cellStartLocation = 0.2;
                            valueAxis.renderer.cellEndLocation = 0.8;
                            valueAxis.min = 0;
                            valueAxis.max = 100;
                            valueAxis.title.text = "Total Compliance";
                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            chart.exporting.menu.align = "right";
                            chart.exporting.menu.verticalAlign = "top";
                            function createSeries(field, name) {
                                var series = chart.series.push(new am4charts.ColumnSeries3D());
                                series.dataFields.valueX = field;
                                series.dataFields.categoryY = "type";
                                series.dataFields.valueXShow = "totalPercent";
                                series.columns.template.maxHeight = chartData.length > 1 ? 20 : 50
                                series.stacked = true;
                                series.name = name;
                                // series.clustered = false;
                                var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                                labelBullet.label.text = "{valueX.totalPercent.formatNumber('#.00')}%";
                                labelBullet.locationX = 0.5;
                                series.columns.template.events.on("hit", function (ev) {
                                    setCount(0)
                                    setShowMoreCount(0)
                                    setDataCount(ev.target.dataItem.component.name == "Inprogress" ? ev.target.dataItem._dataContext.inprogress_compliance_count
                                        : ev.target.dataItem.component.name == "Complied" ? ev.target.dataItem._dataContext.complied_count
                                            : ev.target.dataItem.component.name == "Not Complied" ? ev.target.dataItem._dataContext.not_complied_count
                                                : ev.target.dataItem.component.name == "Delayed Compliance" ? ev.target.dataItem._dataContext.delayed_compliance_count : 0)
                                    console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                                    setCompliance(ev.target.dataItem.component.name);
                                    console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                                    const payload = [

                                        authtoken,

                                        {

                                            "session_token": authtoken,

                                            "request": [
                                                "GetComplianceStatusDrillDownData",
                                                {
                                                    "d_ids": [
                                                        commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                    ],
                                                    "from_date": null,
                                                    "to_date": null,
                                                    "filter_type": ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                                        (fieldType == "Division" && go == true) ? `Division` : (
                                                            (fieldType == "Category" && go == true) ? "Category" : (
                                                                (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                                                            ))
                                                    )),
                                                    "filter_id": ev.target.dataItem._dataContext.typeId,
                                                    "compliance_status": ev.target.dataItem.component.name,
                                                    "chart_year": year,
                                                    "record_count": 0,
                                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                    "country_domains": countryDomain,
                                                    "criticality": ""
                                                }
                                            ]

                                        }

                                    ]



                                    getCompliancestatusTable({

                                        payload: payload,

                                        paramid: paramid

                                    })
                                    setTable(true)
                                })

                                series.columns.template.height = am4core.percent(70);

                                // series.columns.template.column3D.stroke = am4core.color("#fff");
                                // series.columns.template.column3D.strokeOpacity = 0.2;
                                series.calculatePercent = true;
                                series.calculateTotals = true;
                                series.columns.template.tooltipText = "{name}: {valueX.totalPercent}[/]% : ({valueX} out of {valueX.total}[/])";
                            }
                            createSeries("complied_count", "Complied");
                            createSeries("delayed_compliance_count", "Delayed Compliance");
                            createSeries("not_complied_count", "Not Complied");
                            createSeries("inprogress_compliance_count", "Inprogress");

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            var label7 = chart.createChild(am4core.Label);
                            label7.text = `${textValue}`
                            label7.fontSize = 14;
                            label7.align = "left";
                            label7.marginLeft = '1%';
                            label7.marginRight = '2%';
                            label7.marginTop = '2%';
                            label7.fontWeight = 'bold'
                            label7.height = '5%'
                            label7.width = '50%'
                            label7.wrap = true

                            if (dateState === true && go === true) {
                                var label2 = chart.createChild(am4core.Label)
                                label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                                label2.align = "left";
                                label2.marginLeft = '1%';
                                label2.marginRight = '2%';
                                label2.marginTop = '2%';
                                label2.marginBottom = '5%'
                            }
                        }
                        addChart(chartData);
                    }
                }
            }
        }

    }, [dataValue, timeZoneData, timeZone, table, chartType, multiSelect, criticaltityStatus, chartName, chartData, textValue])


    // useEffect(() => {
    //     if ((table == false) && (criticaltityStatus == false)) {
    //         console.log('Avvvvvvvvs');
    //         let total = ((dataValue && dataValue.length && dataValue[0].complied_count) + (dataValue && dataValue.length && dataValue[0].delayed_compliance_count) + (dataValue && dataValue.length && dataValue[0].inprogress_compliance_count) + (dataValue && dataValue.length && dataValue[0].not_complied_count))
    //         let totalValue = 100 / ((dataValue && dataValue.length && dataValue[0].complied_count) + (dataValue && dataValue.length && dataValue[0].delayed_compliance_count) + (dataValue && dataValue.length && dataValue[0].inprogress_compliance_count) + (dataValue && dataValue.length && dataValue[0].not_complied_count))
    //         if (dataValue && dataValue.length > 0) {
    //             if (chartName == 'chord') {
    //                 console.log('testinggggg');
    //                 if (chartType == 'count') {
    //                     am4core.useTheme(am4themes_animated);
    //                     // Themes end

    //                     var chart = am4core.create("chartdiv", am4charts.ChordDiagram);

    //                     // colors of main characters
    //                     // chart.colors.saturation = 0.45;
    //                     // chart.colors.step = 3;
    //                     var colors = {
    //                         India: chart.colors.next(),
    //                         Complied: "#0de580",
    //                         DelayedCompliance: "#3c78d8",
    //                         Inprogress: "#f9c80e",
    //                         NotComplied: "#fe4365",

    //                     }

    //                     // data was provided by: https://www.reddit.com/user/notrudedude

    //                     chart.data = [
    //                         // node property fields take data from data items where they are first mentioned, that's 
    //                         // why we add empty data items at the beginning and set colors here
    //                         { "from": "India", "color": colors.India },
    //                         { "from": "Complied", "color": colors.Complied },
    //                         { "from": "Delayed Compliance", "color": colors.DelayedCompliance },
    //                         { "from": "Inprogress", "color": colors.Inprogress },
    //                         { "from": "Not Complied", "color": colors.NotComplied },


    //                         // real data
    //                         // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
    //                         { "from": "Complied", "to": "India", "value": dataValue && dataValue.length && dataValue[0].complied_count },
    //                         { "from": "Delayed Compliance", "to": "India", "value": dataValue && dataValue.length && dataValue[0].delayed_compliance_count },
    //                         { "from": "Inprogress", "to": "India", "value": dataValue && dataValue.length && dataValue[0].inprogress_compliance_count },
    //                         { "from": "Not Complied", "to": "India", "value": dataValue && dataValue.length && dataValue[0].not_complied_count },

    //                     ]


    //                     //     }
    //                     // }, [dataValue, table, chartType, criticaltityStatus])
    //                     console.log('iiiiiiiiiiiii');
    //                     console.log(tableData, 'tableData');

    //                     chart.dataFields.fromName = "from";
    //                     chart.dataFields.toName = "to";
    //                     chart.dataFields.value = "value";
    //                     chart.nodePadding = 0.9;
    //                     chart.minNodeSize = 0.01;
    //                     chart.startAngle = 80;
    //                     chart.endAngle = chart.startAngle + 360;
    //                     chart.sortBy = "value";
    //                     chart.fontSize = 10;

    //                     var nodeTemplate = chart.nodes.template;
    //                     //  nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
    //                     nodeTemplate.showSystemTooltip = true;
    //                     nodeTemplate.propertyFields.fill = "color";
    //                     nodeTemplate.tooltipText = "{name}";

    //                     // when rolled over the node, make all the links rolled-over
    //                     nodeTemplate.events.on("over", function (event) {
    //                         var node = event.target;
    //                         node.outgoingDataItems.each(function (dataItem) {
    //                             if (dataItem.toNode) {
    //                                 dataItem.link.isHover = true;
    //                                 dataItem.toNode.label.isHover = true;
    //                             }
    //                         })
    //                         node.incomingDataItems.each(function (dataItem) {
    //                             if (dataItem.fromNode) {
    //                                 dataItem.link.isHover = true;
    //                                 dataItem.fromNode.label.isHover = true;
    //                             }
    //                         })

    //                         node.label.isHover = true;
    //                     })

    //                     // when rolled out from the node, make all the links rolled-out
    //                     nodeTemplate.events.on("out", function (event) {
    //                         var node = event.target;
    //                         node.outgoingDataItems.each(function (dataItem) {
    //                             if (dataItem.toNode) {
    //                                 dataItem.link.isHover = false;
    //                                 dataItem.toNode.label.isHover = false;
    //                             }
    //                         })
    //                         node.incomingDataItems.each(function (dataItem) {
    //                             if (dataItem.fromNode) {
    //                                 dataItem.link.isHover = false;
    //                                 dataItem.fromNode.label.isHover = false;
    //                             }
    //                         })

    //                         node.label.isHover = false;
    //                     })

    //                     var label = nodeTemplate.label;
    //                     label.relativeRotation = 90;

    //                     label.fillOpacity = 0.4;
    //                     let labelHS = label.states.create("hover");
    //                     labelHS.properties.fillOpacity = 1;

    //                     nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    //                     // this adapter makes non-main character nodes to be filled with color of the main character which he/she kissed most
    //                     nodeTemplate.adapter.add("fill", function (fill, target) {
    //                         let node = target;
    //                         let counters = {};
    //                         let mainChar = false;
    //                         node.incomingDataItems.each(function (dataItem) {
    //                             if (colors[dataItem.toName]) {
    //                                 mainChar = true;
    //                             }

    //                             if (isNaN(counters[dataItem.fromName])) {
    //                                 counters[dataItem.fromName] = dataItem.value;
    //                             }
    //                             else {
    //                                 counters[dataItem.fromName] += dataItem.value;
    //                             }
    //                         })
    //                         if (mainChar) {
    //                             return fill;
    //                         }

    //                         let count = 0;
    //                         let color;
    //                         let biggest = 0;
    //                         let biggestName;

    //                         for (var name in counters) {
    //                             if (counters[name] > biggest) {
    //                                 biggestName = name;
    //                                 biggest = counters[name];
    //                             }
    //                         }
    //                         if (colors[biggestName]) {
    //                             fill = colors[biggestName];
    //                         }

    //                         return fill;
    //                     })

    //                     // link template
    //                     var linkTemplate = chart.links.template;
    //                     linkTemplate.strokeOpacity = 0;
    //                     linkTemplate.fillOpacity = 0.15;
    //                     linkTemplate.tooltipText = `{fromName}:{value} out of ${total}`;

    //                     var hoverState = linkTemplate.states.create("hover");
    //                     hoverState.properties.fillOpacity = 0.9;
    //                     hoverState.properties.strokeOpacity = 0.9
    //                     linkTemplate.events.on("hit", function (ev) {
    //                         console.log("clicked on ", ev.target.link._dataItem.properties.fromName
    //                         );

    //                         setCompliance(ev.target.link._dataItem.properties.fromName);
    //                         console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
    //                         const payload = [

    //                             authtoken,

    //                             {

    //                                 "session_token": authtoken,

    //                                 "request": [
    //                                     "GetComplianceStatusDrillDownData",
    //                                     {
    //                                         "d_ids": [
    //                                             commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                         ],
    //                                         "from_date": null,
    //                                         "to_date": null,
    //                                         "filter_type": "Group",
    //                                         "filter_id": 1,
    //                                         "compliance_status": ev.target.link._dataItem.properties.fromName,
    //                                         "chart_year": year,
    //                                         "record_count": 0,
    //                                         "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                                         "country_domains": [
    //                                             {
    //                                                 "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                                 "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                                 "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                                 "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                             }
    //                                         ],
    //                                         "criticality": ""
    //                                     }
    //                                 ]

    //                             }

    //                         ]



    //                         getCompliancestatusTable({

    //                             payload: payload,

    //                             paramid: paramid

    //                         })
    //                         setTable(true)

    //                     }, this);



    //                 }
    //                 else if (chartType == 'Percentage') {
    //                     am4core.useTheme(am4themes_animated);
    //                     // Themes end

    //                     var chart = am4core.create("chartdiv", am4charts.ChordDiagram);

    //                     // colors of main characters
    //                     // chart.colors.saturation = 0.45;
    //                     // chart.colors.step = 3;
    //                     var colors = {
    //                         India: chart.colors.next(),
    //                         Complied: "#0de580",
    //                         DelayedCompliance: "#3c78d8",
    //                         Inprogress: "#f9c80e",
    //                         NotComplied: "#fe4365",

    //                     }

    //                     // data was provided by: https://www.reddit.com/user/notrudedude

    //                     chart.data = [
    //                         // node property fields take data from data items where they are first mentioned, that's 
    //                         // why we add empty data items at the beginning and set colors here
    //                         { "from": "India", "color": colors.India },
    //                         { "from": "Complied", "color": colors.Complied },
    //                         { "from": "Delayed Compliance", "color": colors.DelayedCompliance },
    //                         { "from": "Inprogress", "color": colors.Inprogress },
    //                         { "from": "Not Complied", "color": colors.NotComplied },


    //                         // real data
    //                         // { "from": "India", "to": "Complied"+"DelayedCompliance"+"Inprogress"+"NotComplied", "value": ( dataValue && dataValue.length && dataValue[0].complied_count) +( dataValue && dataValue.length && dataValue[0].delayed_compliance_count)+(dataValue && dataValue.length && dataValue[0].inprogress_compliance_count)+(dataValue && dataValue.length && dataValue[0].not_complied_count)},
    //                         { "from": "Complied", "to": "India", "value": dataValue && dataValue.length && dataValue[0].complied_count },
    //                         { "from": "Delayed Compliance", "to": "India", "value": dataValue && dataValue.length && dataValue[0].delayed_compliance_count },
    //                         { "from": "Inprogress", "to": "India", "value": dataValue && dataValue.length && dataValue[0].inprogress_compliance_count },
    //                         { "from": "Not Complied", "to": "India", "value": dataValue && dataValue.length && dataValue[0].not_complied_count },

    //                     ]


    //                     chart.dataFields.fromName = "from";
    //                     chart.dataFields.toName = "to";
    //                     chart.dataFields.value = "value";

    //                     chart.nodePadding = 0.9;
    //                     chart.minNodeSize = 0.01;
    //                     chart.startAngle = 80;
    //                     chart.endAngle = chart.startAngle + 360;
    //                     chart.sortBy = "value";
    //                     chart.fontSize = 10;
    //                     chart.events.on("hit", function (ev) {
    //                         console.log('ev.target.baseSprite');
    //                     });

    //                     var nodeTemplate = chart.nodes.template;
    //                     // nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
    //                     nodeTemplate.showSystemTooltip = true;
    //                     nodeTemplate.propertyFields.fill = "color";
    //                     nodeTemplate.tooltipText = "{name}";
    //                     nodeTemplate.clickable = true;

    //                     // when rolled over the node, make all the links rolled-over
    //                     nodeTemplate.events.on("over", function (event) {
    //                         var node = event.target;
    //                         node.outgoingDataItems.each(function (dataItem) {
    //                             if (dataItem.toNode) {
    //                                 dataItem.link.isHover = true;
    //                                 dataItem.toNode.label.isHover = true;
    //                             }
    //                         })
    //                         node.incomingDataItems.each(function (dataItem) {
    //                             if (dataItem.fromNode) {
    //                                 dataItem.link.isHover = true;
    //                                 dataItem.fromNode.label.isHover = true;
    //                             }
    //                         })

    //                         node.label.isHover = true;
    //                     })

    //                     // when rolled out from the node, make all the links rolled-out
    //                     nodeTemplate.events.on("out", function (event) {
    //                         var node = event.target;
    //                         node.outgoingDataItems.each(function (dataItem) {
    //                             if (dataItem.toNode) {
    //                                 dataItem.link.isHover = false;
    //                                 dataItem.toNode.label.isHover = false;
    //                             }
    //                         })
    //                         node.incomingDataItems.each(function (dataItem) {
    //                             if (dataItem.fromNode) {
    //                                 dataItem.link.isHover = false;
    //                                 dataItem.fromNode.label.isHover = false;
    //                             }
    //                         })

    //                         node.label.isHover = false;
    //                     })

    //                     var label = nodeTemplate.label;
    //                     label.relativeRotation = 90;

    //                     label.fillOpacity = 0.4;
    //                     let labelHS = label.states.create("hover");
    //                     labelHS.properties.fillOpacity = 1;

    //                     nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    //                     // this adapter makes non-main character nodes to be filled with color of the main character which he/she kissed most
    //                     nodeTemplate.adapter.add("fill", function (fill, target) {
    //                         let node = target;
    //                         let counters = {};
    //                         let mainChar = false;
    //                         node.incomingDataItems.each(function (dataItem) {
    //                             if (colors[dataItem.toName]) {
    //                                 mainChar = true;
    //                             }

    //                             if (isNaN(counters[dataItem.fromName])) {
    //                                 counters[dataItem.fromName] = dataItem.value;
    //                             }
    //                             else {
    //                                 counters[dataItem.fromName] += dataItem.value;
    //                             }
    //                         })
    //                         if (mainChar) {
    //                             return fill;
    //                         }

    //                         let count = 0;
    //                         let color;
    //                         let biggest = 0;
    //                         let biggestName;

    //                         for (var name in counters) {
    //                             if (counters[name] > biggest) {
    //                                 biggestName = name;
    //                                 biggest = counters[name];
    //                             }
    //                         }
    //                         if (colors[biggestName]) {
    //                             fill = colors[biggestName];
    //                         }

    //                         return fill;
    //                     })

    //                     // link template
    //                     var linkTemplate = chart.links.template;
    //                     linkTemplate.strokeOpacity = 0;
    //                     linkTemplate.fillOpacity = 0.15;
    //                     linkTemplate.adapter.add("tooltipText", function (text, target, key) {
    //                         console.log(text);
    //                         console.log(target);
    //                         console.log(key);
    //                         console.log(target.dataItem.dataContext);
    //                         let count = (target.dataItem.dataContext.value) * totalValue
    //                         return `India-{fromName}:(${count.toFixed(2)}%)`;
    //                     });
    //                     // linkTemplate.tooltipText = `{fromName}:({value}${totalValue})`;

    //                     var hoverState = linkTemplate.states.create("hover");
    //                     hoverState.properties.fillOpacity = 0.9;
    //                     hoverState.properties.strokeOpacity = 0.9

    //                     linkTemplate.events.on("hit", function (ev) {
    //                         console.log("clicked on ", ev.target.link._dataItem.properties.fromName
    //                         );

    //                         setCompliance(ev.target.link._dataItem.properties.fromName);
    //                         console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
    //                         const payload = [

    //                             authtoken,

    //                             {

    //                                 "session_token": authtoken,

    //                                 "request": [
    //                                     "GetComplianceStatusDrillDownData",
    //                                     {
    //                                         "d_ids": [
    //                                             commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                         ],
    //                                         "from_date": null,
    //                                         "to_date": null,
    //                                         "filter_type": "Group",
    //                                         "filter_id": 1,
    //                                         "compliance_status": ev.target.link._dataItem.properties.fromName,
    //                                         "chart_year": year,
    //                                         "record_count": 0,
    //                                         "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                                         "country_domains": [
    //                                             {
    //                                                 "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                                 "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                                 "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                                 "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                             }
    //                                         ],
    //                                         "criticality": ""
    //                                     }
    //                                 ]

    //                             }

    //                         ]



    //                         getCompliancestatusTable({

    //                             payload: payload,

    //                             paramid: paramid

    //                         })
    //                         setTable(true)

    //                     }, this);


    //                 }
    //             }
    //         }
    //     }
    // }, [dataValue, table, chartType, criticaltityStatus, chartName])

    useEffect(() => {
        if ((table == false) && (criticaltityStatus == false)) {
            console.log('Avvvvvvvvs');
            let total = ((dataValue && dataValue.length && dataValue[0].complied_count) + (dataValue && dataValue.length && dataValue[0].delayed_compliance_count) + (dataValue && dataValue.length && dataValue[0].inprogress_compliance_count) + (dataValue && dataValue.length && dataValue[0].not_complied_count))
            let totalValue = 100 / ((dataValue && dataValue.length && dataValue[0].complied_count) + (dataValue && dataValue.length && dataValue[0].delayed_compliance_count) + (dataValue && dataValue.length && dataValue[0].inprogress_compliance_count) + (dataValue && dataValue.length && dataValue[0].not_complied_count))
            if (dataValue && dataValue.length > 0) {
                if (chartName == 'chord') {
                    console.log('testinggggg');
                    if (chartType == 'count') {
                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;

                        function addChart(data) {

                            // Create chart instance
                            var chart = container.createChild(am4charts.ChordDiagram);

                            var colors = {
                                India: chart.colors.next(),
                                Complied: "#0de580",
                                DelayedCompliance: "#3c78d8",
                                Inprogress: "#f9c80e",
                                NotComplied: "#fe4365",

                            }

                            // Add data
                            chart.data = data;
                            chart.dataFields.fromName = "from";
                            chart.dataFields.toName = "to";
                            chart.dataFields.value = "value";
                            chart.nodePadding = 0.9;
                            chart.minNodeSize = 0.01;
                            chart.startAngle = 80;
                            chart.endAngle = chart.startAngle + 360;
                            chart.sortBy = "value";
                            chart.fontSize = 10;

                            var nodeTemplate = chart.nodes.template;
                            //  nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
                            nodeTemplate.showSystemTooltip = true;
                            nodeTemplate.propertyFields.fill = "color";
                            nodeTemplate.tooltipText = "{name}";

                            // when rolled over the node, make all the links rolled-over
                            nodeTemplate.events.on("over", function (event) {
                                var node = event.target;
                                node.outgoingDataItems.each(function (dataItem) {
                                    if (dataItem.toNode) {
                                        dataItem.link.isHover = true;
                                        dataItem.toNode.label.isHover = true;
                                    }
                                })
                                node.incomingDataItems.each(function (dataItem) {
                                    if (dataItem.fromNode) {
                                        dataItem.link.isHover = true;
                                        dataItem.fromNode.label.isHover = true;
                                    }
                                })

                                node.label.isHover = true;
                            })

                            // when rolled out from the node, make all the links rolled-out
                            nodeTemplate.events.on("out", function (event) {
                                var node = event.target;
                                node.outgoingDataItems.each(function (dataItem) {
                                    if (dataItem.toNode) {
                                        dataItem.link.isHover = false;
                                        dataItem.toNode.label.isHover = false;
                                    }
                                })
                                node.incomingDataItems.each(function (dataItem) {
                                    if (dataItem.fromNode) {
                                        dataItem.link.isHover = false;
                                        dataItem.fromNode.label.isHover = false;
                                    }
                                })

                                node.label.isHover = false;
                            })

                            var label = nodeTemplate.label;
                            label.relativeRotation = 90;

                            label.fillOpacity = 0.4;
                            let labelHS = label.states.create("hover");
                            labelHS.properties.fillOpacity = 1;

                            nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                            // this adapter makes non-main character nodes to be filled with color of the main character which he/she kissed most
                            nodeTemplate.adapter.add("fill", function (fill, target) {
                                let node = target;
                                let counters = {};
                                let mainChar = false;
                                node.incomingDataItems.each(function (dataItem) {
                                    if (colors[dataItem.toName]) {
                                        mainChar = true;
                                    }

                                    if (isNaN(counters[dataItem.fromName])) {
                                        counters[dataItem.fromName] = dataItem.value;
                                    }
                                    else {
                                        counters[dataItem.fromName] += dataItem.value;
                                    }
                                })
                                if (mainChar) {
                                    return fill;
                                }

                                let count = 0;
                                let color;
                                let biggest = 0;
                                let biggestName;

                                for (var name in counters) {
                                    if (counters[name] > biggest) {
                                        biggestName = name;
                                        biggest = counters[name];
                                    }
                                }
                                if (colors[biggestName]) {
                                    fill = colors[biggestName];
                                }

                                return fill;
                            })

                            // link template
                            var linkTemplate = chart.links.template;
                            linkTemplate.strokeOpacity = 0;
                            linkTemplate.fillOpacity = 0.15;
                            linkTemplate.tooltipText = `{fromName}:{value} out of ${chordCount}`;

                            var hoverState = linkTemplate.states.create("hover");
                            hoverState.properties.fillOpacity = 0.9;
                            hoverState.properties.strokeOpacity = 0.9
                            linkTemplate.events.on("hit", function (ev) {
                                console.log("clicked on ", ev.target.link._dataItem.properties.fromName
                                );
                                setCount(0)
                                setCategoryName(ev.target.link.dataItem.categories.categoryY)
                                setCompliance(ev.target.link._dataItem.properties.fromName);
                                console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                                const payload = [

                                    authtoken,

                                    {

                                        "session_token": authtoken,

                                        "request": [
                                            "GetComplianceStatusDrillDownData",
                                            {
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "from_date": null,
                                                "to_date": null,
                                                "filter_type": "Group",
                                                "filter_id": 1,
                                                "compliance_status": ev.target.link._dataItem.properties.fromName,
                                                "chart_year": year,
                                                "record_count": 0,
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                "country_domains": countryDomain,
                                                "criticality": ""
                                            }
                                        ]

                                    }
                                ]
                                getCompliancestatusTable({
                                    payload: payload,
                                    paramid: paramid
                                })
                                setTable(true)

                            }, this);

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'
                            title.fontSize = '14px'

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            var label7 = chart.createChild(am4core.Label);
                            label7.text = `${textValue}`
                            label7.fontSize = 14;
                            label7.align = "left";
                            label7.marginLeft = '1%';
                            label7.marginRight = '2%';
                            label7.marginTop = '2%';
                            label7.fontWeight = 'bold'
                            label7.height = '5%'
                            label7.width = '50%'
                            label7.wrap = true

                            if (dateState === true && go === true) {
                                var label2 = chart.createChild(am4core.Label)
                                label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                                label2.align = "left";
                                label2.marginLeft = '1%';
                                label2.marginRight = '2%';
                                label2.marginTop = '2%';
                                label2.marginBottom = '5%'
                            }

                        }

                        addChart(chordChartData);
                    }
                    else if (chartType == 'Percentage') {
                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;

                        function addChart(data) {

                            // Create chart instance
                            var chart = container.createChild(am4charts.ChordDiagram);

                            var colors = {
                                India: chart.colors.next(),
                                Complied: "#0de580",
                                DelayedCompliance: "#3c78d8",
                                Inprogress: "#f9c80e",
                                NotComplied: "#fe4365",

                            }

                            // Add data
                            chart.data = data;

                            chart.dataFields.fromName = "from";
                            chart.dataFields.toName = "to";
                            chart.dataFields.value = "value";

                            chart.nodePadding = 0.9;
                            chart.minNodeSize = 0.01;
                            chart.startAngle = 80;
                            chart.endAngle = chart.startAngle + 360;
                            chart.sortBy = "value";
                            chart.fontSize = 10;
                            chart.events.on("hit", function (ev) {
                                console.log('ev.target.baseSprite');
                            });

                            var nodeTemplate = chart.nodes.template;
                            // nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
                            nodeTemplate.showSystemTooltip = true;
                            nodeTemplate.propertyFields.fill = "color";
                            nodeTemplate.tooltipText = "{name}";
                            nodeTemplate.clickable = true;

                            // when rolled over the node, make all the links rolled-over
                            nodeTemplate.events.on("over", function (event) {
                                var node = event.target;
                                node.outgoingDataItems.each(function (dataItem) {
                                    if (dataItem.toNode) {
                                        dataItem.link.isHover = true;
                                        dataItem.toNode.label.isHover = true;
                                    }
                                })
                                node.incomingDataItems.each(function (dataItem) {
                                    if (dataItem.fromNode) {
                                        dataItem.link.isHover = true;
                                        dataItem.fromNode.label.isHover = true;
                                    }
                                })

                                node.label.isHover = true;
                            })

                            // when rolled out from the node, make all the links rolled-out
                            nodeTemplate.events.on("out", function (event) {
                                var node = event.target;
                                node.outgoingDataItems.each(function (dataItem) {
                                    if (dataItem.toNode) {
                                        dataItem.link.isHover = false;
                                        dataItem.toNode.label.isHover = false;
                                    }
                                })
                                node.incomingDataItems.each(function (dataItem) {
                                    if (dataItem.fromNode) {
                                        dataItem.link.isHover = false;
                                        dataItem.fromNode.label.isHover = false;
                                    }
                                })

                                node.label.isHover = false;
                            })

                            var label = nodeTemplate.label;
                            label.relativeRotation = 90;

                            label.fillOpacity = 0.4;
                            let labelHS = label.states.create("hover");
                            labelHS.properties.fillOpacity = 1;

                            nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                            // this adapter makes non-main character nodes to be filled with color of the main character which he/she kissed most
                            nodeTemplate.adapter.add("fill", function (fill, target) {
                                let node = target;
                                let counters = {};
                                let mainChar = false;
                                node.incomingDataItems.each(function (dataItem) {
                                    if (colors[dataItem.toName]) {
                                        mainChar = true;
                                    }

                                    if (isNaN(counters[dataItem.fromName])) {
                                        counters[dataItem.fromName] = dataItem.value;
                                    }
                                    else {
                                        counters[dataItem.fromName] += dataItem.value;
                                    }
                                })
                                if (mainChar) {
                                    return fill;
                                }

                                let count = 0;
                                let color;
                                let biggest = 0;
                                let biggestName;

                                for (var name in counters) {
                                    if (counters[name] > biggest) {
                                        biggestName = name;
                                        biggest = counters[name];
                                    }
                                }
                                if (colors[biggestName]) {
                                    fill = colors[biggestName];
                                }

                                return fill;
                            })

                            // link template
                            var linkTemplate = chart.links.template;
                            linkTemplate.strokeOpacity = 0;
                            linkTemplate.fillOpacity = 0.15;
                            linkTemplate.adapter.add("tooltipText", function (text, target, key) {
                                console.log(text);
                                console.log(target);
                                console.log(key);
                                console.log(target.dataItem.dataContext);
                                let count = ((target.dataItem.dataContext.value) / chordCount) * 100
                                return `India-{fromName}:(${count.toFixed(2)}%)`;
                            });
                            // linkTemplate.tooltipText = `{fromName}:({value}${totalValue})`;

                            var hoverState = linkTemplate.states.create("hover");
                            hoverState.properties.fillOpacity = 0.9;
                            hoverState.properties.strokeOpacity = 0.9

                            linkTemplate.events.on("hit", function (ev) {
                                console.log("clicked on ", ev.target.link._dataItem.properties.fromName
                                );
                                setCategoryName(ev.target.link.dataItem.categories.categoryY)
                                setCompliance(ev.target.link._dataItem.properties.fromName);
                                console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                                const payload = [

                                    authtoken,

                                    {

                                        "session_token": authtoken,

                                        "request": [
                                            "GetComplianceStatusDrillDownData",
                                            {
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "from_date": null,
                                                "to_date": null,
                                                "filter_type": "Group",
                                                "filter_id": 1,
                                                "compliance_status": ev.target.link._dataItem.properties.fromName,
                                                "chart_year": year,
                                                "record_count": 0,
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                "country_domains": countryDomain,
                                                "criticality": ""
                                            }
                                        ]

                                    }

                                ]



                                getCompliancestatusTable({

                                    payload: payload,

                                    paramid: paramid

                                })
                                setTable(true)

                            }, this);

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'
                            title.fontSize = '14px'


                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            var label7 = chart.createChild(am4core.Label);
                            label7.text = `${textValue}`
                            label7.fontSize = 14;
                            label7.align = "left";
                            label7.marginLeft = '1%';
                            label7.marginRight = '2%';
                            label7.marginTop = '2%';
                            label7.fontWeight = 'bold'
                            label7.height = '5%'
                            label7.width = '50%'
                            label7.wrap = true

                            if (dateState === true && go === true) {
                                var label2 = chart.createChild(am4core.Label)
                                label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                                label2.align = "left";
                                label2.marginLeft = '1%';
                                label2.marginRight = '2%';
                                label2.marginTop = '2%';
                                label2.marginBottom = '5%'
                            }

                        }

                        addChart(chordChartData);


                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, chordChartData])


    // useEffect(() => {
    //     if ((table == false) && (criticaltityStatus == false)) {
    //         let total = ((dataValue && dataValue.length && dataValue[0].complied_count) + (dataValue && dataValue.length && dataValue[0].delayed_compliance_count) + (dataValue && dataValue.length && dataValue[0].inprogress_compliance_count) + (dataValue && dataValue.length && dataValue[0].not_complied_count))
    //         console.log('Avvvvvvvvs');
    //         if (dataValue && dataValue.length > 0) {
    //             if (chartName == 'pie') {
    //                 console.log('testinggggg');
    //                 if (chartType == 'count') {
    //                     am4core.useTheme(am4themes_animated);

    //                     var data = [{
    //                         "country": "India",
    //                         "count": ((dataValue && dataValue.length && dataValue[0].complied_count) + (dataValue && dataValue.length && dataValue[0].delayed_compliance_count) + (dataValue && dataValue.length && dataValue[0].inprogress_compliance_count) + (dataValue && dataValue.length && dataValue[0].not_complied_count)),
    //                         "pie": [

    //                             { "title": "Complied", "value": dataValue && dataValue.length && dataValue[0].complied_count, "color": "#0de580" },
    //                             { "title": "DelayedCompliance", "value": dataValue && dataValue.length && dataValue[0].delayed_compliance_count, "color": "#3c78d8" },
    //                             { "title": "Inprogress", "value": dataValue && dataValue.length && dataValue[0].inprogress_compliance_count, "color": "#f9c80e" },
    //                             { "title": "NotComplied", "value": dataValue && dataValue.length && dataValue[0].not_complied_count, "color": "#fe4365" },
    //                         ]

    //                     }];


    //                     // Create chart instance
    //                     var chart = am4core.create("chartdiv", am4charts.XYChart3D);
    //                     chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    //                     // Add data
    //                     chart.data = data;

    //                     // Create axes
    //                     var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    //                     categoryAxis.dataFields.category = "country";
    //                     categoryAxis.title.text = "Countries";
    //                     // categoryAxis.renderer.grid.template.disabled = true;

    //                     var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //                     valueAxis.title.text = "Total Compliance";
    //                     valueAxis.min = 0;
    //                     valueAxis.renderer.baseGrid.disabled = true;
    //                     valueAxis.renderer.grid.template.strokeOpacity = 0.07;
    //                     chart.scrollbarX = new am4core.Scrollbar();
    //                     chart.scrollbarX.parent = chart.topAxesContainer;
    //                     // chart.plotContainer.stroke = "#000000";
    //                     // chart.plotContainer.strokeWidth = 5;
    //                     chart.scrollbarY = new am4core.Scrollbar();
    //                     chart.scrollbarY.parent = chart.rightAxesContainer;
    //                     // Create series
    //                     var series = chart.series.push(new am4charts.ColumnSeries());
    //                     series.dataFields.valueY = "count";
    //                     series.dataFields.categoryX = "country";
    //                     series.tooltip.pointerOrientation = "vertical";
    //                     var columnTemplate = series.columns.template;
    //                     // add tooltip on column, not template, so that slices could also have tooltip
    //                     columnTemplate.column.tooltipText = "{categoryX}:{valueY} ";
    //                     columnTemplate.column.tooltipY = 0;
    //                     columnTemplate.column.cornerRadiusTopLeft = 20;
    //                     columnTemplate.column.cornerRadiusTopRight = 20;
    //                     columnTemplate.strokeOpacity = 0;
    //                     // create pie chart as a column child
    //                     var pieChart = series.columns.template.createChild(am4charts.PieChart3D);
    //                     pieChart.width = am4core.percent(80);
    //                     pieChart.height = am4core.percent(80);
    //                     pieChart.align = "center";
    //                     pieChart.valign = "middle";
    //                     pieChart.dataFields.data = "pie";
    //                     var pieSeries = pieChart.series.push(new am4charts.PieSeries());
    //                     pieSeries.dataFields.value = "value";
    //                     pieSeries.dataFields.category = "title";
    //                     pieSeries.calculatePercent = true;
    //                     pieSeries.calculateTotals = true;
    //                     pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
    //                     pieSeries.labels.template.disabled = true;
    //                     pieSeries.ticks.template.disabled = true;
    //                     pieSeries.slices.template.stroke = am4core.color("#ffffff");
    //                     pieSeries.slices.template.strokeWidth = 1;
    //                     pieSeries.slices.template.strokeOpacity = 0;
    //                     pieSeries.slices.template.propertyFields.fill = "color";
    //                     pieSeries.hiddenState.properties.startAngle = -90;
    //                     pieSeries.hiddenState.properties.endAngle = 270;
    //                 }
    //                 else if (chartType == 'Percentage') {
    //                     am4core.useTheme(am4themes_animated);
    //                     var data = [{
    //                         "country": "India",
    //                         "count": ((dataValue && dataValue.length && dataValue[0].complied_count) + (dataValue && dataValue.length && dataValue[0].delayed_compliance_count) + (dataValue && dataValue.length && dataValue[0].inprogress_compliance_count) + (dataValue && dataValue.length && dataValue[0].not_complied_count)),
    //                         "pie": [
    //                             { "title": "Complied", "value": dataValue && dataValue.length && dataValue[0].complied_count, "color": "#0de580" },
    //                             { "title": "DelayedCompliance", "value": dataValue && dataValue.length && dataValue[0].delayed_compliance_count, "color": "#3c78d8" },
    //                             { "title": "Inprogress", "value": dataValue && dataValue.length && dataValue[0].inprogress_compliance_count, "color": "#f9c80e" },
    //                             { "title": "NotComplied", "value": dataValue && dataValue.length && dataValue[0].not_complied_count, "color": "#fe4365" },
    //                         ]

    //                     }];


    //                     // Create chart instance
    //                     var chart = am4core.create("chartdiv", am4charts.XYChart3D);
    //                     chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    //                     // Add data
    //                     chart.data = data;

    //                     // Create axes
    //                     var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    //                     categoryAxis.dataFields.category = "country";
    //                     categoryAxis.title.text = "Countries";
    //                     // categoryAxis.renderer.grid.template.disabled = true;

    //                     var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //                     valueAxis.title.text = "Total Compliance";
    //                     valueAxis.min = 0;
    //                     valueAxis.renderer.baseGrid.disabled = true;
    //                     valueAxis.renderer.grid.template.strokeOpacity = 0.07;

    //                     // Create series
    //                     var series = chart.series.push(new am4charts.ColumnSeries());
    //                     series.dataFields.valueY = "count";
    //                     series.dataFields.categoryX = "country";
    //                     series.tooltip.pointerOrientation = "vertical";


    //                     var columnTemplate = series.columns.template;
    //                     // add tooltip on column, not template, so that slices could also have tooltip
    //                     columnTemplate.column.tooltipText = "{categoryX}: {valueY} out of ${total}";
    //                     columnTemplate.column.tooltipY = 0;
    //                     columnTemplate.column.cornerRadiusTopLeft = 20;
    //                     columnTemplate.column.cornerRadiusTopRight = 20;
    //                     columnTemplate.strokeOpacity = 0;


    //                     // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    //                     // columnTemplate.adapter.add("fill", (fill, target) => {
    //                     //     var color = chart.colors.getIndex(target.dataItem.index * 3);
    //                     //     return color;
    //                     // });

    //                     // create pie chart as a column child
    //                     var pieChart = series.columns.template.createChild(am4charts.PieChart3D);
    //                     pieChart.width = am4core.percent(80);
    //                     pieChart.height = am4core.percent(80);
    //                     pieChart.align = "center";
    //                     pieChart.valign = "middle";
    //                     pieChart.dataFields.data = "pie";

    //                     var pieSeries = pieChart.series.push(new am4charts.PieSeries());
    //                     pieSeries.dataFields.value = "value";
    //                     pieSeries.dataFields.category = "title";
    //                     pieSeries.labels.template.disabled = true;
    //                     pieSeries.ticks.template.disabled = true;
    //                     pieSeries.slices.template.stroke = am4core.color("#ffffff");
    //                     pieSeries.slices.template.strokeWidth = 1;
    //                     pieSeries.slices.template.strokeOpacity = 0;
    //                     pieSeries.slices.template.propertyFields.fill = "color";
    //                     pieSeries.calculatePercent = true;
    //                     pieSeries.calculateTotals = true;
    //                     pieSeries.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#.00')}[/]%";
    //                     // pieSeries.slices.template.adapter.add("fill", (fill, target) => {
    //                     //     return am4core.color("#ffffff")
    //                     // });

    //                     // pieSeries.slices.template.adapter.add("fillOpacity", (fillOpacity, target) => {
    //                     //     return (target.dataItem.index + 1) * 0.2;
    //                     // });

    //                     pieSeries.hiddenState.properties.startAngle = -90;
    //                     pieSeries.hiddenState.properties.endAngle = 270;

    //                 }

    //             }
    //         }
    //     }
    // }, [dataValue, table, chartType, criticaltityStatus, chartName])

    useEffect(() => {
        if ((table == false) && (criticaltityStatus == false)) {
            let total = ((dataValue && dataValue.length && dataValue[0].complied_count) + (dataValue && dataValue.length && dataValue[0].delayed_compliance_count) + (dataValue && dataValue.length && dataValue[0].inprogress_compliance_count) + (dataValue && dataValue.length && dataValue[0].not_complied_count))
            console.log('Avvvvvvvvs');
            if (dataValue && dataValue.length > 0) {
                if (chartName == 'pie') {
                    console.log('testinggggg');
                    if (chartType == 'count') {
                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;

                        function addChart(data) {
                            var chart = container.createChild(am4charts.XYChart3D);

                            // Add data
                            chart.data = data;
                            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                            // Add data
                            chart.data = data;

                            // Create axes
                            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.dataFields.category = "data";
                            categoryAxis.title.text =
                                ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                    (fieldType == "Division" && go == true) ? `Division` : (
                                        (fieldType == "Category" && go == true) ? `Category` : (
                                            (fieldType == "BusinessGroup" && go == true) ? `Business Groups` : (
                                                (fieldType == "Unit" && go == true) ? `Unit` : `Countries`
                                            )))
                                ))
                            // categoryAxis.renderer.grid.template.disabled = true;
                            categoryAxis.renderer.labels.template.events.on("hit", function (event) {
                                if (chartData.length > 1) {
                                    console.log(event.target.dataItem._dataContext.pieValue, 'event.target.dataItem._dataContext');
                                    setSelectChart(event.target.dataItem._dataContext.pieValue)

                                }
                            })
                            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                            valueAxis.title.text = "Total Compliance";
                            valueAxis.min = 0;
                            valueAxis.renderer.baseGrid.disabled = true;
                            valueAxis.renderer.grid.template.strokeOpacity = 0.07;
                            chart.scrollbarX = new am4core.Scrollbar();
                            chart.scrollbarX.parent = chart.topAxesContainer;
                            // chart.plotContainer.stroke = "#000000";
                            // chart.plotContainer.strokeWidth = 5;
                            chart.scrollbarY = new am4core.Scrollbar();
                            chart.scrollbarY.parent = chart.rightAxesContainer;
                            // Create series
                            var series = chart.series.push(new am4charts.ColumnSeries());
                            series.dataFields.valueY = "count";
                            series.dataFields.categoryX = "data";
                            series.tooltip.pointerOrientation = "vertical";
                            var columnTemplate = series.columns.template;
                            // add tooltip on column, not template, so that slices could also have tooltip
                            columnTemplate.column.tooltipText = "{categoryX}:{valueY} ";
                            columnTemplate.column.tooltipY = 0;
                            columnTemplate.column.cornerRadiusTopLeft = 20;
                            columnTemplate.column.cornerRadiusTopRight = 20;
                            columnTemplate.strokeOpacity = 0;
                            // create pie chart as a column child
                            var pieChart = series.columns.template.createChild(am4charts.PieChart3D);
                            pieChart.width = am4core.percent(80);
                            pieChart.height = am4core.percent(80);
                            pieChart.align = "center";
                            pieChart.valign = "middle";
                            pieChart.dataFields.data = "pieValue";
                            var pieSeries = pieChart.series.push(new am4charts.PieSeries());
                            pieSeries.dataFields.value = "value";
                            pieSeries.dataFields.category = "title";
                            pieSeries.calculatePercent = true;
                            pieSeries.calculateTotals = true;
                            pieSeries.slices.template.tooltipText = `{category}: {value} out of ${columncount}`;
                            pieSeries.labels.template.disabled = true;
                            pieSeries.ticks.template.disabled = true;
                            pieSeries.slices.template.stroke = am4core.color("#ffffff");
                            pieSeries.slices.template.strokeWidth = 1;
                            pieSeries.slices.template.strokeOpacity = 0;
                            pieSeries.slices.template.propertyFields.fill = "color";
                            pieSeries.hiddenState.properties.startAngle = -90;
                            pieSeries.hiddenState.properties.endAngle = 270;

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'
                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '2%'

                            var label7 = chart.createChild(am4core.Label);
                            label7.text = `${textValue}`
                            label7.fontSize = 14;
                            label7.align = "left";
                            label7.marginLeft = '1%';
                            label7.marginRight = '2%';
                            label7.marginTop = '2%';
                            label7.fontWeight = 'bold'
                            label7.height = '5%'
                            label7.width = '50%'
                            label7.wrap = true

                            if (dateState === true && go === true) {
                                var label2 = chart.createChild(am4core.Label)
                                label2.text = `Date : ${moment(filterData.from_date).format('DD-MMM-YYYY')} to ${moment(filterData.to_date).format('DD-MMM-YYYY')}`
                                label2.align = "left";
                                label2.marginLeft = '1%';
                                label2.marginRight = '2%';
                                label2.marginTop = '2%';
                                label2.marginBottom = '5%'
                            }

                        }

                        addChart(columChart);

                    }
                    else if (chartType == 'Percentage') {
                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;

                        function addChart(data) {
                            var chart = container.createChild(am4charts.XYChart3D);

                            // Add data
                            chart.data = data;
                            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                            // Add data
                            chart.data = data;

                            // Create axes
                            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.dataFields.category = "data";
                            categoryAxis.title.text =
                                ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                                    (fieldType == "Division" && go == true) ? `Division` : (
                                        (fieldType == "Category" && go == true) ? `Category` : (
                                            (fieldType == "Unit" && go == true) ? `Unit` : `Countries`
                                        ))
                                ))
                            // categoryAxis.renderer.grid.template.disabled = true;

                            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                            valueAxis.title.text = "Total Compliance";
                            valueAxis.min = 0;
                            valueAxis.renderer.baseGrid.disabled = true;
                            valueAxis.renderer.grid.template.strokeOpacity = 0.07;

                            // Create series
                            var series = chart.series.push(new am4charts.ColumnSeries());
                            series.dataFields.valueY = "count";
                            series.dataFields.categoryX = "data";
                            series.tooltip.pointerOrientation = "vertical";


                            var columnTemplate = series.columns.template;
                            // add tooltip on column, not template, so that slices could also have tooltip
                            columnTemplate.column.tooltipText = "{categoryX}: {count}";
                            columnTemplate.column.tooltipY = 0;
                            columnTemplate.column.cornerRadiusTopLeft = 20;
                            columnTemplate.column.cornerRadiusTopRight = 20;
                            columnTemplate.strokeOpacity = 0;


                            // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
                            // columnTemplate.adapter.add("fill", (fill, target) => {
                            //     var color = chart.colors.getIndex(target.dataItem.index * 3);
                            //     return color;
                            // });

                            // create pie chart as a column child
                            var pieChart = series.columns.template.createChild(am4charts.PieChart3D);
                            pieChart.width = am4core.percent(80);
                            pieChart.height = am4core.percent(80);
                            pieChart.align = "center";
                            pieChart.valign = "middle";
                            pieChart.dataFields.data = "pieValue";

                            var pieSeries = pieChart.series.push(new am4charts.PieSeries());
                            pieSeries.dataFields.value = "value";
                            pieSeries.dataFields.category = "title";
                            pieSeries.labels.template.disabled = true;
                            pieSeries.ticks.template.disabled = true;
                            pieSeries.slices.template.stroke = am4core.color("#ffffff");
                            pieSeries.slices.template.strokeWidth = 1;
                            pieSeries.slices.template.strokeOpacity = 0;
                            pieSeries.slices.template.propertyFields.fill = "color";
                            pieSeries.calculatePercent = true;
                            pieSeries.calculateTotals = true;
                            pieSeries.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#.00')}[/]%";
                            // pieSeries.slices.template.adapter.add("fill", (fill, target) => {
                            //     return am4core.color("#ffffff")
                            // });

                            // pieSeries.slices.template.adapter.add("fillOpacity", (fillOpacity, target) => {
                            //     return (target.dataItem.index + 1) * 0.2;
                            // });

                            pieSeries.hiddenState.properties.startAngle = -90;
                            pieSeries.hiddenState.properties.endAngle = 270;

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} UTC`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                        }

                        addChart(columChart);
                    }

                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, columChart])
    useEffect(() => {
        if (filterChartName == 'donut') {
            if ((table == false) && (criticaltityStatus == false)) {
                var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;

                        function addChart(data) {

                            // Create chart instance
                            var chart = container.createChild(am4charts.PieChart3D);

                            // Add data
                            chart.data = data;

                            chart.innerRadius = am4core.percent(30);
                            function isZero(x) { return x == 0; }
                            // Add and configure Series
                            var pieSeries = chart.series.push(new am4charts.PieSeries3D());
                            pieSeries.dataFields.value = "count";
                            pieSeries.dataFields.category = "type";
                            pieSeries.slices.template.propertyFields.fill = "color";
                            chart.current = chart;
                            chart.legend = new am4charts.Legend();
                            pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;



                            // pieSeries.slices.template.stroke = am4core.color("#fff");
                            // pieSeries.slices.template.strokeWidth = 2;
                            // pieSeries.slices.template.strokeOpacity = 1;

                            chart.legend = new am4charts.Legend();
                            chart.legend.useDefaultMarker = true;
                            chart.legend.valueLabels.template.disabled = true;
                            var marker = chart.legend.markers.template.children.getIndex(0);
                            marker.cornerRadius(12, 12, 12, 12);
                            marker.strokeWidth = 2;
                            marker.strokeOpacity = 1;
                            marker.stroke = am4core.color("#ccc");


                            pieSeries.tooltip.label.adapter.add("text", function (text, target) {
                                if (target.dataItem && target.dataItem.value == 0) {
                                    return "";
                                }
                                else {
                                    return text;
                                }
                            });

                            // This creates initial animation
                            pieSeries.hiddenState.properties.opacity = 1;
                            pieSeries.hiddenState.properties.endAngle = -90;
                            pieSeries.hiddenState.properties.startAngle = -90;

                            // pieSeries.slices.template.events.on("hit", function (ev) {

                            //     // setCompliedType((ev.target.dataItem.category === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.category === '0-30 days' ? 'Below 30' : (ev.target.dataItem.category === '31-60 days' ? 'Below 60' : (ev.target.dataItem.category === '61-90 days' ? 'Below 90' : '')))))
                            //     setCount(0)
                            //         setCompliance(ev.target.dataItem.category);
                            //         setShowMoreCount(0)
                            //         // setDataCount(ev.target.dataItem.component.name == "Inprogress" ? ev.target.dataItem._dataContext.inprogress_compliance_count
                            //         //     : ev.target.dataItem.component.name == "Complied" ? ev.target.dataItem._dataContext.complied_count
                            //         //         : ev.target.dataItem.component.name == "Not Complied" ? ev.target.dataItem._dataContext.not_complied_count
                            //         //             : ev.target.dataItem.component.name == "Delayed Compliance" ? ev.target.dataItem._dataContext.delayed_compliance_count : 0)
                            //         console.log(ev.target.dataItem, ' ev.target.dataItem.component.name');
                            //         console.log(commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id, ' commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id');
                            //         const payload = [

                            //             authtoken,

                            //             {

                            //                 "session_token": authtoken,

                            //                 "request": [
                            //                     "GetComplianceStatusDrillDownData",
                            //                     {
                            //                         "d_ids": [
                            //                             commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                            //                         ],
                            //                         "from_date": null,
                            //                         "to_date": null,
                            //                         "filter_type": ((fieldType == "LegalEntity" && go == true) ? `Legal Entity` : (
                            //                             (fieldType == "Division" && go == true) ? `Division` : (
                            //                                 (fieldType == "Category" && go == true) ? "Category" : (
                            //                                     (fieldType == "Unit" && go == true) ? "Unit" : "Group"
                            //                                 ))
                            //                         )),
                            //                         "filter_id": ev.target.dataItem._dataContext.typeId,
                            //                         "compliance_status": ev.target.dataItem.category,
                            //                         "chart_year": year,
                            //                         "record_count": 0,
                            //                         "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            //                         "country_domains": countryDomain,
                            //                         "criticality": ""
                            //                     }
                            //                 ]
                            //             }
                            //         ]
                            //         getCompliancestatusTable({
                            //             payload: payload,
                            //             paramid: paramid
                            //         })
                            //         setTable(true)


                            // })

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            // fieldType == "LegalEntity" ? filterData.legalentity :
                            // fieldType == 'Division' ? Number(filterData.division) :
                            //     fieldType == 'Category' ? Number(filterData.category) :
                            //         fieldType == 'Unit' ? Number(filterData.unit) 

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            // label1.text = ['hiii','hello']
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            // var title2 = chart.titles.create();
                            // title2.text = 'Country-Domain'
                            // title2.disabled = false;
                            // title2.align = 'bottom'
                            // title2.marginTop = '2%'
                            // title2.marginLeft = '2%'

                            // title2.fontWeight = 'bold'

                            // Enable title on export
                            // chart.exporting.events.on("exportstarted", function (ev) {
                            //     title.disabled = false;
                            //     title.parent.invalidate();
                            // });

                            // // Disable title when export finishes
                            // chart.exporting.events.on("exportfinished", function (ev) {
                            //     title.disabled = true;
                            // });
                        }

                        // Add charts
                        // addChart(multiSelectChart
                        // );
                        addChart([
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count
                                    ,
                                    "color": am4core.color("#0de580")
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Complaince",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8")
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365")
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e")
                                } : {}),
                        ]);


                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart])
    useEffect(() => {
        if (filterChartName == '3d') {
            if ((table == false) && (criticaltityStatus == false)) {
                var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;

                        function addChart(data) {

                            // Create chart instance
                            var chart = container.createChild(am4charts.XYChart3D);

                            // Add data
                            chart.data = data;
                            chart.scrollbarX = new am4core.Scrollbar();
                            chart.scrollbarY = new am4core.Scrollbar();
                            chart.scrollbarY.parent = chart.rightAxesContainer;
                            chart.height = 500
                            var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.dataFields.category = "type";
                            // categoryAxis.numberFormatter.numberFormat = "#";
                            // categoryAxis.renderer.inversed = true;

                            var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());

                            // Create series
                            var series = chart.series.push(new am4charts.ColumnSeries3D());
                            series.dataFields.valueX = "count";
                            series.dataFields.categoryY = "type";
                            // series.name = "count";
                            series.columns.template.propertyFields.fill = "color";
                            series.columns.template.tooltipText = `{type}:  ({valueX} out of ${total})`;
                            // series.columns.template.column3D.stroke = am4core.color("#fff");
                            series.columns.template.maxHeight = 50

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            // fieldType == "LegalEntity" ? filterData.legalentity :
                            // fieldType == 'Division' ? Number(filterData.division) :
                            //     fieldType == 'Category' ? Number(filterData.category) :
                            //         fieldType == 'Unit' ? Number(filterData.unit) 

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            // label1.text = ['hiii','hello']
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            // var title2 = chart.titles.create();
                            // title2.text = 'Country-Domain'
                            // title2.disabled = false;
                            // title2.align = 'bottom'
                            // title2.marginTop = '2%'
                            // title2.marginLeft = '2%'

                            // title2.fontWeight = 'bold'

                            // Enable title on export
                            // chart.exporting.events.on("exportstarted", function (ev) {
                            //     title.disabled = false;
                            //     title.parent.invalidate();
                            // });

                            // // Disable title when export finishes
                            // chart.exporting.events.on("exportfinished", function (ev) {
                            //     title.disabled = true;
                            // });
                        }

                        // Add charts

                        addChart([
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count
                                    ,
                                    "color": am4core.color("#0de580")
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Complaince",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8")
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365")
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e")
                                } : {}),
                        ]);



                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart])

    useEffect(() => {
        if (filterChartName == 'bar') {
            if ((table == false) && (criticaltityStatus == false)) {
                var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;

                        function addChart(data) {

                            // Create chart instance
                            var chart = container.createChild(am4charts.XYChart3D);

                            // Add data
                            chart.data = data;
                            chart.height = 500
                            chart.scrollbarX = new am4core.Scrollbar();
                            chart.scrollbarY = new am4core.Scrollbar();
                            chart.scrollbarY.parent = chart.rightAxesContainer;
                            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.dataFields.category = "type";
                            // categoryAxis.numberFormatter.numberFormat = "#";
                            // categoryAxis.renderer.inversed = true;

                            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                            // Create series
                            var series = chart.series.push(new am4charts.ColumnSeries3D());
                            series.dataFields.categoryX = "type";
                            series.dataFields.valueY = "count";
                            // series.name = "count";
                            series.columns.template.propertyFields.fill = "color";
                            series.columns.template.tooltipText = `{type}:  ({valueY} out of ${total})`;
                            series.columns.template.column3D.stroke = am4core.color("#fff");
                            series.columns.template.column3D.strokeOpacity = 0.2;
                            series.columns.template.maxHeight = 50
                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            // fieldType == "LegalEntity" ? filterData.legalentity :
                            // fieldType == 'Division' ? Number(filterData.division) :
                            //     fieldType == 'Category' ? Number(filterData.category) :
                            //         fieldType == 'Unit' ? Number(filterData.unit) 

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            // label1.text = ['hiii','hello']
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            // var title2 = chart.titles.create();
                            // title2.text = 'Country-Domain'
                            // title2.disabled = false;
                            // title2.align = 'bottom'
                            // title2.marginTop = '2%'
                            // title2.marginLeft = '2%'

                            // title2.fontWeight = 'bold'

                            // Enable title on export
                            // chart.exporting.events.on("exportstarted", function (ev) {
                            //     title.disabled = false;
                            //     title.parent.invalidate();
                            // });

                            // // Disable title when export finishes
                            // chart.exporting.events.on("exportfinished", function (ev) {
                            //     title.disabled = true;
                            // });
                        }

                        // Add charts
                        // addChart(multiSelectChart
                        // );
                        addChart([
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count
                                    ,
                                    "color": am4core.color("#0de580")
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Complaince",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8")
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365")
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e")
                                } : {}),
                        ]);



                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart])

    useEffect(() => {
        if (filterChartName == 'curve') {
            if ((table == false) && (criticaltityStatus == false)) {
                var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;

                        function addChart(data) {
                            var chart = container.createChild(am4charts.XYChart3D);

                            chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

                            chart.data = data
                            chart.height = 500

                            // chart.colors.list = [
                            //     am4core.color("#0cce73"),
                            //     am4core.color("#f9c80e"),
                            //     am4core.color("#e53c5b"),
                            //     // am4core.color("#fe4365"),
                            // ];


                            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.renderer.grid.template.location = 0;
                            categoryAxis.dataFields.category = "type";
                            categoryAxis.renderer.minGridDistance = 40;

                            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                            var series = chart.series.push(new am4charts.CurvedColumnSeries());
                            series.dataFields.categoryX = "type";
                            series.dataFields.valueY = "count";
                            // series.tooltipText = "{valueY.value}"
                            series.columns.template.strokeOpacity = 0;
                            // series.columns.template.tension = 1;
                            series.columns.template.propertyFields.fill = "color";
                            series.columns.template.fillOpacity = 0.75;
                            series.columns.template.maxHeight = 50
                            series.columns.template.tooltipText = `{type}:  ({valueY} out of ${total})`;
                            var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                            labelBullet.label.text = "{valueY}";
                            labelBullet.locationX = 0.5;

                            // var hoverState = series.columns.template.states.create("hover");
                            // hoverState.properties.fillOpacity = 1;
                            // hoverState.properties.tension = 0.8;

                            // chart.cursor = new am4charts.XYCursor();

                            // Add distinctive colors for each column using adapter
                            // series.columns.template.adapter.add("fill", function (fill, target) {
                            //     return chart.colors.getIndex(target.dataItem.index);
                            // });

                            // chart.scrollbarX = new am4core.Scrollbar();
                            chart.scrollbarY = new am4core.Scrollbar();
                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            // fieldType == "LegalEntity" ? filterData.legalentity :
                            // fieldType == 'Division' ? Number(filterData.division) :
                            //     fieldType == 'Category' ? Number(filterData.category) :
                            //         fieldType == 'Unit' ? Number(filterData.unit) 

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            // label1.text = ['hiii','hello']
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                        }
                        addChart([
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count
                                    ,
                                    "color": am4core.color("#0de580")
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Complaince",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8")
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365")
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e")
                                } : {}),
                        ]);
                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart])
    useEffect(() => {
        if (filterChartName == 'triangle') {
            if ((table == false) && (criticaltityStatus == false)) {
                var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;

                        function addChart(data) {
                            var chart = container.createChild(am4charts.XYChart3D);

                            chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

                            chart.data = data
                            chart.height = 500
                            // chart.colors.list = [
                            //     am4core.color("#0cce73"),
                            //     am4core.color("#f9c80e"),
                            //     am4core.color("#e53c5b"),
                            //     // am4core.color("#fe4365"),
                            // ];


                            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.renderer.grid.template.location = 0;
                            categoryAxis.dataFields.category = "type";
                            categoryAxis.renderer.minGridDistance = 40;

                            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                            var series = chart.series.push(new am4charts.CurvedColumnSeries());
                            series.dataFields.categoryX = "type";
                            series.dataFields.valueY = "count";
                            // series.tooltipText = "{valueY.value}"
                            series.columns.template.strokeOpacity = 0;
                            series.columns.template.tension = 1;
                            series.columns.template.propertyFields.fill = "color";
                            series.columns.template.fillOpacity = 0.75;
                            series.columns.template.maxHeight = 50
                            series.columns.template.tooltipText = `{type}:  ({valueY} out of ${total})`;
                            var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                            labelBullet.label.text = "{valueY}";
                            labelBullet.locationX = 0.5;
                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            // fieldType == "LegalEntity" ? filterData.legalentity :
                            // fieldType == 'Division' ? Number(filterData.division) :
                            //     fieldType == 'Category' ? Number(filterData.category) :
                            //         fieldType == 'Unit' ? Number(filterData.unit) 

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            // label1.text = ['hiii','hello']
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                            // var hoverState = series.columns.template.states.create("hover");
                            // hoverState.properties.fillOpacity = 1;
                            // hoverState.properties.tension = 0.8;

                            // chart.cursor = new am4charts.XYCursor();

                            // Add distinctive colors for each column using adapter
                            // series.columns.template.adapter.add("fill", function (fill, target) {
                            //     return chart.colors.getIndex(target.dataItem.index);
                            // });

                            // chart.scrollbarX = new am4core.Scrollbar();
                            chart.scrollbarY = new am4core.Scrollbar();

                        }
                        addChart([
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count
                                    ,
                                    "color": am4core.color("#0de580")
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Complaince",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8")
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365")
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e")
                                } : {}),
                        ]);
                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart])
    useEffect(() => {
        if (filterChartName == 'cylinder') {
            if ((table == false) && (criticaltityStatus == false)) {
                var total = multiSelectChart.complied_count + multiSelectChart.delayed_compliance_count + multiSelectChart.not_complied_count + multiSelectChart.inprogress_compliance_count
                console.log(total, 'Avvvvvvvvs');
                if (multiSelect == true) {
                    if (multiSelectChart) {
                        console.log('testinggggg');

                        am4core.useTheme(am4themes_animated);
                        // Create a container
                        var container = am4core.create("container", am4core.Container);
                        container.width = am4core.percent(100);
                        container.height = am4core.percent(100);
                        container.layout = "vertical";
                        container.background.fill = am4core.color("#000");
                        container.background.fillOpacity = 0.1;
                        container.background.stroke = am4core.color("#000");
                        container.background.strokeOpacity = 0.2;
                        container.background.strokeWidth = 2;
                        function addChart(data) {

                            // Themes end

                            // Create chart instance
                            var chart = container.createChild(am4charts.XYChart3D);

                            chart.paddingBottom = 30;
                            chart.angle = 35;

                            // Add data
                            chart.data = data
                            chart.height = 500
                            chart.colors.list = [
                                am4core.color("#0cce73"),
                                am4core.color("#f9c80e"),
                                am4core.color("#e53c5b"),
                                // am4core.color("#fe4365"),
                            ];

                            // Create axes
                            // var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                            // categoryAxis.dataFields.category = "country";
                            // categoryAxis.renderer.grid.template.location = 0;
                            // categoryAxis.renderer.minGridDistance = 20;
                            // categoryAxis.renderer.inside = true;
                            // categoryAxis.renderer.grid.template.disabled = true;

                            // let labelTemplate = categoryAxis.renderer.labels.template;
                            // labelTemplate.rotation = -90;
                            // labelTemplate.horizontalCenter = "left";
                            // labelTemplate.verticalCenter = "middle";
                            // labelTemplate.dy = 10; // moves it a bit down;
                            // labelTemplate.inside = false; // this is done to avoid settings which are not suitable when label is rotated

                            // var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                            categoryAxis.renderer.grid.template.location = 0;
                            categoryAxis.dataFields.category = "type";
                            categoryAxis.renderer.minGridDistance = 40;

                            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                            // valueAxis.renderer.grid.template.disabled = true;

                            // Create series
                            var series = chart.series.push(new am4charts.ConeSeries());
                            series.dataFields.valueY = "count";
                            series.dataFields.categoryX = "type";
                            series.columns.template.strokeOpacity = false
                            series.columns.template.propertyFields.fill = "color";
                            // series.columns.template.maxHeight = 50
                            series.columns.template.tooltipText = `{type}:  ({valueY} out of ${total})`;
                            // var columnTemplate = series.columns.template;
                            // // columnTemplate.adapter.add("fill", function (fill, target) {
                            // //     return chart.colors.getIndex(target.dataItem.index);
                            // // })


                            // columnTemplate.adapter.add("stroke", function (stroke, target) {
                            //     return chart.colors.getIndex(target.dataItem.index);
                            // })

                            chart.exporting.menu = new am4core.ExportMenu();
                            chart.exporting.menu.items = [{
                                "label": "...",
                                "menu": [
                                    {
                                        "label": "Image",
                                        "menu": [
                                            { "type": "png", "label": "PNG" },
                                            { "type": "jpg", "label": "JPG" },
                                            { "type": "svg", "label": "SVG" },
                                            { "type": "pdf", "label": "PDF" },
                                            { "type": "gif", "label": "GIF" }
                                        ]
                                    }, {
                                        "label": "Data",
                                        "menu": [
                                            { "type": "csv", "label": "CSV" },
                                            { "type": "xlsx", "label": "XLSX" },
                                        ]
                                    }, {
                                        "label": "Print", "type": "print"
                                    }
                                ]
                            }];
                            chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                            var title = chart.titles.create();
                            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                            title.disabled = false;
                            title.align = 'left'
                            title.marginTop = '2%'
                            title.marginLeft = '2%'

                            // fieldType == "LegalEntity" ? filterData.legalentity :
                            // fieldType == 'Division' ? Number(filterData.division) :
                            //     fieldType == 'Category' ? Number(filterData.category) :
                            //         fieldType == 'Unit' ? Number(filterData.unit) 

                            var title1 = chart.titles.create();
                            title1.text = ((fieldType == "LegalEntity" && go == true) ? `Legal Entity Wise Compliance(${year})` : (
                                (fieldType == "Division" && go == true) ? `Division Wise Compliance(${year})` : (
                                    (fieldType == "Category" && go == true) ? `Category Wise Compliance(${year})` : (
                                        (fieldType == "BusinessGroup" && go == true) ? `Business Group Wise Compliance(${year})` : (
                                            (fieldType == "Unit" && go == true) ? `Unit Wise Compliance(2022)` : `Country Wise Compliance (${year})`
                                        )))
                            ))
                            title1.disabled = false;
                            title1.align = 'center'
                            title1.marginTop = '5%'
                            title1.marginLeft = '2%'
                            title1.fontWeight = 'bold'

                            // <b><p>Country-Domain</p></b>
                            // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                            var label = chart.createChild(am4core.Label);
                            label.text = "Country-Domain";
                            label.fontSize = 14;
                            label.align = "left";
                            label.marginLeft = '2%';
                            label.marginBottom = '2%'
                            label.fontWeight = 'bold'

                            var label1 = chart.createChild(am4core.Label);
                            label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                            // label1.text = ['hiii','hello']
                            label1.fontSize = 14;
                            label1.align = "left";
                            label1.marginLeft = '2%';
                            label1.marginBottom = '5%'

                        }

                        addChart([
                            (multiSelectChart && multiSelectChart.complied_count > 0 ?
                                {
                                    "type": "Complied",
                                    "count": multiSelectChart && multiSelectChart.complied_count
                                    ,
                                    "color": am4core.color("#0de580")
                                } : {})
                            ,
                            (multiSelectChart && multiSelectChart.delayed_compliance_count > 0 ?
                                {
                                    "type": "Delayed Complaince",
                                    "count": multiSelectChart && multiSelectChart.delayed_compliance_count,
                                    "color": am4core.color("#3c78d8")
                                } : {}),
                            (multiSelectChart && multiSelectChart.not_complied_count > 0 ?
                                {
                                    "type": "Not Complied",
                                    "count": multiSelectChart && multiSelectChart.not_complied_count,
                                    "color": am4core.color("#fe4365")
                                } : {}),
                            (multiSelectChart && multiSelectChart.inprogress_compliance_count > 0 ?
                                {
                                    "type": "Inprogress",
                                    "count": multiSelectChart && multiSelectChart.inprogress_compliance_count,
                                    "color": am4core.color("#f9c80e")
                                } : {}),
                        ]);
                    }
                }
            }
        }

    }, [dataValue, table, chartType, criticaltityStatus, chartName, multiSelect, filterChartName, multiSelectChart])

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Dashboard</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Compliance Status Chart</span>
                                </li>
                            </ol>
                        </nav>

                    </div>
                </div>
            </div>
           { table == false ?
            <div className="accordion mb-1" id="accordionExample" style={{marginLeft:'21px',marginRight:'23px'}}>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button bg-white text-primary border-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                        >
                            Compliance Status Counts
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">

                            <div className="row">
                                <div className="col-md-3">

                                    <div className="card w-100 overflow-hidden card-hover">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <h3 className="pt-1 mb-0 h1">
                                                        1260
                                                    </h3>
                                                    <h6 className="text-muted mb-0 fw-normal">Not Complied</h6>
                                                </div>
                                                <div className="ms-auto">
                                                    <span
                                                        className=" btn btn-xl btn-light-danger text-danger btn-circle
                                      d-flex align-items-center justify-content-center" >
                                                        <i data-feather="alert-circle"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="monthly-sales"></div>
                                    </div>

                                </div>
                                <div className="col-md-3">
                                    <div className="card w-100 overflow-hidden card-hover">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <h3 className="pt-1 mb-0 h1">
                                                        21
                                                    </h3>
                                                    <h6 className="text-muted mb-0 fw-normal">Inprogress</h6>
                                                </div>
                                                <div className="ms-auto">
                                                    <span
                                                        className="
                                  btn btn-xl btn-light-info
                                  text-info
                                  btn-circle
                                  d-flex
                                  align-items-center
                                  justify-content-center
                                "
                                                    >
                                                        <i data-feather="check-square"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="monthly-sales1"></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <a href="../main/compliance-approval.html"><div className="card w-100 overflow-hidden card-hover">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <h3 className="pt-1 mb-0 h1">
                                                        56
                                                    </h3>
                                                    <h6 className="text-muted mb-0 fw-normal">Action Pending</h6>
                                                </div>
                                                <div className="ms-auto">
                                                    <span
                                                        className="
                                  btn btn-xl btn-light-warning
                                  text-warning
                                  btn-circle
                                  d-flex
                                  align-items-center
                                  justify-content-center
                                "
                                                    >
                                                        <i data-feather="folder-minus"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="monthly-sales4"></div>
                                    </div></a>
                                </div>
                                <div className="col-md-3">
                                    <a href="../main/compliance-approval.html"><div className="card w-100 overflow-hidden card-hover">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <h3 className="pt-1 mb-0 h1">
                                                        125
                                                    </h3>
                                                    <h6 className="text-muted mb-0 fw-normal fs-2">Approver Pending</h6>
                                                </div>
                                                <div className="ms-auto">
                                                    <span
                                                        className="
                                  btn btn-xl btn-light-secondary
                                  text-secondary
                                  btn-circle
                                  d-flex
                                  align-items-center
                                  justify-content-center
                                "
                                                    >
                                                        <i data-feather="user-minus"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="monthly-sales3"></div>
                                    </div></a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div> : ''}
            {
                table == true ?
                    <div className="container-fluid pt-1">
                        <div className='col-md-12 mt-2'>
                            <div className='row'>
                                {fieldType === '' && go === false ?
                                    <div className="col-md-4">
                                        <p><b>{`Compliance - Country : India, Status : ${compliance}`}</b></p>
                                    </div> :
                                    <div className="col-md-4">
                                        <p><b>{`Compliance-${fieldType === 'BusinessGroup' ? 'Business Group' : fieldType === 'LegalEntity' ? 'Legal Entity' : fieldType} : ${categoryName},Status : ${compliance}`}</b></p>
                                    </div>}
                                <div className="col-md-4">

                                </div>
                                <div className="col-md-4">
                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '70%' }}
                                        icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                            setTable(false)
                                        }}>
                                        Back
                                    </Button>
                                </div>
                            </div>
                        </div><br />
                        <Collapse defaultActiveKey={['0']}>
                            {tableData.length > 0 &&
                                tableData.map((items) => {
                                    let unitName = items.drill_compliances

                                    let keys = Object.keys(unitName);
                                    return (

                                        <Panel header={`${items.le_name}-${items.u_name}`}>
                                            {keys && keys.length > 0 && keys.map((item, i) => {
                                                let values = Object.values(unitName);
                                                console.log(values[i], 'values');
                                                let childDatas = values
                                                let tempArr = []
                                                if (childDatas && childDatas.length > 0) {
                                                    for (let m in childDatas[i]) {
                                                        console.log(m, 'inndexxx');
                                                        console.log(childDatas[i][m].comp_name, 'childDatas');
                                                        tempArr.push({
                                                            'bg_name': items.bg_name,
                                                            'le_name': items.le_name,
                                                            'legal_entity_id': items.legal_entity_id,
                                                            'div_name': items.div_name,
                                                            'comp_name': childDatas && childDatas[i][m].comp_name,
                                                            'criticality': childDatas && childDatas[i][m].criticality,
                                                            'assignee_name': childDatas && childDatas[i][m].assignee_name,
                                                            'descp': childDatas && childDatas[i][m].descp,
                                                            'compfielaw_url': childDatas && childDatas[i][m].compfielaw_url,
                                                            'ageing': childDatas && childDatas[i][m].ageing,
                                                            'index': serialNo

                                                        })
                                                        { <span hidden>{serialNo = serialNo + 1}</span> }
                                                        // childDatas[i] = []
                                                    }
                                                    console.log(tempArr, 'tempArr');
                                                }
                                                childDatas[i] = tempArr


                                                return (
                                                    <>
                                                        <h6 className='m-2 ml-2 mb-2'>{keys[i]}</h6>

                                                        <Table
                                                            size={'small'}
                                                            columns={columnState === true ? columns1 : columns}
                                                            dataSource={childDatas[i]}
                                                            bordered
                                                            scroll={{ x: 100 }}
                                                            pagination={false}
                                                            onChange={handleChange}

                                                        />
                                                    </>
                                                )
                                            })}

                                        </Panel>

                                    )
                                })
                            }




                        </Collapse>
                        {dataCount == showmoreCount ? false : <div className='col-md-12 mt-2'>
                            <div className='row'>
                                <div className='col-md-4'></div>
                                <div className='col-md-4'>
                                    <button className="btn btn-success" style={{ float: "center", marginLeft: "50px" }} onClick={showmore} >Show More</button>
                                </div>
                                <div className='col-md-4'></div>
                            </div>
                        </div>}

                        {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                    className='userprivclass' /> */}
                    </div> : <div className="container-fluid pt-1">
                        <div className="row" >
                            <div className="col-md-12 mt-5">
                                <div className="row">
                                   
                                    {multiSelect == false ?
                                        <>
                                            <div className="col-md-2">
                                                {
                                                    criticaltityStatus == false ? <Select style={{ width: '100%' }} onChange={(data) => {
                                                        setChartType(data)
                                                    }} value={chartType}>
                                                        <Option key='count'>Count</Option>
                                                        <Option key='Percentage'>Percentage</Option>
                                                    </Select> : <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '50%' }} onClick={() => {
                                                        setCriticalityStatus(false)
                                                        const payload = [
                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request":
                                                                    ["GetComplianceStatusChart",
                                                                        {
                                                                            "c_ids": [
                                                                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                                            ],
                                                                            "d_ids": [
                                                                                commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                                            ],
                                                                            "filter_type": "Group",
                                                                            "from_date": null,
                                                                            "to_date": null,
                                                                            "criticality": "",
                                                                            "filter_ids": [
                                                                                1
                                                                            ],
                                                                            "le_ids": [
                                                                                commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                                            ],
                                                                            "country_domains": countryDomain,
                                                                            "chart_year": year
                                                                        }
                                                                    ]
                                                            }
                                                        ]
                                                        getCompliancestatuschart({
                                                            payload: payload,
                                                            paramid: paramid
                                                        })
                                                    }}
                                                        size='default' >
                                                        Compliance Status
                                                    </Button>
                                                }

                                            </div>
                                            <div className="col-md-2">
                                                {criticaltityStatus == false ? <Select style={{ width: '100%' }} onChange={(data) => {
                                                    setChartName(data)
                                                }} value={chartName}>
                                                    <Option key='stacked'>3D Stacked Bar</Option>
                                                    <Option key='pie'>Column With Pie</Option>
                                                    <Option key='chord'>Chord Diagram</Option>
                                                </Select> : false}

                                            </div>
                                            <div className="col-md-2">
                                                {criticaltityStatus == false ?
                                                    <Button type="primary" className='addbutton' style={{ background: "#0096FF" }} onClick={() => {
                                                        setCriticalityStatus(true)
                                                        setChartName("stacked")
                                                        setChartType("count")
                                                    }}
                                                        size='default' >
                                                        Criticality
                                                    </Button> : <Select style={{ width: '100%' }} value={criticality} onChange={(data) => {
                                                        setCriticalityStatus(true)
                                                        setCriticality(data)
                                                    }}>
                                                        <Option key='A'>A</Option>
                                                        <Option key='B'>B</Option>
                                                        <Option key='C'>C</Option>
                                                    </Select>}

                                            </div>
                                            <div className="col-md-6" style={{textAlign:'right'}}>
                                                <Button type="primary" className='addbutton' style={{ background: "#0096FF" }} onClick={() => {
                                                    setOpen(true)
                                                    setFilterOpen(true)
                                                }}
                                                    size='default' >
                                                    Filter
                                                </Button>
                                            </div>
                                        </> :
                                        <>
                                            <div className="col-md-1">

                                            </div>
                                            <div className="col-md-2">

                                            </div>
                                            <div className="col-md-2">
                                                <Select style={{ width: '100%' }}
                                                    onChange={(data) => {
                                                        setfilterChartName(data)
                                                    }}
                                                    value={filterChartName}
                                                >
                                                    <Option key='donut'>3D Donut</Option>
                                                    <Option key='3d'>3D Column</Option>
                                                    <Option key='bar'>3D  Bar Column</Option>
                                                    <Option key='curve'>Curved Column</Option>
                                                    <Option key='triangle'>Triangle Column</Option>
                                                    <Option key='cylinder'>Cylinder Column</Option>
                                                </Select>

                                            </div>
                                            <div className="col-md-2">

                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F", marginLeft: '70%' }}
                                                    icon={<ArrowLeftOutlined />} size='default'
                                                    onClick={() => {
                                                        setmultiSelect(false)
                                                    }}
                                                >
                                                    Back
                                                </Button>


                                            </div>
                                            <div className="col-md-6" style={{textAlign:'right'}}>
                                                <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '50%' }} onClick={() => {
                                                    setOpen(true)
                                                    setFilterOpen(true)
                                                }}
                                                    size='default' >
                                                    Filter
                                                </Button>
                                            </div>
                                        </>}
                                </div>
                            </div>
                            {dataValue && dataValue.length > 0 ?
                                <>
                                    <div className="col-md-12 mt-3">

                                        {/* <div className="card">
                                            <div className='card-body'> */}

                                        <>
                                            {chartName == "stacked" ?
                                                <div id="container" key="container" style={{ width: "100%", height: "610px" }}></div>
                                                :
                                                <div id="container" key="container" style={{ width: "100%", height: "610px" }}></div>}

                                            <div className="col-md-12" style={{ marginTop: '2%' }}>
                                                <div className="row">
                                                    <div className="col-md-6">

                                                    </div>
                                                    <div className="col-md-6" style={{ marginLeft: '70%' }}>
                                                        <label htmlFor=""><b>Time Zone :</b></label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            onChange={(data) => {
                                                                setTimeZone(data)
                                                                setGeneratedOn(true)
                                                                const payload = [
                                                                    authtoken,
                                                                    {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "GetDateTime_ByZone",
                                                                            {
                                                                                "tz_name": data
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                                getTimeZoneData({
                                                                    payload: payload,
                                                                    paramid: paramid
                                                                })

                                                            }}
                                                            placeholder="Enter Time Zone"
                                                            style={{ marginLeft: '5%' }}
                                                        // value={compfie.user_Group}

                                                        >

                                                            {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                return (

                                                                    <Option key={item.tz_name}>

                                                                        {item.tz_name}

                                                                    </Option>

                                                                );

                                                            })}
                                                            {/* );
                                                })} */}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>

                                        </>



                                        {/* </div>
                                        </div> */}
                                    </div>
                                </>
                                : <p style={{ marginTop: '18%', marginLeft: '40%', marginBottom: '18%' }}>No Data to Display </p>}
                            {/* {filterOpen == false && go == false ?
                                <div className="col-md-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "25px" }}
                                                icon={<ArrowLeftOutlined />} size='default'
                                                onClick={() => {
                                                    setYear(year - 1)
                                                }}
                                            >
                                                Previous Year
                                            </Button>
                                        </div>
                                        <div className="col-md-5"></div>
                                        <div className="col-md-3"></div>

                                        <div className="col-md-2">
                                            {year != moment().year() ?
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", }}
                                                    size='default'
                                                    onClick={() => {
                                                        setYear(year + 1)
                                                    }}
                                                >
                                                    Next Year<ArrowRightOutlined />
                                                </Button>
                                                : false}
                                        </div>
                                    </div>
                                </div> :
                                filterOpen == true && go == true && dataValue && dataValue.length == 0 ?
                                    <div>
                                        <p>Date:{moment(filterData.from_date).format('DD-MMM-YYYY')} to {moment(filterData.to_date).format('DD-MMM-YYYY')}</p>
                                    </div> : ''
                            } */}
                            {((dateState === true) && (go === true)) ? '' :
                                <div className="col-md-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "25px" }}
                                                icon={<ArrowLeftOutlined />} size='default'
                                                onClick={() => {
                                                    setYear(year - 1)
                                                }}
                                            >
                                                Previous Year
                                            </Button>
                                        </div>
                                        <div className="col-md-5"></div>
                                        <div className="col-md-3"></div>

                                        <div className="col-md-2">
                                            {year != moment().year() ?
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", }}
                                                    size='default'
                                                    onClick={() => {
                                                        setYear(year + 1)
                                                    }}
                                                >
                                                    Next Year<ArrowRightOutlined />
                                                </Button>
                                                : false}
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>
                    </div>
            }

            <Drawer title="FILTER BY" placement="right" closable={false} onClose={false} visible={open}
                extra={
                    <Space>
                        <CloseCircleOutlined style={{ marginTop: '2%', fontSize: '140%' }} onClick={onClose} />
                    </Space>
                }
            >
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                            <label><b>Country</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            <Select
                                placeholder="Enter Country"
                                name="units"
                                id="units"
                                className='form-control'
                                onChange={(option) => CountryHandelChange(option)}
                                mode="multiple"
                                value={country}
                                maxTagCount='responsive'
                                allowClear={false}
                            >
                                {country_info && country_info.length > 1 ? <>  <Option key="all" value="all" style={{ display: countryselectcheckbox }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: countryunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                {country_info && country_info.length > 0 && country_info.map((item, i) => {
                                    return (
                                        <Option key={item.c_id}>
                                            {item.c_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label><b>Domain</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            <Select
                                placeholder="Enter Domain"
                                name="units"
                                id="units"
                                className='form-control'
                                allowClear={false}
                                onChange={(option) => DomainHandleChane(option)}
                                mode="multiple"
                                value={domain}
                                disabled={country.length > 0 ? false : true}
                                maxTagCount='responsive'
                            >
                                {domainDatas && domainDatas.length > 0 ? <>  <Option key="all" value="all" style={{ display: domainselectcheckbox }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: domainunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}
                                {domainListData && domainListData.length > 0 &&
                                    domainListData.map((finalList_unitlist) => {
                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                            return (
                                                <OptGroup label={finalList_unitlist.label}>
                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                        <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                    ))}
                                                </OptGroup>
                                            )
                                    })}

                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-1">
                            <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                setGroupCheckBox(false)
                                setCheckBox(false)
                            }}></input>
                        </div>
                        <div className="col-md-4">
                            <label><b>Group</b></label>
                        </div>
                        {/* <div className="col-md-2">

                        </div> */}
                        <div className="col-md-1">
                            <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input>
                        </div>
                        <div className="col-md-4">
                            <label><b>Consolidated</b></label>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">

                            <label><b>From Date</b></label>
                            <DatePicker
                                allowClear={() => {
                                    setFilterData({
                                        ...filterData,
                                        from_date: null,
                                    })
                                }}
                                value={filterData.from_date ? moment(filterData.from_date) : ''}
                                // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                // value={consolidatedData.from_date}
                                disabled={domain.length > 0 ? false : true}
                                onChange={(date, dateString) => {
                                    setDateState(true)
                                    setFieldType('Date')
                                    console.log(dateString, 'dateString');
                                    setFilterData({
                                        ...filterData,
                                        from_date: dateString,
                                        from_year: moment(dateString).year(),
                                        from_month: moment(dateString).month(),
                                        from_date_only: moment(dateString).date()
                                    })

                                }}



                            />

                        </div>
                        <div className="col-md-4">

                            <label><b>To Date</b></label>

                            <DatePicker
                                disabled={domain.length > 0 ? false : true}
                                // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                // value={consolidatedData.from_date}
                                // disabled={consolidatedData.domainy == '' ? true : false}
                                // onChange={(date, dateString) => {

                                //     console.log(dateString, 'dateString');
                                //     setConsolidatedData({
                                //         ...consolidatedData,
                                //         from_date: dateString,
                                //         to_date: moment(dateString).add(3, 'months')
                                //     })

                                // }}
                                value={filterData.to_date ? moment(filterData.to_date) : ''}
                                onChange={(date, dateString) => {
                                    setDateState(true)
                                    setFieldType('Date')
                                    if (dateString != undefined) {
                                        setFilterData({
                                            ...filterData,
                                            to_date: dateString,
                                        })
                                    }
                                    else {
                                        setFilterData({
                                            ...filterData,
                                            to_date: null,
                                        })
                                    }
                                }}
                                disabledDate={(current) => {
                                    console.log(current.date(), 'current.date()');
                                    console.log(moment(current).date(), 'currrrrr');
                                    console.log(current.year(), 'coreectCuurentYear');
                                    console.log(current.month(), 'month');
                                    console.log(current.date(), 'date');
                                    console.log(current, 'current');
                                    return moment(current) < moment(filterData.from_date)
                                }}




                            />

                        </div>
                        <div className="col-md-4">
                            <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '5%', marginTop: '22%' }} disabled={dateState === false ? true : false}
                                onClick={() => {
                                    setFilterData({
                                        ...filterData,
                                        from_date: null,
                                        to_date: null
                                    })
                                    setDateState(false)
                                }}
                                size='default' >
                                Clear
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label style={{ color: fieldType == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            <Select
                                placeholder="Enter Business Group"
                                name="units"
                                id="units"
                                allowClear={false}
                                className='form-control'
                                onChange={(option) => BusinessHandelChange(option)}
                                onClick={() => {
                                    setFieldType('BusinessGroup')
                                    let temp = 'Business'
                                    trueState.push(temp)
                                }}
                                mode="multiple"
                                value={business}
                                maxTagCount='responsive'
                                disabled={domain.length > 0 ? false : true}
                            >
                                {businessGroupData && businessGroupData.length > 0 ? <>  <Option key="all" value="all" style={{ display: businessselectcheckbox }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: businessunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}
                                {businessGroupData && businessGroupData.length > 0 &&
                                    businessGroupData.map((item) => {
                                        return (

                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>

                                        )

                                    }

                                    )}
                            </Select>
                            {/* <Select
                                allowClear={true}
                                size="default"
                                placeholder="Enter Business Group"
                                value={filterData.business}
                                onChange={(data) => {
                                    setFilterData({
                                        ...filterData,
                                        business: data
                                    })
                                }
                                }
                                style={{ width: '100%' }}
                            >
                                {field && field.business && field.business.length && field.business.map((item, i) => {
                                    return (
                                        <Option key={item.bg_id}>
                                            {item.bg_name}
                                        </Option>
                                    );
                                })}


                            </Select> */}

                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label style={{ color: fieldType == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            {/* <Select
                                allowClear={true}
                                size="default"
                                placeholder="Enter Legal Entity"
                                value={filterData.legalentity}
                                onChange={(data) => {
                                    setFilterData({
                                        ...filterData,
                                        legalentity: data
                                    })
                                    setFieldType('LegalEntity')
                                    setGo(false)
                                }
                                }
                                style={{ width: '100%' }}
                            >

                                {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                    return (
                                        <Option key={item.le_id}>
                                            {item.le_name}
                                        </Option>
                                    );
                                })}

                            </Select> */}
                            <Select
                                name="units"
                                id="units"
                                className='form-control'
                                allowClear={false}
                                onChange={(option) => EntityHandleChane(option)}
                                onClick={() => {
                                    setFieldType('LegalEntity')
                                    let temp = 'Legal'
                                    trueState.push(temp)
                                }}
                                mode="multiple"
                                value={Entity}
                                maxTagCount='responsive'
                                placeholder="Enter Entity"
                                disabled={domain.length > 0 ? false : true}
                            >
                                {EntityDatas && EntityDatas.length > 1 ? <>  <Option key="all" value="all" style={{ display: Entityselectcheckbox }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: EntityUnselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                {LegalEntityList && LegalEntityList.length > 0 ? LegalEntityList && LegalEntityList.length > 0 &&
                                    LegalEntityList.map((finalList_unitlist) => {
                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                            return (
                                                <OptGroup label={finalList_unitlist.label}>
                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                        <Option key={subUnitData.le_id} value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                    ))}
                                                </OptGroup>
                                            )
                                    }) :
                                    <>  <OptGroup label="Others">
                                    </OptGroup>{EntityDatas && EntityDatas.map((item, i) => {
                                        return (

                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>

                                        );
                                    })}</>

                                }
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label style={{ color: fieldType == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            {/* <Select
                                allowClear={true}
                                size="default"
                                placeholder="Enter Division"
                                value={filterData.division}
                                onChange={(data) => {
                                    setFilterData({
                                        ...filterData,
                                        division: data
                                    })
                                    let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                    console.log(subData, 'subDatasubData');
                                    setCategory(subData)
                                    setFieldType('Division')
                                    setGo(false)
                                }
                                }
                                style={{ width: '100%' }}
                            >
                                {division && division.length && division.map((item, i) => {
                                    return (
                                        <Option key={item.div_id}>
                                            {item.div_name}
                                        </Option>
                                    );
                                })}


                            </Select> */}
                            <Select
                                name="units"
                                id="units"
                                className='form-control'
                                onChange={(option) => DivisionHandleChane(option)}
                                onClick={() => {
                                    setCoulmnState(true)
                                    setFieldType('Division')
                                    let temp = 'Division'
                                    trueState.push(temp)
                                }}
                                mode="multiple"
                                value={divisionDatas}
                                allowClear={false}
                                maxTagCount='responsive'
                                placeholder="Enter Division"
                                disabled={domain.length > 0 ? false : true}
                            >
                                {divisionData && divisionData.length > 0 ? <>  <Option key="all" value="all" style={{ display: divisionselectcheckbox }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: divisionUnselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                {divisionList && divisionList.length > 0 &&
                                    divisionList.map((finalList_unitlist) => {
                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                            return (
                                                <OptGroup label={finalList_unitlist.label}>
                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                        <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                    ))}
                                                </OptGroup>
                                            )
                                    })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label style={{ color: fieldType == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            {/* <Select
                                allowClear={true}
                                size="default"
                                placeholder="Enter Category"
                                value={filterData.category}
                                onChange={(data) => {
                                    setFilterData({
                                        ...filterData,
                                        category: data
                                    })
                                    let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                    setUnit(subData)
                                    setFieldType('Category')
                                    setGo(false)
                                }
                                }
                                style={{ width: '100%' }}
                            >
                                {category && category.length && category.map((item, i) => {
                                    return (
                                        <Option key={item.cat_id}>
                                            {item.cat_name}
                                        </Option>
                                    );
                                })}


                            </Select> */}
                            <Select
                                placeholder="Enter Category"
                                name="units"
                                id="units"
                                className='form-control'
                                onChange={(option) => CategoryHandleChange(option)}
                                onClick={() => {
                                    setFieldType('Category')
                                    setCoulmnState(true)
                                    let temp = 'Category'
                                    trueState.push(temp)
                                }}
                                mode="multiple"
                                allowClear={false}
                                value={categoryData}
                                maxTagCount='responsive'
                                disabled={domain.length > 0 ? false : true}
                            >
                                {categoryDatas && categoryDatas.length > 1 ? <>  <Option key="all" value="all" style={{ display: categoryselectcheckbox }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: categoryunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                {categoryList && categoryList.length > 0 &&
                                    categoryList.map((finalList_unitlist) => {
                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                            return (
                                                <OptGroup label={finalList_unitlist.label}>
                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                        <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                    ))}
                                                </OptGroup>
                                            )
                                    })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            <label style={{ color: fieldType == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
                        </div>
                        <div className="col-md-8">
                            {/* <Select
                                allowClear={true}
                                size="default"
                                placeholder="Enter Unit"
                                value={filterData.unit}
                                onChange={(data) => {
                                    setFilterData({
                                        ...filterData,
                                        unit: data
                                    })
                                    setFieldType('Unit')
                                    setGo(false)
                                }
                                }
                                style={{ width: '100%' }}
                            >
                                {unit && unit.length > 0 && unit.map((item, i) => {
                                    return (
                                        <Option key={item.u_id}>
                                            {item.u_name}
                                        </Option>
                                    );
                                })}


                            </Select> */}

                            <Select
                                name="units"
                                id="units"
                                className='form-control'
                                placeholder="Enter Unit"
                                onChange={(option) => UnitHandleChane(option)}
                                onClick={() => {
                                    setCoulmnState(true)
                                    setFieldType('Unit')
                                    let temp = 'Unit'
                                    trueState.push(temp)
                                }}
                                mode="multiple"
                                value={unitData}
                                allowClear={false}
                                maxTagCount='responsive'
                                disabled={domain.length > 0 ? false : true}
                            >
                                {unitDatas && unitDatas.length > 1 ? <>  <Option key="all" value="all" style={{ display: unitselectcheckbox }}>---SELECT ALL---</Option>
                                    <Option key="unselect" value="unselect" style={{ display: unitUnselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                {unitList && unitList.length > 0 &&
                                    unitList.map((finalList_unitlist) => {
                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                            return (
                                                <OptGroup label={finalList_unitlist.label}>
                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                        <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                    ))}
                                                </OptGroup>
                                            )
                                    })}
                            </Select>
                        </div>
                    </div>
                </div>
                <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                    onClick={filterChange}
                    size='default' >
                    Go
                </Button>
                <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                    onClick={() => {
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request":
                                    ["GetComplianceStatusChart",
                                        {
                                            "c_ids": [
                                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                            ],
                                            "d_ids": domainIds,
                                            "filter_type": "Group",
                                            "from_date": null,
                                            "to_date": null,
                                            "criticality": "",
                                            "filter_ids": [
                                                1
                                            ],
                                            "le_ids": entityData,
                                            "country_domains": countryDomain,
                                            "chart_year": year
                                        }
                                    ]
                            }
                        ]
                        getCompliancestatuschart({
                            payload: payload,
                            paramid: paramid
                        })
                        setOpen(false)
                        setFilterOpen(false)
                        setGo(false)
                        setCriticalityStatus(false)
                        setChartType("count")
                        setFieldType("")
                        setFilterData({
                            ...filterData,
                            country: '',
                            domain: '',
                            legalentity: '',
                            division: '',
                            unit: '',
                            business: '',
                            category: '',
                            unit: ''
                        })
                        setCountry([])
                        setDomain([])
                        setBusiness([])
                        setEntity([])
                        setdivisionDatas([])
                        setcategoryData([])
                        setunitData([])
                        setDateState(false)
                        setTempState({
                            ...tempState,
                            bg: [],
                            le: [],
                            div: [],
                            cat: [],
                            unit: []
                        })

                    }}
                    size='default' >
                    Reset
                </Button>
            </Drawer>
        </div>

    )
}


export default connect(mapStateToProps, {
    getCompliancestatuschart,
    getCriticalityFilter,
    getCommonData,
    getCompliancestatusTable,
    getTimeZoneData,
    getComplianceTrendShowMoreTableData
})(ComplianceStatusChart);