import {COMPARATIVE_TRENT_CHART,COMMON_DATA,COMPLIANCE_TREND_TABLE_DATA,TIME_ZONE_DATA } from './../../types/index'
const initialState = {
    comparativeTrendChart: {
       chartData:[],
       time:0,
       Data:[],
       tableData:[],
       timeZoneData:[]
       
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case COMPARATIVE_TRENT_CHART: {
            console.log(payload,"payload4444");
            return {
                ...state,
                comparativeTrendChart: {
                    ...state.comparativeTrendChart,
                    chartData: payload.Comparative_data,
                    time:payload.current_date
                },
            };
        }
        case COMMON_DATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                comparativeTrendChart: {
                    ...state.comparativeTrendChart,
                    Data: payload,
                },
            };
        }
        case TIME_ZONE_DATA:{
            return {
                ...state,
                comparativeTrendChart: {
                    ...state.comparativeTrendChart,
                    timeZoneData: payload,
                },
            };
        }
       
        default:
            return state;
    }
}
