import HTTP from '../../Libs/http';
import { LOGINSUCCESS, NOTIFICATIONS, ESCALATION, REMAINDER, MESSAGE, NOTIFICATIONSHOWALL, NOTIFICATIONMODAL, LOGINVENDOR, LOGINLITIGATION, LOGINCOMPFIEPRODUCT } from '../types/index'
import { TOGGLE_COMMON_LOADER } from "../types/index";

const initialState = {
    token: null,
    isAuthenticated: false,
    commonLoading: false,
    notifications: [],
    escalations: [],
    remainder: [],
    message: [],
    notificationshowall: [],
    notificationmodal: [],
    loginvendor: [],
    loginlitigation: [],
    logincompfieproduct: []
}
const getUnique = (arr, index) => {
    const unique = arr
        .map(e => e[index])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e]).map(e => arr[e]);
    return unique;
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGINSUCCESS:
            {
                const token = payload.session_token;
                let country_info_array = [];
                let arr = []
                if (payload.country_info != '') {
                    country_info_array = payload.country_info
                } else {
                    payload.entity_info && payload.entity_info.length > 0 && payload.entity_info.map(function (user) {
                        if ((arr.indexOf(user.c_name) < 0))
                            arr.push({ 'c_name': user.c_name, 'c_id': user.c_id });
                    });
                    country_info_array = getUnique(arr, 'c_id')
                }
                console.log(payload.menu.Static[0].form_key, 'country_info_array')
                console.log(payload, 'LOGIN PAYLOAD VALUESSSSSSSSSSSSSSSSs')

                const arrayvalue = {
                    'entity_info': payload.entity_info,
                    'country_info': country_info_array,
                    'login_response': payload
                }
                localStorage.setItem('sessionValue', JSON.stringify(arrayvalue))
                localStorage.setItem('isVendor', false)
                localStorage.setItem('currentTheme', payload.theme)
                if (payload.theme == 'theme_one') {                    
                    localStorage.setItem('currentSkin', 'skin6')
                    localStorage.setItem('text-font', 'text-dark')
                    localStorage.setItem('iconColor', 'iconColorGrey')
                }
                else if (payload.theme == 'theme_two') {
                    localStorage.setItem('currentSkin', 'skin7')
                    localStorage.setItem('text-font', 'text-white')
                    localStorage.setItem('iconColor', 'iconColorWhite')
                }
                else if (payload.theme == 'theme_three') {
                    localStorage.setItem('currentSkin', 'skin8')
                    localStorage.setItem('text-font', 'text-white')
                    localStorage.setItem('iconColor', 'iconColorWhite')
                } else if (payload.theme == 'theme_four') {
                    localStorage.setItem('currentSkin', 'skin9')
                    localStorage.setItem('text-font', 'text-dark')
                    localStorage.setItem('iconColor', 'iconColorGrey')
                } else {
                    localStorage.setItem('currentSkin', 'skin6')
                    localStorage.setItem('text-font', 'text-dark')
                    localStorage.setItem('iconColor', 'iconColorGrey')
                }
                // localStorage.setItem('currentSkin', 'skin6')
                // localStorage.setItem('text-font', 'text-dark')
                localStorage.setItem('formkey', payload.menu.Static[0].form_key)
                // localStorage.setItem('iconColor', 'iconColorGrey')
                const entity_info = payload.entity_info;
                const country_info = payload.country_info;
                HTTP.setAuthToken(token)
                return {
                    ...state,
                    token: token,
                    isAuthenticated: true,
                    entity_info: entity_info,
                    country_info: country_info
                };
            }
        case TOGGLE_COMMON_LOADER:
            {
                return {
                    ...state,
                    commonLoading: !state.commonLoading
                }
            }
        case NOTIFICATIONS:
            {
                return {
                    ...state,
                    notifications: payload,
                };
            }
        case ESCALATION:
            {
                return {
                    ...state,
                    escalations: payload,
                };
            }
        case REMAINDER:
            {
                return {
                    ...state,
                    remainder: payload,
                };
            }
        case MESSAGE:
            {
                return {
                    ...state,
                    message: payload,
                };
            }
        case NOTIFICATIONSHOWALL:
            {
                return {
                    ...state,
                    notificationshowall: payload,
                };
            }
        case NOTIFICATIONMODAL:
            {
                return {
                    ...state,
                    notificationmodal: payload
                }
            }
        case LOGINVENDOR:
            {

                const menu = {
                    'menu': payload[1][0]
                }
                let prevData = JSON.parse(localStorage.getItem('sessionValue')).login_response;
                console.log(prevData, "prevDataprevData");
                prevData.menu = payload[1][0]
                const arrayvalue = {
                    'country_info': JSON.parse(localStorage.getItem('sessionValue')).country_info,
                    'entity_info': payload[1][1],
                    'login_response': prevData
                }
                localStorage.setItem('sessionValue', JSON.stringify(arrayvalue))
                localStorage.setItem("isVendor", true)
                localStorage.setItem('formkey', prevData.menu.Static[0].form_key)
                return {
                    ...state,
                    loginvendor: payload
                }
            }
        case LOGINCOMPFIEPRODUCT:
            {

                const menu = {
                    'menu': payload[1][0]
                }
                let prevData = JSON.parse(localStorage.getItem('sessionValue')).login_response;
                prevData.menu = payload[1][0]
                const arrayvalue = {
                    'country_info': JSON.parse(localStorage.getItem('sessionValue')).country_info,
                    'entity_info': payload[1][1],
                    'login_response': prevData
                }
                localStorage.setItem('sessionValue', JSON.stringify(arrayvalue))
                localStorage.setItem("isVendor", false)

                return {
                    ...state,
                    logincompfieproduct: payload
                }
            }

        case LOGINLITIGATION:
            {

                const menu = {
                    'menu': payload[1][0]
                }
                let prevData = JSON.parse(localStorage.getItem('sessionValue')).login_response;
                prevData.menu = payload[1][0]
                const arrayvalue = {
                    'country_info': JSON.parse(localStorage.getItem('sessionValue')).country_info,
                    'entity_info': payload[1][1],
                    'login_response': prevData
                }
                localStorage.setItem('sessionValue', JSON.stringify(arrayvalue))
                localStorage.setItem("isVendor", true)
                return {
                    ...state,
                    loginlitigation: payload
                }
            }
        default:
            return state;
    }
}