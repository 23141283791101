import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Modal, Input, Table, Tooltip, Descriptions } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import DataTable from "react-data-table-component";
import { notificationshowall, updatenotification, updatenotificationnew } from '../../Store/Action/Login';
import { Toaster } from '../../Libs/Toaster';
import { PlusSquareTwoTone, LikeOutlined, DislikeOutlined, PoweroffOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';


const mapStateToProps = (state) => ({
    lists: state.Login.notificationshowall.reminders,
    modal: state.Login.notificationmodal.notification_details

})
const Reminders = ({
    notificationshowall,
    lists,
    modal,
    updatenotificationnew
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [modalVisible, setModaVisible] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const { Search } = Input;
    const header = paramid.split('/');
    var breadcrumb = '';
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const [showModal, setshowModal] = useState(false);
    var type = '';
    if (header[1] == 'reminders') {
        breadcrumb = 'Remainders';
        type = 2;
    } else if (header[1] == 'notifications') {
        breadcrumb = 'Notifications';
    } else if (header[1] == 'messages') {
        breadcrumb = 'Messages';
        type = 4;
    } else if (header[1] == 'escalations') {
        breadcrumb = 'Escalations';
        type = 3;
    }

    const [modalData, setmodalData] = useState({
        act_name:'',
        unit: '',
        compliance: '',
        due_date: '',
        delayed_by: '',
        assignee: '',
        concurrence: '',
        approval: '',
    })


    useEffect(() => {
        if (isAuth && modal) {
            // console.log(modal[0].act_name,'modal')
          
            setmodalData({
                ...modalData,
                act_name: modal[0].act_name,
                unit: modal[0].unit,
                compliance: modal[0].compliance_name,
                due_date: modal[0].due_date,
                delayed_by: modal[0].delayed_by,
                assignee: modal[0].assignee_name,
                concurrence: modal[0].concurrer_name,
                approval: modal[0].approver_name

            })
        }
    }, [isAuth, modal])

    const handleCancel = () => {
        setshowModal(false)
        setmodalData(false);
        let payload;

            if (legalid != 'null') {
                payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetNotifications",
                            {
                                "le_ids": [
                                    legalid
                                ],
                                "notification_type": 2,
                                "start_count": 0,
                                "end_count": 50
                            }
                        ]
                    }
                ]
            } else {
                let temp = [];
                for (let i = 0; i < callername.entity_info.length; i++) {
                    temp.push(callername.entity_info[i].le_id);
                }
                payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetNotifications",
                            {
                                "le_ids": temp,
                                "notification_type": 2,
                                "start_count": 0,
                                "end_count": 50
                            }
                        ]
                    }
                ]
            }
        


        notificationshowall({
            payload: payload,
            caller: paramid
        })
    }

    const downloadreport = (e, record) => {
        window.open('https://13.234.100.64/' + e);
        markread(record)

    }

    const markread = (record) => {
        setshowModal(true);
        let notifypayload;
        if (authtoken && legalid && legalid != "null") {
            notifypayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UpdateNotificationStatus",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_id": record.notification_id,
                            "has_read": true,
                            "extra_details": record.extra_details
                        }
                    ]
                }
            ]

        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            notifypayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UpdateNotificationStatus",
                        {
                            "le_ids": temp,
                            "notification_id": record.notification_id,
                            "has_read": true,
                            "extra_details": record.extra_details
                        }
                    ]
                }
            ]
        }
        updatenotificationnew({
            payload: notifypayload,
            paramid: paramid
        })

    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_on',
            key: 'created_on',
            width: '20px'

        },
        {
            title: 'Title',
            dataIndex: 'notification_text',
            key: 'notification_text',
            width: '300px',
            render: (text, record) => {
                return <span className='pointer' onClick={(e) => { markread(record) }}>{record.notification_text}</span>

            }

        },
    ];

    useEffect(() => {
        if (isAuth) {
            let payload;
                if (legalid != 'null') {
                    payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetNotifications",
                                {
                                    "le_ids": [
                                        legalid
                                    ],
                                    "notification_type": 2,
                                    "start_count": 0,
                                    "end_count": 50
                                }
                            ]
                        }
                    ]
                } else {
                    let temp = [];
                    for (let i = 0; i < callername.entity_info.length; i++) {
                        temp.push(callername.entity_info[i].le_id);
                    }
                    payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetNotifications",
                                {
                                    "le_ids": temp,
                                    "notification_type": 2,
                                    "start_count": 0,
                                    "end_count": 50
                                }
                            ]
                        }
                    ]
                }
            


            notificationshowall({
                payload: payload,
                caller: paramid
            })

        }
    }, [isAuth])

    const homescreen = ()=>{
        window.location.href= '/dashboard'
    }

    return (
        <Fragment>
            <div>

                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <Link>{breadcrumb}</Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">

                                    <div className="card-body" style={{ padding: '0px' }}>

                                        {/* <DataTable
                                                columns={columns}
                                                data={filteredItems}
                                                fixedHeader={dataTableProperties.fixedHeader}
                                                pagination
                                                subHeader
                                                subHeaderComponent={subHeaderComponent}
                                            /> */}
                                        <Table
                                            className='userprivclass'
                                            columns={columns}
                                            dataSource={lists}
                                            bordered
                                            pagination={{
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
                <Modal title="Other Details" visible={showModal} footer={false} onCancel={handleCancel}>
                    <Descriptions layout="vertical" className='labelclass'>
                        <Descriptions.Item label="Act">{modalData.act_name}</Descriptions.Item>
                        <Descriptions.Item label="Unit">{modalData.unit}</Descriptions.Item>
                        <Descriptions.Item label="Compliance">{modalData.compliance}</Descriptions.Item>
                        <Descriptions.Item label="Due Date">{modalData.due_date}</Descriptions.Item>
                        <Descriptions.Item label="Delayed By">{modalData.delayed_by}</Descriptions.Item>
                        <Descriptions.Item label="Assignee">
                            {modalData.assignee}
                        </Descriptions.Item>
                        <Descriptions.Item label="Concurrence">
                            {modalData.concurrence}
                        </Descriptions.Item>
                        <Descriptions.Item label="Approval">
                            {modalData.approval}
                        </Descriptions.Item>
                    </Descriptions>
                </Modal>

            </div>

        </Fragment>
    )
}
export default connect(mapStateToProps, {
    notificationshowall,
    updatenotificationnew
})(Reminders);