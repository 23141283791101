import { MONTHLYCOMPLIANCEFILTER, MONTHLYCOMPLIANCETABLEDATA } from './../../types/index'
const initialState = {
    unitlistreports: {
        unitlist: [],
        tabledata: []
    }
}
export default function(state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
        case MONTHLYCOMPLIANCEFILTER:
            {
                return {
                    ...state,
                    unitlistreports: {
                        ...state.unitlistreports,
                        unitlist: payload,
                    },
                };

            }
        case MONTHLYCOMPLIANCETABLEDATA:
            {
                return {
                    ...state,
                    unitlistreports: {
                        ...state.unitlistreports,
                        tabledata: payload,
                    },
                };

            }
        default:
            return state;
    }
}