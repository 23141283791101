import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import { Collapse, Select, Input, DatePicker, Card, Modal, Button, Table, Progress, Pagination, Tooltip } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { UnitWiseReportFilters, UnitWiseReport, UnitExportData } from './../../../Store/Action/Report/UnitReport';

const mapStateToProps = (state) => ({
    unitreports: state.UnitReport.unitwisereport,
})

const UnitWiseReports = ({
    UnitWiseReportFilters,
    UnitWiseReport,
    UnitExportData,
    unitreports: { unitreport, unitwisereports }
}) => {
    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid').replaceAll('"', ''));
    const paramid = location.pathname;
    const _ = require("lodash");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [categoryList, setCategoryList] = useState([])
    const [act, setAct] = useState([])
    const [frequency, setFrequency] = useState([])
    const [Compliancetask, setCompliancetask] = useState([])
    const [usertype, setUsertype] = useState([])
    const [division, setDivision] = useState([])
    const [domain, setDomain] = useState([])
    const [unitcode, setUnitcode] = useState([])
    const [users, setUsers] = useState([])
    const [unit, setUnit] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [titledata, Settitledata] = useState([])
    let [count, setcount] = useState(0)
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 7, 8, 9, 10, 11, 30, 32])
    const [statkey, setStatKey] = useState("0")
    const [key, setKey] = useState("1")
    const [legalstate, Setlegalstate] = useState(false)
    const [unitdata, setUnitData] = useState({
        country: '',
        countryName: '',
        domain: 0,
        domain_name: '',
        user: 0,
        user_name: '',
        // legel_entity: entityid,
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        act: 0,
        act_name: '',
        from_date: "",
        division: 0,
        division_name: '',
        compliance_task: null,
        to_date: "",
        category: 0,
        category_name: '',
        compliance_frequency: 0,
        compliance_frequency_name: 'All',
        compliance_task_status: '',
        compliance_task_status_name: 'All',
        unit: '',
        unit_name: '',
        user_type: '',
        user_type_name: 'All'

        // task_category: "",
        // task_sub_category: "",
    })
    const validator = useRef(new SimpleReactValidator());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [disableButton, setDisableButton] = useState(false)
    const { Panel } = Collapse;
    const { Option } = Select;

    const columns = [
        {
            title: "Compliance Task",
            dataIndex: false,
            key: false,
            width: '200px',
            ellipsis: true,
            render: (record) => {
                return (
                    <>
                        <Tooltip title={record.compliance_description}>
                            <ExclamationCircleTwoTone />
                        </Tooltip> &nbsp;
                        {record.compliance_task}
                    </>
                )
            },
        },
        {
            title: "Frequency",
            dataIndex: 'frequency_name',
            width: '100px',
            ellipsis: true
        },
        {
            title: "Due Date",
            dataIndex: 'due_date',
            width: '120px',
            ellipsis: true
        },
        {
            title: "Compliance Task Status",
            dataIndex: 'task_status',
            width: '120px',
            // ellipsis: true
        },
        {
            title: "User Name",
            dataIndex: 'assignee_name',
            width: '170px',
            ellipsis: true
        },
        {
            title: "Activity Status",
            dataIndex: 'activity_status',
            width: '120px',
            ellipsis: true
        },
        {
            title: "Activity Date",
            dataIndex: 'activity_date',
            width: '120px',
            ellipsis: true
        },
        {
            title: "Uploaded Document",
            // dataIndex: 'activity_date',
            width: '150px',
            ellipsis: true
        },
        {
            title: "Task Completion Date/Document issued Date",
            // dataIndex: 'activity_date',
            width: '150px',
            ellipsis: true
        },
    ]

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                if (entityid != "null") {
                    UnitWiseReportFilters({
                        payload: [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetUnitWiseReportFilters",
                                    {
                                        "country_id": Number(unitdata.country),
                                        "legal_entity_id": entityid
                                    }
                                ]
                            }
                        ],
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }
    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        if (unitreport) {
            // setaskCategory(filterValue && filterValue.Data && filterValue.Data.task_category_list)
            setAct(unitreport && unitreport.act_legal_entity)
            setCategoryList(unitreport && unitreport.categories_list)
            setFrequency(unitreport && unitreport.compliance_frequency_list)
            setCompliancetask(unitreport && unitreport.compliance_task_status)
            setUsertype(unitreport && unitreport.compliance_user_type)
            setUsers(unitreport && unitreport.compliance_users)
            setDivision(unitreport && unitreport.divisions_list)
            setDomain(unitreport && unitreport.domains)
            setUnitcode(unitreport && unitreport.unit_code_label_list)
            setUnit(unitreport && unitreport.unit_legal_entity)
        }
    }, [unitreport])

    useEffect(() => {
        if (unitwisereports && unitwisereports.unit_compliances && unitwisereports.unit_compliances.length > 0) {
            var array = unitwisereports && unitwisereports.unit_compliances.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }

    }, [unitwisereports && unitwisereports.unit_compliances, pageSize, current])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (unitwisereports && unitwisereports.unit_compliances && unitwisereports.unit_compliances.length > 0) {
            let uniqueObjArray = [...new Map(unitwisereports && unitwisereports.unit_compliances && unitwisereports.unit_compliances.length && unitwisereports.unit_compliances.map((item) => [item["domain_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(unitwisereports && unitwisereports.unit_compliances && unitwisereports.unit_compliances.length && unitwisereports.unit_compliances, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])
        }
    }, [unitwisereports && unitwisereports.unit_compliances])

    useEffect(() => {
        if (filteredArray.length) {
            // let temp = []
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
            // for (let i in filteredArray) {
            //     temp = filteredArray[i]
            // }
            // setfilteredArrayState([...filteredArrayState, ...temp])
        }
    }, [filteredArray])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (paginationArray && paginationArray.length) {
            const ab = paginationArray && paginationArray.length && paginationArray.map(function (item) {
                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.length && paginationArray.map((child, childIndex) => {
                        if (data === child.statutory_mapping) {
                            entityArray = {
                                'activity_date': child.activity_date,
                                'activity_status': child.activity_status,
                                'assignee_name': child.assignee_name,
                                'category_name': child.category_name,
                                'completion_date': child.completion_date,
                                'compliance_activity_id': child.compliance_activity_id,
                                'compliance_description': child.compliance_description,
                                'compliance_history_id': child.compliance_history_id,
                                'compliance_id': child.compliance_id,
                                'compliance_task': child.compliance_task,
                                'country_id': child.country_id,
                                'division_name': child.division_name,
                                'doc_ref_num': child.doc_ref_num,
                                'document_name': child.document_name,
                                'domain_id': child.domain_id,
                                'domain_name': child.domain_name,
                                'due_date': child.due_date,
                                'extend_due_date': child.extend_due_date,
                                'frequency_name': child.frequency_name,
                                'history_count': child.history_count,
                                'interim_count': child.interim_count,
                                'legal_entity_id': child.legal_entity_id,
                                'logo_url': child.logo_url,
                                'remarks_count': child.remarks_count,
                                'start_date': child.start_date,
                                'statutory_mapping': child.statutory_mapping,
                                'task_status': child.task_status,
                                'tz_name': child.tz_name,
                                'unit_address': child.unit_address,
                                'unit_id': child.unit_id,
                                'unit_name': child.unit_name,
                                'url': child.url,
                                // 'collapseId': i,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
        }
    }, [paginationArray && paginationArray.length && paginationArray])

    useEffect(() => {
        if (entityid != null) {
            setUnitData({
                ...unitdata,
                country: sessionArr[0].c_id
            })
        }
    }, [entityid != null])

    // useEffect(() => {
    //     // setTempeditdata(editdata)
    //     let array = [];
    //     let finalarray = [];
    //     // let datavalue = [];
    //     if (unitwisereports.unit_compliances) {
    //         const ab = unitwisereports.unit_compliances.map(function (item) {
    //             if ((array.indexOf(item.statutory_mapping) < 0))
    //                 array.push(item.statutory_mapping)
    //         })

    //         array.map((data, i) => {
    //             let temp = [];
    //             let entityArray = [];
    //             let datavalue = [];
    //             if (data) {
    //                 unitwisereports.unit_compliances.map((child, childIndex) => {
    //                     if (data === child.statutory_mapping) {
    //                         entityArray = {
    //                             'activity_date': child.activity_date,
    //                             'activity_status': child.activity_status,
    //                             'assignee_name': child.assignee_name,
    //                             'category_name': child.category_name,
    //                             'completion_date': child.completion_date,
    //                             'compliance_activity_id': child.compliance_activity_id,
    //                             'compliance_description': child.compliance_description,
    //                             'compliance_history_id': child.compliance_history_id,
    //                             'compliance_id': child.compliance_id,
    //                             'compliance_task': child.compliance_task,
    //                             'country_id': child.country_id,
    //                             'division_name': child.division_name,
    //                             'doc_ref_num': child.doc_ref_num,
    //                             'document_name': child.document_name,
    //                             'domain_id': child.domain_id,
    //                             'domain_name': child.domain_name,
    //                             'due_date': child.due_date,
    //                             'extend_due_date': child.extend_due_date,
    //                             'frequency_name': child.frequency_name,
    //                             'history_count': child.history_count,
    //                             'interim_count': child.interim_count,
    //                             'legal_entity_id': child.legal_entity_id,
    //                             'logo_url': child.logo_url,
    //                             'remarks_count': child.remarks_count,
    //                             'start_date': child.start_date,
    //                             'statutory_mapping': child.statutory_mapping,
    //                             'task_status': child.task_status,
    //                             'tz_name': child.tz_name,
    //                             'unit_address': child.unit_address,
    //                             'unit_id': child.unit_id,
    //                             'unit_name': child.unit_name,
    //                             'url': child.url,
    //                             // 'collapseId': i,
    //                             'childId': childIndex,
    //                             'parentId': i
    //                         }
    //                         temp.push(entityArray)
    //                     }
    //                 })
    //             }

    //             datavalue = {
    //                 index: i,
    //                 parent: data,
    //                 child: temp
    //             }
    //             finalarray.push(datavalue)
    //         })
    //         Settitledata(finalarray)
    //     }

    // }, [unitwisereports.unit_compliances])

    useEffect(() => {
        if (unitdata.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [unitdata.legal_entity_name])

    const onshow = () => {
        setfilteredArrayState([])
        Settitledata([])
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setStatKey(1)
            setKey(0)
            UnitWiseReport({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetUnitWiseReport",
                            {
                                "country_id": Number(unitdata.country),
                                "legal_entity_id": entityid,
                                "division_id": Number(unitdata.division),
                                "category_id": Number(unitdata.category),
                                "unit_id": Number(unitdata.unit),
                                "d_id_optional": Number(unitdata.domain),
                                "parent_id": Number(unitdata.act),
                                "compliance_task": unitdata.compliance_task,
                                "frequency_id": Number(unitdata.compliance_frequency),
                                "user_type": unitdata.user_type_name,
                                "user_id": Number(unitdata.user),
                                "due_from_date": moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY'),
                                "due_to_date": moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY'),
                                "task_status": unitdata.compliance_task_status_name,
                                "csv": false,
                                "from_count": 0,
                                "page_count": 0,
                                "list_check": 0
                            }
                        ]
                    }
                ],
                paramid: paramid
            })

        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 7, 8, 9, 10, 11, 30, 33])
        }
    }

    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setAddFormSubmit(true)
        setTableShow(true)
        setStatKey(1)
        setKey(0)
        UnitExportData({
            payload: [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitWiseReport",
                        {
                            "country_id": Number(unitdata.country),
                            "legal_entity_id": entityid,
                            "division_id": Number(unitdata.division),
                            "category_id": Number(unitdata.category),
                            "unit_id": Number(unitdata.unit),
                            "d_id_optional": Number(unitdata.domain),
                            "parent_id": Number(unitdata.act),
                            "compliance_task": unitdata.compliance_task,
                            "frequency_id": Number(unitdata.compliance_frequency),
                            "user_type": unitdata.user_type,
                            "user_id": Number(unitdata.user),
                            "due_from_date": moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY'),
                            "due_to_date": moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY'),
                            "task_status": unitdata.compliance_task_status,
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ],
            paramid: paramid
        })
    };

    useEffect(() => {
        if (pageState === true) {
            setcount(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const disablecondition = (e) => {
        var currentToDate = moment(unitdata.from_date).add(3, 'months')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }

    const item = [
        {
            compliance: "manoj"
        }
    ]
    const onChange = (key) => {
        // console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }


    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-0 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Unit Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <Modal title="Select Fields" visible={isModalOpen} onOk={handleOk} footer={null} onCancel={handleCancel} width={100}>
                <div className='row'>
                    <div className='col-lg-3'><input id="select_all" width="100%" type="checkbox" autocomplete="off"
                        checked={checkboxValue.length === 32 ? true : false}
                        onClick={(e) => {
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                        }} /> Select All</div>

                    <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" disabled="disabled" value='1' checked onChange={(e) => (e) => checkBoxOnChange(e)} /> Unit Code</div>
                    <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" disabled="disabled" value='2' checked onChange={(e) => checkBoxOnChange(e)} /> Unit Name</div>
                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='3' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 1</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2 </div>
                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 3 </div>
                    <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 4 </div>
                    <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" disabled="disabled" value='7' onChange={(e) => checkBoxOnChange(e)} /> Location </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" disabled="disabled" value='8' checked onChange={(e) => checkBoxOnChange(e)} /> Domain Name </div>
                    <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='9' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /> Act / Rules  </div>
                    <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='10' disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Compliance Task</div>
                    <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='11' disabled="disabled" onChange={(e) => checkBoxOnChange(e)} />Frequency  </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Periodicity </div>
                    <div className='col-lg-3'><input id="column_6" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Division </div>
                    <div className='col-lg-3'><input id="column_11" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Category </div>
                    <div className='col-lg-3'><input id="column_12" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Assigned by </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_13" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assigned To </div>
                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date </div>
                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assignee </div>
                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Completed on </div>

                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Concurrer </div>
                    <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurred on  </div>
                    <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} />Approver </div>
                    <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} />  Approved on  </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} />Start Date </div>
                    <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} />  Due Date </div>
                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Extend Due Date </div>
                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date / Document Issued Date </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_25" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> Month </div>
                    <div className='col-lg-3'><input id="column_26" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} />Validity Date</div>
                    <div className='col-lg-3'><input id="column_27" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} />  Duration </div>
                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='31' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_30" name='chk' type="checkbox" value='32' onChange={(e) => checkBoxOnChange(e)} /> Remarks </div>
                </div>
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '40%' }}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                </div>
            </Modal>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div id='only-restricted' className="card-body" style={{ position: 'relative', padding: "15px 5px" }}>

                            <Collapse style={{ backgroundColor: '#5daae3' }} className='report-collapse' accordion defaultActiveKey={["1"]} onChange={onChange} >
                                <Panel header=" Unit Report" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                placeholder='Enter Country'
                                                                allowClear={false}
                                                                size="default"
                                                                onChange={(value, data) => {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        country: value,
                                                                        countryName: data.children,
                                                                        domain: 0,
                                                                        domain_name: '',
                                                                        user: 0,
                                                                        user_name: '',
                                                                        // legel_entity: entityid,
                                                                        legal_entity_id: "",
                                                                        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                        act: 0,
                                                                        act_name: '',
                                                                        from_date: "",
                                                                        division: 0,
                                                                        division_name: '',
                                                                        compliance_task: null,
                                                                        to_date: "",
                                                                        category: 0,
                                                                        category_name: '',
                                                                        compliance_frequency: 0,
                                                                        compliance_frequency_name: 'All',
                                                                        compliance_task_status: '',
                                                                        compliance_task_status_name: 'All',
                                                                        unit: '',
                                                                        unit_name: '',
                                                                        user_type: '',
                                                                        user_type_name: 'All'
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '2px' }}

                                                                value={unitdata.countryName || undefined}
                                                            >
                                                                {country_info && country_info.length && country_info.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'countryName',
                                                                unitdata.countryName,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{sessionArr[0].c_name}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Domain:</label>
                                                <Select
                                                    value={unitdata.domain_name || undefined}
                                                    disabled={unitdata.unit != "" ? false : true}
                                                    onChange={(value, data) => {
                                                        let act = _.filter(unitreport.act_legal_entity, { domain_id: Number(value) })
                                                        setAct(act)
                                                        setUnitData({
                                                            ...unitdata,
                                                            domain: value,
                                                            domain_name: data.children,

                                                        })
                                                    }}
                                                    placeholder="Enter Domain"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {domain && domain.length > 0 && domain.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>User : </label>
                                                <Select
                                                    value={unitdata.user_name || undefined}
                                                    disabled={unitdata.unit != "" ? false : true}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            user: value,
                                                            user_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter User"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {users && users.length > 0 && users.map((item, i) => {
                                                        return (
                                                            <Option key={item.completed_by}>
                                                                {item.assignee_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Legal Entity :  <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(data, value) => {
                                                                Setlegalstate(true)
                                                                setCurrentEntity(data)
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    legal_entity_id: data,
                                                                    legal_entity_name: value.children,
                                                                    domain: 0,
                                                                    domain_name: '',
                                                                    user: 0,
                                                                    user_name: '',
                                                                    // legel_entity: entityid,
                                                                    act: 0,
                                                                    act_name: '',
                                                                    from_date: "",
                                                                    division: 0,
                                                                    division_name: '',
                                                                    compliance_task: null,
                                                                    to_date: "",
                                                                    category: 0,
                                                                    category_name: '',
                                                                    compliance_frequency: 0,
                                                                    compliance_frequency_name: 'All',
                                                                    compliance_task_status: '',
                                                                    compliance_task_status_name: 'All',
                                                                    unit: '',
                                                                    unit_name: '',
                                                                    user_type: '',
                                                                    user_type_name: 'All'
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                            value={unitdata.legal_entity_name || undefined}
                                                            disabled={unitdata.countryName == '' ? true : false}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legal_entity_id',
                                                            unitdata.legal_entity_name,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required ',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Act : </label>
                                                <Select
                                                    value={unitdata.act_name || undefined}
                                                    disabled={unitdata.unit != "" ? false : true}
                                                    // onClick={() => {
                                                    //     if (unitdata.unit == '') {
                                                    //         toast.warning("Domain Required")
                                                    //     }
                                                    // }}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            act: value,
                                                            act_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Act"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {act && act.length > 0 && act.map((item, i) => {
                                                        return (
                                                            <Option key={item.parent_id}>
                                                                {item.statutory_mapping}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                {/* <label>From Date <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker style={{ width: '280px' }}
                                                    onChange={(date, dateString) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: moment(new Date(dateString)).format('DD-MMM-YYYY')
                                                        })
                                                    }}
                                                /> */}
                                                <label style={{ "fontWeight": "bold" }}>From Date  : <span style={{ color: "red" }} >*</span></label>
                                                <DatePicker value={unitdata.from_date ? moment(unitdata.from_date) : undefined} format={'DD-MMM-YYYY'} style={{ width: '100%', marginTop: '5px' }} placeholder='DD-MMM-YYYY'
                                                    allowClear={false}
                                                    onChange={(date, dateString) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: dateString,
                                                            to_date: moment(dateString).add(3, 'months')
                                                        })
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'from_date',
                                                    unitdata.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Division : </label>
                                                <Select
                                                    value={unitdata.division_name || undefined}
                                                    onChange={(value, data) => {
                                                        let category = _.filter(unitreport.categories_list, { div_id: Number(value) })
                                                        let unit = _.filter(unitreport.unit_legal_entity, { division_id: Number(value) })
                                                        setCategoryList(category)
                                                        setUnit(unit)
                                                        setUnitData({
                                                            ...unitdata,
                                                            division: value,
                                                            division_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Division"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {unitreport && unitreport.divisions_list && unitreport.divisions_list.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Compliance Task : </label>
                                                <Input placeholder="Enter Compliance Task"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                    value={unitdata.compliance_task || undefined}
                                                    onChange={(e) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            compliance_task: e.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                {/* <label>To Date <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker style={{ width: '280px' }}
                                                    onChange={(date, dateString) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: moment(new Date(dateString)).format('DD-MMM-YYYY')
                                                        })
                                                    }}
                                                /> */}
                                                <label style={{ "fontWeight": "bold" }}>To Date :  <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker format={'DD-MMM-YYYY'} style={{ width: '100%', marginTop: '5px' }} placeholder='DD-MMM-YYYY'
                                                    allowClear={false}
                                                    value={unitdata.to_date || undefined}
                                                    onChange={(date, dateString) => {
                                                        disablecondition(dateString)
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: dateString._i
                                                        })
                                                    }}
                                                    disabledDate={(current) => {
                                                        return moment(unitdata.from_date).add(1, 'days') >= current ||
                                                            moment(unitdata.from_date).add(1, 'year') < current;
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'to_date',
                                                    unitdata.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required ',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Category : </label>
                                                <Select
                                                    value={unitdata.category_name || undefined}
                                                    onChange={(value, data) => {
                                                        let unit = _.filter(unitreport.unit_legal_entity, { category_id: Number(value) })
                                                        setUnit(unit)
                                                        setUnitData({
                                                            ...unitdata,
                                                            category: value,
                                                            category_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Category"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Compliance Frequency : </label>
                                                <Select
                                                    value={unitdata.compliance_frequency_name || undefined}
                                                    defaultValue={"All"}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            compliance_frequency: value,
                                                            compliance_frequency_name: data.children
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    <Option key={0}>All</Option>
                                                    {unitreport && unitreport.compliance_frequency_list && unitreport.compliance_frequency_list.map((item, i) => {
                                                        return (
                                                            <Option key={item.frequency_id}>
                                                                {item.frequency_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Compliance Task Status : </label>
                                                <Select
                                                    value={unitdata.compliance_task_status_name || undefined}
                                                    defaultValue={"All"}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            compliance_task_status: value,
                                                            compliance_task_status_name: data.children
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    <Option key='All'>All</Option>
                                                    {unitreport && unitreport.compliance_task_status && unitreport.compliance_task_status.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_status}>
                                                                {item.task_status}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Unit  : <span style={{ color: "red" }}>*</span></label>
                                                {/* <Select
                                                        allowClear={false}
                                                        disabled={data1.domain != "" && data1.timezone == "" ? false : true}
                                                        size="default"
                                                        placeholder="Enter TimeZone"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={data1.domain ? unitlist && unitlist.timezone && unitlist.timezone[0] && unitlist.timezone[0].tz_name : ""}
                                                        style={{ width: '300px' }}
                                                    >

                                                    </Select> */}
                                                <Select allowClear={false}
                                                    value={unitdata.unit_name || undefined}
                                                    onChange={(value, data) => {
                                                        let user = _.filter(unitreport && unitreport.compliance_users, { unit_id: Number(value) })
                                                        const unique = _.uniqBy(user, function (e) {
                                                            return e.completed_by;
                                                        });
                                                        setUsers(unique)

                                                        setUnitData({
                                                            ...unitdata,
                                                            unit: value,
                                                            unit_name: data.children,
                                                            user: 0,
                                                            user_name: '',
                                                            // legel_entity: entityid,
                                                            act: 0,
                                                            act_name: '',
                                                            from_date: "",
                                                            compliance_task: null,
                                                            to_date: "",
                                                            compliance_frequency: 0,
                                                            compliance_frequency_name: 'All',
                                                            compliance_task_status: '',
                                                            compliance_task_status_name: 'All',
                                                            user_type: '',
                                                            user_type_name: 'All',
                                                            domain: 0,
                                                            domain_name: '',

                                                        })
                                                    }}
                                                    placeholder="Enter Unit"
                                                    disabled={entityid == '' ? true : false}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {unit && unit.length > 0 && unit.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {`${item.unit_code}-${item.unit_name}`}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'unit',
                                                    unitdata.unit,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Unit Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>User Type : </label>
                                                <Select allowClear={false}
                                                    value={unitdata.user_type_name || undefined}
                                                    defaultValue={"All"}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            user_type: value,
                                                            user_type_name: data.children
                                                        })
                                                    }}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    <Option key='All'>All</Option>
                                                    {usertype && usertype.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_type_id}>
                                                                {item.user_type}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                <button className="btn btn-success" style={{ width: "100px" }} >Export</button>
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div> */}
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <Button type="primary" disabled={disableButton} shape="round" className='addbutton'
                                                    style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "65px", marginRight: "10px", marginTop: '5px' }}
                                                    onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#32a852", borderColor: "#32a852", marginTop: '5px' }}
                                                    onClick={showModal}
                                                >
                                                    Export
                                                </Button>
                                                {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            {tableshow == true ? <Collapse defaultActiveKey={["1"]} style={{ marginTop: '1%', backgroundColor: '#5daae3' }} className='report-collapse' onChange={onStatChange} >
                                <Panel header="Overall Statistics" key={statkey}>
                                    <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                        <div className="row m-2">
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={30} width={90} format={(percent) => `${130}`} />
                                                <h4>Complied</h4>
                                            </div>
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={20} width={90} status="exception" format={(percent) => `${10}`} />
                                                <h4>Delayed Complied</h4>

                                            </div>
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={100} width={90} format={(percent) => `${1200}`} />
                                                <h4>Inprogress</h4>

                                            </div>
                                            <div className="col-md-3">
                                                <Progress type="circle" percent={80} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${180}`} />
                                                <h4>Not Complied</h4>

                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse> : false}
                            {tableshow == true ?
                                <>
                                    <div className='col-md-12 mt-2 mb-2' >
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ marginLeft: '15%' }}><b>Legal Entity :</b> {unitdata.legal_entity_name}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ marginLeft: '30%' }}><b>Country :</b> {unitdata.countryName ? unitdata.countryName : sessionArr[0].c_name}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Unit :</b> {unitdata.unit_name}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content userprivclass1'>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className="ant-table-thead antable-head" >
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: '10px' }}> # </th>
                                                                <th className='ant-table-cell' style={{ width: '150px' }}>Compliance Task</th>
                                                                {/* <th className='ant-table-cell custom-width1'>Criticality</th> */}
                                                                <th className='ant-table-cell' style={{ width: '80px' }}>Frequency</th>
                                                                <th className='ant-table-cell' style={{ width: '250px' }}><center>Due Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '160x' }}>Compliance Task Status</th>
                                                                <th className='ant-table-cell' style={{ width: '150px' }}><center>User Name</center></th>
                                                                <th className='ant-table-cell' style={{ width: '200px' }}><center>Activity Status</center></th>
                                                                <th className='ant-table-cell' style={{ width: '220px' }}><center>Activity Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '150px' }}><center>Uploaded Document</center></th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>Task Completion Date/Document issued Date</center></th>
                                                            </tr>
                                                        </thead>
                                                        {filteredArrayState && filteredArrayState.length > 0 ?
                                                            filteredArrayState.map((item, i) => {
                                                                return (
                                                                    <tbody className='ant-table-tbody'>
                                                                        <tr className="bg-lightdark">
                                                                            <td colSpan="100%">
                                                                                <table className='white' style={{ tableLayout: 'auto' }}>
                                                                                    <tbody className='ant-table-tbody'>
                                                                                        <div className="col-md-12">
                                                                                            <div className='row m-2'>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Domain :</b> {item[0].domain_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Division :</b> {item[0].division_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Category :</b> {item[0].category_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <div className='row m-2'>
                                                                                            <div className='col-md-4' >
                                                                                              
                                                                                            </div>
                                                                                            <div className='col-md-4' >
                                                                                               
                                                                                            </div>
                                                                                            <div className='col-md-4' >
                                                                                            </div>
                                                                                        </div> */}
                                                                                        {/* <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className='ant-table-cell w6'><b>Domain :</b></td>
                                                                                            <td className='ant-table-cell w15'>{item[0].domain_name}</td>
                                                                                            <td className='ant-table-cell w6'><b>Division :</b></td>
                                                                                            <td className='ant-table-cell w15' >{item[0].division_name}</td>
                                                                                        </tr>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className='ant-table-cell w6'><b>Category:</b></td>
                                                                                            <td className='ant-table-cell w40'><span className="category-name">{item[0].category_name}</span></td>
                                                                                            <td className='ant-table-cell w6'><b>Time Zone:</b></td>
                                                                                            <td className='ant-table-cell w40'><span className="category-name">{item[0].tz_name}</span></td>
                                                                                        </tr> */}
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>

                                                                        {titledata && titledata.map((itemdata, i) => {
                                                                            let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                            if (data != undefined) {
                                                                                if (data.statutory_mapping == itemdata.parent) {
                                                                                    return <Fragment>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                        </tr>
                                                                                        {itemdata.child.map((items) => {
                                                                                            if (item[0].unit_id == items.unit_id) {
                                                                                                return <tr>
                                                                                                    <td className='ant-table-cell'><center>{count + 1}</center></td>
                                                                                                    <td className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                        <ExclamationCircleTwoTone />
                                                                                                    </Tooltip> &nbsp;{items.compliance_task}</td>
                                                                                                    {/* <td className='ant-table-cell'>{items.Criticality}</td> */}
                                                                                                    <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                                    <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                    <td className='ant-table-cell'>{items.task_status}</td>
                                                                                                    <td className='ant-table-cell'>{items.assignee_name}</td>
                                                                                                    <td className='ant-table-cell'>{items.activity_status}</td>
                                                                                                    <td className='ant-table-cell'><center>{items.activity_date}</center></td>
                                                                                                    <td className='ant-table-cell'>{items.document_name}</td>
                                                                                                    <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                                    {/* </tr> */}
                                                                                                    <span hidden>{count = count + 1}</span>
                                                                                                </tr>
                                                                                            }
                                                                                        })
                                                                                        }
                                                                                    </Fragment>
                                                                                }
                                                                            }
                                                                        })
                                                                        }

                                                                    </tbody>
                                                                )
                                                            }) :
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td style={{ paddingTop: '5%', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '5%' }}>No Records Found</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                            </tr>
                                                        }
                                                    </table>

                                                    {/* <br /> */}
                                                    <div>
                                                        {/* <button
                                                className="btn btn-light-success rounded-pill px-4 text-success"
                                                onClick={submitrec}>Submit</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {count != 0 ?
                                        <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                            <label style={{ marginLeft: "28px" }}>Showing {pageSize * (current - 1) + 1} to {count} of {unitwisereports && unitwisereports.total_count} entries</label>
                                        </div> : ''}
                                    <div
                                        style={{ position: 'absolute', bottom: '-25px', right: 0 }}>
                                        <Pagination
                                            current={current}
                                            showSizeChanger={true}
                                            // showQuickJumper={true}
                                            onShowSizeChange={() => {
                                                setCurrent(1)
                                                // setpageState(true)
                                            }}
                                            pageSizeOptions={[25, 50, 100]}
                                            hideOnSinglePage={unitwisereports && unitwisereports.total_count > 25 ? false : true}
                                            defaultPageSize={25}
                                            onChange={(page, pageSizes) => {
                                                setpageState(true)
                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                setPageSize(pageSizes)
                                            }}
                                            total={unitwisereports && unitwisereports.total_count}
                                        // showTotal={(total) => `Showing 1 to ${count} of ${unitwisereports && unitwisereports.total_count} entries`}
                                        />

                                    </div>

                                </>
                                : false}
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}
export default connect(mapStateToProps, {
    UnitWiseReportFilters,
    UnitWiseReport,
    UnitExportData
})(UnitWiseReports);