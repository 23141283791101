import { LEGALENTITYREPORT,LEGALENTITYTABLE,LEGALENTITYEXPORT,TOGGLE_COMMON_LOADER } from '../../types/index'

const initialState = {
    legalreport: {
        data: [],
        getTableData:[],
     
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LEGALENTITYREPORT: {
           
            console.log(payload, "payloadred");
            return {
                ...state,
                legalreport: {
                    ...state.legalreport,
                    data:payload
                },
            };
        }
        case LEGALENTITYTABLE: {
          
            console.log(payload, "payloadred1111");
            return {
                ...state,
                legalreport: {
                    ...state.legalreport,
                    getTableData:payload
                },
            };
        }
        default:
            return state;
    }
}
