import { CVMONTHWISEREPORT, COMPLIANCE_CERTIFICATE_FILTER, CERTIFICATE_REMARKS_DATA, CERTIFICATE_MAIL_DATA, APPROVE_DETAILS_MODAL, APPROVE_COMPLIANCE_STATUS_MODAL, APPROVE_COMPLIANCE_CERTIFICATE_TABLE, APPROVE_COMPLIANCE_CERTIFICATE_FILTER, SEARCH_COMPLIANCE, DOWNLOAD_COMPLIANCE, LABEL_LIST, DETAILS_MODAL, MAPPING_DETAILS, COMPLIANCE_STATUS_MODAL, COMPLIANCE_CERTIFICATE_TABLE, TOGGLE_COMMON_LOADER, MONTHWISEREPORTTABLE } from './../../types/index'
const initialState = {
    approvecomplianceCertificate: {
        FilterData: [],
        tabledata: [],
        complianceStatusModal: [],
        detailsModal: [],
        MappingDetails: [],
        labelList: [],
        searchData: [],
        downloadFile: [],
        mailDatas: [],
        RemarksDatas: []
    }
}
export default function (state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
        case APPROVE_COMPLIANCE_CERTIFICATE_FILTER:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    approvecomplianceCertificate: {
                        ...state.approvecomplianceCertificate,
                        FilterData: payload,
                    },
                };
            }
        case APPROVE_COMPLIANCE_CERTIFICATE_TABLE:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    approvecomplianceCertificate: {
                        ...state.approvecomplianceCertificate,
                        tabledata: payload,
                    },
                };
            }
        case APPROVE_COMPLIANCE_STATUS_MODAL:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    approvecomplianceCertificate: {
                        ...state.approvecomplianceCertificate,
                        complianceStatusModal: payload,
                    },
                };
            }
        case APPROVE_DETAILS_MODAL:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    approvecomplianceCertificate: {
                        ...state.approvecomplianceCertificate,
                        detailsModal: payload,
                    },
                };
            }
        case CERTIFICATE_MAIL_DATA:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    approvecomplianceCertificate: {
                        ...state.approvecomplianceCertificate,
                        mailDatas: payload,
                    },
                };
            }
        case CERTIFICATE_REMARKS_DATA:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    approvecomplianceCertificate: {
                        ...state.approvecomplianceCertificate,
                        RemarksDatas: payload,
                    },
                };
            }
        default:
            return state;
    }
}