import React from 'react'
import moment from 'moment';
import { Collapse, Select, Input, DatePicker, Card, Pagination, Popover, Drawer, Button, Table, Tooltip, Modal, Descriptions, Empty, Space } from 'antd';

import DataTable from "react-data-table-component";
import { Fragment, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ArrowRightOutlined, CheckOutlined, CloseOutlined, ArrowLeftOutlined, CloseCircleOutlined, SearchOutlined, FilterFilled, PlayCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getCommonData, getTaskDistributionchart, getTaskDistributionTable, getTimeZoneData, getTaskDistributionShowMoreTableData } from '../../Store/Action/Dashboard/TaskDistribution';
import { toast } from 'react-toastify';


// import "core-js/stable";
// import "regenerator-runtime/runtime";

const mapStateToProps = (state) => ({
    data: state.TaskDistributionCharts.taskDistribution.chartData,
    time: state.TaskDistributionCharts.taskDistribution.time,
    commonData: state.TaskDistributionCharts.taskDistribution.Data,
    tableData: state.TaskDistributionCharts.taskDistribution.tableData,
    timeZoneData: state.TaskDistributionCharts.taskDistribution.timeZoneData,
    showMoreData: state.TaskDistributionCharts.taskDistribution.showMoreData

})

const TaskDistribution = ({
    getFilterValue,
    getCommonData,
    getTaskDistributionchart, getTaskDistributionTable,
    data,
    commonData,
    time,
    tableData,
    getTimeZoneData,
    timeZoneData,
    getTaskDistributionShowMoreTableData,
    showMoreData
}) => {
    console.log(tableData, 'tableDatatableData');
    console.log(timeZoneData, 'timeZoneData');
    const _ = require("lodash");
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [taskDistributionTable, setTaskDistributionTable] = useState([])

    const [filterData, setFilterData] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const { Panel } = Collapse;
    const [chartId, setChartId] = useState()
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],

    })
    var [count, setCount] = useState(1)
    const [open, setOpen] = useState(false)
    const [showmoreCount, setShowMoreCount] = useState(0)
    const [table, setTable] = useState(false)
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [fieldType, setFieldType] = useState("")
    const [unit, setUnit] = useState([])
    const chart = useRef(null);
    const { Option } = Select;
    const [year, setYear] = useState(moment().year())
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const [entityData, setEntityData] = useState([])
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    console.log(commonData, 'commonData');
    const location = useLocation();
    const [paramid, setParamId] = useState("")
    const [generatedOn, setGeneratedOn] = useState(false)
    const [go, setGo] = useState(false)
    const [filterDataNames, setFilterDataNames] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const country_info = sessionParsedValue.country_info
    const [criticalityValue, setCriticalityValue] = useState()
    const [domainName, setDomainName] = useState()
    const [countValue, setCountValue] = useState(0)
    const [dataCount, setDataCount] = useState()
    const { OptGroup } = Select;
    const [domainListData, setDomainListData] = useState([])
    const [divisionListData, setDivisionListData] = useState([])
    const [categoryListData, setCategoryListData] = useState([])
    const [unitListData, setUnitListData] = useState([])
    const [bgIds, setBgIds] = useState([])
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [legalEntityArray, setLegalEntityArray] = useState([])
    const [checkBox, setCheckBox] = useState(true)
    const [countryDomain, setCountryDomain] = useState([])
    const [dIds, setDIds] = useState([])
    const [type, setType] = useState('')

    useEffect(() => {
        {

            var the_arr = location.pathname.split('/');

            the_arr.pop();

            let a = the_arr.join('/')

            setParamId(a)

        }

    }, []);
    console.log(generatedOn, 'generatedOn');
    console.log(timeZone, 'timeZone');

    useEffect(() => {
        if (tableData) {
            setTaskDistributionTable(tableData.r_drill_down_data)
        }

    }, [tableData])
    console.log(tableData, 'taskDistributionTable');

    useEffect(() => {
        if (commonData) {
            let unique = []
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                unique = _.uniqBy(commonData && commonData.bg_groups, function (e) {
                    return e.bg_id;
                });
            }
            setfield({
                ...field,
                domain: commonData && commonData.d_info,
                legalEntity: commonData && commonData.le_did_infos,
                country: commonData && commonData.countries,
                business: unique
            })
            let uniqueDiv = []
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                uniqueDiv = _.uniqBy(commonData && commonData.div_infos, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)
            setCategory(commonData && commonData.cat_info)
            setUnit(commonData && commonData.chart_units)
        }

    }, [isAuth, entityid, commonData])

    console.log(field, 'fieldfield');
    console.log(commonData, ' commonDatacommonDatacommonData');

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            setDomainName(domainNameArray)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityid])
    useEffect(() => {
        if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
            console.log('sneha');
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request":
                        ["GetTskDistributionStatusChart",
                            {
                                "c_ids": [
                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                ],
                                "d_ids": dIds,
                                "filter_type": "Group",
                                "filter_ids": [
                                    1
                                ],
                                "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
                                "country_domains": countryDomain,
                                "chart_year": year
                            }
                        ]
                }
            ]
            if (paramid != "") {
                getTaskDistributionchart({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityData, year, commonData, dIds, countryDomain, year])

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != '	All Legal Entity') {
    //         if ((commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
    //            console.log('aishuuu');
    //             const payload = [
    //                 authtoken,
    //                 {
    //                     "session_token": authtoken,
    //                     "request":
    //                         ["GetTskDistributionStatusChart",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": "Group",
    //                                 "filter_ids": [
    //                                     1
    //                                 ],
    //                                 "le_ids": [entityid],
    //                                 "country_domains": countryDomain,
    //                                 "chart_year": year
    //                             }
    //                         ]
    //                 }
    //             ]
    //             if (paramid != "") {
    //                 getTaskDistributionchart({
    //                     payload: payload,
    //                     paramid: paramid
    //                 })
    //             }
    //         }
    //     }

    // }, [localStorage.getItem('SelectedEntity') != '	All Legal Entity', isAuth, entityid, commonData, year, countryDomain, dIds])

    const dataSource = [
        {
            key: '1',
            name: 'Rule 18',
            task: 'Trail Task117',
            description: 'test',
            p_cons: "Shall be punishable with imprisonment for a term which shall not be less than six months but which may extend to three years and with fine which shall not be less than fifty thousand rupees but which may extend to two lakh rupees - Section 63",
            address: '10 Downing Street',
            frequency: 'periodical',
            task_status: 'opted',
            trigger_before_days: 10,
            task: 'Trail Task119',
            summary: "Repeats every 1 Month(s) "
        },
        {
            key: '2',
            name: 'Rule 3',
            description: 'Online application can be submitted for one musical license covering all branches of pan India by paying prescribed fees. Music license is applicable for music played for public in TV, radio, live performances, DJ by internet or even copied in CD, DVD or pen drives/hard disc',
            address: '10 Downing Street',
            frequency: 'Review',
            task_status: 'Not opted',
            task: 'Trail Task',
            summary: "Repeats every 6 Month(s) ",
            trigger_before_days: 20,
            p_cons: "Section 23 - Imprisonment up to 3 months or with fine up to Rs.1000/- or with both. If the contravention is continued fine may extend to Rs.100/- for each day",
        },
        {
            key: '3',
            name: 'Rule 12',
            description: 'test',
            frequency: 'OnOccurence',
            address: '10 Downing Street',
            p_cons: "test",
            task: 'Trail Task55',
            task_status: 'opted',
            trigger_before_days: 25,
            summary: "Repeats every 1 Month(s) "
        },
    ];
    useEffect(() => {
        if (taskDistributionTable && taskDistributionTable.length > 0) {
            let count = []
            for (let i in taskDistributionTable) {
                for (let j in taskDistributionTable[i].ap_compliances) {
                    let a = taskDistributionTable[i].ap_compliances[j]
                    let data = Object.values(a)


                    count.push(data.length)

                    // count.push(data[i])
                    console.log(data, ' taskDistributionTable[i].ap_compliances');

                }
            }
            console.log(count, 'count');
            let countValues = count.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setShowMoreCount(countValues)
            console.log(countValues, 'countValue9999');
        }

    }, [taskDistributionTable])
    console.log(showmoreCount, 'showmoreCount');
    useEffect(() => {
        console.log(taskDistributionTable, 'taskDistributionTable');
        console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data5555');
        if (showMoreData && showMoreData.r_drill_down_data && showMoreData.r_drill_down_data.length > 0) {
            let temp = []
            let temp2 = []
            console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data');
            for (let i in taskDistributionTable) {
                for (let j in showMoreData.r_drill_down_data) {
                    if (taskDistributionTable[i].level1_statutory_name == showMoreData.r_drill_down_data[j].level1_statutory_name) {
                        for (let s in taskDistributionTable[i].ap_compliances) {
                            for (let a in showMoreData.r_drill_down_data[j].ap_compliances) {
                                console.log(s, 'taskDistributionTable[i].ap_compliances[s]');
                                console.log(showMoreData.r_drill_down_data[j].ap_compliances[a], 'showMoreData.r_drill_down_data[j].ap_compliances[a]');
                                if (s == a) {
                                    let arr1 = taskDistributionTable[i].ap_compliances[s]
                                    let arr2 = showMoreData.r_drill_down_data[j].ap_compliances[a]
                                    let arr3 = arr1.concat(arr2)
                                    console.log(arr3, 'arr3');
                                    taskDistributionTable[i].ap_compliances[s] = arr3
                                    console.log(s, 'sneha');
                                }
                                else {
                                    taskDistributionTable[i].ap_compliances[a] = showMoreData.r_drill_down_data[j].ap_compliances[a]
                                }
                            }
                        }
                        // let newArray = { ...obj, ...objdata }

                        // var newArray = Object.assign({}, taskDistributionTable[i].ap_compliances, showMoreData.r_drill_down_data[j].ap_compliances);
                        // taskDistributionTable[i].ap_compliances = newArray
                        temp2.push(taskDistributionTable[i].level1_statutory_name)
                        // setTaskDistributionTable(showMoreData.r_drill_down_data)
                    }

                }
            }
            console.log(temp2, 'temp2');
            for (let i in showMoreData.r_drill_down_data) {

                if ((temp2.includes(showMoreData.r_drill_down_data[i].level1_statutory_name)) == false) {
                    // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
                    console.log(showMoreData.r_drill_down_data[i], 'datadata');
                    temp.push(showMoreData.r_drill_down_data[i])
                }



            }

            setTaskDistributionTable([...taskDistributionTable, ...temp])



        }

    }, [showMoreData])

    // useEffect(() => {
    //     if(showMoreData && showMoreData.r_drill_down_data && showMoreData.r_drill_down_data.length > 0){
    //         for(let i in showMoreData.r_drill_down_data){
    //             for(let j in taskDistributionTable){
    //                 if(showMoreData.r_drill_down_data[i].level1_statutory_name === taskDistributionTable[j].level1_statutory_name){
    //                     const filterValue = _.find(showMoreData.r_drill_down_data,{level1_statutory_name:taskDistributionTable[j].level1_statutory_name})
    //                     console.log(filterValue,'filterValue');
    //                     let employee = {
    //                         ...taskDistributionTable[j].ap_compliances,
    //                         ...filterValue.ap_compliances
    //                     };
    //                     console.log(employee,'employee');
    //                 }
    //                 else{
    //                     setTaskDistributionTable(showMoreData.r_drill_down_data)
    //                 }

    //             }
    //         }
    //     }

    // },[showMoreData])


    console.log(showMoreData, 'showMoreData');
    console.log(taskDistributionTable, 'taskDistributionTable');

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
            let arrunit = [];
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-domain');
                if (country_info && country_info[0].c_id == subunitlist.c_id) {
                    entityArrayunit = {

                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'is_active': subunitlist.is_active,
                        'month_from': subunitlist.month_from,
                        'month_to': subunitlist.month_to,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setDomainListData(countrylisttempdataunit)
        }
    }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if (commonData) {
            let temp = []
            for (let i in commonData.d_info) {
                temp.push(commonData.d_info[i].d_id)
            }
            setFilterData({
                ...filterData,
                country: `${country_info[0].c_id}`,
                domain: temp
            })
        }
    }, [commonData])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            let arrunit = [];
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-domain');
                if (country_info && country_info[0].c_id == subunitlist.c_id) {
                    entityArrayunit = {

                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'is_active': subunitlist.is_active,
                        'month_from': subunitlist.month_from,
                        'month_to': subunitlist.month_to,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setDomainListData(countrylisttempdataunit)
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
                let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(commonData && commonData.bg_groups[0].bg_id) })
                console.log(filteredDivision, 'filteredDivision');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)
                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)
                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)

                }
            }
            else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                console.log('hello ais');
                let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: commonData.le_did_infos[0].le_id })
                console.log(filteredDivision, 'filteredDivision555');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)

                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)

                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)
                }
            }
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && bgIds.length > 0 && commonData) {
            if (bgIds.includes(null) === true) {
                console.log('snehaaa');
                setfield({
                    ...field,
                    legalEntity: commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos,
                })

            }
            else {
                let filteredLegalEntity = _.filter(commonData.le_did_infos, { bg_id: Number(commonData.bg_groups[0].bg_id) })
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                })
            }

        }
    }, [commonData, bgIds, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredCategory, 'filteredCategory111');
                let temp = []
                temp.push({
                    'bg_id': filteredCategory[0].bg_id,
                    'c_id': filteredCategory[0].c_id,
                    'cat_id': filteredCategory[0].cat_id,
                    'cat_name': filteredCategory[0].cat_name,
                    'd_id': filteredCategory[0].d_id,
                    'div_id': filteredCategory[0].div_id,
                    'le_id': filteredCategory[0].le_id,
                    'div_name': commonData && commonData.div_infos[0].div_name
                })
                setCategory(temp)
                let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredUnit, 'filteredUnit123');
                setUnit(filteredUnit)
            }
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity && filteredLegalEntity[0].bg_id })
                console.log(filteredBusinessGroup, 'filteredBusinessGroup');
                const unique = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                console.log(unique, 'unique');
                setfield({
                    ...field,
                    business: unique,
                    legalEntity: filteredLegalEntity
                })
            }

        }
    }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if ((commonData) && (division && division.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            division && division.length > 0 && division.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');

                if (commonData && commonData.le_did_infos[0].le_id == subunitlist.le_id) {

                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.div_name,
                        'values': subunitlist.div_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            console.log(totalListunit, 'totalListunit');

            countrylisttempdataunit.push(totalListunit)
            console.log(countrylisttempdataunit, 'countrylisttempdataunit');
            setDivisionListData(countrylisttempdataunit)
        }
    }, [commonData, division, field.legalEntity])

    useEffect(() => {
        if (type === 'Group')
            setGroupCheckBox(checkBox)
    }, [type, checkBox])

    useEffect(() => {
        if (field.legalEntity && field.legalEntity.length > 0) {
            if (division) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
            if (category) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                category && category.length > 0 && category.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-category');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setCategoryListData(countrylisttempdataunit)
            }
            if (unit) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                unit && unit.length > 0 && unit.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-unit');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'address': subunitlist.address,
                            'business_group_id': subunitlist.business_group_id,
                            'category_id': subunitlist.category_id,
                            'country_id': subunitlist.country_id,
                            'd_ids': subunitlist.d_ids,
                            'division_id': subunitlist.division_id,
                            'le_id': subunitlist.le_id,
                            'postal_code': subunitlist.postal_code,
                            'u_id': subunitlist.u_id,
                            'u_name': subunitlist.u_name,
                            'label': subunitlist.u_name,
                            'values': subunitlist.u_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                countrylisttempdataunit.push(totalListunit)
                setUnitListData(countrylisttempdataunit)
            }
        }
    }, [field.legalEntity, division, category, unit])

    useEffect(() => {
        if ((commonData) && (category && category.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            category && category.length > 0 && category.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-category');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }
            countrylisttempdataunit.push(totalListunit)
            setCategoryListData(countrylisttempdataunit)
        }

    }, [commonData, category && category.length > 0])

    useEffect(() => {
        if ((commonData) && (unit && unit.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            unit && unit.length > 0 && unit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-unit');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'address': subunitlist.address,
                        'business_group_id': subunitlist.business_group_id,
                        'category_id': subunitlist.category_id,
                        'country_id': subunitlist.country_id,
                        'd_ids': subunitlist.d_ids,
                        'division_id': subunitlist.division_id,
                        'le_id': subunitlist.le_id,
                        'postal_code': subunitlist.postal_code,
                        'u_id': subunitlist.u_id,
                        'u_name': subunitlist.u_name,
                        'label': subunitlist.u_name,
                        'values': subunitlist.u_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setUnitListData(countrylisttempdataunit)
        }
    }, [commonData, unit && unit.length > 0])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            console.log('hiii');
            if ((commonData && commonData.bg_groups && commonData.bg_groups.length === 0) && (legalEntityArray && legalEntityArray.length > 0)) {
                console.log('hello');
                if (unit) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    unit && unit.length > 0 && unit.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-lee');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'address': subunitlist.address,
                                'business_group_id': subunitlist.business_group_id,
                                'category_id': subunitlist.category_id,
                                'country_id': subunitlist.country_id,
                                'd_ids': subunitlist.d_ids,
                                'division_id': subunitlist.division_id,
                                'le_id': subunitlist.le_id,
                                'postal_code': subunitlist.postal_code,
                                'u_id': subunitlist.u_id,
                                'u_name': subunitlist.u_name,
                                'label': subunitlist.u_name,
                                'values': subunitlist.u_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunitleee');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setUnitListData(countrylisttempdataunit)
                }
                if (division) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    division && division.length > 0 && division.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {

                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.div_name,
                                'values': subunitlist.div_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunit');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setDivisionListData(countrylisttempdataunit)
                }
                if (category) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    category && category.length > 0 && category.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-category');
                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'cat_id': subunitlist.cat_id,
                                'cat_name': subunitlist.cat_name,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.cat_name,
                                'values': subunitlist.cat_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunit');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                    setCategoryListData(countrylisttempdataunit)
                }
            }
        }

    }, [commonData, legalEntityArray, unit, division, category, localStorage.getItem('SelectedEntity') === "All Legal Entity"])


    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
            }
            console.log(tempIds, 'tempIds');
            setDIds(tempIds)
            setCountryDomain(tempArr)
            console.log(tempArr, 'tempArr');
        }

    }, [commonData])

    const HandelChange = (obj) => {
        console.log(obj, 'objj');
        setType('')
        setFilterData({
            ...filterData,
            domain: obj
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            domain: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
        let temp = []
        let bg = []
        let tempDiv = []
        let tempCat = []
        let tempUnit = []
        for (let i in obj) {
            console.log(obj[i], 'obj[i');
            console.log(i, 'i');
            if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity[0].bg_id })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            } else {
                let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(obj[i])))
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(obj[i]) })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            }
            let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(obj[i]) })
            let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
            let temp1 = []
            temp1.push({
                'bg_id': filteredCategory[0].bg_id,
                'c_id': filteredCategory[0].c_id,
                'cat_id': filteredCategory[0].cat_id,
                'cat_name': filteredCategory[0].cat_name,
                'd_id': filteredCategory[0].d_id,
                'div_id': filteredCategory[0].div_id,
                'le_id': filteredCategory[0].le_id,
                'div_name': division[0].div_name
            })
            tempCat = temp1
            let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
            tempUnit = filteredUnit
            tempDiv = filteredDivision
            let uniqueDiv = []
            if (filteredDivision && filteredDivision.length > 0) {
                uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)

            setCategory(temp1)
            setUnit(filteredUnit)

        }
        console.log(tempCat, 'tempCat');
        console.log(bg, 'Aishuuuu');
        console.log(temp, 'SnehaaaaaaaaAsss');

    }

    console.log(checkBox, 'checkBox');


    const showmore = () => {
        setCountValue(countValue + 100)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request":
                    ["GetTskDistributionStatusDrillDown",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": dIds,
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "record_count": countValue + 100,
                            "applicability_status": criticalityValue,
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain,
                            "chart_year": year
                        }
                    ]
            }
        ]
        if (paramid != "") {
            getTaskDistributionShowMoreTableData({
                payload: payload,
                paramid: paramid
            })
        }
    }



    const onClose = () => {
        setOpen(false);
    };

    console.log(groupCheckBox, 'groupCheckBox');
    console.log(checkBox, 'checkBox');


    const filterChange = () => {
        setGo(true)
        setGeneratedOn(false)
        if (groupCheckBox === true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTskDistributionStatusChart",
                        {
                            "c_ids": [
                                filterData && filterData.country ? Number(filterData && filterData.country) : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids":
                                filterData && filterData.domain ? filterData.domain : dIds
                            ,
                            "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                            "filter_ids": [
                                (fieldType == "LegalEntity" && type === '') ? filterData.legalentity :
                                    (fieldType == 'Division' && type === '') ? Number(filterData.division) :
                                        (fieldType == 'Category' && type === '') ? Number(filterData.category) :
                                            (fieldType == 'Unit' && type === '') ? Number(filterData.unit) :
                                                (fieldType == 'BusinessGroup' && type === '') ? Number(filterData.business) :
                                                    (fieldType != "" && type === 'Group') ? 1 :
                                                        1
                            ],
                            "le_ids":
                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                            ,
                            "country_domains": countryDomain,
                            "chart_year": year
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getTaskDistributionchart({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
        else {
            toast.error('Please Choose Groups')
        }
        setOpen(false);
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder='Search'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };


    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            width: 10,
            align: 'center'
        },

        {
            title: "Statutory",
            ...getColumnSearchProps('s_prov'),
            dataIndex: false,
            key: 's_prov',
            width: 20,
            filteredValue: filteredInfo.s_prov || null,
            render: (record) => {
                return <p title={record.s_prov} className='cut-text'>
                    {record.s_prov}</p>
            }
        },
        {
            title: 'Compliance Task',
            ...getColumnSearchProps('comp_name'),
            dataIndex: false,
            key: 'comp_name',
            ellipsis: false,
            width: 30,
            render: (row, record) => {
                if (record.download_url_list) {
                    return <p title={record.comp_name} className='cut-text'><a href={`${'https://13.234.100.64/compliance-approval/' + record.download_url_list}`}>{record.comp_name}</a></p>
                }
                else {
                    return <p title={record.comp_name} className='cut-text'>{record.comp_name}</p>
                }
            },
            filteredValue: filteredInfo.comp_name || null,

        },
        {
            title: 'Criticality',
            ...getColumnSearchProps('criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            width: 17,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,

        },
        {
            title: 'Compliance Description',
            ...getColumnSearchProps('descp'),
            dataIndex: false,
            key: 'descp',
            ellipsis: false,
            width: 30,
            filteredValue: filteredInfo.descp || null,
            render:(record) => {
                return <p title={record.descp} className='cut-text'>{record.descp}</p>
            }

        },
        {
            title: 'Penal Conseqeunces',
            ...getColumnSearchProps('p_cons'),
            dataIndex: false,
            key: 'p_cons',
            ellipsis: false,
            width: 30,
            filteredValue: filteredInfo.p_cons || null,
            render:(record) => {
                return <p title={record.p_cons} className='cut-text'>{record.p_cons}</p>
            }

        },
        {
            title: "Compliance Frequency",
            ...getColumnSearchProps('frequency'),
            dataIndex: 'frequency',
            key: 'frequency',
            ellipsis: false,
            width: 30,
            filteredValue: filteredInfo.frequency || null,

        },
        {
            title: 'Repeats',
            ...getColumnSearchProps('summary'),
            dataIndex: false,
            key: 'summary',
            ellipsis: false,
            width: 20,
            filteredValue: filteredInfo.summary || null,
            render:(record) => {
                return <p title={record.summary} className='cut-text'>{record.summary}</p>
            }

        },

        {
            title: 'Trigger Before',
            ...getColumnSearchProps('statu_dates'),
            // dataIndex: 'false',
            // key: 'false',
            ellipsis: false,
            width: 25,
            dataIndex: false,
            key: 'statu_dates',
            render: (record) => {
                console.log(record && record.length > 0 && record[0].trigger_before_days, 'record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days');
                return <p title={record && record.length > 0 && record[0].trigger_before_days} className='cut-text'>{record && record.length > 0 && record[0].trigger_before_days}</p>
                
            },
            filteredValue: filteredInfo.statu_dates || null,
        },
    ];



    // const columns = [
    //     {
    //         title: 'Statutory',
    //         dataIndex: 's_prov',
    //         key: 's_prov',
    //     },
    //     {
    //         title: 'Compliance Task',
    //         dataIndex: 'comp_name',
    //         key: 'comp_name',
    //     },
    //     {
    //         title: 'Criticality',
    //         dataIndex: 'criticality',
    //         key: 'criticality',
    //     },
    //     {
    //         title: 'Compliance Description',
    //         dataIndex: 'descp',
    //         key: 'descp',
    //     },
    //     {
    //         title: 'Panel Conseqeunces',
    //         dataIndex: 'p_cons',
    //         key: 'p_cons',
    //     },
    //     {
    //         title: 'Compliance Frequency',
    //         dataIndex: 'frequency',
    //         key: 'frequency',
    //     },
    //     {
    //         title: 'Repeats',
    //         dataIndex: 'summary',
    //         key: 'summary',
    //     },
    //     {
    //         title: 'Trigger Before',
    //         dataIndex: 'statu_dates',
    //         key: 'statu_dates',
    //         render: (record) => {
    //             console.log(record && record.length > 0 && record[0].trigger_before_days, 'record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days');
    //             return <p>{record && record.length > 0 && record[0].trigger_before_days
    //             }</p>
    //         }
    //     },
    // ];

    // useEffect(() => {
    //     if (data && data.length > 0) {
    //         console.log('test');
    //         let total = ((data && data.length > 0 && data[0].assigned_count) + (data && data.length > 0 && data[0].not_opted_count) + (data && data.length > 0 && data[0].unassign_count) + (data && data.length > 0 && data[0].not_applicable_count))
    //         if (table == false) {

    //             let x = am4core.create("chartdiv", am4charts.PieChart3D);
    //             am4core.useTheme(am4themes_animated);
    //             x.paddingRight = 20;
    //             x.data = [{
    //                 "task": "Assigned",
    //                 "count": data && data.length > 0 && data[0].assigned_count,
    //                 "color": am4core.color("#00a3a3")
    //             }, {
    //                 "task": "Not Opted",
    //                 "count": data && data.length > 0 && data[0].not_opted_count,
    //                 "color": am4core.color("#dd070c")
    //             },
    //             {
    //                 "task": "Unassigned",
    //                 "count": data && data.length > 0 && data[0].unassign_count,
    //                 "color": am4core.color("#ff9c80")
    //             },
    //             {
    //                 "task": "Not Applicable",
    //                 "count": data && data.length > 0 && data[0].not_applicable_count,
    //                 "color": am4core.color("#808080")
    //             },
    //             ];

    //             var pieSeries = x.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "count";
    //             console.log(pieSeries.dataFields.value, ' pieSeries.dataFields.value');
    //             pieSeries.dataFields.category = "task";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             pieSeries.calculatePercent = true;
    //             pieSeries.calculateTotals = true;


    //             pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#.00')}[/]% : ({value.value} out of ${total})`;
    //             chart.current = x;
    //             x.legend = new am4charts.Legend();
    //             x.legend.valueLabels.template.disabled = true;
    //             x.legend.readerValueText = "{category}";
    //             x.legend.position = "right";
    //             x.legend.useDefaultMarker = true;
    //             var marker = x.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 1;
    //             marker.strokeOpacity = 0.5;
    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setTable(true)
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request":
    //                             ["GetTskDistributionStatusDrillDown",
    //                                 {
    //                                     "c_ids": [
    //                                         commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                     ],
    //                                     "d_ids": [
    //                                         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                     ],
    //                                     "filter_type": "Group",
    //                                     "filter_ids": [
    //                                         1
    //                                     ],
    //                                     "record_count": 0,
    //                                     "applicability_status": ev.target.dataItem.category,
    //                                     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
    //                                         commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
    //                                     ],
    //                                     "country_domains": [
    //                                         {
    //                                             "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                             "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                             "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                             "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                         }
    //                                     ],
    //                                     "chart_year": year
    //                                 }
    //                             ]
    //                     }
    //                 ]
    //                 getTaskDistributionTable({
    //                     payload: payload,
    //                     paramid: paramid
    //                 })
    //             });

    //         }
    //     }
    // }, [data, table, localStorage.getItem('chart')])

    useEffect(() => {
        if (data && data.length > 0) {
            console.log('test');
            let total = ((data && data.length > 0 && data[0].assigned_count) + (data && data.length > 0 && data[0].not_opted_count) + (data && data.length > 0 && data[0].unassign_count) + (data && data.length > 0 && data[0].not_applicable_count))
            if (table == false) {
                var container = am4core.create("container", am4core.Container);
                container.width = am4core.percent(100);
                container.height = am4core.percent(100);
                container.layout = "vertical";
                container.background.fill = am4core.color("#000");
                container.background.fillOpacity = 0.1;
                container.background.stroke = am4core.color("#000");
                container.background.strokeOpacity = 0.2;
                container.background.strokeWidth = 2;

                function addChart(data) {

                    let x = container.createChild(am4charts.PieChart3D);
                    am4core.useTheme(am4themes_animated);
                    x.paddingRight = 20;
                    x.data = data

                    var pieSeries = x.series.push(new am4charts.PieSeries3D());
                    pieSeries.dataFields.value = "count";
                    console.log(pieSeries.dataFields.value, ' pieSeries.dataFields.value');
                    pieSeries.dataFields.category = "task";
                    pieSeries.slices.template.propertyFields.fill = "color";
                    pieSeries.calculatePercent = true;
                    pieSeries.calculateTotals = true;


                    pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;
                    chart.current = x;
                    x.legend = new am4charts.Legend();
                    x.legend.valueLabels.template.disabled = true;
                    x.legend.readerValueText = "{category}";
                    x.legend.position = "right";
                    x.legend.useDefaultMarker = true;
                    var marker = x.legend.markers.template.children.getIndex(0);
                    marker.cornerRadius(12, 12, 12, 12);
                    marker.strokeWidth = 1;
                    marker.strokeOpacity = 0.5;
                    pieSeries.slices.template.events.on("hit", function (ev) {
                        console.log(ev.target.dataItem._dataContext.count, 'ev.target.dataItem.');
                        setCountValue(0)
                        setShowMoreCount(0)
                        setDataCount(ev.target.dataItem._dataContext.count)
                        setCriticalityValue(ev.target.dataItem.category)

                        setTable(true)
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request":
                                    ["GetTskDistributionStatusDrillDown",
                                        {
                                            "c_ids": [
                                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                            ],
                                            "d_ids": dIds,
                                            "filter_type": fieldType != "" ? fieldType : "Group",
                                            "filter_ids": [
                                                fieldType == "LegalEntity" ? filterData.legalentity :
                                                    fieldType == 'Division' ? Number(filterData.division) :
                                                        fieldType == 'Category' ? Number(filterData.category) :
                                                            fieldType == 'Unit' ? Number(filterData.unit) :
                                                                fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                    1
                                            ],
                                            "record_count": 0,
                                            "applicability_status": ev.target.dataItem.category,
                                            "le_ids":
                                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                            ,
                                            "country_domains": countryDomain,
                                            "chart_year": year
                                        }
                                    ]
                            }
                        ]
                        getTaskDistributionTable({
                            payload: payload,
                            paramid: paramid
                        })
                    });


                    var title = x.titles.create();
                    title.text = generatedOn === true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                    title.disabled = false;
                    title.align = 'left'
                    title.marginTop = '2%'
                    title.marginLeft = '2%'

                    var title1 = x.titles.create();
                    title1.text =
                        ((fieldType == "LegalEntity" && go == true && type === '') ? `Task Distribution of Legal Entity [bold]${filterDataNames.legalentity} (${year})[/]` : (
                            (fieldType == "Division" && go == true && type === '') ? `Task Distribution of Division [bold]${filterDataNames.division} (${year})[/]` : (
                                (fieldType == "Category" && go == true && type === '') ? `Task Distribution of Category [bold]${filterDataNames.category} (${year})[/]` : (
                                    (fieldType == "BusinessGroup" && go == true && type === '') ? `Task Distribution of Business Group [bold]${filterDataNames.business} (${year})[/]` : (
                                        (fieldType == "Unit" && go == true && type === '') ? `Task Distribution of Unit [bold]${filterDataNames.unit} (${year})[/]` : `Task Distribution of ${commonData && commonData.g_name} (${year})`
                                    )))
                        ))
                    title1.disabled = false;
                    title1.align = 'center'
                    title1.marginTop = '5%'
                    title1.marginLeft = '2%'
                    // title1.fontWeight = 'bold'

                    // <b><p>Country-Domain</p></b>
                    // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                    var label = x.createChild(am4core.Label);
                    label.text = "Country-Domain";
                    label.fontSize = 14;
                    label.align = "left";
                    label.marginLeft = '2%';
                    label.marginBottom = '2%'
                    label.fontWeight = 'bold'

                    var label1 = x.createChild(am4core.Label);
                    label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                    label1.fontSize = 14;
                    label1.align = "left";
                    label1.marginLeft = '2%';
                    label1.marginBottom = '5%'

                    var label2 = x.createChild(am4core.Label);
                    label2.text = `Total Task : ${total}`;
                    label2.fontSize = 14;
                    label2.align = "center";
                    label2.marginLeft = '2%';
                    label2.marginBottom = '4%'
                    label2.fontWeight = 'bold'

                }

                addChart([{
                    "task": "Assigned",
                    "count": data && data.length > 0 && data[0].assigned_count,
                    "color": am4core.color("#00a3a3")
                }, {
                    "task": "Not Opted",
                    "count": data && data.length > 0 && data[0].not_opted_count,
                    "color": am4core.color("#dd070c")
                },
                {
                    "task": "Unassigned",
                    "count": data && data.length > 0 && data[0].unassign_count,
                    "color": am4core.color("#ff9c80")
                },
                {
                    "task": "Not Applicable",
                    "count": data && data.length > 0 && data[0].not_applicable_count,
                    "color": am4core.color("#808080")
                }]);

            }
        }

    }, [data, timeZone, timeZoneData, table, localStorage.getItem('chart')])

    return (
        <div>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                <Link>Dashboard</Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                                <Link>Task Distribution Chart</Link>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            {table == false ?
                                <div className="col-md-2" style={{ marginTop: '1%' }}>
                                    <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '50%' }} onClick={() => {
                                        setOpen(true)
                                    }}
                                        size='default' >
                                        <Tooltip title='Filter'>Filter</Tooltip>
                                    </Button>
                                </div> : ''}
                        </div>
                    </div>
                </div>
                {
                    table == true ?
                        <div className="container-fluid pt-1">
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className="col-md-4">
                                        <p><b>{`${commonData && commonData.g_name} - ${criticalityValue} Compliance`}</b></p>
                                    </div>
                                    <div className="col-md-4">

                                    </div>
                                    <div className="col-md-4">
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginLeft: '70%' }}
                                            icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                                setTable(false)
                                            }}>
                                            <Tooltip title='Back'>Back</Tooltip>
                                        </Button>
                                    </div>
                                </div>
                            </div><br />
                            <Collapse defaultActiveKey={['0']}>
                                {taskDistributionTable && taskDistributionTable.length > 0 &&
                                    taskDistributionTable.map((item, i) => {
                                        let unitName = item.ap_compliances
                                        let keys = Object.keys(unitName);
                                        let values = Object.values(unitName);


                                        return (
                                            <Panel header={item.level1_statutory_name}>
                                                {keys && keys.length > 0 && keys.map((items, j) => {
                                                    console.log(values[j], 'values[j]');
                                                    let tempArr = []
                                                    if (values && values.length > 0) {
                                                        for (let m in values[j]) {
                                                            tempArr.push({
                                                                'index': count,
                                                                'ageing': values[j][m].ageing,
                                                                'assignee_name': values[j][m].assignee_name,
                                                                'bg_name': values[j][m].bg_name,
                                                                'comp_name': values[j][m].comp_name,
                                                                'comp_id': values[j][m].comp_id,
                                                                'frequency': values[j][m].frequency,
                                                                'compfielaw_url': values[j][m].compfielaw_url,
                                                                'criticality': values[j][m].criticality,
                                                                'descp': values[j][m].descp,
                                                                'is_active': values[j][m].is_active,
                                                                'div_name': values[j][m].div_name,
                                                                'p_cons': values[j][m].p_cons,
                                                                'remarks': values[j][m].remarks,
                                                                's_prov': values[j][m].s_prov,
                                                                'statu_dates': values[j][m].statu_dates,
                                                                'summary': values[j][m].summary,
                                                                // 'index': values[j][m].,
                                                                'le_name': values[j][m].le_name,
                                                                'download_url_list': values[j][m].download_url_list
                                                            })
                                                            { <span hidden>{count = count + 1}</span> }
                                                            // childDatas[i] = []
                                                        }
                                                        console.log(tempArr, 'tempArr');
                                                    }
                                                    values[j] = tempArr
                                                    return (
                                                        <>
                                                            <p><b>{keys[j]}</b></p>

                                                            <Table
                                                                size={'small'}
                                                                columns={columns}
                                                                dataSource={values[j]}
                                                                bordered
                                                                scroll={{ x: 1500 }}
                                                                pagination={false}
                                                                onChange={handleChange}


                                                            />
                                                        </>
                                                    )

                                                })}


                                            </Panel>
                                        )
                                    })
                                }
                            </Collapse>
                            {dataCount == showmoreCount ? false : <div className='col-md-12 mt-2'>
                                <div className='row'>
                                    <div className='col-md-4'></div>
                                    <div className='col-md-4'>
                                        <button className="btn btn-success" style={{ float: "center", marginLeft: "50px" }}
                                            onClick={showmore}
                                        >Show More</button>
                                    </div>
                                    <div className='col-md-4'></div>
                                </div>
                            </div>
                            }


                            {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                    className='userprivclass' /> */}
                        </div> :
                        <div className="container-fluid pt-1">
                            <div className="row" >
                                <div className="col-md-12">

                                </div>
                                {(data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0)) ?

                                    <p style={{ marginTop: '18%', marginLeft: '35%', marginBottom: '18%' }}>No Data to Display </p> :
                                    <>
                                        <div className="col-md-12 mt-3">
                                            <>
                                                <div id="container" style={{ width: "100%", marginTop: '10px', height: "400px" }}></div>
                                                <div className="col-md-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-3">
                                                        </div>
                                                        <div className="col-md-6" style={{ marginLeft: '70%' }}>
                                                            <label htmlFor=""><b>Time Zone :</b></label>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                onChange={(data) => {
                                                                    setTimeZone(data)
                                                                    setGeneratedOn(true)
                                                                    const payload = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetDateTime_ByZone",
                                                                                {
                                                                                    "tz_name": data
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    getTimeZoneData({
                                                                        payload: payload,
                                                                        paramid: paramid
                                                                    })

                                                                }}
                                                                placeholder="Enter Time Zone"
                                                                style={{ marginLeft: '5%' }}
                                                            // value={compfie.user_Group}

                                                            >

                                                                {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                    return (

                                                                        <Option key={item.tz_name}>

                                                                            {item.tz_name}

                                                                        </Option>

                                                                    );

                                                                })}
                                                                {/* );
                                                })} */}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        </div>

                                    </>}
                            </div>
                        </div>
                }
                <Drawer title="FILTER BY" placement="right" closable={false} onClose={false} visible={open}
                    extra={
                        <Space>
                            <CloseCircleOutlined style={{ marginTop: '2%', fontSize: '140%' }} onClick={onClose} />
                        </Space>
                    }
                >
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Country</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Country"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.country ? filterData.country : undefined}
                                    onChange={(data, values) => {
                                        setFilterData({
                                            ...filterData,
                                            country: data,
                                            domain: ''
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            country: values.children,
                                            domain: '',
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            business: '',
                                            category: '',
                                            unit: ''
                                        })
                                        setType('')

                                    }
                                    }
                                    onDeselect={(data) => {

                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {country_info && country_info.length > 0 && country_info.map((item, i) => {
                                        return (
                                            <Option key={item.c_id}>
                                                {item.c_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Domain</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.domain ? filterData.domain : undefined}
                                    // onChange={(data, values) => {
                                    //     console.log(data,'data-domain');
                                    //     let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(data) })
                                    //     let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(data) })
                                    //     // let filteredCategory = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     // let filteredUnit = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(data)))
                                    //     let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
                                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                    //     let temp = []
                                    //     temp.push({
                                    //         'bg_id': filteredCategory[0].bg_id,
                                    //         'c_id': filteredCategory[0].c_id,
                                    //         'cat_id': filteredCategory[0].cat_id,
                                    //         'cat_name': filteredCategory[0].cat_name,
                                    //         'd_id': filteredCategory[0].d_id,
                                    //         'div_id': filteredCategory[0].div_id,
                                    //         'le_id': filteredCategory[0].le_id,
                                    //         'div_name': division[0].div_name
                                    //     })
                                    //     setCategory(temp)
                                    //     setUnit(filteredUnit)
                                    //     setfield({
                                    //         ...field,
                                    //         legalEntity: filteredLegalEntity,
                                    //         business: filteredBusinessGroup
                                    //     })
                                    //     let temp1=[]
                                    //     for(let i in commonData.d_info){
                                    //         temp1.push(commonData.d_info[i].d_id)
                                    //     }
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         domain: temp1
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         domain: values.children,
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setDivision(filteredDivision)
                                    //     // let temp = []
                                    //     // temp.push({
                                    //     //     'bg_id': filteredCategory[0].bg_id,
                                    //     //     'c_id': filteredCategory[0].c_id,
                                    //     //     'cat_id': filteredCategory[0].cat_id,
                                    //     //     'cat_name': filteredCategory[0].cat_name,
                                    //     //     'd_id': filteredCategory[0].d_id,
                                    //     //     'div_id': filteredCategory[0].div_id,
                                    //     //     'le_id': filteredCategory[0].le_id,
                                    //     //     'div_name': filteredDivision[0].div_name
                                    //     // })
                                    //     // setCategory(temp)
                                    //     // setUnit(filteredUnit)
                                    // }
                                    // }
                                    onChange={(option) => HandelChange(option)}
                                    // onDeselect={(data) => {
                                    //     console.log(data,'data-deselect');
                                    //     filterData && filterData.domain.splice(data,1)
                                    // }}
                                    style={{ width: '100%' }}
                                    disabled={filterData.country == '' ? true : false}
                                >
                                    {domainListData && domainListData.length > 0 &&
                                        domainListData.map((finalList_unitlist) => (
                                            <OptGroup label={finalList_unitlist.label}>
                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                ))}
                                            </OptGroup>
                                        ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-1">
                                <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                    setType('Group')
                                    setCheckBox(!checkBox)
                                }}></input>
                            </div>
                            <div className="col-md-4">
                                <label><b>Group</b></label>
                            </div>
                            {/* <div className="col-md-2">

                        </div> */}
                            <div className="col-md-1">
                                {/* <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input> */}
                            </div>
                            <div className="col-md-4">
                                {/* <label><b>Consolidated</b></label> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-6">

                                <label><b>From Date</b></label>
                                <DatePicker
                                    disabled={true}
                                    allowClear={() => {
                                        setFilterData({
                                            ...filterData,
                                            from_date: null,
                                        })
                                    }}
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        setFilterData({
                                            ...filterData,
                                            from_date: dateString,
                                        })

                                    }}

                                />

                            </div>
                            <div className="col-md-6">

                                <label><b>To Date</b></label>

                                <DatePicker
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    // onChange={(date, dateString) => {

                                    //     console.log(dateString, 'dateString');
                                    //     setConsolidatedData({
                                    //         ...consolidatedData,
                                    //         from_date: dateString,
                                    //         to_date: moment(dateString).add(3, 'months')
                                    //     })

                                    // }}
                                    disabled={true}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        if (dateString != undefined) {
                                            setFilterData({
                                                ...filterData,
                                                to_date: dateString,
                                            })
                                        }
                                        else {
                                            setFilterData({
                                                ...filterData,
                                                to_date: null,
                                            })
                                        }


                                    }}

                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    value={filterDataNames.business ? filterDataNames.business : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { bg_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        setfield({
                                            ...field,
                                            legalEntity: filteredEntity
                                        })
                                        setFieldType('BusinessGroup')
                                        setFilterData({
                                            ...filterData,
                                            business: data,
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            business: values.children,
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        setUnit(filteredUnit)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}
                                >
                                    {field && field.business && field.business.length && field.business.map((item, i) => {
                                        return (
                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>
                                        );
                                    })}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    value={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    placeholder="Enter Legal Entity"
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: data })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { le_id: data })
                                        let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        setLegalEntityArray(filteredLegalEntity)
                                        // setfield({
                                        //     ...field,
                                        //     legalEntity: filteredLegalEntity
                                        // })
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                            setUnit(filteredUnit)
                                        }
                                        else {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: data })
                                            console.log(filteredUnit, 'filteredUnit555');
                                            setUnit(filteredUnit)
                                        }

                                        console.log(filteredDivision, 'filteredDivisionLeg');
                                        setFilterData({
                                            ...filterData,
                                            legalentity: data
                                        })
                                        setFieldType('LegalEntity')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            legalentity: values.children,
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        // setUnit(filteredUnit)
                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                        return (
                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    value={filterDataNames.division ? filterDataNames.division : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.find(commonData && commonData.div_infos, { div_id: Number(data) })
                                        let getLEname = _.find(commonData && commonData.le_did_infos, { le_id: filteredDivision.le_id })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(data) })
                                        setFilterData({
                                            ...filterData,
                                            division: data
                                        })

                                        // let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        // setDivisionObj(filteredDivision)
                                        // setLEObj(getLEname)
                                        // setCategory(subData)
                                        setFieldType('Division')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            division: values.children,
                                            unit: '',
                                            category: '',
                                        })
                                        setUnit(filteredUnit)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision.div_name
                                        })
                                        setCategory(temp)

                                        console.log(category, 'catStateeeee');

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {divisionListData && divisionListData.length > 0 &&
                                        divisionListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.div_id}>{subUnitData.div_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }

                                        }
                                        )}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    value={filterDataNames.category ? filterDataNames.category : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            category: data,
                                            unit: '',
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            category: values.children
                                        })
                                        let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                        setUnit(subData)
                                        setFieldType('Category')
                                        setGo(false)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {categoryListData && categoryListData.length > 0 &&
                                        categoryListData.map((finalList_unitlist) => {
                                            console.log(finalList_unitlist, 'finalList_unitlist');
                                            if (finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Unit"
                                    value={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            unit: data
                                        })
                                        setFieldType('Unit')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            unit: values.children
                                        })

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {unitListData && unitListData.length > 0 &&
                                        unitListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '15%', marginTop: '10%' }}
                        onClick={filterChange}
                        size='default' >
                        Go
                    </Button>
                    {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                        onClick={() => {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusChart",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "filter_type": "Group",
                                                "filter_ids": [
                                                    1
                                                ],
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                    commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                ],
                                                "country_domains": [
                                                    {
                                                        "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                    }
                                                ],
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getCriticalitychart({
                                payload: payload,
                                paramid: paramid
                            })
                            setOpen(false)

                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                country: '',
                                domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: ''
                            })
                        }}
                        size='default' >
                        Reset
                    </Button> */}
                </Drawer>
            </div>
        </div>

    )


}

export default connect(mapStateToProps, {
    getCommonData,
    getTaskDistributionchart,
    getTaskDistributionTable,
    getTimeZoneData,
    getTaskDistributionShowMoreTableData
})(TaskDistribution);

