import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, { getUrl } from './../../../Libs/URL'
import EncryptDecrypt from '../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { GET_DOMAIN_DATA, GET_LE_TABLE_DATA, TOGGLE_COMMON_LOADER } from '../../types/index'
import { toast } from 'react-toastify';
import alertmessage from '../../../Libs/Altermessage';
const urls = getUrl()


export const LegalEntityScoreCardData = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_reports`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        }
        else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptDatassss");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: GET_DOMAIN_DATA,
                    payload: decryptData[1],
                });
            }
            // }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const GetLEScoreReportData = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_reports`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        }
        else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptDatassss");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: GET_LE_TABLE_DATA,
                    payload: decryptData[1],
                });
            }
            // }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

// export const getExportData = ({ payload, executeCancel, paramid }) => async dispatch => {
//     try {
//         dispatch({
//             type: TOGGLE_COMMON_LOADER
//         });
//         const encryptkey = localStorage.getItem('formkey')
//         const head = {
//             headers: {
//                 'Caller-Name': paramid
//             },
//         }
//         let encoded = base64_decode(encryptkey);
//         const data1 = EncryptDecrypt.encryptdata(payload, encoded);
//         const createRequest = HTTP.commonpost({
//                       url: urls + `/api/client_reports`,
//             headers: head,
//             executeCancel,
//             body: data1

//         })
//         const executeRequest = await HTTP.execute(createRequest)
//         if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
//             executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
//             (executeRequest.status === 400 || executeRequest.status === 401)) {
//             Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
//         }
//         else {
//             const response = HTTP.parseResponse(executeRequest)
//             const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
//             console.log(decryptData, "decryptData");
//             if (decryptData[0] == 'ExportToCSVSuccess') {
//                 toast.success('Export process is initiated.Yow will receive messages once file generated')
//                 setTimeout(() => {
//                     window.location.reload(false);
//                 }, 2000);
//             }
//             // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
//             dispatch({
//                 type: TOGGLE_COMMON_LOADER
//             });
//             dispatch({
//                 type: EXPORT_DATA,
//                 payload: decryptData[1],
//             });

//             // }
//         }


//     } catch (err) {
//         Toaster.error(err)
//         dispatch({
//             type: TOGGLE_COMMON_LOADER
//         });
//     }
// };
