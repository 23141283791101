import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { Card, Divider, Tabs, Steps, Radio, Input, Row, Col, Select, Button, Tooltip } from 'antd';
import { ArrowRightOutlined, CheckOutlined, CloseOutlined, ArrowLeftOutlined, PlusCircleTwoTone, PlusOutlined, PlayCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { getUsermanagementDetails, getById, addUserDetails, selectHaveCompliance, addUserManagementDetails, EdithaveCompliance, edituserManagementDetails, getUsermanagementList } from './../../Store/Action/Master/UserManagement'
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";
import { Toaster } from '../../Libs/Toaster'
// import Usermanagement from "../Master/Usermanagement"
import Usermanagement from './Usermanagement';
import { blockInvalidChar, allowAlpha } from '../../Libs/Blockinvalidcharacter';



const _ = require("lodash");
const mapStateToProps = (state) => ({
    usermanagementList: state.UserManagement.userManagement,

})

const AddUserManagement = ({
    getUsermanagementDetails,
    getById,
    addUserDetails,
    selectHaveCompliance,
    AddType,
    setAddType,
    EdithaveCompliance,
    addUserManagementDetails,
    // setShowList,
    details,
    showList,
    edituserManagementDetails,
    usermanagementList: { userManagementList, UserLegalDetails, userManagementDetails, GetUserDetails, haveCompliance, count }
}) => {
    const previous = () => {
        setTabValue({
            ...tabValue,
            compfie: false,
            compfie_litigation: false,
            compfie_vendor: false
        })
        setEntityValue([])
        setLitigationEntityValue([])
        setVendoreEntityValue([])
        setdomainValue([])
        setlitigationdomainValue([])
        setvendordomainValues([])
        setCategoryValues([])
        setVendorCategoryValues([])
        setDivisionValue([])
        setvendorDivisionValue([])

        setShowList(true)
        return (
            <>
                <Usermanagement showList={showList1} setShowList={setShowList} />
            </>

        )
    }
    console.log(haveCompliance, 'haveCompliance');
    console.log(GetUserDetails, 'GetUserDetails');
    console.log(details, 'details');
    const [categoryList, setcategoryList] = useState([]);
    const [vendorcategoryList, setvendorcategoryList] = useState([]);
    const [unitListData, setUnitListData] = useState([]);
    const [vendorListData, setvendorListData] = useState([]);
    const [payloadEntityEdit, setpayloadEntityEdit] = useState([])
    const [payloadEditDomain, setPayloaEditdDomain] = useState([])
    const [payloadEditlitigationDomain, setpayloadEditlitigationDomain] = useState([])
    console.log(payloadEditlitigationDomain, 'payloadEditlitigationDomain');
    const [payloadEditVendorDomain, setPayloaEditVendordDomain] = useState([])
    const [payloadEditunit, setPayloadEditunit] = useState([])
    console.log(payloadEditunit, 'payloadEditunit');
    const [legalBg, setLegalBg] = useState([])
    const [litigationlegalBg, setLitigationLegalBg] = useState([])
    const [vendorlegalBg, setVendorLegalBg] = useState([])
    const [payloadEditClosedunit, setPayloadClosedEditunit] = useState([])
    console.log(payloadEditClosedunit, 'payloadEditClosedunit');
    console.log(payloadEditDomain, 'payloadEditDomain');
    console.log(payloadEntityEdit, 'payloadEntityEdit');
    const [bgTooltip, setBgToolTip] = useState([])
    const [vendorBgTooltip, setVendorBgToolTip] = useState([])
    const [litigationBgTooltip, setLitigationBgTooltip] = useState([])
    const [entityTooltip, setEntityToolTip] = useState([])
    const [vendorEntityTooltip, setVendorEntityToolTip] = useState([])
    const [litigationEntityTooltip, setLitigationEntityTooltip] = useState([])
    const [domainTooltip, setDomainToolTip] = useState([])
    const [vendorDomainTooltip, setVendorDomainToolTip] = useState([])
    const [litigationDomainTooltip, setLitigationDomainTooltip] = useState([])
    const [divisionTooltip, setDivisionToolTip] = useState([])
    const [vendordivisionTooltip, setVendorDivisionToolTip] = useState([])
    const [catTooltip, setCatTooltip] = useState([])
    const [vendorcatTooltip, setVedorCatTooltip] = useState([])
    console.log(catTooltip, 'catTooltip');
    const [payloadLitigationEntityEdit, setpayloadLitigationEntityEdit] = useState([])
    console.log(payloadLitigationEntityEdit, 'payloadLitigationEntityEdit');
    const [payloadVendorEntityEdit, setpayloadVendorEntityEdit] = useState([])
    console.log(payloadVendorEntityEdit, 'payloadVendorEntityEdit');
    const [unitAccess, setunitAccess] = useState(false);
    const [vendorAccess, setVendorAccess] = useState(false);
    console.log(vendorAccess, 'vendorAccess');
    console.log(unitAccess, 'unitAccess');
    const [LegalEntityList, setLegalEntityList] = useState([]);
    const [LitigationLegalEntityList, setLitigationLegalEntityList] = useState([]);
    const [DomainListData, setDomainListData] = useState([]);
    const [editEntity, setEditEntity] = useState([])
    const [editEntityValue, setEditEntityValue] = useState([])
    const [allUnitData, setAllUnitData] = useState([])
    const [allvendorData, setAllVendorData] = useState([])
    console.log(allvendorData, 'allvendorData');
    const [vendorAllUnitData, setvendorAllUnitData] = useState([])
    const [LitigationDomainList, setLitigationDomainList] = useState([]);
    const [VendorDomainList, setVendorDomainList] = useState([]);
    const [vendorEntityList, setVendorLegalEntityList] = useState([]);
    console.log(unitListData, 'unitListData');
    const [usergroupcompfie, setUserGroupCompfie] = useState([])
    const [vendorgroupcompfie, setVendorgroupcompfie] = useState([])
    const [showList1, setShowList] = useState(false)
    const { Option, OptGroup } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const [all, setAll] = useState([]);
    const [vendorAll, setVendorAll] = useState([])
    console.log(vendorAll, 'vendorAll');
    const [entityValue, setEntityValue] = useState([])
    const [businessGroupData, setBusinessGroupData] = useState([])
    const [businessGroupValue, setbusinessGroupValue] = useState([])
    const [unitDatas, setunitDatas] = useState([])
    const [CloseUnitDAta, setCloseUnitDAta] = useState([])
    const [vendorunitDatas, setvendorunitDatas] = useState([])
    const [vendorCloseUnitDAta, setvendorCloseUnitDAta] = useState([])
    const [litigationbusinessGroupData, setlitigationbusinessGroupData] = useState([])
    const [lititigationbusinessGroupValue, setlititigationbusinessGroupValue] = useState([])
    console.log(lititigationbusinessGroupValue, 'lititigationbusinessGroupValue');
    const [vendorbusinessGroupData, setVendorBusinessGroupData] = useState([])
    const [vendorbusinessGroupValue, setVendorbusinessGroupValue] = useState([])
    const [litigationEntityValue, setLitigationEntityValue] = useState([])
    console.log(litigationEntityValue, 'litigationEntityValue');
    const [vendoreEntityValue, setVendoreEntityValue] = useState([])
    console.log(vendoreEntityValue, 'vendoreEntityValue');
    const [vendorClosedAll, setVendorClosedAll] = useState([])
    const [domainValue, setdomainValue] = useState([])
    console.log(domainValue, 'domainValuedomainValuedomainValuedomainValue');
    const [litigationdomainValue, setlitigationdomainValue] = useState([])
    const [vendordivisionData, setvendordivisionData] = useState([])
    const [divisionData, setdivisionData] = useState([])
    const [vendordomainValue, setvendordomainValues] = useState([])
    console.log(vendordomainValue, 'vendordomainValue');
    const [vendorDivisionValue, setvendorDivisionValue] = useState([])
    const [DivisionValue, setDivisionValue] = useState([])
    const [categoryValues, setCategoryValues] = useState([])
    const [VendorcategoryValues, setVendorCategoryValues] = useState([])
    const [closedAll, setclosedAll] = useState([]);
    const [selectcheckbox, Setselectcheckbox] = useState('block');
    const [unselectcheckbox, Setunselectcheckbox] = useState('none');
    const [domainEntity, setdomainEntity] = useState([])
    const [litigationdomainEntity, setlitigationdomainEntity] = useState([])
    const [vendordomainEntity, setvendordomainEntity] = useState([])
    console.log(domainEntity, 'domainEntitydomainEntity');
    const [LitigationEntityselectcheckbox, SetLitigationEntityselectcheckbox] = useState('block');
    const [LitigationEntitysunselectcheckbox, SetLitigationEntityunselectcheckbox] = useState('none');
    const [VendorEntityselectcheckbox, SetVendorEntityselectcheckbox] = useState('block');
    const [VendorEntityunselectcheckbox, SetVendorEntityunselectcheckbox] = useState('none');
    const [domainselectcheckbox, SetDomainselectcheckbox] = useState('block');
    const [domainunselectcheckbox, SetDomainunselectcheckbox] = useState('none');
    const [litigationdomainselectcheckbox, Setlitigationdomainselectcheckbox] = useState('block');
    const [litigationdomainunselectcheckbox, SetlitigationDomainunselectcheckbox] = useState('none');
    const [vendordomianselectcheckbox, SetvendorDomainselectcheckbox] = useState('block');
    const [Vendordomainunselectcheckbox, Setvendorunselectcheckbox] = useState('none');
    const [categoryselectcheckbox, Setcategoryselectcheckbox] = useState('block');
    const [categoryunselectcheckbox, Setcategoryunselectcheckbox] = useState('none');
    const [divisionselectcheckbox, Setdivisionselectcheckbox] = useState('block');
    const [divisionunselectcheckbox, Setdivisionunselectcheckbox] = useState('none');
    const [vendorbusinessGroupselectcheckbox, SetvendorbusinessGroupselectcheckbox] = useState('block');
    const [vendorbusinessGroupUnselectcheckbox, SetvendorbusinessGroupUnselectcheckbox] = useState('none');
    const [businessGroupselectcheckbox, SetbusinessGroupselectcheckbox] = useState('block');
    const [businessGroupUnselectcheckbox, SetbusinessGroupUnselectcheckbox] = useState('none');
    const [litigationbusinessGroupselectcheckbox, SetlitigationbusinessGroupselectcheckbox] = useState('block');
    const [litigationbusinessGroupunselectcheckbox, SetlitigationbusinessGroupunselectcheckbox] = useState('none');
    const [vendordivisionselectcheckbox, Setvendordivisionselectcheckbox] = useState('block');
    const [vendordivisionunselectcheckbox, Setvendordivisionunselectcheckbox] = useState('none');
    const [vendorcategoryselectcheckbox, Setvendorcategoryselectcheckbox] = useState('block');
    const [vendorcategoryunselectcheckbox, Setvendoruncategoryselectcheckbox] = useState('none');
    const [unitselectcheckbox, Setunitselectcheckbox] = useState('block');
    const [unitunselectcheckbox, Setunitunselectcheckbox] = useState('none');
    const [closeunitselectcheckbox, Setclosedunitselectcheckbox] = useState('block');
    const [closedunitunselectcheckbox, Setclosedunitunselectcheckbox] = useState('none');
    const [vendorunitselectcheckbox, Setvendorunitselectcheckbox] = useState('block');
    const [vendorunitunselectcheckbox, Setvendorunitunselectcheckbox] = useState('none');
    const [vendorcloseunitselectcheckbox, Setvendorclosedunitselectcheckbox] = useState('block');
    const [vendorclosedunitunselectcheckbox, SetVendorclosedunitunselectcheckbox] = useState('none');
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const entityid = localStorage.getItem('SelectedEntityid');
    const { TabPane } = Tabs;
    const { Search } = Input;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [categoryFormSubmit, setCategoryFormSubmit] = useState(false);
    const [LitigationFormSubmit, setLitigationFormSubmit] = useState(false);
    const [active, setActive] = useState('1')
    const [selectUnit, setSelectUnit] = useState(false);
    const [closedUnitData, setClosedtUnitData] = useState([]);
    const [selectUnitData, setSelectUnitData] = useState([]);
    const [selectUnitList, setselectUnitList] = useState([]);
    const [closedselectUnitList, setclosedselectUnitList] = useState([]);
    console.log(closedselectUnitList, 'closedselectUnitList');
    const [vendorUnitList, setvendorUnitList] = useState([]);
    const [vendorClosedUnitList, setcvendorClosedUnitList] = useState([]);
    console.log(vendorClosedUnitList, 'vendorClosedUnitList');
    const [closedOption, setClosedOption] = useState([])
    const [categoryDatas, setCategoryDatas] = useState([])
    console.log(categoryDatas, 'categoryDatas');
    const [litigationcategoryDatas, setlitigationcategoryDatas] = useState([])
    const [vendorcategoryDatas, setvendorcategoryDatas] = useState([])

    const [EntityData, setEntityData] = useState([])
    const [litigationEntityData, setlitigationEntityData] = useState([])
    const [vendorEntityData, setvendorEntityData] = useState([])
    console.log(EntityData, 'EntityDataEntityDataEntityData');
    const [domainData, setDomainData] = useState([])
    const [litigationDomainData, setLitigationDomainData] = useState([])
    const [VendorDomainData, setVendorDomainData] = useState([])
    const [vendorcategory, setVendorCategory] = useState([
    ])

    const [unitEntity, setUnitEntity] = useState([])
    const [closedEntityUnit, setclosedEntityUnit] = useState([])
    const [vendorunitEntity, setvendorunitEntity] = useState([])
    const [vendorClosedunitEntity, setvendorClosedunitEntity] = useState([])
    const [domainname, setDomainName] = useState([])
    const [domainlist, setDomainList] = useState([])
    const [options, setOptions] = useState([{
        label: '',
        value: '',

    }])
    const [VendorEntityOption, setVendorEntityOption] = useState([{
        label: '',
        value: '',

    }])
    const [Entityoptions, setEntityOptions] = useState([{
        label: '',
        value: '',
    }])
    console.log(domainEntity, 'domainEntitydomainEntity');
    console.log(DivisionValue, 'DivisionValue');
    const [Domainoptions, setDomainOptions] = useState([{
        label: '',
        value: '',
        legal: "",
        u_dm_id: "",
        legal_id: ""
    }])
    const [VendorDomainoptions, setVendorDomainOptions] = useState([{
        label: '',
        value: '',
    }])
    const [LitigationDomainoptions, setLitigationDomainOptions] = useState([{
        label: '',
        value: '',
    }])
    const [categoryoptions, setCategoryOptions] = useState([{
        label: '',
        value: '',
    }])
    const [LitigationEntityoptions, setLitigationEntityOptions] = useState([{
        label: '',
        value: '',
    }])
    const [Vendoroptions, setVendorOptions] = useState([{
        label: '',
        value: '',

    }])
    const [vendorselected, setvendorSelected] = useState([]);
    const [vendorClosedOption, setvendorClosedOption] = useState([{
        label: '',
        value: '',

    }])
    const [DivisionOption, setDivisionOption] = useState([{
        label: '',
        value: '',

    }])
    const [divisions, setDivisions] = useState([])

    const [vendorcloseselected, setVendorCloseSelected] = useState([]);
    const [VendorEntity, setVendorEntity] = useState([])
    const [selected, setSelected] = useState([]);
    const [closedSelected, setClosedSelected] = useState([])
    const [DomainValue, setDomainValue] = useState([]);
    const [VendorDomainValue, setvendorDomainValue] = useState([]);
    const [LitigationDomainValue, setLitigationDomainValue] = useState([]);
    const [domainPayload, setDomainPayload] = useState([])
    const [litigationdomainPayload, setLitigationDomainPayload] = useState([])
    const [vendordomainPayload, setVendorDomainPayload] = useState([])
    console.log(LitigationDomainValue, "VendorDomainValue");
    console.log(usergroupcompfie, "usergroupcompfie");
    const [compfieCategory, setcompfieCategory] = useState([]);
    const [closeselected, setCloseSelected] = useState([]);
    const [vendorcategories, setVendorCategories] = useState([
    ])
    const [legalEntity, setLegalEntity] = useState([])
    const [vendorlegalEntity, setvendorLegalEntity] = useState([])
    console.log(vendorlegalEntity, 'vendorlegalEntity');
    console.log(legalEntity, 'legalEntity');
    const [litigationlegalEntity, setlitigationLegalEntity] = useState([])
    const [tab, setTab] = useState()
    const [tabboolean, setTabBoolean] = useState(false)
    const [domain, setDomain] = useState([])
    const [userDetails, setUserDetails] = useState({
        emp_name: '',
        emp_contact_country_code: '',
        emp_contact_code: '',
        emp_contact_no: '',
        emp_id: '',
        mobile_no_country_code: '',
        mobile_no: '',
        email: '',
        remarks: '',
        emp_code: '',
        otp: 0

    })
    const [tabValue, setTabValue] = useState({
        compfie: false,
        compfie_litigation: false,
        compfie_vendor: false
    })
    const [mainTab, setMainTab] = useState(false)
    const [category, setCategory] = useState({
        compfie: '',
        compie_litigation: '',
        compfie_vendor: ''
    })

    const [compfie, setCompfie] = useState({
        category: null,
        seating_Unit: null,
        user_Group: '',
        user_level: '1',
        entity: '',
        serviceProvider: '',
        checked_value: '',
        legal_Entity: [],
        Domain: [],
        domain_name: "",
        Division: '',
        businessGroup: ''

        // domain:'',

    })
    const [compfieVendorValue, setCompfieVendorValue] = useState({
        category: null,
        seating_Unit: null,
        user_Group: null,
        user_level: '1',
        entity: '',
        serviceProvider: null,
        checked_value: '',
        legal_Entity: [],
        Domain: [],
        Division: '',

        // domain:'',

    })
    const [compfieLitigation, setCompfieLitigation] = useState({
        category: null,
        user: AddType == 'edit' ? "Inhouse User" : '',
        legal_Entity: [],
        Domain: [],
        businessGroup: '',
        serviceProvider: ''
    })
    const [compfieDomain, setCompfieDomain] = useState([])
    const [vendorDomain, setVendorDomain] = useState([])
    const [categoryValue, setCategoryValue] = useState({
        category: [],
        seatingUnit: [],
        serviceProvider: [],
        legalEnity: [],
        userGroup: [],
        domain: []
    })
    const [compfieLitigationCategory, setcompfieLitigationCategory] = useState({
        // category: [],
        // seatingUnit: [],
        // serviceProvider: [],
        legalEnity: [],
        // userGroup: [],
        domain: []
    })
    const [EditCompfieVendor, setEditCompfieVendor] = useState({
        category: [],
        seatingUnit: [],
        legalEnity: [],
        userGroup: [],
        domain: []
    })
    const [vendorUnitDetails, setVendorUnitDetails] = useState([])
    console.log(vendorUnitDetails, 'vendorUnitDetails');
    const [vendorClosedUnitDetails, setVendorClosedUnitDetails] = useState([])
    const [vendorUnit, setVendorUnit] = useState([])
    const [vendorClosedUnit, setVendorClosedUnit] = useState([])
    const [value, setValue] = useState({
        userGroup: '',
        seatingUnit: '',
        userLevel: '',
        businessGroup: '',
        legalEnity: "",
        category: '',
        domain: '',
        division: ''
    })

    const [userUnits, setUserUnits] = useState([

    ])
    const [userClosedUnits, setClosedUserUnits] = useState([

    ])
    console.log(userClosedUnits, 'userClosedUnits');
    console.log(userUnits, 'userUnits');
    const validator = useRef(new SimpleReactValidator());
    const Formvalidator = useRef(new SimpleReactValidator());
    const LitigationFormvalidator = useRef(new SimpleReactValidator());
    console.log(legalEntity, 'legalEntitylegalEntity');
    const onChange = (e) => {
        setUserDetails({
            ...userDetails,
            otp: e.target.value
        })
    };


    const onUserDetailOnChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value
        })
    }
    console.log(domainEntity, 'domainEntitydomainEntitydomainEntity');

    console.log(litigationdomainValue, 'litigationdomainValue1233444');
    console.log(compfieLitigation, 'compfieLitigation12333');
    console.log(divisionData, 'divisionData');
    console.log(domainValue, 'domainValue');
    console.log(GetUserDetails && GetUserDetails.ul_user_units.length, 'GetUserDetails && GetUserDetails.ul_user_units.length');
    useEffect(() => {
        if (compfie.category == '2' || compfie.category == '7' || compfie.category == "View Only" || compfie.category == "View OnlyResticted") {
            if (allUnitData && allUnitData.length > 0) {
                if (((allUnitData.length) == (userManagementDetails && userManagementDetails.um_legal_units.length))) {
                    setunitAccess(true)
                }

            }
            if (GetUserDetails && GetUserDetails.ul_user_units && GetUserDetails.ul_user_units.length == 0) {
                setunitAccess(true)
            }
        }
    }, [allUnitData, GetUserDetails, compfie.category])
    useEffect(() => {
        let temp = []
        if (businessGroupValue.length > 0) {
            for (let i in businessGroupValue) {
                let data = _.filter(businessGroupData, { bg_id: Number(businessGroupValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].bg_name)
                }

            }
            if (temp.length > 0) {
                setBgToolTip(temp.join(','))
            }

        }
    }, [businessGroupValue, businessGroupData])
    useEffect(() => {
        let temp = []
        if (lititigationbusinessGroupValue.length > 0) {
            for (let i in lititigationbusinessGroupValue) {
                let data = _.filter(litigationbusinessGroupData, { bg_id: Number(lititigationbusinessGroupValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].bg_name)
                }

            }
            if (temp.length > 0) {
                setLitigationBgTooltip(temp.join(','))
            }

        }
    }, [lititigationbusinessGroupValue, litigationbusinessGroupData])
    useEffect(() => {
        let temp = []
        if (vendorbusinessGroupValue.length > 0) {
            for (let i in vendorbusinessGroupValue) {
                let data = _.filter(vendorbusinessGroupData, { bg_id: Number(vendorbusinessGroupValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].bg_name)
                }

            }
            if (temp.length > 0) {
                setVendorBgToolTip(temp.join(','))
            }

        }
    }, [vendorbusinessGroupValue, vendorbusinessGroupData])
    useEffect(() => {
        let temp = []
        if (entityValue.length > 0) {
            for (let i in entityValue) {
                let data = _.filter(EntityData, { le_id: entityValue[i] })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].le_name)
                }

            }
            if (temp.length > 0) {
                setEntityToolTip(temp.join(','))
            }

        }
    }, [entityValue, EntityData])
    useEffect(() => {
        let temp = []
        if (litigationEntityValue.length > 0) {
            for (let i in litigationEntityValue) {
                let data = _.filter(litigationEntityData, { le_id: litigationEntityValue[i] })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].le_name)
                }

            }
            if (temp.length > 0) {
                setLitigationEntityTooltip(temp.join(','))
            }

        }
    }, [litigationEntityValue, litigationEntityData])
    useEffect(() => {
        let temp = []
        if (vendoreEntityValue.length > 0) {
            for (let i in vendoreEntityValue) {
                let data = _.filter(vendorEntityData, { le_id: vendoreEntityValue[i] })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].le_name)
                }

            }
            if (temp.length > 0) {
                setVendorEntityToolTip(temp.join(','))
            }

        }
    }, [vendoreEntityValue, vendorEntityData])
    useEffect(() => {
        let temp = []
        if (domainPayload.length > 0) {
            for (let i in domainPayload) {
                let data = _.filter(domainData, { u_dm_id: Number(domainPayload[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_dm_name)
                }

            }
            if (temp.length > 0) {
                setDomainToolTip(temp.join(','))
            }

        }
    }, [domainPayload, domainData])
    useEffect(() => {
        let temp = []
        if (litigationdomainPayload.length > 0) {
            for (let i in litigationdomainPayload) {
                let data = _.filter(litigationDomainData, { u_dm_id: Number(litigationdomainPayload[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_dm_name)
                }

            }
            if (temp.length > 0) {
                setLitigationDomainTooltip(temp.join(','))
            }

        }
    }, [litigationdomainPayload, litigationDomainData])
    useEffect(() => {
        let temp = []
        if (vendordomainPayload.length > 0) {
            for (let i in vendordomainPayload) {
                let data = _.filter(VendorDomainData, { u_dm_id: Number(vendordomainPayload[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_dm_name)
                }

            }
            if (temp.length > 0) {
                setVendorDomainToolTip(temp.join(','))
            }

        }
    }, [vendordomainPayload, VendorDomainData])
    useEffect(() => {
        let temp = []
        if (DivisionValue.length > 0) {
            for (let i in DivisionValue) {
                let data = _.filter(divisionData, { d_id: Number(DivisionValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].d_name)
                }

            }
            if (temp.length > 0) {
                setDivisionToolTip(temp.join(','))
            }

        }
    }, [DivisionValue, divisionData])
    useEffect(() => {
        let temp = []
        if (vendorDivisionValue.length > 0) {
            for (let i in vendorDivisionValue) {
                let data = _.filter(vendordivisionData, { d_id: Number(vendorDivisionValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].d_name)
                }

            }
            if (temp.length > 0) {
                setVendorDivisionToolTip(temp.join(','))
            }

        }
    }, [vendorDivisionValue, vendordivisionData])
    useEffect(() => {
        let temp = []
        if (categoryValues.length > 0) {
            for (let i in categoryValues) {
                let data = _.filter(categoryDatas, { cat_id: Number(categoryValues[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].cat_name)
                }

            }
            if (temp.length > 0) {
                setCatTooltip(temp.join(','))
            }

        }
    }, [categoryValues, categoryDatas])
    useEffect(() => {
        let temp = []
        if (VendorcategoryValues.length > 0) {
            for (let i in VendorcategoryValues) {
                let data = _.filter(vendorcategoryDatas, { cat_id: Number(VendorcategoryValues[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].cat_name)
                }

            }
            if (temp.length > 0) {
                setVedorCatTooltip(temp.join(','))
            }

        }
    }, [VendorcategoryValues, vendorcategoryDatas])
    useEffect(() => {
        if (compfieVendorValue.category || compfieVendorValue.category == '7' || compfieVendorValue.category == '2' || compfieVendorValue.category == "View Only" || compfieVendorValue.category == "View OnlyResticted") {
            if (allvendorData && allvendorData.length > 0) {
                if (((allvendorData.length) == (userManagementDetails && userManagementDetails.um_vndr_legal_units.length))) {
                    setVendorAccess(true)
                }

            }
            if (GetUserDetails && GetUserDetails.vend_unitlist && GetUserDetails.vend_unitlist.length == 0) {
                setVendorAccess(true)
            }
        }
    }, [allvendorData, GetUserDetails])
    useEffect(() => {
        let temp = []

        if (AddType == 'edit') {
            if (GetUserDetails && GetUserDetails.ul_user_units && GetUserDetails.ul_user_units.length > 0) {
                for (let i in GetUserDetails && GetUserDetails.ul_user_units) {
                    temp.push({
                        le_id: GetUserDetails && GetUserDetails.ul_user_units[i].le_id,
                        u_id: GetUserDetails && GetUserDetails.ul_user_units[i].u_unt_id
                    })
                }

                setAllUnitData(temp)
            }
            if (GetUserDetails && GetUserDetails.vend_unitlist && GetUserDetails.vend_unitlist.length > 0) {
                // if (category.compfie_vendor == '7') {
                for (let i in GetUserDetails && GetUserDetails.vend_unitlist) {
                    temp.push({
                        le_id: GetUserDetails && GetUserDetails.vend_unitlist[i].le_id,
                        u_id: GetUserDetails && GetUserDetails.vend_unitlist[i].u_unt_id
                    })
                }
                // }

                setAllVendorData(temp)
            }



        }

        else {
            if (compfie.category == "View Only Restricted" || compfie.category == "View Only" || compfie.category == 2 || compfie.category == '7') {
                if (userManagementDetails.um_legal_units && userManagementDetails.um_legal_units && userManagementDetails.um_legal_units.length > 0) {
                    for (let i in userManagementDetails.um_legal_units) {
                        temp.push({
                            le_id: userManagementDetails.um_legal_units[i].le_id,
                            u_id: userManagementDetails.um_legal_units[i].u_unt_id
                        })
                    }
                }

                setAllUnitData(temp)
            }
            if (userManagementDetails && userManagementDetails.um_vndr_legal_units.length > 0) {
                if (category.compfie_vendor == '7' || category.compfie_vendor == '2') {
                    for (let i in userManagementDetails && userManagementDetails.um_vndr_legal_units) {
                        temp.push({
                            le_id: userManagementDetails && userManagementDetails.um_vndr_legal_units[i].le_id,
                            u_id: userManagementDetails && userManagementDetails.um_vndr_legal_units[i].u_unt_id
                        })
                    }
                }
                setAllVendorData(temp)
            }

        }
    }, [userManagementDetails && userManagementDetails.um_legal_units, category.compfie_vendor, compfie.category, GetUserDetails && GetUserDetails.vend_unitlist
    ])
    console.log(allUnitData, 'allUnitData');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];

        for (let i in entityValue) {
            let entityData = _.filter(EntityData && EntityData.length > 0 && EntityData, { le_id: entityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            divisionData && divisionData.length > 0 && divisionData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setUnitListData(countrylisttempdataunit)


    }, [divisionData, EntityData, entityValue])
    console.log(selectUnitData, 'selectUnitData');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];

        for (let i in entityValue) {
            let entityData = _.filter(EntityData && EntityData.length > 0 && EntityData, { le_id: entityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            selectUnitData && selectUnitData.length > 0 && selectUnitData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_unt_id': subunitlist.u_unt_id,
                        'u_unt_code': subunitlist.u_unt_code,
                        'u_unt_name': subunitlist.u_unt_name,
                        'u_unt_address': subunitlist.u_unt_address,
                        'le_id': subunitlist.le_id,
                        "d_id": subunitlist.d_id,
                        "cat_id": subunitlist.cat_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_unt_name,
                        'values': subunitlist.u_unt_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })


            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setselectUnitList(countrylisttempdataunit)


    }, [selectUnitData, EntityData, entityValue])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];

        for (let i in entityValue) {
            let entityData = _.filter(EntityData && EntityData.length > 0 && EntityData, { le_id: entityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            closedUnitData && closedUnitData.length > 0 && closedUnitData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_unt_id': subunitlist.u_unt_id,
                        'u_unt_code': subunitlist.u_unt_code,
                        'u_unt_address': subunitlist.u_unt_address,
                        'u_unt_name': subunitlist.u_unt_name,
                        'le_id': subunitlist.le_id,
                        "d_id": subunitlist.d_id,
                        "cat_id": subunitlist.cat_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_unt_name,
                        'values': subunitlist.u_unt_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(tempArrayunit, 'tempArrayunit');
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setclosedselectUnitList(countrylisttempdataunit)


    }, [closedUnitData, EntityData, entityValue])
    console.log(vendorUnit, 'vendorUnit99999999999999999999');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];

        for (let i in vendoreEntityValue) {
            let entityData = _.filter(vendorEntityData && vendorEntityData.length > 0 && vendorEntityData, { le_id: vendoreEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            vendorUnit && vendorUnit.length > 0 && vendorUnit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_unt_id': subunitlist.u_unt_id,
                        'u_unt_code': subunitlist.u_unt_code,
                        'u_unt_name': subunitlist.u_unt_name,
                        'u_unt_address': subunitlist.u_unt_address,
                        'le_id': subunitlist.le_id,
                        "d_id": subunitlist.d_id,
                        "cat_id": subunitlist.cat_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_unt_name,
                        'values': subunitlist.u_unt_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData55555555')
            countrylisttempdataunit.push(totalListunit)
        })
        setvendorUnitList(countrylisttempdataunit)


    }, [vendorUnit, vendorEntityData, vendoreEntityValue])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];

        for (let i in vendoreEntityValue) {
            let entityData = _.filter(vendorEntityData && vendorEntityData.length > 0 && vendorEntityData, { le_id: vendoreEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            vendorClosedUnit && vendorClosedUnit.length > 0 && vendorClosedUnit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_unt_id': subunitlist.u_unt_id,
                        'u_unt_code': subunitlist.u_unt_code,
                        'u_unt_name': subunitlist.u_unt_name,
                        'u_unt_address': subunitlist.u_unt_address,
                        'le_id': subunitlist.le_id,
                        "d_id": subunitlist.d_id,
                        "cat_id": subunitlist.cat_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_unt_name,
                        'values': subunitlist.u_unt_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData55555555')
            countrylisttempdataunit.push(totalListunit)
        })
        setcvendorClosedUnitList(countrylisttempdataunit)


    }, [vendorClosedUnit, vendorEntityData, vendoreEntityValue])
    console.log(selectUnitList, 'selectUnitList');
    console.log(vendorUnitList, 'vendorUnitList');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let divisionDatas = []

        console.log(divisionDatas, 'divisionDatassssss');
        for (let i in entityValue) {
            let entityData = _.filter(EntityData && EntityData.length > 0 && EntityData, { le_id: entityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])

            console.log(arrunit, 'arrunit');
        }
        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            categoryDatas && categoryDatas.length > 0 && categoryDatas.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setcategoryList(countrylisttempdataunit)


    }, [categoryDatas, EntityData, entityValue])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let divisionDatas = []
        console.log(divisionDatas, 'divisionDatassssss');
        for (let i in vendoreEntityValue) {
            let entityData = _.filter(vendorEntityData && vendorEntityData.length > 0 && vendorEntityData, { le_id: vendoreEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])

            console.log(arrunit, 'arrunit');
        }
        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            vendorcategoryDatas && vendorcategoryDatas.length > 0 && vendorcategoryDatas.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setvendorcategoryList(countrylisttempdataunit)


    }, [vendorcategoryDatas, vendorEntityData, vendoreEntityValue])
    console.log(categoryList, 'categoryList');
    console.log(vendorcategoryList, 'vendorcategoryList');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let entityArrayunit = []
        let tempArrayunit = []
        console.log(legalBg, 'legalBg');
        if (businessGroupData.length > 0) {
            if (legalBg && legalBg.length > 0 && legalBg.includes(null) == false) {
                if (businessGroupValue && businessGroupValue.length > 0) {


                    for (let i in businessGroupValue) {
                        let entityData = _.filter(businessGroupData && businessGroupData.length > 0 && businessGroupData, { bg_id: Number(businessGroupValue[i]) })
                        console.log(entityData, 'entityData');
                        arrunit.push(entityData[0])
                    }
                    console.log(arrunit, 'arrunit');

                    arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
                        let entityArrayunit = []
                        let tempArrayunit = []

                        EntityData && EntityData.length > 0 && EntityData.map((subunitlist) => {
                            console.log(subunitlist, 'subunitlist');
                            if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                                entityArrayunit = {
                                    'bg_id': subunitlist.bg_id,
                                    'le_name': subunitlist.le_name,
                                    'le_id': subunitlist.le_id,

                                    'label': subunitlist.le_name,
                                    'values': subunitlist.le_id
                                }
                                tempArrayunit.push(entityArrayunit)
                            }
                        })

                        totalListunit = {
                            'label': pmenu && pmenu.bg_name == null ? 'Others' : pmenu && pmenu.bg_name,
                            'options': tempArrayunit
                        }
                        console.log(totalListunit, 'aishwarya');
                        console.log(totalListunit, 'unitListData')
                        countrylisttempdataunit.push(totalListunit)
                    })
                    setLegalEntityList(countrylisttempdataunit)

                }
            }

            else {

                console.log('sneha');
                let data = []


                EntityData && EntityData.length > 0 && EntityData.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');
                    // if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                    entityArrayunit = [{
                        'bg_id': subunitlist.bg_id,
                        'le_name': subunitlist.le_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.le_name,
                        'values': subunitlist.le_id
                    }]
                    // tempArrayunit.push(entityArrayunit)
                    // }
                    data = _.filter(businessGroupData, { bg_id: subunitlist.bg_id })
                    console.log(data, '12395559');
                    console.log(subunitlist.bg_id, 'subunitlist.bg_id');
                    totalListunit = {
                        'label': subunitlist.bg_id != null ? data && data[0].bg_name : 'Others',
                        'options': entityArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                })



                console.log(countrylisttempdataunit, 'aishwarya87535333');
                console.log(totalListunit, 'unitListData')
                // let uniqueObjArray = [...new Map(countrylisttempdataunit.length > 0 && countrylisttempdataunit.map((item) => [item["bg_id"], item])).values()];

                setLegalEntityList(countrylisttempdataunit)

            }
        }
    }, [businessGroupValue, EntityData])

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let entityArrayunit = []
        let tempArrayunit = []
        if (litigationbusinessGroupData.length > 0) {
            if (litigationlegalBg && litigationlegalBg.length > 0 && litigationlegalBg.includes(null) == false) {
                if (lititigationbusinessGroupValue && lititigationbusinessGroupValue.length > 0) {


                    for (let i in lititigationbusinessGroupValue) {
                        let entityData = _.filter(litigationbusinessGroupData && litigationbusinessGroupData.length > 0 && litigationbusinessGroupData, { bg_id: Number(lititigationbusinessGroupValue[i]) })
                        console.log(entityData, 'entityData');
                        arrunit.push(entityData[0])
                    }
                    console.log(arrunit, 'arrunit');

                    arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
                        let entityArrayunit = []
                        let tempArrayunit = []

                        litigationEntityData && litigationEntityData.length > 0 && litigationEntityData.map((subunitlist) => {
                            console.log(subunitlist, 'subunitlist');
                            if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                                entityArrayunit = {
                                    'bg_id': subunitlist.bg_id,
                                    'le_name': subunitlist.le_name,
                                    'le_id': subunitlist.le_id,

                                    'label': subunitlist.le_name,
                                    'values': subunitlist.le_id
                                }
                                tempArrayunit.push(entityArrayunit)
                            }
                        })

                        totalListunit = {
                            'label': pmenu && pmenu.bg_name == null ? 'Others' : pmenu && pmenu.bg_name,
                            'options': tempArrayunit
                        }
                        console.log(totalListunit, 'aishwarya');
                        console.log(totalListunit, 'unitListData')
                        countrylisttempdataunit.push(totalListunit)
                    })
                    setLitigationLegalEntityList(countrylisttempdataunit)

                }
            }

            else {

                console.log('sneha');
                let data = []


                litigationEntityData && litigationEntityData.length > 0 && litigationEntityData.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');
                    // if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                    entityArrayunit = [{
                        'bg_id': subunitlist.bg_id,
                        'le_name': subunitlist.le_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.le_name,
                        'values': subunitlist.le_id
                    }]
                    // tempArrayunit.push(entityArrayunit)
                    // }
                    data = _.filter(litigationbusinessGroupData, { bg_id: subunitlist.bg_id })
                    console.log(data, '12395559');
                    console.log(subunitlist.bg_id, 'subunitlist.bg_id');
                    totalListunit = {
                        'label': subunitlist.bg_id != null ? data && data.length > 0 && data[0].bg_name : 'Others',
                        'options': entityArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                })



                console.log(countrylisttempdataunit, 'aishwarya87535333');
                console.log(totalListunit, 'unitListData')
                // let uniqueObjArray = [...new Map(countrylisttempdataunit.length > 0 && countrylisttempdataunit.map((item) => [item["bg_id"], item])).values()];

                setLitigationLegalEntityList(countrylisttempdataunit)

            }
        }
    }, [lititigationbusinessGroupValue, litigationbusinessGroupData, litigationEntityData])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let entityArrayunit = []
        let tempArrayunit = []
        if (vendorbusinessGroupData.length > 0) {
            if (vendorlegalBg && vendorlegalBg.length > 0 && vendorlegalBg.includes(null) == false) {
                if (vendorbusinessGroupValue && vendorbusinessGroupValue.length > 0) {


                    for (let i in vendorbusinessGroupValue) {
                        let entityData = _.filter(vendorbusinessGroupData && vendorbusinessGroupData.length > 0 && vendorbusinessGroupData, { bg_id: Number(vendorbusinessGroupValue[i]) })
                        console.log(entityData, 'entityData');
                        arrunit.push(entityData[0])
                    }
                    console.log(arrunit, 'arrunit');

                    arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
                        let entityArrayunit = []
                        let tempArrayunit = []

                        vendorEntityData && vendorEntityData.length > 0 && vendorEntityData.map((subunitlist) => {
                            console.log(subunitlist, 'subunitlist');
                            if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                                entityArrayunit = {
                                    'bg_id': subunitlist.bg_id,
                                    'le_name': subunitlist.le_name,
                                    'le_id': subunitlist.le_id,

                                    'label': subunitlist.le_name,
                                    'values': subunitlist.le_id
                                }
                                tempArrayunit.push(entityArrayunit)
                            }
                        })

                        totalListunit = {
                            'label': pmenu && pmenu.bg_name == null ? 'Others' : pmenu && pmenu.bg_name,
                            'options': tempArrayunit
                        }
                        console.log(totalListunit, 'aishwarya');
                        console.log(totalListunit, 'unitListData')
                        countrylisttempdataunit.push(totalListunit)
                    })
                    setVendorLegalEntityList(countrylisttempdataunit)

                }
            }

            else {

                console.log('sneha');
                let data = []


                vendorEntityData && vendorEntityData.length > 0 && vendorEntityData.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');
                    // if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                    entityArrayunit = [{
                        'bg_id': subunitlist.bg_id,
                        'le_name': subunitlist.le_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.le_name,
                        'values': subunitlist.le_id
                    }]
                    // tempArrayunit.push(entityArrayunit)
                    // }
                    data = _.filter(vendorbusinessGroupData, { bg_id: subunitlist.bg_id })
                    console.log(data, '12395559');
                    console.log(subunitlist.bg_id, 'subunitlist.bg_id');
                    totalListunit = {
                        'label': subunitlist.bg_id != null ? data && data.length > 0 && data[0].bg_name : 'Others',
                        'options': entityArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                })



                console.log(countrylisttempdataunit, 'aishwarya87535333');
                console.log(totalListunit, 'unitListData')
                // let uniqueObjArray = [...new Map(countrylisttempdataunit.length > 0 && countrylisttempdataunit.map((item) => [item["bg_id"], item])).values()];

                setVendorLegalEntityList(countrylisttempdataunit)

            }
        }
    }, [vendorbusinessGroupValue, vendorbusinessGroupData, vendorEntityData])


    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        for (let i in entityValue) {
            let entityData = _.filter(EntityData && EntityData.length > 0 && EntityData, { le_id: entityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            domainData && domainData.length > 0 && domainData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_dm_id': subunitlist.u_dm_id,
                        'u_dm_name': subunitlist.u_dm_name,
                        'le_id': subunitlist.le_id,
                        // 'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_dm_name,
                        'values': subunitlist.u_dm_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setDomainListData(countrylisttempdataunit)


    }, [domainData, EntityData, entityValue])
    console.log(vendordomainValue, 'vendordomainValue');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        for (let i in litigationEntityValue) {
            let entityData = _.filter(litigationEntityData && litigationEntityData.length > 0 && litigationEntityData, { le_id: litigationEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            litigationDomainData && litigationDomainData.length > 0 && litigationDomainData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_dm_id': subunitlist.u_dm_id,
                        'u_dm_name': subunitlist.u_dm_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.u_dm_name,
                        'values': subunitlist.u_dm_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setLitigationDomainList(countrylisttempdataunit)


    }, [litigationDomainData, litigationEntityData, litigationEntityValue])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        for (let i in vendoreEntityValue) {
            let entityData = _.filter(vendorEntityData && vendorEntityData.length > 0 && vendorEntityData, { le_id: vendoreEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit77777');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            VendorDomainData && VendorDomainData.length > 0 && VendorDomainData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist6666666');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_dm_id': subunitlist.u_dm_id,
                        'u_dm_name': subunitlist.u_dm_name,
                        'le_id': subunitlist.le_id,
                        // 'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_dm_name,
                        'values': subunitlist.u_dm_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya8145222');
            console.log(totalListunit, 'unitListData666666')
            countrylisttempdataunit.push(totalListunit)
        })
        setVendorDomainList(countrylisttempdataunit)


    }, [VendorDomainData, vendorEntityData, vendoreEntityValue])
    console.log(LegalEntityList, 'LegalEntityList');
    console.log(LitigationLegalEntityList, 'LitigationLegalEntityList');
    console.log(vendorEntityList, 'vendorEntityList');
    console.log(DomainListData, 'DomainListData');
    console.log(LitigationDomainList, 'LitigationDomainList');
    console.log(VendorDomainList, 'VendorDomainList');

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        for (let i in vendoreEntityValue) {
            let entityData = _.filter(vendorEntityData && vendorEntityData.length > 0 && vendorEntityData, { le_id: vendoreEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            vendordivisionData && vendordivisionData.length > 0 && vendordivisionData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist89999');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })
            // console.log(tempArrayunit, 'sneha');
            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setvendorListData(countrylisttempdataunit)


    }, [vendoreEntityValue, vendorEntityData, vendordivisionData])
    console.log(vendorListData, 'vendorListData');
    console.log(vendordivisionData, 'vendordivisionData');
    const compfieDomainHandelChange = (obj, data) => {
        let tem = []
        console.log(data, 'data');
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (domainData && domainData && domainData.length > 0) {
                let temp = []
                for (let i in domainData) {
                    temp.push(`${domainData[i].le_id}-${domainData[i].u_dm_id}`)
                    allArray.push(domainData[i].u_dm_id)

                }
                setdomainValue(temp)
                setdomainEntity(domainData)
            }
            SetDomainselectcheckbox('none');
            SetDomainunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setdomainValue([]);
            SetDomainselectcheckbox('block');
            SetDomainunselectcheckbox('none');
            setdomainEntity([])
        } else {
            setdomainValue(obj)
            if (obj.length == domainData.length) {
                SetDomainselectcheckbox('none');
                SetDomainunselectcheckbox('block');
            } else {
                SetDomainselectcheckbox('block');
                SetDomainunselectcheckbox('none');
            }
            if (obj.length > 0) {
                console.log(obj, 'obj9995922');
                let temp = []
                for (let i in obj) {
                    let splitValue = obj[i].split("-")
                    console.log(splitValue, 'splitValuesplitValue');
                    let data = _.filter(domainData && domainData, { u_dm_id: Number(splitValue[1]), le_id: splitValue[0] })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(data, 'tempppp');
                }
                setdomainEntity(temp)
            }

            console.log(obj, 'obj');

        }
        return obj;

    };
    const LitigationDomainHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (litigationDomainData && litigationDomainData.length > 0) {
                let temp = []
                for (let i in litigationDomainData) {
                    temp.push(`${litigationDomainData[i].le_id}-${litigationDomainData[i].u_dm_id}`)
                    allArray.push(litigationDomainData[i].u_dm_id)

                }
                setlitigationdomainValue(temp)
                setlitigationdomainEntity(litigationDomainData)
            }
            Setlitigationdomainselectcheckbox('none');
            SetlitigationDomainunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setlitigationdomainValue([]);
            setlitigationdomainEntity([])
            Setlitigationdomainselectcheckbox('block');
            SetlitigationDomainunselectcheckbox('none');
        } else {
            console.log('testinggggggggggg');
            setlitigationdomainValue(obj)
            if (obj.length == litigationDomainData.length) {
                Setlitigationdomainselectcheckbox('none');
                SetlitigationDomainunselectcheckbox('block');
            } else {
                Setlitigationdomainselectcheckbox('block');
                SetlitigationDomainunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let splitValue = obj[i].split("-")
                    let data = _.filter(litigationDomainData && litigationDomainData, { u_dm_id: Number(splitValue[1]), le_id: splitValue[0] })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setlitigationdomainEntity(temp)
            }
        }
        return obj;

    };
    const vendorDoaminHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (VendorDomainData && VendorDomainData.length > 0) {
                let temp = []
                for (let i in VendorDomainData) {
                    temp.push(`${VendorDomainData[i].le_id}-${VendorDomainData[i].u_dm_id}`)
                    allArray.push(VendorDomainData[i].u_dm_id)

                }
                setvendordomainValues(temp)
                setvendordomainEntity(litigationDomainData)
            }
            SetvendorDomainselectcheckbox('none');
            Setvendorunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setvendordomainValues([]);
            setvendordomainEntity([])
            SetvendorDomainselectcheckbox('block');
            Setvendorunselectcheckbox('none');
        } else {
            if (obj.length == VendorDomainData.length) {
                SetvendorDomainselectcheckbox('none');
                Setvendorunselectcheckbox('block');
            } else {
                SetvendorDomainselectcheckbox('block');
                Setvendorunselectcheckbox('none');
            }
            setvendordomainValues(obj)
            if (obj.length > 0) {

                let temp = []
                for (let i in obj) {
                    let splitValue = obj[i].split("-")
                    let data = _.filter(VendorDomainData && VendorDomainData, { u_dm_id: Number(splitValue[1]), le_id: splitValue[0] })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setlitigationdomainEntity(temp)
            }
        }
        return obj;

    };

    console.log(categoryValues, 'categoryValues');

    const compfieCategoryHandelChange = (obj, value) => {
        console.log(obj, 'objjjj');
        console.log(value, 'aishuuuuuuuuuuuuuu');
        let allArray = [];
        if (obj.includes('all')) {
            let cat = []

            if (categoryDatas && categoryDatas.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in categoryDatas) {
                    temp.push(categoryDatas[i].cat_id)
                    cat.push(categoryDatas[i].cat_name)
                    allArray.push(categoryDatas[i].cat_id)

                }
                setCatTooltip(cat.join(','))
                setCategoryValues(temp)
                // setDomainData(temp2)
            }
            Setcategoryselectcheckbox('none');
            Setcategoryunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setCatTooltip(temp)
            setCategoryValues([]);
            Setcategoryselectcheckbox('block');
            Setcategoryunselectcheckbox('none');
        } else {
            let temp = value && value.length > 0 && value.map((item, i) => {
                return item.children
            })
            if (temp.length > 0) {
                setCatTooltip(temp.join(','))
            }

            if (obj.length == categoryDatas.length) {
                Setcategoryselectcheckbox('none');
                Setcategoryunselectcheckbox('block');
            } else {
                Setcategoryselectcheckbox('block');
                Setcategoryunselectcheckbox('none');
            }
            setCategoryValues(obj)
        }
        return obj;

    };
    const vendorCategoryHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorcategoryDatas && vendorcategoryDatas.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in vendorcategoryDatas) {
                    temp.push(vendorcategoryDatas[i].cat_id)
                    allArray.push(vendorcategoryDatas[i].cat_id)
                }
                setVendorCategoryValues(temp)
                // setDomainData(temp2)
            }
            Setvendorcategoryselectcheckbox('none');
            Setvendoruncategoryselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendorCategoryValues([]);
            Setvendorcategoryselectcheckbox('block');
            Setvendoruncategoryselectcheckbox('none');
        } else {
            if (obj.length == vendorcategoryDatas.length) {
                Setvendorcategoryselectcheckbox('none');
                Setvendoruncategoryselectcheckbox('block');
            } else {
                Setvendorcategoryselectcheckbox('block');
                Setvendoruncategoryselectcheckbox('none');
            }
            setVendorCategoryValues(obj)
        }
        return obj;

    };




    console.log(categoryDatas, 'categoryDatas');
    console.log(EntityData, 'EntityData');
    console.log(selectUnitData, 'selectUnitData');
    console.log(all, 'AllData');
    useEffect(() => {
        if (DivisionValue.length > 0) {
            let temp = []
            for (let i in DivisionValue) {
                let data = _.filter(userManagementDetails && userManagementDetails.um_group_category, { d_id: Number(DivisionValue[i]) })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            setCategoryDatas(temp)
        }
        else {
            setCategoryDatas([])
        }
    }, [DivisionValue])
    console.log(categoryDatas, 'categoryDatas');
    useEffect(() => {
        if (vendorDivisionValue.length > 0) {
            let temp = []
            for (let i in vendorDivisionValue) {
                let data = _.filter(userManagementDetails && userManagementDetails.um_group_category, { d_id: Number(vendorDivisionValue[i]) })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            setvendorcategoryDatas(temp)
        }
        else {
            setvendorcategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
        }
    }, [vendorDivisionValue])
    console.log(domainData, 'domainData');


    const divisionHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (divisionData && divisionData.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in divisionData) {
                    temp.push(`${divisionData[i].d_id}`)
                    allArray.push(divisionData[i].d_id)


                }
                setDivisionValue(allArray)
                setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            }
            Setdivisionselectcheckbox('none');
            Setdivisionunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setDivisionValue([]);
            setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            Setdivisionselectcheckbox('block');
            Setdivisionunselectcheckbox('none');
        } else {
            if (obj.length == divisionData.length) {
                Setdivisionselectcheckbox('none');
                Setdivisionunselectcheckbox('block');
            } else {
                Setdivisionselectcheckbox('block');
                Setdivisionunselectcheckbox('none');
            }
            let temp = []
            setDivisionValue(obj)



            console.log(obj, 'obj');
            // setCategoryDatas(data)
        }
        return obj;

    };
    console.log(DivisionValue, 'DivisionValue');
    const VendordivisionHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (vendordivisionData && vendordivisionData.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in vendordivisionData) {
                    temp.push(vendordivisionData[i].d_id)
                    allArray.push(vendordivisionData[i].d_id)


                }
                setvendorDivisionValue(temp)
                setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            }
            Setvendordivisionselectcheckbox('none');
            Setvendordivisionunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setvendorDivisionValue([]);
            setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            Setvendordivisionselectcheckbox('block');
            Setvendordivisionunselectcheckbox('none');
        } else {
            if (obj.length == vendordivisionData.length) {
                Setvendordivisionselectcheckbox('none');
                Setvendordivisionunselectcheckbox('block');
            } else {
                Setvendordivisionselectcheckbox('block');
                Setvendordivisionunselectcheckbox('none');
            }
            // let data = _.filter(userManagementDetails && userManagementDetails.um_group_category, { d_id: Number(obj[0]) })
            setvendorDivisionValue(obj)
            // setCategoryDatas(data)
        }
        return obj;

    };

    console.log(litigationdomainValue, 'litigationdomainValuelitigationdomainValue');


    console.log(userUnits, 'userUnitsuserUnitsuserUnits');
    const compfieEntityHandelChange = (obj, value) => {
        console.log(value, 'datadatadatadatadatadata');
        let allArray = [];
        if (obj.includes('all')) {
            if (EntityData && EntityData.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in EntityData) {
                    temp.push(EntityData[i].le_id)
                    allArray.push(EntityData[i].le_id)
                }
                setLegalEntity(EntityData)
                setEntityValue(temp)
                setDomainData(userManagementDetails && userManagementDetails.um_legal_domain)
            }
            Setselectcheckbox('none');
            Setunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setEntityValue([]);
            Setselectcheckbox('block');
            Setunselectcheckbox('none');
        } else {
            console.log(obj, 'objobjobjobjobj');



            if (obj.length == EntityData.length) {
                Setselectcheckbox('none');
                Setunselectcheckbox('block');
            } else {
                Setselectcheckbox('block');
                Setunselectcheckbox('none');
            }
            let data = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: obj[0] })
            let datas = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: obj[0] })

            console.log(datas, 'datadata');
            setLegalEntity(datas)
            // setDomainData(data)

            setEntityValue(obj)



        }
        return obj;

    };
    const litigationEntityHandelChange = (obj) => {
        console.log(EntityData, 'EntityData');
        let allArray = [];
        if (obj.includes('all')) {
            if (litigationEntityData && litigationEntityData.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in litigationEntityData) {
                    temp.push(litigationEntityData[i].le_id)
                    allArray.push(litigationEntityData[i].le_id)

                }
                setLitigationEntityValue(temp)
                setLitigationDomainData(userManagementDetails && userManagementDetails.ltmg_um_legal_domain)
            }
            SetLitigationEntityselectcheckbox('none');
            SetLitigationEntityunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setLitigationEntityValue([]);
            SetLitigationEntityselectcheckbox('block');
            SetLitigationEntityunselectcheckbox('none');
        } else {

            if (obj.length == litigationEntityData.length) {
                SetLitigationEntityselectcheckbox('none');
                SetLitigationEntityunselectcheckbox('block');
            } else {
                SetLitigationEntityselectcheckbox('block');
                SetLitigationEntityunselectcheckbox('none');
            }
            setLitigationEntityValue(obj)
            let data = _.filter(userManagementDetails && userManagementDetails.ltmg_um_legal_domain, { le_id: obj[0] })
            console.log(data, 'datadata');
            // setLitigationDomainData(data)
            let datas = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: obj[0] })

            console.log(datas, 'datadata');
            setlitigationLegalEntity(datas)
        }
        return obj;

    };
    const vendorEntityHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorEntityData && vendorEntityData.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in vendorEntityData) {
                    temp.push(vendorEntityData[i].le_id)
                    allArray.push(vendorEntityData[i].le_id)
                }
                setVendoreEntityValue(temp)
                setvendorLegalEntity(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
                // setVendorDomainData(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
            }
            SetVendorEntityselectcheckbox('none');
            SetVendorEntityunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendoreEntityValue([]);
            SetVendorEntityselectcheckbox('block');
            SetVendorEntityunselectcheckbox('none');
        } else {

            if (obj.length == vendorEntityData.length) {
                SetVendorEntityselectcheckbox('none');
                SetVendorEntityunselectcheckbox('block');
            } else {
                SetVendorEntityselectcheckbox('block');
                SetVendorEntityunselectcheckbox('none');
            }
            setVendoreEntityValue(obj)
            let temp = []
            let tempData = []
            let tempData2 = []
            let temp2 = []
            for (let i in obj) {

                let data = _.filter(userManagementDetails && userManagementDetails.vndr_um_legal_domain, { le_id: obj[i] })
                let datas = _.filter(EntityData, { le_id: entityValue[i] })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                for (let j in datas) {
                    temp2.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            setvendorLegalEntity(temp)
        }
        return obj;

    };
    console.log(domainData, 'domainDatadomainData');


    useEffect(() => {
        if (haveCompliance == "HaveComplianceFailed") {
            console.log('aishuuu8888');
            const Editpayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UserManagementEditView",
                        {
                            "user_id": details.user_id
                        }
                    ]

                }
            ]
            getById({
                payload: Editpayload,
                paramid: paramid,

            })
        }

    }, [haveCompliance, count])
    console.log(entityValue, 'entityValueentityValueentityValue');
    console.log(editEntityValue, 'editEntityValue');
    useEffect(() => {
        if (entityValue.length > 0) {
            let temp = []
            let tempData = []
            let tempData2 = []
            let temp2 = []
            for (let i in entityValue) {
                let data = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: entityValue[i] })
                let datas = _.filter(EntityData, { le_id: entityValue[i] })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                for (let j in datas) {
                    temp2.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            // if (AddType == 'add') {
            setDomainData(temp)
            setLegalEntity(temp2)
            let tempDivision = []
            let catDiv = []

            for (let i in entityValue) {


                let data = _.filter(unitDatas, { le_id: entityValue[i] })
                let datas = _.filter(CloseUnitDAta, { le_id: entityValue[i] })
                let division = _.filter(userManagementDetails && userManagementDetails.um_cmpf_group_division, { le_id: entityValue[i] })
                let categoryArray = _.filter(userManagementDetails && userManagementDetails.um_group_category
                    , { le_id: entityValue[i] })

                // console.log(datas, 'datadata');
                console.log(division, 'division999999');

                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    tempData.push(data[j])
                }
                for (let j in datas) {
                    tempData2.push(datas[j])
                }
                for (let k in division) {
                    tempDivision.push(division[k])
                }
                for (let v in categoryArray) {
                    catDiv.push(categoryArray[v])
                }
                console.log(tempData, 'tempppp');
            }
            console.log(tempDivision, 'tempDivision');

            // if (AddType == 'add') {
            setSelectUnitData(tempData)
            setClosedtUnitData(tempData2)
            setdivisionData(tempDivision)
            setCategoryDatas(catDiv)
        }
    }, [entityValue])
    useEffect(() => {
        if (vendoreEntityValue.length > 0) {
            let temp = []
            let temp2 = []
            let tempData2 = []
            let tempData = []
            let tempDivision = []
            let catDiv = []
            for (let i in vendoreEntityValue) {
                let data = _.filter(userManagementDetails && userManagementDetails.vndr_um_legal_domain, { le_id: vendoreEntityValue[i] })
                let datas = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { le_id: vendoreEntityValue[i] })
                let division = _.filter(userManagementDetails && userManagementDetails.um_vndr_group_division
                    , { le_id: vendoreEntityValue[i] })
                let categoryArray = _.filter(userManagementDetails && userManagementDetails.um_group_category
                    , { le_id: vendoreEntityValue[i] })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                for (let j in datas) {
                    temp2.push(data[j])
                }
                for (let k in division) {
                    tempDivision.push(division[k])
                }
                for (let v in categoryArray) {
                    catDiv.push(categoryArray[v])
                }
                // temp.push(data)
                console.log(temp, 'tempppp88888888');
            }
            // if (AddType == 'add') {
            setVendorDomainData(temp)
            setvendorcategoryDatas(catDiv)
            // }
            setvendorLegalEntity(temp2)
            for (let i in entityValue) {


                let data = _.filter(vendorunitDatas, { le_id: vendoreEntityValue[i] })
                let datas = _.filter(CloseUnitDAta, { le_id: vendoreEntityValue[i] })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    tempData.push(data[j])
                }
                for (let j in datas) {
                    tempData2.push(datas[j])
                }
                console.log(tempData, 'tempppp');
            }
            setvendordivisionData(tempDivision)
        }
        else {
            setVendorDomainData(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
        }
    }, [vendoreEntityValue])
    console.log(compfie, 'compfie55555');
    useEffect(() => {
        if (litigationEntityValue.length > 0) {
            let temp = []
            let temp2 = []
            for (let i in litigationEntityValue) {
                console.log(litigationEntityValue, 'litigationEntityValue');
                let data = _.filter(userManagementDetails && userManagementDetails.ltmg_um_legal_domain, { le_id: litigationEntityValue[i] })
                let datas = _.filter(litigationEntityData, { le_id: litigationEntityValue[i] })
                console.log(datas, 'snehaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                for (let j in datas) {
                    temp2.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp999999999');
            }
            // if (AddType == 'add') {
            setLitigationDomainData(temp)
            // }
            setlitigationLegalEntity(temp2)
        }
        else {
            setLitigationDomainData(userManagementDetails && userManagementDetails.ltmg_um_legal_domain)
        }
    }, [litigationEntityValue])
    useEffect(() => {
        if (EntityData.length > 0) {
            let temp = []
            let temp2 = []
            for (let i in EntityData) {
                let data = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, {
                    le_id: EntityData
                    [i].le_id
                })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }

                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            if (AddType == 'edit') {
                setDomainData(temp)
            }

        }
        else {
            setDomainData(userManagementDetails && userManagementDetails.um_legal_domain)
            // setLegalEntity()
        }
    }, [EntityData])

    useEffect(() => {
        if (litigationEntityData.length > 0) {
            let temp = []
            let temp2 = []
            for (let i in litigationEntityData) {
                let data = _.filter(userManagementDetails && userManagementDetails.ltmg_um_legal_domain, {
                    le_id: litigationEntityData

                    [i].le_id
                })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }

                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            if (AddType == 'edit') {
                setLitigationDomainData(temp)
            }

        }
        else {
            setLitigationDomainData(userManagementDetails && userManagementDetails.ltmg_um_legal_domain)
            // setLegalEntity()
        }
    }, [litigationEntityData])


    useEffect(() => {
        if (vendorEntityData.length > 0) {
            let temp = []
            let temp2 = []
            for (let i in vendorEntityData) {
                let data = _.filter(userManagementDetails && userManagementDetails.vndr_um_legal_domain, {
                    le_id: vendorEntityData

                    [i].le_id
                })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }

                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            if (AddType == 'edit') {
                setVendorDomainData(temp)
            }

        }
        else {
            setVendorDomainData(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
            // setLegalEntity()
        }
    }, [vendorEntityData])

    const HandelChange = (obj, data) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (selectUnitData && selectUnitData && selectUnitData.length > 0) {
                let temp = []
                for (let i in selectUnitData) {
                    temp.push(selectUnitData[i].u_unt_id)
                    allArray.push(selectUnitData[i].u_unt_id)

                }
                setUnitEntity(selectUnitData)
                setAll(temp)
            }
            Setunitselectcheckbox('none');
            Setunitunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setAll([]);
            setUnitEntity([])
            Setunitselectcheckbox('block');
            Setunitunselectcheckbox('none');
        } else {
            setAll(obj)
            if (obj.length == selectUnitData.length) {
                Setunitselectcheckbox('none');
                Setunitunselectcheckbox('block');
            } else {
                Setunitselectcheckbox('block');
                Setunitunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let data = _.filter(selectUnitData && selectUnitData, { u_unt_id: Number(obj[i]) })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setUnitEntity(temp)
            }
            // else {
            //     setUnitEntity(selectUnitData)
            // }



        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    console.log(businessGroupValue, 'businessGroupValue');




    console.log(vendorEntityData, 'vendorEntityData');
    const businessGroupHandelChange = (obj, data) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (businessGroupData && businessGroupData && businessGroupData.length > 0) {
                let temp = []
                for (let i in businessGroupData) {
                    temp.push(`${businessGroupData[i].bg_id}`)
                    allArray.push(businessGroupData[i].bg_id)

                }
                setbusinessGroupValue(temp)
            }
            SetbusinessGroupselectcheckbox('none');
            SetbusinessGroupUnselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setbusinessGroupValue([]);
            SetbusinessGroupselectcheckbox('block');
            SetbusinessGroupUnselectcheckbox('none');
        } else {
            setbusinessGroupValue(obj)
            if (obj.length == businessGroupData.length) {
                SetbusinessGroupselectcheckbox('none');
                SetbusinessGroupUnselectcheckbox('block');
            } else {
                SetbusinessGroupselectcheckbox('block');
                SetbusinessGroupUnselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let data = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { bg_id: Number(obj[i]) })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setEntityData(temp)
            }
            else {
                setEntityData(userManagementDetails && userManagementDetails.um_legal_entity)
            }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };

    const litigationbusinessGroupHandelChange = (obj, data) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (litigationbusinessGroupData && litigationbusinessGroupData.length > 0) {
                let temp = []
                for (let i in litigationbusinessGroupData) {
                    temp.push(`${litigationbusinessGroupData[i].bg_id}`)
                    allArray.push(litigationbusinessGroupData[i].bg_id)

                }
                setlititigationbusinessGroupValue(temp)
            }
            SetlitigationbusinessGroupselectcheckbox('none');
            SetlitigationbusinessGroupunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setlititigationbusinessGroupValue([]);
            SetlitigationbusinessGroupselectcheckbox('block');
            SetlitigationbusinessGroupunselectcheckbox('none');
        } else {
            setlititigationbusinessGroupValue(obj)
            if (obj.length == litigationbusinessGroupData.length) {
                SetlitigationbusinessGroupselectcheckbox('none');
                SetlitigationbusinessGroupunselectcheckbox('block');
            } else {
                SetlitigationbusinessGroupselectcheckbox('block');
                SetlitigationbusinessGroupunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    if (obj[i] != null) {
                        let data = _.filter(userManagementDetails && userManagementDetails.um_ltmg_legal_entity, { bg_id: Number(obj[i]) })

                        for (let j in data) {
                            temp.push(data[j])
                        }
                        // temp.push(data)
                        console.log(temp, 'tempppp');
                    }
                    setlitigationEntityData(temp)
                }

            }
            else {
                setlitigationEntityData(userManagementDetails && userManagementDetails.um_ltmg_legal_entity)
            }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    const vendorbusinessGroupHandelChange = (obj, data) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorbusinessGroupData && vendorbusinessGroupData && vendorbusinessGroupData.length > 0) {
                let temp = []
                for (let i in vendorbusinessGroupData) {
                    temp.push(`${vendorbusinessGroupData[i].bg_id}`)
                    allArray.push(vendorbusinessGroupData[i].bg_id)

                }
                setVendorbusinessGroupValue(temp)
            }
            SetvendorbusinessGroupselectcheckbox('none');
            SetvendorbusinessGroupUnselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendorbusinessGroupValue([]);
            SetvendorbusinessGroupselectcheckbox('block');
            SetvendorbusinessGroupUnselectcheckbox('none');
        } else {

            setVendorbusinessGroupValue(obj)
            if (obj.length == vendorbusinessGroupData.length) {
                SetvendorbusinessGroupselectcheckbox('none');
                SetvendorbusinessGroupUnselectcheckbox('block');
            } else {
                SetvendorbusinessGroupselectcheckbox('block');
                SetvendorbusinessGroupUnselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let data = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { bg_id: Number(obj[i]) })
                    console.log(data, 'aaaaaaa');
                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setvendorEntityData(temp)
            }
            else {
                setvendorEntityData(userManagementDetails && userManagementDetails.um_vndr_legal_entity)
            }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    const CloseUnitHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (closedUnitData && closedUnitData && closedUnitData.length > 0) {
                let temp = []
                for (let i in closedUnitData) {
                    temp.push(closedUnitData[i].u_unt_id)
                    allArray.push(closedUnitData[i].u_unt_id)

                }
                setclosedAll(temp)
            }
            Setclosedunitselectcheckbox('none');
            Setclosedunitunselectcheckbox('block');
            setclosedEntityUnit(closedUnitData)
        } else if (obj.includes('unselect')) {
            let temp = [];
            setclosedAll([]);
            Setclosedunitselectcheckbox('block');
            Setclosedunitunselectcheckbox('none');
            setclosedEntityUnit([])
        } else {

            setclosedAll(obj)
            if (obj.length == closedUnitData.length) {
                Setclosedunitselectcheckbox('none');
                Setclosedunitunselectcheckbox('block');
            } else {
                Setclosedunitselectcheckbox('block');
                Setclosedunitunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let data = _.filter(closedUnitData && closedUnitData, { u_unt_id: Number(obj[i]) })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setclosedEntityUnit(temp)
            }
            // else {
            //     setclosedEntityUnit(closedUnitData)
            // }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    const VendorUnitHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorUnit && vendorUnit && vendorUnit.length > 0) {
                let temp = []
                for (let i in vendorUnit) {
                    temp.push(vendorUnit[i].u_unt_id)
                    allArray.push(vendorUnit[i].u_unt_id)

                }
                setVendorAll(temp)
                setvendorunitEntity(vendorUnit)
            }
            Setvendorunitselectcheckbox('none');
            Setvendorunitunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendorAll([]);
            setvendorunitEntity([])
            Setvendorunitselectcheckbox('block');
            Setvendorunitunselectcheckbox('none');
        } else {
            setVendorAll(obj)
            if (obj.length == vendorUnit.length) {
                Setvendorunitselectcheckbox('none');
                Setvendorunitunselectcheckbox('block');
            } else {
                Setvendorunitselectcheckbox('block');
                Setvendorunitunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let data = _.filter(vendorUnit && vendorUnit, { u_unt_id: Number(obj[i]) })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setvendorunitEntity(temp)
            }
            // else {
            //     setvendorunitEntity(vendorUnit)
            // }
        }
        return obj;

    };
    const VendorClosedUnitHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorClosedUnit && vendorClosedUnit.length > 0) {
                let temp = []
                for (let i in vendorClosedUnit) {
                    temp.push(vendorClosedUnit[i].u_unt_id)
                    allArray.push(vendorClosedUnit[i].u_unt_id)

                }
                setVendorClosedAll(temp)
                setvendorClosedunitEntity(vendorClosedUnit)
            }
            Setvendorclosedunitselectcheckbox('none');
            SetVendorclosedunitunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendorClosedAll([]);
            Setvendorclosedunitselectcheckbox('block');
            SetVendorclosedunitunselectcheckbox('none');
        } else {
            setVendorClosedAll(obj)
            if (obj.length == vendorClosedUnit.length) {
                Setvendorclosedunitselectcheckbox('none');
                SetVendorclosedunitunselectcheckbox('block');
            } else {
                Setvendorclosedunitselectcheckbox('block');
                SetVendorclosedunitunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let data = _.filter(vendorClosedUnit && vendorClosedUnit, { u_unt_id: Number(obj[i]) })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setvendorClosedunitEntity(temp)
            }
            // else {
            //     setvendorClosedunitEntity(vendorUnit)
            // }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    const userDetailOnSubmit = () => {

        //   setActive("2")
        setTabBoolean(true)
        setAddFormSubmit(true);
        setCategoryFormSubmit(true)
        setLitigationFormSubmit(true)
        if (tabValue.compfie == true || tabValue.compfie_litigation == true || tabValue.compfie_vendor == true) {
            if (validator.current.allValid()) {
                console.log(validator.current.allValid(), 'validator.current.allValid()');
                console.log(Formvalidator.current.allValid(), 'Formvalidator.current.allValid()');
                if (Formvalidator.current.allValid()) {
                    setSelectUnit(true)
                    // setActive("2")

                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "EmployeeCodeExists",
                                {
                                    "mode": "SAVE",
                                    "user_id_optional": null,
                                    "employee_code": userDetails.emp_id,
                                    "mobile_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`
                                }
                            ]
                            // "request": [
                            //     "EmployeeCodeExists",
                            //     {
                            //         "mode": "SAVE",
                            //         "user_id_optional": null,
                            //         "employee_code": "a5344",
                            //         "mobile_no": "+91-9853322282"
                            //     }
                            // ]
                            // "request": [
                            //     "EmployeeCodeExists",
                            //     {
                            //      "mode": "SAVE",
                            //      "user_id_optional": null,
                            //      "employee_code": "a12345",
                            //      "mobile_no": "+91-9853322222"
                            //     }
                            //    ]
                        }
                    ]
                    const Editpayload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "EmployeeCodeExists",
                                {
                                    "mode": "UPDATE",
                                    "user_id_optional": GetUserDetails && GetUserDetails.ul_userDetails[0].user_id,
                                    "employee_code": userDetails.emp_id,
                                    "mobile_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`
                                }
                            ]
                            // "request": [
                            //     "EmployeeCodeExists",
                            //     {
                            //         "mode": "SAVE",
                            //         "user_id_optional": null,
                            //         "employee_code": "a5344",
                            //         "mobile_no": "+91-9853322282"
                            //     }
                            // ]
                            // "request": [
                            //     "EmployeeCodeExists",
                            //     {
                            //      "mode": "SAVE",
                            //      "user_id_optional": null,
                            //      "employee_code": "a12345",
                            //      "mobile_no": "+91-9853322222"
                            //     }
                            //    ]
                        }
                    ]
                    addUserDetails({
                        payload: AddType == 'add' ? payload : Editpayload,
                        paramid: paramid,
                        setActive: setActive
                    })


                }
                else {
                    Toaster.warning("Please  Enter All Values")
                }

            }
        }
        else {
            Toaster.error('Select atleast one product')
        }

        // else {
        //     Toaster.warning("Please  Enter All Values")
        // }
        // setSelectUnit(true)




    }
    console.log(domainData, 'litigationdomainValue');
    console.log(GetUserDetails, 'GetUserDetailsGetUserDetails');
    useEffect(() => {
        if (AddType == 'edit') {
            if (GetUserDetails && GetUserDetails.ul_userDetails && GetUserDetails.ul_userDetails.length > 0) {
                let mobile = GetUserDetails && GetUserDetails.ul_userDetails[0].mob_no.split("-")
                setUserDetails({
                    ...userDetails,
                    emp_name: GetUserDetails && GetUserDetails.ul_userDetails[0].emp_name,
                    emp_id: GetUserDetails && GetUserDetails.ul_userDetails[0].emp_code,
                    email: GetUserDetails && GetUserDetails.ul_userDetails[0].email_id,
                    mobile_no_country_code: mobile && mobile[0],
                    mobile_no: mobile && mobile[1],
                    otp: GetUserDetails && GetUserDetails.ul_userDetails[0].otp_required,
                    remarks: GetUserDetails && GetUserDetails.ul_userDetails[0].otp_remarks

                })
                let userCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category, { u_c_id: GetUserDetails && GetUserDetails.ul_userDetails[0].u_cat_id })
                let SeatingUnit = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { u_unt_id: GetUserDetails && GetUserDetails.ul_userDetails[0].seating_unit_id })
                let UserGroup = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_g_id: GetUserDetails && GetUserDetails.ul_userDetails[0].u_g_id })
                let ServiceProvider = _.filter(userManagementDetails && userManagementDetails.um_service_providers, { u_sp_id: GetUserDetails && GetUserDetails.ul_userDetails[0].sp_id })
                let entityData = []
                let EntityData2 = []
                if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                    let LegalEntity = GetUserDetails && GetUserDetails.ul_legal_entities && GetUserDetails && GetUserDetails.ul_legal_entities.length && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                        return item.le_id
                    })
                    setEditEntity(LegalEntity)
                    setEntityValue(LegalEntity)
                }
                else {
                    let data = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: entityid })
                    console.log(data, 'dataaaaaaaaa')
                    if (data && data.length > 0) {
                        for (let l in data) {

                            entityData.push(`${data[l].le_id}`)

                        }
                        setEditEntity(entityData)
                        setEntityValue(entityData)
                    }
                    let LegalEntity = GetUserDetails && GetUserDetails.ul_legal_entities && GetUserDetails && GetUserDetails.ul_legal_entities.length && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                        return item.le_id
                    })
                    setpayloadEntityEdit(LegalEntity)
                }
                let tempp = []
                let tempp2 = []
                console.log(businessGroupData, 'businessGroupData');
                if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                    console.log('aishuuu');
                    let value = GetUserDetails && GetUserDetails.ul_legal_entities.length > 0 && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                        // if (item.bg_id != null) {
                        return item.bg_id
                        // }


                    })
                    setLegalBg(value)
                    GetUserDetails && GetUserDetails.ul_legal_entities.length > 0 && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                        // if (item.bg_id != null) {
                        //     return `${item.bg_id}`
                        // }
                        let data = _.filter(businessGroupData, { bg_id: item.bg_id })
                        console.log(data, 'dataaaaaaa');
                        if (data && data.length > 0) {
                            tempp.push(data[0])
                        }

                    })
                    console.log(tempp, 'tempp777777');
                    if (tempp && tempp.length > 0) {
                        for (let l in tempp) {
                            if (tempp[l].bg_id != null) {
                                tempp2.push(`${tempp[l].bg_id}`)
                            }
                        }
                    }

                    setbusinessGroupValue(tempp2)
                } else {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_legal_entities, { le_id: entityid })
                    console.log(data, 'dataaaaaaaaa')
                    if (data && data.length > 0) {
                        for (let l in data) {
                            if (data[l].bg_id != null) {
                                tempp2.push(`${data[l].bg_id}`)
                            }
                        }
                    }
                    setbusinessGroupValue(tempp2)
                }


                let LitigationLegalEntity = GetUserDetails && GetUserDetails.ul_ltg_legal_entities && GetUserDetails && GetUserDetails.ul_ltg_legal_entities.length > 0 && GetUserDetails && GetUserDetails.ul_ltg_legal_entities.map((item) => {
                    return item.le_id
                })
                console.log(LitigationLegalEntity, 'LitigationLegalEntity');
                if (((LitigationLegalEntity && LitigationLegalEntity.length == 0) || (LitigationLegalEntity == false) || (LitigationLegalEntity == 0))) {
                    setLitigationEntityValue([])
                    setpayloadLitigationEntityEdit([])
                }
                else {
                    setLitigationEntityValue(LitigationLegalEntity)
                    setpayloadLitigationEntityEdit(LitigationLegalEntity)

                }

                let litigationbg = GetUserDetails && GetUserDetails.ul_ltg_legal_entities.length && GetUserDetails.ul_ltg_legal_entities.map((item) => {
                    if (item.bg_id != null) {
                        return `${item.bg_id}`
                    }

                })

                let litigationbg1 = GetUserDetails && GetUserDetails.ul_ltg_legal_entities.length && GetUserDetails.ul_ltg_legal_entities.map((item) => {

                    return item.bg_id


                })
                setLitigationLegalBg(litigationbg1)
                let templ = []
                for (let l in litigationbg) {
                    if ((litigationbg[l] != undefined) && (litigationbg[l] != null)) {
                        templ.push(litigationbg[l])
                    }
                }
                console.log(vendorEntityData, 'vendorEntityData');
                setlititigationbusinessGroupValue(templ)
                // setLitigationEntityValue(LitigationLegalEntity)
                let VendorLegalEntity = GetUserDetails && GetUserDetails.ul_vend_legal_entities && GetUserDetails && GetUserDetails.ul_vend_legal_entities.length && GetUserDetails && GetUserDetails.ul_vend_legal_entities.map((item) => {
                    return item.le_id
                })
                console.log(VendorLegalEntity, 'VendorLegalEntity8888888');

                if (((VendorLegalEntity && VendorLegalEntity.length == 0) || (VendorLegalEntity == false) || (VendorLegalEntity == 0))) {
                    setpayloadVendorEntityEdit([])
                    setVendoreEntityValue([])
                }
                else {
                    setVendoreEntityValue(VendorLegalEntity)
                    setpayloadVendorEntityEdit(VendorLegalEntity)
                }
                // if (VendorLegalEntity != 0) {
                //     setpayloadVendorEntityEdit(VendorLegalEntity)
                // }
                // else {
                //     setpayloadVendorEntityEdit([])
                // }
                let vendorBg = GetUserDetails && GetUserDetails.ul_vend_legal_entities.length && GetUserDetails.ul_vend_legal_entities.map((item) => {
                    if (item.bg_id != null) {
                        return `${item.bg_id}`
                    }
                })
                let vendorBg1 = GetUserDetails && GetUserDetails.ul_vend_legal_entities.length && GetUserDetails.ul_vend_legal_entities.map((item) => {

                    return item.bg_id

                })
                setVendorLegalBg(vendorBg1)
                let temp5 = []
                for (let l in vendorBg) {
                    if ((vendorBg[l] != undefined) && (vendorBg[l] != null)) {
                        temp5.push(vendorBg[l])
                    }
                }

                setVendorbusinessGroupValue(temp5)

                if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                    let division = GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units.map((item) => {
                        if (item.div_id != null) {
                            console.log(item.div_id, 'item.div_id');
                            return item.div_id
                        }

                    })


                    let temp = []
                    for (let l in division) {
                        if (division[l] != undefined) {
                            temp.push(division[l])
                        }
                    }
                    setDivisionValue([...new Set(temp)])
                }
                else {
                    let EntityData = _.filter(GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units, { le_id: entityid })
                    if (EntityData && EntityData.length > 0) {
                        let division = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                            if (item.div_id != null) {
                                console.log(item.div_id, 'item.div_id');
                                return item.div_id
                            }

                        })


                        let temp = []
                        for (let l in division) {
                            if (division[l] != undefined) {
                                temp.push(division[l])
                            }
                        }
                        setDivisionValue([...new Set(temp)])
                    }
                }

                // setDivisionValue(temp)
                if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                    let temp2 = []
                    let vendordivision = GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist.map((item) => {
                        if (item.div_id != null) {
                            console.log(item.div_id, 'item.div_id');
                            return item.div_id
                        }

                    })
                    for (let k in vendordivision) {
                        if (vendordivision[k] != undefined) {
                            temp2.push(vendordivision[k])
                        }
                    }
                    setvendorDivisionValue([...new Set(temp2)])
                }

                else {
                    let EntityData = _.filter(GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist, { le_id: entityid })
                    let temp2 = []
                    let vendordivision = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                        if (item.div_id != null) {
                            console.log(item.div_id, 'item.div_id');
                            return item.div_id
                        }

                    })
                    for (let k in vendordivision) {
                        if (vendordivision[k] != undefined) {
                            temp2.push(vendordivision[k])
                        }
                    }
                    setvendorDivisionValue([...new Set(temp2)])

                }
                if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                    let categorydata = GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units.map((item) => {
                        if (item.cat_id != null) {
                            console.log(item.cat_id, 'item.cat_id');
                            return item.cat_id
                        }

                    })
                    let temparr1 = []
                    for (let l in categorydata) {
                        if (categorydata[l] != undefined) {
                            temparr1.push(categorydata[l])
                        }
                    }
                    setCategoryValues([...new Set(temparr1)])
                }
                else {
                    let EntityData = _.filter(GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units, { le_id: entityid })
                    let categorydata = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                        if (item.cat_id != null) {
                            console.log(item.cat_id, 'item.cat_id');
                            return item.cat_id
                        }

                    })
                    let temparr1 = []
                    for (let l in categorydata) {
                        if (categorydata[l] != undefined) {
                            temparr1.push(categorydata[l])
                        }
                    }
                    setCategoryValues([...new Set(temparr1)])
                }
                if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                    let vendorcategorydata = GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist.map((item) => {
                        if (item.cat_id != null) {
                            console.log(item.cat_id, 'item.cat_id');
                            return item.cat_id
                        }

                    })
                    let temparr2 = []
                    for (let l in vendorcategorydata) {
                        if (vendorcategorydata[l] != undefined) {
                            temparr2.push(vendorcategorydata[l])
                        }
                    }
                    setVendorCategoryValues([...new Set(temparr2)])
                }
                else {
                    let EntityData = _.filter(GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist, { le_id: entityid })
                    if (EntityData && EntityData.length > 0) {
                        let vendorcategorydata = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                            if (item.cat_id != null) {
                                console.log(item.cat_id, 'item.cat_id');
                                return item.cat_id
                            }

                        })
                        let temparr2 = []
                        for (let l in vendorcategorydata) {
                            if (vendorcategorydata[l] != undefined) {
                                temparr2.push(vendorcategorydata[l])
                            }
                        }
                        setVendorCategoryValues([...new Set(temparr2)])
                    }
                }
                let VendoruserCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category, { u_c_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category })
                let VendorSeatingUnit = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { u_unt_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_seating_unit })
                let VendorUserGroup = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_g_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_group_id })
                setCategory({
                    ...category,
                    compfie: userCategory && userCategory.length > 0 && userCategory[0].u_c_id,
                    compfie_vendor: VendoruserCategory && VendoruserCategory.length > 0 && VendoruserCategory[0].u_c_id

                })

                setCategoryValue({
                    ...categoryValue,
                    category: userCategory,
                    // domain: Domain,
                    serviceProvider: ServiceProvider,
                    // legalEnity: LegalEntity,
                    userGroup: UserGroup,
                    seatingUnit: SeatingUnit
                })
                setEditCompfieVendor({
                    ...EditCompfieVendor,
                    category: VendoruserCategory,
                    // domain: VendorDomains,
                    seatingUnit: VendorSeatingUnit,
                    legalEnity: VendorLegalEntity,
                    userGroup: VendorUserGroup
                })
                setCompfie({
                    ...compfie,
                    category: GetUserDetails && GetUserDetails.ul_userDetails.length > 0 && GetUserDetails.ul_userDetails[0].u_cat_id == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails.length > 0 && GetUserDetails.ul_userDetails[0].u_cat_id}`,
                    seating_Unit: SeatingUnit.length ? `${SeatingUnit[0].u_unt_code}-${SeatingUnit[0].u_unt_name}` : null,
                    user_level: GetUserDetails && GetUserDetails.ul_userDetails[0].user_level == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].user_level}`,
                    user_Group: GetUserDetails && GetUserDetails.ul_userDetails[0].u_g_id == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].u_g_id}`,
                    serviceProvider: ServiceProvider && ServiceProvider.length ? ServiceProvider && ServiceProvider.length && ServiceProvider[0].u_sp_name : null,
                    // legal_Entity: LegalEntity && LegalEntity.length ? LegalEntity[0].le_name : [],
                    // Domain: Domain && Domain.length ? Domain && Domain.length && Domain[0].u_dm_name : []
                })
                setCompfieLitigation({
                    ...compfieLitigation,
                    category: GetUserDetails && GetUserDetails.ul_userDetails[0].usr_type_id,
                    // legal_Entity: LitigationLegalEntity && LitigationLegalEntity.length ? LitigationLegalEntity[0].le_name : [],
                    // Domain: LitigationDomain.length ? LitigationDomain && LitigationDomain[0].u_dm_name : []
                })
                console.log(GetUserDetails && GetUserDetails.ul_userDetails[0].u_cat_id, 'GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category');
                setCompfieVendorValue({
                    ...compfieVendorValue,
                    category: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category}`,
                    seating_Unit: VendorSeatingUnit && VendorSeatingUnit.length ? `${VendorSeatingUnit[0].u_unt_code}-${VendorSeatingUnit[0].u_unt_name}` : null,
                    user_level: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_level == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_level}`,
                    user_Group: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_group_id == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_group_id}`,
                    // legal_Entity: VendorLegalEntity && VendorLegalEntity.length && VendorLegalEntity[0].le_name,
                    serviceProvider: ServiceProvider && ServiceProvider.length ? ServiceProvider && ServiceProvider.length && ServiceProvider[0].u_sp_name : null,

                    // Domain: VendorDomain && VendorDomain.length ? VendorDomain && VendorDomain.length && VendorDomain[0].u_dm_name : []
                })



            }
        }

    }, [GetUserDetails.ul_userDetails, GetUserDetails && GetUserDetails.ul_ltg_legal_entities && GetUserDetails.ul_ltg_legal_entities.length && GetUserDetails.ul_ltg_legal_entities])
    useEffect(() => {
        if (AddType == 'add') {
            setUserDetails({
                ...userDetails,
                email: ''
            })
        }

    }, [])
    useEffect(() => {
        if (AddType == 'edit') {
            if ((GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].is_access_ct == 1) && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 1) && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1)) {
                setTabValue({
                    ...tabValue,
                    compfie: true,
                    compfie_litigation: true,
                    compfie_vendor: GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].vndr_user_category != null ? true : false
                })
            }
            else if ((GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].is_access_ct == 1) || (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 1) || (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1)) {
                setTabValue({
                    ...tabValue,
                    compfie: GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ct == 1 ? true : false,
                    compfie_litigation: GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 1 ? true : false,
                    compfie_vendor: ((GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1) && (GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].vndr_user_category != null)) ? true : false
                })
            }
        }

    }, [GetUserDetails && GetUserDetails.ul_userDetails, AddType])

    useEffect(() => {
        if (domainValue && domainValue.length > 0) {
            let temp = []
            for (let i in domainValue) {
                let split = domainValue[i].split("-")
                console.log(split, 'jjjjjjjjj');
                temp.push(Number(split[1]))

            }
            setDomainPayload(temp)
        }
        if (litigationdomainValue && litigationdomainValue.length > 0) {
            let temp = []
            for (let i in litigationdomainValue) {
                let split = litigationdomainValue[i] && litigationdomainValue[i].split("-")
                console.log(split, 'jjjjjjjjj');
                if (split && split.length > 0) {
                    temp.push(Number(split[1]))
                }


            }
            setLitigationDomainPayload(temp)
        }
        if (vendordomainValue && vendordomainValue.length > 0) {
            let temp = []
            for (let i in vendordomainValue) {
                let split = vendordomainValue[i] && vendordomainValue[i].split("-")
                console.log(split, 'jjjjjjjjj');
                if (split && split.length > 0) {
                    temp.push(Number(split[1]))
                }

            }
            setVendorDomainPayload(temp)
        }
    }, [domainValue, litigationdomainValue, vendordomainValue])
    console.log(domainPayload, 'domainPayloaddomainPayloaddomainPayload');
    console.log(litigationdomainPayload, 'litigationdomainPayload');
    console.log(vendordomainPayload, 'vendordomainPayload');
    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_user_units.length && AddType == 'edit') {
            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []

            for (let s in entityValue) {

                if (GetUserDetails && GetUserDetails.ul_user_units.length > 0) {
                    let data = _.filter(GetUserDetails.ul_user_units, { le_id: entityValue[s] })

                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem.push(data[j])
                        }
                    }
                }

            }
            for (let t in vendoreEntityValue) {

                if (GetUserDetails && GetUserDetails.vend_unitlist.length > 0) {
                    let data = _.filter(GetUserDetails.vend_unitlist, { le_id: vendoreEntityValue[t] })

                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem2.push(data[j])
                        }
                    }
                }
            }

            console.log(tem, 'temtem');
            if (tem && tem.length > 0) {
                for (let i in tem) {
                    if (selectUnitData.length) {
                        let data = _.find(selectUnitData, { u_unt_id: Number(tem[i].u_unt_id) })
                        temparr.push(data)

                    }
                    if (closedUnitData.length) {
                        let closedData = _.find(closedUnitData, { u_unt_id: Number(tem[i].u_unt_id) })
                        closedTemp.push(closedData)
                    }

                    if (userManagementDetails && userManagementDetails.um_group_category.length) {
                        let Data = _.find(userManagementDetails && userManagementDetails.um_group_category, { cat_id: Number(GetUserDetails && GetUserDetails.ul_user_units.length && GetUserDetails.ul_user_units[i].cat_id) })
                        categoryTemp.push(Data)
                    }

                    if (userManagementDetails && userManagementDetails.um_cmpf_group_division.length) {
                        let Data = _.find(userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division, { d_id: Number(GetUserDetails && GetUserDetails.ul_user_units.length && GetUserDetails.ul_user_units[i].div_id) })
                        divisionarr.push(Data)
                    }
                }
            }
            console.log(vendorUnit, 'vendorUnitvendorUnit');
            if (tem2 && tem2.length > 0) {
                for (let i in tem2) {
                    if (vendorUnit.length > 0) {
                        console.log('ice');
                        let data = _.find(vendorUnit, { u_unt_id: Number(tem2 && tem2.length > 0 && tem2[i].u_unt_id) })
                        vendor.push(data)
                        console.log(data, 'snehaaaaaaaaaaa');
                    }
                    if (vendorClosedUnit.length > 0) {
                        let data = _.find(vendorClosedUnit, { u_unt_id: Number(tem2 && tem2.length > 0 && tem2[i].u_unt_id) })
                        vendorClosed.push(data)
                    }

                }
            }
            console.log(vendor, 'vendorsssss');
            if (temparr && temparr.length) {
                let data = []
                let a = []
                console.log(temparr, 'temparrtemparr');
                for (let i in temparr) {
                    if (temparr && temparr.length && temparr[i] != undefined) {
                        // if (entityid === null || entityid === "null") {
                        //     console.log(all[i], 'all[i]');
                        //     if (unitEntity&&unitEntity.length) {
                        //         temparr2.push(

                        //             {
                        //                 le_id: legalEntity[k].le_id,
                        //                 u_id: temparr && temparr.length && temparr[i].u_unt_id
                        //             }
                        //         )
                        //     }

                        // }
                        // else {
                        //     temparr2.push(

                        //         {
                        //             le_id: entityid,
                        //             u_id: temparr && temparr.length && temparr[i].u_unt_id
                        //         }
                        //     )
                        // }
                        a.push(temparr && temparr.length && temparr[i])
                        data.push(
                            temparr && temparr.length && temparr[i].u_unt_id
                        )


                    }

                }
                console.log(a, 'SMMMM');
                console.log(temparr2, 'temparr2');
                console.log(data, 'data');
                setUnitEntity(a)
                // setUserUnits(temparr2)
                setAll(data)
            }
            if (closedTemp && closedTemp.length) {
                let data = []
                let a = []
                // for (let i in temparr) {
                //     if (temparr && temparr.length && temparr[i] != undefined) {
                //         temparr2.push({

                //             label: `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                //             value: temparr && temparr.length && temparr[i].u_unt_id
                //         })
                //         data.push(
                //             `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                //         )

                //     }
                // }
                for (let i in closedTemp) {
                    if (closedTemp && closedTemp.length && closedTemp[i] != undefined) {
                        // if (entityid === null || entityid === "null") {
                        //     console.log(all[i], 'all[i]');
                        //     for (let k in legalEntity) {
                        //         ClosedTemparr.push(

                        //             {
                        //                 le_id: legalEntity[k].le_id,
                        //                 u_id: closedTemp && closedTemp.length && closedTemp[i].u_unt_id
                        //             }
                        //         )
                        //     }

                        // }
                        // else {
                        //     ClosedTemparr.push(

                        //         {
                        //             le_id: entityid,
                        //             u_id: closedTemp && closedTemp.length && closedTemp[i].u_unt_id
                        //         }
                        //     )
                        // }
                        a.push(closedTemp && closedTemp.length && closedTemp[i])
                        data.push(
                            closedTemp && closedTemp.length && closedTemp[i].u_unt_id
                        )

                    }

                }
                console.log(a, 'temparr2');
                console.log(data, 'data');
                setclosedEntityUnit(a)
                // setClosedUserUnits(ClosedTemparr)
                // setClosedSelected(data)
                // setCloseSelected(ClosedTemparr)
                setclosedAll(data)
            }
            if (vendor && vendor.length) {
                let vendorData = []
                let data = []
                let a = []
                // for (let i in temparr) {
                //     if (temparr && temparr.length && temparr[i] != undefined) {
                //         temparr2.push({

                //             label: `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                //             value: temparr && temparr.length && temparr[i].u_unt_id
                //         })
                //         data.push(
                //             `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                //         )

                //     }
                // }
                console.log(vendor, 'vendorvendorvendorvendor');
                for (let i in vendor) {
                    if (vendor && vendor.length && vendor[i] != undefined) {
                        // if (entityid === null || entityid === "null") {
                        //     console.log(all[i], 'all[i]');
                        //     for (let k in legalEntity) {
                        //         vendorData.push(

                        //             {
                        //                 le_id: legalEntity[k].le_id,
                        //                 u_id: vendor && vendor.length && vendor[i].u_unt_id
                        //             }
                        //         )
                        //     }

                        // }
                        // else {
                        //     vendorData.push(

                        //         {
                        //             le_id: entityid,
                        //             u_id: vendor && vendor.length && vendor[i].u_unt_id
                        //         }
                        //     )
                        // }
                        a.push(vendor && vendor.length && vendor[i])
                        data.push(
                            vendor && vendor.length && vendor[i].u_unt_id
                        )

                    }

                }
                console.log(temparr2, 'temparr2');
                console.log(data, 'data');
                setVendorUnit(a)
                // setVendorUnitDetails(vendorData)
                setVendorAll(data)
            }
            if (vendorClosed && vendorClosed.length) {
                let vendorData = []
                let data = []
                let a = []
                console.log(vendorClosed, 'vendorvendorvendorvendor');
                for (let i in vendorClosed) {
                    if (vendorClosed && vendorClosed.length && vendorClosed[i] != undefined) {
                        // if (entityid === null || entityid === "null") {
                        //     console.log(all[i], 'all[i]');
                        //     console.log(vendorlegalEntity, 'legalEntity123');
                        //     for (let k in vendorlegalEntity) {
                        //         vendorData.push(

                        //             {
                        //                 le_id: vendorlegalEntity[k].le_id,
                        //                 u_id: vendorClosed && vendorClosed.length && vendorClosed[i].u_unt_id
                        //             }
                        //         )
                        //     }

                        // }
                        // else {
                        //     vendorData.push(

                        //         {
                        //             le_id: entityid,
                        //             u_id: vendorClosed && vendorClosed.length && vendorClosed[i].u_unt_id
                        //         }
                        //     )
                        // }
                        data.push(
                            vendorClosed && vendorClosed.length && vendorClosed[i].u_unt_id
                        )
                        a.push(vendorClosed && vendorClosed.length && vendorClosed[i])

                    }

                }
                console.log(temparr2, 'temparr2');
                console.log(data, 'data');
                setvendorClosedunitEntity(a)
                // setVendorClosedUnitDetails(vendorData)
                setVendorClosedAll(data)
            }

            if (categoryTemp && categoryTemp.length) {
                for (let i in categoryTemp) {
                    if (categoryTemp && categoryTemp.length && categoryTemp[i] != undefined) {
                        categoryTemparr.push({

                            label: categoryTemp && categoryTemp.length && categoryTemp[i].cat_name,
                            value: categoryTemp && categoryTemp.length && categoryTemp[i].cat_id
                        })

                    }
                }

                setcompfieCategory(categoryTemparr)
            }
            if (divisionarr && divisionarr.length) {
                for (let i in divisionarr) {
                    if (divisionarr && divisionarr.length && divisionarr[i] != undefined) {
                        divisionArr.push({

                            label: divisionarr && divisionarr.length && divisionarr[i].d_name,
                            value: divisionarr && divisionarr.length && divisionarr[i].d_id
                        })

                    }
                }

                setDivisions(divisionArr)
            }

        }
        if (GetUserDetails && GetUserDetails.vend_unitlist.length && AddType == 'edit') {
            let temparr = []
            let temparr2 = []
            for (let i in GetUserDetails && GetUserDetails.vend_unitlist.length && GetUserDetails && GetUserDetails.vend_unitlist) {
                let data = _.find(vendorUnit, { u_unt_id: GetUserDetails && GetUserDetails.vend_unitlist.length && GetUserDetails.vend_unitlist[i].u_unt_id })
                temparr.push(data)
            }
            if (temparr && temparr.length && temparr[0] != undefined) {
                for (let i in temparr) {
                    if (temparr && temparr.length && temparr[i] != undefined) {
                        temparr2.push({

                            label: `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                            value: temparr && temparr.length && temparr[i].u_unt_id
                        })

                    }
                }

                setvendorSelected(temparr2)
            }
        }
    }, [GetUserDetails && GetUserDetails.ul_user_units, entityValue, vendoreEntityValue, GetUserDetails && GetUserDetails.vend_unitlist, legalEntity])
    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_user_units.length && AddType == 'edit') {
            if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
                let temparr = []
                let temparr2 = []
                let closedTemp = []
                let ClosedTemparr = []
                let vendor = []
                let vendorClosed = []
                let categoryTemp = []
                let categoryTemparr = []
                let divisionarr = []
                let divisionArr = []
                let tem = []
                let tem2 = []



                if (GetUserDetails && GetUserDetails.ul_user_units.length > 0) {
                    for (let i in GetUserDetails && GetUserDetails.ul_user_units) {
                        tem.push({
                            le_id: GetUserDetails && GetUserDetails.ul_user_units[i].le_id,
                            u_id: GetUserDetails && GetUserDetails.ul_user_units[i].u_unt_id
                        })
                    }
                }
                setPayloadEditunit(tem)
                if (GetUserDetails && GetUserDetails.vend_unitlist.length > 0) {
                    let temp = []
                    for (let i in GetUserDetails && GetUserDetails.vend_unitlist) {
                        temp.push({
                            le_id: GetUserDetails && GetUserDetails.vend_unitlist[i].le_id,
                            u_id: GetUserDetails && GetUserDetails.vend_unitlist[i].u_unt_id
                        })
                    }
                    setPayloadClosedEditunit(temp)
                }




            }

        }
    }, [GetUserDetails && GetUserDetails.ul_user_units, GetUserDetails && GetUserDetails.vend_unitlist])
    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_user_domains.length > 0 && AddType == 'edit') {
            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []

            for (let s in entityValue) {

                if (GetUserDetails && GetUserDetails.ul_user_domains
                    .length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_user_domains.length && GetUserDetails.ul_user_domains, { le_id: entityValue[s] })
                    console.log(data, 'datadatadata');
                    console.log(GetUserDetails && GetUserDetails.ul_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem.push(data[j])
                        }
                    }
                }

            }


            setdomainEntity(tem)

            if (tem && tem.length > 0) {
                let data = tem && tem.length > 0 && tem.map((item) => {
                    return `${item.le_id}-${item.u_dm_id}`
                })
                setdomainValue(data)
            }

            if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
                let temarray = []
                for (let s in payloadEntityEdit) {

                    if (GetUserDetails && GetUserDetails.ul_user_domains.length > 0) {
                        let data = _.filter(GetUserDetails && GetUserDetails.ul_user_domains.length && GetUserDetails.ul_user_domains, { le_id: payloadEntityEdit[s] })
                        console.log(data, 'datadatadata');
                        console.log(GetUserDetails && GetUserDetails.ul_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                        if (data && data.length > 0) {
                            for (let j in data) {
                                temarray.push(data[j])
                            }
                        }
                    }

                }


                // setdomainEntity(tem)
                console.log(temarray, 'tem555555');
                if (temarray && temarray.length > 0) {
                    let data = temarray && temarray.length > 0 && temarray.map((item) => {

                        temparr.push({
                            le_id: item.le_id,
                            d_id: item.u_dm_id
                        })
                    })
                    console.log(temparr, 'mukilan');
                    setPayloaEditdDomain(temparr)
                }
            }


        }
        if (GetUserDetails && GetUserDetails.ul_ltg_user_domains.length && AddType == 'edit') {
            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []



            for (let s in litigationEntityValue) {

                if (GetUserDetails && GetUserDetails.ul_ltg_user_domains.length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_ltg_user_domains.length && GetUserDetails.ul_ltg_user_domains, { le_id: litigationEntityValue[s] })

                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem.push(data[j])
                        }
                    }
                }

            }

            setlitigationdomainEntity(tem)

            let datas = litigationDomainData && litigationDomainData.length > 0 && litigationDomainData.map((item) => {
                return item.u_dm_id
            })

            if (tem && tem.length > 0) {
                let data = tem && tem.length > 0 && tem.map((item) => {
                    if (datas.includes(item.u_dm_id)) {
                        return `${item.le_id}-${item.u_dm_id}`
                    }
                })
                setlitigationdomainValue(data)
            }
            if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
                let temarray = []
                for (let s in payloadLitigationEntityEdit) {

                    if (GetUserDetails && GetUserDetails.ul_ltg_user_domains.length > 0) {
                        let data = _.filter(GetUserDetails && GetUserDetails.ul_ltg_user_domains.length && GetUserDetails.ul_ltg_user_domains, { le_id: payloadLitigationEntityEdit[s] })
                        console.log(data, 'datadatadata');
                        console.log(GetUserDetails && GetUserDetails.ul_ltg_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                        if (data && data.length > 0) {
                            for (let j in data) {
                                temarray.push(data[j])
                            }
                        }
                    }

                }


                // setdomainEntity(tem)

                if (temarray && temarray.length > 0) {
                    let data = temarray && temarray.length > 0 && temarray.map((item) => {

                        temparr.push({
                            le_id: item.le_id,
                            d_id: item.u_dm_id
                        })
                    })
                    setpayloadEditlitigationDomain(temparr)
                }
            }

        }
        if (GetUserDetails && GetUserDetails.ul_vend_user_domains.length && AddType == 'edit') {
            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []
            for (let s in vendoreEntityValue) {

                if (GetUserDetails && GetUserDetails.ul_vend_user_domains.length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_vend_user_domains.length && GetUserDetails.ul_vend_user_domains, { le_id: vendoreEntityValue[s] })

                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem.push(data[j])
                        }
                    }
                }

            }

            setvendordomainEntity(tem)
            let datas = VendorDomainData && VendorDomainData.length > 0 && VendorDomainData.map((item) => {
                return item.u_dm_id
            })
            if (tem && tem.length > 0) {
                let data = tem && tem.length > 0 && tem.map((item) => {
                    if (datas.includes(item.u_dm_id)) {
                        return `${item.le_id}-${item.u_dm_id}`
                    }
                    // else {
                    //     return null
                    // }
                })
                setvendordomainValues(data)
            }

            if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
                let temarray = []
                for (let s in payloadVendorEntityEdit) {

                    if (GetUserDetails && GetUserDetails.ul_vend_user_domains.length > 0) {
                        let data = _.filter(GetUserDetails && GetUserDetails.ul_vend_user_domains.length && GetUserDetails.ul_vend_user_domains, { le_id: payloadEntityEdit[s] })
                        console.log(data, 'datadatadata');
                        console.log(GetUserDetails && GetUserDetails.ul_vend_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                        if (data && data.length > 0) {
                            for (let j in data) {
                                temarray.push(data[j])
                            }
                        }
                    }

                }


                // setdomainEntity(tem)

                if (temarray && temarray.length > 0) {
                    let data = temarray && temarray.length > 0 && temarray.map((item) => {

                        temparr.push({
                            le_id: item.le_id,
                            d_id: item.u_dm_id
                        })
                    })
                    setPayloaEditVendordDomain(temparr)
                }
            }



        }

    }, [GetUserDetails && GetUserDetails.ul_user_domains, entityValue, litigationEntityValue, vendoreEntityValue])
    console.log(compfie.category, 'compfie.category');
    console.log(litigationdomainValue, 'litigationdomainValue');
    console.log(GetUserDetails, 'GetUserDetails');
    console.log(all, 'allllllllllllll');
    const userDetailsOnSubmit = () => {
        setAddFormSubmit(true);
        setCategoryFormSubmit(true)
        console.log('aishuuuu');
        if (AddType == 'add') {


            if (validator.current.allValid()) {
                if (Formvalidator.current.allValid()) {

                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveClientUser",
                                {
                                    "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
                                    "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                    "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
                                    "u_g_id": compfie.user_Group != null ? Number(compfie.user_Group) : null,
                                    "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
                                    "email_id": userDetails.email,
                                    "emp_name": userDetails.emp_name,
                                    "emp_code": userDetails.emp_id,
                                    "cont_no": "--",
                                    "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                    "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                                    "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                    // "s_unit": null,
                                    // "vend_s_unit": compfieVendorValue.seating_Unit != null ? Number(compfieVendorValue.seating_Unit) : null,
                                    "is_sp": compfie.serviceProvider != '' ? true : false,
                                    "sp_id": compfie.serviceProvider != '' ? Number(compfie.serviceProvider) : null,
                                    "user_entity_ids": ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],
                                    "l_user_entity_ids": ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                    "vend_user_entity_ids": ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                    "user_domain_ids": compfie.Domain,
                                    "l_user_domain_ids": compfieLitigation.Domain,
                                    "vend_user_domain_ids": compfieVendorValue.Domain,
                                    "user_unit_ids": unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                    "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
                                    "checked_value": "0",
                                    "otp_remarks": "OTP feature has been switched off",
                                    "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                    "ltmg_prdt_access": tabValue.compfie_litigation == true ? 1 : 0,
                                    "vndr_prdt_access": tabValue.compfie_vendor == true ? 1 : 0
                                }
                            ]


                        }
                    ]
                    addUserManagementDetails({
                        payload: payload,
                        paramid: paramid
                    })
                }
                else {
                    Toaster.warning("Please  Enter All Values")
                }
            }

            else {
                Toaster.warning("Please  Enter All Values")
            }

        }

        if (AddType == 'edit') {

            if (validator.current.allValid()) {
                if (Formvalidator.current.allValid()) {
                    const payloadEdit = [
                        authtoken,
                        {
                            "session_token": authtoken,

                            "request": [
                                "UpdateClientUser",
                                {
                                    "u_id": Number(GetUserDetails && GetUserDetails.ul_userDetails[0].user_id),
                                    "u_cat_id": compfie && compfie.category == (categoryValue && categoryValue.category.length && categoryValue.category[0].u_c_name) ? Number(categoryValue && categoryValue.category[0].u_c_id) : compfie.category == null ? null : Number(compfie.category),
                                    "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                    "vend_u_cat_id": compfieVendorValue.category === (EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_name) ? Number(EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_id) : compfieVendorValue.category == null ? null : Number(compfieVendorValue.category),
                                    "u_g_id": compfie && compfie.user_Group === (categoryValue && categoryValue.userGroup.length > 0 && categoryValue.userGroup[0].u_g_name) ? Number(categoryValue && categoryValue.userGroup && categoryValue.userGroup.length && categoryValue.userGroup[0].u_g_id) : compfie && compfie.user_Group == null ? null : Number(compfie && compfie.user_Group),
                                    "vend_u_g_id": compfieVendorValue.user_Group === (EditCompfieVendor && EditCompfieVendor.userGroup.length && EditCompfieVendor.userGroup[0].u_g_name) ? Number(EditCompfieVendor && EditCompfieVendor.userGroup[0].u_g_id) : compfieVendorValue.user_Group == null ? null : Number(compfieVendorValue.user_Group),
                                    "email_id": userDetails.email,
                                    "emp_name": userDetails.emp_name,
                                    "emp_code": userDetails.emp_id,
                                    "cont_no": "--",
                                    "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                    "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                                    "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                    "is_sp": compfie.serviceProvider == null ? false : true,
                                    "sp_id": compfie.serviceProvider === (categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_name) ? Number(categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_id) : compfie.serviceProvider == null ? null : Number(compfie.serviceProvider),

                                    "l_user_entity_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadLitigationEntityEdit : ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                    "vend_user_entity_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadVendorEntityEdit :
                                            ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                    "l_user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditlitigationDomain :
                                            ((tabValue.compfie_litigation == true) && (compfieLitigation.Domain.length)) ? compfieLitigation.Domain : [],
                                    "vend_user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditVendorDomain :
                                            ((tabValue.compfie_vendor == true) && (compfieVendorValue.Domain.length > 0)) ? compfieVendorValue.Domain : [],
                                    "user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditDomain :
                                            ((tabValue.compfie == true) && (compfie.Domain.length)) ? compfie.Domain : [],
                                    "user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditunit : unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                    "vndr_user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditClosedunit : vendorAccess == true ? vendorUnitDetails : vendorUnitDetails.concat(vendorClosedUnitDetails),
                                    "user_entity_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEntityEdit : ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],

                                    "c_user": compfie.category != null ? true : false,
                                    "v_user": compfieVendorValue.category != null ? true : false,
                                    "l_user": compfieLitigation.category != null ? true : false,
                                    "checked_value": "0",
                                    "otp_remarks": "OTP feature has been switched off",
                                    "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                    "ltmg_prdt_access": compfieLitigation.category != null == true ? 1 : 0,
                                    "vndr_prdt_access": compfieVendorValue.category != null ? 1 : 0,
                                }
                            ]

                        }
                    ]
                    console.log(payloadEdit, 'payloadEdit');
                    console.log(compfieVendorValue, 'compfieVendorValue');
                    edituserManagementDetails({
                        payload: payloadEdit,
                        paramid: paramid
                    })

                }
                else {
                    Toaster.warning("Please  Enter All Values")
                }
            }

            else {
                Toaster.warning("Please  Enter All Values")
            }

        }

    }
    console.log(userUnits, 'userUnits');
    const userDetailsSaved = () => {
        setAddFormSubmit(true);
        if (AddType == 'add') {

            if (compfie.category == 3 || compfie.category == 2 || compfie.category == 7) {
                if (validator.current.allValid()) {
                    if (Formvalidator.current.allValid()) {
                        if (userUnits.length > 0) {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "SaveClientUser",
                                        {
                                            "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
                                            "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                            "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
                                            "u_g_id": compfie.user_Group != null ? Number(compfie.user_Group) : null,
                                            "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
                                            "email_id": userDetails.email,
                                            "emp_name": userDetails.emp_name,
                                            "emp_code": userDetails.emp_id,
                                            "cont_no": "--",
                                            "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                            "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                                            "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                            // "s_unit": null,
                                            // "vend_s_unit": compfieVendorValue.seating_Unit != null ? Number(compfieVendorValue.seating_Unit) : null,
                                            "is_sp": compfie.serviceProvider != '' ? true : false,
                                            "sp_id": compfie.serviceProvider != '' ? Number(compfie.serviceProvider) : null,
                                            "user_entity_ids": ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],
                                            "l_user_entity_ids": ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                            "vend_user_entity_ids": ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                            "user_domain_ids": compfie.Domain,
                                            "l_user_domain_ids": compfieLitigation.Domain,
                                            "vend_user_domain_ids": compfieVendorValue.Domain,
                                            "user_unit_ids": unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                            "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
                                            "checked_value": "0",
                                            "otp_remarks": "OTP feature has been switched off",
                                            "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                            "ltmg_prdt_access": tabValue.compfie_litigation == true ? 1 : 0,
                                            "vndr_prdt_access": tabValue.compfie_vendor == true ? 1 : 0
                                        }
                                    ]


                                }
                            ]
                            addUserManagementDetails({
                                payload: payload,
                                paramid: paramid
                            })
                        }
                        else {
                            Toaster.error("Atleast one unit required for each Legal Entity for compfie")
                        }
                    }
                }
            }
            else {

                if (userUnits.length > 0) {
                    if (validator.current.allValid()) {
                        if (Formvalidator.current.allValid()) {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "SaveClientUser",
                                        {
                                            "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
                                            "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                            "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
                                            "u_g_id": compfie.user_Group != null ? Number(compfie.user_Group) : null,
                                            "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
                                            "email_id": userDetails.email,
                                            "emp_name": userDetails.emp_name,
                                            "emp_code": userDetails.emp_id,
                                            "cont_no": "--",
                                            "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                            "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                                            "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                            // "s_unit": null,
                                            // "vend_s_unit": compfieVendorValue.seating_Unit != null ? Number(compfieVendorValue.seating_Unit) : null,
                                            "is_sp": compfie.serviceProvider != '' ? true : false,
                                            "sp_id": compfie.serviceProvider != '' ? Number(compfie.serviceProvider) : null,
                                            "user_entity_ids": ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],
                                            "l_user_entity_ids": ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                            "vend_user_entity_ids": ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                            "user_domain_ids": compfie.Domain,
                                            "l_user_domain_ids": compfieLitigation.Domain,
                                            "vend_user_domain_ids": compfieVendorValue.Domain,
                                            "user_unit_ids": unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                            "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
                                            "checked_value": "0",
                                            "otp_remarks": "OTP feature has been switched off",
                                            "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                            "ltmg_prdt_access": tabValue.compfie_litigation == true ? 1 : 0,
                                            "vndr_prdt_access": tabValue.compfie_vendor == true ? 1 : 0
                                        }
                                    ]


                                }
                            ]
                            addUserManagementDetails({
                                payload: payload,
                                paramid: paramid
                            })
                        }
                    }

                }
                else {
                    Toaster.error("Atleast one unit required for each Legal Entity for compfie")
                }
            }

        }

        if (AddType == 'edit') {
            if (compfie.category == 3 || compfie.category == 2 || compfie.category == 7 ||
                compfie.category == "View Only Restricted" || compfie.category == "Legal Entity Admin" || compfie.category == "View Only") {
                if (validator.current.allValid()) {
                    if (Formvalidator.current.allValid()) {
                        if (userUnits.length > 0) {
                            const payloadEdit = [
                                authtoken,
                                {
                                    "session_token": authtoken,

                                    "request": [
                                        "UpdateClientUser",
                                        {
                                            "u_id": Number(GetUserDetails && GetUserDetails.ul_userDetails[0].user_id),
                                            "u_cat_id": compfie && compfie.category == (categoryValue && categoryValue.category.length && categoryValue.category[0].u_c_name) ? Number(categoryValue && categoryValue.category[0].u_c_id) : compfie.category == null ? null : Number(compfie.category),
                                            "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                            "vend_u_cat_id": compfieVendorValue.category === (EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_name) ? Number(EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_id) : compfieVendorValue.category == null ? null : Number(compfieVendorValue.category),
                                            "u_g_id": compfie && compfie.user_Group === (categoryValue && categoryValue.userGroup.length > 0 && categoryValue.userGroup[0].u_g_name) ? Number(categoryValue && categoryValue.userGroup && categoryValue.userGroup.length && categoryValue.userGroup[0].u_g_id) : compfie && compfie.user_Group == null ? null : Number(compfie && compfie.user_Group),
                                            "vend_u_g_id": compfieVendorValue.user_Group === (EditCompfieVendor && EditCompfieVendor.userGroup.length && EditCompfieVendor.userGroup[0].u_g_name) ? Number(EditCompfieVendor && EditCompfieVendor.userGroup[0].u_g_id) : compfieVendorValue.user_Group == null ? null : Number(compfieVendorValue.user_Group),
                                            "email_id": userDetails.email,
                                            "emp_name": userDetails.emp_name,
                                            "emp_code": userDetails.emp_id,
                                            "cont_no": "--",
                                            "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                            "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                                            "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                            "is_sp": compfie.serviceProvider == null ? false : true,
                                            "sp_id": compfie.serviceProvider === (categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_name) ? Number(categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_id) : compfie.serviceProvider == null ? null : Number(compfie.serviceProvider),

                                            "l_user_entity_ids":
                                                localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadLitigationEntityEdit : ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                            "vend_user_entity_ids":
                                                localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadVendorEntityEdit :
                                                    ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                            "l_user_domain_ids":
                                                localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditlitigationDomain :
                                                    ((tabValue.compfie_litigation == true) && (compfieLitigation.Domain.length)) ? compfieLitigation.Domain : [],
                                            "vend_user_domain_ids":
                                                localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditVendorDomain :
                                                    ((tabValue.compfie_vendor == true) && (compfieVendorValue.Domain.length > 0)) ? compfieVendorValue.Domain : [],
                                            "user_domain_ids":
                                                localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditDomain :
                                                    ((tabValue.compfie == true) && (compfie.Domain.length)) ? compfie.Domain : [],
                                            "user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditunit : unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                            "vndr_user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditClosedunit : vendorAccess == true ? vendorUnitDetails : vendorUnitDetails.concat(vendorClosedUnitDetails),
                                            "user_entity_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEntityEdit : ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],

                                            "c_user": compfie.category != null ? true : false,
                                            "v_user": compfieVendorValue.category != null ? true : false,
                                            "l_user": compfieLitigation.category != null ? true : false,
                                            "checked_value": "0",
                                            "otp_remarks": "OTP feature has been switched off",
                                            "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                            "ltmg_prdt_access": compfieLitigation.category != null == true ? 1 : 0,
                                            "vndr_prdt_access": compfieVendorValue.category != null ? 1 : 0,
                                        }
                                    ]

                                }
                            ]
                            console.log(payloadEdit, 'payloadEdit');
                            edituserManagementDetails({
                                payload: payloadEdit,
                                paramid: paramid
                            })
                        }
                        else {
                            Toaster.error("Atleast one unit required for each Legal Entity for compfie")
                        }
                    }
                }
            }
            else {
                if (userUnits.length > 0) {
                    const payloadEdit = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UpdateClientUser",
                                {
                                    "u_id": Number(GetUserDetails && GetUserDetails.ul_userDetails[0].user_id),
                                    "u_cat_id": compfie && compfie.category == (categoryValue && categoryValue.category.length && categoryValue.category[0].u_c_name) ? Number(categoryValue && categoryValue.category[0].u_c_id) : compfie.category == null ? null : Number(compfie.category),
                                    "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                    "vend_u_cat_id": compfieVendorValue.category === (EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_name) ? Number(EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_id) : compfieVendorValue.category == null ? null : Number(compfieVendorValue.category),
                                    "u_g_id": compfie && compfie.user_Group === (categoryValue && categoryValue.userGroup.length > 0 && categoryValue.userGroup[0].u_g_name) ? Number(categoryValue && categoryValue.userGroup && categoryValue.userGroup.length && categoryValue.userGroup[0].u_g_id) : compfie && compfie.user_Group == null ? null : Number(compfie && compfie.user_Group),
                                    "vend_u_g_id": compfieVendorValue.user_Group === (EditCompfieVendor && EditCompfieVendor.userGroup.length && EditCompfieVendor.userGroup[0].u_g_name) ? Number(EditCompfieVendor && EditCompfieVendor.userGroup[0].u_g_id) : compfieVendorValue.user_Group == null ? null : Number(compfieVendorValue.user_Group),
                                    "email_id": userDetails.email,
                                    "emp_name": userDetails.emp_name,
                                    "emp_code": userDetails.emp_id,
                                    "cont_no": "--",
                                    "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                    "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                                    "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                    "is_sp": compfie.serviceProvider == null ? false : true,
                                    "sp_id": compfie.serviceProvider === (categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_name) ? Number(categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_id) : compfie.serviceProvider == null ? null : Number(compfie.serviceProvider),

                                    "l_user_entity_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadLitigationEntityEdit : ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                    "vend_user_entity_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadVendorEntityEdit :
                                            ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                    "l_user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditlitigationDomain :
                                            ((tabValue.compfie_litigation == true) && (compfieLitigation.Domain.length)) ? compfieLitigation.Domain : [],
                                    "vend_user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditVendorDomain :
                                            ((tabValue.compfie_vendor == true) && (compfieVendorValue.Domain.length > 0)) ? compfieVendorValue.Domain : [],
                                    "user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditDomain :
                                            ((tabValue.compfie == true) && (compfie.Domain.length)) ? compfie.Domain : [],
                                    "user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditunit : unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                    "vndr_user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditClosedunit : vendorAccess == true ? vendorUnitDetails : vendorUnitDetails.concat(vendorClosedUnitDetails),
                                    "user_entity_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEntityEdit : ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],

                                    "c_user": compfie.category != null ? true : false,
                                    "v_user": compfieVendorValue.category != null ? true : false,
                                    "l_user": compfieLitigation.category != null ? true : false,
                                    "checked_value": "0",
                                    "otp_remarks": "OTP feature has been switched off",
                                    "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                    "ltmg_prdt_access": compfieLitigation.category != null == true ? 1 : 0,
                                    "vndr_prdt_access": compfieVendorValue.category != null ? 1 : 0,
                                }
                            ]

                        }
                    ]
                    console.log(payloadEdit, 'payloadEdit');
                    edituserManagementDetails({
                        payload: payloadEdit,
                        paramid: paramid
                    })
                }
                else {
                    Toaster.error("Atleast one unit required for each Legal Entity for compfie")
                }
            }

        }



    }
    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_userDetails.length) {
            let CategoryData = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_c_id: Number(category.compfie) })
            setUserGroupCompfie(CategoryData)
            let CategoryDatas = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_c_id: Number(category.compfie_vendor) })
            setVendorgroupcompfie(CategoryDatas)
        }
    }, [category.compfie, category.compfie_vendor])
    const categoryOnChange = (e) => {

        if (e == undefined) {
            setCategory({
                ...category,
                compfie: "",

            })
            setCompfie({
                ...compfie,
                category: "",
                user_Group: ''
            })
            setEntityValue([])
            setbusinessGroupValue([])

        }
        else {
            let CategoryData = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_c_id: Number(e) })
            setUserGroupCompfie(CategoryData)
            setCategory({
                ...category,
                compfie: e
            })
            setCompfie({
                ...compfie,
                category: e
            })
        }
    }

    useEffect(() => {
        validator.current.showMessages()
        Formvalidator.current.showMessages()
        LitigationFormvalidator.current.showMessages()
    }, [])
    console.log(litigationEntityData, 'litigationEntityData');
    useEffect(() => {
        if (AddType == 'add') {
            if (userManagementDetails && userManagementDetails.um_legal_entity && userManagementDetails.um_legal_entity.length > 0) {
                if (entityid === null || entityid === "null") {
                    let uniqueObjArray = [...new Map(userManagementDetails.um_legal_entity && userManagementDetails.um_legal_entity.length > 0 && userManagementDetails.um_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    })
                    setEntityData(uniqueIds)
                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: entityid })
                    setEntityData(entityData)
                }
            }
            if (userManagementDetails && userManagementDetails.um_ltmg_legal_entity.length > 0) {
                if (entityid === null || entityid === "null") {
                    let uniqueObjArray = [...new Map(userManagementDetails.um_ltmg_legal_entity && userManagementDetails.um_ltmg_legal_entity.length > 0 && userManagementDetails.um_ltmg_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    })
                    setlitigationEntityData(uniqueIds)
                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_ltmg_legal_entity, { le_id: entityid })
                    setlitigationEntityData(entityData)
                }
            }
            if (userManagementDetails && userManagementDetails.um_vndr_legal_entity.length > 0) {
                if (entityid === null || entityid === "null") {
                    let uniqueObjArray = [...new Map(userManagementDetails && userManagementDetails.um_vndr_legal_entity && userManagementDetails.um_vndr_legal_entity.length > 0 && userManagementDetails.um_vndr_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    })

                    setvendorEntityData(uniqueIds)
                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { le_id: entityid })
                    setvendorEntityData(entityData)
                }
            }





        }


        if (AddType == "edit") {
            if (userManagementDetails && userManagementDetails.um_legal_entity && userManagementDetails.um_legal_entity.length > 0) {
                if (entityid === null || entityid === "null") {
                    let uniqueObjArray = [...new Map(userManagementDetails.um_legal_entity && userManagementDetails.um_legal_entity.length > 0 && userManagementDetails.um_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    })
                    setEntityData(uniqueIds)
                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: entityid })
                    setEntityData(entityData)
                }
            }
            if (userManagementDetails && userManagementDetails.um_legal_entity && userManagementDetails.um_ltmg_legal_entity.length > 0) {
                if (entityid === null || entityid === "null") {
                    let uniqueObjArray = [...new Map(userManagementDetails.um_ltmg_legal_entity && userManagementDetails.um_ltmg_legal_entity.length > 0 && userManagementDetails.um_ltmg_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    })
                    setlitigationEntityData(uniqueIds)
                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_ltmg_legal_entity, { le_id: entityid })
                    setlitigationEntityData(entityData)
                }
            }
            if (userManagementDetails && userManagementDetails.um_vndr_legal_entity.length > 0) {
                if (entityid === null || entityid === "null") {
                    let uniqueObjArray = [...new Map(userManagementDetails && userManagementDetails.um_vndr_legal_entity && userManagementDetails.um_vndr_legal_entity.length > 0 && userManagementDetails.um_vndr_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    })

                    setvendorEntityData(uniqueIds)
                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { le_id: entityid })
                    setvendorEntityData(entityData)
                }
            }
            if (userManagementDetails && userManagementDetails.um_legal_domain) {
                setDomainData(userManagementDetails && userManagementDetails.um_legal_domain)
            }
            if (userManagementDetails && userManagementDetails.ltmg_um_legal_domain) {
                setLitigationDomainData(userManagementDetails && userManagementDetails.ltmg_um_legal_domain)
            }
            if (userManagementDetails && userManagementDetails.vndr_um_legal_domain.length > 0) {
                setVendorDomainData(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
            }
        }
        else {
            if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                if (userManagementDetails && userManagementDetails.um_legal_domain) {
                    setDomainData(userManagementDetails && userManagementDetails.um_legal_domain)
                }
                if (userManagementDetails && userManagementDetails.ltmg_um_legal_domain) {
                    setLitigationDomainData(userManagementDetails && userManagementDetails.ltmg_um_legal_domain)
                }
                if (userManagementDetails && userManagementDetails.vndr_um_legal_domain.length > 0) {
                    setVendorDomainData(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
                }
            }
            if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
                let data = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: entityid })
                setDomainData(data)
                let datas = _.filter(userManagementDetails && userManagementDetails.ltmg_um_legal_domain, { le_id: entityid })
                setLitigationDomainData(datas)
                let vendordata = _.filter(userManagementDetails && userManagementDetails.vndr_um_legal_domain, { le_id: entityid })
                setVendorDomainData(vendordata)
            }
        }
        if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
            if (userManagementDetails && userManagementDetails.um_legal_entity) {
                let entityData = _.find(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: entityid })
                // setEntityData(entityData)
                if (entityData) {
                    let entityDatas = _.filter(userManagementDetails && userManagementDetails.um_business_group, { bg_id: entityData.bg_id })
                    setBusinessGroupData(entityDatas)

                }

            }

        }

        else {

            setBusinessGroupData(userManagementDetails && userManagementDetails.um_business_group)

        }
        // if (userManagementDetails && userManagementDetails.um_group_category) {
        //     setBusinessGroupData(userManagementDetails && userManagementDetails.um_business_group)
        // }
        if (userManagementDetails && userManagementDetails.um_ltmg_business_group) {
            setlitigationbusinessGroupData(userManagementDetails && userManagementDetails.um_ltmg_business_group)
        }
        if (userManagementDetails && userManagementDetails.um_vndr_business_group) {
            setVendorBusinessGroupData(userManagementDetails && userManagementDetails.um_vndr_business_group)
        }
        if (userManagementDetails && userManagementDetails.um_group_category) {
            setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            setvendorcategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
        }
        if (userManagementDetails && userManagementDetails.um_cmpf_group_division) {
            setdivisionData(userManagementDetails && userManagementDetails.um_cmpf_group_division)
        }
        if (userManagementDetails && userManagementDetails.um_vndr_group_division) {
            setvendordivisionData(userManagementDetails && userManagementDetails.um_vndr_group_division)
        }
    }, [userManagementDetails, userManagementDetails && userManagementDetails.um_legal_entity, userManagementDetails && userManagementDetails.um_legal_domain, entityid])

    console.log(userManagementDetails, 'userManagementDetails');
    useEffect(() => {
        if (userManagementDetails && userManagementDetails.um_legal_units) {
            let entityData = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { is_closed: true })
            setClosedtUnitData(entityData)
            setCloseUnitDAta(entityData)
            let unitData = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { is_closed: false })
            setSelectUnitData(unitData)
            setunitDatas(unitData)
        }
    }, [userManagementDetails && userManagementDetails.um_legal_units])
    console.log(domainData, 'domainData');
    useEffect(() => {
        if (userManagementDetails && userManagementDetails.um_vndr_legal_units) {
            let vendorData = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_units, { is_closed: true })
            setVendorClosedUnit(vendorData)
            setvendorCloseUnitDAta(vendorData)
            let unitData = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_units, { is_closed: false })
            setVendorUnit(unitData)
            setvendorunitDatas(unitData)
        }
    }, [userManagementDetails && userManagementDetails.um_vndr_legal_units])


    useEffect(() => {
        if (EntityData.length) {
            let temparr = []
            for (let i in EntityData) {
                temparr.push({

                    label: EntityData[i].le_name,
                    value: EntityData[i].le_id
                })

                setEntityOptions(temparr)
                setLitigationEntityOptions(temparr)
                setVendorEntityOption(temparr)
            }
        }

        if (userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain) {
            let temparr = []
            for (let i in userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain) {
                temparr.push({

                    label: userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain[i].u_dm_name,
                    value: userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain[i].u_dm_id
                })

                // setDomainOptions(temparr)
                setLitigationDomainOptions(temparr)
                setVendorDomainOptions(temparr)
            }
        }
        if (userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division) {
            let temparr = []
            for (let i in userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division) {
                temparr.push({

                    label: userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division[i].d_name,
                    value: userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division[i].d_id
                })

                setDivisionOption(temparr)
            }
        }
        if (userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category) {
            let temparr = []
            for (let i in userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category) {
                temparr.push({

                    label: userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category[i].cat_name,
                    value: userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category[i].cat_id
                })

                setCategoryOptions(temparr)
            }
        }

    }, [EntityData && EntityData, userManagementDetails])
    console.log(all, 'alllllllllllll');
    console.log(closedAll, 'closedAll');
    console.log(userUnits, 'userUnits');
    console.log(vendorUnitDetails, 'vendorUnitDetails');
    useEffect(() => {
        if (unitAccess == false) {
            if (all && all.length > 0) {
                let tempIds = []
                let temparr = []
                console.log(all, 'all');

                for (let i in all) {
                    if (entityid === null || entityid === "null") {
                        console.log(all[i], 'all[i]');
                        if (unitEntity && unitEntity.length > 0) {
                            console.log(unitEntity, 'unitEntity9999999');
                            temparr.push(

                                {
                                    le_id: unitEntity && unitEntity.length > 0 && unitEntity[i].le_id,
                                    u_id: all[i]
                                }
                            )
                        }

                    }
                    else {
                        temparr.push(

                            {
                                le_id: entityid,
                                u_id: all[i]
                            }
                        )
                    }


                    setUserUnits(temparr)
                }
            }
            else {
                setUserUnits([])
            }

            if (closedAll && closedAll.length > 0) {
                let tempIds = []
                let temparr = []
                console.log(all, 'all');

                for (let i in closedAll) {
                    if (entityid === null || entityid === "null") {
                        console.log(all[i], 'all[i]');
                        if (closedEntityUnit && closedEntityUnit.length > 0) {
                            temparr.push(

                                {
                                    le_id: closedEntityUnit && closedEntityUnit.length > 0 && closedEntityUnit[i].le_id,
                                    u_id: closedAll[i]
                                }
                            )
                        }

                    }
                    else {
                        temparr.push(

                            {
                                le_id: entityid,
                                u_id: closedAll[i]
                            }
                        )
                    }


                    setClosedUserUnits(temparr)
                }
            }
            else {
                setClosedUserUnits([])
            }
            if (vendorAll && vendorAll.length > 0) {
                let tempIds = []
                let temparr = []
                console.log(all, 'all');

                for (let i in vendorAll) {
                    if (entityid === null || entityid === "null") {
                        console.log(all[i], 'all[i]');
                        if (vendorunitEntity && vendorunitEntity.length > 0) {
                            temparr.push(

                                {
                                    le_id: vendorunitEntity && vendorunitEntity.length > 0 && vendorunitEntity[i].le_id,
                                    u_id: vendorAll[i]
                                }
                            )
                        }

                    }
                    else {
                        temparr.push(

                            {
                                le_id: entityid,
                                u_id: vendorAll[i]
                            }
                        )
                    }


                    setVendorUnitDetails(temparr)
                }
            }
            else {
                setVendorUnitDetails
                    ([])
            }
            if (vendorClosedAll && vendorClosedAll.length > 0) {
                let tempIds = []
                let temparr = []
                console.log(all, 'all');

                for (let i in vendorClosedAll) {
                    if (entityid === null || entityid === "null") {

                        if (vendorClosedunitEntity && vendorClosedunitEntity.length > 0) {
                            temparr.push(

                                {
                                    le_id: vendorClosedunitEntity && vendorClosedunitEntity.length > 0 && vendorClosedunitEntity[i].le_id,
                                    u_id: vendorClosedAll[i]
                                }
                            )
                        }

                    }
                    else {
                        temparr.push(

                            {
                                le_id: entityid,
                                u_id: vendorClosedAll[i]
                            }
                        )
                    }


                    setVendorClosedUnitDetails(temparr)
                }
            }
            else {
                setVendorClosedUnitDetails
                    ([])
            }


        }
    }, [vendorAll, all, closedAll, vendorClosedAll, unitEntity, vendorunitEntity, closedEntityUnit, vendorClosedunitEntity])
    console.log(compfie, 'compfieeeee');
    console.log(domainValue, 'domainValue');

    useEffect(() => {
        if (compfie.category != null) {
            if (entityValue && entityValue.length > 0) {
                console.log(entityValue, 'entityValue');
                let tempIds = []
                for (let i in entityValue) {
                    tempIds.push(entityValue[i])

                }
                if (tempIds.length) {
                    console.log(tempIds, 'tempIds123455');
                    setCompfie({
                        ...compfie,
                        legal_Entity: tempIds
                    })
                }
                else {
                    setCompfie({
                        ...compfie,
                        legal_Entity: []
                    })
                }

            }
        }


        if (litigationEntityValue && litigationEntityValue.length > 0) {
            let tempIds = []
            for (let i in litigationEntityValue) {
                tempIds.push(litigationEntityValue[i])

            }
            if (tempIds.length) {
                setCompfieLitigation({
                    ...compfieLitigation,
                    legal_Entity: tempIds
                })
            }

        }
        else {
            setCompfieLitigation({
                ...compfieLitigation,
                legal_Entity: []
            })
        }
        // if (litigationlegalEntity && litigationlegalEntity.length > 0) {
        //     let tempIds = []
        //     for (let i in litigationlegalEntity) {
        //         tempIds.push(litigationlegalEntity[i].value)

        //     }
        //     if (tempIds.length) {
        //         setCompfieLitigation({
        //             ...compfieLitigation,
        //             legal_Entity: tempIds
        //         })
        //     }




        // }
        // else {
        //     setCompfieLitigation({
        //         ...compfieLitigation,
        //         legal_Entity: []
        //     })
        // }


        // if (VendorEntity && VendorEntity.length > 0) {
        //     let tempIds = []
        //     for (let i in VendorEntity) {
        //         tempIds.push(VendorEntity[i].value)

        //     }
        //     if (tempIds.length) {
        //         setCompfieVendorValue({
        //             ...compfieVendorValue,
        //             legal_Entity: tempIds
        //         })
        //     }



        // }
        // else {
        //     setCompfieVendorValue({
        //         ...compfieVendorValue,
        //         legal_Entity: []
        //     })
        // }
        if (vendoreEntityValue && vendoreEntityValue.length > 0) {
            let tempIds = []
            for (let i in vendoreEntityValue) {
                tempIds.push(vendoreEntityValue[i])

            }
            if (tempIds.length) {
                setCompfieVendorValue({
                    ...compfieVendorValue,
                    legal_Entity: tempIds
                })
            }

        }
        else {
            setCompfieVendorValue({
                ...compfieVendorValue,
                legal_Entity: []
            })
        }

    }, [legalEntity, litigationlegalEntity, VendorEntity, entityValue, litigationEntityValue, vendoreEntityValue])
    console.log(compfieVendorValue, "legalEntity555");
    console.log(compfieLitigation, 'compfieLitigation');
    console.log(domainEntity, 'domainEntity');
    console.log(domainPayload, 'domainPayload');
    useEffect(() => {
        if (compfie.legal_Entity.length > 0) {
            if (domainPayload && domainPayload.length > 0) {
                let tempIds = []
                let temparr = []
                for (let i in domainPayload) {
                    tempIds.push(domainPayload[i])

                }
                if (tempIds.length) {
                    console.log(tempIds, 'entityId');
                    console.log(domainEntity, 'legalEntity12345555');
                    for (let i = 0; i < tempIds.length; i++) {
                        if (entityid === null || entityid === "null") {
                            if (domainEntity && domainEntity.length > 0) {
                                if (domainEntity.length == tempIds.length) {
                                    temparr.push(

                                        {
                                            le_id: domainEntity && domainEntity.length > 0 && domainEntity[i].le_id,
                                            d_id: Number(tempIds[i])
                                        }
                                    )
                                }

                            }
                        }
                        else {
                            temparr.push(

                                {
                                    le_id: entityid,
                                    d_id: Number(tempIds[i])
                                }
                            )
                        }

                    }
                    console.log(temparr, 'temparr');
                    setCompfie({
                        ...compfie,
                        Domain: temparr
                    })
                }


            }
            else {
                setCompfie({
                    ...compfie,
                    Domain: []
                })
            }


        }

        if (compfieLitigation.legal_Entity.length > 0) {
            console.log('Aishwarya');
            if (litigationdomainPayload && litigationdomainPayload.length > 0) {
                let tempIds = []
                let temparr = []
                for (let i in litigationdomainPayload) {
                    tempIds.push(litigationdomainPayload[i])

                }
                console.log(litigationlegalEntity, "litigationlegalEntity");
                if (tempIds.length) {
                    for (let i in tempIds) {
                        if (entityid === null || entityid === "null") {
                            // for (let k in litigationlegalEntity) {
                            if (litigationdomainEntity && litigationdomainEntity.length > 0) {
                                if (litigationdomainEntity[i]) {
                                    temparr.push(
                                        {
                                            le_id: litigationdomainEntity && litigationdomainEntity[i].le_id,
                                            d_id: Number(tempIds[i])
                                        }
                                    )
                                }
                            }

                            // }
                        }
                        else {
                            temparr.push(

                                {
                                    le_id: entityid,
                                    d_id: Number(tempIds[i])
                                }
                            )

                        }

                    }
                    console.log(temparr, 'temparrtemparrtemparrtemparrtemparr');
                    setCompfieLitigation({
                        ...compfieLitigation,
                        Domain: temparr
                    })
                }


            }
            else {
                setCompfieLitigation({
                    ...compfieLitigation,
                    Domain: []
                })
            }
        }


        if (compfieVendorValue.legal_Entity.length > 0) {
            if (vendordomainPayload && vendordomainPayload.length > 0) {
                let tempIds = []
                let temparr = []
                for (let i in vendordomainPayload) {
                    tempIds.push(vendordomainPayload[i])

                }
                console.log(tempIds, "tempIdstempIds");
                if (tempIds.length > 0) {
                    for (let i = 0; i < tempIds.length; i++) {
                        if (entityid === null || entityid === "null") {
                            if (vendorlegalEntity && vendorlegalEntity.length > 0) {
                                console.log(vendorlegalEntity, 'vendorlegalEntity');
                                // console.log( compfieVendorValue && compfieVendorValue[k], "8888999");
                                temparr.push(
                                    {
                                        le_id: vendorlegalEntity && vendorlegalEntity[i].le_id,
                                        d_id: Number(tempIds[i])
                                    }
                                )
                            }
                        }
                        else {
                            temparr.push(
                                {
                                    le_id: entityid,
                                    d_id: Number(tempIds[i])
                                }
                            )
                        }
                    }
                    setCompfieVendorValue({
                        ...compfieVendorValue,
                        Domain: temparr
                    })
                }


            }

            else {
                setCompfieVendorValue({
                    ...compfieVendorValue,
                    Domain: []
                })
            }

        }

    }, [entityid, domainPayload, litigationdomainPayload, vendordomainPayload, VendorDomainValue, compfie.legal_Entity, compfieLitigation.legal_Entity, compfieVendorValue.legal_Entity, litigationlegalEntity, legalEntity, VendorEntity])

    console.log(litigationdomainValue, 'litigationdomainValuelitigationdomainValue');
    console.log(compfie.legal_Entity, 'compfie.legal_Entity');
    // useEffect(() => {
    //     if (userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain.length > 0) {
    //         const datal = legalEntity.map((item) => {
    //             return item.value
    //         })
    //         let temp = []
    //         for (let j in datal) {
    //             let array = _.find(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: datal && datal[j] });
    //             // if (array != undefined) {
    //             // let filter = array.filter((item) => {
    //             //     return item != undefined
    //             // })
    //             temp.push(array)
    //             setDomainList(temp)
    //         }

    //         // }
    //     }
    // }, [userManagementDetails.um_legal_domain, legalEntity])

    console.log(compfie.Domain, "compfie123");
    console.log(LitigationDomainValue, "LitigationDomainValue");
    useEffect(() => {
        if (compfie.category != null) {
            if (GetUserDetails && GetUserDetails.ul_user_domains.length > 0) {
                let temparr = []
                for (let i in GetUserDetails && GetUserDetails.ul_user_domains) {
                    temparr.push(
                        {
                            le_id: GetUserDetails && GetUserDetails.ul_user_domains && GetUserDetails.ul_user_domains[i].le_id,
                            d_id: GetUserDetails && GetUserDetails.ul_user_domains && GetUserDetails.ul_user_domains[i].u_dm_id
                        }
                    )
                    setCompfie({
                        ...compfie,
                        Domain: temparr
                    })
                }
            }
        }
    }, [GetUserDetails && GetUserDetails.ul_user_domains, compfie.category])
    useEffect(() => {
        if (compfieVendorValue.category != null) {
            if (GetUserDetails && GetUserDetails.ul_vend_user_domains.length > 0) {
                let temparr = []
                for (let i in GetUserDetails && GetUserDetails.ul_vend_user_domains) {
                    temparr.push(
                        {
                            le_id: GetUserDetails && GetUserDetails.ul_vend_user_domains && GetUserDetails.ul_vend_user_domains[i].le_id,
                            d_id: GetUserDetails && GetUserDetails.ul_vend_user_domains && GetUserDetails.ul_vend_user_domains[i].u_dm_id
                        }
                    )
                    setCompfieVendorValue({
                        ...compfieVendorValue,
                        Domain: temparr
                    })
                }
            }
        }
    }, [GetUserDetails && GetUserDetails.ul_vend_user_domains, compfieVendorValue.category])
    useEffect(() => {
        if (compfieLitigation.category != null) {
            if (GetUserDetails && GetUserDetails.ul_ltg_user_domains.length > 0) {
                let temparr = []
                for (let i in GetUserDetails && GetUserDetails.ul_ltg_user_domains) {
                    temparr.push(
                        {
                            le_id: GetUserDetails && GetUserDetails.ul_ltg_user_domains && GetUserDetails.ul_ltg_user_domains[i].le_id,
                            d_id: GetUserDetails && GetUserDetails.ul_ltg_user_domains && GetUserDetails.ul_ltg_user_domains[i].u_dm_id
                        }
                    )
                    setCompfieLitigation({
                        ...compfieLitigation,
                        Domain: temparr
                    })
                }
            }
        }
    }, [GetUserDetails && GetUserDetails.ul_ltg_user_domains, compfieLitigation.category])
    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <>
            {showList1 === false ?
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-1">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>User Management</span>

                                            {/* <Link>User Management</Link> */}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="
        col-lg-4 col-md-6
        d-none d-md-flex
        align-items-center
        justify-content-end
      "
                            >
                                <div>
                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", float: 'left' }}
                                        icon={<ArrowLeftOutlined />} size='default' onClick={previous}>
                                        Back
                                    </Button></div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card rounded">
                                    <div className="card-body" style={{ padding: "5px 15px" }}>

                                        <div className="col-md-12">
                                            <Tabs activeKey={active} onChange={(key) => {
                                                setActive(key)
                                            }} type="card" >
                                                <TabPane tab="User Details" key="1" >
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Employee Name</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <Input type="text" autocomplete="off" className='form-control' name="emp_name" onChange={onUserDetailOnChange} id="" value={userDetails.emp_name || undefined}
                                                                        onKeyPress={(event) => {
                                                                            if (!/^[A-Za-z  ]+$/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        style={{ marginTop: "5px" }} />
                                                                </div>
                                                                {validator.current.message(
                                                                    'emp_name',
                                                                    userDetails.emp_name,
                                                                    ['required', { regex: /^[A-Za-z ]+$/ }],
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Employee Name Required',
                                                                            regex: 'Enter valid name'
                                                                        }
                                                                    })}
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Employee ID</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <Input type="text" autocomplete="off" className='form-control' onChange={onUserDetailOnChange} name="emp_id" id="" value={userDetails.emp_id} style={{ marginTop: "5px" }} />
                                                                </div>
                                                                {validator.current.message(
                                                                    'emp_id',
                                                                    userDetails.emp_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Employee ID Required',
                                                                        }
                                                                    })}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Email ID</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <Input type="text" autocomplete="off" className='form-control' onChange={onUserDetailOnChange} name="email" id="" value={userDetails.email} style={{ marginTop: "5px" }} />
                                                                </div>
                                                                {validator.current.message(
                                                                    'email',
                                                                    userDetails.email,
                                                                    ['required', 'email'],
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Email Id Required',
                                                                            regex: 'Enter valid email'
                                                                        }
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <div className="row">


                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Mobile No</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <Input.Group size="default" style={{ marginTop: "5px" }} >
                                                                        <Row gutter={10}>
                                                                            <Col span={5}>
                                                                                <Input type="text" className='form-control' autocomplete="off" placeholder='+91' onKeyDown={blockInvalidChar} value={userDetails.mobile_no_country_code} name='mobile_no_country_code' onChange={onUserDetailOnChange}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9 +]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    maxlength='4'
                                                                                />
                                                                                {validator.current.message(
                                                                                    'mobile_no_country_code',
                                                                                    userDetails.mobile_no_country_code,
                                                                                    ['required', { regex: /^\+(\d{1}\-)?(\d{1,4})$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Enter Country Code for Mobile No.',
                                                                                            regex: 'Please enter valid country code'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                            <Col span={15}>
                                                                                <Input type="text" max={10} className='form-control' autocomplete="off" onKeyDown={blockInvalidChar} placeholder='9876543210' value={userDetails.mobile_no} name='mobile_no' onChange={onUserDetailOnChange}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    maxlength='10'
                                                                                />
                                                                                {validator.current.message(
                                                                                    'mobile_no',
                                                                                    userDetails.mobile_no,
                                                                                    ['required', { regex: /^[0-9\b]+$/ }, `min:10`, `max:10`],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Mobile Number Required',
                                                                                            min: 'Mobile Number should be 10 Numbers',
                                                                                            max: 'Mobile Number should be 10 Numbers'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>
                                                                    [+91 9876543210]
                                                                </div>
                                                            </div>

                                                            <div className='col-md-4'>
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Contact No</b></label>
                                                                    <Input.Group size="default" style={{ marginTop: "5px" }} >
                                                                        <Row gutter={8}>
                                                                            <Col span={5}>
                                                                                <Input type="text" className='form-control' autocomplete="off" placeholder='+00' name='emp_contact_country_code' value={userDetails.emp_contact_country_code} onChange={onUserDetailOnChange}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9 +]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    maxlength='4'
                                                                                />
                                                                                {validator.current.message(
                                                                                    'emp_contact_country_code',
                                                                                    userDetails.emp_contact_country_code,
                                                                                    [{ regex: /^\+(\d{1}\-)?(\d{1,4})$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            regex: 'Enter valid country code'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                            <Col span={5}>
                                                                                <Input type="text" className='form-control' autocomplete="off" placeholder='000' name='emp_contact_code' value={userDetails.emp_contact_code} onChange={onUserDetailOnChange}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    maxlength='3'
                                                                                />
                                                                                {validator.current.message(
                                                                                    'emp_contact_code',
                                                                                    userDetails.emp_contact_code,
                                                                                    [{ regex: /^(\+\d{1,3}[- ]?)?\d{3}$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            regex: 'Enter valid input'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                            <Col span={10}>
                                                                                <Input type="text" autocomplete="off" className='form-control' placeholder='1234567890' name='emp_contact_no' value={userDetails.emp_contact_no} onChange={onUserDetailOnChange}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    maxlength='10'
                                                                                />
                                                                                {validator.current.message(
                                                                                    'emp_contact_no',
                                                                                    userDetails.emp_contact_no,
                                                                                    [{ regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            regex: 'Enter valid mobile number'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>
                                                                    [+91 452 1234567]
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>OTP</b> </label> <span style={{ "color": "red" }}>*</span> <br />
                                                                    <Radio.Group onChange={onChange} value={userDetails.otp} name='otp' style={{ marginTop: "5px" }} >
                                                                        <Radio value={1} disabled={true}>On</Radio>
                                                                        <Radio value={0} >Off</Radio>
                                                                    </Radio.Group>
                                                                </div>
                                                                {validator.current.message(
                                                                    'otp',
                                                                    userDetails.otp,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'OTP Required',
                                                                        }
                                                                    })}


                                                            </div>
                                                            <div className="col-md-4 mt-2">
                                                                <div className="form-group">

                                                                    <label htmlFor=""><b>Remarks</b></label> <span style={{ "color": "red" }}>*</span>
                                                                    <div className="d-flex align-items-end">

                                                                        <Input type="text" autocomplete="off" className='form-control' onChange={onUserDetailOnChange}
                                                                            disabled={true}
                                                                            value='OTP feature has been switched off'
                                                                            name="remarks" id="" style={{ marginTop: '5px' }} />
                                                                        <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                                                        >
                                                                            <PlusCircleOutlined />
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div className="col-md-4"> </div>
                                                    <div className="col-md-4"> </div>


                                                    <div className="col-md-12" style={{ marginTop: "15px" }}>
                                                        <Tabs type="card"

                                                            onChange={(key) => {
                                                                setTab(key)
                                                            }}
                                                            //  onChange={() => {
                                                            //     setCategory({
                                                            //         compfie: '',
                                                            //         compie_litigation: '',
                                                            //         compfie_vendor: ''
                                                            //     })
                                                            // }}
                                                            activeKey={tab}

                                                        >

                                                            <TabPane

                                                                disabled={tabValue.compfie == false ? true : false}
                                                                tab={
                                                                    <span style={{ marginTop: "-16px", padding: "0px 50px", fontSize: "16px" }}>
                                                                        {
                                                                            tabValue.compfie == false ?
                                                                                <CloseOutlined onClick={() => {
                                                                                    setTab("1")
                                                                                    setTabValue({
                                                                                        ...tabValue,
                                                                                        compfie: !tabValue.compfie
                                                                                    })


                                                                                }}
                                                                                    style={{ color: 'red' }} /> : <CheckOutlined onClick={() => {
                                                                                        if (AddType == 'add') {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie: !tabValue.compfie
                                                                                            })
                                                                                        }
                                                                                        else if (AddType == 'edit') {
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_id": details.le_id,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "1"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            EdithaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,
                                                                                                setTabValue: setTabValue,
                                                                                                tabValue: tabValue,
                                                                                                type: 'compfie'
                                                                                            })



                                                                                        }

                                                                                        // }
                                                                                        // else {
                                                                                        //     setTabValue({
                                                                                        //         ...tabValue,
                                                                                        //         compfie: !tabValue.compfie
                                                                                        //     })
                                                                                        // }
                                                                                        console.log(AddType, 'AddTyp');
                                                                                        if (AddType == 'add') {

                                                                                            setEntityValue([])
                                                                                            setbusinessGroupValue([])
                                                                                            setdomainValue([])
                                                                                            setCategoryValues([])
                                                                                            setDivisionValue([])
                                                                                            setCompfie({
                                                                                                ...compfie,
                                                                                                category: null,
                                                                                                seating_Unit: null,
                                                                                                user_Group: '',
                                                                                                user_level: '1',
                                                                                                entity: '',
                                                                                                serviceProvider: '',
                                                                                                checked_value: '',
                                                                                                legal_Entity: [],
                                                                                                Domain: [],
                                                                                                domain_name: "",
                                                                                                Division: '',
                                                                                                businessGroup: ''
                                                                                            })
                                                                                        }


                                                                                    }} style={{ color: 'green' }}
                                                                                />
                                                                        }

                                                                        <b> Compfie</b>
                                                                    </span>
                                                                }
                                                                key="1">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor=""><b>Category</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                {/* <select name="compfie" className='form-control' id="cars" onChange={categoryOnChange}>
                                                                                        <option value="Select">Select</option>
                                                                                        <option value="Client Executive">Client Executive</option>
                                                                                        <option value="Domain Admin">Domain Admin</option>
                                                                                        <option value="Legal Entity Admin">Legal Entity Admin</option>
                                                                                        <option value="Service Provider Admin">Service Provider Admin</option>
                                                                                        <option value="View only">View only</option>
                                                                                        <option value="View only restricted">View only restricted</option>
                                                                                    </select> */}
                                                                                <br />
                                                                                <Select
                                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                                    // showSearch
                                                                                    // optionFilterProp="children"
                                                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Enter Category"
                                                                                    onChange={categoryOnChange}
                                                                                    // onChange={(data) => {
                                                                                    //     setUnit({
                                                                                    //         ...unit,
                                                                                    //         unit: data
                                                                                    //     })
                                                                                    // }}
                                                                                    value={compfie.category}
                                                                                    disabled={tabValue.compfie == false || ((AddType == 'edit') && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ct == 1)) ? true : false}
                                                                                >
                                                                                    {userManagementDetails && userManagementDetails.um_user_category && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category.map((item, i) => {

                                                                                        return (
                                                                                            <Option key={item.u_c_id}>
                                                                                                {item.u_c_name}
                                                                                            </Option>


                                                                                        )


                                                                                    })}


                                                                                </Select>
                                                                                {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                    'mobile_no',
                                                                                    compfie.category,
                                                                                    ['required'],
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Category Required',
                                                                                        }
                                                                                    })) : ''}


                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-4">
                                                                            <label htmlFor=""><b>User Group</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                            <Select

                                                                                // showSearch
                                                                                // optionFilterProp="children"
                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                allowClear={false}
                                                                                size="default"
                                                                                placeholder="Enter User Group"

                                                                                onChange={(data) => {
                                                                                    if (data != undefined) {
                                                                                        setValue({
                                                                                            ...value,
                                                                                            userGroup: data
                                                                                        })
                                                                                        setCompfie({
                                                                                            ...compfie,
                                                                                            user_Group: data
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        setValue({
                                                                                            ...value,
                                                                                            userGroup: ""
                                                                                        })
                                                                                        setCompfie({
                                                                                            ...compfie,
                                                                                            user_Group: ""
                                                                                        })
                                                                                    }
                                                                                }

                                                                                }
                                                                                value={compfie.user_Group || undefined}
                                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                                disabled={tabValue.compfie == false ? true : false}
                                                                            >
                                                                                {usergroupcompfie && usergroupcompfie.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.u_g_id}>
                                                                                            {item.u_g_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}

                                                                            </Select>
                                                                            {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                'usergroup',
                                                                                compfie.user_Group,
                                                                                ['required'],
                                                                                {
                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'User Group Required',
                                                                                        // regex: 'Please enter valid mobile number'
                                                                                    }
                                                                                })) : ''}
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                            <option value="volvo">None Selected</option>
                                                                          </select> */}
                                                                        </div>

                                                                        {category.compfie == '5' || category.compfie == '4' || category.compfie == '3' ?


                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor=""><b>User Level</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                    {/* <select name="" id="" placeholder='Enter Userlevel' className='form-control' value={compfie.user_level} style={{ marginTop: "5px" }} onChange={(e) => {
                                                                                                setCompfie({
                                                                                                    ...compfie,
                                                                                                    user_level: e.target.value
                                                                                                })
                                                                                            }}>
                                                                                                <option value="1">Level 1 (Low)</option>
                                                                                                <option value="2">Level 2</option>
                                                                                                <option value="3">Level 3</option>
                                                                                                <option value="4">Level 4</option>
                                                                                                <option value="5">Level 5</option>
                                                                                                <option value="6">Level 6</option>
                                                                                                <option value="7">Level 7</option>
                                                                                                <option value="8">Level 8</option>
                                                                                                <option value="9">Level 9</option>
                                                                                                <option value="10">Level 10 (High)</option>

                                                                                            </select> */}
                                                                                    <Select className='form-control' placeholder='Enter Userlevel'
                                                                                        value={compfie.user_level}
                                                                                        allowClear={true}
                                                                                        style={{ marginTop: "5px", width: '100%' }}
                                                                                        onChange={(data) => {
                                                                                            if (data !== undefined) {
                                                                                                setCompfie({
                                                                                                    ...compfie,
                                                                                                    user_level: data
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                setCompfie({
                                                                                                    ...compfie,
                                                                                                    user_level: ""
                                                                                                })
                                                                                            }
                                                                                        }}>
                                                                                        <Option value="1">Level 1 (Low)</Option>
                                                                                        <Option value="2">Level 2</Option>
                                                                                        <Option value="3">Level 3</Option>
                                                                                        <Option value="4">Level 4</Option>
                                                                                        <Option value="5">Level 5</Option>
                                                                                        <Option value="6">Level 6</Option>
                                                                                        <Option value="7">Level 7</Option>
                                                                                        <Option value="8">Level 8</Option>
                                                                                        <Option value="9">Level 9</Option>
                                                                                        <Option value="10">Level 10 (High)</Option>
                                                                                    </Select>
                                                                                </div>
                                                                            </div>

                                                                            : ''}

                                                                    </div>

                                                                    {/* <div className="col-md-12 mt-3">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="">Seating Unit</label><br />
                                                                                            <input type="text" className='form-control' id="" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}

                                                                    {category.compfie == '6' ? <div className="col-md-12 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label htmlFor=""><b>Service Provider</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                                    <Select
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={true}
                                                                                        size="default"
                                                                                        placeholder="Enter Service Provider"
                                                                                        // onChange={categoryOnChange}
                                                                                        onChange={(data) => {
                                                                                            if (data !== undefined) {
                                                                                                setValue({
                                                                                                    ...value,
                                                                                                    serviceProvider: data
                                                                                                })
                                                                                                setCompfie({
                                                                                                    ...compfie,
                                                                                                    serviceProvider: data
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                setValue({
                                                                                                    ...value,
                                                                                                    serviceProvider: ""
                                                                                                })
                                                                                                setCompfie({
                                                                                                    ...compfie,
                                                                                                    serviceProvider: ""
                                                                                                })
                                                                                            }
                                                                                        }}

                                                                                        value={compfie.serviceProvider || undefined}
                                                                                        style={{ width: '100%', marginTop: '5px' }}
                                                                                        disabled={tabValue.compfie == false ? true : false}
                                                                                    >
                                                                                        {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.u_sp_id}>
                                                                                                    {item.u_sp_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select>
                                                                                    {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                        'serviceProvider',
                                                                                        compfie.serviceProvider,
                                                                                        ['required'],
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Service Provider Required',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                        : ''}
                                                                </div>
                                                                {/* <Divider /> */}
                                                                <h5 style={{ marginTop: "15px" }}><b>Settings</b></h5>
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-4 order-4" style={{ marginBottom: "20px" }}>
                                                                            <label htmlFor=""><b>Business Group</b></label><br />
                                                                            {businessGroupValue.length > 0 ?
                                                                                <Tooltip title={bgTooltip}>
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        placeholder="Enter Business Group"
                                                                                        onDeselect={(e, value) => {
                                                                                            if (AddType == 'edit') {
                                                                                                console.log(e, 'eeeeeeeeeee');
                                                                                                let data = _.find(userManagementDetails && userManagementDetails.um_legal_entity, { bg_id: Number(e) })
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_id": data && data.le_id,
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "1"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                        onChange={(option, data) => businessGroupHandelChange(option, data)}
                                                                                        disabled={tabValue.compfie == false ? true : false}
                                                                                        // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                        mode="multiple"
                                                                                        value={businessGroupValue || undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {businessGroupData && businessGroupData.length > 1 ?
                                                                                            <>
                                                                                                <Option key="all" value="all" style={{ display: businessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: businessGroupUnselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}


                                                                                        {businessGroupData && businessGroupData.length > 0 &&
                                                                                            businessGroupData.map((item) => {
                                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <Option key={item.bg_id}>
                                                                                                        {item.bg_name}
                                                                                                    </Option>

                                                                                                )

                                                                                            }

                                                                                            )}
                                                                                    </Select>
                                                                                </Tooltip> :
                                                                                <Select
                                                                                    style={{ marginTop: '5px' }}
                                                                                    name="units"
                                                                                    id="units"
                                                                                    className='form-control'
                                                                                    placeholder="Enter Business Group"
                                                                                    onDeselect={(e, value) => {
                                                                                        if (AddType == 'edit') {
                                                                                            console.log(e, 'eeeeeeeeeee');
                                                                                            let data = _.find(userManagementDetails && userManagementDetails.um_legal_entity, { bg_id: Number(e) })
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_id": data && data.le_id,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "1"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            selectHaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,

                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                    onChange={(option, data) => businessGroupHandelChange(option, data)}
                                                                                    disabled={tabValue.compfie == false ? true : false}
                                                                                    // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                    mode="multiple"
                                                                                    value={businessGroupValue || undefined}
                                                                                    maxTagCount='responsive'
                                                                                >
                                                                                    {businessGroupData && businessGroupData.length > 1 ?
                                                                                        <>
                                                                                            <Option key="all" value="all" style={{ display: businessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: businessGroupUnselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                        </> : false}


                                                                                    {businessGroupData && businessGroupData.length > 0 &&
                                                                                        businessGroupData.map((item) => {
                                                                                            let data = _.find(EntityData, { le_id: item.le_id })
                                                                                            return (

                                                                                                <Option key={item.bg_id}>
                                                                                                    {item.bg_name}
                                                                                                </Option>

                                                                                            )

                                                                                        }

                                                                                        )}
                                                                                </Select>}
                                                                        </div>
                                                                        {category.compfie == '2' || category.compfie == '7' ?
                                                                            <div className="col-md-12 order-0">
                                                                                {/* <div className="form-group"> */}
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <label htmlFor=""><b>All Units Accessible</b></label><br />
                                                                                    <input type="checkbox" name="" id="" style={{ width: "40px", height: "20px" }}
                                                                                        checked={unitAccess == true ? true : false}
                                                                                        onChange={(e) => {
                                                                                            if ((e.target.checked == true)) {
                                                                                                let temp = []
                                                                                                setunitAccess(true)
                                                                                                console.log(userManagementDetails && userManagementDetails.um_legal_units.length, 'userManagementDetails && userManagementDetails.um_legal_units.length ');
                                                                                                if (userManagementDetails && userManagementDetails.um_legal_units.length > 0) {
                                                                                                    for (let i in userManagementDetails && userManagementDetails.um_legal_units) {
                                                                                                        temp.push({
                                                                                                            le_id: userManagementDetails && userManagementDetails.um_legal_units[i].le_id,
                                                                                                            u_id: userManagementDetails && userManagementDetails.um_legal_units[i].u_unt_id
                                                                                                        })
                                                                                                    }
                                                                                                }
                                                                                                setUserUnits(temp)
                                                                                            }
                                                                                            else {
                                                                                                setunitAccess(false)
                                                                                                setUserUnits([])
                                                                                                setAll([])
                                                                                                setclosedAll([])
                                                                                                // setAllUnitData([])
                                                                                            }
                                                                                        }} />
                                                                                </div>
                                                                                {/* </div> */}
                                                                            </div> : ''}
                                                                        {category.compfie == '5' || category.compfie == '6' ?

                                                                            <div className="col-md-4 order-2" >
                                                                                <label htmlFor=""><b>Division</b></label><br />
                                                                                {/* <MultiSelect
                                                                                        style={{ marginTop: '5px' }}
                                                                                        // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                        options={DivisionOption}
                                                                                        value={divisions}
                                                                                        onChange={setDivisions}
                                                                                        labelledBy="Select"

                                                                                    /><br /> */}
                                                                                {DivisionValue.length > 0 ?
                                                                                    <Tooltip title={divisionTooltip}>
                                                                                        <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            name="units"
                                                                                            id="units"
                                                                                            title=" "
                                                                                            className='form-control'
                                                                                            placeholder="Enter Division"
                                                                                            onChange={(option, data) => divisionHandelChange(option, data)}
                                                                                            disabled={entityValue && entityValue.length <= 0 ? true : false}
                                                                                            mode="multiple"
                                                                                            onDeselect={(e) => {
                                                                                                if (AddType == 'edit') {
                                                                                                    let data = _.find(userManagementDetails && userManagementDetails.um_legal_units, { d_id: e })
                                                                                                    console.log(data, 'eeeeeeeeeee');
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesMultiUnits",
                                                                                                                {
                                                                                                                    "le_id": data.le_id,
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "unit_ids": [data.u_unt_id],
                                                                                                                    "product_id": "1"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })

                                                                                                }

                                                                                            }}
                                                                                            value={DivisionValue || undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {divisionData && divisionData.length > 1 ?
                                                                                                <>
                                                                                                    <Option key="all" value="all" style={{ display: divisionselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: divisionunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                </> : false}


                                                                                            {/* {divisionData && divisionData.length > 0 &&
                                                                                        divisionData.map((item) => {
                                                                                            if (EntityData && EntityData.length > 0) {
                                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                return (
                                                                                                    <OptGroup label={data && data.le_name}>
                                                                                                        <Option key={item.d_id}>
                                                                                                            {item.d_name}
                                                                                                        </Option>
                                                                                                    </OptGroup>
                                                                                                )
                                                                                            }
                                                                                            else {
                                                                                                return (
                                                                                                    <Option key={item.d_id}>
                                                                                                        {item.d_name}
                                                                                                    </Option>
                                                                                                )
                                                                                            }


                                                                                        }

                                                                                        )} */}



                                                                                            {unitListData && unitListData.length > 0 &&
                                                                                                unitListData.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })}
                                                                                        </Select>
                                                                                    </Tooltip> :
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        placeholder="Enter Division"
                                                                                        onChange={(option, data) => divisionHandelChange(option, data)}
                                                                                        disabled={entityValue && entityValue.length <= 0 ? true : false}
                                                                                        mode="multiple"
                                                                                        onDeselect={(e) => {
                                                                                            if (AddType == 'edit') {
                                                                                                let data = _.find(userManagementDetails && userManagementDetails.um_legal_units, { d_id: e })
                                                                                                console.log(data, 'eeeeeeeeeee');
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesMultiUnits",
                                                                                                            {
                                                                                                                "le_id": data.le_id,
                                                                                                                "user_id": details.user_id,
                                                                                                                "unit_ids": [data.u_unt_id],
                                                                                                                "product_id": "1"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })

                                                                                            }

                                                                                        }}
                                                                                        value={DivisionValue || undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {divisionData && divisionData.length > 1 ?
                                                                                            <>
                                                                                                <Option key="all" value="all" style={{ display: divisionselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: divisionunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}


                                                                                        {/* {divisionData && divisionData.length > 0 &&
                                                                                 divisionData.map((item) => {
                                                                                     if (EntityData && EntityData.length > 0) {
                                                                                         let data = _.find(EntityData, { le_id: item.le_id })
                                                                                         return (
                                                                                             <OptGroup label={data && data.le_name}>
                                                                                                 <Option key={item.d_id}>
                                                                                                     {item.d_name}
                                                                                                 </Option>
                                                                                             </OptGroup>
                                                                                         )
                                                                                     }
                                                                                     else {
                                                                                         return (
                                                                                             <Option key={item.d_id}>
                                                                                                 {item.d_name}
                                                                                             </Option>
                                                                                         )
                                                                                     }


                                                                                 }

                                                                                 )} */}



                                                                                        {unitListData && unitListData.length > 0 &&
                                                                                            unitListData.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })}
                                                                                    </Select>
                                                                                }
                                                                            </div>
                                                                            : ''}



                                                                        <div className="col-md-4 order-3" style={{ marginBottom: "20px" }}>

                                                                            <label htmlFor=""><b>Legal Entity</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                            {/* <Select
                                                                                        allowClear={false}
                                                                                        size="default"
                                                                                        placeholder="Enter Unit"
                                                                                        mode="multiple"
                                                                                        onChange
                                                                                        defaultValue={compfie.legal_Entity}
                                                                                        // value={filter.seller}
                                                                                        style={{ width: '65%' }}
                                                                                    >
                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}

                                                                            {/* <MultiSelect

                                                                                        // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                        options={Entityoptions}
                                                                                        value={legalEntity}
                                                                                        onChange={setLegalEntity}
                                                                                        labelledBy="Enter Legal Entity"
                                                                                        disabled={tabValue.compfie == false ? true : false}

                                                                                    /> */}
                                                                            {entityValue.length > 0 ?
                                                                                <Tooltip title={entityTooltip}>
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={compfie.category == null ? true : false}
                                                                                        placeholder='Enter Legal Entity'
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        onDeselect={(e) => {
                                                                                            if (AddType == 'edit') {
                                                                                                console.log(e, 'eeeeeeeeeee');
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_id": e,
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "1"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                                if (entityValue && entityValue.value.length == 0) {
                                                                                                    setdomainValue([])
                                                                                                }
                                                                                            }

                                                                                        }}
                                                                                        onChange={(option, value) => compfieEntityHandelChange(option, value)}
                                                                                        mode="multiple"
                                                                                        value={entityValue || undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {EntityData && EntityData.length > 1 ?
                                                                                            <><Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}

                                                                                        {/* {EntityData && EntityData.map((item, i) => {
                                                                                        if (businessGroupData && businessGroupData.length > 0) {

                                                                                            let data = _.find(businessGroupData, { bg_id: item.bg_id })
                                                                                            return (
                                                                                                <OptGroup label={data && data.bg_name}>
                                                                                                    <Option key={item.le_id}>
                                                                                                        {item.le_name}
                                                                                                    </Option>
                                                                                                </OptGroup>
                                                                                            );
                                                                                        }
                                                                                        else {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        }

                                                                                    })} */}
                                                                                        {LegalEntityList && LegalEntityList.length > 0 ? LegalEntityList && LegalEntityList.length > 0 &&
                                                                                            LegalEntityList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option key={subUnitData.le_id} value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            }) :
                                                                                            <>  <OptGroup label="Others">
                                                                                            </OptGroup>{EntityData && EntityData.map((item, i) => {
                                                                                                return (

                                                                                                    <Option key={item.le_id}>
                                                                                                        {item.le_name}
                                                                                                    </Option>

                                                                                                );
                                                                                            })}</>

                                                                                        }
                                                                                    </Select>
                                                                                </Tooltip> : <Select
                                                                                    style={{ marginTop: '5px' }}
                                                                                    disabled={compfie.category == null ? true : false}
                                                                                    placeholder='Enter Legal Entity'
                                                                                    name="units"
                                                                                    id="units"
                                                                                    className='form-control'
                                                                                    onDeselect={(e) => {
                                                                                        if (AddType == 'edit') {
                                                                                            console.log(e, 'eeeeeeeeeee');
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_id": e,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "1"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            selectHaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,

                                                                                            })
                                                                                            if (entityValue && entityValue.value.length == 0) {
                                                                                                setdomainValue([])
                                                                                            }
                                                                                        }

                                                                                    }}
                                                                                    onChange={(option, value) => compfieEntityHandelChange(option, value)}
                                                                                    mode="multiple"
                                                                                    value={entityValue || undefined}
                                                                                    maxTagCount='responsive'
                                                                                >
                                                                                    {EntityData && EntityData.length > 1 ?
                                                                                        <><Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                        </> : false}

                                                                                    {/* {EntityData && EntityData.map((item, i) => {
                                                                                        if (businessGroupData && businessGroupData.length > 0) {

                                                                                            let data = _.find(businessGroupData, { bg_id: item.bg_id })
                                                                                            return (
                                                                                                <OptGroup label={data && data.bg_name}>
                                                                                                    <Option key={item.le_id}>
                                                                                                        {item.le_name}
                                                                                                    </Option>
                                                                                                </OptGroup>
                                                                                            );
                                                                                        }
                                                                                        else {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        }

                                                                                    })} */}
                                                                                    {LegalEntityList && LegalEntityList.length > 0 ? LegalEntityList && LegalEntityList.length > 0 &&
                                                                                        LegalEntityList.map((finalList_unitlist) => {
                                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                return (
                                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                            <Option key={subUnitData.le_id} value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                        ))}
                                                                                                    </OptGroup>
                                                                                                )
                                                                                        }) :
                                                                                        <>  <OptGroup label="Others">
                                                                                        </OptGroup>{EntityData && EntityData.map((item, i) => {
                                                                                            return (

                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>

                                                                                            );
                                                                                        })}</>

                                                                                    }
                                                                                </Select>}

                                                                            {/* <Select
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={false}
                                                                                        size="default"
                                                                                        placeholder="Enter Entity"
                                                                                        mode="multiple"
                                                                                        defaultValue={`${compfie.legal_Entity}`}
                                                                                        // defaultValue="Lulu Software Services Private Limited (India)"
                                                                                        disabled={tabValue.compfie == false ? true : false}
                                                                                        // onChange={(e) => {
                                                                                        //     setCompfie({
                                                                                        //         ...compfie,
                                                                                        //         Domain: value
                                                                                        //     })
                                                                                        // }}
                                                                                        onChange={(data) => handled(data)
                                                                                        }
                                                                                        // value={filter.seller}
                                                                                        style={{ width: '65%' }}
                                                                                    >

                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}




                                                                            {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                'legal_Entity',
                                                                                compfie.legal_Entity,
                                                                                ['required'],
                                                                                {
                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Legal Entity Required',
                                                                                        // regex: 'Please enter valid mobile number'
                                                                                    }
                                                                                })) : ""}
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}



                                                                        </div>
                                                                        {category.compfie == '5' || category.compfie == '6' ?
                                                                            <div className="col-md-4 order-4">
                                                                                <label htmlFor=""><b>Category</b></label><br />
                                                                                {/* <MultiSelect
                                                                                            style={{ marginTop: '5px' }}
                                                                                            style={{ marginTop: '5px' }}
                                                                                            // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                            options={categoryoptions}
                                                                                            value={compfieCategory}
                                                                                            onChange={setcompfieCategory}
                                                                                            labelledBy="Select"
                                                                                        /> */}
                                                                                {categoryValues.length > 0 ?
                                                                                    <Tooltip title={catTooltip}>
                                                                                        <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            disabled={entityValue && entityValue.length <= 0 ? true : false}
                                                                                            placeholder='Enter Category'
                                                                                            name="units"
                                                                                            id="units"
                                                                                            onDeselect={(e) => {
                                                                                                if (AddType == 'edit') {
                                                                                                    let data = _.find(userManagementDetails && userManagementDetails.um_legal_units
                                                                                                        , { cat_id: e })
                                                                                                    console.log(data, 'eeeeeeeeeee');
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesMultiUnits",
                                                                                                                {
                                                                                                                    "le_id": data.le_id,
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "unit_ids": [data.u_unt_id],
                                                                                                                    "product_id": "2"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })

                                                                                                }

                                                                                            }}
                                                                                            className='form-control'
                                                                                            onChange={(option, value) => compfieCategoryHandelChange(option, value)}
                                                                                            mode="multiple"
                                                                                            value={categoryValues || undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {categoryDatas && categoryDatas.length > 1 ?
                                                                                                <>
                                                                                                    <Option key="all" value="all" style={{ display: categoryselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: categoryunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                </> : false}

                                                                                            {/* {categoryDatas && categoryDatas.length > 0 && categoryDatas.map((item, i) => {
                                                                                            if (EntityData && EntityData.length > 0) {
                                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                return (
                                                                                                    <OptGroup label={data && data.le_name}>
                                                                                                        <Option key={item.cat_id}>
                                                                                                            {item.cat_name}
                                                                                                        </Option>
                                                                                                    </OptGroup>
                                                                                                );

                                                                                            }
                                                                                            else {
                                                                                                return (
                                                                                                    <Option key={item.cat_id}>
                                                                                                        {item.cat_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            }

                                                                                        })} */}
                                                                                            {categoryList && categoryList.length > 0 &&
                                                                                                categoryList.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={subUnitData.cat_id}>{subUnitData.cat_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })}
                                                                                        </Select>
                                                                                    </Tooltip> :
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={entityValue && entityValue.length <= 0 ? true : false}
                                                                                        placeholder='Enter Category'
                                                                                        name="units"
                                                                                        id="units"
                                                                                        onDeselect={(e) => {
                                                                                            if (AddType == 'edit') {
                                                                                                let data = _.find(userManagementDetails && userManagementDetails.um_legal_units
                                                                                                    , { cat_id: e })
                                                                                                console.log(data, 'eeeeeeeeeee');
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesMultiUnits",
                                                                                                            {
                                                                                                                "le_id": data.le_id,
                                                                                                                "user_id": details.user_id,
                                                                                                                "unit_ids": [data.u_unt_id],
                                                                                                                "product_id": "2"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })

                                                                                            }

                                                                                        }}
                                                                                        className='form-control'
                                                                                        onChange={(option, value) => compfieCategoryHandelChange(option, value)}
                                                                                        mode="multiple"
                                                                                        value={categoryValues || undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {categoryDatas && categoryDatas.length > 1 ?
                                                                                            <>
                                                                                                <Option key="all" value="all" style={{ display: categoryselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: categoryunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}

                                                                                        {/* {categoryDatas && categoryDatas.length > 0 && categoryDatas.map((item, i) => {
                                                                                    if (EntityData && EntityData.length > 0) {
                                                                                        let data = _.find(EntityData, { le_id: item.le_id })
                                                                                        return (
                                                                                            <OptGroup label={data && data.le_name}>
                                                                                                <Option key={item.cat_id}>
                                                                                                    {item.cat_name}
                                                                                                </Option>
                                                                                            </OptGroup>
                                                                                        );

                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <Option key={item.cat_id}>
                                                                                                {item.cat_name}
                                                                                            </Option>
                                                                                        );
                                                                                    }

                                                                                })} */}
                                                                                        {categoryList && categoryList.length > 0 &&
                                                                                            categoryList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.cat_id}>{subUnitData.cat_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })}
                                                                                    </Select>}
                                                                            </div> : ''}
                                                                        {category.compfie == '5' || category.compfie == '4' || category.compfie == '6' || category.compfie == '2' || category.compfie == '7' ?
                                                                            <div className="col-md-4 order-4" style={{ marginBottom: "50px" }}>
                                                                                <label htmlFor=""><b>Domain</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                                {domainValue.length > 0 ?
                                                                                    <Tooltip title={domainTooltip}>
                                                                                        <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            disabled={entityValue && entityValue.length <= 0 ? true : false}
                                                                                            placeholder='Enter Domain'
                                                                                            name="units"
                                                                                            id="units"
                                                                                            onDeselect={(e) => {

                                                                                                let splitData = e.split("-")
                                                                                                console.log(splitData, 'splitData');
                                                                                                if (AddType == 'edit') {
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesLE",
                                                                                                                {
                                                                                                                    "le_id": splitData && splitData.length > 0 && splitData[0],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "product_id": "1",
                                                                                                                    "domain_id": Number(splitData && splitData.length > 0 && splitData[0]),


                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })
                                                                                                }
                                                                                                console.log(e, 'eeeeeeeeeee');

                                                                                            }}
                                                                                            className='form-control'
                                                                                            onChange={(option, value) => compfieDomainHandelChange(option, value)}
                                                                                            mode="multiple"
                                                                                            value={domainValue || undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {domainData && domainData.length > 1 ? <> <Option key="all" value="all" style={{ display: domainselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: domainunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                            {/* {domainData && domainData.map((item, i) => {
                                                                                            if (EntityData && EntityData.length > 0) {
                                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <OptGroup label={data && data.le_name}>
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    </OptGroup>
                                                                                                );
                                                                                            }
                                                                                            else {
                                                                                                return (

                                                                                                    <Option key={item.u_dm_id}>
                                                                                                        {item.u_dm_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            }
                                                                                        })} */}

                                                                                            {DomainListData && DomainListData.length > 0 &&
                                                                                                DomainListData.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })}
                                                                                        </Select>
                                                                                    </Tooltip> : <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={entityValue && entityValue.length <= 0 ? true : false}
                                                                                        placeholder='Enter Domain'
                                                                                        name="units"
                                                                                        id="units"
                                                                                        onDeselect={(e) => {

                                                                                            let splitData = e.split("-")
                                                                                            console.log(splitData, 'splitData');
                                                                                            if (AddType == 'edit') {
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_id": splitData && splitData.length > 0 && splitData[0],
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "1",
                                                                                                                "domain_id": Number(splitData && splitData.length > 0 && splitData[0]),


                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                            }
                                                                                            console.log(e, 'eeeeeeeeeee');

                                                                                        }}
                                                                                        className='form-control'
                                                                                        onChange={(option, value) => compfieDomainHandelChange(option, value)}
                                                                                        mode="multiple"
                                                                                        value={domainValue || undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {domainData && domainData.length > 1 ? <> <Option key="all" value="all" style={{ display: domainselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: domainunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                        {/* {domainData && domainData.map((item, i) => {
                                                                                            if (EntityData && EntityData.length > 0) {
                                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <OptGroup label={data && data.le_name}>
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    </OptGroup>
                                                                                                );
                                                                                            }
                                                                                            else {
                                                                                                return (

                                                                                                    <Option key={item.u_dm_id}>
                                                                                                        {item.u_dm_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            }
                                                                                        })} */}

                                                                                        {DomainListData && DomainListData.length > 0 &&
                                                                                            DomainListData.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })}
                                                                                    </Select>}
                                                                                {/* <Select
                                                                                            allowClear={false}
                                                                                            size="default"
                                                                                            placeholder="Enter Domain"
                                                                                            mode="multiple"
                                                                                            defaultValue={DomainValue && DomainValue[0] && DomainValue[0].label}
                                                                                            key={DomainValue && DomainValue[0] && DomainValue[0].label}
                                                                                            onChange={DomainOnchange}
                                                                                           
                                                                                            
                                                                                            style={{ width: "440px", marginTop: '5px' }}
                                                                                        >

                                                                                            {Domainoptions && Domainoptions.map((item, i) => {
                                                                                                console.log(item, "itemitemitemitem");
                                                                                                return (
                                                                                                    <>
                                                                                                        <OptGroup label={item.legal}>
                                                                                                            <Option key={item.u_dm_id}>{item.label}</Option>
                                                                                                        </OptGroup>
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </Select> */}
                                                                                {/* <MultiSelect
                                                                                        // label="kkkk"
                                                                                        // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                        options={Domainoptions}
                                                                                        value={DomainValue}
                                                                                        onChange={setDomainValue}
                                                                                        // onChange={DomainOnchange}
                                                                                    // labelledBy="Select"

                                                                                    /> */}
                                                                                {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                    'domain',
                                                                                    compfie.Domain,
                                                                                    ['required'],
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Domain Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                            </div> : ""}
                                                                    </div>
                                                                </div>

                                                                {/* {category.compfie == '2' || category.compfie == '7' ? <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <label htmlFor=""><b>All units accessible</b></label><br />
                                                                            <input type="checkbox" name="" id="" />

                                                                        </div>
                                                                    </div> : ''} */}
                                                            </TabPane>


                                                            <TabPane
                                                                // disabled={tabValue.compfie_litigation == false ? true : false}
                                                                tab={
                                                                    <span style={{ padding: "0px 50px", fontSize: "16px" }}>
                                                                        {
                                                                            tabValue.compfie_litigation == false ?
                                                                                <CloseOutlined onClick={() => {
                                                                                    setTab("2")
                                                                                    setTabValue({
                                                                                        ...tabValue,
                                                                                        compfie_litigation: !tabValue.compfie_litigation
                                                                                    })

                                                                                }}
                                                                                    style={{ color: 'red' }} /> : <CheckOutlined onClick={() => {
                                                                                        // if (AddType == 'edit') {

                                                                                        if (AddType == 'add') {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie_litigation: !tabValue.compfie_litigation
                                                                                            })
                                                                                        }
                                                                                        else if (AddType == 'edit') {
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_id": GetUserDetails && GetUserDetails.ul_ltg_legal_entities.length > 0 && GetUserDetails.ul_ltg_legal_entities[0].le_id,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "2"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            EdithaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,
                                                                                                setTabValue: setTabValue,
                                                                                                tabValue: tabValue,
                                                                                                type: "litigation"
                                                                                            })



                                                                                        }
                                                                                        // }
                                                                                        // else {
                                                                                        //     setTabValue({
                                                                                        //         ...tabValue,
                                                                                        //         compfie_litigation: !tabValue.compfie_litigation
                                                                                        //     })
                                                                                        // }
                                                                                        if (AddType == 'add') {
                                                                                            setlititigationbusinessGroupValue([])
                                                                                            setlitigationdomainValue([])
                                                                                            setLitigationEntityValue([])
                                                                                            setVendorCategory("")
                                                                                            setCompfieLitigation({
                                                                                                ...compfieLitigation,
                                                                                                category: null,
                                                                                                user: '',
                                                                                                legal_Entity: [],
                                                                                                Domain: [],
                                                                                                businessGroup: '',
                                                                                                serviceProvider: ''
                                                                                            })
                                                                                        }

                                                                                    }} style={{ color: 'green' }}
                                                                                />
                                                                        }

                                                                        <b> Compfie Litigation</b>
                                                                    </span>
                                                                }
                                                                key="2">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor=""><b>User Category</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                <Select className='form-control' placeholder="Enter User Group" disabled={tabValue.compfie_litigation == false ? true : false} value={compfieLitigation.category} style={{ marginTop: '5px' }}
                                                                                    onChange={(data) => {
                                                                                        if (data !== undefined) {
                                                                                            setCompfieLitigation({
                                                                                                ...compfieLitigation,
                                                                                                category: data
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            setCompfieLitigation({
                                                                                                ...compfieLitigation,
                                                                                                category: ""
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                    }
                                                                                    allowClear={true}
                                                                                >

                                                                                    <Option key=''>Select</Option>
                                                                                    <Option key={2} value={2}>Creator</Option>
                                                                                    <Option key={3} value={3}>User</Option>
                                                                                </Select>
                                                                                {/* <select name="user_category" className='form-control' disabled={tabValue.compfie_litigation == false ? true : false} value={compfieLitigation.category} style={{ marginTop: '5px' }} onChange={(e) => {
                                                                                    setCompfieLitigation({
                                                                                        ...compfieLitigation,
                                                                                        category: e.target.value
                                                                                    })
                                                                                }} placeholder="Enter User Group">
                                                                                    <option value="">Select</option>
                                                                                    <option value="2">Creator</option>
                                                                                    <option value="3">User</option>
                                                                                </select> */}
                                                                                {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                    'mobile_no',
                                                                                    compfieLitigation.category,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'User Category Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor=""><b>User</b> <span style={{ "color": "red" }}>*</span></label>
                                                                                {/* <select className='form-control' id="cars"
                                                                                    style={{ marginTop: '5px' }}
                                                                                    disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                    value={compfieLitigation.user}
                                                                                    onChange={(e) => {
                                                                                        setCompfieLitigation({
                                                                                            ...compfieLitigation,
                                                                                            user: e.target.value
                                                                                        })



                                                                                        if (e.target.value == "Service Provider User") {
                                                                                            let vendorCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category, { u_c_name: e.target.value })
                                                                                            setVendorCategory(vendorCategory)
                                                                                        }
                                                                                        //   setVendorCategories(category)
                                                                                    }}>
                                                                                    <option value={null}>Select</option>
                                                                                    <option value="Inhouse User">Inhouse User</option>
                                                                                    <option value="Service Provider User">Service Provider User</option>
                                                                                </select> */} <Select className='form-control' placeholder="Enter User"
                                                                                    style={{ marginTop: '5px' }}
                                                                                    allowClear={true}
                                                                                    disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                    value={compfieLitigation.user}
                                                                                    onChange={(data) => {
                                                                                        if (data !== undefined) {
                                                                                            setCompfieLitigation({
                                                                                                ...compfieLitigation,
                                                                                                user: data
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            setCompfieLitigation({
                                                                                                ...compfieLitigation,
                                                                                                user: ""
                                                                                            })
                                                                                        }


                                                                                        if (data == "Service Provider User") {
                                                                                            let vendorCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category, { u_c_name: data })
                                                                                            setVendorCategory(vendorCategory)
                                                                                        }
                                                                                        //   setVendorCategories(category)
                                                                                    }}>
                                                                                    <Option value={null}>Select</Option>
                                                                                    <Option value='Inhouse User'>Inhouse User</Option>
                                                                                    <Option value='Service Provider User'>Service Provider User</Option>
                                                                                </Select>
                                                                                {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                    'compie_litigation',
                                                                                    compfieLitigation.user,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'User Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                            </div>
                                                                        </div>

                                                                        {compfieLitigation.user == 'Service Provider User' ? <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor=""><b>Service Provider</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                                <Select
                                                                                    // showSearch
                                                                                    // optionFilterProp="children"
                                                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Enter Service Provider"
                                                                                    // onChange={categoryOnChange}
                                                                                    onChange={(data) => {
                                                                                        if (data !== undefined) {
                                                                                            setValue({
                                                                                                ...value,
                                                                                                serviceProvider: data
                                                                                            })
                                                                                            setCompfieLitigation({
                                                                                                ...compfieLitigation,
                                                                                                serviceProvider: data
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            setValue({
                                                                                                ...value,
                                                                                                serviceProvider: ""
                                                                                            })
                                                                                            setCompfieLitigation({
                                                                                                ...compfieLitigation,
                                                                                                serviceProvider: ""
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                    // value={filter.seller}
                                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                                    disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                >
                                                                                    {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                        return (
                                                                                            <Option key={item.u_sp_id}>
                                                                                                {item.u_sp_name}
                                                                                            </Option>
                                                                                        );
                                                                                    })}
                                                                                </Select>
                                                                                {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                    'mobile_no',
                                                                                    compfieLitigation.serviceProvider,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Service Provider Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                            </div>
                                                                        </div>

                                                                            : ''}
                                                                    </div>
                                                                </div>{/* <Divider /> */}
                                                                <h5 style={{ marginTop: "15px" }}><b>Settings</b></h5>
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor=""><b>Business Group</b></label><br />
                                                                            {lititigationbusinessGroupValue.length > 0 ?
                                                                                <Tooltip title={litigationBgTooltip}>
                                                                                    <Select

                                                                                        style={{ marginTop: '5px' }}
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        placeholder="Enter Business Group"
                                                                                        onDeselect={(e, value) => {
                                                                                            if (AddType == 'edit') {
                                                                                                console.log(e, 'eeeeeeeeeee');
                                                                                                let data = _.find(userManagementDetails && userManagementDetails.um_ltmg_legal_entity, { bg_id: Number(e) })
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_id": data && data.le_id,
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "2"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                        disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                        onChange={(option, data) => litigationbusinessGroupHandelChange(option, data)}
                                                                                        // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                        mode="multiple"
                                                                                        value={lititigationbusinessGroupValue || undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {litigationbusinessGroupData && litigationbusinessGroupData.length > 1 ?
                                                                                            <>
                                                                                                <Option key="all" value="all" style={{ display: litigationbusinessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: litigationbusinessGroupunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}


                                                                                        {litigationbusinessGroupData && litigationbusinessGroupData.length > 0 &&
                                                                                            litigationbusinessGroupData.map((item) => {
                                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <Option key={item.bg_id}>
                                                                                                        {item.bg_name}
                                                                                                    </Option>

                                                                                                )

                                                                                            }

                                                                                            )}
                                                                                    </Select>
                                                                                </Tooltip> :
                                                                                <Select

                                                                                    style={{ marginTop: '5px' }}
                                                                                    name="units"
                                                                                    id="units"
                                                                                    className='form-control'
                                                                                    placeholder="Enter Business Group"
                                                                                    onDeselect={(e, value) => {
                                                                                        if (AddType == 'edit') {
                                                                                            console.log(e, 'eeeeeeeeeee');
                                                                                            let data = _.find(userManagementDetails && userManagementDetails.um_ltmg_legal_entity, { bg_id: Number(e) })
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_id": data && data.le_id,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "2"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            selectHaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,

                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                    disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                    onChange={(option, data) => litigationbusinessGroupHandelChange(option, data)}
                                                                                    // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                    mode="multiple"
                                                                                    value={lititigationbusinessGroupValue || undefined}
                                                                                    maxTagCount='responsive'
                                                                                >
                                                                                    {litigationbusinessGroupData && litigationbusinessGroupData.length > 1 ?
                                                                                        <>
                                                                                            <Option key="all" value="all" style={{ display: litigationbusinessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: litigationbusinessGroupunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                        </> : false}


                                                                                    {litigationbusinessGroupData && litigationbusinessGroupData.length > 0 &&
                                                                                        litigationbusinessGroupData.map((item) => {
                                                                                            let data = _.find(EntityData, { le_id: item.le_id })
                                                                                            return (

                                                                                                <Option key={item.bg_id}>
                                                                                                    {item.bg_name}
                                                                                                </Option>

                                                                                            )

                                                                                        }

                                                                                        )}
                                                                                </Select>}
                                                                        </div>
                                                                        <div className="col-md-4">

                                                                            <label htmlFor=""><b>Legal Entity</b> <span style={{ "color": "red" }}>*</span> </label><br />

                                                                            {litigationEntityValue.length > 0 ?
                                                                                <Tooltip title={litigationEntityTooltip}>
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={compfieLitigation.category == null ? true : false}
                                                                                        placeholder='Enter Legal Entity'
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        onDeselect={(e) => {
                                                                                            if (AddType == 'edit') {
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_id": e,
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "2"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                                if (litigationEntityValue && litigationEntityValue.value.length == 0) {
                                                                                                    setlitigationdomainValue([])
                                                                                                }
                                                                                            }

                                                                                            console.log(e, 'eeeeeeeeeee');

                                                                                        }}
                                                                                        onChange={(option) => litigationEntityHandelChange(option)}
                                                                                        mode="multiple"
                                                                                        value={litigationEntityValue || undefined}
                                                                                        maxTagCount='responsive'
                                                                                        tagRender={litigationEntityValue.length > 1 ? true : false}

                                                                                    >
                                                                                        {litigationEntityData && litigationEntityData.length > 0 ? <> <Option key="all" value="all" style={{ display: LitigationEntityselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: LitigationEntitysunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}
                                                                                        {LitigationLegalEntityList && LitigationLegalEntityList.length > 0 ? LitigationLegalEntityList && LitigationLegalEntityList.length > 0 &&
                                                                                            LitigationLegalEntityList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            }) : <>  <OptGroup label="Others">
                                                                                            </OptGroup>{litigationEntityData && litigationEntityData.map((item, i) => {
                                                                                                return (

                                                                                                    <Option key={item.le_id}>
                                                                                                        {item.le_name}
                                                                                                    </Option>

                                                                                                );
                                                                                            })}</>}




                                                                                    </Select>
                                                                                </Tooltip> : <Select
                                                                                    style={{ marginTop: '5px' }}
                                                                                    disabled={compfieLitigation.category == null ? true : false}
                                                                                    placeholder='Enter Legal Entity'
                                                                                    name="units"
                                                                                    id="units"
                                                                                    className='form-control'
                                                                                    onDeselect={(e) => {
                                                                                        if (AddType == 'edit') {
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_id": e,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "2"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            selectHaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,

                                                                                            })
                                                                                            if (litigationEntityValue && litigationEntityValue.value.length == 0) {
                                                                                                setlitigationdomainValue([])
                                                                                            }
                                                                                        }

                                                                                        console.log(e, 'eeeeeeeeeee');

                                                                                    }}
                                                                                    onChange={(option) => litigationEntityHandelChange(option)}
                                                                                    mode="multiple"
                                                                                    value={litigationEntityValue || undefined}
                                                                                    maxTagCount='responsive'
                                                                                    tagRender={litigationEntityValue.length > 1 ? true : false}

                                                                                >
                                                                                    {litigationEntityData && litigationEntityData.length > 0 ? <> <Option key="all" value="all" style={{ display: LitigationEntityselectcheckbox }}>---SELECT ALL---</Option>
                                                                                        <Option key="unselect" value="unselect" style={{ display: LitigationEntitysunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                    {LitigationLegalEntityList && LitigationLegalEntityList.length > 0 ? LitigationLegalEntityList && LitigationLegalEntityList.length > 0 &&
                                                                                        LitigationLegalEntityList.map((finalList_unitlist) => {
                                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                return (
                                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                            <Option value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                        ))}
                                                                                                    </OptGroup>
                                                                                                )
                                                                                        }) : <>  <OptGroup label="Others">
                                                                                        </OptGroup>{litigationEntityData && litigationEntityData.map((item, i) => {
                                                                                            return (

                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>

                                                                                            );
                                                                                        })}</>}




                                                                                </Select>}
                                                                            {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                'legalentity',
                                                                                compfieLitigation.legal_Entity,
                                                                                'required',
                                                                                {
                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Legal Entity Required',
                                                                                        // regex: 'Please enter valid mobile number'
                                                                                    }
                                                                                })) : ''}
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor=""><b>Domain</b> < span style={{ "color": "red" }}>*</span> </label><br />
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                                <option value="volvo">None Selected</option>
                                                                            </select> */}
                                                                            {/* <Select
                                                                                disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                // showSearch
                                                                                // optionFilterProp="children"
                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                allowClear={false}
                                                                                size="default"
                                                                                mode='multiple'
                                                                                placeholder="Enter Unit"
                                                                                onChange={(data) => handleChange(data)
                                                                                }
                                                                                defaultValue={compfieLitigation.Domain}
                                                                                style={{ width: '65%' }}
                                                                            >
                                                                                {domain && domain.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.u_dm_id}>
                                                                                            {item.u_dm_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select> */}


                                                                            {/* <MultiSelect
                                                                            style={{ width: '100%', marginTop: '5px' }}
                                                                            // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                            options={LitigationDomainoptions}
                                                                            value={LitigationDomainValue}
                                                                            onChange={setLitigationDomainValue}
                                                                            labelledBy="Select"
                                                                            disabled={tabValue.compfie_litigation == false ? true : false}

                                                                        /> */}
                                                                            {litigationdomainValue.length > 0 ?
                                                                                <Tooltip title={litigationDomainTooltip}>

                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={litigationEntityValue && litigationEntityValue.length <= 0 ? true : false}
                                                                                        placeholder='Enter Domain'
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        onDeselect={(e) => {
                                                                                            let splitData = e.split("-")
                                                                                            console.log(splitData, 'splitData');
                                                                                            if (AddType == 'edit') {
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_id": splitData && splitData.length > 0 && splitData[0],
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "2",
                                                                                                                "domain_id": Number(splitData && splitData.length > 0 && splitData[0]),


                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                            }
                                                                                            console.log(e, 'eeeeeeeeeee');

                                                                                        }}
                                                                                        onChange={(option) => LitigationDomainHandelChange(option)}
                                                                                        mode="multiple"
                                                                                        value={litigationdomainValue.includes(undefined) == true ? undefined : litigationdomainValue}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {litigationDomainData && litigationDomainData.length > 1 ? <>
                                                                                            <Option key="all" value="all" style={{ display: litigationdomainselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: litigationdomainunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                        </> : false}

                                                                                        {/* {litigationDomainData && litigationDomainData.map((item, i) => {
                                                                            if (litigationEntityData && litigationEntityData.length > 0) {
                                                                                let data = _.find(litigationEntityData, { le_id: item.le_id })
                                                                                return (

                                                                                    <OptGroup label={data && data.le_name}>
                                                                                        <Option key={item.u_dm_id}>
                                                                                            {item.u_dm_name}
                                                                                        </Option>
                                                                                    </OptGroup>
                                                                                );
                                                                            }
                                                                            else {
                                                                                return (

                                                                                    <Option key={item.u_dm_id}>
                                                                                        {item.u_dm_name}
                                                                                    </Option>
                                                                                );
                                                                            }

                                                                        })} */}
                                                                                        {LitigationDomainList && LitigationDomainList.length > 0 &&
                                                                                            LitigationDomainList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })}
                                                                                    </Select>
                                                                                </Tooltip> :
                                                                                <Select
                                                                                    style={{ marginTop: '5px' }}
                                                                                    disabled={litigationEntityValue && litigationEntityValue.length <= 0 ? true : false}
                                                                                    placeholder='Enter Domain'
                                                                                    name="units"
                                                                                    id="units"
                                                                                    className='form-control'
                                                                                    onDeselect={(e) => {
                                                                                        let splitData = e.split("-")
                                                                                        console.log(splitData, 'splitData');
                                                                                        if (AddType == 'edit') {
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_id": splitData && splitData.length > 0 && splitData[0],
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "2",
                                                                                                            "domain_id": Number(splitData && splitData.length > 0 && splitData[0]),


                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            selectHaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,

                                                                                            })
                                                                                        }
                                                                                        console.log(e, 'eeeeeeeeeee');

                                                                                    }}
                                                                                    onChange={(option) => LitigationDomainHandelChange(option)}
                                                                                    mode="multiple"
                                                                                    value={litigationdomainValue.includes(undefined) == true ? undefined : litigationdomainValue}
                                                                                    maxTagCount='responsive'
                                                                                >
                                                                                    {litigationDomainData && litigationDomainData.length > 1 ? <>
                                                                                        <Option key="all" value="all" style={{ display: litigationdomainselectcheckbox }}>---SELECT ALL---</Option>
                                                                                        <Option key="unselect" value="unselect" style={{ display: litigationdomainunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                    </> : false}

                                                                                    {/* {litigationDomainData && litigationDomainData.map((item, i) => {
                                                                     if (litigationEntityData && litigationEntityData.length > 0) {
                                                                         let data = _.find(litigationEntityData, { le_id: item.le_id })
                                                                         return (

                                                                             <OptGroup label={data && data.le_name}>
                                                                                 <Option key={item.u_dm_id}>
                                                                                     {item.u_dm_name}
                                                                                 </Option>
                                                                             </OptGroup>
                                                                         );
                                                                     }
                                                                     else {
                                                                         return (

                                                                             <Option key={item.u_dm_id}>
                                                                                 {item.u_dm_name}
                                                                             </Option>
                                                                         );
                                                                     }

                                                                 })} */}
                                                                                    {LitigationDomainList && LitigationDomainList.length > 0 &&
                                                                                        LitigationDomainList.map((finalList_unitlist) => {
                                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                return (
                                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                            <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                        ))}
                                                                                                    </OptGroup>
                                                                                                )
                                                                                        })}
                                                                                </Select>}
                                                                            {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                'domain',
                                                                                compfieLitigation.Domain,
                                                                                'required',
                                                                                {
                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Domain Required',
                                                                                        // regex: 'Please enter valid mobile number'
                                                                                    }
                                                                                })) : ''}
                                                                        </div>
                                                                        <div className="col-md-6"></div>
                                                                    </div>
                                                                </div>
                                                                <div>


                                                                </div>

                                                            </TabPane>


                                                            <TabPane
                                                                // disabled={tabValue.compfie_vendor == false ? true : false}
                                                                tab={
                                                                    <span style={{ padding: "0px 50px", fontSize: "16px" }}>
                                                                        {
                                                                            tabValue.compfie_vendor == false ?
                                                                                <CloseOutlined onClick={() => {
                                                                                    setTabValue({
                                                                                        ...tabValue,
                                                                                        compfie_vendor: !tabValue.compfie_vendor
                                                                                    })
                                                                                    setTab("3")
                                                                                }}
                                                                                    style={{ color: 'red' }} /> : <CheckOutlined onClick={() => {
                                                                                        // if (AddType == 'edit') {
                                                                                        //     setTabValue({
                                                                                        //         ...tabValue,
                                                                                        //         compfie_vendor: tabValue.compfie_vendor
                                                                                        //     })
                                                                                        // }
                                                                                        // else {

                                                                                        if (AddType == 'add') {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie_vendor: !tabValue.compfie_vendor
                                                                                            })
                                                                                        }
                                                                                        else if (AddType == 'edit') {
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_id": GetUserDetails && GetUserDetails.ul_vend_legal_entities
                                                                                                                .length > 0 && GetUserDetails.ul_vend_legal_entities
                                                                                                                [0].le_id,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "3"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            EdithaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,
                                                                                                setTabValue: setTabValue,
                                                                                                tabValue: tabValue,
                                                                                                type: "vendor"
                                                                                            })



                                                                                        }
                                                                                        // }
                                                                                        if (AddType == 'add') {
                                                                                            setVendorbusinessGroupValue([])
                                                                                            setVendoreEntityValue([])
                                                                                            setvendordomainValues([])
                                                                                            setvendorDivisionValue([])
                                                                                            setVendorCategoryValues([])
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                category: null,
                                                                                                seating_Unit: null,
                                                                                                user_Group: null,
                                                                                                user_level: '1',
                                                                                                entity: '',
                                                                                                serviceProvider: null,
                                                                                                checked_value: '',
                                                                                                legal_Entity: [],
                                                                                                Domain: [],
                                                                                                Division: '',
                                                                                            })
                                                                                        }

                                                                                    }} style={{ color: 'green' }} />
                                                                        }

                                                                        <b> Compfie Vendor</b>
                                                                    </span>
                                                                }
                                                                key="3">

                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor=""><b>Category</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                <br />
                                                                                <Select
                                                                                    disabled={tabValue.compfie_vendor == false || ((AddType == 'edit') && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1)) ? true : false}
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Enter Category"
                                                                                    // onChange={categoryOnChange}
                                                                                    onChange={(data) => {
                                                                                        if (data !== undefined) {
                                                                                            let CategoryData = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_c_id: Number(data) })
                                                                                            setVendorgroupcompfie(CategoryData)
                                                                                            setCategory({
                                                                                                ...category,
                                                                                                compfie_vendor: data
                                                                                            })
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                category: data
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            setCategory({
                                                                                                ...category,
                                                                                                compfie_vendor: "",

                                                                                            })
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                category: "",
                                                                                                user_Group: ''
                                                                                            })
                                                                                            setVendoreEntityValue([])
                                                                                        }
                                                                                    }}
                                                                                    value={compfieVendorValue.category || undefined}
                                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                                >
                                                                                    {compfieLitigation.user == 'Service Provider User' ? vendorcategory.map((item, i) => {
                                                                                        return (
                                                                                            <Option key={item.u_c_id}>
                                                                                                {item.u_c_name}
                                                                                            </Option>
                                                                                        );
                                                                                    }) : userManagementDetails && userManagementDetails.um_user_category && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category.map((item, i) => {
                                                                                        return (
                                                                                            <Option key={item.u_c_id}>
                                                                                                {item.u_c_name}
                                                                                            </Option>
                                                                                        );
                                                                                    })}
                                                                                </Select>

                                                                                {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                    'category',
                                                                                    compfieVendorValue.category,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Category Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}

                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-4">
                                                                            <label htmlFor=""><b>User Group</b></label><br />
                                                                            <Select
                                                                                disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                // showSearch
                                                                                // optionFilterProp="children"
                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                allowClear={true}
                                                                                size="default"
                                                                                placeholder="Enter User Group"

                                                                                onChange={(data) => {
                                                                                    if (data !== undefined) {
                                                                                        setCompfieVendorValue({
                                                                                            ...compfieVendorValue,
                                                                                            user_Group: data
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        setCompfieVendorValue({
                                                                                            ...compfieVendorValue,
                                                                                            user_Group: ""
                                                                                        })
                                                                                    }
                                                                                }
                                                                                }
                                                                                value={compfieVendorValue.user_Group || undefined}
                                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                            >
                                                                                {vendorgroupcompfie && vendorgroupcompfie.length > 0 && vendorgroupcompfie.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.u_g_id}>
                                                                                            {item.u_g_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select>

                                                                        </div>

                                                                        {category.compfie_vendor == '5' || category.compfie_vendor == '4' || category.compfie_vendor == '3' ? <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor=""><b>User Level</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                {/* <select name="" id="" className='form-control' onChange={(e) => {
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    user_level: e.target.value
                                                                                                })


                                                                                            }}
                                                                                                value={compfieVendorValue.user_level}
                                                                                                style={{ width: '100%', marginTop: '5px' }}  >
                                                                                                <option value="1">Level 1(Low)</option>
                                                                                                <option value="2">Level 2</option>
                                                                                                <option value="3">Level 3</option>
                                                                                                <option value="4">Level 4</option>
                                                                                                <option value="5">Level 5</option>
                                                                                                <option value="6">Level 6</option>
                                                                                                <option value="7">Level 7</option>
                                                                                                <option value="8">Level 8</option>
                                                                                                <option value="9">Level 9</option>
                                                                                                <option value="10">Level 10(High)</option>

                                                                                            </select> */}


                                                                                <Select className='form-control' placeholder='Enter Userlevel'
                                                                                    allowClear={true}
                                                                                    value={compfieVendorValue.user_level} style={{ marginTop: "5px", width: '100%' }}
                                                                                    onChange={(data) => {
                                                                                        if (data !== undefined) {
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                user_level: data
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                user_level: ""
                                                                                            })
                                                                                        }
                                                                                    }}>
                                                                                    <Option value="1">Level 1 (Low)</Option>
                                                                                    <Option value="2">Level 2</Option>
                                                                                    <Option value="3">Level 3</Option>
                                                                                    <Option value="4">Level 4</Option>
                                                                                    <Option value="5">Level 5</Option>
                                                                                    <Option value="6">Level 6</Option>
                                                                                    <Option value="7">Level 7</Option>
                                                                                    <Option value="8">Level 8</Option>
                                                                                    <Option value="9">Level 9</Option>
                                                                                    <Option value="10">Level 10 (High)</Option>
                                                                                </Select>
                                                                                {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                    'user_level',
                                                                                    compfieVendorValue.user_level,
                                                                                    ['required'],
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'User Level Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                            </div>
                                                                        </div> : ''}


                                                                        {category.compfie_vendor == '6' ?

                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor=""><b>Service Provider</b></label> <span style={{ "color": "red" }}>*</span> <br />
                                                                                    <Select
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={true}
                                                                                        size="default"
                                                                                        placeholder="Enter Service Provider"
                                                                                        // onChange={categoryOnChange}
                                                                                        onChange={(data) => {
                                                                                            if (data !== undefined) {
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    serviceProvider: data
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    serviceProvider: ""
                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                        value={compfieVendorValue.serviceProvider || undefined}
                                                                                        style={{ width: '100%', marginTop: '5px' }}
                                                                                        disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                    >
                                                                                        {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.u_sp_id}>
                                                                                                    {item.u_sp_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select>
                                                                                    {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                        'serviceProvider',
                                                                                        compfieVendorValue.serviceProvider,
                                                                                        ['required'],
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Service Provider Required',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''}
                                                                                </div>
                                                                            </div>

                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                                {/* <Divider /> */}
                                                                <h5 style={{ marginTop: "15px" }}>Settings</h5>
                                                                <div className="col-md-12" >
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <label htmlFor=""><b>Business Group</b></label><br />
                                                                            {vendorbusinessGroupValue.length > 0 ?
                                                                                <Tooltip title={vendorBgTooltip}>
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        placeholder="Enter Business Group"
                                                                                        onDeselect={(e, value) => {
                                                                                            if (AddType == 'edit') {
                                                                                                console.log(e, 'eeeeeeeeeee');
                                                                                                let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { bg_id: Number(e) })
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_id": data && data.le_id,
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "3"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                        onChange={(option, data) => vendorbusinessGroupHandelChange(option, data)}
                                                                                        disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                        // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                        mode="multiple"
                                                                                        value={vendorbusinessGroupValue || undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {vendorbusinessGroupData && vendorbusinessGroupData.length > 1 ?
                                                                                            <>
                                                                                                <Option key="all" value="all" style={{ display: vendorbusinessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: vendorbusinessGroupUnselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}


                                                                                        {vendorbusinessGroupData && vendorbusinessGroupData.length > 0 &&
                                                                                            vendorbusinessGroupData.map((item) => {
                                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <Option key={item.bg_id}>
                                                                                                        {item.bg_name}
                                                                                                    </Option>

                                                                                                )

                                                                                            }

                                                                                            )}
                                                                                    </Select>
                                                                                </Tooltip> :
                                                                                <Select
                                                                                    style={{ marginTop: '5px' }}
                                                                                    name="units"
                                                                                    id="units"
                                                                                    className='form-control'
                                                                                    placeholder="Enter Business Group"
                                                                                    onDeselect={(e, value) => {
                                                                                        if (AddType == 'edit') {
                                                                                            console.log(e, 'eeeeeeeeeee');
                                                                                            let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { bg_id: Number(e) })
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_id": data && data.le_id,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "3"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            selectHaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,

                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                    onChange={(option, data) => vendorbusinessGroupHandelChange(option, data)}
                                                                                    disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                    // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                    mode="multiple"
                                                                                    value={vendorbusinessGroupValue || undefined}
                                                                                    maxTagCount='responsive'
                                                                                >
                                                                                    {vendorbusinessGroupData && vendorbusinessGroupData.length > 1 ?
                                                                                        <>
                                                                                            <Option key="all" value="all" style={{ display: vendorbusinessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: vendorbusinessGroupUnselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                        </> : false}


                                                                                    {vendorbusinessGroupData && vendorbusinessGroupData.length > 0 &&
                                                                                        vendorbusinessGroupData.map((item) => {
                                                                                            let data = _.find(EntityData, { le_id: item.le_id })
                                                                                            return (

                                                                                                <Option key={item.bg_id}>
                                                                                                    {item.bg_name}
                                                                                                </Option>

                                                                                            )

                                                                                        }

                                                                                        )}
                                                                                </Select>}
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <label htmlFor=""><b>Legal Entity</b> <span style={{ "color": "red" }}>*</span></label> <br />
                                                                            {/* <Select
                                                                                        disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={false}
                                                                                        size="default"
                                                                                        placeholder="Enter Unit"

                                                                                        onChange={(data) => {
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                legal_Entity: data
                                                                                            })

                                                                                            let division = _.filter(userManagementDetails && userManagementDetails.um_cmpf_group_division, { le_id: data })
                                                                                            setDivision(division)
                                                                                            let domain = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: data })
                                                                                            setDomain(domain)
                                                                                        }}
                                                                                        value={compfieVendorValue.legal_Entity}
                                                                                        style={{ width: '65%' }}
                                                                                    >
                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}

                                                                            {/* <Select
                                                                                 
                                                                                        allowClear={false}
                                                                                        size="default"
                                                                                        placeholder="Enter Entity"
                                                                                        mode="multiple"
                                                                                        onChange={(data) => handledChangeVendor(data)}
                                                                                        defaultValue={compfieVendorValue.legal_Entity}
                                                                                        disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                      
                                                                                        onChange={(data) => handledChangeVendor(data)
                                                                                        }
                                                                                        
                                                                                        style={{ width: '65%' }}
                                                                                    >

                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}
                                                                            {/* <MultiSelect
                                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                                    // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                    options={VendorEntityOption}
                                                                                    value={VendorEntity}
                                                                                    onChange={setVendorEntity}
                                                                                    labelledBy="Enter Legal Entity"
                                                                                    disabled={tabValue.compfie_vendor == false ? true : false}

                                                                                /> */}
                                                                            {vendoreEntityValue.length > 0 ?
                                                                                <Tooltip title={vendorEntityTooltip}>
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={compfieVendorValue.category == null ? true : false}
                                                                                        placeholder='Enter Legal Entity'
                                                                                        name="units"
                                                                                        id="units"
                                                                                        onDeselect={(e) => {
                                                                                            if (AddType == 'edit') {
                                                                                                console.log(e, 'eeeeeeeeeee');
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_id": e,
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "3"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                                if (vendoreEntityValue && vendoreEntityValue.value.length == 0) {
                                                                                                    setvendorDomainValue([])
                                                                                                }
                                                                                            }

                                                                                        }}
                                                                                        className='form-control'
                                                                                        onChange={(option) => vendorEntityHandelChange(option)}
                                                                                        mode="multiple"
                                                                                        value={vendoreEntityValue || undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {vendorEntityData && vendorEntityData.length > 1 ? <>
                                                                                            <Option key="all" value="all" style={{ display: VendorEntityselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: VendorEntityunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                        </> : false}
                                                                                        {/* 
                                                                                {vendorEntityData && vendorEntityData.length > 0 && vendorEntityData.map((item, i) => {
                                                                                    if (vendorbusinessGroupData && vendorbusinessGroupData.length > 0) {
                                                                                        let data = _.find(vendorbusinessGroupData, { bg_id: item.bg_id })
                                                                                        return (
                                                                                            <OptGroup label={data && data.bg_name}>
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            </OptGroup>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <Option key={item.le_id}>
                                                                                                {item.le_name}
                                                                                            </Option>
                                                                                        );
                                                                                    }

                                                                                })} */}


                                                                                        {vendorEntityList && vendorEntityList.length > 0 ?
                                                                                            vendorEntityList && vendorEntityList.length > 0 &&
                                                                                            vendorEntityList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            }) : <>  <OptGroup label="Others">
                                                                                            </OptGroup>{vendorEntityData && vendorEntityData.map((item, i) => {
                                                                                                return (

                                                                                                    <Option key={item.le_id}>
                                                                                                        {item.le_name}
                                                                                                    </Option>

                                                                                                );
                                                                                            })}</>}




                                                                                    </Select>
                                                                                </Tooltip> :
                                                                                <Select
                                                                                    style={{ marginTop: '5px' }}
                                                                                    disabled={compfieVendorValue.category == null ? true : false}
                                                                                    placeholder='Enter Legal Entity'
                                                                                    name="units"
                                                                                    id="units"
                                                                                    onDeselect={(e) => {
                                                                                        if (AddType == 'edit') {
                                                                                            console.log(e, 'eeeeeeeeeee');
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_id": e,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "3"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            selectHaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,

                                                                                            })
                                                                                            if (vendoreEntityValue && vendoreEntityValue.value.length == 0) {
                                                                                                setvendorDomainValue([])
                                                                                            }
                                                                                        }

                                                                                    }}
                                                                                    className='form-control'
                                                                                    onChange={(option) => vendorEntityHandelChange(option)}
                                                                                    mode="multiple"
                                                                                    value={vendoreEntityValue || undefined}
                                                                                    maxTagCount='responsive'
                                                                                >
                                                                                    {vendorEntityData && vendorEntityData.length > 1 ? <>
                                                                                        <Option key="all" value="all" style={{ display: VendorEntityselectcheckbox }}>---SELECT ALL---</Option>
                                                                                        <Option key="unselect" value="unselect" style={{ display: VendorEntityunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                    </> : false}
                                                                                    {/* 
                                                                                {vendorEntityData && vendorEntityData.length > 0 && vendorEntityData.map((item, i) => {
                                                                                    if (vendorbusinessGroupData && vendorbusinessGroupData.length > 0) {
                                                                                        let data = _.find(vendorbusinessGroupData, { bg_id: item.bg_id })
                                                                                        return (
                                                                                            <OptGroup label={data && data.bg_name}>
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            </OptGroup>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <Option key={item.le_id}>
                                                                                                {item.le_name}
                                                                                            </Option>
                                                                                        );
                                                                                    }

                                                                                })} */}


                                                                                    {vendorEntityList && vendorEntityList.length > 0 ?
                                                                                        vendorEntityList && vendorEntityList.length > 0 &&
                                                                                        vendorEntityList.map((finalList_unitlist) => {
                                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                return (
                                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                            <Option value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                        ))}
                                                                                                    </OptGroup>
                                                                                                )
                                                                                        }) : <>  <OptGroup label="Others">
                                                                                        </OptGroup>{vendorEntityData && vendorEntityData.map((item, i) => {
                                                                                            return (

                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>

                                                                                            );
                                                                                        })}</>}




                                                                                </Select>}
                                                                            {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                'legal_Entity',
                                                                                compfieVendorValue.legal_Entity,
                                                                                ['required'],
                                                                                {
                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Legal Entity Required',
                                                                                        // regex: 'Please enter valid mobile number'
                                                                                    }
                                                                                })) : ''}
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                        </div>



                                                                        {category.compfie_vendor == '5' || category.compfie_vendor == '6' ?
                                                                            <div className="col-md-4">
                                                                                <label htmlFor=""><b>Category</b></label><br />
                                                                                {/* <Select
                                                                                               
                                                                                                allowClear={false}
                                                                                                size="default"
                                                                                                placeholder="Enter Unit"

                                                                                                onChange={(data) => {
                                                                                                    setCompfieVendorValue({
                                                                                                        ...compfieVendorValue,
                                                                                                        categoryValue: data
                                                                                                    })
                                                                                                }}
                                                                                                value={compfieVendorValue.categoryValue}
                                                                                                style={{ width: '65%' }}
                                                                                                disabled={AddType == 'edit' ? true : false}
                                                                                            >
                                                                                                {categories && categories.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.cat_id}>
                                                                                                            {item.cat_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select> */}
                                                                                {VendorcategoryValues.length > 0 ?
                                                                                    <Tooltip title={vendorcatTooltip}>
                                                                                        <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            disabled={vendoreEntityValue && vendoreEntityValue.length <= 0 ? true : false}
                                                                                            placeholder='Enter Category'
                                                                                            name="units"
                                                                                            id="units"
                                                                                            onDeselect={(e) => {
                                                                                                if (AddType == 'edit') {
                                                                                                    let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_units

                                                                                                        , { cat_id: e })
                                                                                                    console.log(data, 'eeeeeeeeeee');
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesMultiUnits",
                                                                                                                {
                                                                                                                    "le_id": data.le_id,
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "unit_ids": [data.u_unt_id],
                                                                                                                    "product_id": "2"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })

                                                                                                }

                                                                                            }}
                                                                                            className='form-control'
                                                                                            onChange={(option) => vendorCategoryHandelChange(option)}
                                                                                            mode="multiple"
                                                                                            value={VendorcategoryValues || undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {vendorcategoryDatas && vendorcategoryDatas.length > 1 ?
                                                                                                <>
                                                                                                    <Option key="all" value="all" style={{ display: vendorcategoryselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: vendorcategoryunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                </> : false}

                                                                                            {/* {categoryDatas && categoryDatas.length > 0 && categoryDatas.map((item, i) => {
                                                                                            if (vendorEntityData && vendorEntityData.length > 0) {
                                                                                                let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                                                return (
                                                                                                    <OptGroup label={data && data.le_name}>
                                                                                                        <Option key={item.cat_id}>
                                                                                                            {item.cat_name}
                                                                                                        </Option>
                                                                                                    </OptGroup>
                                                                                                );
                                                                                            }
                                                                                            else {
                                                                                                return (
                                                                                                    <Option key={item.cat_id}>
                                                                                                        {item.cat_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            }

                                                                                        })} */}
                                                                                            {vendorcategoryList && vendorcategoryList.length > 0 &&
                                                                                                vendorcategoryList.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={subUnitData.cat_id}>{subUnitData.cat_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })}
                                                                                        </Select>
                                                                                    </Tooltip> :
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={vendoreEntityValue && vendoreEntityValue.length <= 0 ? true : false}
                                                                                        placeholder='Enter Category'
                                                                                        name="units"
                                                                                        id="units"
                                                                                        onDeselect={(e) => {
                                                                                            if (AddType == 'edit') {
                                                                                                let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_units

                                                                                                    , { cat_id: e })
                                                                                                console.log(data, 'eeeeeeeeeee');
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesMultiUnits",
                                                                                                            {
                                                                                                                "le_id": data.le_id,
                                                                                                                "user_id": details.user_id,
                                                                                                                "unit_ids": [data.u_unt_id],
                                                                                                                "product_id": "2"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })

                                                                                            }

                                                                                        }}
                                                                                        className='form-control'
                                                                                        onChange={(option) => vendorCategoryHandelChange(option)}
                                                                                        mode="multiple"
                                                                                        value={VendorcategoryValues || undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {vendorcategoryDatas && vendorcategoryDatas.length > 1 ?
                                                                                            <>
                                                                                                <Option key="all" value="all" style={{ display: vendorcategoryselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: vendorcategoryunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}

                                                                                        {/* {categoryDatas && categoryDatas.length > 0 && categoryDatas.map((item, i) => {
                                                                                    if (vendorEntityData && vendorEntityData.length > 0) {
                                                                                        let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                                        return (
                                                                                            <OptGroup label={data && data.le_name}>
                                                                                                <Option key={item.cat_id}>
                                                                                                    {item.cat_name}
                                                                                                </Option>
                                                                                            </OptGroup>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <Option key={item.cat_id}>
                                                                                                {item.cat_name}
                                                                                            </Option>
                                                                                        );
                                                                                    }

                                                                                })} */}
                                                                                        {vendorcategoryList && vendorcategoryList.length > 0 &&
                                                                                            vendorcategoryList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.cat_id}>{subUnitData.cat_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })}
                                                                                    </Select>}
                                                                                {/* <MultiSelect

                                                                                            // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                            options={categoryoptions}
                                                                                            value={compfieCategory}
                                                                                            onChange={setcompfieCategory}
                                                                                            labelledBy="Select"
                                                                                            style={{ width: '100%', marginTop: '5px' }}
                                                                                        /> */}
                                                                            </div> : category.compfie_vendor == '2' || category.compfie_vendor == '7' ?
                                                                                <div className="col-md-6">
                                                                                    <div className="col-md-3">

                                                                                        {/* <div className="form-group"> */}
                                                                                        <label htmlFor=""><b>All Vendor Accessible</b></label><br />
                                                                                        <input type="checkbox" name="" id="" style={{ width: "40px", height: "20px", marginTop: '5px' }}
                                                                                            checked={vendorAccess == true ? true : false}
                                                                                            onChange={(e) => {
                                                                                                if ((e.target.checked == true)) {
                                                                                                    let temp = []
                                                                                                    setVendorAccess(true)
                                                                                                    console.log(userManagementDetails && userManagementDetails.um_vndr_legal_units.length, 'userManagementDetails && userManagementDetails.um_legal_units.length ');
                                                                                                    if (userManagementDetails && userManagementDetails.um_vndr_legal_units.length > 0) {
                                                                                                        for (let i in userManagementDetails && userManagementDetails.um_vndr_legal_units) {
                                                                                                            temp.push({
                                                                                                                le_id: userManagementDetails && userManagementDetails.um_vndr_legal_units[i].le_id,
                                                                                                                u_id: userManagementDetails && userManagementDetails.um_vndr_legal_units[i].u_unt_id
                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                    setVendorUnitDetails(temp)
                                                                                                }
                                                                                                else {
                                                                                                    setVendorAccess(false)
                                                                                                    setVendorUnitDetails([])
                                                                                                    setVendorAll([])
                                                                                                    setVendorClosedAll([])
                                                                                                    // setAllUnitData([])
                                                                                                }
                                                                                            }}

                                                                                        />
                                                                                    </div>
                                                                                    {/* </div> */}

                                                                                </div> : ''}
                                                                        {category.compfie_vendor == '5' || category.compfie_vendor == '6' ?
                                                                            <div className="col-md-4">
                                                                                <label htmlFor=""><b>Division</b>  </label><br />
                                                                                {/* <select name="cars" className='form-control' id="cars">
                                                                                <option value="volvo">None Selected</option>
                                                                            </select> */}
                                                                                {/* <Select
                                                                                              
                                                                                                allowClear={false}
                                                                                                size="default"
                                                                                                placeholder="Enter Unit"
                                                                                                onChange={(data) => handleChanged(data)
                                                                                                }
                                                                                                mode='multiple'
                                                                                                defaultValue={compfieVendorValue.Domain}

                                                                                                style={{ width: '65%' }}
                                                                                            >
                                                                                                {domain && domain.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select> */}
                                                                                {vendorDivisionValue.length > 0 ?
                                                                                    <Tooltip title={vendordivisionTooltip}>
                                                                                        <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            name="units"
                                                                                            id="units"
                                                                                            className='form-control'
                                                                                            placeholder="Enter Division"
                                                                                            disabled={vendoreEntityValue && vendoreEntityValue.length <= 0 ? true : false}
                                                                                            onChange={(option) => VendordivisionHandelChange(option)}
                                                                                            mode="multiple"
                                                                                            onDeselect={(e) => {
                                                                                                if (AddType == 'edit') {
                                                                                                    let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_units
                                                                                                        , { d_id: e })
                                                                                                    console.log(data, 'eeeeeeeeeee');
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesMultiUnits",
                                                                                                                {
                                                                                                                    "le_id": data.le_id,
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "unit_ids": [data.u_unt_id],
                                                                                                                    "product_id": "2"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })

                                                                                                }

                                                                                            }}
                                                                                            value={vendorDivisionValue || undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {vendordivisionData && vendordivisionData.length > 1 ?
                                                                                                <>
                                                                                                    <Option key="all" value="all" style={{ display: vendordivisionselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: vendordivisionunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                </> : false}



                                                                                            {vendorListData && vendorListData.length > 0 &&
                                                                                                vendorListData.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })}
                                                                                        </Select>
                                                                                    </Tooltip> : <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        placeholder="Enter Division"
                                                                                        disabled={vendoreEntityValue && vendoreEntityValue.length <= 0 ? true : false}
                                                                                        onChange={(option) => VendordivisionHandelChange(option)}
                                                                                        mode="multiple"
                                                                                        onDeselect={(e) => {
                                                                                            if (AddType == 'edit') {
                                                                                                let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_units
                                                                                                    , { d_id: e })
                                                                                                console.log(data, 'eeeeeeeeeee');
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesMultiUnits",
                                                                                                            {
                                                                                                                "le_id": data.le_id,
                                                                                                                "user_id": details.user_id,
                                                                                                                "unit_ids": [data.u_unt_id],
                                                                                                                "product_id": "2"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })

                                                                                            }

                                                                                        }}
                                                                                        value={vendorDivisionValue || undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {vendordivisionData && vendordivisionData.length > 1 ?
                                                                                            <>
                                                                                                <Option key="all" value="all" style={{ display: vendordivisionselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: vendordivisionunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}


                                                                                        {vendorListData && vendorListData.length > 0 &&
                                                                                            vendorListData.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })}
                                                                                    </Select>}

                                                                            </div> : ""}
                                                                        {category.compfie_vendor == '5' || category.compfie_vendor == '4' || category.compfie_vendor == '6' || category.compfie_vendor == '2' || category.compfie_vendor == '7' ?

                                                                            <div className="col-md-4">
                                                                                <label htmlFor=""><b>Domain</b> <span style={{ "color": "red" }}>*</span></label><br />

                                                                                {vendordomainValue.length > 0 ?
                                                                                    <Tooltip title={vendorDomainTooltip}>
                                                                                        <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            disabled={vendoreEntityValue.length <= 0 ? true : false}
                                                                                            placeholder='Enter Domain'
                                                                                            name="units"
                                                                                            id="units"
                                                                                            className='form-control'
                                                                                            onDeselect={(e) => {

                                                                                                let splitData = e.split("-")
                                                                                                console.log(splitData, 'splitData');
                                                                                                if (AddType == 'edit') {
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesLE",
                                                                                                                {
                                                                                                                    "le_id": splitData && splitData.length > 0 && splitData[0],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "product_id": "3",
                                                                                                                    "domain_id": Number(splitData && splitData.length > 0 && splitData[0]),


                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })
                                                                                                }
                                                                                                console.log(e, 'eeeeeeeeeee');

                                                                                            }}
                                                                                            onChange={(option) => vendorDoaminHandelChange(option)}
                                                                                            mode="multiple"
                                                                                            value={vendordomainValue.includes(undefined) == true ? undefined : vendordomainValue}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {VendorDomainData && VendorDomainData.length > 1 ? <> <Option key="all" value="all" style={{ display: vendordomianselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: Vendordomainunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                            {/* {VendorDomainData && VendorDomainData.length > 0 && VendorDomainData.map((item, i) => {
                                                                                            if (vendorEntityData && vendorEntityData.length > 0) {
                                                                                                let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <OptGroup label={data && data.le_name}>
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    </OptGroup>
                                                                                                );
                                                                                            }
                                                                                            else {
                                                                                                return (

                                                                                                    <Option key={item.u_dm_id}>
                                                                                                        {item.u_dm_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            }

                                                                                        })} */}
                                                                                            {VendorDomainList && VendorDomainList.length > 0 &&
                                                                                                VendorDomainList.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })}
                                                                                        </Select>
                                                                                    </Tooltip> : <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={vendoreEntityValue.length <= 0 ? true : false}
                                                                                        placeholder='Enter Domain'
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        onDeselect={(e) => {

                                                                                            let splitData = e.split("-")
                                                                                            console.log(splitData, 'splitData');
                                                                                            if (AddType == 'edit') {
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_id": splitData && splitData.length > 0 && splitData[0],
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "3",
                                                                                                                "domain_id": Number(splitData && splitData.length > 0 && splitData[0]),


                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                            }
                                                                                            console.log(e, 'eeeeeeeeeee');

                                                                                        }}
                                                                                        onChange={(option) => vendorDoaminHandelChange(option)}
                                                                                        mode="multiple"
                                                                                        value={vendordomainValue.includes(undefined) == true ? undefined : vendordomainValue}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {VendorDomainData && VendorDomainData.length > 1 ? <> <Option key="all" value="all" style={{ display: vendordomianselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: Vendordomainunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                        {/* {VendorDomainData && VendorDomainData.length > 0 && VendorDomainData.map((item, i) => {
                                                                                            if (vendorEntityData && vendorEntityData.length > 0) {
                                                                                                let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <OptGroup label={data && data.le_name}>
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    </OptGroup>
                                                                                                );
                                                                                            }
                                                                                            else {
                                                                                                return (

                                                                                                    <Option key={item.u_dm_id}>
                                                                                                        {item.u_dm_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            }

                                                                                        })} */}
                                                                                        {VendorDomainList && VendorDomainList.length > 0 &&
                                                                                            VendorDomainList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })}
                                                                                    </Select>}
                                                                                {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                    'domain',
                                                                                    compfieVendorValue.Domain,
                                                                                    ['required'],
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Domain Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                            </div>
                                                                            : ''}
                                                                    </div>
                                                                </div>

                                                            </TabPane>

                                                        </Tabs>
                                                    </div>
                                                    {unitAccess == true || compfie.category == 3 ||
                                                        (vendorAccess == true && tabValue.compfie_vendor == true)
                                                        // || compfie.category == 7 || 
                                                        // compfie.category == "View Only Restricted" 
                                                        || compfie.category == "Legal Entity Admin" || compfie.category == "View Only" ? <a href={() => false} style={{ marginLeft: '90%', marginTop: '40%' }} >
                                                        {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'left' }}
                                                            icon={<ArrowLeftOutlined />} size='default' onClick={previous}>
                                                            Previous
                                                        </Button> */}
                                                        {/* <button style={{ float: "left" }} className="usrbutton button--anthe prev" onClick={previous}><span class="text-white fs-3 fw-bold">Back</span></button> */}

                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", float: "right", borderColor: "#198754" }}
                                                            icon={<PlayCircleOutlined />} size='default' onClick={userDetailsOnSubmit
                                                            }>
                                                            Submit
                                                        </Button> 
                                                        {/* <div class="button2" style={{ float: 'right' }} id={localStorage.getItem('currentTheme')} onClick={userDetailsOnSubmit}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Submit
                                        </div> */}

                                                    </a> :
                                                        <>

                                                            {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'left' }}
                                                                icon={<ArrowLeftOutlined />} size='default' onClick={previous}>
                                                                Previous
                                                            </Button> */}
                                                            {/* <a href={() => false} style={{ marginLeft: '90%' }}> */}
                                                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", float: "right" }} key={unitAccess}
                                                                size='default' onClick={userDetailOnSubmit}>
                                                                Next <ArrowRightOutlined />
                                                            </Button>
                                                            {/* <button style={{float: "right" }} className="button button--anthe"><span style={{color:'white'}}>Next</span></button> */}
                                                            {/* <button style={{ float: "left" }} className="usrbutton button--anthe prev" onClick={previous}><span class="text-white fs-3 fw-bold">Back</span></button> */}
                                                            {/* <button style={{ float: "right" }} className="usrbutton button--anthe" onClick={userDetailOnSubmit}><span class="text-white fs-3 fw-bold">Next</span></button> */}
                                                            {/* </a> */}
                                                        </>}

                                                </TabPane>
                                                <TabPane tab="Select Units"
                                                    disabled={selectUnit === true ? false : true}
                                                    key="2">
                                                    <Tabs defaultActiveKey="1" type="card" onChange={onChange}>
                                                        {tabValue.compfie == true ?
                                                            <TabPane tab=" Compfie" key="1">
                                                                {/* <MultiSelect
                                                                    options={options}
                                                                    value={selected}
                                                                    onChange={setSelected}
                                                                    labelledBy="Select"
                                                                    style={{ width: '100px' }}

                                                                /><br /> */}
                                                                <Select
                                                                    name="units"
                                                                    id="units"
                                                                    className='form-control'
                                                                    onChange={(option, data) => HandelChange(option, data)}
                                                                    mode="multiple"
                                                                    optionFilterProp="children"
                                                                    // allowClear
                                                                    showSearch
                                                                    // filterOption={(inputValue, option) =>
                                                                    //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                    // }
                                                                    filterOption={(input, option) =>

                                                                        // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                        option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                    }
                                                                    value={all}
                                                                    onDeselect={(e) => {
                                                                        if (AddType == 'edit') {
                                                                            let data = _.find(selectUnitData, { u_unt_id: e })
                                                                            console.log(data, 'eeeeeeeeeee');
                                                                            const Editpayload = [
                                                                                authtoken,
                                                                                {
                                                                                    "session_token": authtoken,
                                                                                    "request": [
                                                                                        "HaveCompliancesUnit",
                                                                                        {
                                                                                            "le_id": data.le_id,
                                                                                            "user_id": details.user_id,
                                                                                            "unit_id": e,
                                                                                            "product_id": "1"
                                                                                        }
                                                                                    ]

                                                                                }
                                                                            ]
                                                                            selectHaveCompliance({
                                                                                payload: Editpayload,
                                                                                paramid: paramid,

                                                                            })

                                                                        }

                                                                    }}
                                                                    // defaultValue={selected}
                                                                    // key={selected}
                                                                    maxTagCount='responsive'
                                                                >
                                                                    {selectUnitData && selectUnitData.length > 1 ? <> <Option key="all" value="all" style={{ display: unitselectcheckbox }}>---SELECT ALL---</Option>
                                                                        <Option key="unselect" value="unselect" style={{ display: unitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                    {/* {selectUnitData && selectUnitData.length > 0 &&
                                                                        selectUnitData.map((item) => {
                                                                            if (EntityData && EntityData.length > 0) {
                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                return (
                                                                                    <OptGroup label={data && data.le_name}>
                                                                                        <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                    </OptGroup>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                )
                                                                            }

                                                                        }

                                                                        )} */}
                                                                    {selectUnitList && selectUnitList.length > 0 &&
                                                                        selectUnitList.map((finalList_unitlist) => {
                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                return (
                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                        {finalList_unitlist.options.map((subUnitData) => (
                                                                                            <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                        ))}
                                                                                    </OptGroup>
                                                                                )
                                                                        })}


                                                                </Select>
                                                                <h6 style={{ marginTop: '5px' }}>Closed Units</h6>
                                                                {/* <MultiSelect
                                                                    options={closedOption}
                                                                    value={closeselected}
                                                                    onChange={setCloseSelected}
                                                                    labelledBy="Select"

                                                                /> */}
                                                                <Select
                                                                    name="units"
                                                                    id="units"
                                                                    className='form-control'
                                                                    onChange={(option) => CloseUnitHandelChange(option)}
                                                                    optionFilterProp="children"
                                                                    // allowClear
                                                                    showSearch
                                                                    // filterOption={(inputValue, option) =>
                                                                    //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                    // }
                                                                    filterOption={(input, option) =>

                                                                        // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                        option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                    }
                                                                    mode="multiple" onDeselect={(e) => {
                                                                        if (AddType == 'edit') {
                                                                            let data = _.find(closedUnitData, { u_unt_id: e })
                                                                            console.log(data, 'eeeeeeeeeee');
                                                                            const Editpayload = [
                                                                                authtoken,
                                                                                {
                                                                                    "session_token": authtoken,
                                                                                    "request": [
                                                                                        "HaveCompliancesUnit",
                                                                                        {
                                                                                            "le_id": data.le_id,
                                                                                            "user_id": details.user_id,
                                                                                            "unit_id": e,
                                                                                            "product_id": "1"
                                                                                        }
                                                                                    ]

                                                                                }
                                                                            ]
                                                                            selectHaveCompliance({
                                                                                payload: Editpayload,
                                                                                paramid: paramid,

                                                                            })

                                                                        }

                                                                    }}

                                                                    value={closedAll}
                                                                    maxTagCount='responsive'
                                                                >
                                                                    {closedUnitData && closedUnitData.length > 1 ? <>  <Option key="all" value="all" style={{ display: closeunitselectcheckbox }}>---SELECT ALL---</Option>
                                                                        <Option key="unselect" value="unselect" style={{ display: closedunitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                    {/* {closedUnitData && closedUnitData.length > 0 &&
                                                                        closedUnitData.map((item) => {
                                                                            if (EntityData && EntityData.length > 0) {
                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                return (
                                                                                    <OptGroup label={data && data.le_name}>
                                                                                        <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                    </OptGroup>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                )
                                                                            }
                                                                        }
                                                                        )} */}

                                                                    {closedselectUnitList && closedselectUnitList.length > 0 &&
                                                                        closedselectUnitList.map((finalList_unitlist) => {
                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                return (

                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                        {finalList_unitlist.options.length > 0 && finalList_unitlist.options.map((subUnitData) => (
                                                                                            <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                        ))}
                                                                                    </OptGroup>
                                                                                )
                                                                        })}
                                                                </Select>
                                                            </TabPane> : null
                                                        }                {tabValue.compfie_vendor == true ?
                                                            <TabPane tab="Compfie Vendor" key="2">

                                                                {/* <MultiSelect
                                                                    options={Vendoroptions}
                                                                    value={vendorselected}
                                                                    onChange={setvendorSelected}
                                                                    labelledBy="Select"

                                                                /><br /> */}
                                                                <Select
                                                                    name="units"
                                                                    id="units"
                                                                    className='form-control'
                                                                    onChange={(option) => VendorUnitHandelChange(option)}
                                                                    mode="multiple"
                                                                    optionFilterProp="children"
                                                                    // allowClear
                                                                    showSearch
                                                                    // filterOption={(inputValue, option) =>
                                                                    //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                    // }
                                                                    filterOption={(input, option) =>

                                                                        // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                        option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                    }
                                                                    onDeselect={(e) => {
                                                                        if (AddType == 'edit') {
                                                                            let data = _.find(vendorUnit, { u_unt_id: e })
                                                                            console.log(data, 'eeeeeeeeeee');
                                                                            const Editpayload = [
                                                                                authtoken,
                                                                                {
                                                                                    "session_token": authtoken,
                                                                                    "request": [
                                                                                        "HaveCompliancesUnit",
                                                                                        {
                                                                                            "le_id": data.le_id,
                                                                                            "user_id": details.user_id,
                                                                                            "unit_id": e,
                                                                                            "product_id": "2"
                                                                                        }
                                                                                    ]

                                                                                }
                                                                            ]
                                                                            selectHaveCompliance({
                                                                                payload: Editpayload,
                                                                                paramid: paramid,

                                                                            })

                                                                        }

                                                                    }}
                                                                    value={vendorAll}
                                                                    maxTagCount='responsive'
                                                                >
                                                                    {vendorUnit && vendorUnit.length > 1 ? <>  <Option key="all" value="all" style={{ display: vendorunitselectcheckbox }}>---SELECT ALL---</Option>
                                                                        <Option key="unselect" value="unselect" style={{ display: vendorunitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                    {/* {vendorUnit && vendorUnit.length > 0 &&
                                                                        vendorUnit.map((item) => {
                                                                            if (vendorEntityData && vendorEntityData.length > 0) {
                                                                                let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                                return (
                                                                                    <OptGroup label={data && data.le_name}>
                                                                                        <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                    </OptGroup>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                )
                                                                            }

                                                                        })} */}

                                                                    {vendorUnitList && vendorUnitList.length > 0 &&
                                                                        vendorUnitList.map((finalList_unitlist) => {
                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                return (
                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                            <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                        ))}
                                                                                    </OptGroup>
                                                                                )
                                                                        })}
                                                                </Select>
                                                                <h6 style={{ marginTop: '5px' }}>Closed Units</h6>


                                                                <Select
                                                                    name="units"
                                                                    id="units"
                                                                    className='form-control'
                                                                    onChange={(option) => VendorClosedUnitHandelChange(option)}
                                                                    mode="multiple"
                                                                    optionFilterProp="children"
                                                                    // allowClear
                                                                    showSearch
                                                                    // filterOption={(inputValue, option) =>
                                                                    //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                    // }
                                                                    filterOption={(input, option) =>

                                                                        // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                        option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                    }
                                                                    onDeselect={(e) => {
                                                                        if (AddType == 'edit') {
                                                                            let data = _.find(vendorClosedUnit, { u_unt_id: e })
                                                                            console.log(data, 'eeeeeeeeeee');
                                                                            const Editpayload = [
                                                                                authtoken,
                                                                                {
                                                                                    "session_token": authtoken,
                                                                                    "request": [
                                                                                        "HaveCompliancesUnit",
                                                                                        {
                                                                                            "le_id": data.le_id,
                                                                                            "user_id": details.user_id,
                                                                                            "unit_id": e,
                                                                                            "product_id": "2"
                                                                                        }
                                                                                    ]

                                                                                }
                                                                            ]
                                                                            selectHaveCompliance({
                                                                                payload: Editpayload,
                                                                                paramid: paramid,

                                                                            })

                                                                        }

                                                                    }}
                                                                    value={vendorClosedAll}
                                                                    maxTagCount='responsive'
                                                                >
                                                                    {vendorClosedUnit && vendorClosedUnit.length > 1 ? <>  <Option key="all" value="all" style={{ display: vendorcloseunitselectcheckbox }}>---SELECT ALL---</Option>
                                                                        <Option key="unselect" value="unselect" style={{ display: vendorclosedunitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                    {vendorClosedUnitList && vendorClosedUnitList.length > 0 &&
                                                                        vendorClosedUnitList.map((finalList_unitlist) => {
                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                return (
                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                        {finalList_unitlist.options.length > 0 && finalList_unitlist.options.map((subUnitData) => (
                                                                                            <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                        ))}
                                                                                    </OptGroup>
                                                                                )
                                                                        })}
                                                                </Select>
                                                                {/* <MultiSelect
                                                                    options={vendorClosedOption}
                                                                    value={vendorcloseselected}
                                                                    onChange={setVendorCloseSelected}
                                                                    labelledBy="Select"

                                                                /> */}
                                                            </TabPane> : null
                                                        }</Tabs>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-6" style={{ marginTop: '20px' }}>

                                                                {/* <ArrowRightOutlined /> */}


                                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "1px" }}
                                                                    icon={<ArrowLeftOutlined />} size='default'
                                                                    onClick={() => {
                                                                        setActive('1')
                                                                    }}
                                                                >
                                                                    Previous
                                                                </Button>
                                                                {/* <button style={{ float: "left" }} className="usrbutton button--anthe prev"
                                                                    onClick={() => {
                                                                        setActive('1')
                                                                    }}
                                                                ><span class="text-white fs-3 fw-bold">Previous</span></button> */}

                                                            </div>
                                                            <div className="col-md-6" style={{ marginTop: '20px', float: 'right' }}>
                                                                <a href={() => false} >
                                                                    {/* <ArrowRightOutlined /> */}

                                                                    <Button type="primary" shape="round" className='addbutton'
                                                                        style={{ background: "#198754", borderColor: "#198754", float: 'right', marginLeft: '33%' }}
                                                                        icon={<PlayCircleOutlined />} size='default'
                                                                        onClick={userDetailsSaved}
                                                                    >Submit
                                                                    </Button> 
                                                                    {/* <div class="button2" style={{ float: 'right', marginLeft: '33%' }} id={localStorage.getItem('currentTheme')} onClick={userDetailsSaved}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Submit
                                        </div> */}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </TabPane>
                                            </Tabs>

                                            {/* :""}/ */}

                                        </div>
                                        {/* <div className="form-actions">
                      <div className="card-body border-4op">
                        <button
                          type="submit"
                          className="btn btn-light-success rounded-pill px-4 text-success"
                        >
                          <div className="d-flex align-items-center">
                            <i
                              data-feather="save"
                              className="feather-sm me-1 text-center fill-icon"
                            ></i>
                            Save
                          </div>
                        </button>
                        <Link to='/serviceproviderlist' className="btn btn-light-danger rounded-pill px-4  ms-2 text-danger">Cancel</Link>
                      </div>
                    </div> */}
                                        {/* </div>
                      </div>
                    </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Usermanagement showList={showList1} setShowList={setShowList} />}
        </>
    )
}

export default connect(mapStateToProps, {
    getUsermanagementDetails,
    addUserDetails,
    addUserManagementDetails,
    EdithaveCompliance,
    edituserManagementDetails,
    selectHaveCompliance,
    getById
})(AddUserManagement)