import {TASKWISE_REPORT_DATA,FILTER_DATA,SERVICE_PROVIDER_REMARKS,SERVICE_PROVIDER_EXPORT,SERVICE_PROVIDER_DATA,TOGGLE_COMMON_LOADER } from './../../types/index'
const initialState = {
    ServiceProviderComplianceReport: {
        Data: [],
        ServiceProviderComplianceReportList:[],
        unitData:[],
        downloadPath:[],
        RemarksData:[]

    }
}
export default function (state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
        // case CATEGORY_DATA: {
        //     console.log("HIIIIII");
        //     console.log(payload, "payload777");
        //     return {
        //         ...state,
        //         ConsolidatedReports: {
        //             ...state.ConsolidatedReports,
        //             Data: payload,
        //         },
        //     };
        // }
        case FILTER_DATA: {
            console.log("HIIIIII");
            console.log(payload, "avvvvvv");
            return {
                ...state,
                ServiceProviderComplianceReport: {
                    ...state.ServiceProviderComplianceReport,
                    Data: payload,
                },
            };
        }
        case SERVICE_PROVIDER_DATA: {
            console.log("HIIIIII");
            console.log(payload, "avvvvvv");
            return {
                ...state,
                ServiceProviderComplianceReport: {
                    ...state.ServiceProviderComplianceReport,
                    ServiceProviderComplianceReportList: payload,
                },
            };
        }
         case SERVICE_PROVIDER_EXPORT: {
            return {
                ...state,
                TaskWiseReports: {
                    ...state.TaskWiseReports,
                    downloadPath:payload
                },
            };
        }
        case SERVICE_PROVIDER_REMARKS: {
            console.log("HIIIIII");
            console.log(payload, "avvvvvv");
            return {
                ...state,
                ServiceProviderComplianceReport: {
                    ...state.ServiceProviderComplianceReport,
                    RemarksData: payload,
                },
            };
        }
        // case TASKWISE_REPORT: {
        //     console.log("HIIIIII");
        //     return {
        //         ...state,
        //         TaskWiseReports: {
        //             ...state.TaskWiseReports,
        //             TaskWiseReportList: payload,
        //         },
        //     };
        // }
        default:
            return state;
    }
}
