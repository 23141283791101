import {GETDATA,TOGGLE_COMMON_LOADER,SPTABLEDATA } from './../../types/index'
const initialState = {
    serviceproviderdetails: {
       getdata:[],
       tabledata: []
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GETDATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                serviceproviderdetails: {
                    ...state.serviceproviderdetails,
                    getdata: payload,
                },
            };
        }
        case SPTABLEDATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                serviceproviderdetails: {
                    ...state.serviceproviderdetails,
                    tabledata: payload,
                },
            };
        }
     
        default:
            return state;
    }
}
