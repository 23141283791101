import { AUDITTRAILREPORTFILTER, AUDIT_TRAIL_REPORT_SHOW, AUDIT_TRAIL_REPORT_EXPORT, AUDIT_TRAIL_REPORT_EXPORT_RESET } from './../../types/index'
const initialState = {
    audittrailreport: {
        audittrailfilter: [],
        audittrailshow: [],
        audittrailexport: []
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case AUDITTRAILREPORTFILTER:
            return {
                ...state,
                audittrailreport: {
                    ...state.audittrailreport,
                    audittrailfilter: payload,
                },
            };
        case AUDIT_TRAIL_REPORT_SHOW:
            return {
                ...state,
                audittrailreport: {
                    ...state.audittrailreport,
                    audittrailshow: payload,
                },
            };
        case AUDIT_TRAIL_REPORT_EXPORT:
            return {
                ...state,
                audittrailreport: {
                    ...state.audittrailreport,
                    audittrailexport: payload,
                },
            };
        case AUDIT_TRAIL_REPORT_EXPORT_RESET:
            return {
                ...state,
                audittrailreport: {
                    ...state.audittrailreport,
                    audittrailexport: [],
                },
            };

        default:
            return state;
    }
}