import { useState, useRef, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Modal, Card, Collapse, Tooltip, Input, Table, Button, Select, Space } from 'antd';
import DataTable from "react-data-table-component";
import { useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall';
import { statutorylist, editstatutory, filterdata, locksettings, SaveStatutorySettings, UpdateStatutorySettings } from './../../Store/Action/Transcations/Statutorysettings'
import FeatherIcon from 'feather-icons-react';
import { EditOutlined, CheckOutlined, CloseOutlined, FilterFilled, UserAddOutlined, PlayCircleOutlined, PlusSquareTwoTone, ArrowLeftOutlined, MinusCircleOutlined, ExclamationCircleTwoTone, SearchOutlined, UpCircleOutlined, PlusCircleOutlined, EyeOutlined, DownOutlined, SaveOutlined, FullscreenOutlined } from '@ant-design/icons';
import { indexOf, unset, update } from 'lodash';
import MultiUnitStatutorySettings from './MultiUnitStatutorySettings';
import { STATOTORYLIST } from '../../Store/types';
import TextArea from 'antd/lib/input/TextArea';
// import { GiCheckMark } from "react-icons/gi";

const mapStateToProps = (state) => ({
  list: state.Statutorysetting.statutorysettings.statutorylist,
  editdata: state.Statutorysetting.statutorysettings.editdatas,
  filterdatas: state.Statutorysetting.statutorysettings.filterdata,
  filterrecall: state.recallfilters.recallfilters,
  totaldatas: state.Statutorysetting.statutorysettings.totaldata,

})
const Statutorysetting = ({
  statutorylist,
  editstatutory,
  list,
  editdata,
  filterdata,
  locksettings,
  totaldatas,
  filterdatas,
  GetRemarksData,
  filterrecall: { recallremarks },
  SaveStatutorySettings,
  UpdateStatutorySettings

}) => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const { Option } = Select;
  const formValidator = useRef(new SimpleReactValidator());
  const saveformValidator = useRef(new SimpleReactValidator());
  const paramid = location.pathname;
  const _ = require("lodash");
  const [scroll, setScroll] = useState(false)
  const { Panel } = Collapse;
  const history = useHistory();
  const [data, setData] = useState();
  const [checkedRow, setCheckedRow] = useState(false);
  const isAuth = localStorage.getItem('isAuthenticated');
  const authtoken = localStorage.getItem('authToken');
  const validator = useRef(new SimpleReactValidator());
  const [AddFormSubmit, setAddFormSubmit] = useState(false);
  const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
  const [showbutton, setshowbutton] = useState(false)
  const sessionValue = localStorage.getItem('sessionValue')
  const sessionParsedValue = JSON.parse(sessionValue)
  const sessionArr = sessionParsedValue.entity_info
  // const entityid = localStorage.getItem('SelectedEntityid');
  const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
  const [childValue, setChildValue] = useState()
  const [isModalVisible, SetisModalVisible] = useState(false);
  const [passwordmodal, Setpasswordmodal] = useState(false);
  const [submitpasswordmodal, Setsubmitpasswordmodal] = useState(false);
  const [editshow, seteditshow] = useState(false);
  const [listshow, setlistshow] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [uiddomian, setUiddomain] = useState([]);
  const [statutorypayload, setStatutorypayload] = useState([]);
  const [tempstatutorypayload, setTempstatutorypayload] = useState([]);
  const [UpdataCheck, setUpdateCheck] = useState([]);
  const [dataTableProperties, setDataTableProperties] = useState({
    fixedHeader: true,
    pagesize: 10,
    sizechanger: true,
    pageSizeOptions: ['10', '30', '50']
  })
  // const [tempeditdata, setTempeditdata] = useState();
  const [remarksFormSubmit, setRemarksFormSubmit] = useState(false)
  const [ExtraCollapseId, setExtraCollapseId] = useState()
  // const [CollapseId, setCollapseId] = useState()
  const [updateRemarks, setUpdateRemarks] = useState([])
  console.log(updateRemarks, 'updateRemarks');
  // const [check, setCheck] = useState(false)
  const [RowRemarks, setRowRemark] = useState([])
  console.log(RowRemarks, 'RowRemarks');
  const [RowStatus, setRowStatus] = useState([])
  console.log(RowStatus, 'RowStatus');
  const [updateStatus, setUpdateStatus] = useState([])
  console.log(updateStatus, 'updateStatus');
  const [remarksModal, setRemarksModal] = useState(false);
  const [PannelremarksModal, setPannelRemarksModal] = useState(false)
  const [collapseremarksModal, setCollapseremarksModal] = useState(false);
  const [PanelRemarkChange, setpanelRemarkChange] = useState(false)
  console.log(PanelRemarkChange, 'PanelRemarkChange');
  // const [remarksPanel, setRemarksPanel] = useState(true)
  const [minuscircleoutlined, setMinuscircleoutlined] = useState([]);
  const [closeoutlined, setCloseoutlined] = useState([]);
  const [checkoutlined, setCheckoutlined] = useState([]);
  const [UpdatePannelRemarks, setUpdatePannelRemarks] = useState([]);
  const [CheckStatus, setCheckStatus] = useState([])
  console.log(CheckStatus, 'CheckStatus');
  const [CollapseStatus, setCollapseStatus] = useState([]);
  const [key, setKey] = useState("1")
  // const [finalcollapse, setFinalcollapse] = useState([]);
  // const [payload, setPayload] = useState([])
  // const [remarkvalue, setRemarkvalue] = useState([]);
  // const [messages, setMessages] = useState([])
  const [childData, setChildData] = useState([])
  const [collapsemessages, setCollapseMessages] = useState([])
  // const [tempremarkvalue, setTempremarkvalue] = useState([])
  // const [editopt, setEditopt] = useState([])
  // const [panelremark, setPanelremark] = useState([]);
  // const [remarkmessage, setRemarkmessage] = useState();
  // const [collapseremarkmessage, setCollapseremarkmessage] = useState([]);
  // const [collapsename, setCollapsename] = useState([]);
  // const [tempcollapseid, setTempcollapseid] = useState();
  // const [checkpanelremark, setCheckpanelremark] = useState([]);
  // const [remarksindex, setRemarksindex] = useState({
  //   remarks: false,
  //   remarks_id: [],
  //   parent: [],
  //   collapseId: []
  // });
  var [count1, setCount1] = useState(1);
  // let [remarkscount, setRemarkscount] = useState(0)
  const [checkdata, setCheckdata] = useState([])
  // const [remarksdata, SetRemarksdata] = useState({
  //   remarks: ''
  // })
  const [password, Setpassword] = useState({
    passwordvalue: '',
    le_id: '',
    d_id: '',
    u_id: '',
    lock: '',
  })
  const [titledata, Settitledata] = useState()
  console.log(titledata, 'titledata');
  const [rowRemarks, setRowRemarks] = useState(false)
  // const [collapseRemark, setCollapseRemark] = useState([])
  const [filtervalue, Setfiltervalue] = useState({
    entity: '',
    division: '',
    category: '',
    bg_id: '',
    bg_name: ''
  })
  const [remarksvalue, Setremarksvalue] = useState();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };
  const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
    setSelectedKeys(e.target.value ? [e.target.value] : [])
    confirm({ closeDropdown: false });
    // setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  //     <div
  //       style={{
  //         padding: 8,
  //       }}
  //     >
  //       <Input
  //         ref={searchInput}
  //         placeholder='Search'
  //         value={selectedKeys[0]}
  //         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{
  //           marginBottom: 8,
  //           display: 'block',
  //         }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => clearFilters && handleReset(clearFilters)}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Reset
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <FilterFilled
  //       style={{
  //         color: filtered ? '#1890ff' : undefined,
  //       }}
  //     />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
  //   onFilterDropdownOpenChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.current?.select(), 100);
  //     }
  //   },
  //   render: (text) => text
  // });

  const getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
      >
        <Input
          ref={searchInput}
          placeholder={'Search ' + placeholder}
          value={selectedKeys[0]}
          // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text
  });
  //  Full screen mode //
  const [fullscreen, setfullscreen] = useState(false);
  const fullscreenMode = () => {
    let topBar = document.getElementById('top-nav')
    let leftbar = document.getElementsByClassName('left-sidebar');
    let leftnone = leftbar[0];
    let pageWrapper = document.getElementById('page-wrapper')
    if (fullscreen == false) {
      document.documentElement.requestFullscreen();
      topBar.style.display = 'none'
      leftnone.style.display = 'none'
      pageWrapper.style.padding = '0'
      pageWrapper.style.marginLeft = '0'
      setfullscreen(true);
    } else {
      document.exitFullscreen();
      topBar.style.display = 'block'
      leftnone.style.display = 'block'
      pageWrapper.style.marginLeft = '65px'
      pageWrapper.style.paddingTop = '70px'
      setfullscreen(false);
    }
  }



  useEffect(() => {
    if (localStorage.getItem('SelectedEntityid') != null) {
      setCurrentEntity(localStorage.getItem('SelectedEntityid'))
    }
  }, [localStorage.getItem('SelectedEntityid')])

  const scrollup = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY >= 213) {
      setScroll(true)
    }
    else {
      setScroll(false)
    }
  }

  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch({
        type: STATOTORYLIST,
        payload: ['', { statutories: [] }]
      })
    }
  }, [])

  const column = [
    {
      title: '#',
      dataIndex: 'indexNo',
      key: 'indexNo',
      width: "20px",
      align: "center"
    },
    {
      title: "Statutory Provision",
      render: (record) => record.s_prov,
      sortable: true,
      width: '160px',
      reorder: true,
      ellipsis: false
    },
    {
      title: "Compliance Task",
      render: (record) => record.comp_name,
      sortable: true,
      width: '160px',
      reorder: true,
      // ellipsis: true
    },
    {
      title: "Compliance Frequency",
      render: (record) => record.frequency_name,
      sortable: true,
      width: '80px',
      reorder: true,
      // ellipsis: true,
      // align: 'center'
    },
    {
      title: "Compliance Description",
      render: (record) => record.comp_name,
      sortable: true,
      reorder: true,
      width: '160px',
      // ellipsis: true
    },
    {
      title: "Criticality",
      render: (record) => record.criticality_know,
      sortable: true,
      reorder: true,
      width: '50px',
      // ellipsis: true,
      align: 'center'
    },
    {
      title:
        <span title='Applicable'>
          <EditOutlined />
        </span>,
      render: (record) => record.app_status === true ? <FeatherIcon icon="check-circle" size={20} className={'pointer'} style={{ color: '#318CE7' }} /> : <FeatherIcon icon="x-circle" className={'pointer'} size={20} />,
      // sortable: true,
      // right: true,
      reorder: true,
      width: '20px',
      // ellipsis: true,
      align: 'center'
    },
    {
      title: <span title='Opted'>
        <UserAddOutlined />
      </span>,
      render: (record) => {
        // let temp;
        // if (record.unit_wise_status[0].comp_app_status === true) {
        //   temp = 1
        // } else {
        //   temp = 0
        // }
        // if (record.unit_wise_status[0].comp_opt_status === 1) {
        //   // if (remarksindex.remarks_id.length === 0) {
        //   return (record.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) < 0) ? <CheckOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]}onClick={(e) => {
        //     changeremarks(e, record)
        //   }} /> :
        //     (record.unit_wise_status[0].comp_opt_status === 1 && (closeoutlined.indexOf(record.comp_id) >= 0) && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (checkoutlined.indexOf(record.comp_id) < 0) ? <CloseOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //       changeremarks(e, record)
        //     }} /> :
        //       (record.unit_wise_status[0].comp_opt_status === 1 && (checkoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) < 0) && (minuscircleoutlined.indexOf(record.comp_id) >= 0) ? <MinusCircleOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //         changeremarks(e, record)
        //       }} /> : '')))
        //   // (row.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) >= 0) ? <CloseOutlined onClick={(e) => {
        //   //   changeremarks(e, row)
        //   // }} /> :
        //   //   (row.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(row.comp_id) >= 0) && (checkoutlined.indexOf(row.comp_id) < 0) ? <MinusCircleOutlined onClick={(e) => {
        //   //     changeremarks(e, row)
        //   //   }} /> : '')))
        //   // } else {
        //   // return (row.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) < 0) ? <CheckOutlined onClick={(e) => {
        //   //   changeremarks(e, row)
        //   // }} /> :
        //   //   (row.unit_wise_status[0].comp_opt_status === 1 && (closeoutlined.indexOf(row.comp_id) >= 0) && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (checkoutlined.indexOf(row.comp_id) < 0) ? <CloseOutlined onClick={(e) => {
        //   //     changeremarks(e, row)
        //   //   }} /> :
        //   //     (row.unit_wise_status[0].comp_opt_status === 1 && (checkoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) < 0) && (minuscircleoutlined.indexOf(row.comp_id) >= 0) ? <MinusCircleOutlined onClick={(e) => {
        //   //       changeremarks(e, row)
        //   //     }} /> : '')))
        //   // }
        // } else if (record.unit_wise_status[0].comp_opt_status === 0) {
        //   return (record.unit_wise_status[0].comp_opt_status === 0 && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (checkoutlined.indexOf(record.comp_id) < 0) ? <CloseOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //     changeremarks(e, record)
        //   }} /> :
        //     (record.unit_wise_status[0].comp_opt_status === 0 && (minuscircleoutlined.indexOf(record.comp_id) >= 0) && (closeoutlined.indexOf(record.comp_id) < 0) && (checkoutlined.indexOf(record.comp_id) < 0) ? <MinusCircleOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //       changeremarks(e, record)
        //     }} /> :
        //       (record.unit_wise_status[0].comp_opt_status === 0 && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) < 0) && (checkoutlined.indexOf(record.comp_id) >= 0) ? <CheckOutlined   defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //         changeremarks(e, record)
        //       }} /> : '')))
        // } else {
        //   return (record.unit_wise_status[0].comp_opt_status === 2 && (checkoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) < 0) ? <MinusCircleOutlined onClick={(e) => {
        //     changeremarks(e, record)
        //   }} /> :
        //     (record.unit_wise_status[0].comp_opt_status === 2 && (checkoutlined.indexOf(record.comp_id) >= 0) && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) < 0) ? <CheckOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //       changeremarks(e, record)
        //     }} /> :
        //       (record.unit_wise_status[0].comp_opt_status === 2 && (checkoutlined.indexOf(record.comp_id) < 0) && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) >= 0) ? <CloseOutlined  defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //         changeremarks(e, record)
        //       }} /> : '')))
        // }<Checkmark /> BsCheckLg

        if (updateStatus[record.childId] === 1) {
          return <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'orange' }} size="20" defaultValue={updateStatus[record.childId]} key={updateStatus[record.childId]} onClick={(e) => {
            setpanelRemarkChange(false)
            // setCheck(false)
            // tick to close
            // changeremarks(e, record)
            CheckStatus.map((item, i) => {
              if (item == record.comp_id) {
                CheckStatus.splice(i, 1)
              }
            })
            let temp = []
            temp.push(record.comp_id)
            setCheckStatus([...CheckStatus, ...temp])
            if (record.childId in updateStatus) {
              // let index = updateStatus.indexOf(record.childId)
              // console.log(index, 'index');
              // updateStatus[record.childId] = [];
              // console.log(index == -1, 'index == -1');
              // if (index == -1) {
              updateStatus[record.childId] = 0
              setUpdateStatus([...updateStatus])
              // } else {
              // updateStatus[record.childId] = [];
              // updateStatus[record.childId] = 0
              // }
            }
            // if (record.childId in RowStatus) {
            //   let index = RowStatus.indexOf(record)
            //   console.log(index, 'index');
            //   RowStatus[record.childId] = [];
            //   console.log(index == -1, 'index == -1');
            //   if (index == -1) {
            //     RowStatus[record.childId] = 0
            //   }
            //   else {
            //     RowStatus[record.childId] = [];
            //     RowStatus[record.childId] = 0
            //   }
            // }
            // setRowStatus([...RowStatus])
            // UpdataCheck.map((item, i) => {
            //   if (item == record.parentId) {
            //     UpdataCheck.splice(i, 1)
            //   }
            // })
            // setUpdateCheck([...UpdataCheck])
            if (CheckStatus.includes(record.comp_id)) {
              if (record.parentId in CollapseStatus) {
                let index = CollapseStatus.indexOf(record.parentId)
                RowStatus[record.childId] = [];
                if (index == -1) {
                  CollapseStatus[record.parentId] = 1
                } else {
                  CollapseStatus[record.parentId] = [];
                  CollapseStatus[record.parentId] = 1
                }
              }
              setCollapseStatus([...CollapseStatus])
            } else {
              // console.log(record.parentId in CollapseStatus, 'record.parentId in CollapseStatus');
              // if (record.parentId in CollapseStatus) {
              // let index = CollapseStatus.indexOf(record.parentId)
              // console.log(index, 'indexsssss');
              // RowStatus[record.childId] = 0;
              // console.log(index == -1, 'index == -1');
              // if (index == -1) {
              // CollapseStatus[record.parentId] = 0
              // } else {
              // CollapseStatus[record.parentId] = [];
              let temp = []
              for (let i in tempstatutorypayload) {
                if (tempstatutorypayload[i].collapseId == record.parentId) {
                  // console.log('12345678S');
                  if (tempstatutorypayload[i].comp_id == record.comp_id) {
                    temp.push(0)
                  } else {
                    temp.push(tempstatutorypayload[i].c_o_status)
                  }
                }
              }
              if (temp.includes(1) != true) {
                CollapseStatus[record.parentId] = 0
              }
              // else{

              // }
              // }
              // }
              // }
              setCollapseStatus([...CollapseStatus])
            }

            if (record.childId in updateRemarks) {
              // let index = updateRemarks.indexOf(record)
              // updateRemarks[record.childId] = [];
              // if (index == -1) {
              updateRemarks[record.childId] = null;
              // }
            }
            // else {
            // updateRemarks[record.childId] = [];
            // updateRemarks[record.childId] = null
            // }
            setUpdateRemarks([...updateRemarks])
            // CheckStatus.map((item, i) => {
            //   if (item == record.c_comp_id) {
            //     CheckStatus.splice(i, 1)
            //     console.log("mukilan");
            //   }
            // })

            // let tempArray = []
            // payload && payload.length > 0 && payload.map((item, i) => {
            //   if (item.comp_id === record.comp_id) {
            //     payload.splice(i, 1)
            //     setPayload([...payload, ...tempArray])
            //   }
            // })
            // tempArray.push({
            //   "c_c_id": record.unit_wise_status[0].c_comp_id,
            //   "a_status": record.app_status,
            //   "n_a_remarks": record.not_app_remarks,
            //   "comp_id": record.comp_id,
            //   "c_o_status": updateStatus[record.childId],
            //   "c_remarks": null,
            //   "u_name": selectedRows[0].u_name,
            //   "u_id": record.unit_wise_status[0].unit_id,
            //   "c_a_status": record.app_status,
            //   "h_id": record.unit_wise_status[0].h_id
            // })
            // setPayload([...payload, ...tempArray])
          }}
          />
        }
        else if (updateStatus[record.childId] === 2) {
          return <FeatherIcon icon="minus-circle" className={'pointer'} size={20} style={{ color: '#FF0000' }} onClick={(e) => {
            setpanelRemarkChange(false)
            // setCheck(true)
            // minus to tick
            let tempArray = []
            // changeremarks(e, record)
            if (record.childId in updateStatus) {
              // let index = updateStatus.indexOf(record)
              // console.log(index, 'index');
              // updateStatus[record.childId] = [];
              // console.log(index == -1, 'index == -1');
              // if (index == -1) {
              updateStatus[record.childId] = 1
              // }
              // else {
              // updateStatus[record.childId] = [];
              // updateStatus[record.childId] = 1
              // }
            }
            setUpdateStatus([...updateStatus])

            // if (record.parentId in CollapseStatus) {
            // let index = CollapseStatus.indexOf(CollapseId)
            // console.log(index, 'index');
            // RowStatus[record.childId] = [];
            // console.log(index == -1, 'index == -1');
            // if (index == -1) {
            CollapseStatus[record.parentId] = 1
            // }
            // else {
            // CollapseStatus[record.parentId] = [];
            // CollapseStatus[record.parentId] = 1
            // }
            // }
            setCollapseStatus([...CollapseStatus])
            let temp1 = []
            UpdataCheck.map((item, i) => {
              if (item == record.parentId) {
                UpdataCheck.splice(i, 1)
              }
            })
            temp1.push(record.parentId)
            setUpdateCheck([...UpdataCheck, ...temp1])
            // if (record.childId in RowStatus) {
            //   let index = RowStatus.indexOf(record)
            //   console.log(index, 'index');
            //   RowStatus[record.childId] = [];
            //   console.log(index == -1, 'index == -1');
            //   if (index == -1) {
            //     RowStatus[record.childId] = 1
            //   }
            //   else {
            //     RowStatus[record.childId] = [];
            //     RowStatus[record.childId] = 1
            //   }
            // }
            // setRowStatus([...RowStatus])
            let temp = []

            temp.push(record.comp_id)
            setCheckStatus([...CheckStatus, ...temp])
            // payload && payload.length > 0 && payload.map((item, i) => {
            //   if (item.comp_id === record.comp_id) {
            //     payload.splice(i, 1)
            //     setPayload([...payload, ...tempArray])
            //   }
            // })
            // tempArray.push({
            //   "c_c_id": record.unit_wise_status[0].c_comp_id,
            //   "a_status": record.app_status,
            //   "n_a_remarks": record.not_app_remarks,
            //   "comp_id": record.comp_id,
            //   "c_o_status": updateStatus[record.childId],
            //   "c_remarks": updateRemarks[record.childId],
            //   "u_name": selectedRows[0].u_name,
            //   "u_id": record.unit_wise_status[0].unit_id,
            //   "c_a_status": record.app_status,
            //   "h_id": record.unit_wise_status[0].h_id
            // })
            // setPayload([...payload, ...tempArray])

          }} defaultValue={updateStatus[record.childId]} key={updateStatus[record.childId]} />
        }
        else {
          return <FeatherIcon icon="x-circle" size="20" className={'pointer'} defaultValue={updateStatus[record.childId]} key={updateStatus[record.childId]} onClick={(e) => {
            // changeremarks(e, record)
            setpanelRemarkChange(false)
            // setCheck(false)
            let tempArray = []
            if (record.childId in updateStatus) {
              updateStatus[record.childId] = 2

            }
            setUpdateStatus([...updateStatus])
            CheckStatus.map((item, i) => {
              if (item == record.comp_id) {
                CheckStatus.splice(i, 1)
              }
            })
            setCheckStatus([...CheckStatus])

          }} />
        }

      },
      reorder: true,
      width: '20px',
      // ellipsis: true,
      align: 'center'

    },
    {
      title: "Remarks",
      render: (record) => {
        if (CheckStatus.includes(record.comp_id) == false) {
          if ((updateRemarks[record.childId] === null) && (CollapseStatus[record.parentId] === 1) && (updateStatus[record.childId] != 2) && (updateStatus[record.childId] != 1)) {
            return <p>{record.not_app_remarks}</p>
          }
        }
        if (Number(record.unit_wise_status[0].comp_app_status) != updateStatus[record.childId]) {
          if (PanelRemarkChange == true || updateRemarks[record.childId] != null ? updateRemarks[record.childId] : '' === RowRemarks[record.childId]) {
            return <>
              <div className='d-flex align-items-end'>
                <TextArea class="form-control" cols="5" rows="1" placeholder='Enter remarks' style={{ "width": "100%" }}
                  // value={row.unit_wise_status[0].comp_remarks}
                  defaultValue={updateRemarks[record.childId]}
                  key={updateRemarks[record.childId]}
                  onBlur={(e) => {
                    let tempArray = []
                    let temp = []
                    temp.push(e.target.value)
                    if (record.childId in updateRemarks) {
                      // let index = updateRemarks.indexOf(record)
                      // updateRemarks[record.childId] = [];
                      // if (index == -1) {
                      updateRemarks[record.childId] = e.target.value;
                      // }
                      // } else {
                      // updateRemarks[record.childId] = [];
                      // updateRemarks[record.childId] = e.target.value
                    }
                    setUpdateRemarks([...updateRemarks])
                  }}
                  id="remarks" name="remarks" type={"text"}
                  hidden={PanelRemarkChange == true || updateRemarks[record.childId] === RowRemarks[record.childId] ? true : false}
                />
                {saveformValidator.current.message(
                  'remarks',
                  updateRemarks[record.childId],
                  'required',
                  {
                    className: `invalid-feedback ${remarksFormSubmit ? 'show' : 'hide'}`,
                    messages: {
                      required: 'Remarks Required',

                    }
                  })
                }
                <PlusCircleOutlined className='text-info ms-1' onClick={() => { showRemarksModal(record.comp_id, record.collapseId, record.childId) }} hidden={PanelRemarkChange == true || updateRemarks[record.childId] === RowRemarks[record.childId] ? true : false} style={{ marginTop: '10px' }} />
              </div>
            </>
          } else {
            return <>
              <div className='d-flex align-items-end'>
                <TextArea class="form-control" cols="5" rows="1" placeholder='Enter remarks' style={{ "width": "100%" }}
                  // value={row.unit_wise_status[0].comp_remarks}
                  defaultValue={updateRemarks[record.childId]}
                  key={updateRemarks[record.childId]}
                  onBlur={(e) => {
                    let tempArray = []
                    let temp = []
                    temp.push(e.target.value)
                    if (record.childId in updateRemarks) {
                      // let index = updateRemarks.indexOf(record)
                      // updateRemarks[record.childId] = [];
                      // if (index == -1) {
                      updateRemarks[record.childId] = e.target.value;
                      // }
                      // } else {
                      // updateRemarks[record.childId] = [];
                      // updateRemarks[record.childId] = e.target.value
                    }
                    setUpdateRemarks([...updateRemarks])
                  }}
                  id="remarks" name="remarks" type={"text"}
                  hidden={PanelRemarkChange == true}
                />
                {saveformValidator.current.message(
                  'remarks',
                  updateRemarks[record.childId],
                  'required',
                  {
                    className: `invalid-feedback ${remarksFormSubmit ? 'show' : 'hide'}`,
                    messages: {
                      required: 'Remarks Required',

                    }
                  })
                }
                <PlusCircleOutlined className='text-info ms-1' onClick={() => { showRemarksModal(record.comp_id, record.collapseId, record.childId) }} hidden={PanelRemarkChange == true} style={{ marginTop: '10px' }} />
              </div>
            </>
          }
        }
      },
      sortable: true,
      reorder: true,
      width: '130px',
      // ellipsis: true
    },
    {
      title: "Viewed",
      render: (record) => {
        return (
          <>
            {record && record.unit_wise_status && record.unit_wise_status[0].is_new ?
              <Tooltip title={record.compliance_frequency}><p><i style={{ color: "white" }} class="bi bi-square-fill"></i></p></Tooltip> :
              <Tooltip title={record.compliance_frequency}><p><i style={{ color: "orange" }} class="bi bi-square-fill"></i></p></Tooltip>
            }
          </>
        )
      },
      sortable: true,
      reorder: true,
      width: '50px',
      // ellipsis: true,
      align: 'center'
    }
  ];

  const rowSelection = {
    renderCell: (checked, record, index, originNode) => {
      return (
        <Fragment style={{ width: '10px' }}>
          <input type="checkbox"
            style={{ width: '10px' }}
            name="checked"
            hidden={record.is_locked === false ? false : true}
            onClick={(e) => {
              let checked = e.target.checked;
              addSelectedDataValues(
                checked,
                record.u_id,
                // record.childId
              );
            }}
          />
        </Fragment>
      )
    }

  }

  const columns = [
    {
      title: "Unit",
      ...getColumnSearchProps('u_name', 'Unit'),
      render: (record) => {
        if (record.is_new) {
          return <><Tooltip title={`${record.address}`} ><ExclamationCircleTwoTone style={{ marginRight: '5px' }} /></Tooltip><span style={{ color: '#0000FF' }}>{record.u_name}</span></>
        } else {
          return <><Tooltip title={`${record.address}`} ><ExclamationCircleTwoTone style={{ marginRight: '5px' }} /></Tooltip>{record.u_name}</>
        }
      },
      sortable: true,
      width: '210px',
      // ellipsis: true,
      dataIndex: false,
      key: false,

    },
    {
      title: "Location",
      ...getColumnSearchProps('location', 'Location'),
      render: (record) => {
        if (record.is_new) {
          return <span style={{ color: '#0000FF' }}>{record.location}</span>
        } else {
          return record.location
        }
      },
      sortable: true,
      width: '210px',
      // ellipsis: true,
      dataIndex: false,
      key: false,

    },
    {
      title: "Domain",
      ...getColumnSearchProps('d_name', 'Domain'),
      render: (record) => {
        if (record.is_new) {
          return <span style={{ color: '#0000FF' }}>{record.d_name}</span>
        } else {
          return record.d_name
        }
      },
      sortable: true,
      width: '190px',
      // ellipsis: true,
      dataIndex: false,
      key: false,

    },
    {
      title: <label><span title='No.of Compliance'>Compliance</span></label>,
      ...getColumnSearchProps('r_count', 'No.of Compliance'),
      render: (record) => {
        if (record.is_new) {
          return <span style={{ color: '#0000FF' }}>{record.r_count}</span>
        } else {
          return record.r_count
        }
      },
      sortable: true,
      width: '40px',
      ellipsis: false,
      dataIndex: false,
      key: false,
      align: 'center'

    },
    {
      title: "Updated By",
      ...getColumnSearchProps('usr_by', 'Updated By'),
      render: (record) => {
        if (record.is_new) {
          return <span style={{ color: '#0000FF' }}>{record.usr_by}</span>
        } else {
          return record.usr_by
        }
      },
      sortable: true,
      width: '100px',
      // ellipsis: true,
      dataIndex: false,
      key: false,
      align: 'center'


    },
    {
      title: "Updated On",
      ...getColumnSearchProps('usr_on', 'Updated On'),
      render: (record) => {
        if (record.is_new) {
          return <span style={{ color: '#0000FF' }}>{record.usr_on}</span>
        } else {
          return record.usr_on
        }
      },
      sortable: true,
      width: '100px',
      dataIndex: false,
      key: false,
      align: 'center'

    },

    {
      title: "Lock",
      sortable: true,
      width: '50px',
      render: (record) => {
        return (
          <center>
            {
              record.is_locked === true ?
                <div onClick={(e) => {
                  unlock(record)
                }} ><div >
                    <span className="btn btn-m btn-light-danger
                              text-danger btn-circle d-flex
                              align-items-center justify-content-center"
                      style={{
                        width: "30px",
                        height: "30px"
                      }}><Tooltip title='Click here to unlock'><FeatherIcon icon="lock" /></Tooltip></span>
                  </div></div> : <div>
                  <span className="btn btn-m btn-light-success
                text-success btn-circle d-flex align-items-center
                justify-content-center" style={{
                      width: "30px",
                      height: "30px"
                    }}>
                    <FeatherIcon icon="unlock" /></span>
                </div>
            }
          </center>
        )
      },

    }
  ];

  const remarkscolumn = [

    {
      title: 'S.No',
      dataIndex: false,
      width: '30px',
      align: 'center',
      // ellipsis: true,
      render: (text, record, index) => {
        return (page - 1) * 10 + index + 1
      }
    },
    {
      title: "Description",
      render: (text, row) => <a href={() => false} onClick={(e) => {
        setRowRemarks(true)
        let rowId = childValue
        if (rowId in updateRemarks) {
          // let index = updateRemarks.indexOf(rowId)
          updateRemarks[rowId] = [];
          if (updateRemarks.indexOf(rowId) == -1) {
            updateRemarks[rowId] = row.r_description;
          }

        } else {
          updateRemarks[rowId] = [];
          updateRemarks[rowId] = row.r_description
        }
        setUpdateRemarks([...updateRemarks])
        setRemarksModal(false)
        setPannelRemarksModal(false)
      }}>{row.r_description}</a>,
      sortable: true,
      width: '250px',
      ellipsis: true,
      reorder: true,
    }
  ]

  const Pannelremarkscolumn = [
    {
      title: 'S.No',
      dataIndex: false,
      width: '30px',
      align: 'center',
      // ellipsis: true,
      render: (text, record, index) => {
        return (page - 1) * 10 + index + 1
      }
    },
    {
      title: 'Description',
      dataIndex: 'r_description',
      key: 'r_description',
      width: '200px',
      ellipsis: true,
      render: (text, row) => <a href={() => false} onClick={(e) => {
        setRowRemarks(true)
        let rowId = ExtraCollapseId
        if (rowId in UpdatePannelRemarks) {
          // let index = updateRemarks.indexOf(rowId)
          UpdatePannelRemarks[rowId] = [];
          if (UpdatePannelRemarks.indexOf(rowId) == -1) {
            UpdatePannelRemarks[rowId] = row.r_description;
          }

        } else {
          UpdatePannelRemarks[rowId] = [];
          UpdatePannelRemarks[rowId] = row.r_description
        }
        setUpdatePannelRemarks([...UpdatePannelRemarks])
        // setRemarksModal(false)
        setPannelRemarksModal(false)
        for (let i in childData) {
          if (childData[i].childId in RowRemarks) {
            let index = RowRemarks.indexOf(childData[i].childId)
            RowRemarks[childData[i].childId] = [];
            if (index == -1) {
              RowRemarks[childData[i].childId] = row.r_description
            }
            else {
              RowRemarks[childData[i].childId] = [];
              RowRemarks[childData[i].childId] = row.r_description
            }
          }
        }
        setRowRemark([...RowRemarks])


        if (collapseremarksModal === true) {
          //  setCollapseremarkmessage(temp)
          let temp = []
          // setCollapseMessages(temp)
          setCollapseremarksModal(false)
        }
      }}>{row.r_description}</a>,
    },
  ]

  useEffect(() => {
    if (recallremarks) {
      let temp = [];
      for (let i = 0; i < recallremarks.length; i++) {
        if (recallremarks[i].r_description != '') {
          temp.push(recallremarks[i])
        }
        Setremarksvalue(temp)
      }
    }

  }, [recallremarks])

  useEffect(() => {
    if (editdata.length > 0) {
      // console.log(editdata, 'editdata');
      let temp = checkdata
      for (let i in editdata) {
        temp.push(editdata[i])
      }
      setCheckdata([...temp])
    }
  }, [editdata])



  useEffect(() => {
    saveformValidator.current.showMessages()
    formValidator.current.showMessages()
    validator.current.showMessages()
  }, [])

  useEffect(() => {
    if (isAuth) {

      const payload = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "GetStatutorySettings",
            {
              "le_id": entityid,
              "div_id": null,
              "cat_id": null,
            }
          ]
        }
      ]

      const filterAPI = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "GetStatutorySettingsFilters",
            {}
          ]
        }
      ]
      if (entityid != 'null') {
        statutorylist({
          payload: payload,
          paramid: paramid
        })
      }

      filterdata({
        payload: filterAPI,
        paramid: paramid
      })
    }
  }, [isAuth])

  useEffect(() => {
    // setTempeditdata(editdata)
    let array = [];
    let finalarray = [];
    let datavalue = [];
    if (checkdata) {
      const ab = checkdata.map(function (item) {
        if ((array.indexOf(item.lone_statu_name) < 0))
          array.push(item.lone_statu_name)
      })
      array.map((data, i) => {
        // let array2 = [];
        let temp = [];
        let entityArray = [];
        if (data) {
          checkdata.map((child, childIndex) => {
            if (data === child.lone_statu_name) {
              entityArray = {
                'app_status': child.app_status,
                'comp_id': child.comp_id,
                'comp_name': child.comp_name,
                'compfielaw_url': child.compfielaw_url,
                'criticality_client': child.criticality_client,
                'criticality_know': child.criticality_know,
                'descp': child.descp,
                'frequency_name': child.frequency_name,
                'h_id': child.h_id,
                'is_assigned': child.is_assigned,
                'lone_statu_name': child.lone_statu_name,
                'mapping_provision': child.mapping_provision,
                'not_app_remarks': child.not_app_remarks,
                'opt_status': child.opt_status,
                's_prov': child.s_prov,
                'unit_wise_status': child.unit_wise_status,
                'collapseId': i,
                'childId': childIndex,
                'parentId': i,
                'indexNo': count1
              }
              temp.push(entityArray)
              { <span hidden>{count1 = count1 + 1}</span> }
            }
          })
        }
        datavalue = {
          index: i,
          parent: data,
          child: temp
        }
        finalarray.push(datavalue)
      })
      Settitledata(finalarray)
      getData()
    }

  }, [checkdata])

  useEffect(() => {
    titledata && titledata.length && titledata.map((item, i) => {
      let temp = [];
      UpdatePannelRemarks[i] = item.child[0].not_app_remarks
      item && item.child.length > 0 && item.child.map((itemdata, j) => {
        temp.push(itemdata.unit_wise_status[0].comp_opt_status)
      })
      if (temp.includes(1) === true) {
        CollapseStatus[i] = 1
      } else {
        let count = 0
        for (let k in temp) {
          if (temp[k] === 2) {
            count = count + 1
            if (count === temp.length) {
              CollapseStatus[i] = 2
            }
          }
        }
        if (count !== temp.length) {
          CollapseStatus[i] = 0
        }
      }
      // CollapseStatus[i] = item.child[0].app_status == true ? 1 : 0
    })
    setUpdatePannelRemarks([...UpdatePannelRemarks])
    setCollapseStatus([...CollapseStatus])

  }, [titledata])

  useEffect(() => {
    let temp = []
    let tempArr = []
    if (statutorypayload.length > 0) {
      for (let i in statutorypayload) {
        temp.push({
          "c_c_id": statutorypayload && statutorypayload[i].c_c_id,
          "a_status": RowStatus[i],
          "n_a_remarks": RowRemarks[i],
          "comp_id": statutorypayload && statutorypayload[i].comp_id,
          "c_o_status": updateStatus[i],
          "c_remarks": updateRemarks[i],
          "u_name": statutorypayload && statutorypayload[i].u_name,
          "u_id": statutorypayload && statutorypayload[i].u_id,
          "c_a_status": statutorypayload && statutorypayload[i].c_a_status,
          "h_id": statutorypayload && statutorypayload[i].h_id
        })
        setStatutorypayload(temp)
      }
    }
    if (tempstatutorypayload.length > 0) {
      for (let i in tempstatutorypayload) {
        tempArr.push({
          "c_c_id": tempstatutorypayload && tempstatutorypayload[i].c_c_id,
          "a_status": RowStatus[i],
          "n_a_remarks": RowRemarks[i],
          "comp_id": tempstatutorypayload && tempstatutorypayload[i].comp_id,
          "c_o_status": updateStatus[i],
          "c_remarks": updateRemarks[i],
          "u_name": tempstatutorypayload && tempstatutorypayload[i].u_name,
          "u_id": tempstatutorypayload && tempstatutorypayload[i].u_id,
          "c_a_status": tempstatutorypayload && tempstatutorypayload[i].c_a_status,
          "h_id": tempstatutorypayload && tempstatutorypayload[i].h_id,
          "collapseId": tempstatutorypayload && tempstatutorypayload[i].collapseId,
        })
        setTempstatutorypayload(tempArr)
      }
    }
  }, [updateRemarks, updateStatus, RowStatus, UpdatePannelRemarks, RowRemarks])

  useEffect(() => {
    let tempArray = []
    // let tempArr = []
    titledata && titledata.length && titledata.map((itemdata, i) => {
      itemdata && itemdata.child.length > 0 && itemdata.child.map((item, j) => {
        tempArray.push({
          "c_c_id": item.unit_wise_status[0].c_comp_id,
          "a_status": item.unit_wise_status[0].act_status,
          "n_a_remarks": item.not_app_remarks,
          "comp_id": item.comp_id,
          "c_o_status": item.unit_wise_status[0].comp_opt_status,
          "c_remarks": item.unit_wise_status[0].comp_remarks,
          "u_name": selectedRows && selectedRows.length > 0 && selectedRows[0].u_name,
          "u_id": item.unit_wise_status[0].unit_id,
          "c_a_status": item.unit_wise_status[0].comp_app_status,
          "h_id": item.unit_wise_status[0].h_id,
          "collapseId": item.collapseId
        })
        // tempArr.push({
        //   "c_c_id": item.unit_wise_status[0].c_comp_id,
        //   "a_status": item.unit_wise_status[0].act_status,
        //   "n_a_remarks": item.not_app_remarks,
        //   "comp_id": item.comp_id,
        //   "c_o_status": item.unit_wise_status[0].comp_opt_status,
        //   "c_remarks": item.unit_wise_status[0].comp_remarks,
        //   "u_name": selectedRows && selectedRows.length > 0 && selectedRows[0].u_name,
        //   "u_id": item.unit_wise_status[0].unit_id,
        //   "c_a_status": item.unit_wise_status[0].comp_app_status,
        //   "h_id": item.unit_wise_status[0].h_id,
        //   "collapseId":item.collapseId
        // })
      })
    })
    setTempstatutorypayload([...tempArray])
    setStatutorypayload(tempArray)
  }, [titledata])

  const handleCancel = () => {
    setRemarksModal(false)
    SetisModalVisible(false);
    setPannelRemarksModal(false)
  }

  const passwordcancel = () => {
    setAddFormSubmit1(false)
    Setpasswordmodal(false);
  }

  const showmore = () => {
    editscreen(checkdata.length)
  }

  const unlock = (e_data) => {
    Setpasswordmodal(true)
    Setsubmitpasswordmodal(true)
    Setpassword({
      ...password,
      le_id: entityid,
      d_id: e_data.d_id,
      u_id: e_data.u_id,
      lock: e_data.is_new
    })
  }

  const handleOk = () => {
    setRemarksModal(false)
    setPannelRemarksModal(false)
  };

  const getData = () => {
    let array = [];
    let countrylisttempdataNew = [];
    let arrNew = [];
    const ab = checkdata.map(function (item) {
      if ((array.indexOf(item.lone_statu_name) < 0))
        arrNew.push(item.lone_statu_name)
    })

    arrNew.map((data, i) => {
      let entityArrayNew = [];
      let tempArraryNew = [];
      if (data) {
        checkdata.map((child, childIndex) => {
          if (data === child.lone_statu_name) {
            entityArrayNew = {
              'app_status': child.app_status,
              'comp_id': child.comp_id,
              'comp_name': child.comp_name,
              'compfielaw_url': child.compfielaw_url,
              'criticality_client': child.criticality_client,
              'criticality_know': child.criticality_know,
              'descp': child.descp,
              'frequency_name': child.frequency_name,
              'h_id': child.h_id,
              'is_assigned': child.is_assigned,
              'lone_statu_name': child.lone_statu_name,
              'mapping_provision': child.mapping_provision,
              'not_app_remarks': child.not_app_remarks,
              'opt_status': child.opt_status,
              's_prov': child.s_prov,
              'unit_wise_status': child.unit_wise_status,
              'collapseId': i,
              'childId': childIndex,
              'parentId': i
            }
            tempArraryNew.push(entityArrayNew)
            updateRemarks[childIndex] = child.unit_wise_status[0].comp_remarks
            updateStatus[childIndex] = child.unit_wise_status[0].comp_opt_status
            RowRemarks[childIndex] = child.not_app_remarks
            RowStatus[childIndex] = child.opt_status
          }
        })


      }
      countrylisttempdataNew.push(tempArraryNew)
    })
    var merged = [].concat.apply([], countrylisttempdataNew);
    setData(merged)
    setUpdateStatus([...updateStatus])
    setUpdateRemarks([...updateRemarks])
    setRowRemark([...RowRemarks])
    setRowStatus([...RowStatus])
  }

  const genExtra = (val, collapseId, parent, child) => {
    // console.log(val,collapseId,parent,child);
    return panel_data(val, collapseId, parent, child);
  };

  const updatestatutorySettings = () => {
    Setsubmitpasswordmodal(false)
    Setpasswordmodal(true)
  }

  const savestatutorysettings = () => {
    setRemarksFormSubmit(true)
    // console.log(saveformValidator.current.allValid(), 'saveformValidator.current.allValid()');
    if (saveformValidator.current.allValid()) {
      SaveStatutorySettings({
        payload:
          [
            authtoken,
            {
              "session_token": authtoken,
              "request": [
                "SaveStatutorySettings",
                {
                  "update_statutories": statutorypayload,
                  "le_id": entityid,
                  "s_s": 1,
                  "d_id": selectedRows[0].d_id,
                  "u_ids": uiddomian
                },

              ]
            }
          ],
        paramid: paramid
      })
    }
  }

  const panel_data = (val, collapseId, parent, child) => {
    // console.log(collapseId, parent, child, 'mukilan');
    // for (let i in child) {
    // console.log(child[0],'childdddd');
    // child[i].unit_wise_status[0].comp_opt_status
    // }
    if (CollapseStatus[collapseId] != 1) {
      return <div className='d-flex align-items-end'
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
        }}
      >

        <label style={{ "paddingRight": "15px" }}>Remarks :</label>
        <TextArea cols="5" rows="1" style={{ width: "120px" }} type={"text"}
          placeholder="Enter remarks"
          defaultValue={UpdatePannelRemarks[collapseId]} key={UpdatePannelRemarks[collapseId]}
          onBlur={(e) => {
            if (collapseId in UpdatePannelRemarks) {
              // let index = updateRemarks.indexOf(rowId)
              UpdatePannelRemarks[collapseId] = [];
              if (UpdatePannelRemarks.indexOf(collapseId) == -1) {
                UpdatePannelRemarks[collapseId] = e.target.value
              }

            } else {
              UpdatePannelRemarks[collapseId] = [];
              UpdatePannelRemarks[collapseId] = e.target.value
            }
            // setRemarksModal(false)
            setPannelRemarksModal(false)

            for (let i in child) {
              if (child[i].childId in RowRemarks) {
                let index = RowRemarks.indexOf(child[i].childId)
                RowRemarks[child[i].childId] = [];
                if (index == -1) {
                  RowRemarks[child[i].childId] = e.target.value
                }
                else {
                  RowRemarks[child[i].childId] = [];
                  RowRemarks[child[i].childId] = e.target.value
                }
              }
            }
            setRowRemark([...RowRemarks])


            if (PanelRemarkChange == true) {
              for (let i in child) {
                if (child[i].childId in updateRemarks) {
                  let index = updateRemarks.indexOf(child[i].childId)
                  updateRemarks[child[i].childId] = [];
                  if (index == -1) {
                    updateRemarks[child[i].childId] = e.target.value
                  }
                  else {
                    updateRemarks[child[i].childId] = [];
                    updateRemarks[child[i].childId] = e.target.value
                  }
                }
              }
              setUpdateRemarks([...updateRemarks])
            }
          }
          }

        />
        {saveformValidator.current.message(
          'remarks',
          UpdatePannelRemarks[collapseId],
          'required',
          {
            className: `invalid-feedback ${remarksFormSubmit ? 'show' : 'hide'}`,
            messages: {
              required: 'Remarks Required',

            }
          })
        }
        <PlusCircleOutlined className='text-info' style={{ "paddingLeft": "5px" }} onClick={() => { showRemarksModall(collapseId, parent, child) }} />
      </div>
    } else {
      return null
    }
  }

  const editscreen = (len) => {
    let uid = []
    seteditshow(true);
    setlistshow(false);
    for (let i = 0; i < selectedRows.length; i++) {
      uid.push(selectedRows[i].u_id)
    }
    setUiddomain(uid)
    const editvalue = [
      authtoken,
      {
        "session_token": authtoken,
        "request": [
          "GetSettingsCompliances",
          {
            "le_id": entityid,
            "u_ids": uid,
            "r_count": len,
            "d_id": 1
          }
        ]
      }
    ]
    editstatutory({
      payload: editvalue,
      paramid: paramid
    })
  }

  const previous = () => {
    seteditshow(false);
    setlistshow(true);
    // setTimeout(() => {
    // window.location.reload(false);
    // }, 2000);
    setCheckedRow(false)
    setSelectedRows([])
    var ele = document.getElementsByName('allchecked');
    ele[0].checked = false;
    var ele1 = document.getElementsByName('checked');
    for (var j = 0; j < ele1.length; j++) {
      if (ele1[j].type === 'checkbox')
        ele1[j].checked = false;
    }
    setCheckdata([])
  }

  const showRemarksModal = (comp_id, collapseId, childId) => {
    setChildValue(childId)
    GetRemarksData({
      payload:
        [
          authtoken,
          {
            "session_token": authtoken,
            "request": [
              "GetRemarksData",
              {}
            ]
          }
        ],
      paramid: paramid
    })
    setRemarksModal(true);
  };

  const showRemarksModall = (collapseId, parent, child) => {
    setExtraCollapseId(collapseId)
    setChildData(child)
    // setTempcollapseid(collapseId)
    let temp = []
    temp.push(collapseId)
    setCollapseMessages([...collapsemessages, ...temp])
    GetRemarksData({
      payload:
        [
          authtoken,
          {
            "session_token": authtoken,
            "request": [
              "GetRemarksData",
              {}
            ]
          }
        ],
      paramid: paramid
    })
    // setRemarksModal(true);
    setPannelRemarksModal(true)
    setCollapseremarksModal(true);
  };

  const filterapply = (e) => {
    setAddFormSubmit(true)
    setshowbutton(true)
    let payloaddata;


    if (validator.current.allValid()) {
      setKey(0)
      if (filtervalue.entity === '') {
        payloaddata = [
          authtoken,
          {
            "session_token": authtoken,
            "request": [
              "GetStatutorySettings",
              {
                "le_id": entityid,
                "div_id": parseInt(filtervalue.division),
                "cat_id": parseInt(filtervalue.category),
              }
            ]
          }
        ]
      } else {

        payloaddata = [
          authtoken,
          {
            "session_token": authtoken,
            "request": [
              "GetStatutorySettings",
              {
                "le_id": filtervalue.entity,
                "div_id": parseInt(filtervalue.division),
                "cat_id": parseInt(filtervalue.category),
              }
            ]
          }
        ]
      }
      statutorylist({
        payload: payloaddata,
        paramid: paramid
      })
      SetisModalVisible(false);
    }
  }

  const submitpassword = (e) => {
    setAddFormSubmit1(true)
    e.preventDefault();
    if (formValidator.current.allValid() && submitpasswordmodal === true) {

      const payload = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "ChangeStatutorySettingsLock",
            {
              "le_id": password.le_id,
              "d_id": password.d_id,
              "u_id": password.u_id,
              "lock": password.lock,
              "password": password.passwordvalue
            }
          ]
        }
      ]

      locksettings({
        payload: payload,
        paramid: paramid,
        history: history
      })
      Setpasswordmodal(false);
    } else {
      if (formValidator.current.allValid()) {
        UpdateStatutorySettings({
          payload:
            [
              authtoken,
              {
                "session_token": authtoken,
                "request": [
                  "UpdateStatutorySettings",
                  {
                    "password": password.passwordvalue,
                    "update_statutories": statutorypayload,
                    "le_id": entityid,
                    "s_s": 2,
                    "d_id": selectedRows[0].d_id,
                    "u_ids": uiddomian
                  },

                ]
              }
            ],
          paramid: paramid
        })
      }
    }
  }
  const addAllSelectedDataValues = (checked, u_id) => {
    console.log(checked, "checked");
    let allArray = [];
    let listArray
    var ele = document.getElementsByName('checked');
    if (checked === true) {
      listArray = _.filter(list, { is_locked: false });
      setCheckedRow(true)
      if (listArray && listArray.length > 0) {
        let temp = listArray.filter((item) => {
          return item.is_locked == false
        })
        let temp1 = []
        for (let i in listArray) {
          temp1.push(listArray[i])
        }
        for (var i = 0; i < ele.length; i++) {
          if (ele[i].type === 'checkbox')
            ele[i].checked = true;
        }
        setSelectedRows(temp1)
      }
    } else {
      setCheckedRow(false)
      setSelectedRows([])
      for (var j = 0; j < ele.length; j++) {
        if (ele[j].type === 'checkbox')
          ele[j].checked = false;
      }
    }
  };

  const addSelectedDataValues = (checked, u_id, act_name) => {
    var ele = document.getElementsByName('allchecked');
    let array
    let listArray
    let tempArray = selectedRows
    if (checkedRow === true) {
      if (checked === true) {
        array = _.find(list, { u_id: u_id });
        tempArray.push(array)
      } else {
        for (let i in tempArray) {
          if (tempArray[i].u_id === u_id) {
            tempArray.splice(i, 1)
          }
          if (list.length !== selectedRows.length) {
            setCheckedRow(false)
            for (var j = 0; j < ele.length; j++) {
              if (ele[j].type === 'checkbox')
                ele[j].checked = false;
            }
          }
        }
      }
      setSelectedRows([...tempArray])
    }
    else {
      if (checked === true) {
        listArray = _.filter(list, { is_locked: false });
        array = _.find(list, { u_id: u_id });
        tempArray.push(array)
        if (listArray.length === selectedRows.length) {
          //setCheckedRow(true)
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].type === 'checkbox')
              ele[i].checked = true;
          }
        }
      } else {
        for (let i in tempArray) {
          if (tempArray[i].u_id === u_id) {
            tempArray.splice(i, 1)
          }
        }
        ele[0].checked = false;
      }
      setSelectedRows([...tempArray])
    }
  }

  const homescreen = () => {
    window.location.href = '/dashboard'
  }

  return (
    <div>
      <div id='page-wrapper' className="page-wrapper">
        <div style={{ display: (!listshow ? 'none' : '') }}>
          <div className="page-titles pb-1 pt-1" style={{ maxWidth: "unset" }}>
            <div className="row">
              <div className="col-lg-8 col-md-8 col-12 align-self-center" style={{ padding: '0' }}>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 d-flex align-items-center">
                    <li className="breadcrumb-item">
                      <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <span style={{ color: '#1890ff' }}>Transaction</span>
                    </li>
                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                      <span style={{ color: '#1890ff' }}>Statutory Settings</span>
                    </li>
                  </ol>
                </nav>
              </div>

              <div className=' col-lg-4 col-md-4 col-12  align-self-center' style={{ position: 'absolute', right: 1 }} >
                {selectedRows.length > 0 ? <>
                  <div className="btn-md" style={{ float: 'right' }}>
                    < Button type="primary" shape="round" className='addbutton'
                      size='default'
                      icon={<EditOutlined />}
                      onClick={(e) => {
                        editscreen(editdata.length)
                      }}>
                      Edit
                    </Button></div>
                </>
                  : ''
                }
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'}>
              <Collapse className='report-collapse' defaultActiveKey={["1"]}>
                <Panel header="Statutory Settings" key={key}>
                  <div className='col-md-12'>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor=""><b>Business Group :</b></label>
                        </div>
                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                          <div className="form-group">
                            <Select
                              allowClear={true}
                              size="default"
                              placeholder="Select Business Group"
                              onChange={(data, value) => {
                                if (data !== undefined) {
                                  Setfiltervalue({
                                    ...filtervalue,
                                    bg_id: data,
                                    bg_name: value.children
                                  })
                                }
                                else {
                                  Setfiltervalue({
                                    ...filtervalue,
                                    bg_id: "",
                                    bg_name: "",
                                    entity: '',
                                    division: '',
                                    category: '',
                                    legal_entity_id: ''
                                  })
                                }
                              }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                              value={filtervalue.bg_id ? filtervalue.bg_id : undefined}
                              style={{ width: '100%', marginTop: '5px' }}

                            >
                              {sessionArr && sessionArr.length && sessionArr.map((item, i) => {

                                if (item.bg_name !== null) {
                                  return (
                                    <Option key={item.bg_id}>
                                      {item.bg_name}
                                    </Option>
                                  );
                                }
                              })}
                            </Select>

                          </div> :
                          <p> - </p>
                        }
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}> *</span>
                        </div>
                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                          <>
                            <Select
                              allowClear={true}
                              size="default"
                              placeholder="Select Legal Entity"
                              onChange={(data, value) => {
                                if (data !== undefined) {
                                  setCurrentEntity(data)
                                  Setfiltervalue({
                                    ...filtervalue,
                                    legal_entity_id: data,
                                    legal_entity_name: value.children
                                  })
                                }
                                else {
                                  setCurrentEntity(data)
                                  Setfiltervalue({
                                    ...filtervalue,
                                    legal_entity_id: "",
                                    legal_entity_name: ""
                                  })
                                }
                              }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                              //  value={userDetails.legal_entity_id}
                              value={filtervalue.legal_entity_id ? filtervalue.legal_entity_id : undefined}
                              style={{ "width": "100%", marginTop: '5px' }}
                            >
                              {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                return (
                                  <Option key={item.le_id}>
                                    {item.le_name}
                                  </Option>
                                );
                              })}
                            </Select>
                            {validator.current.message(
                              'legal_entity_id',
                              filtervalue.legal_entity_id,
                              'required',
                              {
                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                messages: {
                                  required: 'Legal Entity Required',
                                }
                              })}

                          </> : <p style={{ "color": "#6666ff", "fontWeight": "bold", width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>}

                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label ><b>Division :</b></label>
                        </div>

                        <Select
                          allowClear={true}
                          size="default"
                          placeholder="Select Division"
                          className=""
                          style={{ "width": "100%", marginTop: '5px' }}
                          showSearch
                          optionFilterProp="children"
                          value={filtervalue.division ? filtervalue.division : undefined}
                          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          onChange={(e) => {
                            if (e !== undefined) {
                              Setfiltervalue({
                                ...filtervalue,
                                division: e.target.value
                              })
                            }
                            else {
                              Setfiltervalue({
                                ...filtervalue,
                                division: ""
                              })
                            }
                          }}>

                          {filterdatas && filterdatas.length > 0 && filterdatas.div_infos.map((item) => {
                            return <option value={item.div_id}>{item.div_name}</option>
                          })}
                        </Select>

                      </div>
                    </div>
                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label ><b>Category :</b></label>
                          </div>
                          <Select
                            className=""
                            style={{ "width": "100%", marginTop: '5px' }}
                            allowClear={true}
                            value={filtervalue.category ? filtervalue.category : undefined}
                            size="default"
                            placeholder="Select Category"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            onChange={(e) => {
                              if (e !== undefined) {
                                Setfiltervalue({
                                  ...filtervalue,
                                  category: e.target.value
                                })
                              }
                              else {
                                Setfiltervalue({
                                  ...filtervalue,
                                  category: ""
                                })
                              }
                            }}>

                            {filterdatas && filterdatas.length > 0 && filterdatas.cat_info.map((item) => {
                              return <option value={item.cat_id}>{item.cat_name}</option>
                            })}
                          </Select>

                        </div>
                      </div>

                      <div style={{ marginTop: '10px', textAlign: 'center' }} >
                        <div className="form-group">
                          <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", align: 'center' }}
                            size='default' icon={<EyeOutlined />} onClick={filterapply}> Show
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
            {/* </div> */}
          </div>

          {list && list.length > 0 && showbutton == true ?
            <div>
              <div className="container-fluid pt-1">
                {/* <div className="row"> */}
                {/* <div className="col-12"> */}
                <div className='card-body1-statuatory rounded' >
                  <div className="table-responsive">
                    <div className="edit-user-priv">
                      <Collapse defaultActiveKey={["1"]}>
                        {/* <Panel header="Overall Statistics" key={key} extra={`Selected Units : ${selectedRows.length} Total Compliances : 447 Locked count : 1 Unlocked count : 2`} > */}
                        <Panel header="Overall Statistics" key={key} extra={<><span style={{ paddingRight: '80px' }}>Selected Units : {selectedRows.length}</span><span style={{ paddingRight: '80px' }}>Total Compliances : 447</span><span style={{ paddingRight: '80px' }}>Locked count : 1</span><span style={{ paddingRight: '50px' }}>Unlocked count : 2</span></>} >

                          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div style={{ height: '50%', paddingTop: '5px' }}>
                              <a>
                                <div className="card p-2 w-100 m-0 overflow-hidden card-hover" style={{ borderColor: 'brown' }}>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <h3 className="pt-1 mb-0 h1">
                                        <b style={{ color: 'brown' }}>&nbsp;{selectedRows.length}</b>
                                      </h3>
                                      <h6 className="text-muted mb-0 fw-normal"><b style={{ color: 'brown' }}>Selected Units</b></h6>
                                    </div>
                                    <div className="ms-auto">
                                      <span
                                        className=" btn btn-m btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center">
                                        <FeatherIcon icon="check-square" />
                                      </span>
                                    </div>
                                  </div>
                                  <div id="monthly-sales"></div>
                                </div>
                              </a>
                            </div>
                            <div style={{ height: '50%', paddingTop: '5px' }}>
                              <a><div className="card p-2 w-100 m-0 overflow-hidden card-hover" style={{ borderColor: 'orange' }}>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <h3 className="pt-1 mb-0 h1">
                                      <b style={{ color: 'orange' }}> 447</b>
                                    </h3>
                                    <h6 className="text-muted mb-0 fw-normal"><b style={{ color: 'orange' }}>Total Compliances</b></h6>
                                  </div>
                                  <div className="ms-auto">
                                    <span
                                      className=" btn btn-m btn-light-warning text-warning btn-circle d-flex align-items-center justify-content-center">
                                      <FeatherIcon icon="clipboard" />
                                    </span>
                                  </div>
                                </div>
                                <div id="monthly-sales1"></div>
                              </div></a>
                            </div>
                            <div style={{ height: '50%', paddingTop: '5px' }}>
                              <a><div className="card p-2 w-100 m-0 m-0 overflow-hidden card-hover" style={{ borderColor: 'red' }}>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <h3 className="pt-1 mb-0 h1">
                                      <b style={{ color: 'red' }}>1</b>
                                    </h3>
                                    <h6 className="text-muted mb-0 fw-normal"><b style={{ color: 'red' }}>Locked count</b></h6>
                                  </div>
                                  <div className="ms-auto">
                                    <span
                                      className=" btn btn-m btn-light-danger
                                        text-danger btn-circle d-flex
                                        align-items-center justify-content-center">
                                      <FeatherIcon icon="lock" />
                                    </span>
                                  </div>
                                </div>
                                <div id="monthly-sales4"></div>
                              </div></a>
                            </div>
                            <div style={{ height: '50%', paddingTop: '5px' }}>
                              <a><div className="card p-2 w-100 m-0 overflow-hidden card-hover" style={{ borderColor: 'green' }}>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <h3 className="pt-1 mb-0 h1">
                                      <b style={{ color: 'green' }}>2</b>
                                    </h3>
                                    <h6 className="text-muted mb-0 fw-normal fs-2"><b style={{ color: 'green' }}>Unlocked count</b></h6>
                                  </div>
                                  <div className="ms-auto">
                                    <span
                                      className="btn btn-m btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                      <FeatherIcon icon="unlock" />
                                    </span>
                                  </div>
                                </div>
                                <div id="monthly-sales3"></div>
                              </div></a>
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '6px' }}>
                            <div>
                              <i style={{ color: "black", }} className="bi bi-square-fill"></i> <label style={{ color: '#808080' }}>Statutes assigned</label>
                            </div><div><i style={{ color: "blue" }} className="bi bi-square-fill"></i> <label style={{ color: '#808080' }}>Units to be assigned</label>
                            </div></div>
                          {/* <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <p><b>Selected Unit : </b>&nbsp;{selectedRows.length} </p>
                            <p><b>No.of Compliances: </b>&nbsp; 2055 </p>
                            <div style={{ display: 'flex' }}><span className="btn btn-m btn-light-danger
                              text-danger btn-circle d-flex
                              align-items-center justify-content-center"
                              style={{
                                width: "30px",
                                height: "30px"
                              }}><Tooltip title='Locked items count'><FeatherIcon icon="lock" /></Tooltip></span> 3</div>
                            <div style={{ display: 'flex' }}><span className="btn btn-m btn-light-success text-success btn-circle d-flex align-items-center justify-content-center" style={{
                              width: "30px",
                              height: "30px"
                            }}>
                              <Tooltip title='Unlocked items count'><FeatherIcon icon="unlock" /></Tooltip></span> 1</div>
                          </div> */}

                        </Panel>
                      </Collapse>
                      {/* </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid pt-1">
                <div className="row">
                  <div className="col-12">
                    <div className='card servcardround mb-2 pt-1' >
                      <div className="table-responsive">
                        <div className="edit-user-priv">
                          <Table style={{ padding: "0px 8px 5px 8px" }}
                            className={localStorage.getItem("currentTheme") + ' edit-user-priv'}
                            size={'small'}
                            columns={columns}
                            dataSource={list}
                            bordered
                            scroll={{ x: 1000 }}
                            pagination={false}
                            rowSelection={{
                              columnWidth: '30px',
                              columnTitle: <input type="checkbox" style={{ width: '10px' }} name="allchecked" id="all" className='checkclass'
                                onClick={(e) => {
                                  let checked = e.target.checked;
                                  addAllSelectedDataValues(
                                    checked
                                  );
                                }}
                              />,
                              ...rowSelection
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            null}
          {/* </div> */}
          {/* {selectedRows.length > 0 ?
                      <div className="btn btn-primary btn-md" onClick={(e) => {
                        editscreen()
                      }}>Edit</div> : ''
                    } */}
          {/* <br /> */}
          {/* <div style={{ paddingLeft: "20px" }}>
                    <p>Selected Unit : &nbsp;{selectedRows.length} </p>
                    <p>Statutes assigned to those units are marked in Black Color</p>
                    <p>Select atleast one unit</p>
                    <p style={{ color: '#0000FF' }}>Units to be assigned are marked in Blue Color</p>
                  </div> */}
          {/* </div> */}



        </div>
        {editshow == true && selectedRows.length > 1 ? <MultiUnitStatutorySettings selectedRows={selectedRows} setSelectedRows={setSelectedRows} seteditshow={seteditshow} setlistshow={setlistshow} /> :
          <div style={{ display: (!editshow ? 'none' : '') }}>
            <div className={scroll == true ? "d-block" : "d-none"}>
              <Button title="Scroll Back to Top" style={{ position: 'fixed', right: '30px', bottom: '50px', zIndex: 1, width: '34px', display: 'flex', justifyContent: 'center', borderRadius: '15px' }} onClick={() => { scrollup() }}><UpCircleOutlined style={{ fontSize: '1.5rem' }} /></Button>
            </div>
            <div>
              <div>
                <div className="page-titles pt-2 pb-0">
                  <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                          <li className="breadcrumb-item">
                            <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                            <Link>Transaction</Link>
                          </li>
                          <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                            <Link> Statutory Settings</Link>
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end" style={{ paddingRight: 0 }}>
                      <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                        icon={<ArrowLeftOutlined />} size='default' onClick={(e) => { previous(e) }}>
                        Back
                      </Button>

                      <button style={{ marginLeft: '3px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                        <Tooltip placement="left" title="Full Screen Mode" color='#1890ff'><FullscreenOutlined /></Tooltip>
                      </button>
                      {/* <button style={{ float: "right" }} className="usrbutton button--anthe prev" onClick={(e) => { previous(e) }}><span class="text-white fs-3 fw-bold">Back</span></button> */}
                      {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginTop: '7px' }}
                        size='default'
                        onClick={(e) => {
                          savestatutorysettings(e)
                        }}
                      >Save
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 stat-set-card">
                      <div className="card-body">
                        <div className="col-md-12 mt-3">
                          <div className="row">
                            {/* {titledata && titledata.map((itemdata, i) => { defaultActiveKey={['0']} */}
                            {titledata &&
                              titledata.map((itemdata, i) => {
                                return (
                                  <Collapse key={i} defaultActiveKey={['0']}
                                    expandIcon={() =>

                                      CollapseStatus[itemdata.index] == 0 ? <FeatherIcon className={'pointer'} icon="x-circle" size="20" onClick={(event) => {
                                        event.stopPropagation();
                                        setpanelRemarkChange(true)
                                        if (itemdata.index in CollapseStatus) {
                                          let index = CollapseStatus.indexOf(itemdata)
                                          CollapseStatus[itemdata.index] = [];
                                          // console.log(index == -1, 'index == -1');
                                          if (index == -1) {
                                            CollapseStatus[itemdata.index] = 2
                                          }
                                        } else {
                                          CollapseStatus[itemdata.index] = [];
                                          CollapseStatus[itemdata.index] = 2
                                        }
                                        setCollapseStatus([...CollapseStatus])
                                        for (let i in itemdata.child) {
                                          if (itemdata.child[i].childId in RowStatus) {
                                            let index = RowStatus.indexOf(itemdata.child[i].childId)
                                            RowStatus[itemdata.child[i].childId] = [];
                                            if (index == -1) {
                                              RowStatus[itemdata.child[i].childId] = 2
                                            }
                                            else {
                                              RowStatus[itemdata.child[i].childId] = [];
                                              RowStatus[itemdata.child[i].childId] = 2
                                            }
                                          }
                                          if (itemdata.child[i].childId in updateStatus) {
                                            let index = updateStatus.indexOf(itemdata.child[i].childId)
                                            // console.log(index, 'index');
                                            updateStatus[itemdata.child[i].childId] = [];
                                            // console.log(index == -1, 'index == -1');
                                            if (index == -1) {
                                              updateStatus[itemdata.child[i].childId] = 2
                                            }
                                            else {
                                              updateStatus[itemdata.child[i].childId] = [];
                                              updateStatus[itemdata.child[i].childId] = 2
                                            }
                                          }
                                          if (itemdata.child[i].childId in updateRemarks) {
                                            let index = updateRemarks.indexOf(itemdata.child[i].childId)
                                            // console.log(index, 'index');
                                            updateRemarks[itemdata.child[i].childId] = [];
                                            // console.log(index == -1, 'index == -1');
                                            if (index == -1) {
                                              updateRemarks[itemdata.child[i].childId] = null
                                            }
                                            else {
                                              updateRemarks[itemdata.child[i].childId] = [];
                                              updateRemarks[itemdata.child[i].childId] = null
                                            }
                                          }
                                          if (itemdata.child[i].childId in RowRemarks) {
                                            let index = RowRemarks.indexOf(itemdata.child[i].childId)
                                            // console.log(index, 'index');
                                            RowRemarks[itemdata.child[i].childId] = [];
                                            // console.log(index == -1, 'index == -1');
                                            if (index == -1) {
                                              RowRemarks[itemdata.child[i].childId] = UpdatePannelRemarks[itemdata.index]
                                            }
                                            else {
                                              RowRemarks[itemdata.child[i].childId] = [];
                                              RowRemarks[itemdata.child[i].childId] = UpdatePannelRemarks[itemdata.index]
                                            }
                                          }
                                        }
                                        setRowStatus([...RowStatus])
                                        setUpdateStatus([...updateStatus])
                                        setUpdateRemarks([...updateRemarks])
                                        setRowRemark([...RowRemarks])

                                      }} /> :
                                        CollapseStatus[i] == 1 ? <FeatherIcon icon="check-circle" className={'pointer'} size={20} style={{ color: '#318CE7' }} onClick={(event) => {
                                          setpanelRemarkChange(true)
                                          event.stopPropagation();
                                          let temp = []
                                          if (itemdata.index in CollapseStatus) {
                                            let index = CollapseStatus.indexOf(itemdata)
                                            CollapseStatus[itemdata.index] = [];
                                            if (index == -1) {
                                              CollapseStatus[itemdata.index] = 0
                                            }
                                          } else {
                                            CollapseStatus[itemdata.index] = [];
                                            CollapseStatus[itemdata.index] = 0
                                          }
                                          setCollapseStatus([...CollapseStatus])

                                          for (let i in itemdata.child) {
                                            if (itemdata.child[i].childId in RowStatus) {
                                              let index = RowStatus.indexOf(itemdata.child[i].childId)
                                              // console.log(index, 'index');
                                              RowStatus[itemdata.child[i].childId] = [];
                                              // console.log(index == -1, 'index == -1');
                                              if (index == -1) {
                                                RowStatus[itemdata.child[i].childId] = 0
                                              }
                                              else {
                                                RowStatus[itemdata.child[i].childId] = [];
                                                RowStatus[itemdata.child[i].childId] = 0
                                              }
                                            }
                                            if (itemdata.child[i].childId in updateStatus) {
                                              let index = updateStatus.indexOf(itemdata.child[i].childId)
                                              // console.log(index, 'index');
                                              updateStatus[itemdata.child[i].childId] = [];
                                              // console.log(index == -1, 'index == -1');
                                              if (index == -1) {
                                                updateStatus[itemdata.child[i].childId] = 0
                                              }
                                              else {
                                                updateStatus[itemdata.child[i].childId] = [];
                                                updateStatus[itemdata.child[i].childId] = 0
                                              }
                                            }
                                            if (itemdata.child[i].childId in updateRemarks) {
                                              let index = updateRemarks.indexOf(itemdata.child[i].childId)
                                              // console.log(index, 'index');
                                              updateRemarks[itemdata.child[i].childId] = [];
                                              // console.log(index == -1, 'index == -1');
                                              if (index == -1) {
                                                updateRemarks[itemdata.child[i].childId] = null
                                              }
                                              else {
                                                updateRemarks[itemdata.child[i].childId] = [];
                                                updateRemarks[itemdata.child[i].childId] = null
                                              }
                                            }
                                            if (itemdata.child[i].childId in RowRemarks) {
                                              let index = RowRemarks.indexOf(itemdata.child[i].childId)
                                              // console.log(index, 'index');
                                              RowRemarks[itemdata.child[i].childId] = [];
                                              // console.log(index == -1, 'index == -1');
                                              if (index == -1) {
                                                RowRemarks[itemdata.child[i].childId] = UpdatePannelRemarks[itemdata.index]
                                              }
                                              else {
                                                RowRemarks[itemdata.child[i].childId] = [];
                                                RowRemarks[itemdata.child[i].childId] = UpdatePannelRemarks[itemdata.index]
                                              }
                                            }
                                          }
                                          setUpdateStatus([...updateStatus])
                                          setRowStatus([...RowStatus])
                                          setUpdateRemarks([...updateRemarks])
                                          setRowRemark([...RowRemarks])
                                        }} /> : <FeatherIcon icon="minus-circle" className={'pointer'} size={20} style={{ color: '	#FF0000' }} onClick={(event) => {
                                          setpanelRemarkChange(true)
                                          event.stopPropagation();
                                          let temp = []
                                          if (itemdata.index in CollapseStatus) {
                                            let index = CollapseStatus.indexOf(itemdata)
                                            CollapseStatus[itemdata.index] = [];
                                            if (index == -1) {
                                              CollapseStatus[itemdata.index] = 1
                                            }
                                            for (let i in itemdata.child) {
                                              setMinuscircleoutlined([])
                                              setCloseoutlined([])
                                              temp.push(itemdata.child[i].comp_id
                                              )
                                            }
                                            setCheckoutlined([...checkoutlined, ...temp])
                                          } else {
                                            CollapseStatus[itemdata.index] = [];
                                            CollapseStatus[itemdata.index] = 1
                                          }
                                          setCollapseStatus([...CollapseStatus])
                                          for (let i in itemdata.child) {
                                            if (itemdata.child[i].childId in updateStatus) {
                                              let index = updateStatus.indexOf(itemdata.child[i].childId)
                                              // console.log(index, 'index');
                                              updateStatus[itemdata.child[i].childId] = [];
                                              // console.log(index == -1, 'index == -1');
                                              if (index == -1) {
                                                updateStatus[itemdata.child[i].childId] = 1
                                              }
                                              else {
                                                updateStatus[itemdata.child[i].childId] = [];
                                                updateStatus[itemdata.child[i].childId] = 1
                                              }
                                            }
                                            if (itemdata.child[i].childId in RowStatus) {
                                              let index = RowStatus.indexOf(itemdata.child[i].childId)
                                              // console.log(index, 'index');
                                              RowStatus[itemdata.child[i].childId] = [];
                                              // console.log(index == -1, 'index == -1');
                                              if (index == -1) {
                                                RowStatus[itemdata.child[i].childId] = 1
                                              }
                                              else {
                                                RowStatus[itemdata.child[i].childId] = [];
                                                RowStatus[itemdata.child[i].childId] = 1
                                              }
                                            }
                                            if (itemdata.child[i].childId in updateRemarks) {
                                              let index = updateRemarks.indexOf(itemdata.child[i].childId)
                                              // console.log(index, 'index');
                                              updateRemarks[itemdata.child[i].childId] = [];
                                              // console.log(index == -1, 'index == -1');
                                              if (index == -1) {
                                                updateRemarks[itemdata.child[i].childId] = null
                                              }
                                              else {
                                                updateRemarks[itemdata.child[i].childId] = [];
                                                updateRemarks[itemdata.child[i].childId] = null
                                              }
                                            }
                                            if (itemdata.child[i].childId in RowRemarks) {
                                              let index = RowRemarks.indexOf(itemdata.child[i].childId)
                                              // console.log(index, 'index');
                                              RowRemarks[itemdata.child[i].childId] = [];
                                              // console.log(index == -1, 'index == -1');
                                              if (index == -1) {
                                                RowRemarks[itemdata.child[i].childId] = UpdatePannelRemarks[itemdata.index]
                                              }
                                              else {
                                                RowRemarks[itemdata.child[i].childId] = [];
                                                RowRemarks[itemdata.child[i].childId] = UpdatePannelRemarks[itemdata.index]
                                              }
                                            }
                                          }
                                          setUpdateStatus([...updateStatus])
                                          setRowStatus([...RowStatus])
                                          setUpdateRemarks([...updateRemarks])
                                          setRowRemark([...RowRemarks])
                                        }} />

                                    }
                                    className="site-collapse-custom-collapse"
                                  >

                                    <Panel header={itemdata.parent} showArrow={<p>ssss</p>} key={itemdata.index}
                                      extra={genExtra(itemdata.child[0].not_app_remarks, itemdata.index, itemdata.parent, itemdata.child)}
                                    >
                                      <Table
                                        size={'small'}
                                        className={localStorage.getItem("currentTheme") + ' edit-user-priv'}
                                        columns={column}
                                        dataSource={itemdata.child}
                                        bordered
                                        scroll={{ x: 1000 }}
                                        pagination={false} />
                                    </Panel>
                                  </Collapse>
                                )
                              })}


                          </div>
                        </div>
                        {/* <div className="col-md-12 mt-2" >
                          <div className="row">
                            <label style={{ marginLeft: '-10px' }}>Showing 1 to {checkdata.length} of  {totaldatas}</label>
                            <label style={{ marginLeft: '-10px' }}>Note : Maximum 500 character allowed for each remarks</label>
                          </div>
                        </div> */}

                        <div className="col-md-12 mt-3">
                          <div className="row">
                            <div className='col-md-4' style={{ paddingLeft: '0px' }} >
                              <label>Showing 1 to {checkdata.length} of  {totaldatas}</label>
                              <label>Note : Maximum 500 character allowed for each remarks</label>
                            </div>
                            <div className='col-md-4 text-center'  >
                              {checkdata.length === totaldatas ?
                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#198754" }}
                                  onClick={(e) => {
                                    updatestatutorySettings(e)
                                  }} icon={<PlayCircleOutlined />} size='default'>
                                  Submit
                                </Button>
                                //     <div class="button2" id={localStorage.getItem('currentTheme')}  onClick={(e) => { updatestatutorySettings(e)}}>
                                //     <span></span>
                                //     <span></span>
                                //     <span></span>
                                //     <span></span>
                                //     Submit
                                // </div>
                                :
                                <Button type="primary" shape="round" className='showbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                  size='default'
                                  onClick={(e) => { showmore(e) }}
                                >Show More <DownOutlined />
                                </Button>
                              }
                            </div>
                            <div className='col-md-4' style={{ textAlign: 'right', paddingRight: 0 }} >
                              {/* {checkdata.length === totaldatas ? */}
                              <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                size='default'
                                onClick={(e) => {
                                  savestatutorysettings(e)
                                }}
                              >Save <SaveOutlined />
                              </Button>
                              {/* // : '' */}
                              {/* } */}
                            </div>
                          </div>
                        </div>
                      </div></div></div>
                </div>
              </div>
            </div>

            <Modal title="Remarks List" className={"remark-header add-service-prv modelradius " + localStorage.getItem('currentTheme')} visible={remarksModal} footer={null} onOk={handleOk} onCancel={handleCancel}>
              <Table
                // style={{ width: '420px !important' }}
                className={localStorage.getItem("currentTheme")}
                size={'small'}
                columns={remarkscolumn}
                dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                bordered
                // scroll={{ y: 400 }}
                pagination={false}
              // pagination={{
              //   defaultPageSize: dataTableProperties.pagesize,
              //   showSizeChanger: dataTableProperties.sizechanger,
              //   pageSizeOptions: dataTableProperties.pageSizeOptions
              // }}
              />
            </Modal>
            <Modal title="Remarks List" className={"remark-header add-service-prv modelradius " + localStorage.getItem('currentTheme')} visible={PannelremarksModal} footer={null} onOk={handleOk} onCancel={handleCancel}>
              <Table size={'small'}
                className={localStorage.getItem("currentTheme")}
                columns={Pannelremarkscolumn}
                dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                bordered
                // scroll={{ y: 400 }}
                pagination={false}
              // pagination={{
              //   defaultPageSize: dataTableProperties.pagesize,
              //   showSizeChanger: dataTableProperties.sizechanger,
              //   pageSizeOptions: dataTableProperties.pageSizeOptions
              // }}
              />
            </Modal>
            {/* Password Modal popup */}
            <Modal visible={passwordmodal} footer={null} onCancel={passwordcancel} className="newStyleModalPassword">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-6">
                    <label className="control-label">Password:</label>
                    <span style={{ "color": "red" }}>*</span>
                    <input
                      type="password"
                      id="password"
                      name='passwordvalue'
                      className="form-control"
                      placeholder="Enter password"
                      autoComplete='off'
                      onChange={(e) => {
                        Setpassword({
                          ...password,
                          passwordvalue: e.target.value
                        })
                      }}
                      value={password.passwordvalue}
                    />
                    {formValidator.current.message(
                      'Password',
                      password.passwordvalue,
                      ['required'],
                      {
                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                        messages: {
                          regex: 'Password Required'
                        }
                      })}
                  </div>
                </div>
              </div>
              <div className="form-actions text-center">

                <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#198754", marginTop: '10px' }} icon={<PlayCircleOutlined />} size='default'
                  onClick={submitpassword} >

                  Submit
                </Button>
                {/* <div class="button2" id={localStorage.getItem('currentTheme')} onClick={submitpassword}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Submit
                                        </div> */}
                {/* <button
                  className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start" onClick={passwordcancel}>

                  Cancel
                </button> */}
              </div>
            </Modal>
          </div >
        }
      </div>
    </div >

  )
}

export default connect(mapStateToProps, {
  statutorylist,
  editstatutory,
  filterdata,
  locksettings,
  GetRemarksData,
  SaveStatutorySettings,
  UpdateStatutorySettings
})(Statutorysetting);