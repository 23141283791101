import {OVERDUE_CHART,COMMON_DATA, TOGGLE_COMMON_LOADER,OVERDUE_CHART_TABLE_DATA,TIME_ZONE_DATA,SHOW_MORE_TABLE_DATA } from './../../types/index'
const initialState = {
    overdueChart: {
       chartData:[],
       time:0,
       Data:[],
       tableData:[],
       timeZoneData:[],
       showMoreData:[]
       
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case OVERDUE_CHART: {
            console.log(payload,"payload4444");
            return {
                ...state,
                overdueChart: {
                    ...state.overdueChart,
                    chartData: payload,
                    time:payload.current_date
                },
            };
        }
        case COMMON_DATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                overdueChart: {
                    ...state.overdueChart,
                    Data: payload,
                },
            };
        }

        case OVERDUE_CHART_TABLE_DATA:{
            return {
                ...state,
                overdueChart: {
                    ...state.overdueChart,
                    tableData: payload,
                },
            };
        }

        case TIME_ZONE_DATA:{
            return {
                ...state,
                overdueChart: {
                    ...state.overdueChart,
                    timeZoneData: payload,
                },
            };
        }

        case SHOW_MORE_TABLE_DATA:{
            return {
                ...state,
                overdueChart: {
                    ...state.overdueChart,
                    showMoreData: payload,
                },
            };
        }

        // case RISK_CHART_DELAYED_TABLE_DATA:{
        //     return {
        //         ...state,
        //         overdueChart: {
        //             ...state.overdueChart,
        //             delayedTableData: payload.drill_down_data,
        //         },
        //     };
        // }
        default:
            return state;
    }
}
