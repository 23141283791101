import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons';
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Progress, Tooltip, Pagination } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useRef, useEffect, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { DomainReportData, getDomainData, getExportData } from '../../../Store/Action/Report/DomainReport';
import moment from 'moment';

const mapStateToProps = (state) => ({
    allData: state.DomainReport.domainReport.data,
    tableData: state.DomainReport.domainReport.getTableData
})

const DomainReportBackup = ({
    DomainReportData,
    allData,
    tableData,
    getDomainData,
    getExportData
}) => {
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [statkey, setStatKey] = useState("0")
    const [key, setKey] = useState("1")
    const [pageState, setpageState] = useState(false);
    const [consolidateddata, setConsolidatedDate] = useState({
        country: '',
        category: "",
        user_type: "",
        legel_entity: "",
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "",
        compliance_task_status: "",
        division: "",
        domainName: '',
        countryName: '',
        unit_name: '',
        user_name: '',
        statutory_mapping: '',
        division_name: '',
        compliance_frequency_name: 'All',
        compliance_task_status_name: 'All',
        category_name: '',
        user_type_name: 'All',
        le_id: ''

    })
    const [titledata, Settitledata] = useState()
    const validator = useRef(new SimpleReactValidator());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [domainList, setDomainList] = useState([])
    const [divisionList, setDivisionList] = useState([])
    const [unitCodeLabelList, setUnitCodeLabelList] = useState([])
    const [unitLegalEntity, setUnitLegalEntity] = useState([])
    const [complianceUser, setComplianceUser] = useState([])
    const [complianceUserType, setComplianceUserType] = useState([])
    const [complianceTaskStatus, setComplianceTaskStatus] = useState([])
    const [complianceFrequencyList, setComplianceFrequencyList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [actLegalEntity, setActLegalEntity] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [actName, setActName] = useState([])
    const [actNameFilteredState, setActNameFilteredState] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    let [index, setIndex] = useState(0)
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 7, 8, 9, 10, 28, 31])
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [paginationArray, setPaginationArray] = useState([])
    const [disableButton, setDisableButton] = useState(false)
    const { Panel } = Collapse;
    const { Option } = Select;

    const onshow = () => {
        setAddFormSubmit(true)
        setFilteredArray([])
        setFilteredArrayState([])
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetDomainWiseReport",
                        {
                            "country_id": consolidateddata.country ? Number(consolidateddata.country) : sessionArr && sessionArr.length && sessionArr[0].c_id,
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidateddata.domain),
                            "division_id": Number(consolidateddata.division),
                            "category_id": Number(consolidateddata.category) ? Number(consolidateddata.category) : 0,
                            "unit_id": Number(consolidateddata.unit) ? Number(consolidateddata.unit) : 0,
                            "parent_id": Number(consolidateddata.act) ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? consolidateddata.compliance_task : null,
                            "frequency_id": Number(consolidateddata.compliance_frequency) ? Number(consolidateddata.compliance_frequency) : 0,
                            "user_type": consolidateddata.user_type ? consolidateddata.user_type : 'All',
                            "user_id": consolidateddata.user ? Number(consolidateddata.user) : 0,
                            "due_from_date": moment(consolidateddata.from_date).format('DD-MMM-YYYY'),
                            "due_to_date": moment(consolidateddata.to_date._d).format('DD-MMM-YYYY'),
                            "task_status": consolidateddata.compliance_task_status ? consolidateddata.compliance_task_status : 'All',
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": null
                        }
                    ]
                }


            ]
            getDomainData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const columns = [
        {
            title: "Compliance Task",
            ellipsis: true,
            // selector: (row) => row.compliance_task,
            width: '60px',
            dataIndex: false,
            key: false,
            render: (record) => {
                return (
                    <>
                        <div className="mb-3">
                            <Tooltip title={record.compliance_description}>
                                <p style={{ whiteSpace: 'unset' }}><InfoCircleOutlined />&nbsp;{record.compliance_task}</p>
                            </Tooltip>
                        </div>
                    </>
                )
            }
        },
        {
            title: "Frequency",
            ellipsis: true,
            width: '50px',
            align: 'center',
            dataIndex: "frequency_name",
            key: "frequency_name",
            // render: (record) => record.frequency_name
        },
        {
            title: "Due Date",
            width: '50px',
            ellipsis: true,
            dataIndex: "due_date",
            key: "due_date",
            // render: (record) => record.due_date
        },
        {
            title: "Compliance Task Status",
            width: '50px',
            ellipsis: true,
            dataIndex: "task_status",
            key: "task_status",
            // render: (record) => record.task_status
        },
        {
            title: "User Name",
            width: '50px',
            ellipsis: true,
            dataIndex: "assignee_name",
            key: false,
            // render: (record) => record.assignee_name
        },
        {
            title: "Activity Status",
            width: '50px',
            ellipsis: true,
            dataIndex: "activity_status",
            key: "activity_status",
            // render: (record) => record.activity_status
        },
        // {
        //     title: "Activity Date",
        //     width: '50px',
        //     ellipsis: true,
        //     dataIndex: "activity_date",
        //     key: "activity_date",
        //     render: (record) => record.activity_date
        // },
        {
            title: "Uploaded Document",
            width: '50px',
            ellipsis: true,
            dataIndex: "frequency_name",
            key: "frequency_name",
            // render: (record) => record.frequency_name
        },
        {
            title: "Task Completion Date/Document Issued Date",
            width: '50px',
            ellipsis: true,
            dataIndex: "frequency_name",
            key: "frequency_name",
            // render: (record) => record.frequency_name
        },



    ];

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };

    const handleOk = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payloads = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetDomainWiseReport",
                        {
                            "country_id": Number(consolidateddata.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidateddata.domain),
                            "division_id": Number(consolidateddata.division),
                            "category_id": Number(consolidateddata.category) ? Number(consolidateddata.category) : 0,
                            "unit_id": Number(consolidateddata.unit) ? Number(consolidateddata.unit) : 0,
                            "parent_id": Number(consolidateddata.act) ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? consolidateddata.compliance_task : null,
                            "frequency_id": Number(consolidateddata.compliance_frequency) ? Number(consolidateddata.compliance_frequency) : 0,
                            "user_type": consolidateddata.user_type ? consolidateddata.user_type : 'All',
                            "user_id": 0,
                            "due_from_date": "11-Oct-2022",
                            "due_to_date": "11-Jan-2023",
                            "task_status": consolidateddata.compliance_task_status ? consolidateddata.compliance_task_status : 'All',
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": null
                        }
                    ]
                }


            ]

            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetDomainWiseReport",
                        {
                            "country_id": Number(consolidateddata.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidateddata.domain),
                            "division_id": Number(consolidateddata.division),
                            "category_id": Number(consolidateddata.category) ? Number(consolidateddata.category) : 0,
                            "unit_id": Number(consolidateddata.unit) ? Number(consolidateddata.unit) : 0,
                            "parent_id": Number(consolidateddata.act) ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? consolidateddata.compliance_task : null,
                            "frequency_id": Number(consolidateddata.compliance_frequency) ? Number(consolidateddata.compliance_frequency) : 0,
                            "user_type": consolidateddata.user_type ? Number(consolidateddata.user_type) : 'All',
                            "user_id": 0,
                            "due_from_date": "11-Oct-2022",
                            "due_to_date": "11-Jan-2023",
                            "task_status": consolidateddata.compliance_task_status ? consolidateddata.compliance_task_status : 'All',
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            getExportData({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const item = [
        {
            compliance: "manoj"
        }
    ]

    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([])
        }
    }


    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (tableData && tableData.legal_entities_compliances && tableData.legal_entities_compliances.length > 0) {
            var array = tableData.legal_entities_compliances.slice((current - 1) * pageSize, (current * pageSize))
            console.log(tableData.legal_entities_compliances, 'tableData.legal_entities_compliances');
            setPaginationArray(array)
        }
    }, [tableData.legal_entities_compliances, pageSize, current])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(consolidateddata.le_id)

        }
    }, [entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setConsolidatedDate({
                ...consolidateddata,
                legel_entity: localStorage.getItem('SelectedEntityid'),
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetDomainWiseReportFilters",
                        {
                            "country_id": localStorage.getItem('SelectedEntity') === "All Legal Entity" ? Number(consolidateddata.country) : sessionArr[0].c_id,
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                DomainReportData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (allData) {
            setActLegalEntity(allData && allData.act_legal_entity)
            setCategoryList(allData && allData.categories_list)
            setComplianceFrequencyList(allData && allData.compliance_frequency_list)
            setComplianceTaskStatus(allData && allData.compliance_task_status)
            setComplianceUserType(allData && allData.compliance_user_type)
            // setComplianceUser(allData && allData.compliance_users)
            setUnitLegalEntity(allData && allData.unit_legal_entity)
            setUnitCodeLabelList(allData && allData.unit_code_label_list)
            setDivisionList(allData && allData.divisions_list)
            setDomainList(allData && allData.domains)
        }

    }, [allData])

    useEffect(() => {
        if (paginationArray && paginationArray.length > 0) {
            let uniqueObjArray = [...new Map(paginationArray && paginationArray.length && paginationArray.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(paginationArray, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([...filteredArray, tempArr])
            }
        }
    }, [paginationArray])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (paginationArray && paginationArray.length > 0) {
            const ab = paginationArray && paginationArray.length && paginationArray.map(function (item) {
                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })

            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.length && paginationArray.map((child, childIndex) => {
                        if (data === child.statutory_mapping) {
                            entityArray = {
                                "activity_date": child.activity_date,
                                "activity_status": child.activity_status,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "completion_date": child.completion_date,
                                "compliance_activity_id": child.compliance_activity_id,
                                "compliance_description": child.compliance_description,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_id": child.compliance_id,
                                "compliance_task": child.compliance_task,
                                "country_id": child.country_id,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "document_name": child.document_name,
                                "domain_id": child.domain_id,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "frequency_name": child.frequency_name,
                                "history_count": child.history_count,
                                "interim_count": child.interim_count,
                                "legal_entity_id": child.legal_entity_id,
                                "logo_url": child.logo_url,
                                "remarks_count": child.remarks_count,
                                "start_date": child.start_date,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                "url": child.url,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [paginationArray])

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const disablecondition = (e) => {
        var currentToDate = moment(consolidateddata.from_date).add(3, 'months')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-0 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Domain Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-body" id='only-restricted' style={{ position: 'relative', padding: "15px 5px" }} >

                            <Collapse  className='report-collapse' style={{backgroundColor: '#5daae3' }}accordion defaultActiveKey={["1"]} onChange={onChange}>
                                <Panel header=" Domain Report" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            placeholder="Enter Country"
                                                            onChange={(value, data) => {

                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    country: value,
                                                                    countryName: data.children,
                                                                    category: "",
                                                                    user_type: "",
                                                                    legel_entity: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    statutory_mapping: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: 'All',
                                                                    compliance_task_status_name: 'All',
                                                                    category_name: '',
                                                                    user_type_name: 'All',
                                                                    le_id: ''
                                                                })
                                                            }}
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            value={consolidateddata.countryName || undefined}
                                                        >
                                                            {country_info && country_info.length && country_info.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            consolidateddata.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{sessionArr && sessionArr.length && sessionArr[0].c_name}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b> Unit : </b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                unit: value,
                                                                unit_name: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Unit"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        value={consolidateddata.unit_name || undefined}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}
                                                    >
                                                        {unitLegalEntity && unitLegalEntity.length && unitLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {`${item.unit_code}-${item.unit_name}`}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b> User : </b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                user: value,
                                                                user_name: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter User"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.user_name || undefined}
                                                        disabled={consolidateddata.domain === '' ? true : false}

                                                    >
                                                        {complianceUser && complianceUser.length && complianceUser.map((item, i) => {
                                                            return (
                                                                <Option key={item.completed_by}>
                                                                    {item.assignee_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b> Legal Entity :  </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(data, value) => {
                                                                setCurrentEntity(data)
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    le_id: data,
                                                                    legel_entity: value.children,
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    statutory_mapping: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: 'All',
                                                                    compliance_task_status_name: 'All',
                                                                    category_name: '',
                                                                    user_type_name: 'All',
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                            value={consolidateddata.legel_entity || undefined}
                                                            disabled={consolidateddata.country == '' ? true : false}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            consolidateddata.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Act : </b> </label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                act: value,
                                                                statutory_mapping: data.children
                                                            })
                                                        }}
                                                        placeholder="Enter Act"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.statutory_mapping || undefined}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}


                                                    >
                                                        {actLegalEntity && actLegalEntity.length && actLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.statutory_mapping}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>From Date :  </b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}
                                                        value={consolidateddata.from_date ? moment(consolidateddata.from_date) : ''}
                                                        onChange={(date, dateString) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                from_date: dateString,
                                                                to_date: moment(dateString).add(3, 'months')
                                                            })
                                                        }}
                                                    // disabled={consolidateddata.legel_entity == '' ? true : false}



                                                    />
                                                </div>
                                                {validator.current.message(
                                                    'from_date',
                                                    consolidateddata.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required',
                                                        }
                                                    })}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-1'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b>  <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        onChange={(value, data) => {
                                                            const unique = _.uniqBy(allData && allData.compliance_users, function (e) {
                                                                return e.completed_by;
                                                            });
                                                            setComplianceUser(unique)
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                domain: value,
                                                                domainName: data.children,
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                division: "",
                                                                unit_name: '',
                                                                user_name: '',
                                                                statutory_mapping: '',
                                                                division_name: '',
                                                                compliance_frequency_name: 'All',
                                                                compliance_task_status_name: 'All',
                                                                category_name: '',
                                                                user_type_name: 'All',
                                                            })
                                                        }}
                                                        placeholder="Enter Domain"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.domainName || undefined}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}


                                                    >
                                                        {domainList && domainList.length && domainList.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidateddata.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task : </b> </label>
                                                <div className="form-group">
                                                    <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '2px' }}
                                                        allowClear={false}
                                                        onChange={(e) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_task: e.target.value
                                                            })
                                                        }}
                                                        value={consolidateddata.compliance_task || undefined}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}


                                                    />
                                                </div>
                                                {/* <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            compliance_task: value
                                                        })
                                                    }}
                                                    placeholder="Enter Domain Name"
                                                    style={{ width: '280px' }}
                                                >
                                                    {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_status_id}>
                                                                {item.task_status}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select> */}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date : </b>  <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}
                                                        value={consolidateddata.to_date || undefined}
                                                        onChange={(date, dateString) => {
                                                            disablecondition(dateString)
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                to_date: dateString._i
                                                            })
                                                        }}
                                                        disabledDate={(current) => {
                                                            return moment(consolidateddata.from_date).add(1, 'days') >= current ||
                                                                moment(consolidateddata.from_date).add(1, 'year') < current;
                                                        }}
                                                    // disabled={consolidateddata.legel_entity == '' ? true : false}


                                                    />
                                                </div>
                                                {validator.current.message(
                                                    'to_date',
                                                    consolidateddata.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required',
                                                        }
                                                    })}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Division : </b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                division: value,
                                                                division_name: data.children
                                                            })
                                                            let filteredCategory = _.filter(allData && allData.categories_list, { div_id: Number(value) })
                                                            setCategoryList(filteredCategory)
                                                            let filteredUnit = _.filter(allData && allData.unit_legal_entity, { division_id: Number(value) })
                                                            setUnitLegalEntity(filteredUnit)
                                                        }}
                                                        placeholder="Enter Division"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.division_name || undefined}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}


                                                    >
                                                        {divisionList && divisionList.length && divisionList.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Frequency : </b> </label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_frequency: value,
                                                                compliance_frequency_name: data.children
                                                            })
                                                        }}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}


                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.compliance_frequency_name || undefined}
                                                    >
                                                        <Option key='All'>All</Option>
                                                        {complianceFrequencyList && complianceFrequencyList.length && complianceFrequencyList.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task Status : </b> </label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_task_status: value,
                                                                compliance_task_status_name: data.children
                                                            })
                                                        }}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}


                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.compliance_task_status_name || undefined}
                                                    >
                                                        <Option key='All'>All</Option>
                                                        {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {
                                                            return (
                                                                <Option key={item.task_status}>
                                                                    {item.task_status}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Category : </b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                category: value,
                                                                category_name: data.children
                                                            })
                                                        }}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}
                                                        placeholder="Enter Category"
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.category_name || undefined}
                                                    >
                                                        {categoryList && categoryList.length && categoryList.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b> User Type : </b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                user_type: value,
                                                                user_type_name: data.children
                                                            })
                                                        }}
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.user_type_name || undefined}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}


                                                    >
                                                        <Option key='All'>All</Option>
                                                        {complianceUserType && complianceUserType.length && complianceUserType.map((item, i) => {
                                                            return (
                                                                <Option key={item.user_type_id}>
                                                                    {item.user_type}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4' style={{ marginLeft: '37%' }}>
                                                <Button type="primary" disabled={disableButton} shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "23%", marginRight: "10px", marginTop: '5px' }}
                                                    onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852", marginTop: '5px' }}
                                                    onClick={showModal}
                                                >
                                                    Export
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                </Panel>
                            </Collapse>
                            {tableshow == true ?
                                <Collapse className='report-collapse' id='only-restricted' defaultActiveKey={["1"]} onChange={onStatChange} style={{ marginTop: '1%',backgroundColor: '#5daae3' }} >
                                    <Panel header="Overall Statistics" key={statkey}>
                                        <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                            <div className="row m-2">
                                                <div className="col-md-3">
                                                    <Progress type="circle" percent={30} width={90} format={(percent) => `${140}`} />
                                                    <h4>Complied</h4>
                                                </div>
                                                <div className="col-md-3">
                                                    <Progress type="circle" percent={20} width={90} status="exception" format={(percent) => `${50}`} />
                                                    <h4>Delayed Complied</h4>

                                                </div>
                                                <div className="col-md-3">
                                                    <Progress type="circle" percent={100} width={90} format={(percent) => `${500}`} />
                                                    <h4>Inprogress</h4>

                                                </div>
                                                <div className="col-md-3">
                                                    <Progress type="circle" percent={80} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${180}`} />
                                                    <h4>Not Complied</h4>

                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse> : ''}
                            {tableshow == true ?
                                <>
                                    <div className='col-md-12 mt-2 mb-2' >
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{marginLeft:'15%'}}><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidateddata.legel_entity : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{marginLeft:'30%'}}><b>Country :</b> {sessionArr && sessionArr[0].c_name}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain :</b> {consolidateddata.domainName}</label>
                                            </div>


                                        </div>
                                    </div>
                                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content userprivclass1'>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className="ant-table-thead antable-head" >
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: '10px' }}>#</th>
                                                                <th className='ant-table-cell' style={{ width: '200px' }}>Compliance Task</th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}>Frequency</th>
                                                                <th className='ant-table-cell' style={{ width: '300px' }}><center>Due Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '140px' }}>Compliance Task Status</th>
                                                                <th className='ant-table-cell' style={{ width: '300px' }}><center>User Name</center></th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>Activity Status</center></th>
                                                                <th className='ant-table-cell' style={{ width: '140px' }}><center>Activity Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '150px' }}><center>Uploaded Document</center></th>
                                                                <th className='ant-table-cell' style={{ width: '250px' }}><center>Task Completion Date/Document issued Date</center></th>
                                                            </tr>
                                                        </thead>
                                                        {filteredArrayState && filteredArrayState.length > 0 ?
                                                            filteredArrayState.map((item, i) => {
                                                                return (
                                                                    <tbody className='ant-table-tbody'>
                                                                        <tr className="bg-lightdark">
                                                                            <td className='report-td' colSpan="100%">
                                                                                <table className='white' style={{ tableLayout: 'auto' }}>
                                                                                    <tbody className='ant-table-tbody'>
                                                                                        <div className="col-md-12">
                                                                                            <div className='row m-2'>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Unit :</b> {item[0].unit_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Category :</b> {item[0].category_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Division :</b> {item[0].division_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div className='col-md-3' >
                                                                                                    </div> */}
                                                                                        </div>
                                                                                        {/* <div className='row m-2'>
                                                                                            <div className='col-md-4' >
                                                                                                
                                                                                            </div>
                                                                                            <div className='col-md-4 ' >
                                                                                               
                                                                                            </div>
                                                                                            <div className='col-md-4' >
                                                                                            </div>
                                                                                          
                                                                                        </div> */}
                                                                                        {/* <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className='ant-table-cell w6'><b>Unit :</b></td>
                                                                                            <td className='ant-table-cell w15' title={item[0].unit_name}>{item[0].unit_name}</td>
                                                                                            <td className='ant-table-cell w6'><b>Category :</b></td>
                                                                                            <td className='ant-table-cell w40'><span className="category-name">{item[0].category_name}</span></td>
                                                                                        </tr>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className='ant-table-cell w6'><b>Division :</b></td>
                                                                                            <td className='ant-table-cell w15' >{item[0].division_name}</td>
                                                                                            <td className='ant-table-cell w6'><b>Time Zone :</b></td>
                                                                                            <td className='ant-table-cell w40'><span className="category-name">{item[0].tz_name}</span></td>
                                                                                        </tr> */}
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                        {titledata && titledata.map((itemdata, i) => {
                                                                            let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                            if (data != undefined) {
                                                                                if (data.statutory_mapping == itemdata.parent) {
                                                                                    let list = itemdata.child
                                                                                    let size = 25
                                                                                    const items = list.slice(0, size)
                                                                                    return <Fragment>

                                                                                        <tr className='ant-table-row ant-table-row-level-0'>

                                                                                            <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                        </tr>

                                                                                        {itemdata.child.map((items) => {
                                                                                            if (item[0].unit_id == items.unit_id) {
                                                                                                return (
                                                                                                    <><tr>
                                                                                                        <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                        <td className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                            <ExclamationCircleTwoTone />
                                                                                                        </Tooltip> &nbsp;{items.compliance_task}</td>
                                                                                                        <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                        <td className='ant-table-cell'>{items.task_status}</td>
                                                                                                        <td className='ant-table-cell'>{items.assignee_name}</td>
                                                                                                        <td className='ant-table-cell'>{items.activity_status}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.activity_date}</center></td>
                                                                                                        <td className='ant-table-cell'>{items.document_name}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                                    </tr>
                                                                                                        <span hidden>{index = index + 1}</span>
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                        })

                                                                                        }
                                                                                    </Fragment>
                                                                                }
                                                                            }
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                )
                                                            }) :
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td style={{ paddingTop: '5%', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '5%' }}>No Records Found</td>

                                                            </tr>
                                                        }
                                                    </table>
                                                    {/* <br /> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {index != 0 ?
                                        <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                            <label style={{ marginLeft: "28px" }}>Showing {pageSize * (current - 1) + 1} to {index} of {tableData.total_count} entries</label>
                                        </div> : ''}

                                    <div
                                        // className="btn btn-light-success rounded-pill px-4 text-success"
                                        style={{ position: 'absolute', bottom: '-25px', right: 0 }}
                                    // onClick={submitrec}
                                    >
                                        <Pagination
                                            current={current}
                                            showSizeChanger={true}
                                            // showQuickJumper={true}
                                            onShowSizeChange={() => {
                                                setCurrent(1)
                                                // setpageState(true)
                                            }}
                                            pageSizeOptions={[25, 50, 100]} hideOnSinglePage={tableData.total_count > 25 ? false : true} defaultPageSize={25}
                                            onChange={(page, pageSizes) => {
                                                setpageState(true)
                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                setPageSize(pageSizes)
                                            }} total={tableData.total_count}
                                        // showTotal={(total) => `Showing 1 to ${index} of ${tableData.total_count} entries`}
                                        />
                                    </div>


                                </>
                                : false}
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Select Fields" visible={isModalOpen} onOk={handleOk} footer={null} onCancel={handleCancel} width={100}>
                <div className='row'>
                    <div className='col-lg-3'><input id="select_all" width="100%" type="checkbox" autocomplete="off"
                        checked={checkboxValue.length === 33 ? true : false}
                        onClick={(e) => {
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                        }} /> Select All</div>

                    <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" disabled="disabled" value='1' checked onChange={(e) => (e) => checkBoxOnChange(e)} /> Unit Code</div>
                    <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" disabled="disabled" value='2' checked onChange={(e) => checkBoxOnChange(e)} /> Unit Name</div>
                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='7' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /> Location</div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='7' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /> Location </div>
                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" disabled="disabled" checked value='8' onChange={(e) => checkBoxOnChange(e)} /> Act / Rules </div>
                    <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" disabled="disabled" checked value='9' onChange={(e) => checkBoxOnChange(e)} /> Compliance Task </div>
                    <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" disabled="disabled" checked value='10' onChange={(e) => checkBoxOnChange(e)} /> Frequency </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" disabled="disabled" value='28' checked onChange={(e) => checkBoxOnChange(e)} /> Statutory Status </div>
                    <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='31' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /> Remarks </div>
                    <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='3' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 1 </div>
                    <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2 </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 3 </div>
                    <div className='col-lg-3'><input id="column_6" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 4 </div>
                    <div className='col-lg-3'><input id="column_11" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /> Periodicity </div>
                    <div className='col-lg-3'><input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Division </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Category </div>
                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Assigned by </div>
                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Assigned To </div>
                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date </div>

                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assignee </div>
                    <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Completed on </div>
                    <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Concurrer </div>
                    <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Concurred on </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Approver </div>
                    <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Approved on </div>
                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Start Date </div>
                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Due Date </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_25" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Extend Due Date </div>
                    <div className='col-lg-3'><input id="column_26" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date / Document Issued Date </div>
                    <div className='col-lg-3'><input id="column_27" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Month </div>
                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /> Validity Date </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-lg-3'><input id="column_30" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /> Duration </div>
                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number </div>
                </div>
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '40%' }}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                </div>
            </Modal>
        </div>

    )
}

export default connect(mapStateToProps, {
    DomainReportData,
    getDomainData,
    getExportData
})(DomainReportBackup);