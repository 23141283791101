import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaulttoast = message => {
    toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}

const errorToaster = message => {
    toast.error(message)
}
const successToaster = message => {
    toast.success(message)
}
const warningToaster = message => {
    toast.warning(message)
}
const infoToaster = message => {
    toast.info(message)
}


export const Toaster = {
    success: successToaster,
    error: errorToaster,
    warning: warningToaster,
    info: infoToaster,
    default:defaulttoast
}