import { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Input, Button, Tooltip } from 'antd';
import SimpleReactValidator from "simple-react-validator";
import { Updatepassword } from './../../src/Store/Action/Profile';
import { useLocation, Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { ExclamationCircleTwoTone } from '@ant-design/icons';


const mapStateToProps = () => ({
    Updatepassword
});

const ChangePassword = ({

}) => {
    const { Search } = Input;
    const location = useLocation();
    const validator = useRef(new SimpleReactValidator());
    const formvalidator = useRef(new SimpleReactValidator());
    const [password, setPassword] = useState({
        current_password: '',
        new_password: '',
        confirm_password: ''
    })
    const [formdata, Setformdata] = useState({
        form_url: '',
        form_key: ''
    });
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const paramid = location.pathname;
    // const [Entity, setEntity] = useState("All Legal Entity")
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    useEffect(() => {
        validator.current.showMessages()
        formvalidator.current.showMessages()
    }, [])

    const SubmitPassword = () => {
        setAddFormSubmit1(true);
        if (formvalidator.current.allValid()) {
            if (password.new_password === password.confirm_password) {
                Updatepassword({
                    payload: [
                        authtoken,
                        [
                            "ChangePassword",
                            {
                                "session_token": "undefined-" + authtoken,
                                "current_password": password.current_password,
                                "new_password": password.new_password,
                                "confirm_password": password.confirm_password
                            }
                        ]
                    ],
                    paramid: paramid,
                    formkey: formdata.form_key
                })
            } else {
                toast.error("New password and Confirn password Should Match")
            }

        }
    }

    const homescreen = ()=>{
        window.location.href= '/dashboard'
    }

    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-1">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>My Accounts</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large' }}>
                                            <Link>Change Password</Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                            </div>
                        </div>
                    </div>
                    <div className="page-titles  pb-2 pt-1">
                        {/* <Card> */}
                        <div className="container-fluid pt-1">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card" >
                                        <div className="card-body" >
                                            <div className="col-md-12">
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label><b>Current Password</b><span style={{ color: "red" }}>*</span></label>&nbsp;
                                                            <Tooltip title={<div>"The password is at least 8 characters long 
                                                                The password has at least one uppercase letter 
                                                                The password has at least one lowercase letter 
                                                                The password has at least one digit 
                                                                The password has at least one special character"</div>} >
                            <ExclamationCircleTwoTone />
                        </Tooltip> 
                                                            <input placeholder="Enter Current Password"
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    setPassword({
                                                                        ...password,
                                                                        current_password: e.target.value
                                                                    })
                                                                }} />
                                                            {formvalidator.current.message(
                                                                'Password',
                                                                password.current_password,
                                                                ['required', { regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        regex: 'Enter Strong Password'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-4'>
                                                        </div>
                                                        <div className='col-md-6'>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label><b>New Password</b><span style={{ color: "red" }}>*</span></label>
                                                            <input onmouseenter="hjdsbfdjsfb" placeholder="Enter New Password" style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    setPassword({
                                                                        ...password,
                                                                        new_password: e.target.value
                                                                    })
                                                                }} />
                                                            {formvalidator.current.message(
                                                                'Password',
                                                                password.new_password,
                                                                ['required', { regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        regex: 'Enter Strong Password'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label><b>Confirm Password</b><span style={{ color: "red" }}>*</span></label>
                                                            <input placeholder="Enter Confirm Password" style={{ width: '100%' }} onChange={(e) => {
                                                                setPassword({
                                                                    ...password,
                                                                    confirm_password: e.target.value
                                                                })
                                                            }} />
                                                            {formvalidator.current.message(
                                                                'Password',
                                                                password.confirm_password,
                                                                ['required', { regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        regex: 'Enter Strong Password'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-6'>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><br />
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4'>
                                                        {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px", width: "100px" }} onClick={SubmitPassword}
                                                        >
                                                            Submit
                                                        </Button>

                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </Card> */}
                    </div>
                </div >

            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    Updatepassword
})(ChangePassword);