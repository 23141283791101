import React from 'react'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Popover, Drawer, Button, Table, Tooltip, Modal, Descriptions, Empty, Space } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ArrowRightOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, ArrowLeftOutlined, PlayCircleOutlined, BookTwoTone, SearchOutlined, FilterFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getCommonData, getOverduechart, getOverDueChartTableData, getTimeZoneData, getOverDueShowMoreTableData } from '../../Store/Action/Dashboard/Overdue';
import { toast } from 'react-toastify';
import moment from 'moment';

// import "core-js/stable";
// import "regenerator-runtime/runtime";

const mapStateToProps = (state) => ({
    data: state.Overdue.overdueChart.chartData,
    time: state.Overdue.overdueChart.time,
    commonData: state.Overdue.overdueChart.Data,
    tableData: state.Overdue.overdueChart.tableData,
    timeZoneData: state.Overdue.overdueChart.timeZoneData,
    showMoreData: state.Overdue.overdueChart.showMoreData

    // tableData1: state.RiskChart.riskChart.delayedTableData,

})

const OverdueChart = ({
    // getFilterValue,
    getOverduechart,
    getCommonData,
    data,
    commonData,
    getOverDueChartTableData,
    // getRiskChartDelayedTableData,
    time,
    getTimeZoneData,
    // getCriticalityTableData,
    tableData,
    timeZoneData,
    getOverDueShowMoreTableData,
    showMoreData
    // tableData1
}) => {
    var [count, setCount] = useState(1)
    const _ = require("lodash");
    const [open, setOpen] = useState(false)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [chartId, setChartId] = useState()
    const [table, setTable] = useState(false)
    const chart = useRef(null);
    const { Option } = Select;
    const { Panel } = Collapse;
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const [entityData, setEntityData] = useState([])
    const [showmoreCount, setShowMoreCount] = useState(0)
    const [dataCount, setDataCount] = useState()
    const [criticalityTable, setCriticalityTable] = useState([])
    const [criticalityValue, setCriticalityValue] = useState()
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const location = useLocation();
    // const paramid = location.pathname;
    const [paramid, setParamId] = useState("")
    const [filterData, setFilterData] = useState({
        country: [],
        domain: [],
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const [filterDataNames, setFilterDataNames] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],

    })
    const [legalEntityArray, setLegalEntityArray] = useState([])
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [unit, setUnit] = useState([])
    const [hiddenRiskData, setHiddenRiskData] = useState([])
    const [exposedRiskData, setExposedRiskData] = useState([])
    const [chartValue, setChartValue] = useState('')
    const [generatedOn, setGeneratedOn] = useState(false)
    const [fieldType, setFieldType] = useState("")
    const [go, setGo] = useState(false)
    const [domainName, setDomainName] = useState()
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const [countValue, setCountValue] = useState(0)
    const [compliedType, setCompliedType] = useState()
    const [indexValue, setIndexValue] = useState([])
    let [counts, setcounts] = useState(0)

    const { OptGroup } = Select;
    const [domainListData, setDomainListData] = useState([])
    const [divisionListData, setDivisionListData] = useState([])
    const [categoryListData, setCategoryListData] = useState([])
    const [unitListData, setUnitListData] = useState([])
    const [bgIds, setBgIds] = useState([])
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [checkBox, setCheckBox] = useState(true)
    const [countryDomain, setCountryDomain] = useState([])
    const [dIds, setDIds] = useState([])
    const [year, setYear] = useState(moment().year())
    const [columnState, setCoulmnState] = useState(false)
    const [type, setType] = useState('')
    const [legalEntity, setLegalEntity] = useState([])
    console.log(compliedType, 'compliedType');
    console.log(legalEntity, 'legalEntity');

    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            the_arr.pop();
            let a = the_arr.join('/')
            setParamId(a)
        }

    }, []);


    // useEffect(() => {
    //     if (data) {
    //         setHiddenRiskData(data.risk_chart_data_nu)
    //         setExposedRiskData(data.risk_chart_data_dn)
    //     }
    // }, [data])



    console.log(filterData.domain, 'filterData.domain ');

    console.log(country_info, 'country_info');

    console.log(field, 'field');
    console.log(commonData, 'commonData');

    // useEffect(() => {
    //     if(field.country == ''){
    //         setfield({
    //             ...field,
    //             country:country_info
    //         })
    //     }
    // },[field.country])

    useEffect(() => {
        if (commonData) {
            let unique = []
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                unique = _.uniqBy(commonData && commonData.bg_groups, function (e) {
                    return e.bg_id;
                });
            }
            setfield({
                ...field,
                domain: commonData && commonData.d_info,
                legalEntity: commonData && commonData.le_did_infos,
                country: commonData && commonData.countries,
                business: unique
            })
            setLegalEntity(commonData && commonData.le_did_infos)
            let uniqueDiv = []
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                uniqueDiv = _.uniqBy(commonData && commonData.div_infos, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)
            setCategory(commonData && commonData.cat_info)
            setUnit(commonData && commonData.chart_units)
        }

    }, [isAuth, entityid, commonData])

    console.log(unit,'unit');

    console.log(field, 'field');

    useEffect(() => {
        if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
            const payload = [
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetNotCompliedChart",
                            {
                                "c_ids": [
                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                ],
                                "d_ids": dIds,
                                "filter_type": "Group",
                                "filter_ids": [
                                    1
                                ],
                                "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
                                "country_domains": countryDomain,
                                "chart_year": year
                            }
                        ]
                    }
                ]
            ]
            if (paramid != "") {
                console.log(paramid,"paramid33333333333");
                getOverduechart({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [entityData, commonData, dIds, countryDomain, year])




    useEffect(() => {
        if (commonData) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotCompliedChart",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": dIds,
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain,
                            "chart_year": year
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getOverduechart({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid, commonData, dIds, countryDomain, year])

    console.log(sessionArr, 'commonDatacommonData');
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
            let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

                return item.le_id
            })
            setEntityData(id)

        }
    }, [])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityid])


    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            setDomainName(domainNameArray)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    console.log(commonData, 'aisVig');
    console.log(data, 'data12345');

    useEffect(() => {
        if (data) {
            if (table == false) {
                am4core.useTheme(am4themes_animated);
                var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
                // Create a container
                var container = am4core.create("container", am4core.Container);
                container.width = am4core.percent(100);
                container.height = am4core.percent(100);
                container.layout = "vertical";
                container.background.fill = am4core.color("#000");
                container.background.fillOpacity = 0.1;
                container.background.stroke = am4core.color("#000");
                container.background.strokeOpacity = 0.2;
                container.background.strokeWidth = 2;


                // Function that creates and adds a chart
                function addChart(data) {

                    // Create chart instance
                    var chart = container.createChild(am4charts.PieChart3D);

                    // Add data
                    chart.data = data;

                    chart.innerRadius = am4core.percent(30);
                    function isZero(x) { return x == 0; }
                    // Add and configure Series
                    var pieSeries = chart.series.push(new am4charts.PieSeries3D());
                    pieSeries.dataFields.value = "litres";
                    pieSeries.dataFields.category = "country";
                    pieSeries.slices.template.propertyFields.fill = "color";
                    chart.current = chart;
                    chart.legend = new am4charts.Legend();
                    pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total}`;



                    // pieSeries.slices.template.stroke = am4core.color("#fff");
                    // pieSeries.slices.template.strokeWidth = 2;
                    // pieSeries.slices.template.strokeOpacity = 1;

                    chart.legend = new am4charts.Legend();
                    chart.legend.useDefaultMarker = true;
                    chart.legend.valueLabels.template.disabled = true;
                    var marker = chart.legend.markers.template.children.getIndex(0);
                    marker.cornerRadius(12, 12, 12, 12);
                    marker.strokeWidth = 2;
                    marker.strokeOpacity = 1;
                    marker.stroke = am4core.color("#ccc");


                    pieSeries.tooltip.label.adapter.add("text", function (text, target) {
                        if (target.dataItem && target.dataItem.value == 0) {
                            return "";
                        }
                        else {
                            return text;
                        }
                    });

                    // This creates initial animation
                    pieSeries.hiddenState.properties.opacity = 1;
                    pieSeries.hiddenState.properties.endAngle = -90;
                    pieSeries.hiddenState.properties.startAngle = -90;

                    pieSeries.slices.template.events.on("hit", function (ev) {
                        setShowMoreCount(0)
                        setDataCount(ev.target.dataItem._dataContext.litres)
                        setCriticalityValue(ev.target.dataItem.category)
                        setCountValue(0)
                        setCompliedType((ev.target.dataItem.category === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.category === '0-30 days' ? 'Below 30' : (ev.target.dataItem.category === '31-60 days' ? 'Below 60' : (ev.target.dataItem.category === '61-90 days' ? 'Below 90' : '')))))
                        setTable(true)
                        setChartValue('exposedRisk')
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetNotCompliedDrillDown",
                                    {
                                        "d_ids":
                                            filterData && filterData.domain ? filterData.domain : dIds
                                        ,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "le_ids":
                                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain,
                                        "not_complied_type": (ev.target.dataItem.category === 'Above 90 days' ? 'Above 90' : (ev.target.dataItem.category === '0-30 days' ? 'Below 30' : (ev.target.dataItem.category === '31-60 days' ? 'Below 60' : (ev.target.dataItem.category === '61-90 days' ? 'Below 90' : '')))),
                                        "record_count": 0,
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getOverDueChartTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }


                    })

                    chart.exporting.menu = new am4core.ExportMenu();
                    chart.exporting.menu.items = [{
                        "label": "...",
                        "menu": [
                            {
                                "label": "Image",
                                "menu": [
                                    { "type": "png", "label": "PNG" },
                                    { "type": "jpg", "label": "JPG" },
                                    { "type": "svg", "label": "SVG" },
                                    { "type": "pdf", "label": "PDF" },
                                    { "type": "gif", "label": "GIF" }
                                ]
                            }, {
                                "label": "Data",
                                "menu": [
                                    { "type": "csv", "label": "CSV" },
                                    { "type": "xlsx", "label": "XLSX" },
                                ]
                            }, {
                                "label": "Print", "type": "print"
                            }
                        ]
                    }];
                    chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                    var title = chart.titles.create();
                    title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                    title.disabled = false;
                    title.align = 'left'
                    title.marginTop = '2%'
                    title.marginLeft = '2%'

                    // fieldType == "LegalEntity" ? filterData.legalentity :
                    // fieldType == 'Division' ? Number(filterData.division) :
                    //     fieldType == 'Category' ? Number(filterData.category) :
                    //         fieldType == 'Unit' ? Number(filterData.unit) 

                    var title1 = chart.titles.create();
                    title1.text =
                        ((fieldType == "LegalEntity" && go == true && type === '') ? `Over due Compliance of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
                            (fieldType == "Division" && go == true && type === '') ? `Over due Compliance of Division [bold]${filterDataNames.division}[/]` : (
                                (fieldType == "Category" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.category}[/]` : (
                                    (fieldType == "BusinessGroup" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.business}[/]` : (
                                        (fieldType == "Unit" && go == true && type === '') ? `Over due Compliance of Unit [bold]${filterDataNames.unit}[/]` : `Over due Compliance of ${commonData && commonData.g_name}`
                                    )))
                        ))
                    title1.disabled = false;
                    title1.align = 'center'
                    title1.marginTop = '5%'
                    title1.marginLeft = '2%'
                    // title1.fontWeight = 'bold'

                    // <b><p>Country-Domain</p></b>
                    // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                    var label = chart.createChild(am4core.Label);
                    label.text = "Country-Domain";
                    label.fontSize = 14;
                    label.align = "left";
                    label.marginLeft = '2%';
                    label.marginBottom = '2%'
                    label.fontWeight = 'bold'

                    var label1 = chart.createChild(am4core.Label);
                    label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                    // label1.text = ['hiii','hello']
                    label1.fontSize = 14;
                    label1.align = "left";
                    label1.marginLeft = '2%';
                    label1.marginBottom = '5%'

                    // var title2 = chart.titles.create();
                    // title2.text = 'Country-Domain'
                    // title2.disabled = false;
                    // title2.align = 'bottom'
                    // title2.marginTop = '2%'
                    // title2.marginLeft = '2%'

                    // title2.fontWeight = 'bold'

                    // Enable title on export
                    // chart.exporting.events.on("exportstarted", function (ev) {
                    //     title.disabled = false;
                    //     title.parent.invalidate();
                    // });

                    // // Disable title when export finishes
                    // chart.exporting.events.on("exportfinished", function (ev) {
                    //     title.disabled = true;
                    // });
                }

                // Add charts
                addChart([
                    (data && data.T_0_to_30_days_count > 0 ?
                        {
                            "country": "0-30 days",
                            "litres": data && data.T_0_to_30_days_count,
                            "color": am4core.color("#ff9c80")
                        } : {}
                    ),
                    (data && data.T_31_to_60_days_count > 0 ?
                        {
                            "country": "31-60 days",
                            "litres": data && data.T_31_to_60_days_count,
                            "color": am4core.color("#f2746b")
                        } : {}),
                    (data && data.T_61_to_90_days_count > 0 ?
                        {
                            "country": "61-90 days",
                            "litres": data && data.T_61_to_90_days_count,
                            "color": am4core.color("#ff6054")
                        } : {}),
                    (data && data.Above_90_days_count > 0 ?
                        {
                            "country": "Above 90 days",
                            "litres": data && data.Above_90_days_count,
                            "color": am4core.color("#fe4365")
                        }
                        : {})
                ]);

            }
        }
    }, [data, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    console.log(data, 'data');

    useEffect(() => {
        if (tableData) {
            setCriticalityTable(tableData.n_drill_down_data)
        }
    }, [tableData])

    useEffect(() => {
        let temp = []
        if (criticalityTable && criticalityTable.length > 0) {
            criticalityTable.map((item, i) => {
                temp.push(`${i}`)
            }
            )
            setIndexValue(temp)
        }
    }, [criticalityTable])

    useEffect(() => {
        console.log(criticalityTable, 'tableData');
        console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data5555');
        if (showMoreData && showMoreData.n_drill_down_data && showMoreData.n_drill_down_data.length > 0) {
            let temp = []
            let temp2 = []
            for (let i in criticalityTable) {
                for (let j in showMoreData.n_drill_down_data) {
                    if (criticalityTable[i].u_name == showMoreData.n_drill_down_data[j].u_name) {
                        for (let s in criticalityTable[i].drill_compliances) {
                            for (let a in showMoreData.n_drill_down_data[j].drill_compliances) {
                                console.log(s, 'taskDistributionTable[i].ap_compliances[s]');
                                if (s == a) {
                                    let arr1 = criticalityTable[i].drill_compliances[s]
                                    let arr2 = showMoreData.n_drill_down_data[j].drill_compliances[a]
                                    let arr3 = arr1.concat(arr2)
                                    console.log(arr3, 'arr3');
                                    criticalityTable[i].drill_compliances[s] = arr3
                                    console.log(s, 'sneha');
                                }
                                else {
                                    criticalityTable[i].drill_compliances[a] = showMoreData.n_drill_down_data[j].drill_compliances[a]
                                }
                            }
                        }


                        temp2.push(criticalityTable[i].u_name)

                    }

                }
            }
            console.log(temp2, 'temp2');
            for (let i in showMoreData.n_drill_down_data) {

                if ((temp2.includes(showMoreData.n_drill_down_data[i].u_name)) == false) {

                    temp.push(showMoreData.n_drill_down_data[i])
                }



            }
            setCriticalityTable([...criticalityTable, ...temp])



        }

    }, [showMoreData])

    useEffect(() => {
        if (criticalityTable && criticalityTable.length > 0) {
            let count = []
            for (let i in criticalityTable) {
                for (let j in criticalityTable[i].drill_compliances) {
                    let a = criticalityTable[i].drill_compliances[j]
                    let data = Object.values(a)


                    count.push(data.length)

                    // count.push(data[i])
                    console.log(data, ' taskDistributionTable[i].ap_compliances');

                }
            }
            console.log(count, 'count');
            let countValues = count.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setShowMoreCount(countValues)
            console.log(countValues, 'countValue9999');
        }

    }, [criticalityTable])

    useEffect(() => {
        if (type === 'Group')
            setGroupCheckBox(checkBox)
    }, [type, checkBox])

    //Use effects for filter

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
            let arrunit = [];
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-domain');
                if (country_info && country_info[0].c_id == subunitlist.c_id) {
                    entityArrayunit = {

                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'is_active': subunitlist.is_active,
                        'month_from': subunitlist.month_from,
                        'month_to': subunitlist.month_to,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setDomainListData(countrylisttempdataunit)
        }
    }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if (commonData) {
            let temp = []
            for (let i in commonData.d_info) {
                temp.push(commonData.d_info[i].d_id)
            }
            setFilterData({
                ...filterData,
                country: `${country_info[0].c_id}`,
                domain: temp
            })
        }
    }, [commonData])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            let arrunit = [];
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-domain');
                if (country_info && country_info[0].c_id == subunitlist.c_id) {
                    entityArrayunit = {

                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'is_active': subunitlist.is_active,
                        'month_from': subunitlist.month_from,
                        'month_to': subunitlist.month_to,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setDomainListData(countrylisttempdataunit)
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
                let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(commonData && commonData.bg_groups[0].bg_id) })
                console.log(filteredDivision, 'filteredDivision');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)
                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)
                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)

                }
            }
            else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                console.log('hello ais');
                let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: commonData.le_did_infos[0].le_id })
                console.log(filteredDivision, 'filteredDivision555');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)

                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)

                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)
                }
            }
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            console.log('hiii');
            if ((commonData && commonData.bg_groups && commonData.bg_groups.length === 0) && (legalEntityArray && legalEntityArray.length > 0)) {
                console.log('hello');
                if (unit) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    unit && unit.length > 0 && unit.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-lee');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'address': subunitlist.address,
                                'business_group_id': subunitlist.business_group_id,
                                'category_id': subunitlist.category_id,
                                'country_id': subunitlist.country_id,
                                'd_ids': subunitlist.d_ids,
                                'division_id': subunitlist.division_id,
                                'le_id': subunitlist.le_id,
                                'postal_code': subunitlist.postal_code,
                                'u_id': subunitlist.u_id,
                                'u_name': subunitlist.u_name,
                                'label': subunitlist.u_name,
                                'values': subunitlist.u_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunitleee');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setUnitListData(countrylisttempdataunit)
                }
                if (division) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    division && division.length > 0 && division.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');
    
                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
    
                            entityArrayunit = {
    
                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.div_name,
                                'values': subunitlist.div_id
    
                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }
    
                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }
    
                    console.log(totalListunit, 'totalListunit');
    
                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setDivisionListData(countrylisttempdataunit)
                }
                if (category) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    category && category.length > 0 && category.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-category');
                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {
    
                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'cat_id': subunitlist.cat_id,
                                'cat_name': subunitlist.cat_name,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.cat_name,
                                'values': subunitlist.cat_id
    
                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunit');
                        }
    
                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                    setCategoryListData(countrylisttempdataunit)
                }
            }
        }

    }, [commonData, legalEntityArray, unit, division, category, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

   
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && bgIds.length > 0 && commonData) {
            if (bgIds.includes(null) === true) {
                console.log('snehaaa');
                setfield({
                    ...field,
                    legalEntity: commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos,
                })
                setLegalEntity(commonData && commonData.le_did_infos.length)

            }
            else {
                let filteredLegalEntity = _.filter(commonData.le_did_infos, { bg_id: Number(commonData.bg_groups[0].bg_id) })
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                })
                setLegalEntity(filteredLegalEntity)
            }

        }
    }, [commonData, bgIds, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredCategory, 'filteredCategory111');
                let temp = []
                temp.push({
                    'bg_id': filteredCategory[0].bg_id,
                    'c_id': filteredCategory[0].c_id,
                    'cat_id': filteredCategory[0].cat_id,
                    'cat_name': filteredCategory[0].cat_name,
                    'd_id': filteredCategory[0].d_id,
                    'div_id': filteredCategory[0].div_id,
                    'le_id': filteredCategory[0].le_id,
                    'div_name': commonData && commonData.div_infos[0].div_name
                })
                setCategory(temp)
                let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredUnit, 'filteredUnit123');
                setUnit(filteredUnit)
            }
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity && filteredLegalEntity[0].bg_id })
                console.log(filteredBusinessGroup, 'filteredBusinessGroup');
                const unique = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                console.log(unique, 'unique');
                setfield({
                    ...field,
                    business: unique,
                    legalEntity: filteredLegalEntity
                })
                setLegalEntity(filteredLegalEntity)
            }

        }
    }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if ((commonData) && (division && division.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            division && division.length > 0 && division.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');

                if (commonData && commonData.le_did_infos[0].le_id == subunitlist.le_id) {

                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.div_name,
                        'values': subunitlist.div_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            console.log(totalListunit, 'totalListunit');

            countrylisttempdataunit.push(totalListunit)
            console.log(countrylisttempdataunit, 'countrylisttempdataunit');
            setDivisionListData(countrylisttempdataunit)
        }
    }, [commonData, division, field.legalEntity])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            if (field.legalEntity && field.legalEntity.length > 0) {
                if (division) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    division && division.length > 0 && division.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');

                        if (field && field.legalEntity[0].le_id == subunitlist.le_id) {

                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.div_name,
                                'values': subunitlist.div_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunit');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setDivisionListData(countrylisttempdataunit)
                }
                if (category) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    category && category.length > 0 && category.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-category');
                        if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'cat_id': subunitlist.cat_id,
                                'cat_name': subunitlist.cat_name,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.cat_name,
                                'values': subunitlist.cat_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunit');
                        }

                    })
                    totalListunit = {
                        'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                        'options': tempArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                    setCategoryListData(countrylisttempdataunit)
                }
                if (unit) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    unit && unit.length > 0 && unit.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-unit');
                        if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                            console.log(field.legalEntity, 'field.legalEntity');
                            entityArrayunit = {

                                'address': subunitlist.address,
                                'business_group_id': subunitlist.business_group_id,
                                'category_id': subunitlist.category_id,
                                'country_id': subunitlist.country_id,
                                'd_ids': subunitlist.d_ids,
                                'division_id': subunitlist.division_id,
                                'le_id': subunitlist.le_id,
                                'postal_code': subunitlist.postal_code,
                                'u_id': subunitlist.u_id,
                                'u_name': subunitlist.u_name,
                                'label': subunitlist.u_name,
                                'values': subunitlist.u_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunit');
                        }

                    })
                    totalListunit = {
                        'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                        'options': tempArrayunit
                    }

                    countrylisttempdataunit.push(totalListunit)
                    setUnitListData(countrylisttempdataunit)
                }
            }
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity', field.legalEntity, division, category, unit])

   
    useEffect(() => {
        if ((commonData) && (category && category.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            category && category.length > 0 && category.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-category');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }
            countrylisttempdataunit.push(totalListunit)
            setCategoryListData(countrylisttempdataunit)
        }

    }, [commonData, category && category.length > 0])

    useEffect(() => {
        if ((commonData) && (unit && unit.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            unit && unit.length > 0 && unit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-unit');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'address': subunitlist.address,
                        'business_group_id': subunitlist.business_group_id,
                        'category_id': subunitlist.category_id,
                        'country_id': subunitlist.country_id,
                        'd_ids': subunitlist.d_ids,
                        'division_id': subunitlist.division_id,
                        'le_id': subunitlist.le_id,
                        'postal_code': subunitlist.postal_code,
                        'u_id': subunitlist.u_id,
                        'u_name': subunitlist.u_name,
                        'label': subunitlist.u_name,
                        'values': subunitlist.u_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setUnitListData(countrylisttempdataunit)
        }
    }, [commonData, unit && unit.length > 0])

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
            }
            console.log(tempIds, 'tempIds');
            setDIds(tempIds)
            setCountryDomain(tempArr)
            console.log(tempArr, 'tempArr');
        }

    }, [commonData])

    const HandelChange = (obj) => {
        console.log(obj, 'objj');
        setFilterData({
            ...filterData,
            domain: obj
        })
        setType('')
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            domain: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
        let temp = []
        let bg = []
        let tempDiv = []
        let tempCat = []
        let tempUnit = []
        for (let i in obj) {
            console.log(obj[i], 'obj[i');
            console.log(i, 'i');
            if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity[0].bg_id })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            } else {
                let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(obj[i])))
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(obj[i]) })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            }
            let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(obj[i]) })
            let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
            let temp1 = []
            temp1.push({
                'bg_id': filteredCategory[0].bg_id,
                'c_id': filteredCategory[0].c_id,
                'cat_id': filteredCategory[0].cat_id,
                'cat_name': filteredCategory[0].cat_name,
                'd_id': filteredCategory[0].d_id,
                'div_id': filteredCategory[0].div_id,
                'le_id': filteredCategory[0].le_id,
                'div_name': division[0].div_name
            })
            tempCat = temp1
            if (filteredDivision && filteredDivision.length > 0) {
                let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                setUnit(filteredUnit)
            }
            else {
                let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData.le_did_infos[0].le_id })
                console.log(filteredUnit, 'filteredUnit555');
                setUnit(filteredUnit)
            }
            // tempUnit = filteredUnit
            tempDiv = filteredDivision
            let uniqueDiv = []
            if (filteredDivision && filteredDivision.length > 0) {
                uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)

            setCategory(temp1)
            // setUnit(filteredUnit)

        }
        console.log(tempCat, 'tempCat');
        console.log(bg, 'Aishuuuu');
        console.log(temp, 'SnehaaaaaaaaAsss');

    }

    const showmore = () => {
        setCountValue(countValue + 100)

        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetNotCompliedDrillDown",
                    {

                        "not_complied_type": compliedType,
                        "record_count": countValue + 100,
                        "d_ids":
                            filterData && filterData.domain ? filterData.domain : dIds
                        ,
                        "filter_type": fieldType != "" ? fieldType : "Group",
                        "filter_ids": [
                            fieldType == "LegalEntity" ? filterData.legalentity :
                                fieldType == 'Division' ? Number(filterData.division) :
                                    fieldType == 'Category' ? Number(filterData.category) :
                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                            1
                        ],
                        "le_ids":
                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        ,
                        "country_domains": countryDomain,
                    }
                ]
            }
        ]
        if (paramid != "") {
            getOverDueShowMoreTableData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onClose = () => {
        setOpen(false);
    };

    const filterChange = () => {
        setGeneratedOn(false)
        setGo(true)
        if (groupCheckBox === true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetNotCompliedChart",
                        {
                            "c_ids": [
                                filterData && filterData.country ? Number(filterData && filterData.country) : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids":
                                filterData && filterData.domain ? filterData.domain : dIds
                            ,
                            "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                            "filter_ids": [
                                (fieldType == "LegalEntity" && type === '') ? filterData.legalentity :
                                    (fieldType == 'Division' && type === '') ? Number(filterData.division) :
                                        (fieldType == 'Category' && type === '') ? Number(filterData.category) :
                                            (fieldType == 'Unit' && type === '') ? Number(filterData.unit) :
                                                (fieldType == 'BusinessGroup' && type === '') ? Number(filterData.business) :
                                                    (fieldType != "" && type === 'Group') ? 1 :
                                                        1
                            ],
                            "le_ids":
                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                            ,
                            "country_domains": countryDomain,
                        }
                    ]
                }
            ]

            if (paramid != "") {
                getOverduechart({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
        else {
            toast.error('Please select the filter')
        }
        setOpen(false);
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder='Search'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    var myColTitleStyle = {
        textOverflow: 'ellipsis',
        // overflow: "hidden",
        whiteSpace: 'nowrap'
    };

    console.log(count, 'countcount');

    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 6,
            align: 'center'
        },
        ...((fieldType === 'LegalEntity' && go === true) || (fieldType === 'Category' && go === true) || (fieldType === 'Unit' && go === true) ?
            [] :
            [{
                title: "Business Group",
                ...getColumnSearchProps('bg_name'),
                dataIndex: 'bg_name',
                key: 'bg_name',
                ellipsis: false,
                width: 18,
                filteredValue: filteredInfo.bg_name || null,
            },
            {
                title: 'Legal Entity',
                ...getColumnSearchProps('le_name'),
                dataIndex: false,
                key: 'le_name',
                ellipse: false,
                width: 15,
                filteredValue: filteredInfo.le_name || null,
                render: (record) => {
                    return <p title={record.le_name} className='cut-text'>
                        {record.le_name}</p>
                }
            }
            ]
        ),
        {
            title: 'Division',
            ...getColumnSearchProps('div_name'),
            dataIndex: false,
            key: 'div_name',
            width: 14,
            ellipsis: false,
            filteredValue: filteredInfo.div_name || null, 
            render: (record) => {
                return <p title={record.div_name} className='cut-text'>
                    {record.div_name}</p>
            }

        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: false,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.div_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>{record.comp_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: 20,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 15,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name'),
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 15,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Over Due",
            ...getColumnSearchProps('ageing'),
            dataIndex: 'ageing',
            key: 'ageing',
            ellipsis: false,
            render: (row, record) => {
                return (
                    <>
                        <p>{record.ageing}</p>
                    </>
                )
            },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ];

    const columns1 = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 7,
            align: 'center'
        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name'),
            dataIndex:false,
            key: 'comp_name',
            ellipse: true,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.comp_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>{record.comp_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: 25,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name'),
            dataIndex:false,
            key: 'assignee_name',
            ellipsis: false,
            render: (record) => {
                return <p title={record.assignee_name} className='cut-text'>
                    {record.assignee_name}</p>
            },
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 13,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Over Due",
            ...getColumnSearchProps('ageing'),
            dataIndex: 'ageing',
            key: 'ageing',
            ellipsis: false,
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ]



    console.log(criticalityTable, 'cricTable');
    console.log(data, 'data');
    console.log(timeZoneData, 'timeZoneData');
    // console.log(tableData1, 'tableData1');

    // return (
    //     <div className="page-wrapper">
    //         <div className="page-titles pb-2 pt-2">
    //             <div className="col-md-12">
    //                 <div className="row">
    //                     <div className="col-md-8">
    //                         <div className="col-lg-8 col-md-6 col-12 align-self-center">
    //                             <nav aria-label="breadcrumb">
    //                                 <ol className="breadcrumb mb-0 d-flex align-items-center">
    //                                     <li className="breadcrumb-item">
    //                                         <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
    //                                     </li>
    //                                     <li className="breadcrumb-item active" aria-current="page">
    //                                         <Link>Dashboard</Link>
    //                                     </li>
    //                                     <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
    //                                         <Link>Compliance Trend</Link>
    //                                     </li>
    //                                 </ol>
    //                             </nav>

    //                         </div>
    //                     </div>
    //                     {table == false ?
    //                         <>
    //                             <div className="col-md-2">

    //                             </div>
    //                             <div className="col-md-2">
    //                                 <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '40%' }} onClick={() => {
    //                                     setOpen(true)
    //                                 }}
    //                                     size='default' >
    //                                     Filter
    //                                 </Button>
    //                             </div>
    //                         </> : ''
    //                     }

    //                 </div>
    //             </div>
    //         </div>
    //         {
    //             table == true ?
    //                 <div className="container-fluid pt-1">
    //                     {/* <div className='col-md-12'>
    //                         <div className='row'>
    //                             <div className="col-md-4">
    //                                 <p><b>Compliance Trend of {commonData && commonData.g_name}</b></p>
    //                             </div>
    //                             <div className="col-md-4">

    //                             </div>
    //                             <div className="col-md-4">
    //                                 <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F", marginLeft: '70%' }}
    //                                     icon={<ArrowLeftOutlined />} size='default' onClick={() => {
    //                                         setTable(false)
    //                                     }}>
    //                                     Back
    //                                 </Button>
    //                             </div>
    //                         </div>
    //                     </div><br /> */}
    //                     <div className="col-md-12">
    //                         <div className="row">
    //                             <div className="col-md-4">

    //                             </div>
    //                             <div className="col-md-4">

    //                             </div>
    //                             <div className="col-md-4">
    //                                 <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F", marginLeft: '70%' }}
    //                                     icon={<ArrowLeftOutlined />} size='default' onClick={() => {
    //                                         setTable(false)
    //                                     }}>
    //                                     Back
    //                                 </Button>
    //                             </div>
    //                         </div>
    //                     </div><br />
    //                     < CollapsedefaultActiveKey={['0']}>
    //                         {criticalityTable && criticalityTable.length > 0 &&
    //                             criticalityTable.map((item) => {
    //                                 let unitName = item.drill_compliances
    //                                 let keys = Object.keys(unitName);
    //                                 return (
    //                                     <Panel header={`${item.le_name}-${item.u_name}`}>
    //                                         {keys && keys.length > 0 && keys.map((item, i) => {
    //                                             let values = Object.values(unitName);
    //                                             let childDatas = values
    //                                             console.log(childDatas, 'childDatas');
    //                                             return (
    //                                                 <>
    //                                                     <h6 className='m-2 ml-2 mb-2'>{keys[i]}</h6>
    //                                                     <Table
    //                                                         columns={columns}
    //                                                         dataSource={childDatas[i]}
    //                                                         bordered
    //                                                         scroll={{ x: 1500 }}
    //                                                         pagination={false}
    //                                                         key={searchText.index}
    //                                                     />
    //                                                 </>
    //                                             )
    //                                         })}

    //                                     </Panel>
    //                                 )
    //                             })
    //                         }
    //                     </Collapse>
    //                     <div className='col-md-12 mt-2'>
    //                         <div className='row'>
    //                             <div className='col-md-4'></div>
    //                             <div className='col-md-4'>
    //                                 <button className="btn btn-success" style={{ float: "center", marginLeft: "50px" }}
    //                                     onClick={showmore}
    //                                 >Show More</button>
    //                             </div>
    //                             <div className='col-md-4'></div>
    //                         </div>
    //                     </div>
    //                     {/* <Table dataSource={dataSource} columns={columns} pagination={false}
    //         className='userprivclass' /> */}
    //                 </div> :
    //                 <div className="container-fluid pt-1">
    //                     <div className="row">
    //                         {(data.Above_90_days_count > 0) || (data.T_0_to_30_days_count > 0) || (data.T_31_to_60_days_count > 0) || (data.T_61_to_90_days_count > 0) ?
    //                             <>
    //                                 <div className="col-md-12 mt-3">

    //                                     {/* <div className="card">
    //                                     <div className='card-body'> */}

    //                                     <>
    //                                         <div id="container" style={{ width: "100%", height: "450px" }}></div>
    //                                         <div className="col-md-12" style={{ marginTop: '2%' }}>
    //                                             <div className="row">
    //                                                 <div className="col-md-6">

    //                                                 </div>
    //                                                 <div className="col-md-6" style={{ marginLeft: '70%' }}>
    //                                                     <label htmlFor=""><b>Time Zone :</b></label>
    //                                                     <Select
    //                                                         allowClear={true}
    //                                                         size="default"
    //                                                         onChange={(data) => {
    //                                                             setTimeZone(data)
    //                                                             setGeneratedOn(true)
    //                                                             const payload = [
    //                                                                 authtoken,
    //                                                                 {
    //                                                                     "session_token": authtoken,
    //                                                                     "request": [
    //                                                                         "GetDateTime_ByZone",
    //                                                                         {
    //                                                                             "tz_name": data
    //                                                                         }
    //                                                                     ]
    //                                                                 }
    //                                                             ]
    //                                                             getTimeZoneData({
    //                                                                 payload: payload,
    //                                                                 paramid: paramid
    //                                                             })

    //                                                         }}
    //                                                         placeholder="Enter Time Zone"
    //                                                         style={{ marginLeft: '5%' }}
    //                                                     // value={compfie.user_Group}

    //                                                     >

    //                                                         {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

    //                                                             return (

    //                                                                 <Option key={item.tz_name}>

    //                                                                     {item.tz_name}

    //                                                                 </Option>

    //                                                             );

    //                                                         })}
    //                                                         {/* );
    //                                         })} */}
    //                                                     </Select>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </>
    //                                     {/* </div>
    //                                 </div> */}
    //                                 </div>
    //                             </>
    //                             : <p style={{ marginTop: '18%', marginLeft: '40%', marginBottom: '18%' }}>No Data to Display </p>}
    //                         {/* <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-2">
    //                                     <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "25px" }}
    //                                         icon={<ArrowLeftOutlined />} size='default'
    //                                         onClick={() => {
    //                                             setYear(year - 1)
    //                                         }}
    //                                     >
    //                                         Previous Year
    //                                     </Button>
    //                                 </div>
    //                                 <div className="col-md-8"></div>
    //                                 <div className="col-md-2">
    //                                     {year != moment().year() ?
    //                                         <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginRight: "25px" }}
    //                                             size='default'
    //                                             onClick={() => {
    //                                                 setYear(year + 1)
    //                                             }}
    //                                         >
    //                                             Next Year<ArrowRightOutlined />
    //                                         </Button>
    //                                         : false}
    //                                 </div>
    //                             </div>
    //                         </div> */}
    //                     </div>

    //                     {/* } */}
    //                 </div>
    //         }


    //         <Drawer title="FILTER BY" placement="right" onClose={onClose} visible={open}>
    //             <div className="col-md-12">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label><b>Country</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Country"
    //                             onChange={(data, values) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     country: data
    //                                 })
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     country: values.children
    //                                 })
    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {field && field.country && field.country.length && field.country.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.c_id}>
    //                                         {item.c_name}
    //                                     </Option>
    //                                 );
    //                             })}

    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label><b>Domain</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Domain"
    //                             onChange={(data, values) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     domain: data
    //                                 })
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     domain: values.children
    //                                 })
    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {field && field.domain && field.domain.length && field.domain.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.d_id}>
    //                                         {item.d_name}
    //                                     </Option>
    //                                 );
    //                             })}

    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-1">
    //                         <input type='checkbox'></input>
    //                     </div>
    //                     <div className="col-md-4">
    //                         <label><b>Group</b></label>
    //                     </div>
    //                     {/* <div className="col-md-2">

    //                 </div> */}
    //                     <div className="col-md-1">
    //                         {/* <input type='checkbox' onChange={(e) => {
    //                         if (e.target.value) {
    //                             setFilterData({
    //                                 ...filterData,
    //                                 chartType: 'Consolidated'
    //                             })
    //                         }
    //                     }}></input> */}
    //                     </div>
    //                     <div className="col-md-4">
    //                         {/* <label><b>Consolidated</b></label> */}
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-6">

    //                         <label><b>From Date</b></label>
    //                         <DatePicker
    //                             disabled={true}
    //                             allowClear={() => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     from_date: null,
    //                                 })
    //                             }}
    //                             // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
    //                             // value={consolidatedData.from_date}
    //                             // disabled={consolidatedData.domainy == '' ? true : false}
    //                             onChange={(date, dateString) => {

    //                                 console.log(dateString, 'dateString');
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     from_date: dateString,
    //                                 })

    //                             }}

    //                         />

    //                     </div>
    //                     <div className="col-md-6">

    //                         <label><b>To Date</b></label>

    //                         <DatePicker
    //                             // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
    //                             // value={consolidatedData.from_date}
    //                             // disabled={consolidatedData.domainy == '' ? true : false}
    //                             // onChange={(date, dateString) => {

    //                             //     console.log(dateString, 'dateString');
    //                             //     setConsolidatedData({
    //                             //         ...consolidatedData,
    //                             //         from_date: dateString,
    //                             //         to_date: moment(dateString).add(3, 'months')
    //                             //     })

    //                             // }}
    //                             disabled={true}
    //                             onChange={(date, dateString) => {

    //                                 console.log(dateString, 'dateString');
    //                                 if (dateString != undefined) {
    //                                     setFilterData({
    //                                         ...filterData,
    //                                         to_date: dateString,
    //                                     })
    //                                 }
    //                                 else {
    //                                     setFilterData({
    //                                         ...filterData,
    //                                         to_date: null,
    //                                     })
    //                                 }


    //                             }}

    //                         />

    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label><b>Business Group</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Business Group"
    //                             onChange={(data, values) => {
    //                                 let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
    //                                 setfield({
    //                                     ...field,
    //                                     legalEntity: filteredEntity
    //                                 })
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     business: data
    //                                 })
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     business: values.children
    //                                 })
    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {field && field.business && field.business.length && field.business.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.bg_id}>
    //                                         {item.bg_name}
    //                                     </Option>
    //                                 );
    //                             })}


    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label style={{ color: fieldType == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Legal Entity"
    //                             onChange={(data, values) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     legalentity: data
    //                                 })
    //                                 setFieldType('LegalEntity')
    //                                 setGo(false)
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     legalentity: values.children
    //                                 })

    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >

    //                             {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.le_id}>
    //                                         {item.le_name}
    //                                     </Option>
    //                                 );
    //                             })}

    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label style={{ color: fieldType == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Division"
    //                             onChange={(data, values) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     division: data
    //                                 })
    //                                 let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
    //                                 console.log(subData, 'subDatasubData');
    //                                 setCategory(subData)
    //                                 setFieldType('Division')
    //                                 setGo(false)
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     division: values.children
    //                                 })

    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {division && division.length && division.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.div_id}>
    //                                         {item.div_name}
    //                                     </Option>
    //                                 );
    //                             })}


    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label style={{ color: fieldType == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Category"
    //                             onChange={(data) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     category: data
    //                                 })
    //                                 let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
    //                                 setUnit(subData)
    //                                 setFieldType('Category')
    //                                 setGo(false)

    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {category && category.length && category.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.cat_id}>
    //                                         {item.cat_name}
    //                                     </Option>
    //                                 );
    //                             })}


    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-12 mt-3">
    //                 <div className="row">
    //                     <div className="col-md-4">
    //                         <label style={{ color: fieldType == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
    //                     </div>
    //                     <div className="col-md-8">
    //                         <Select
    //                             allowClear={true}
    //                             size="default"
    //                             placeholder="Enter Unit"
    //                             onChange={(data, values) => {
    //                                 setFilterData({
    //                                     ...filterData,
    //                                     unit: data
    //                                 })
    //                                 setFieldType('Unit')
    //                                 setGo(false)
    //                                 setFilterDataNames({
    //                                     ...filterDataNames,
    //                                     unit: values.children
    //                                 })

    //                             }
    //                             }
    //                             style={{ width: '100%' }}
    //                         >
    //                             {unit && unit.length > 0 && unit.map((item, i) => {
    //                                 return (
    //                                     <Option key={item.u_id}>
    //                                         {item.u_name}
    //                                     </Option>
    //                                 );
    //                             })}


    //                         </Select>
    //                     </div>
    //                 </div>
    //             </div>
    //             <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
    //                 onClick={filterChange}
    //                 size='default' >
    //                 Go
    //             </Button>
    //             {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
    //                 onClick={() => {
    //                     const payload = [
    //                         authtoken,
    //                         {
    //                             "session_token": authtoken,
    //                             "request":
    //                                 ["GetTskDistributionStatusChart",
    //                                     {
    //                                         "c_ids": [
    //                                             commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                         ],
    //                                         "d_ids": [
    //                                             commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                         ],
    //                                         "filter_type": "Group",
    //                                         "filter_ids": [
    //                                             1
    //                                         ],
    //                                         "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
    //                                             commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
    //                                         ],
    //                                         "country_domains": [
    //                                             {
    //                                                 "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                                 "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                                 "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                                 "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                             }
    //                                         ],
    //                                         "chart_year": year
    //                                     }
    //                                 ]
    //                         }
    //                     ]
    //                     getTaskDistributionchart({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                     setOpen(false)

    //                     setFieldType("")
    //                     setFilterData({
    //                         ...filterData,
    //                         country: '',
    //                         domain: '',
    //                         legalentity: '',
    //                         division: '',
    //                         unit: '',
    //                         business: '',
    //                         category: '',
    //                         unit: ''
    //                     })
    //                 }}
    //                 size='default' >
    //                 Reset
    //             </Button> */}
    //         </Drawer>

    //     </div>

    console.log(criticalityTable, 'criticalityTable');

    return (
        <div>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                <span style={{ color: '#1890ff' }}>Dashboard</span>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                                <span style={{ color: '#1890ff' }}>Over Due Chart</span>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            {table == false ?
                                <div className="col-md-2" style={{ marginTop: '1%' }}>
                                    <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '50%' }} onClick={() => {
                                        setOpen(true)
                                    }}
                                        size='default' >
                                        <Tooltip title='Filter'>Filter</Tooltip>
                                    </Button>
                                </div> : ''}
                        </div>
                    </div>
                </div>
                {
                    table == true ?
                        <div className="container-fluid pt-1">
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className="col-md-4">
                                        {/* <p><b>{`${commonData && commonData.g_name} - ${criticalityValue} Compliance`}</b></p> */}
                                        <p style={{ marginTop: '2%', fontSize: '16px' }}><b style={{ marginTop: '2%', fontSize: '16px' }}>{`Over Due Compliance Of ${commonData && commonData.g_name}`}</b></p>
                                    </div>
                                    <div className="col-md-4">

                                    </div>
                                    <div className="col-md-4">
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginLeft: '70%' }}
                                            icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                                setTable(false)
                                            }}>
                                            <Tooltip title='Back'>Back</Tooltip>
                                        </Button>
                                    </div>
                                </div>
                            </div><br />
                            <Collapse defaultActiveKey={['0']}>
                                {criticalityTable && criticalityTable.length > 0 &&
                                    criticalityTable.map((items) => {
                                        let unitName = items.drill_compliances
                                        let keys = Object.keys(unitName);
                                        return (
                                            <Panel header={`${items.le_name}-${items.u_name}`}>
                                                {keys && keys.length > 0 && keys.map((item, i) => {
                                                    let values = Object.values(unitName);
                                                    console.log(values[i], 'values');
                                                    let childDatas = values
                                                    let tempArr = []
                                                    if (childDatas && childDatas.length > 0) {
                                                        for (let m in childDatas[i]) {
                                                            console.log(m, 'inndexxx');
                                                            console.log(childDatas[i][m].comp_name, 'childDatas');
                                                            tempArr.push({
                                                                'bg_name': items.bg_name,
                                                                'le_name': items.le_name,
                                                                'legal_entity_id': items.legal_entity_id,
                                                                'div_name': items.div_name,
                                                                'comp_name': childDatas && childDatas[i][m].comp_name,
                                                                'criticality': childDatas && childDatas[i][m].criticality,
                                                                'assignee_name': childDatas && childDatas[i][m].assignee_name,
                                                                'descp': childDatas && childDatas[i][m].descp,
                                                                'compfielaw_url': childDatas && childDatas[i][m].compfielaw_url,
                                                                'ageing': childDatas && childDatas[i][m].ageing,
                                                                'index': count

                                                            })
                                                            { <span hidden>{count = count + 1}</span> }
                                                            // childDatas[i] = []
                                                        }
                                                        console.log(tempArr, 'tempArr');
                                                    }
                                                    childDatas[i] = tempArr


                                                    return (
                                                        <>
                                                            <h6 className='m-2 ml-2 mb-2'>{keys[i]}</h6>

                                                            <Table
                                                                size={'small'}
                                                                columns={columnState === true ? columns1 : columns}
                                                                dataSource={childDatas[i]}
                                                                bordered
                                                                scroll={{ x: 1000 }}
                                                                pagination={false}
                                                                onChange={handleChange}

                                                            />
                                                        </>
                                                    )
                                                })}

                                            </Panel>
                                        )
                                    })
                                }
                            </Collapse>
                            {dataCount == showmoreCount ? false :
                                <div className='col-md-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-md-4'></div>
                                        <div className='col-md-4'>
                                            <button className="btn btn-success" style={{ float: "center", marginLeft: "50px" }}
                                                onClick={showmore}
                                            >Show more records</button>
                                        </div>
                                        <div className='col-md-4'></div>
                                    </div>
                                </div>
                            }

                            {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                    className='userprivclass' /> */}
                        </div> :
                        <div className="container-fluid pt-1">
                            <div className="row" >
                                <div className="col-md-12">

                                </div>
                                {(data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0)) ?

                                    <p style={{ marginTop: '18%', marginLeft: '35%', marginBottom: '18%' }}>No Data to Display </p> :
                                    <>
                                        <div className="col-md-12 mt-3">
                                            <>
                                                <div id="container" style={{ width: "100%", marginTop: '10px', height: "400px" }}></div>
                                                <div className="col-md-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-3">
                                                        </div>
                                                        <div className="col-md-6" style={{ marginLeft: '70%' }}>
                                                            <label htmlFor=""><b>Time Zone :</b></label>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                onChange={(data) => {
                                                                    setTimeZone(data)
                                                                    setGeneratedOn(true)
                                                                    const payload = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetDateTime_ByZone",
                                                                                {
                                                                                    "tz_name": data
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    getTimeZoneData({
                                                                        payload: payload,
                                                                        paramid: paramid
                                                                    })

                                                                }}
                                                                placeholder="Enter Time Zone"
                                                                style={{ marginLeft: '5%' }}
                                                            // value={compfie.user_Group}

                                                            >

                                                                {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                    return (

                                                                        <Option key={item.tz_name}>

                                                                            {item.tz_name}

                                                                        </Option>

                                                                    );

                                                                })}
                                                                {/* );
                                                })} */}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        </div>

                                    </>}
                            </div>
                        </div>
                }

<Drawer title="FILTER BY" placement="right" closable={false} onClose={false} visible={open}
                    extra={
                        <Space>
                            <CloseCircleOutlined style={{ marginTop: '2%', fontSize: '140%' }} onClick={onClose} />
                        </Space>
                    }
                >
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Country</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Country"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.country ? filterData.country : undefined}
                                    onChange={(data, values) => {
                                        setFilterData({
                                            ...filterData,
                                            country: data,
                                            domain: ''
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            country: values.children,
                                            domain: '',
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            business: '',
                                            category: '',
                                            unit: ''
                                        })
                                        setType('')

                                    }
                                    }
                                    onDeselect={(data) => {

                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {country_info && country_info.length > 0 && country_info.map((item, i) => {
                                        return (
                                            <Option key={item.c_id}>
                                                {item.c_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Domain</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.domain ? filterData.domain : undefined}
                                    // onChange={(data, values) => {
                                    //     console.log(data,'data-domain');
                                    //     let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(data) })
                                    //     let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(data) })
                                    //     // let filteredCategory = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     // let filteredUnit = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(data)))
                                    //     let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
                                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                    //     let temp = []
                                    //     temp.push({
                                    //         'bg_id': filteredCategory[0].bg_id,
                                    //         'c_id': filteredCategory[0].c_id,
                                    //         'cat_id': filteredCategory[0].cat_id,
                                    //         'cat_name': filteredCategory[0].cat_name,
                                    //         'd_id': filteredCategory[0].d_id,
                                    //         'div_id': filteredCategory[0].div_id,
                                    //         'le_id': filteredCategory[0].le_id,
                                    //         'div_name': division[0].div_name
                                    //     })
                                    //     setCategory(temp)
                                    //     setUnit(filteredUnit)
                                    //     setfield({
                                    //         ...field,
                                    //         legalEntity: filteredLegalEntity,
                                    //         business: filteredBusinessGroup
                                    //     })
                                    //     let temp1=[]
                                    //     for(let i in commonData.d_info){
                                    //         temp1.push(commonData.d_info[i].d_id)
                                    //     }
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         domain: temp1
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         domain: values.children,
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setDivision(filteredDivision)
                                    //     // let temp = []
                                    //     // temp.push({
                                    //     //     'bg_id': filteredCategory[0].bg_id,
                                    //     //     'c_id': filteredCategory[0].c_id,
                                    //     //     'cat_id': filteredCategory[0].cat_id,
                                    //     //     'cat_name': filteredCategory[0].cat_name,
                                    //     //     'd_id': filteredCategory[0].d_id,
                                    //     //     'div_id': filteredCategory[0].div_id,
                                    //     //     'le_id': filteredCategory[0].le_id,
                                    //     //     'div_name': filteredDivision[0].div_name
                                    //     // })
                                    //     // setCategory(temp)
                                    //     // setUnit(filteredUnit)
                                    // }
                                    // }
                                    onChange={(option) => HandelChange(option)}
                                    // onDeselect={(data) => {
                                    //     console.log(data,'data-deselect');
                                    //     filterData && filterData.domain.splice(data,1)
                                    // }}
                                    style={{ width: '100%' }}
                                    disabled={filterData.country == '' ? true : false}
                                >
                                    {domainListData && domainListData.length > 0 &&
                                        domainListData.map((finalList_unitlist) => (
                                            <OptGroup label={finalList_unitlist.label}>
                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                ))}
                                            </OptGroup>
                                        ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-1">
                                <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                    setType('Group')
                                    setCheckBox(!checkBox)
                                }}></input>
                            </div>
                            <div className="col-md-4">
                                <label><b>Group</b></label>
                            </div>
                            {/* <div className="col-md-2">

                        </div> */}
                            <div className="col-md-1">
                                {/* <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input> */}
                            </div>
                            <div className="col-md-4">
                                {/* <label><b>Consolidated</b></label> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-6">

                                <label><b>From Date</b></label>
                                <DatePicker
                                    disabled={true}
                                    allowClear={() => {
                                        setFilterData({
                                            ...filterData,
                                            from_date: null,
                                        })
                                    }}
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        setFilterData({
                                            ...filterData,
                                            from_date: dateString,
                                        })

                                    }}

                                />

                            </div>
                            <div className="col-md-6">

                                <label><b>To Date</b></label>

                                <DatePicker
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    // onChange={(date, dateString) => {

                                    //     console.log(dateString, 'dateString');
                                    //     setConsolidatedData({
                                    //         ...consolidatedData,
                                    //         from_date: dateString,
                                    //         to_date: moment(dateString).add(3, 'months')
                                    //     })

                                    // }}
                                    disabled={true}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        if (dateString != undefined) {
                                            setFilterData({
                                                ...filterData,
                                                to_date: dateString,
                                            })
                                        }
                                        else {
                                            setFilterData({
                                                ...filterData,
                                                to_date: null,
                                            })
                                        }


                                    }}

                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    value={filterDataNames.business ? filterDataNames.business : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { bg_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        setfield({
                                            ...field,
                                            legalEntity: filteredEntity
                                        })
                                        setFieldType('BusinessGroup')
                                        setFilterData({
                                            ...filterData,
                                            business: data,
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            business: values.children,
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        setUnit(filteredUnit)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}
                                >
                                    {field && field.business && field.business.length && field.business.map((item, i) => {
                                        return (
                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>
                                        );
                                    })}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    value={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    placeholder="Enter Legal Entity"
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: data })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { le_id: data })
                                        let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        setLegalEntityArray(filteredLegalEntity)
                                        // setfield({
                                        //     ...field,
                                        //     legalEntity: filteredLegalEntity
                                        // })
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                            setUnit(filteredUnit)
                                        }
                                        else {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: data })
                                            console.log(filteredUnit, 'filteredUnit555');
                                            setUnit(filteredUnit)
                                        }

                                        console.log(filteredDivision, 'filteredDivisionLeg');
                                        setFilterData({
                                            ...filterData,
                                            legalentity: data
                                        })
                                        setFieldType('LegalEntity')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            legalentity: values.children,
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        // setUnit(filteredUnit)
                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                        return (
                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    value={filterDataNames.division ? filterDataNames.division : undefined}
                                    onChange={(data, values) => {
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.find(commonData && commonData.div_infos, { div_id: Number(data) })
                                        let getLEname = _.find(commonData && commonData.le_did_infos, { le_id: filteredDivision.le_id })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(data) })
                                        setFilterData({
                                            ...filterData,
                                            division: data
                                        })

                                        // let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        // setDivisionObj(filteredDivision)
                                        // setLEObj(getLEname)
                                        // setCategory(subData)
                                        setFieldType('Division')
                                        setCoulmnState(true)
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            division: values.children,
                                            unit: '',
                                            category: '',
                                        })
                                        setUnit(filteredUnit)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision.div_name
                                        })
                                        setCategory(temp)

                                        console.log(category, 'catStateeeee');

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {divisionListData && divisionListData.length > 0 &&
                                        divisionListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.div_id}>{subUnitData.div_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }

                                        }
                                        )}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    value={filterDataNames.category ? filterDataNames.category : undefined}
                                    onChange={(data, values) => {
                                        setCoulmnState(true)
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            category: data,
                                            unit: '',
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            category: values.children
                                        })
                                        let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                        setUnit(subData)
                                        setFieldType('Category')
                                        setGo(false)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {categoryListData && categoryListData.length > 0 &&
                                        categoryListData.map((finalList_unitlist) => {
                                            console.log(finalList_unitlist, 'finalList_unitlist');
                                            if (finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldType == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Unit"
                                    value={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    onChange={(data, values) => {
                                        setCoulmnState(true)
                                        setType('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            unit: data
                                        })
                                        setFieldType('Unit')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            unit: values.children
                                        })

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {unitListData && unitListData.length > 0 &&
                                        unitListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '15%', marginTop: '10%' }}
                        onClick={filterChange}
                        size='default' >
                        Go
                    </Button>
                    {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                        onClick={() => {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusChart",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "filter_type": "Group",
                                                "filter_ids": [
                                                    1
                                                ],
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                    commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                ],
                                                "country_domains": [
                                                    {
                                                        "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                    }
                                                ],
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getCriticalitychart({
                                payload: payload,
                                paramid: paramid
                            })
                            setOpen(false)

                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                country: '',
                                domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: ''
                            })
                        }}
                        size='default' >
                        Reset
                    </Button> */}
                </Drawer>

            </div>
        </div>

    )
    // )


}

export default connect(mapStateToProps, {
    getOverduechart,
    getCommonData,
    getOverDueChartTableData,
    getTimeZoneData,
    getOverDueShowMoreTableData
    // getRiskChartDelayedTableData
})(OverdueChart);