import { GET_DOMAIN_DATA,GET_LE_TABLE_DATA } from '../../types/index'

const initialState = {
    legalEntityReport: {
        domain_data: [],
        getTableData:[],
        exportData:[]
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_DOMAIN_DATA: {
            console.log("HIIIIII");
            console.log(payload, "payloadred123");
            return {
                ...state,
                legalEntityReport: {
                    ...state.legalEntityReport,
                    domain_data:payload
                },
            };
        }

        case GET_LE_TABLE_DATA: {
            console.log("HIIIIII");
            console.log(payload, "legalEntityReport");
            return {
                ...state,
                legalEntityReport: {
                    ...state.legalEntityReport,
                    getTableData:payload
                },
            };
        }

        // case EXPORT_DATA: {
        //     console.log("HIIIIII");
        //     console.log(payload, "payloadred");
        //     return {
        //         ...state,
        //         domainReport: {
        //             ...state.domainReport,
        //             exportData:payload
        //         },
        //     };
        // }
        default:
            return state;
    }
}
