import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import { Modal, Input, Table, Button, Space, Tooltip, Select, InputNumber, DatePicker } from 'antd';
import { certificatelist, certificateadd, certificateupdate, updatestatus } from './../../Store/Action/Master/CV_Certificate_Label';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import { PlayCircleOutlined, PlusOutlined, StopOutlined, SearchOutlined, FilterFilled, PlusCircleOutlined } from '@ant-design/icons';




const mapStateToProps = (state) => ({
    lists: state.CertificateLabel.list,
    filterrecall: state.recallfilters.recallfilters
})
const CertificateLabel = ({
    certificatelist,
    lists,
    certificateadd,
    certificateupdate,
    updatestatus,
    updateblock,
    updateprovider,
    GetRemarksData,
    filterrecall: { recallremarks },
}) => {
    const { TextArea } = Input;
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const location = useLocation();
    const paramid = location.pathname;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [modalVisible, SetmodalVisible] = useState(false);
    const [passvisible, Setpassvisible] = useState(false);
    const [blockvisible, Setblockvisible] = useState(false);
    const [remarksvalue, Setremarksvalue] = useState();
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const { Search } = Input;
    const [remarksModal, setRemarksModal] = useState(false);
    const [remarks, setRemarks] = useState('')
    const [page, setPage] = useState(1);


    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        active_status: ''
    })

    const [blockdata, Setblockdata] = useState({
        "sp_id": '',
        "is_blocked": '',
        "remarks": '',
        "password": ''
    })

    const modalcancel = () => {
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: '',
        })
        setAddFormSubmit(false);
        SetmodalVisible(false);
    }

    const addopen = () => {
        SetmodalVisible(true);
    }

    const passwordcancel = () => {
        setAddFormSubmit1(false)
        Setpassvisible(false);
        Setpassword({
            ...passwordata,
            passvalue: ""

        })
    }

    const blockcancel = () => {
        Setblockvisible(false);
        Setblockdata({
            ...blockdata,
            password: '',
            remarks: ''

        })
        setAddFormSubmit2(false);

    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText({
        //     ...searchText,
        //     data: selectedKeys[0]
        // });
        // setSearchedColumn(dataIndex);
    };
    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div>
                <Input
                    ref={searchInput}
                    placeholder={'Search '+ placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterFilled
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const onInputChange = e => {
        const { value } = e.target;


        const re = /^[A-Z0-9a-z _ .,-]+$/;
        if (value === "" || re.test(value)) {
            setServiceProviderDetails({
                ...serviceProviderDetails,
                [e.target.name]: e.target.value
            })
        }
    }

    const Activate = () => {

        Swal.fire({
            title: 'Are You Sure?',
            text: 'You want to deactivate',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                Setpassvisible(true);
            }
        });
    };

    const Deactivate = () => {

        Swal.fire({
            title: 'Are You Sure?',
            text: 'You want to activate',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                Setpassvisible(true);
            }
        });
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const [paginationSize, setPaginationSize] = useState(25);
    const columns = [
        {
            title: 'S.No',
            key: 'index',
            align: "center",
            width: '80px',
            render: (text, record, index) => {
                return (
                    (page - 1) * paginationSize + index + 1
                )
            },
        },
        {
            title: 'Label Name',
            dataIndex: 'desc',
            key: 'desc',
            align: "center",
            ...getColumnSearchProps('desc','Label Name'),
            ellipsis: true,
            width: '150px',
            sorter: (a, b) => a.desc.localeCompare(b.desc)

        },
        {
            title: 'Edit',
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: '150px',
            render: (text, record) => {
                console.log(record, "record111");
                return (
                    <Fragment>
                        {record.isactive === true ?
                            <div className='text-center'>
                                <span className="btn btn-sm btn-light-info text-info btn-circle "
                                    onClick={(e) => { editservice(record) }}>
                                    <Tooltip title="Click here to Edit" color='#39cb7f'>
                                        <i className="ri-edit-line"></i></Tooltip>
                                </span>
                            </div> :
                            <div className='text-center'>
                                <span className="btn btn-sm btn-light-info text-info btn-circle "
                                >
                                    <i className="ri-edit-line"
                                        style={{ cursor: 'not-allowed' }}></i>
                                </span>
                            </div>
                        }

                    </Fragment>
                )
            }
        },
        {
            title: "Status",
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: '150px',

            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],

            onFilter: (value, record) => record.isactive === value,
            filterSearch: false,
            render: (text, record) => {

                return (
                    <Fragment>
                        <div className='text-center'>
                            {/* {record.isactive === true ?
                                <span className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Activate" color='#fc4b6c'><StopOutlined /></Tooltip>
                                </span>
                                :
                                <span className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Deactivate" color='#39cb7f'><i className="ri-check-line"></i></Tooltip>
                                </span>
                            } */}
                            {record.isactive === true ?
                                <span className="btn btn-sm btn-light-success text-success btn-circle " onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Deactivate" color='#39cb7f'><i className="ri-check-line"></i></Tooltip>
                                </span> :
                                <span className="btn btn-sm btn-light-danger text-danger btn-circle " onClick={(e) => { blockprovider(record) }}><Tooltip title="Click here to Activate" color='#fc4b6c'><StopOutlined /></Tooltip>
                                </span>
                            }
                        </div>
                    </Fragment>
                )
            }
        },
    ];

    console.log(lists.certificate_info, 'lists.certificate_info');

    const [serviceProviderDetails, setServiceProviderDetails] = useState({
        provider_name: '',
    });

    console.log(serviceProviderDetails, "serviceProviderDetails");

    const [sid, Setsid] = useState({
        s_id: ''
    })


    // const serviceProviderOnChange = (e) => {
    //     setServiceProviderDetails({
    //         ...serviceProviderDetails,
    //         [e.target.name]: e.target.value
    //     })
    // }



    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "Fetch_Certificate_Label",
                        {}
                    ]
                }
            ]

            certificatelist({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
        formValidator2.current.showMessages()
    }, []);

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    const addservice = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            if (sid.s_id === '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveCertificateLabel",
                            {
                                "label_description": serviceProviderDetails.provider_name
                            }
                        ]
                    }
                ]
                certificateadd({
                    payload: payload,
                    paramid: paramid
                })
            }

            else {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateCertificateLabel",
                            {
                                "clb_id": sid.s_id,
                                "clb_name": serviceProviderDetails.provider_name,
                            }
                        ]
                    }
                ]
                certificateupdate({
                    payload: payload,
                    paramid: paramid
                })
            }

        }
    }

    const statuschange = (e) => {
        console.log(e, "pppppoooo");

        Activate()
        Setpassword({
            ...passwordata,
            sp_id: e.id,
            active_status: !e.isactive
        })
    }

    const blockprovider = (e) => {
        Deactivate()
        Setpassword({
            ...passwordata,
            sp_id: e.id,
            active_status: !e.isactive
        })
    }


    const submitpassword = () => {
        setAddFormSubmit1(true)
        if (formValidator1.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "ChangeCertificateLabelStatus",
                        {
                            "clb_id": passwordata.sp_id,
                            "is_active": passwordata.active_status,
                            "password": passwordata.passvalue,
                            "pwd_validate": true
                        }
                    ]
                }
            ]
            updatestatus({
                payload: pay,
                paramid: paramid
            })
        }
    }

    const blockservice = () => {
        setAddFormSubmit2(true)
        if (formValidator2.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "BlockServiceProvider",
                        {
                            "sp_id": blockdata.sp_id,
                            "is_blocked": blockdata.is_blocked,
                            "remarks": blockdata.remarks,
                            "password": blockdata.password
                        }
                    ]
                }
            ]
            updateblock({
                payload: pay,
                paramid: paramid
            })

        }
    }

    const editservice = (data) => {
        console.log(data, "data123");
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: data.desc,

        })
        Setsid({
            ...sid,
            s_id: data.id
        })
        SetmodalVisible(true);
    }

    const handleOk = () => {
        setRemarksModal(false)
    };

    const handleCancel = () => {
        setRemarksModal(false)
    };

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                Setblockdata({
                    ...blockdata,
                    remarks: record.r_description
                })
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }



    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span style={{ color: '#1890ff' }} className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Certificate Label</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                                {/* <div class="search-box">
                                    <input class="search-txt" type="text" value={clearText} onChange={searchRecords} placeholder="Type to Search" />
                                    <a class="search-btn">
                                        {iconEnable == true ?
                                            <i class="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                            : <i class="fas fa-search"></i>}
                                    </a>
                                </div> */}
                                <div>
                                    <Tooltip title="Add" color='#1890ff'><Button type="primary" shape="round" className='addbutton'
                                        icon={<PlusCircleOutlined />} size='default' onClick={addopen} >
                                        Add
                                    </Button></Tooltip></div>

                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row" >
                            <div className="col-12">
                                <div className="card">
                                    <div className='card-body' style={{ padding: '0px' }}>
                                        <Table
                                            className='userprivclass'
                                            columns={columns}
                                            //dataSource={lists}
                                            dataSource={lists.certificate_info}
                                            bordered
                                            // pagination={{
                                            //     defaultPageSize: dataTableProperties.pagesize,
                                            //     showSizeChanger: dataTableProperties.sizechanger,
                                            //     pageSizeOptions: dataTableProperties.pageSizeOptions
                                            // }}
                                            pagination={false}
                                            onChange={handleChange} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            </div>
            <Modal title="Add Label" visible={modalVisible} footer={null} maskClosable={false} className="serviceProvider"
                onCancel={modalcancel} >

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='row'>
                                <div className='col-md-1'></div>

                                <div className='col-md-3'>
                                    <label><b>Label Name :</b><span style={{ color: "red" }}> * </span> </label>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <Input
                                            type="text"
                                            id="provider_name"
                                            name='provider_name'
                                            placeholder="Label Name"
                                            autoComplete={'off'}
                                            onChange={onInputChange}
                                            value={serviceProviderDetails.provider_name}
                                            maxlength='100'
                                        />
                                        {formValidator.current.message(
                                            'provider_name',
                                            serviceProviderDetails.provider_name,
                                            [
                                                'required',
                                                {
                                                    regex: /^[a-zA-Z0-9]*$/,
                                                },

                                                `max:100`
                                            ],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Label Name Required',
                                                    regex: 'Enter Valid Name',
                                                    max: 'Maximum character is 100 only'
                                                }
                                            })}
                                    </div>
                                    <div className='col-md-1'></div>

                                </div>
                            </div>
                            <div className="form-actions text-center">
                                <Button type="primary" shape="round" className='addbutton mt-4'
                                    style={{
                                        background: "#198754", borderColor: "#198754"
                                        // , marginLeft: '38%', marginTop: '50px' 
                                    }}
                                    icon={<PlayCircleOutlined />} size='default'
                                    onClick={addservice}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>



            </Modal>
            <Modal visible={passvisible} className="newStyleModalPassword" footer={null} onCancel={passwordcancel}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"> <b>Password </b><span style={{ "color": "red" }}>*</span></label>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                placeholder="Enter password"
                                onChange={(e) => {
                                    Setpassword({
                                        ...passwordata,
                                        passvalue: e.target.value

                                    })
                                }}
                                value={passwordata.passvalue}
                                style={{ "margin-top": "5px", width: '100%' }}
                                className="form-control"
                            />
                            {formValidator1.current.message(
                                'Password',
                                passwordata.passvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={submitpassword}
                    >Submit
                    </Button>

                    {/* <button
                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start"
                        onClick={passwordcancel}
                    >Cancel
                    </button> */}
                </div>
            </Modal>
            <Modal visible={blockvisible} className='usr_modal_class' footer={null}
                onCancel={blockcancel}>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                        <input
                            type="password"
                            id="password"
                            name='passwordvalue'
                            className="form-control"
                            placeholder="Enter password"
                            onChange={(e) => {
                                Setblockdata({
                                    ...blockdata,
                                    password: e.target.value
                                })
                            }}
                            value={blockdata.password}
                        />
                        {formValidator2.current.message(
                            'Password',
                            blockdata.password,
                            ['required'],
                            {
                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required'
                                }
                            })}

                    </div>
                </div>
                <br />
                <div className="col-md-12">
                    <label htmlFor=""><b>Remarks:</b> <span style={{ "color": "red" }}>*</span></label>
                    <textarea className='form-control' name='remark' value={blockdata.remarks}
                        row='1' cols='4' style={{ resize: 'none' }} placeholder="Enter Remarks" onChange={(e) => {
                            Setblockdata({
                                ...blockdata,
                                remarks: e.target.value
                            })

                        }}>
                    </textarea>
                    <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" style={{ "float": "right" }}
                        onClick={(e) => { showRemarksModal() }}>
                        <PlusOutlined />
                    </span>
                    {formValidator2.current.message(
                        'remark',
                        blockdata.remarks,
                        ['required'],
                        {
                            className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Remarks Required'
                            }
                        })}

                </div>
                <br />
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={blockservice}
                    >
                        Submit
                    </Button>
                </div>
            </Modal>
            <Modal title="Remarks List" footer={null} visible={remarksModal} className="remarksClass"
                onOk={handleOk}
                onCancel={handleCancel}>
                {/* <DataTable
                    columns={RemarksColumn}
                    data={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    pagination
                    responsive

                /> */}
                <Table
                    className='userprivclass'
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    bordered
                    pagination={{
                        defaultPageSize: dataTableProperties.pagesize,
                        showSizeChanger: dataTableProperties.sizechanger,
                        pageSizeOptions: dataTableProperties.pageSizeOptions
                    }}
                />

            </Modal>
        </Fragment>
    )
}

export default connect(mapStateToProps, {
    certificatelist,
    certificateadd,
    certificateupdate,
    updatestatus,
    GetRemarksData
})(CertificateLabel);