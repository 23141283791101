import { REVIEWLIST, REVIEWUNITLIST, REVIEWSHOWSETTINGLIST,REVIEWSAVECOMPLIANCE } from '../../types/index'


const initialState = {
    reviewsettings: {
        compliance_frequency: null,
        domain_list: null,
        time_zone_le: null,
        unitlist: null,
        showsettinglist: null,
        timeline: null,
        rs: null,
        savecompliancelist: null

    }

}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REVIEWLIST: {

            return {
                ...state,
                reviewsettings: {
                    ...state.reviewsettings,
                    compliance_frequency: payload.compliance_frequency,
                    domain_list: payload.domain_list,
                    time_zone_le: payload.time_zone_le
                },
            };
        }
        case REVIEWUNITLIST: {
            return {
                ...state,
                reviewsettings: {
                    ...state.reviewsettings,
                    unitlist: payload.rs_unit_list,
                },
            };
        }
        case REVIEWSHOWSETTINGLIST: {
         console.log(payload.showsettinglist,"showsettinglist");
            return {
                ...state,
                reviewsettings: {
                    ...state.reviewsettings,
                    showsettinglist: payload.rs_compliance_list,
                    timeline: payload.timeline,
                    rs: payload.rs

                }
            }
        }
        case REVIEWSAVECOMPLIANCE: {
            
            return {
                ...state,
                reviewsettings: {
                    ...state.reviewsettings,
                    compliance_frequency: payload.compliance_frequency,
                    domain_list: payload.domain_list,
                    unitlist: payload.rs_unit_list,
                    showsettinglist: payload.rs_compliance_list,
                   

                }
            }
        }
        default:
            return state;
    }
}
