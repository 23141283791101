import { Card, Tabs, Collapse, Select, Modal, Table, Button, Input, Tooltip } from 'antd';
import React from 'react'
import { useState, useEffect, useRef, Fragment } from 'react';
import DataTable from "react-data-table-component";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ArrowRightOutlined, ExclamationCircleTwoTone, CheckOutlined, CloseOutlined, ArrowLeftOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { assign, selectunit, table, tabledata, selectassigneedata, showmoredata, saveassigncomliance, syncassigncomliance } from "../../../src/Store/Action/Transcations/Assigncompliance"
import moment from 'moment';
import { toast } from 'react-toastify';
import { entries } from 'lodash';
const _ = require("lodash");
const mapStateToProps = (state) => ({
    unitlist: state.Assigncompliance.assigncompliance
});
const Compliance = ({
    assign,
    selectunit,
    table,
    tabledata,
    showmoredata,
    selectassigneedata,
    saveassigncomliance,
    syncassigncomliance,
    unitlist
}) => {

    const [uniaddress, setUnitAddress] = useState([])
    const [unitnamemodal, setUnitnameModal] = useState([])
    const [tempshowmoredatalist, setTempShowmoredatalist] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [allselect, setAllSelect] = useState(false)
    const { Option } = Select;
    const { Panel } = Collapse;
    const { TabPane } = Tabs;
    const [row, setRow] = useState(false)
    const currentdate = new Date();
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const SelectedEntity = localStorage.getItem('SelectedEntity');
    const sessionValue = localStorage.getItem('sessionValue')
    const [editValue, setEditValue] = useState([])
    const [updateValue, setUpdateValue] = useState([])
    const [updateDate, setUpdateDate] = useState([])
    const [EditDate, setEditDate] = useState([])
    let [count, setCount] = useState(1)
    const [entityid, setCurrentEntity] = useState("");
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    // const entityid = localStorage.getItem('SelectedEntityid');
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const [frequencyid, setFrequencyId] = useState(undefined)
    const [unitid, setUnitId] = useState(undefined)
    const [domain, setDomain] = useState(undefined)
    const [select, setSelect] = useState([])
    const [pushdata, setPushData] = useState([])
    const [selectunit1, setSelectUnit1] = useState([])
    const [temp, setTemp] = useState({
        tempData: [],
        parentIndexId: []
    });
    const [freqid, setFreqid] = useState([])
    const [uid, setUId] = useState([])
    const [collapseindex, setCollapseIndex] = useState([])
    const [set, SetSet] = useState([])
    const [triggervalue, SetTriggerValue] = useState([])
    const [key, setKey] = useState("1")
    const [header, setHeader] = useState([])
    const [updatedateedit, SetUpdateDateEdit] = useState([])
    const [updatedata, setUpdateData] = useState([])
    const [temparr, setTemparr] = useState([])
    console.log(temparr, "temparr1111");
    const [totalrow, setTotalRow] = useState([])
    const [date, setDate] = useState([])
    const [headerlist, setHeaderList] = useState([])
    const [category, setCategory] = useState([])
    const [compliancefrequency, setComplianceFrequency] = useState([])
    const [totaltabledata, setTotalTableData] = useState([])
    const [updatedays, setUpdatedays] = useState([])
    const [assign_statutory, setAssign_statutory] = useState([])
    const [showmoredatalist, setShowMoreDataList] = useState([])
    const [unitname, setUnitname] = useState([])
    const [approvaldrop, setApprovaldrop] = useState([])
    const [assigneedrop, setAssigneedrop] = useState([])
    const [approvalsearch3, setApprovalsearch3] = useState([])
    const [assigneesearch3, setAssigneesearch3] = useState([])
    const [assignpayload, setAssignPayload] = useState([])
    const [triggerindex, setTriggerIndex] = useState([])
    const [updatedaysindex, setupdatedaysIndex] = useState([])
    const [updateeditindex, setUpdateeditindex] = useState([])
    const [dateindex, setDateIndex] = useState([])
    const [rowcompid, setRowCompId] = useState([])
    const [boolean, setBoolean] = useState([])
    const [list, setList] = useState([])
    const [datacount, setdatacount] = useState(null)
    const [entires, setEntires] = useState(null)
    const [showmore1, setShowMore1] = useState(false)
    const [checkedRow, setCheckedRow] = useState(false);
    console.log(checkedRow, "789456");
    const [countvalue, setCountvalue] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [approvalonclick, setApprovalOnclick] = useState(false)
    const [tableclick, setTableclick] = useState(false)
    const [assigneeonclick, setAssigneeOnclick] = useState(false)
    const [editpayload, setEditPayload] = useState(false)
    const [rowpayload, setRowPayload] = useState(false)
    const [diffid, setDiffId] = useState([])
    const [triggerstate, setTriggerstate] = useState(false)
    const [editclick, setEditClick] = useState({
        boolean: false,
        comp_id: []
    })
    const [closeicon, setCloseIcon] = useState({
        comp_id: []
    })
    // const [editclick, setEditClick] = useState(false)
    const [editicon, setEditIcon] = useState([])
    // const [editclickdate, setEditClickDate] = useState(false)
    const [editclickdate, setEditClickDate] = useState({
        comp_id: []
    })
    const [data, setData1] = useState();
    const [categoryname, setCategoryName] = useState("")
    const [clickassignee, setClickassginee] = useState({
        assignee: "",
        approval: "",
        approval_id: null,
        assignee_id: null
    })
    const [assigneelist, setAssigneelist] = useState({
        assign_id: null,
        approval_id: null,
        assignee: "",
        approval: "",
        restricted: "",
        vor_id: ''
    })
    const [data1, setData] = useState({
        legalentity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        division: "",
        category: "",
        domain: "",
        unit1: "",
        compliance_frequency: "",
        timezone: "",
        vor_id: ''
    })
    const [assigneeid, setAssigneeId] = useState([])

    const [approvalid, setApprovalId] = useState([])

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntityid') != null) {
    //         setData({
    //             ...data1,
    //             legalentity: localStorage.getItem('SelectedEntityid')
    //         })
    //     }
    // }, [localStorage.getItem('SelectedEntityid')])



    useEffect(() => {

        if (assigneedrop) {

            let filterrr = assigneedrop.filter((item) => {

                return item.emp_code === assigneelist.assign_id

            })
            setAssigneeId(filterrr && filterrr[0] && filterrr[0].usr_id)

        }

        if (approvaldrop) {

            let filterrr1 = approvaldrop.filter((item) => {
                if (assigneelist.approval_id == null || assigneelist.approval_id == '' || assigneelist.approval_id == 'null') {
                    return item.emp_name === assigneelist.approval
                } else {
                    return item.emp_code === assigneelist.approval_id
                }

            })
            setApprovalId(filterrr1 && filterrr1[0] && filterrr1[0].usr_id)

        }

    }, [assigneedrop, assigneelist, approvaldrop])

    const columns = [

        {
            title: <Fragment>
                <input type="checkbox" name="allchecked" />
            </Fragment>,
            dataIndex: false,
            key: false,
            // ellipsis: true,
            width: '5px',
            align: 'center',
            render: (text, record) => {
                return (
                    <Fragment>
                        <input type="checkbox" className='childcheckbox'
                            name={'checked' + record.parentId}
                            onClick={(e) => {
                                let checked = e.target.checked;
                                let temp = []
                                temp.push(record.comp_id)
                                setRowCompId([...rowcompid, ...temp])

                                for (let i in collapseindex) {

                                    let temp = []
                                    temp.push(headerlist && headerlist[collapseindex[i]] && headerlist[collapseindex[i]])
                                    setHeader([...header, ...temp])
                                }

                                addSelectedDataValues(
                                    checked,
                                    record.comp_id,
                                    record.childId
                                );
                            }}
                        />
                    </Fragment>
                )
            }
        },
        {
            title: 'Compliance Task',
            width: "20px",
            ellipsis: false,
            render: (record) => {
                console.log(record, "recordrecord");
                return <><Tooltip title={`${record.descp}`} ><ExclamationCircleTwoTone style={{ marginRight: '5px' }} /></Tooltip><span>{record.comp_name}</span></>
            },
        },
        // {
        //     title: 'Application Units',
        //     dataIndex: 'Application Units',
        //     key: 'Application Units',
        //     width: "40px",
        //     render: (row, record, index) => <a style={{ padding: "0px 30px" }} onClick={showModal} href="javascript:;">1/1</a>,
        //     // record && record.comp_name
        // },
        {
            title: 'Applicable Vendors',
            dataIndex: false,
            ellipsis: true,
            width: '12px',
            align: "center",
            render: (row, record) => {
                return (
                    <Link style={{ padding: "0px 30px" }} onClick={(e) => {
                        showModal()
                        let temp2 = []
                        let temp3 = []
                        for (let i in record.applicable_units) {
                            let address = uid.filter((item) => {
                                return item.u_id === record.applicable_units[i]
                            })
                            for (let j in address) {
                                temp2.push(address[j].address)
                                temp3.push(address[j].u_name)
                            }
                        }
                        setUnitAddress([...temp2])
                        setUnitnameModal([...temp3])
                    }}>{`${record.applicable_units.length}/${data1.unit1.length}`}</Link>

                )
            }
        },
        {
            title: 'Compliance Frequency',
            dataIndex: 'Compliance Frequency',
            key: 'freq',
            width: "10px",
            align: "center",
            render: (row, record, index) => record && record.freq
            // record && record.comp_name
        },
        {
            title: 'Statutory Date/Schedule',
            ellipsis: true,
            width: "20px",
            render: (row, record, index) => {
                let today_date1 = new Date().toISOString().slice(0, 10)
                let today_date = moment(today_date1).format("MMM-DD")
                return (
                    record.r_every != null && record.repeat_by != null ?
                        <>{record.summary}({record.due_date_list[0] != "" ? moment(record.due_date_list[0]).format("MMM-DD") : today_date})</> : <>{record.summary}</>
                )
            }
            // record && record.comp_name
        },
        {
            title: <label><span>Trigger Before (Days)</span><span style={{ color: "red", marginLeft: "5px" }}>*</span></label>,
            dataIndex: 'Trigger Before (Days)',
            key: 'statu_dates[0].statutory_date',
            width: "10px",
            align: 'center',
            fixed: 'right',
            render: (row, record, index) => {
                return (
                    <>
                        {record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days == null || record && record.statu_dates && record.statu_dates == "" ?
                            <div>
                                <Input type="number"
                                    autocomplete="off"
                                    hidden={record.freq == "On Occurrence"}
                                    name='triggerInput' maxLength={3} style={{ width: "80px", height: "30px" }}
                                    // disabled={temparr && temparr.length && temparr.filter((e) => e && e.comp_id == record.comp_id).length > 0 ? false : true}
                                    className="form-control" placeholder='Days'
                                    onBlur={(e) => {

                                        let temp = []
                                        let temp2 = []
                                        let temp3 = editValue
                                        for (let i in temp3) {
                                            if (temp3[i].comp_id == record.comp_id) {
                                                temp3.splice(i, 1)
                                            }
                                        }
                                        temp2.push({
                                            comp_id: record.comp_id,
                                            value: e.target.value,
                                            index: index
                                        })
                                        temp.push(e.target.value)

                                        if (Number(temp) < 101) {
                                            SetTriggerValue([...triggervalue, ...temp])
                                            setEditValue([...temp3, ...temp2])
                                            setEditPayload(true)
                                            setTriggerstate(false)
                                        } else {
                                            toast.error("Trigger before days should not exceed 100")
                                            setTriggerstate(true)
                                        }
                                    }}

                                ></Input>
                                {/* {validator.current.message(
                                            'trigger_before',
                                            editValue
                                            ['required', { regex: ([1-9]|[1-9][0-9]|100)}],
                                           
                                            {
                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Value',
                                                    regex: 'Maximum trigger before value is 100 only'

                                                }
                                            })} */}
                            </div>
                            :
                            <>
                                <label>{record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days}<span style={{ marginLeft: "5px" }}>Day(s)</span> <i className="ri-edit-line" style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        let temp = []
                                        temp.push(record.comp_id)
                                        setEditClick({
                                            boolean: true,
                                            comp_id: [...editclick.comp_id, ...temp]
                                        })
                                        // setEditClick(true)
                                    }}></i> </label>
                                {editclick && editclick.comp_id.filter((e) => e == record.comp_id).length > 0 ?

                                    // {/* {editclick.boolean == true && editclick && editclick.comp_id.filter((e) => e.comp_id === record.comp_id) && temparr.filter((e) => e.comp_id == record.comp_id).length > 0 ? */ }
                                    <>
                                        <Input name='triggerInputUpdate' maxLength={3} style={{ width: "80px", height: "30px" }} placeholder='Days'
                                            hidden={record.freq == "On Occurrence"}
                                            defaultValue={record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days}
                                            autocomplete="off"
                                            autoFocus={true}
                                            onBlur={(e) => {

                                                let tempupdate = []
                                                let temp2 = []
                                                tempupdate.push(e.target.value)
                                                temp2.push({
                                                    comp_id: record.comp_id,
                                                    value: e.target.value,
                                                    index: index
                                                })
                                                let temp = updateValue
                                                for (let i in temp) {
                                                    if (temp[i].comp_id == record.comp_id) {
                                                        temp.splice(i, 1)
                                                    }
                                                }
                                                if (record.freq == 'One Time' || record.freq == 'On Occurrence') {
                                                    if (Number(tempupdate) < 101) {
                                                        setUpdatedays([...updatedays, ...tempupdate])
                                                        setUpdateValue([...temp, ...temp2])
                                                        setTriggerstate(false)
                                                    } else {
                                                        toast.error("Trigger before days should not exceed 100")
                                                        setTriggerstate(true)
                                                    }
                                                } else {
                                                    let k = record.summary
                                                    if (k.includes('Day') == true) {
                                                        if (Number(tempupdate) <= 30) {
                                                            setUpdatedays([...updatedays, ...tempupdate])
                                                            setUpdateValue([...temp, ...temp2])
                                                            setTriggerstate(false)
                                                        } else {
                                                            toast.error("Trigger before days should not exceed repeats every value")
                                                            setTriggerstate(true)
                                                        }
                                                    } else if (k.includes('Month') == true) {
                                                        if (k.includes('1') == true) {
                                                            if (Number(tempupdate) <= 30) {
                                                                setUpdatedays([...updatedays, ...tempupdate])
                                                                setUpdateValue([...temp, ...temp2])
                                                                setTriggerstate(false)
                                                            } else {
                                                                toast.error("Trigger before days should not exceed repeats every value")
                                                                setTriggerstate(true)
                                                            }
                                                        } else if (k.includes('2') == true) {
                                                            if (Number(tempupdate) <= 60) {
                                                                setUpdatedays([...updatedays, ...tempupdate])
                                                                setUpdateValue([...temp, ...temp2])
                                                                setTriggerstate(false)
                                                            } else {
                                                                toast.error("Trigger before days should not exceed repeats every value")
                                                                setTriggerstate(true)
                                                            }
                                                        } else if (k.includes('3') == true) {
                                                            if (Number(tempupdate) <= 90) {
                                                                setUpdatedays([...updatedays, ...tempupdate])
                                                                setUpdateValue([...temp, ...temp2])
                                                                setTriggerstate(false)
                                                            } else {
                                                                toast.error("Trigger before days should not exceed repeats every value")
                                                                setTriggerstate(true)
                                                            }
                                                        } else {
                                                            if (Number(tempupdate) < 101) {
                                                                setUpdatedays([...updatedays, ...tempupdate])
                                                                setUpdateValue([...temp, ...temp2])
                                                                setTriggerstate(false)
                                                            } else {
                                                                toast.error("Trigger before days should not exceed 100")
                                                                setTriggerstate(true)
                                                            }
                                                        }
                                                    } else if (k.includes('Year') == true) {
                                                        if (Number(tempupdate) < 101) {
                                                            setUpdatedays([...updatedays, ...tempupdate])
                                                            setUpdateValue([...temp, ...temp2])
                                                            setTriggerstate(false)
                                                        } else {
                                                            toast.error("Trigger before days should not exceed 100")
                                                            setTriggerstate(true)
                                                        }
                                                    }

                                                }


                                            }}


                                        ></Input>
                                        <  CloseOutlined style={{ marginLeft: "5px" }}
                                            onClick={(e) => {
                                                let temp = editclick.comp_id
                                                for (let i in temp) {
                                                    if (temp[i] == record.comp_id) {
                                                        temp.splice(i, 1)
                                                    }
                                                }
                                                setEditClick({
                                                    comp_id: [...temp]
                                                })
                                            }} />
                                    </>
                                    : ""}
                            </>
                        }
                    </>

                )
                // }
                // else{
                //     return (
                //         <>
                //             {record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days == null || record && record.statu_dates && record.statu_dates == "" ?
                //                 <div>
                //                     <input type="number"
                //                         name='triggerInput' maxLength={3} style={{ width: "80px", height: "30px" }}
                //                         disabled={temparr && temparr.length && temparr.filter((e) => e && e.comp_id == record.comp_id).length > 0 ? false : true}
                //                         className="form-control" placeholder='Days'
                //                         onBlur={(e) => {

                //                             let temp = []
                //                             let temp2 = []
                //                             temp2.push({
                //                                 comp_id: record.comp_id,
                //                                 value: e.target.value,
                //                                 index: index
                //                             })
                //                             temp.push(e.target.value)

                //                             if (Number(temp) < 101) {
                //                                 SetTriggerValue([...triggervalue, ...temp])
                //                                 setEditValue([...editValue, ...temp2])
                //                                 setEditPayload(true)
                //                                 setTriggerstate(false)
                //                             } else {
                //                                 toast.error("Trigger before days should not exceed 100")
                //                                 setTriggerstate(true)
                //                             }
                //                         }}

                //                     ></input>
                //                 </div>
                //                 :
                //                 <>
                //                     <label>{record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days}  <i className="ri-edit-line" onClick={() => {
                //                         setEditClick(true)
                //                     }}></i> </label>
                //                     {editclick == true && temparr.filter((e) => e.comp_id == record.comp_id).length > 0 ?
                //                         <input name='triggerInputUpdate' maxLength={3} style={{ width: "80px", height: "30px" }} className="form-control" placeholder='Days'
                //                             hidden={record.freq == "On Occurrence"}
                //                             onBlur={(e) => {

                //                                 let tempupdate = []
                //                                 let temp2 = []
                //                                 tempupdate.push(e.target.value)
                //                                 temp2.push({
                //                                     comp_id: record.comp_id,
                //                                     value: e.target.value,
                //                                     index: index
                //                                 })
                //                                 if (Number(tempupdate) < 101) {
                //                                     setUpdatedays([...updatedays, ...tempupdate])
                //                                     setUpdateValue([...updateValue, ...temp2])
                //                                     setTriggerstate(false)
                //                                 } else {
                //                                     toast.error("Trigger before days should not exceed 100")
                //                                     setTriggerstate(true)
                //                                 }

                //                             }}


                //                         ></input>
                //                         : ""}
                //                 </>
                //             }
                //         </>

                //     )
                // }
            }
            // record && record.comp_name
        },
        {
            title: <label><span>Due Date</span><span style={{ color: "red", marginLeft: "5px" }}>*</span></label>,
            dataIndex: 'Due Date *',
            key: 'due_date_list[0]',
            width: "14px",
            align: 'center',
            render: (row, record, index) => {
                let today_date1 = new Date().toISOString().slice(0, 10)
                let today_date = moment(today_date1).format("DD-MMM-YYYY")
                return (
                    <>
                        {record.due_date_list[0] != "" ?

                            //  {record.due_date_list[0] === "" || record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days != null ?
                            <>
                                <label style={{ textAlign: "center" }} hidden={record.freq == "On Occurrence"}>{moment(record.due_date_list[0]).format("DD-MMM-YYYY")}
                                    <i style={{ marginLeft: "5px", cursor: "pointer" }} className="ri-edit-line" onClick={() => {
                                        let temp = []
                                        temp.push(record.comp_id)
                                        // setEditClickDate(true)
                                        setEditClickDate({
                                            comp_id: [...editclickdate.comp_id, ...temp]
                                        })
                                    }}></i>
                                </label>

                                {editclickdate && editclickdate.comp_id.filter((e) => e == record.comp_id).length > 0 ?
                                    // <input style={{ width: "200px", height: "30px" }} className="form-control" type="date" min={moment(currentdate).format("YYYY-MM-DD")} max={moment(currentdate).format("YYYY-MM-DD") == moment(record.due_date_list[0]).format("DD-MM-YYYY") ? moment(currentdate).add(15,'days').format("YYYY-MM-DD"): moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                    <>
                                        <Input
                                            style={{ width: "135px", height: "30px" }}
                                            defaultValue={moment(new Date(record.due_date_list[0])).format('YYYY-MM-DD')}
                                            // className="form-control"
                                            type="date"
                                            min={moment(currentdate).format("DD-MM-YYYY")}
                                            max={moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                            hidden={record.freq == "On Occurrence"}
                                            onChange={(e) => {
                                                let tempupdatedate = []
                                                tempupdatedate.push(e.target.value)
                                                SetUpdateDateEdit([...updatedateedit, ...tempupdatedate])
                                                let temp = updatedata
                                                for (let i in temp) {
                                                    if (temp[i].comp_id == record.comp_id) {
                                                        temp.splice(i, 1)
                                                    }
                                                }
                                                for (let i in updateValue) {
                                                    if (index == updateValue[i].index && record.comp_id == updateValue[i].comp_id) {
                                                        let temparr = []
                                                        temparr.push({
                                                            date: e.target.value,
                                                            comp_id: record.comp_id,
                                                            index: index
                                                        })
                                                        // if (record.r_every == null && record.repeat_by == null) {
                                                        let temparr1 = []
                                                        temparr1.push({
                                                            date: e.target.value,
                                                            comp_id: updateValue[i].comp_id,
                                                            value: updateValue[i].value,
                                                            frequency: record.freq
                                                        })
                                                        setUpdateData([...temp, ...temparr1])
                                                        // }
                                                        setUpdateDate([...updateDate, ...temparr])
                                                    }
                                                }

                                            }}
                                        />
                                        <  CloseOutlined
                                            style={{ marginLeft: "5px" }}
                                            onClick={(e) => {
                                                let temp = editclickdate.comp_id
                                                for (let i in temp) {
                                                    if (temp[i] == record.comp_id) {
                                                        temp.splice(i, 1)
                                                    }
                                                }
                                                setEditClickDate({
                                                    comp_id: [...temp]
                                                })
                                            }} />
                                    </>

                                    : ""}
                            </>
                            :
                            <div>
                                {record.due_date_list[0] === "" && record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days != null ?
                                    <>
                                        <label hidden={record.freq == "On Occurrence"}>{today_date}
                                            <i style={{ marginLeft: "5px" }} className="ri-edit-line" onClick={() => {
                                                // setEditClickDate(true)
                                                let temp = []
                                                temp.push(record.comp_id)
                                                // setEditClickDate(true)
                                                setEditClickDate({
                                                    comp_id: [...editclickdate.comp_id, ...temp]
                                                })
                                            }}></i>
                                        </label>
                                        {editclickdate && editclickdate.comp_id.filter((e) => e == record.comp_id).length > 0 ?
                                            // <input style={{ width: "200px", height: "30px" }} className="form-control" type="date" min={moment(currentdate).format("YYYY-MM-DD")} max={moment(currentdate).format("YYYY-MM-DD") == moment(record.due_date_list[0]).format("DD-MM-YYYY") ? moment(currentdate).add(15,'days').format("YYYY-MM-DD"): moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                            <>
                                                <Input style={{ width: "130px", height: "30px" }} className="form-control" type="date" min={moment(currentdate).format("YYYY-MM-DD")} max={moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                                    hidden={record.freq == "On Occurrence"}
                                                    defaultValue={moment(new Date(today_date)).format('YYYY-MM-DD')}
                                                    onChange={(e) => {
                                                        let tempupdatedate = []
                                                        tempupdatedate.push(e.target.value)
                                                        SetUpdateDateEdit([...updatedateedit, ...tempupdatedate])
                                                        let temp = updatedata
                                                        for (let i in temp) {
                                                            if (temp[i].comp_id == record.comp_id) {
                                                                temp.splice(i, 1)
                                                            }
                                                        }
                                                        for (let i in updateValue) {
                                                            if (index == updateValue[i].index && record.comp_id == updateValue[i].comp_id) {
                                                                let temparr = []
                                                                temparr.push({
                                                                    date: e.target.value,
                                                                    comp_id: record.comp_id,
                                                                    index: index
                                                                })
                                                                // if (record.r_every == null && record.repeat_by == null) {
                                                                let temparr1 = []
                                                                temparr1.push({
                                                                    date: e.target.value,
                                                                    comp_id: updateValue[i].comp_id,
                                                                    value: updateValue[i].value,
                                                                    frequency: record.freq
                                                                })
                                                                setUpdateData([...temp, ...temparr1])
                                                                // }
                                                                setUpdateDate([...updateDate, ...temparr])
                                                            }
                                                        }

                                                    }}
                                                />
                                                <  CloseOutlined
                                                    style={{ marginLeft: "5px" }}
                                                    onClick={(e) => {
                                                        let temp = editclickdate.comp_id
                                                        for (let i in temp) {
                                                            if (temp[i] == record.comp_id) {
                                                                temp.splice(i, 1)
                                                            }
                                                        }
                                                        setEditClickDate({
                                                            comp_id: [...temp]
                                                        })
                                                    }} />
                                            </>
                                            : ""}
                                    </>
                                    :
                                    <Input className='form-control' type="date" min={moment(currentdate).format("YYYY-MM-DD")} max={moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                        hidden={record.freq == "On Occurrence"}
                                        style={{ width: "140px" }}
                                        autocomplete="off"
                                        // disabled={temparr && temparr.length && temparr.filter((e) => e && e.comp_id == record.comp_id).length > 0 ? false : true}
                                        onChange={(e) => {
                                            let temp = EditDate
                                            for (let i in temp) {
                                                if (temp[i].comp_id == record.comp_id) {
                                                    temp.splice(i, 1)
                                                }
                                            }
                                            // let date1 = moment(e.target.value).format("DD-MMM-YYYY")
                                            let tempdate = []
                                            tempdate.push(e.target.value)
                                            setDate([...date, ...tempdate])
                                            for (let i in editValue) {

                                                if (index == editValue[i].index) {
                                                    let temparr = []
                                                    temparr.push({
                                                        date: e.target.value,
                                                        comp_id: editValue[i].comp_id,
                                                        value: editValue[i].value,
                                                        frequency: record.freq
                                                    })
                                                    setEditDate([...temp, ...temparr])
                                                }
                                            }

                                        }}
                                    ></Input >}
                            </div>
                        }
                    </>
                )
            },
            sortable: true,
            // right: true,
            reorder: true,
            fixed: 'right',
            // width: '150px',

        },
        {
            title: 'Validity Date',
            dataIndex: 'Validity Date',
            key: 'due_date_list[0]',
            width: "12px",
            fixed: 'right',
            render: (row, record, index) => {
                return (
                    <>
                        {record && record.freq === "Review" || record && record.freq === "Periodical" ?
                            <Input className='form-control' type="date"
                                // hidden={record.freq == "On Occurrence"}
                                style={{ width: "140px" }}
                                min={moment(currentdate).format("YYYY-MM-DD")}
                                // disabled={temparr.filter((e) => e.comp_id == row.comp_id).length > 0 ? false : true}
                                onChange={(e) => {
                                    let temp = updatedata
                                    for (let i in temp) {
                                        if (temp[i].comp_id == record.comp_id) {
                                            temp.splice(i, 1)
                                        }
                                    }
                                    for (let j in updateValue) {
                                        for (let k in updateDate) {
                                            if (index == updateDate[k].index && record.comp_id == updateDate[k].comp_id && record.comp_id == updateValue[j].comp_id) {
                                                let temparr = []
                                                temparr.push({
                                                    date: updateDate[k].date,
                                                    comp_id: updateDate[k].comp_id,
                                                    value: updateValue[j].value,
                                                    vdate: e.target.value,
                                                    frequency: record.freq
                                                })
                                                setUpdateData([...temp, ...temparr])
                                            }
                                        }
                                    }

                                }}
                            // disabled={temparr.filter((e) => e.comp_id == record.comp_id).length > 0 ? false : true}
                            ></Input >
                            : ""}
                    </>
                )
            },
            reorder: true,
            // width: '90px',

        }

    ]


    // useEffect(() => {
    //     const booleandata = temparr && temparr.map((item) => {
    //         return item.comp_id
    //     })
    //     const booleandata22 = editclick && editclick.comp_id.map((item) => {
    //         return item
    //     })

    //     // const booleandata22 = closeicon && closeicon.comp_id.map((item) => {
    //     //     return item
    //     // })
    //     var difference = booleandata && booleandata.filter(x => booleandata22.indexOf(x) === -1);

    //     console.log(booleandata.filter(x => booleandata22.includes(x)), "samevalues");
    //     console.log(difference, "difference");
    //     // setDiffId(difference)
    //     // const compare = JSON.stringify(booleandata) === JSON.stringify(booleandata22)
    //     setEditIcon(difference)
    // }, [temparr, editclick])
    const showmore = () => {
        setShowMore1(true)
        const payload10 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceForUnits",
                    {
                        "le_id": entityid && entityid,
                        "u_ids": unitid,
                        "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                        "r_count": entires,
                        "f_ids": frequencyid,
                        "tz_name": data1.timezone
                    }
                ]

            }
        ]
        showmoredata({
            payload: payload10,
            paramid: paramid
        })
    }
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };



    const addAllSelectedDataValues = (checked, currentParId, parentData, childData) => {

        let array
        let temparr1 = []
        let tempArray = temparr
        for (let j in childData) {

            temparr1.push(childData && childData[j].comp_id)

        }
        setRowCompId([...rowcompid, ...temparr1])
        var ele = document.getElementsByName('checked' + currentParId);

        if (checked === true) {
            setCheckedRow(true)
            array = _.filter(data, { parentId: currentParId });
            for (let i in array) {
                tempArray.push(array[i])
            }

            setTemparr([...tempArray])
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            for (var x = tempArray.length - 1; x >= 0; x--) {
                if (tempArray[x].parentId === currentParId) {
                    tempArray.splice(x, 1)
                }
            }
            setTemparr([...tempArray])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }

    const addSelectedDataValues = (checked, comp_id, act_name) => {
        console.log(checked, "checkedchecked");
        var ele = document.getElementsByName('allchecked');
        let array
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {
                array = _.find(data, { comp_id: comp_id });
                tempArray.push(array)
            } else {
                console.log("test2");
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                    if (data.length !== temparr.length) {
                        setCheckedRow(false)
                        for (var j = 0; j < ele.length; j++) {
                            if (ele[j].type === 'checkbox')
                                ele[j].checked = false;
                        }
                    }
                }

            }
            setTemparr([...tempArray])
        }
        else {
            if (checked === true) {
                array = _.find(data, { comp_id: comp_id });
                tempArray.push(array)
                if (data.length === temparr.length) {
                    setCheckedRow(true)
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }

            } else {
                console.log("test3");
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }

    }
    console.log(temparr, "temparr777");
    const Next1 = () => {
        setAddFormSubmit(true)
        setCountvalue(true)
        setTemparr([])
        let checked = false
        if (validator.current.allValid() && isAuth) {
            setAddFormSubmit(true)
            setKey("2")
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceTotalToAssign",
                        {
                            "le_id": entityid,
                            "u_ids": unitid,
                            "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                            "f_ids": frequencyid
                        }

                    ]
                }
            ]
            table({
                payload: payload2,
                paramid: paramid
            })
            const payload8 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceForUnits",
                        {
                            "le_id": entityid,
                            "u_ids": unitid,
                            "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                            "r_count": 0,
                            "f_ids": frequencyid,
                            "tz_name": data1.timezone
                        }
                    ]

                }
            ]
            tabledata({
                payload: payload8,
                paramid: paramid
            })
            // setTemparr([])
            // let temp = temparr
            // console.log(temp,"temp9999");
            // for (let i in temp) {
            //     for (let j in checkid) {
            //         if (temp[i].comp_id === checkid[j]) {
            //             temp.splice(i, 1)
            //         }
            //     }
            // }
            // setTemparr([...temp])
            setTemparr([])
        }
    }
    const Next2 = () => {
        setTriggerstate(false)
        setTableclick(true)
        if (temparr != "") {
            for (let i in temparr) {
                if (temparr[i].freq != "On Occurrence") {
                    if (temparr[i].due_date_list[0] != "" && temparr[i].statu_dates[0].trigger_before_days != null ? temparr[i].due_date_list[0] && temparr[i].statu_dates[0].trigger_before_days : editValue.filter((e) => e.comp_id == temparr[i].comp_id) && updatedata.filter((e) => e.comp_id == temparr[i].comp_id)) {
                        // if (temparr[i].due_date_list[0] != "" && temparr[i].statu_dates[0].trigger_before_days != null ? temparr[i].due_date_list[0] != "" && temparr[i].statu_dates[0].trigger_before_days != null : triggervalue != "" && date != "") {
                        const value123 = unitlist && unitlist.assign_user && unitlist.assign_user.filter((item) => {
                            return item.is_approver == true
                        })
                        setApprovaldrop(value123)
                        setKey("3")
                    }
                    else {
                        toast.warning("Trigger before days and Due date fields are required in - ".concat(header[i]))

                    }
                }
                else {
                    setKey("3")

                }
            }

        }
        else {
            toast.warning("No Compliance selected for assign")

        }
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetUserToAssignCompliance",
                    {
                        "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                        "u_ids": unitid,
                        "le_id": entityid
                    }
                ]
            }
        ]
        selectassigneedata({
            payload: payload1,
            paramid: paramid
        })

    }

    const Submit = () => {
        setAddFormSubmit1(true)
        if (validator1.current.allValid() && isAuth) {
            setAddFormSubmit1(true)

            const payload4 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "SaveAssignedCompliance",
                        {
                            "assignee": Number(assigneeid),
                            "assignee_name": `${assigneelist.assign_id}- ${assigneelist.assignee}`,
                            "concurrence_person": null,
                            "concurrer_name": null,
                            "approval_person": Number(approvalid),
                            "approver_name": `${assigneelist.approval}`,
                            "assign_compliances": assignpayload,
                            "le_id": entityid,
                            "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                            "u_ids": unitid,
                            "tz_name": data1.timezone,
                            "vo_list": data1.vor_id ? [data1.vor_id] : []
                        }
                    ]

                }

            ]
            saveassigncomliance({
                payload: payload4,
                paramid: paramid
            })
            const payload5 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "SyncAssignedCompliance",
                        {
                            "assignee": Number(assigneeid),
                            "assignee_name": `${assigneelist.assign_id}- ${assigneelist.assignee}`,
                            "concurrence_person": null,
                            "concurrer_name": null,
                            "approval_person": Number(approvalid),
                            "approver_name": `${assigneelist.approval}`,
                            "assign_compliances": assignpayload,
                            "le_id": entityid,
                            "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                            "u_ids": unitid,
                            "tz_name": data1.timezone,

                        }
                    ]

                }

            ]

            syncassigncomliance({
                payload: payload5,
                paramid: paramid
            })
            const payload6 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssignCompliancesFormData",
                        {}
                    ]
                }
            ]
            selectunit({
                payload: payload6,
                paramid: paramid
            })

            // setKey("1")
            // setData({
            //     ...data,
            //     legalentity: "",
            //     division: "",
            //     category: "",
            //     domain: "",
            //     unit1: "",
            //     compliance_frequency: "",
            //     timezone: ""
            // })
            // setCheckedRow([])
            // setAssigneelist({
            //     ...assigneelist,
            //     assignee: "",
            //     approval: "",
            //     restricted: ""
            // })
            // setTrigger({
            //     ...trigger,
            //     triggerInput: ""
            // })
            // setDate([])
            // toast.success("Compliance Assigned Successfully")
            setTimeout(() => {
                window.location.reload()
            }, 2500);

        }
        // window.location.reload()

    }

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()
    }, [])

    useEffect(() => {
        if (unitlist.tablelist && unitlist.tablelist.length > 0 && unitlist.tablelist != null && unitlist.tablelist != undefined) {
            setAssign_statutory(unitlist && unitlist.tablelist && unitlist.tablelist[0])
        }
        if (showmore1 == true) {
            if (unitlist.showmorelist && unitlist.showmorelist.length > 0 && unitlist.showmorelist != null && unitlist.showmorelist != undefined) {
                // let temp=[]
                // temp.push(showmoredatalist)
                setShowMoreDataList(unitlist && unitlist.showmorelist && unitlist.showmorelist[0])
            }
        }
    }, [unitlist.tablelist, unitlist.showmorelist, showmore1])

    useEffect(() => {
        if (countvalue === true) {
            const count = datacount.map((item) => {
                return item.length
            })
            const total = count.reduce((a, b) => a + b, 0)
            setEntires(total)
        }
    }, [datacount, countvalue])

    useEffect(() => {
        if (showmore1 == true) {
            if (assign_statutory && showmoredatalist) {
                if (count == 1) {
                    let con = Object.entries(assign_statutory)
                    let data = Object.entries(showmoredatalist)
                    let concat = con.concat(data)
                    setList(concat)
                    let con1 = Object.values(assign_statutory)
                    let data1 = Object.values(showmoredatalist)
                    let concat1 = con1.concat(data1)
                    setdatacount(concat1)
                    let con2 = Object.keys(assign_statutory)
                    let data2 = Object.keys(showmoredatalist)
                    let concat2 = con2.concat(data2)
                    setHeaderList(concat2)
                    setCount(2)
                } else {
                    let data = Object.entries(showmoredatalist)
                    let concat = list.concat(data)
                    setList(concat)
                    let data1 = Object.values(showmoredatalist)
                    let concat1 = datacount.concat(data1)
                    setdatacount(concat1)
                    let data2 = Object.keys(showmoredatalist)
                    let concat2 = headerlist.concat(data2)
                    setHeaderList(concat2)
                }
            }
        }
        else {
            if (assign_statutory) {
                setList(Object.entries(assign_statutory))
                setdatacount(Object.values(assign_statutory))
                setHeaderList((Object.keys(assign_statutory)))
            }
        }

    }, [assign_statutory, showmore1, showmoredatalist])


    useEffect(() => {
        if (list.length) {
            let totalList = []
            let arr = [];
            let countrylisttempdata = [];
            let tempSetArray = [];
            let parId = []
            list && list.map(function (user, i) {
                if ((arr.indexOf(user[0]) < 0))
                    arr.push(user[0]);
            });
            arr.map((pmenu, index) => {
                let entityArray = []
                let tempArray = []
                if (pmenu) {
                    list && list.map((subMenuList, childIndex) => {
                        if (pmenu === subMenuList[0]) {
                            subMenuList[1] && subMenuList[1].length && subMenuList[1].map((item) => {

                                entityArray = {
                                    "amendment_history_id": item.amendment_history_id,
                                    "applicable_units": item.applicable_units,
                                    "comp_id": item.comp_id,
                                    "comp_name": item.comp_name,
                                    "compfielaw_url": item.compfielaw_url,
                                    "descp": item.descp,
                                    "due_date_list": item.due_date_list,
                                    "freq": item.freq,
                                    "r_every": item.repeat_by,
                                    "repeat_by": item.repeat_by,
                                    "statu_dates": item.statu_dates,
                                    "summary": item.summary,
                                    "parentId": index
                                }

                                tempArray.push(entityArray)
                            })

                        }
                    })

                }
                totalList = {
                    'index': index,
                    'parentData': pmenu,
                    'child': tempArray
                }
                countrylisttempdata.push(totalList)
                tempSetArray.push(tempArray)
                parId.push(index);

            })
            setTemp({
                ...temp,
                tempData: countrylisttempdata,
                parentIndexId: parId

            })
            // setData(tempSetArray)
            getData()
        }

    }, [list.length])
    const getData = () => {
        let countrylisttempdataNew = [];
        let arr = [];
        let arrNew = [];
        list && list.map(function (user, i) {
            if ((arr.indexOf(user[0]) < 0))
                arr.push(user[0]);
        });

        arr.map((pmenu, index) => {
            let entityArray = []
            let tempArraryNew = [];

            if (pmenu) {

                list && list.map((subMenuList, childIndex) => {

                    if (pmenu === subMenuList[0]) {
                        subMenuList[1] && subMenuList[1].length && subMenuList[1].map((item) => {

                            entityArray = {
                                "amendment_history_id": item.amendment_history_id,
                                "applicable_units": item.applicable_units,
                                "comp_id": item.comp_id,
                                "comp_name": item.comp_name,
                                "compfielaw_url": item.compfielaw_url,
                                "descp": item.descp,
                                "due_date_list": item.due_date_list,
                                "freq": item.freq,
                                "r_every": item.r_every,
                                "repeat_by": item.repeat_by,
                                "statu_dates": item.statu_dates,
                                "summary": item.summary,
                                "parentId": childIndex
                            }

                            tempArraryNew.push(entityArray)
                        })

                    }
                })

            }
            countrylisttempdataNew.push(tempArraryNew)

        })
        var merged = [].concat.apply([], countrylisttempdataNew);
        setData1(merged)
    }


    useEffect(() => {
        if (datacount) {
            var merged = [].concat.apply([], datacount);
            setTotalTableData(merged)
        }
    }, [datacount])

    useEffect(() => {
        let data = freqid.map((item) => {
            return item.frequency_id
        })
        setFrequencyId(data)
        let datadata = uid && uid.map((item) => {
            return item.u_id
        })
        setUnitId(datadata)
    }, [freqid, uid])
    useEffect(() => {
        if (data1.unit1) {
            for (const k in unitid) {
                let arr = unitlist && unitlist.unitdata && unitlist.unitdata.filter((item, i) => {
                    const uniqueCharsrow = [...new Set(item.u_ids)];
                    return item.u_ids.includes(parseInt(unitid[k]))
                })
                if (unitid.length >= 2) {
                    const remove = arr && arr.splice(2, 1)
                    setComplianceFrequency(arr)
                }
                else {
                    setComplianceFrequency(arr)
                }
            }
        }
    }, [unitid, data1])
    useEffect(() => {
        if (unitlist && unitlist.domain && unitlist.domain.length > 0) {
            let data = unitlist && unitlist.domain && unitlist.domain.filter((item) => {
                return item.le_id == entityid
            })
            setDomain(data)
        }
    }, [entityid, unitlist.domain])

    useEffect(() => {
        if (data1.category) {
            let categoryvalue = unitlist.category.filter((item) => {
                return item.cat_id == data1.category
            })
            setCategoryName(categoryvalue[0].cat_name)
            let unitbranch = _.filter(unitlist && unitlist.assignunit && unitlist.assignunit, { category_id: Number(data1.category) })
            setUnitname(unitbranch)
        }
        else {
            setUnitname(unitlist.assignunit)
        }
    }, [data1, unitlist.assignunit])
    useEffect(() => {
        if (tableclick == true) {
            if (approvalonclick == true && unitlist.assign_user != "" && clickassignee.approval_id != "All") {
                let approvallist = approvaldrop && approvaldrop.filter((item) => {
                    return item.usr_id == clickassignee.approval_id
                })
                setApprovalsearch3(approvallist)
            }
            else {
                setApprovalsearch3(approvaldrop)

            }
        }

    }, [approvalonclick, clickassignee, approvaldrop, tableclick])

    useEffect(() => {
        if (tableclick == true) {
            if (assigneeonclick == true && unitlist.assign_user != "" && clickassignee.assignee_id !== "All") {
                let approvallist = assigneedrop.filter((item) => {
                    return item.usr_id == clickassignee.assignee_id
                })
                setAssigneesearch3(approvallist)
            }
            else {
                setAssigneesearch3(assigneedrop)
            }
        }
    }, [assigneeonclick, clickassignee, assigneedrop, tableclick])

    useEffect(() => {
        if (tableclick == true) {
            const value123 = unitlist && unitlist.assign_user && unitlist.assign_user.filter((item) => {
                return item.is_approver == true
            })
            const data = value123 && value123.filter((item) => {
                return item.s_u_name != null
            })
            setApprovaldrop(value123)
            const data1 = data && data.map((item) => {
                return item.s_u_name
            })
            const uniqueCharsrow = [...new Set(data1)];
            setSelect(uniqueCharsrow)
            const assignee = unitlist && unitlist.assign_user && unitlist.assign_user.filter((item) => {
                return item.is_assignee == true
            })
            setAssigneedrop(assignee)

        }
    }, [tableclick, unitlist.assign_user, unitlist && unitlist.assign_user && unitlist.assign_user])
    useEffect(() => {
        if (entityid !== '') {
            if (isAuth && domain) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetAssignComplianceUnits",
                            {
                                "le_id": entityid,
                                "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                                "c_id": 1
                            }
                        ]
                    }
                ]
                if (entityid != "null" && domain != '') {
                    assign({
                        payload: payload,
                        paramid: paramid
                    })
                }

            }
        }
    }, [isAuth, entityid, domain])

    useEffect(() => {
        if (isAuth) {

            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssignCompliancesFormData",
                        {}
                    ]
                }
            ]
            selectunit({
                payload: payload1,
                paramid: paramid
            })
        }
    }, [isAuth, authtoken, paramid])
    useEffect(() => {
        for (let i in triggervalue) {
            let temp = []
            temp.push(i)
            setTriggerIndex([...triggerindex, ...temp])
        }

    }, [triggervalue])
    useEffect(() => {
        for (let i in updatedays) {
            let temp1 = []
            temp1.push(i)
            setupdatedaysIndex([...updatedaysindex, ...temp1])
        }
    }, [updatedays])
    useEffect(() => {
        for (let i in updatedateedit) {
            let temp = []
            temp.push(i)
            setUpdateeditindex([...updateeditindex, ...temp])
        }
    }, [updatedateedit])
    useEffect(() => {
        for (let i in date) {
            let temp = []
            temp.push(i)
            setDateIndex([...dateindex, ...temp])
        }
    }, [date])
    useEffect(() => {
        if (temparr.length > 0) {

            let tempArray = []
            for (let i in temparr) {
                for (let j in EditDate) {
                    if (temparr[i].comp_id == EditDate[j].comp_id) {
                        tempArray.push({
                            "comp_id": temparr[i].comp_id,
                            "comp_name": temparr[i].comp_name,
                            "statu_dates": [
                                {
                                    "statutory_date": Number(moment(EditDate[j].date).format("D")),
                                    "statutory_month": Number(moment(EditDate[j].date).format("M")),
                                    "trigger_before_days": Number(EditDate[j].value),
                                    "repeat_by": temparr[i].statu_dates[0].repeat_by
                                }
                            ],
                            "d_date":
                                temparr[i].due_date_list[0] == "" ? moment(EditDate[j].date).format("DD-MMM-YYYY") : temparr[i].due_date_list[0],
                            "v_date": null,
                            "trigger_before_days": Number(EditDate[j].value),
                            "u_ids": unitid,
                            "repeat_by": temparr[i].repeat_by,
                            "r_every": temparr[i].r_every,
                            "frequency": EditDate[j].frequency,
                            "act": header[j],
                            "amendment_history_id": temparr[i].amendment_history_id


                        })
                    }

                }
                for (let k in updatedata) {
                    if (temparr[i].comp_id == updatedata[k].comp_id) {
                        tempArray.push({
                            "comp_id": temparr[i].comp_id,
                            "comp_name": temparr[i].comp_name,
                            "statu_dates": [
                                {
                                    "statutory_date": Number(moment(updatedata[k].date).format("D")),
                                    "statutory_month": Number(moment(updatedata[k].date).format("M")),
                                    "trigger_before_days": Number(updatedata[k].value),
                                    "repeat_by": temparr[i].statu_dates[0].repeat_by
                                }
                            ],
                            "d_date": moment(updatedata[k].date).format("DD-MMM-YYYY"),
                            "v_date": updatedata[k].vdate ? moment(updatedata[k].vdate).format("DD-MMM-YYYY") : null,
                            "trigger_before_days": Number(updatedata[k].value),
                            "u_ids": unitid,
                            "repeat_by": temparr[i].repeat_by,
                            "r_every": temparr[i].r_every,
                            "frequency": updatedata[k].frequency,
                            "act": header[k],
                            "amendment_history_id": temparr[i].amendment_history_id

                        })

                    }
                }
                for (let x in diffid) {
                    let today_date1 = new Date().toISOString().slice(0, 10)
                    let today_date = moment(today_date1).format("DD-MMM-YYYY")
                    var today = new Date();
                    let current_date = today.getDate()
                    let current_month = today.getMonth()
                    if (temparr && temparr.length && temparr[i].comp_id == diffid && diffid[x]) {
                        tempArray.push({
                            "comp_id": temparr[i].comp_id,
                            "comp_name": temparr[i].comp_name,
                            "statu_dates": [
                                {
                                    "statutory_date": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].statutory_date : current_date,
                                    "statutory_month": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].statutory_month : current_month,
                                    "trigger_before_days": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].trigger_before_days : null,
                                    "repeat_by": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].repeat_by : null
                                }
                            ],
                            "d_date": temparr[i].due_date_list[0] == "" ? today_date : temparr[i].due_date_list[0],
                            "v_date": null,
                            "trigger_before_days": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].trigger_before_days : null,
                            "u_ids": unitid,
                            "repeat_by": temparr[i].repeat_by,
                            "r_every": temparr[i].r_every,
                            "frequency": temparr[i].freq,
                            "act": header[i],
                            "amendment_history_id": temparr[i].amendment_history_id


                        })
                    }

                }

                if (tempArray != "") {
                    setAssignPayload(tempArray)

                }

            }
        }
    }, [updatedata, EditDate, temparr, header, unitid, diffid])



    useEffect(() => {
        let con = updatedata.concat(editValue)
        let ss = con.map((item) => {
            return item.comp_id
        })
        var difference = rowcompid && rowcompid.filter(x => ss.indexOf(x) === -1);
        setDiffId(difference)

    }, [updatedata, rowcompid, editValue])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    return (

        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Assign Compliance</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body" style={{ padding: "10px 15px" }}>
                                <Tabs activeKey={key} type="card">
                                    <TabPane tab="Select Vendor" disabled key="1">
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity: </b> <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select

                                                                // defaultValue={localStorage.getItem('SelectedEntity') === "All Legal Entity" ? "" : localStorage.getItem('SelectedEntity')}
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Legal Entity"
                                                                onChange={(data, value) => {
                                                                    if (data !== undefined) {
                                                                        setCurrentEntity(data)
                                                                        setData({
                                                                            ...data1,
                                                                            legalentity: data,
                                                                            division: '',
                                                                            domain: '',
                                                                            category: '',
                                                                            timezone: '',
                                                                            unit1: '',
                                                                            compliance_frequency: ''
                                                                        })
                                                                    }
                                                                    else {
                                                                        setCurrentEntity("")
                                                                        setData({
                                                                            ...data1,
                                                                            legalentity: "",
                                                                            division: '',
                                                                            domain: '',
                                                                            category: '',
                                                                            timezone: '',
                                                                            unit1: '',
                                                                            compliance_frequency: ''
                                                                        })
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={data1.legalentity}
                                                                style={{ width: "100%", marginTop: "5px", title: '' }}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                data1.legalentity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div>
                                                        :
                                                        <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division: </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            // disabled={data.legalentity == "" ? true : false}
                                                            size="default"
                                                            placeholder="Enter Division"
                                                            onChange={(value) => {
                                                                if (value !== undefined) {
                                                                    let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                    setCategory(CategoryData)
                                                                    setData({
                                                                        ...data1,
                                                                        division: value,
                                                                        category: ''
                                                                    })
                                                                }
                                                                else {
                                                                    setCategory([])
                                                                    setData({
                                                                        ...data1,
                                                                        division: "",
                                                                        category: ''
                                                                    })
                                                                }

                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.division || undefined}
                                                            style={{ width: "100%", marginTop: "5px" }}
                                                            disabled={data1.legalentity == '' ? true : false}
                                                        >
                                                            {unitlist.division && unitlist.division.length && unitlist.division.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <Option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </Option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>

                                                    <label><b>Category: </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            disabled={data1.division == "" ? true : false}
                                                            size="default"
                                                            placeholder="Enter Category"
                                                            onChange={(value) => {
                                                                if (value !== undefined) {
                                                                    setData({
                                                                        ...data1,
                                                                        category: value
                                                                    })
                                                                }
                                                                else {
                                                                    setData({
                                                                        ...data1,
                                                                        category: ""
                                                                    })
                                                                }
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.category || undefined}
                                                            style={{ width: "100%", marginTop: "5px" }}

                                                        >
                                                            {category.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <Option key={item.cat_id}>
                                                                        {item.cat_name}
                                                                    </Option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Domain: </b><span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            // disabled={data.division == "" ? true : false}
                                                            size="default"
                                                            placeholder="Enter Domain"
                                                            onChange={(value) => {
                                                                if (value !== undefined) {
                                                                    setData({
                                                                        ...data1,
                                                                        domain: value,
                                                                        unit1: '',
                                                                        timezone: unitlist.timezone && unitlist.timezone[0] && unitlist.timezone[0].tz_name
                                                                    })
                                                                }
                                                                else {
                                                                    setData({
                                                                        ...data1,
                                                                        domain: '',
                                                                        unit1: '',
                                                                        timezone: ''
                                                                    })
                                                                }
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.domain || undefined}
                                                            style={{ width: "100%", marginTop: "5px" }}
                                                            disabled={data1.legalentity == '' ? true : false}
                                                        >
                                                            {domain && domain.map((item, i) => {

                                                                // {/* {unitlist && unitlist.domain && unitlist.domain.map((item, i) => { */}

                                                                return (
                                                                    // data.unit ?
                                                                    <Option key={item.d_name}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </Select>

                                                        {validator.current.message(
                                                            'domain',
                                                            data1.domain,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Domain Name Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Time Zone: </b><span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            disabled={data1.domain != "" && data1.timezone == "" ? false : true}
                                                            size="default"
                                                            placeholder="Enter TimeZone"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.domain ? unitlist && unitlist.timezone && unitlist.timezone[0] && unitlist.timezone[0].tz_name : undefined
                                                            } style={{ width: "100%", marginTop: "5px" }}
                                                        >

                                                        </Select>
                                                        {/* {validator.current.message(
                                                            'timezone',
                                                            data1.timezone,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Time Zone Required',
                                                                }
                                                            })} */}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Vendor: </b><span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            mode="multiple"
                                                            allowClear={false}
                                                            disabled={data1.domain == "" ? true : false}
                                                            size="default"
                                                            placeholder="Enter Vendor"
                                                            onChange={(value) => {
                                                                let fre = []
                                                                for (const k in value) {
                                                                    let arr = unitname && unitname.filter((item, i) => {
                                                                        return item.u_name === value[k]
                                                                    })
                                                                    fre.push(...arr)
                                                                }
                                                                setUId(fre)

                                                                let temp = []
                                                                temp.push()
                                                                setData({
                                                                    ...data1,
                                                                    unit1: value,
                                                                    compliance_frequency: ''
                                                                })

                                                                setSelectUnit1(value)

                                                            }}

                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.unit1 || undefined}
                                                            style={{ width: "100%", marginTop: "5px" }}
                                                        >
                                                            {unitname && unitname.length && unitname.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <Option key={item.u_name}>
                                                                        {item.u_name}
                                                                    </Option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'unit',
                                                            data1.unit1,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Vendor Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Compliance Frequency: </b><span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            mode="multiple"
                                                            allowClear={false}
                                                            size="default"

                                                            placeholder="Enter Compliance Frequency"
                                                            disabled={data1.unit1 == "" ? true : false}
                                                            onChange={(value) => {
                                                                let fre = []
                                                                for (const k in value) {
                                                                    let arr = unitlist.unitdata.filter((item, i) => {
                                                                        return item.frequency === value[k]
                                                                    })
                                                                    fre.push(...arr)
                                                                }

                                                                setFreqid(fre)

                                                                setData({
                                                                    ...data1,
                                                                    compliance_frequency: value
                                                                })



                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.compliance_frequency || undefined}
                                                            style={{ width: "100%", marginTop: "5px" }}
                                                        >
                                                            {compliancefrequency && compliancefrequency.length && compliancefrequency.map((item, i) => {

                                                                return (
                                                                    // data.unit ?
                                                                    <Option key={item.frequency}>
                                                                        {item.frequency}
                                                                    </Option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'compliance',
                                                            data1.compliance_frequency,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Compliance Frequency Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-12' style={{ marginTop: "20px" }}>
                                            <div className='row'>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-2'>
                                                    {/* <button className="btn btn-info" style={{ float: "right", width: "100px" }} onClick={Next1}>Next</button> */}
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right', marginTop: "5px" }}
                                                        size='default' onClick={Next1}>
                                                        Next <i> <ArrowRightOutlined /></i>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Select Compliance Task" disabled key="2">
                                        {/* <div className='col-md-12' >
                                            <div className='row'>

                                                <div className='col-md-4'>
                                                    <button className="btn btn-secondary" style={{ marginLeft: "25px", width: "100px" }} onClick={() => { setKey("1") }}>Previous</button>
                                                </div>
                                                <div className='col-md-4'>
                                                </div>
                                                <div className='col-md-4'>
                                                    <button className="btn btn-info" style={{ float: "right", marginRight: "25px", width: "100px" }} onClick={Next2}>Next</button>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <Card > */}
                                        <div className="col-md-12" style={{ padding: "0px 20px" }}>
                                            <div className="row">
                                                {temp.tempData.length ? temp.tempData.length &&
                                                    temp.tempData.map((item, i) => {
                                                        console.log(item.child, "childdd");
                                                        return (
                                                            <Collapse
                                                                // onChange={(e) => collapseindexvalue(e)}
                                                                onChange={(e) => {

                                                                    let index = []
                                                                    index.push(...e)
                                                                    setCollapseIndex([...collapseindex, ...index])

                                                                }}
                                                            // defaultActiveKey={['1']}
                                                            >
                                                                <Panel header={item.parentData} key={i} >
                                                                    <div className="container-fluid" style={{ padding: 0 }}>
                                                                        <div className="row">
                                                                            <div className="col-12" >
                                                                                {/* <DataTable
                                                                                columns={columns}
                                                                                data={item[1]}
                                                                                selectableRows={false}
                                                                                pagination
                                                                                onSelectedRowsChange={({
                                                                                    allSelected,
                                                                                    selectedCount,
                                                                                    selectedRows,

                                                                                }) => {
                                                                                    // if (selectedCount > 0) {
                                                                                    let array = []
                                                                                    array.push(item[0])


                                                                                    let checkarray = []
                                                                                    let tempArray = selectedRows && selectedRows.length && selectedRows.map((item) => {
                                                                                        return item
                                                                                    })

                                                                                    if (tempArray.length > 0) {
                                                                                        SetSet([...set, ...tempArray])
                                                                                        setTotalRow([...totalrow, ...tempArray])


                                                                                    }
                                                                                    if (tempArray.length == 0) {
                                                                                        for (const k in set) {
                                                                                            selectedRows.filter((item) => item.comp_id == set[k].comp_id)
                                                                                            SetSet([...set, ...selectedRows])

                                                                                        }
                                                                                        //   SetSet(checkarray)
                                                                                    }

                                                                                }}
                                                                            /> */}
                                                                                {/* <Table columns={columns} pagination={false} dataSource={item[1]} scroll={{
                                                                                x: 1500,
                                                                            }}
                                                                                size="small"
                                                                                bordered


                                                                            // pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
                                                                            /> */}

                                                                                <Table
                                                                                    size={'small'}
                                                                                    // className='userprivclass'
                                                                                    columns={columns}
                                                                                    dataSource={item.child}
                                                                                    bordered
                                                                                    scroll={{ x: 1270 }}
                                                                                    pagination={false}
                                                                                    onHeaderRow={(columns, index) => {

                                                                                        return {
                                                                                            onClick: (e) => {
                                                                                                let temp = []
                                                                                                for (let j in item.child) {
                                                                                                    temp.push(headerlist && headerlist[item.child[j].parentId] && headerlist[item.child[j].parentId])

                                                                                                }
                                                                                                setHeader([...header, ...temp])
                                                                                                let checked = e.target.checked;
                                                                                                addAllSelectedDataValues(
                                                                                                    checked, item.index, item.parentData, item.child
                                                                                                );
                                                                                            }

                                                                                        };
                                                                                    }}


                                                                                />
                                                                            </div>
                                                                            <br />
                                                                        </div>
                                                                    </div>

                                                                </Panel>
                                                            </Collapse>
                                                        )

                                                    })
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        {/* </Card> */}
                                        <div className='col-md-12' style={{ marginTop: "20px" }}>
                                            <div className='row'>

                                                <div className='col-md-6' >
                                                    <label style={{ marginLeft: "28px" }}>Showing 1 to <span>{entires}</span> of {unitlist.heading.r_count} entries</label>
                                                </div>
                                                {/* <div className='col-md-3'></div> */}
                                                <div className='col-md-3'></div>
                                                <div className='col-md-3'>
                                                    <label style={{ marginLeft: "28px" }}>Selected Compliance :<span style={{ marginLeft: "5px" }}>{temparr.length}</span></label>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='col-md-12' style={{ marginTop: '10px' }}>
                                            <div className='row'>

                                                <div className='col-md-4'>
                                                    {/* <button className="btn btn-info" style={{ marginLeft: "25px", width: "100px" }} onClick={() => { setKey("1") }}>Previous</button> */}
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "25px" }}
                                                        icon={<ArrowLeftOutlined />} size='default'
                                                        onClick={() => {
                                                            setKey("1")
                                                            setTemparr([])
                                                            let checked = false
                                                            let comp_id
                                                            for (let i in temparr) {
                                                                comp_id = temparr[i].comp_id
                                                            }
                                                            addSelectedDataValues(
                                                                checked,
                                                                comp_id
                                                            )
                                                            var getchildcheck = document.getElementsByClassName('childcheckbox')
                                                            for (var jk = 0; jk < getchildcheck.length; jk++) {
                                                                if (getchildcheck[jk].type === 'checkbox')
                                                                    getchildcheck[jk].checked = false;
                                                            }
                                                        }}>
                                                        Previous
                                                    </Button>
                                                </div>
                                                <div className='col-md-4' style={{ textAlign: 'center' }}>
                                                    {/* {(unitlist && unitlist.heading && unitlist.heading.r_count != entires && entires != 200) || (unitlist.tablelist.length > 0) ?
                                                        <button className="btn btn-success" style={{ float: "center", marginLeft: "90px" }} onClick={showmore} >Show More</button>
                                                        : ""} */}
                                                    {/* {(unitlist && unitlist.heading && unitlist.heading.r_count != entires && entires != 200) || (unitlist.tablelist.length > 0) ? */}
                                                    {(unitlist && unitlist.heading && unitlist.heading.r_count == entires) ?
                                                        ''
                                                        : <Button type="primary" shape="round" className='addbutton' style={{ background: "#39cb7f", borderColor: "#39cb7f", textAlign: 'center' }}
                                                            size='default' onClick={showmore}>
                                                            Show More
                                                        </Button>}
                                                </div>
                                                <div className='col-md-4'>
                                                    {/* <button className="btn btn-info" style={{ float: "right", marginRight: "25px", width: "100px" }} onClick={Next2}>Next</button> */}
                                                    {triggerstate == false ? <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "175px" }}
                                                        size='default' onClick={Next2}>
                                                        Next <i> <ArrowRightOutlined /></i>
                                                    </Button> : <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "175px" }}

                                                        size='default' onClick={() => { toast.error("Trigger before days should not exceed 100") }}>
                                                        Next <i> <ArrowRightOutlined /></i>
                                                    </Button>}


                                                </div>
                                            </div>
                                        </div>
                                        {/* {unitlist && unitlist.heading && unitlist.heading.r_count > 200 ? entires != 200 : unitlist && unitlist.heading && unitlist.heading.r_count != entires ?
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4'>
                                                        <button className="btn btn-success" style={{ float: "center", marginLeft: "50px" }} onClick={showmore} >Show More</button>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                            : ""} */}

                                    </TabPane>
                                    <TabPane tab="Select Assignee" disabled key="3">
                                        <Card>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    {unitlist.restricted && unitlist.restricted.length > 0 ? <>

                                                        <div className='col-md-4' style={{ textAlign: "center" }}>
                                                            <label><b>Assignee </b><span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                        <div className='col-md-4' style={{ textAlign: "center" }}>
                                                            <label><b>Approval </b><span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                        <div className='col-md-4' style={{ textAlign: "center" }}>
                                                            <label><b>View Only Restricted User </b></label>
                                                        </div> </>
                                                        : <> <div className='col-md-6' style={{ textAlign: "center" }}>
                                                            <label><b>Assignee </b><span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                            <div className='col-md-6' style={{ textAlign: "center" }}>
                                                                <label><b>Approval </b><span style={{ color: "red" }}>*</span></label>
                                                            </div></>}
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    {unitlist.restricted && unitlist.restricted.length > 0 ? <>
                                                        <div className='col-md-4'>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Assignee"
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {

                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            assignee_id: value,
                                                                            assignee: data.children
                                                                        })
                                                                        setAssigneeOnclick(true)
                                                                    }
                                                                    else {
                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            assignee_id: '',
                                                                            assignee: ''
                                                                        })
                                                                        setAssigneeOnclick(false)
                                                                    }

                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={clickassignee.assignee || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                <Option key="All">All</Option>

                                                                {assigneedrop && assigneedrop.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <Option key={item.usr_id}>
                                                                                {item.emp_name}
                                                                            </Option>
                                                                        </>

                                                                    );
                                                                })}
                                                            </Select>

                                                        </div>
                                                        <div className='col-md-4'>

                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Approver"
                                                                onChange={(value, data) => {
                                                                    if (data !== undefined) {
                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            approval_id: value,
                                                                            approval: data.children
                                                                        })
                                                                        setApprovalOnclick(true)
                                                                    }
                                                                    else {
                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            approval_id: '',
                                                                            approval: ''
                                                                        })
                                                                        setAssigneeOnclick(false)

                                                                    }
                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={clickassignee.approval || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                <Option key="All">All</Option>
                                                                {approvaldrop && approvaldrop.map((item, i) => {

                                                                    // {/* {unitlist && unitlist.assign_user && unitlist.assign_user.map((item, i) => { */}
                                                                    return (


                                                                        <Option key={item.usr_id}>
                                                                            {item.emp_name}
                                                                        </Option>
                                                                        // :""
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <div className='col-md-4'></div> </> :
                                                        <><div className='col-md-6'>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Assignee"
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {
                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            assignee_id: value,
                                                                            assignee: data.children
                                                                        })
                                                                        setAssigneeOnclick(true)
                                                                    }
                                                                    else {
                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            assignee_id: '',
                                                                            assignee: ''
                                                                        })
                                                                        setAssigneeOnclick(false)
                                                                    }
                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={clickassignee.assignee || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                <Option key="All">All</Option>

                                                                {assigneedrop && assigneedrop.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <Option key={item.usr_id}>
                                                                                {item.emp_name}
                                                                            </Option>
                                                                        </>

                                                                    );
                                                                })}
                                                            </Select>

                                                        </div>
                                                            <div className='col-md-6'>

                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Select Approver"
                                                                    onChange={(value, data) => {

                                                                        if (value !== undefined) {
                                                                            setClickassginee({
                                                                                ...clickassignee,
                                                                                approval_id: value,
                                                                                approval: data.children
                                                                            })
                                                                            setApprovalOnclick(true)
                                                                        }
                                                                        else {
                                                                            setClickassginee({
                                                                                ...clickassignee,
                                                                                approval_id: '',
                                                                                approval: ''
                                                                            })
                                                                            setApprovalOnclick(false)
                                                                        }
                                                                    }}
                                                                    // onSearch={onSearch}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={clickassignee.approval || undefined}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <Option key="All">All</Option>
                                                                    {approvaldrop && approvaldrop.map((item, i) => {
                                                                        // {/* {unitlist && unitlist.assign_user && unitlist.assign_user.map((item, i) => { */}
                                                                        return (


                                                                            <Option key={item.usr_id}>
                                                                                {item.emp_name}
                                                                            </Option>
                                                                            // :""
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </div></>}
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "20px", marginBottom: "20px" }}>
                                                <div className='row'>
                                                    {unitlist.restricted && unitlist.restricted.length > 0 ? <>

                                                        <div className='col-md-4'>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Assignee"
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {

                                                                        var merged = [].concat.apply([], data.children);
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            assign_id: value,
                                                                            assignee: data.children[2]
                                                                        })
                                                                    }
                                                                    else {
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            assign_id: '',
                                                                            assignee: ''
                                                                        })
                                                                    }

                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneelist.assignee || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                {assigneesearch3 && assigneesearch3.map((item, i) => {


                                                                    return (
                                                                        // data.unit ?
                                                                        clickassignee.assignee ?
                                                                            <Option key={item.emp_code}>
                                                                                {item.emp_code} - {item.emp_name}
                                                                            </Option> : ""
                                                                        // :""
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator1.current.message(
                                                                'Assignee',
                                                                assigneelist.assign_id,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Assignee Required',
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Approver"
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            approval_id: value,
                                                                            approval: data.children
                                                                        })
                                                                    }
                                                                    else {
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            approval_id: '',
                                                                            approval: ''
                                                                        })
                                                                    }
                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneelist.approval || undefined}
                                                                style={{ width: "100%" }}
                                                            >

                                                                {approvalsearch3 && approvalsearch3.map((item, i) => {
                                                                    return (
                                                                        // data.unit ?
                                                                        clickassignee.approval ?
                                                                            item.emp_code != null ?
                                                                                <Option key={item.emp_code}>
                                                                                    {item.emp_code} - {item.emp_name}
                                                                                </Option>
                                                                                :
                                                                                <Option key={item.emp_code}>
                                                                                    {item.emp_name}
                                                                                </Option>
                                                                            : ""
                                                                        // :""
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator1.current.message(
                                                                'Approval',
                                                                assigneelist.approval,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Approval Required',
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Enter View only restricted only"
                                                                onChange={(value) => {

                                                                    setData({
                                                                        ...data1,
                                                                        vor_id: value,

                                                                    })
                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={data1.vor_id || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                {unitlist.restricted && unitlist.restricted.length && unitlist.restricted.map((item, i) => {
                                                                    return (
                                                                        // data.unit ?
                                                                        <Option key={item.usr_id}>
                                                                            {item.emp_code} - {item.emp_name}
                                                                        </Option>
                                                                        // :""
                                                                    );
                                                                })}
                                                            </Select>

                                                        </div> </> :
                                                        <><div className='col-md-6'>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Assignee"
                                                                onChange={(value, data) => {

                                                                    if (value != undefined) {
                                                                        var merged = [].concat.apply([], data.children);
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            assign_id: value,
                                                                            assignee: data.children[2]
                                                                        })
                                                                    }
                                                                    else {
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            assign_id: '',
                                                                            assignee: ''
                                                                        })
                                                                    }
                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneelist.assignee || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                {assigneesearch3 && assigneesearch3.map((item, i) => {


                                                                    return (
                                                                        // data.unit ?
                                                                        clickassignee.assignee ?
                                                                            <Option key={item.emp_code}>
                                                                                {item.emp_code} - {item.emp_name}
                                                                            </Option> : ""
                                                                        // :""
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator1.current.message(
                                                                'Assignee',
                                                                assigneelist.assign_id,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Assignee Required',
                                                                    }
                                                                })}
                                                        </div>
                                                            <div className='col-md-6'>
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Approver"
                                                                    onChange={(value, data) => {
                                                                        if (value !== undefined) {
                                                                            setAssigneelist({
                                                                                ...assigneelist,
                                                                                approval_id: value,
                                                                                approval: data.children
                                                                            })
                                                                        }
                                                                        else {
                                                                            setAssigneelist({
                                                                                ...assigneelist,
                                                                                assign_id: '',
                                                                                assignee: ''
                                                                            })
                                                                        }
                                                                    }}
                                                                    // onSearch={onSearch}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={assigneelist.assign_id || undefined}
                                                                    style={{ width: "100%" }}
                                                                >

                                                                    {approvalsearch3 && approvalsearch3.map((item, i) => {
                                                                        return (
                                                                            // data.unit ?
                                                                            clickassignee.approval ?
                                                                                item.emp_code != null ?
                                                                                    <Option key={item.emp_code}>
                                                                                        {item.emp_code} - {item.emp_name}
                                                                                    </Option>
                                                                                    :
                                                                                    <Option key={item.emp_code}>
                                                                                        {item.emp_name}
                                                                                    </Option>
                                                                                : ""
                                                                            // :""
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator1.current.message(
                                                                    'Approval',
                                                                    assigneelist.approval,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Approval Required',
                                                                        }
                                                                    })}
                                                            </div></>}
                                                </div>
                                            </div>
                                        </Card>
                                        <div className='col-md-12' style={{ marginTop: '10px' }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={() => { setKey("2") }}>Previous</button> */}
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'left', marginRight: "10px" }}
                                                        icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                                            setKey("2")
                                                            setAddFormSubmit1(false)
                                                            setData({
                                                                ...data1,
                                                                vor_id: '',

                                                            })
                                                            setAssigneelist({
                                                                assign_id: null,
                                                                approval_id: null,
                                                                assignee: "",
                                                                approval: "",
                                                                restricted: "",
                                                                vor_id: ''
                                                            })
                                                            setClickassginee({
                                                                assignee: "",
                                                                approval: "",
                                                                approval_id: null,
                                                                assignee_id: null
                                                            })
                                                        }}>
                                                        Previous
                                                    </Button>
                                                </div>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4'>
                                                    {/* <button className="btn btn-success" style={{ width: "100px" }} onClick={Submit}>Submit</button> */}
                                                    <Button type="primary" shape="round" className='addbutton'
                                                        style={{ background: "#198754", borderColor: "#198754", float: 'right' }}
                                                        icon={<PlayCircleOutlined />} size='default' onClick={Submit}>
                                                        Submit
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Applicable Vendors" visible={isModalVisible} onCancel={handleCancel} footer={null}>
                {uniaddress && uniaddress.map((item, i) => {
                    return (
                        <>
                            <p>{unitnamemodal[i]}-{item}</p>
                        </>
                    )
                })}
            </Modal>
        </div >

    )
}
export default connect(mapStateToProps, {
    assign,
    selectunit,
    table,
    tabledata,
    showmoredata,
    selectassigneedata,
    saveassigncomliance,
    syncassigncomliance,

})(Compliance);
