import React, { Fragment } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Collapse, Select, Input, DatePicker, Card, Modal, Button, Table, Tooltip, Pagination } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { LoginTrialReportFilters, LoginTraceReportData, ExportLoginTraceReportData } from '../../../Store/Action/Report/LoginTrace';
import { LOGIN_TRIAL_REPORT_EXPORT_RESET } from '../../../Store/types/index'
const mapStateToProps = (state) => ({
    logintrialreports: state.LoginTrace.logintracereport,
})

const LoginTrace = ({
    LoginTrialReportFilters,
    LoginTraceReportData,
    ExportLoginTraceReportData,
    logintrialreports: { logintracefilter, logintraceshow, logintraceexport }
}) => {

    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid').replaceAll('"', ''));
    const paramid = location.pathname;
    const _ = require("lodash");
    const dispatch = useDispatch();
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    let [count, setcount] = useState(0)
    const [totalcount, setTotalcount] = useState("")
    const [key, setKey] = useState("1")
    const [legalstate, Setlegalstate] = useState(false)
    const [unitdata, setUnitData] = useState({
        user: 0,
        user_name: '',
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        from_date: "",
        to_date: "",
        time_zone_id: '',
        time_zone_name: '',
        min_date: ''
    })
    console.log(unitdata, 'unitdata');
    const [disableButton, setDisableButton] = useState(false)

    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;

    const disablecondition = (e) => {
        var currentToDate = moment(unitdata.from_date).add(3, 'months')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                if (entityid != "null") {
                    LoginTrialReportFilters({
                        payload: [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetLogintraceReportFilters",
                                    {
                                        "legal_entity_id": entityid
                                    }
                                ]
                            }
                        ],
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }
    }, [isAuth, entityid, legalstate])

    console.log(logintracefilter,'logintracefilter');

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {

        if (logintraceshow && logintraceshow.total_count > 0) {

            setTotalcount(logintraceshow.total_count)
        }

    }, [logintraceshow])


    useEffect(() => {
        if (filteredArray.length) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (pageState === true) {
            console.log(pageSize,'pageSize');
            console.log(current,'current');
            setcount(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        if (entityid != null) {
            setUnitData({
                ...unitdata,
                country: sessionArr[0].c_id
            })
        }
    }, [entityid != null])
    useEffect(() => {

        if (logintraceexport && logintraceexport.link) {

            window.open('https://13.234.100.64/' + logintraceexport.link);

            dispatch({

                type: LOGIN_TRIAL_REPORT_EXPORT_RESET
            });
        }
    }, [logintraceexport])

    useEffect(() => {
        if (unitdata.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [unitdata.legal_entity_name])
    useEffect(() => {
        if (logintraceexport && logintraceexport.link) {
            window.open('https://13.234.100.64/' + logintraceexport.link);
        }

    }, [logintraceexport])

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            LoginTraceReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetLoginTraceReportData",
                            {
                                "user_id": Number(unitdata.user),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": false,
                                "from_count": 0,
                                "page_count": 25,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'
                            }
                        ]
                    }
                ],
                paramid: paramid
            })

        }
    }
    useEffect(() => {
        if (pageState == true) {
            LoginTraceReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetLoginTraceReportData",
                            {
                                "user_id": Number(unitdata.user),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": false,
                                "from_count": Math.max(((current - 1) * pageSize), 0),
                                "page_count": pageSize,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])
    const onexport = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            setKey(0)
            ExportLoginTraceReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetLoginTraceReportData",
                            {
                                "user_id": Number(unitdata.user),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": true,
                                "from_count": 0,
                                "page_count": 0,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'
                            }
                        ]
                    }
                ],
                paramid: paramid
            })

        }
    }

    const onChange = (key) => {

    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-0 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Log Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Login Trace</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" id='only-restricted' style={{ position: 'relative', padding: "10px 3px" }}>
                            <Collapse defaultActiveKey={["1"]} onChange={onChange} className='report-collapse' style={{ marginLeft: '10%', marginRight: '10%', backgroundColor: '#5daae3' }}>
                                <Panel header="Login Trace" key={key}>
                                    {/* <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ float: 'right' }}><b>From Date :  </b><span style={{ color: "red" }} >*</span></label></div>
                                            <div className='col-md-3'>

                                                <DatePicker
                                                    format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                    value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                    allowClear={false}
                                                    style={{ width: '100%' }}
                                                    disabledDate={(current) => {
                                                        return current && current.valueOf() > Date.now();
                                                    }}
                                                    onChange={(date, dateString) => {

                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: moment(new Date(dateString)).format('DD-MMM-YYYY'),
                                                            min_date: dateString
                                                        })
                                                    }}
                                                />

                                                {validator.current.message(
                                                    'from_date',
                                                    unitdata.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-3'>
                                                <label style={{ float: 'right' }}><b>User : </b></label>
                                            </div>
                                            <div className='col-md-3'>


                                                <Select
                                                    allowClear={false}
                                                    value={unitdata.user_name || undefined}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            user: value,
                                                            user_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter User"
                                                    style={{ width: '100%' }}
                                                >
                                                    {logintracefilter && logintracefilter.audit_users_list && logintracefilter.audit_users_list.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_id}>
                                                                {item.user_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>



                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label style={{ float: 'right' }}><b>To Date : </b> <span style={{ color: "red" }}>*</span></label>
                                            </div>
                                            <div className='col-md-3'>

                                                <DatePicker
                                                    allowClear={false}
                                                    format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                    disabledDate={(current) => {
                                                        return moment(unitdata.from_date) >= current || current > Date.now();
                                                    }}
                                                    value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                    style={{ width: '100%' }}
                                                    onChange={(date, dateString) => {
                                                        disablecondition(dateString)
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: moment(new Date(dateString)).format('DD-MMM-YYYY'),

                                                        })
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'to_date',
                                                    unitdata.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required',
                                                        }
                                                    })}
                                            </div>

                                            <div className='col-md-3'>
                                                <label style={{ float: 'right' }}><b>Display in Time Zone : </b></label>
                                            </div>
                                            <div className='col-md-3'>


                                                <Select
                                                    allowClear={false}
                                                    placeholder='Enter Time Zone'
                                                    value={unitdata.time_zone_name || undefined}
                                                    // defaultValue={"All"}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            time_zone_id: value,
                                                            time_zone_name: data.children
                                                        })
                                                    }}
                                                    style={{ width: '100%' }}
                                                >
                                                    {logintracefilter && logintracefilter.tz_list && logintracefilter.tz_list.map((item, i) => {
                                                        return (
                                                            <Option key={item.tz_id}>
                                                                {item.tz_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>From Date</b><span style={{ color: "red" }} >*</span></label>
                                                        <DatePicker

                                                            format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                            value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                            allowClear={true}
                                                            style={{ width: '100%' }}
                                                            disabledDate={(current) => {
                                                                return current && current.valueOf() > Date.now();
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                console.log(date, 'dateString');
                                                                if (date != null) {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        from_date: moment(new Date(dateString)).format('DD-MMM-YYYY'),
                                                                        min_date: dateString
                                                                    })
                                                                }
                                                                else {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        from_date: '',
                                                                        min_date: '',
                                                                    })
                                                                }
                                                            }
                                                            }

                                                        />

                                                        {validator.current.message(
                                                            'from_date',
                                                            unitdata.from_date,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'From Date Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>To Date</b><span style={{ color: "red" }} >*</span></label>
                                                    <div className="form-group">
                                                        <DatePicker
                                                            allowClear={true}
                                                            format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                            disabledDate={(current) => {
                                                                return moment(unitdata.from_date) >= current || current > Date.now();
                                                            }}
                                                            value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                            style={{ width: '100%' }}
                                                            onChange={(date, dateString) => {
                                                                if(date!==null){
                                                                disablecondition(dateString)
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    to_date: moment(new Date(dateString)).format('DD-MMM-YYYY'),

                                                                })
                                                            }
                                                            else{
                                                               
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    to_date: '',

                                                                })
                                                            }
                                                            }}
                                                        />
                                                        {validator.current.message(
                                                            'to_date',
                                                            unitdata.to_date,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'To Date Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-1">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor=""><b>User</b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                value={unitdata.user_name || undefined}
                                                                onChange={(value, data) => {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        user: value,
                                                                        user_name: data.children
                                                                    })
                                                                }}
                                                                placeholder="Enter User"
                                                                style={{ width: '100%' }}
                                                            >
                                                                {logintracefilter && logintracefilter.audit_users_list && logintracefilter.audit_users_list.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.user_id}>
                                                                            {item.user_name}
                                                                        </Option>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor=""><b>Display in Time Zone :</b></label>
                                                        <Select
                                                            allowClear={false}
                                                            placeholder='Enter Time Zone'
                                                            value={unitdata.time_zone_name || undefined}
                                                            // defaultValue={"All"}
                                                            onChange={(value, data) => {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    time_zone_id: value,
                                                                    time_zone_name: data.children
                                                                })
                                                            }}
                                                            style={{ width: '100%' }}
                                                        >
                                                            {logintracefilter && logintracefilter.tz_list && logintracefilter.tz_list.map((item, i) => {
                                                                return (
                                                                    <Option key={item.tz_id}>
                                                                        {item.tz_name}
                                                                    </Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <Button disabled={disableButton} type="primary" shape="round" className='addbutton'
                                                    style={{ marginLeft: "23%", marginRight: "10px" }}
                                                    onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button title='' type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#32a852", borderColor: "#32a852" }}
                                                    onClick={onexport}
                                                >
                                                    Export
                                                </Button>
                                                {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <br />
                            {tableshow == true ?
                                <>
                                    <Card style={{ marginTop: '0.2%', marginLeft: '10%', marginRight: '10%' }}>
                                        <div className='col-md-12' >
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>From Date :</b> {unitdata.from_date}</label>
                                                </div>

                                                <div className='col-md-4' style={{ marginLeft: '7%' }}>
                                                    <label><b>To Date :</b> {unitdata.to_date}</label>
                                                </div>
                                                <div className='col-md-2'>
                                                </div>

                                            </div>
                                        </div>
                                        <br />
                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content userprivclass1'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className="ant-table-thead antable-head">
                                                                <tr>
                                                                    <th className='ant-table-cell' style={{ width: '10px' }}> # </th>
                                                                    <th className='ant-table-cell' style={{ width: '200px' }}>Date & Time</th>
                                                                    <th className='ant-table-cell' style={{ width: '220px' }}>Info</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                {logintraceshow && logintraceshow.log_trace_activities && logintraceshow.log_trace_activities.map((itemdata, i) => {

                                                                    return <Fragment>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>{count + 1}</center></td>
                                                                            <td className='ant-table-cell'>{itemdata.created_on}</td>
                                                                            <td className='ant-table-cell'>{itemdata.action}</td>
                                                                            <span hidden>{count = count + 1}</span>
                                                                        </tr>
                                                                    </Fragment>
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    {count != 0 ?
                                        <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                            <label style={{ marginLeft: "11%" }}>Showing {pageSize * (current - 1) + 1} to {count} of {Number(totalcount)} entries</label>
                                        </div> : ''}
                                    <div
                                        style={{ position: 'absolute', marginRight: '11%', bottom: '-25px', right: 0 }}
                                    >

                                        <Pagination
                                            current={current}
                                            showSizeChanger={true}
                                            showQuickJumper={false}
                                            onShowSizeChange={() => {
                                                setCurrent(1)

                                            }}
                                            pageSizeOptions={[25, 50, 100]} hideOnSinglePage={Number(totalcount) > 25 ? false : true} defaultPageSize={25}
                                            onChange={(page, pageSizes) => {
                                                setpageState(true)
                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                setPageSize(pageSizes)
                                            }} total={Number(totalcount)}
                                        // showTotal={(total) => `Showing 1 to ${count} of ${Number(totalcount)} entries`}
                                        />


                                    </div>


                                </> : ''
                                // <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                            }
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}
export default connect(mapStateToProps, {
    LoginTrialReportFilters,
    LoginTraceReportData,
    ExportLoginTraceReportData,
})(LoginTrace);