import {TASKDISTRIBUTION_CHART,COMMON_DATA, TOGGLE_COMMON_LOADER, TASK_DISTRIBUTION_TABLE,TIME_ZONE_DATA , SHOW_MORE_TABLE_DATA } from '../../types/index'
const initialState = {
    taskDistribution: {
       chartData:[],
       time:0,
       Data:[],
       tableData:[],
       timeZoneData:[],
       showMoreData:[]

       
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TASKDISTRIBUTION_CHART: {
            console.log(payload,"payload4444");
            return {
                ...state,
                taskDistribution: {
                    ...state.taskDistribution,
                    chartData: payload.tsk_distribution_chart_data_nu,
                    time:payload.current_date
                },
            };
        }
        case COMMON_DATA: {
            console.log(payload,"payload4444");
            return {
                ...state,
                taskDistribution: {
                    ...state.taskDistribution,
                    Data: payload,
                },
            };
        }
        case TASK_DISTRIBUTION_TABLE: {
            console.log(payload,"payload5555988452");
            return {
                ...state,
                taskDistribution: {
                    ...state.taskDistribution,
                    tableData: payload
                },
            };
        }
        case TIME_ZONE_DATA:{
            return {
                ...state,
                taskDistribution: {
                    ...state.taskDistribution,
                    timeZoneData: payload,
                },
            };
        }
        case SHOW_MORE_TABLE_DATA:{
            return {
                ...state,
                taskDistribution: {
                    ...state.taskDistribution,
                    showMoreData: payload,
                },
            };
        }
        default:
            return state;
    }
}
