import { ASSIGNEE_WISE_COMPLIANCE, ASSIGNEE_WISE_COMPLIANCE_EXPORT, ASSIGNEE_WISE_COMPLIANCE_EXPORT_RESET, ASSIGNEE_WISE_COMPLIANCE_SHOW, ASSIGNEE_YEAR_WISE_COMPLIANCE, ASSIGNEE_WISE_COMPLIANCE_DRILL_DOWN } from './../../types/index'
const initialState = {
    assigneewisecompliance: {
        assigneewisecompliancefilters: [],
        assigneewisecomplianceshow: [],
        assigneewisecompliancedrilldown: [],
        assigneeyearwisecompliance: [],
        assigneewisecomplianceexport: []

    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ASSIGNEE_WISE_COMPLIANCE:
            {
                return {
                    ...state,
                    assigneewisecompliance: {
                        ...state.assigneewisecompliance,
                        assigneewisecompliancefilters: payload,
                    },
                };
            }
        case ASSIGNEE_WISE_COMPLIANCE_SHOW:
            {
                return {
                    ...state,
                    assigneewisecompliance: {
                        ...state.assigneewisecompliance,
                        assigneewisecomplianceshow: payload,
                    },
                };
            }
        case ASSIGNEE_WISE_COMPLIANCE_DRILL_DOWN:
            {
                return {
                    ...state,
                    assigneewisecompliance: {
                        ...state.assigneewisecompliance,
                        assigneewisecompliancedrilldown: payload,
                    },
                };
            }
        case ASSIGNEE_YEAR_WISE_COMPLIANCE:
            {
                return {
                    ...state,
                    assigneewisecompliance: {
                        ...state.assigneewisecompliance,
                        assigneeyearwisecompliance: payload,
                    },
                };
            }
        case ASSIGNEE_WISE_COMPLIANCE_EXPORT:
            {
                return {
                    ...state,
                    assigneewisecompliance: {
                        ...state.assigneewisecompliance,
                        assigneewisecomplianceexport: payload,
                    },
                };
            }
        case ASSIGNEE_WISE_COMPLIANCE_EXPORT_RESET:
            {
                return {
                    ...state,
                    assigneewisecompliance: {
                        ...state.assigneewisecompliance,
                        assigneewisecomplianceexport: [],
                    },
                };
            }

        default:
            return state;
    }
}