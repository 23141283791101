import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Row, Tooltip, Card, Select, Tree, Button, Checkbox } from 'antd';
import { PlayCircleOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import { getViewOnlyRestrictedList, getViewOnlyRestUnitData } from '../../Store/Action/Master/ViewOnlyRestricted';
import { GetGroupListData, FetchEmails, emailsave, verifypassword, updateblockoption } from '../../Store/Action/Master/CV_CertificateMailMapping';
import { constant, filter, indexOf, merge } from 'lodash';
import { Toaster } from '../../Libs/Toaster';
import { toast } from 'react-toastify';
const _ = require("lodash");

const mapStateToProps = (state) => ({
    viewOnlyRestList: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestList,
    viewOnlyRestUnitList: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestUnitList,
    viewOnlyRestCompDetails: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestCompDetails,
    access_info: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.access_info,
    Mail: state.CertificateMailMapping.mailmapping
})

const CertificateMailMapping = ({
    viewOnlyRestList,
    GetGroupListData,
    viewOnlyRestUnitList,
    FetchEmails,
    viewOnlyRestCompDetails,
    emailsave,
    access_info,
    verifypassword,
    updateblockoption,
    Mail: { Mailmapping, Mailmappingshow, mailsave, passwordresult, updaterecord }
}) => {
    const { Panel } = Collapse;
    const { Option } = Select;
    const { Search } = Input;
    const location = useLocation();
    const [show, setShow] = useState(false)
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const paramid = location.pathname;
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const validator2 = useRef(new SimpleReactValidator());
    const [indexValue, setIndexValue] = useState({
        index: '',
        value: ''
    })
    const [password, Setpassword] = useState([])
    const [userId, setUserId] = useState('')
    const [legalentity, setLegalentity] = useState()
    const [mailmapping, setMailMapping] = useState({
        mail: [],
        address: ''
    });
    const [finalmapping, setFinalMapping] = useState({
        To_email: [],
        cc_email: []
    });
    const [mail, setMail] = useState([]);
    const [blockmodal, Setblockmodal] = useState(false);
    const [emailvalue, setEmailvalue] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') == null || localStorage.getItem('SelectedEntityid') == 'null') {

        } else {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            if (localStorage.getItem('SelectedEntityid')) {
                let mail = _.filter(Mailmapping, { legal_entity_id: localStorage.getItem('SelectedEntityid') })
                setMail(mail)
                setLegalentity(localStorage.getItem('SelectedEntityid'))
            }
        }
    }, [localStorage.getItem('SelectedEntityid'), Mailmapping])

    const passwordcancel = () => {
        Setblockmodal(false)
    }

    useEffect(() => {
        if (passwordresult == "PasswordValidSuccess") {
            if (indexValue.value == 0) {
                let array = emailvalue
                let temp = []
                for (let j in array) {
                    if (indexValue.index == j) {
                        temp.push({
                            Email: array[j].Email,
                            address: array[j].address,
                            value: "0"
                        })
                    } else {
                        temp.push(array[j])
                    }
                }
                setEmailvalue([...temp])
                updateRecords(temp)
            } else {
                let array = emailvalue
                let temp = []
                for (let j in array) {
                    if (indexValue.index == j) {
                        temp.push({
                            Email: array[j].Email,
                            address: array[j].address,
                            value: "1"
                        })
                    } else {
                        temp.push(array[j])
                    }
                }
                setEmailvalue([...temp])
                updateRecords(temp)
            }

        }
    }, [passwordresult])

    useEffect(() => {
        if (updaterecord == 'SaveCertificationMaiLDataSuccess') {
            if (indexValue.value == 0) {
                Toaster.success('Deactivated Successfully');
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);
            } else {
                Toaster.success('Activated Successfully');
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);
            }
        }
    }, [updaterecord])

    useEffect(() => {
        if (Mailmappingshow && Mailmappingshow.email_list && Mailmappingshow.email_list.length > 0) {
            if (Mailmappingshow.email_list[0].mail_cc != null && Mailmappingshow.email_list[0].mail_to != null) {
                const mailcc = JSON.parse(Mailmappingshow.email_list[0].mail_cc);
                const mailto = JSON.parse(Mailmappingshow.email_list[0].mail_to);
                let tempto
                let tempcc = []
                let tempto1
                let tempcc1
                let temptofinal = []
                let tempccfinal = []
                let tempccvalue = []
                let temptovalue = []
                if (mailto && mailto[0] && mailto[0].To_email.includes(",")) {
                    tempto = mailto[0].To_email.split(',')
                    for (let i in tempto) {
                        tempto1 = tempto[i].split('|')
                        temptofinal.push(tempto1[0])
                        temptovalue.push(tempto1[1])
                    }
                } else {
                    tempto = [mailto[0].To_email]
                    tempto1 = tempto[0].split('|')
                    temptofinal.push(tempto1[0])
                    temptovalue.push(tempto1[1])
                }
                if (mailcc && mailcc[0] && mailcc[0].cc_email.includes(",")) {
                    tempcc = mailcc[0].cc_email.split(',')
                    for (let i in tempcc) {
                        tempcc1 = tempcc[i].split('|')
                        tempccfinal.push(tempcc1[0])
                        tempccvalue.push(tempcc1[1])
                    }
                } else {
                    tempcc = [mailcc[0].cc_email]
                    tempcc1 = tempcc[0].split('|')
                    tempccfinal.push(tempcc1[0])
                    tempccvalue.push(tempcc1[1])

                }
                let array = []
                let entityArray = []
                for (let i in temptofinal) {
                    entityArray = {
                        'Email': temptofinal[i],
                        'address': "TO",
                        "value": temptovalue[i]
                    }
                    array.push(entityArray)
                }
                for (let i in tempccfinal) {
                    entityArray = {
                        'Email': tempccfinal[i],
                        'address': "CC",
                        "value": tempccvalue[i]
                    }
                    array.push(entityArray)
                }
                setEmailvalue(array)
            } else if (Mailmappingshow.email_list[0].mail_cc == null && Mailmappingshow.email_list[0].mail_to == null) {
                setEmailvalue()
            } else if (Mailmappingshow.email_list[0].mail_cc == null && Mailmappingshow.email_list[0].mail_to != null) {
                const mailto = JSON.parse(Mailmappingshow.email_list[0].mail_to);
                let tempto
                let tempto1
                let temptofinal = []
                let temptovalue = []
                if (mailto[0].To_email.includes(",")) {
                    tempto = mailto[0].To_email.split(',')
                    for (let i in tempto) {
                        tempto1 = tempto[i].split('|')
                        temptofinal.push(tempto1[0])
                        temptovalue.push(tempto1[1])
                    }
                } else {
                    tempto = [mailto[0].To_email]
                    tempto1 = tempto[0].split('|')
                    temptofinal.push(tempto1[0])
                    temptovalue.push(tempto1[1])
                }
                let array = []
                let entityArray = []
                for (let i in temptofinal) {
                    entityArray = {
                        'Email': temptofinal[i],
                        'address': "TO",
                        'value': temptovalue[i]
                    }
                    array.push(entityArray)
                }
                setEmailvalue(array)

            }
        }
    }, [Mailmappingshow])

    const saveRecords = (e) => {
        setAddFormSubmit1(true)
        setAddFormSubmit2(true)
        // emailvalue
        let tempto = []
        let tempcc = []
        let myJSONcc
        let myJSONto
        if (emailvalue) {
            for (let i in emailvalue) {
                if (emailvalue[i].address == 'TO') {
                    tempto.push(emailvalue[i].Email + "|" + emailvalue[i].value)
                } else {
                    if (emailvalue[i].address == 'CC') {
                        tempcc.push(emailvalue[i].Email + "|" + emailvalue[i].value)
                    }
                }
            }

            const arrto = {
                To_email: tempto.toString()
            }
            let arrtofinal = [arrto]
            myJSONto = JSON.stringify(arrtofinal);
            const arrcc = {
                cc_email: tempcc.toString()
            }
            let arrccfinal = [arrcc]
            myJSONcc = JSON.stringify(arrccfinal);
            setFinalMapping({
                ...finalmapping,
                To_email: myJSONto,
                cc_email: myJSONcc
            })
        }

        const savepayload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetEmailSave",
                    {
                        "legal_entity_id": legalentity,
                        "unit_id": Number(userId),
                        "address_id": 2,
                        "c_email_id_to": myJSONto,
                        "c_email_id_cc": myJSONcc
                    }
                ]
            }
        ]
        if (validator1.current.allValid() && validator2.current.allValid()) {
            emailsave({
                payload: savepayload,
                paramid: paramid,
            })
        }
    }

    const updateRecords = (value) => {
        setAddFormSubmit1(true)
        let tempto = []
        let tempcc = []
        let myJSONcc
        let myJSONto
        if (value) {
            for (let i in value) {
                if (value[i].address == 'TO') {
                    tempto.push(value[i].Email + "|" + value[i].value)
                } else {
                    if (value[i].address == 'CC') {
                        tempcc.push(value[i].Email + "|" + value[i].value)
                    }
                }
            }

            const arrto = {
                To_email: tempto.toString()
            }
            let arrtofinal = [arrto]
            myJSONto = JSON.stringify(arrtofinal);
            const arrcc = {
                cc_email: tempcc.toString()
            }
            let arrccfinal = [arrcc]
            myJSONcc = JSON.stringify(arrccfinal);
            setFinalMapping({
                ...finalmapping,
                To_email: myJSONto,
                cc_email: myJSONcc
            })
        }

        const savepayload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetEmailSave",
                    {
                        "legal_entity_id": legalentity,
                        "unit_id": Number(userId),
                        "address_id": 2,
                        "c_email_id_to": myJSONto,
                        "c_email_id_cc": myJSONcc
                    }
                ]
            }
        ]
        if (validator1.current.allValid() && validator2.current.allValid()) {
            updateblockoption({
                payload: savepayload,
                paramid: paramid,
            })
        }
    }

    const passwordvalid = () => {
        const Verifypassword = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "VerifyPassword",
                    {
                        "legal_entity_id": legalentity,
                        "password": password.passwordvalue
                    }
                ]
            }
        ]
        verifypassword({
            payload: Verifypassword,
            paramid: paramid,
        })

    }

    useEffect(() => {
        if (isAuth && sessionArr) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetGroupListData",
                        {
                            "legal_entity_id": sessionArr && sessionArr[0].le_id
                        }
                    ]
                }
            ]
            GetGroupListData({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth, show])

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()
        validator2.current.showMessages()
    }, [])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Master</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "large", fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Mail Configuration</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className='col-lg-6'>
                                        <label htmlFor=""><b>Legal Entity :</b> <span style={{ "color": "red" }}> *</span></label>
                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                            <div className="form-group">
                                                <Select style={{ marginTop: "5px", width: "100%" }}
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Select Legal Entity"
                                                    onChange={(value, data) => {
                                                        let mail = _.filter(Mailmapping, { legal_entity_id: value })
                                                        setMail(mail)
                                                        setCurrentEntity(value)
                                                        setLegalentity(value)
                                                        setUserId('')
                                                        setShow(false)
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    //  value={modalData.legal_entity}
                                                    display="none"
                                                >
                                                    {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                        return (
                                                            <Option title="" key={item.le_id}>
                                                                {item.le_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'legalentity',
                                                    legalentity,
                                                    ['required'],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Legal Entity Required',
                                                        }
                                                    })}
                                            </div> :
                                            <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                        }
                                    </div>
                                    <div className='col-lg-6'>
                                        <label htmlFor=""><b>Vendor :</b> <span style={{ "color": "red" }}> *</span></label>
                                        <Select style={{ marginTop: "5px" }}
                                            // onMouseEnter={false}
                                            labelInValue={false}
                                            allowClear={false}
                                            onFocus={false}
                                            onSelect={false}
                                            disabled={entityid == null || entityid == 'null' ? true : false}
                                            className='form-control'
                                            placeholder="Select Vendor"
                                            onChange={(data, value) => {
                                                setUserId(data)
                                                setShow(false)
                                                setEmailvalue([])
                                            }}
                                            showSearch
                                            value={userId || undefined}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {mail && mail.length && mail.map((item) => {
                                                return (
                                                    <Option title="" key={item.unit_id}>
                                                        {`${item.unit_code}-${item.unit_name}`}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                        {validator.current.message(
                                            'userId',
                                            userId,
                                            ['required'],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Vendor Required',
                                                }
                                            })}
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ textAlign: "center", marginLeft: "26%" }}>
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <Button
                                                onClick={() => {
                                                    const payload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetFetchEmails",
                                                                {
                                                                    "legal_entity_id": entityid,
                                                                    "unit_id": Number(userId)
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                    setAddFormSubmit(true);
                                                    if (validator.current.allValid()) {
                                                        setShow(true)
                                                        FetchEmails({
                                                            payload: payload,
                                                            paramid: paramid
                                                        })
                                                    }
                                                }}
                                                type="primary"
                                                shape="round"
                                                className='addbutton'
                                                style={{
                                                    marginTop: "20px"
                                                }}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                {show == true ?
                                    <>
                                        <div className="col-lg-12" style={{ marginTop: '15px' }}>
                                            <div className="row">
                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content userprivclass1'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className='ant-table-cell w10px' rowspan="2">#</th>
                                                                            <th className='ant-table-cell w40px' rowspan="2">Email ID</th>
                                                                            <th className='ant-table-cell w20px' rowspan="2"><center>Address</center></th>
                                                                            <th className='ant-table-cell custom-width1' colspan="2">Action</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className='ant-table-cell custom-width1'>Block/Unblock</th>
                                                                            <th className='ant-table-cell custom-width1'>Remove</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td colSpan={3}></td>

                                                                            <td className='text-center border-0'><Button
                                                                                type="primary"
                                                                                shape="round"
                                                                                className='addbutton'
                                                                                style={{
                                                                                    marginTop: "10px"
                                                                                }}
                                                                                onClick={() => {
                                                                                    if (emailvalue && emailvalue.length > 0) {
                                                                                        if (emailvalue[emailvalue.length - 1].Email != '' && emailvalue[emailvalue.length - 1].address != '') {
                                                                                            let temp = emailvalue
                                                                                            temp.push({
                                                                                                Email: '',
                                                                                                address: 'TO',
                                                                                                value: '2'
                                                                                            })
                                                                                            setEmailvalue([...temp])
                                                                                        } else {
                                                                                            Toaster.error('Please Enter Email ID');
                                                                                        }
                                                                                    }else{
                                                                                        let temp = emailvalue
                                                                                        temp.push({
                                                                                            Email: '',
                                                                                            address: 'TO',
                                                                                            value: '2'
                                                                                        })
                                                                                        setEmailvalue([...temp])
                                                                                    }

                                                                                }}
                                                                            >
                                                                                Add
                                                                            </Button></td>
                                                                        </tr>
                                                                    </tbody>
                                                                    {emailvalue && emailvalue.length > 0 ? emailvalue.map((items, i) => {
                                                                        return <>
                                                                            <tbody className='ant-table-tbody'>
                                                                                <tr>
                                                                                    <td className='ant-table-cell'>{ }</td>
                                                                                    <td className='ant-table-cell'><center>{<Input
                                                                                        disabled={items.value == 0 ? true : false}
                                                                                        type='text' id={`${i}`}
                                                                                        placeholder='Enter Email ID'
                                                                                        value={items.Email}
                                                                                        onChange={(e) => {
                                                                                            let array = emailvalue
                                                                                            let temp = []
                                                                                            for (let i in array) {
                                                                                                if (i == e.target.id) {
                                                                                                    temp.push({
                                                                                                        Email: e.target.value,
                                                                                                        address: array[i].address,
                                                                                                        value: array[i].value
                                                                                                    })
                                                                                                } else {
                                                                                                    temp.push(array[i])
                                                                                                }
                                                                                            }
                                                                                            setEmailvalue(temp)
                                                                                        }}
                                                                                    ></Input>
                                                                                    }</center>
                                                                                        {validator1.current.message(
                                                                                            'emailvalue',
                                                                                            emailvalue[i].Email,
                                                                                            ['required', { regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ }],
                                                                                            {
                                                                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Email ID Required',
                                                                                                    regex: 'Enter valid email'
                                                                                                }
                                                                                            })}</td>
                                                                                    <td className='ant-table-cell'><Select
                                                                                        disabled={items.value == 0 ? true : false}
                                                                                        onChange={(value, data) => {
                                                                                            let array = emailvalue
                                                                                            let temp = []
                                                                                            for (let j in array) {
                                                                                                if (j == i) {
                                                                                                    temp.push({
                                                                                                        Email: array[i].Email,
                                                                                                        address: value,
                                                                                                        value: array[i].value
                                                                                                    })
                                                                                                } else {
                                                                                                    temp.push(array[j])
                                                                                                }
                                                                                            }
                                                                                            setEmailvalue(temp)
                                                                                            // setMailMapping({
                                                                                            //     ...mailmapping,
                                                                                            //     address: value
                                                                                            // })
                                                                                            // emailvalue
                                                                                        }}
                                                                                        style={{ width: '100%', marginTop: '5px' }}
                                                                                        value={mailmapping.address ? mailmapping.address : items.address || undefined}
                                                                                    >
                                                                                        {/* {countrydata.map((item, i) => { */}
                                                                                        <Option key={'TO'}>{'TO'}</Option>
                                                                                        <Option key={'CC'}>{'CC'}</Option>
                                                                                        {/* // })} */}
                                                                                    </Select>
                                                                                        {validator2.current.message(
                                                                                            'emailvalue',
                                                                                            emailvalue[i].address,
                                                                                            ['required'],
                                                                                            {
                                                                                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Address Required',
                                                                                                }
                                                                                            })}
                                                                                    </td>

                                                                                    <td className='ant-table-cell'><center>{items.value == 0 ? <StopOutlined style={{ color: "red" }} 
                                                                                    onClick={() => {
                                                                                        Setblockmodal(true)
                                                                                        setIndexValue({
                                                                                            ...indexValue,
                                                                                            index: i,
                                                                                            value: '1'
                                                                                        })
                                                                                    }} /> : items.value == 1 ? <StopOutlined 
                                                                                    onClick={() => {
                                                                                        Setblockmodal(true)
                                                                                        setIndexValue({
                                                                                            ...indexValue,
                                                                                            index: i,
                                                                                            value: '0'
                                                                                        })
                                                                                    }} /> : ''}</center></td>
                                                                                    <td className='ant-table-cell'><center>{<DeleteOutlined onClick={() => {
                                                                                        setAddFormSubmit1(false)
                                                                                        let array = emailvalue
                                                                                        for (let j in array) {
                                                                                            if (i == j) {
                                                                                                array.splice(i, 1);
                                                                                            }
                                                                                        }
                                                                                        setEmailvalue([...array])
                                                                                    }} />}</center></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </>
                                                                    }) : ''}

                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: "20px", textAlign: "center", marginLeft: "26%" }}>
                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <Button type="primary" shape="round" className='addbutton'
                                                        style={{ background: "#198754", borderColor: "#198754" }}
                                                        icon={<PlayCircleOutlined />} size='default'
                                                        onClick={() => { saveRecords() }}>
                                                        Submit
                                                    </Button>
                                                    <Button type="primary" shape="round" className='addbutton'
                                                        style={{ marginLeft: '15px', background: "#198754", borderColor: "#198754" }}
                                                        icon={<PlayCircleOutlined />} size='default'
                                                        onClick={() => { setShow(false) }}>
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title={"Block/Unblock User"} visible={blockmodal} footer={null} closable={false} className="newStyleModalPassword">
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label">Password:</label>
                            <span style={{ "color": "red" }}>*</span>
                            <input
                                style={{ marginTop: "10px" }}
                                type="password"
                                id="password"
                                name='passwordvalue'
                                className="form-control"
                                placeholder="Enter password"
                                onChange={(e) => {
                                    Setpassword({
                                        ...password,
                                        passwordvalue: e.target.value
                                    })
                                }}
                                value={password.passwordvalue || undefined}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '13%', marginTop: "10px" }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={() => { passwordvalid() }}
                    >Submit
                    </Button>
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '20px', marginTop: "10px" }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={() => { passwordcancel() }}
                    >Cancel
                    </Button>
                </div>
            </Modal>
        </div >
    )
}

export default connect(mapStateToProps, {
    getViewOnlyRestrictedList,
    getViewOnlyRestUnitData,
    emailsave,
    GetGroupListData,
    FetchEmails,
    verifypassword,
    updateblockoption
})(CertificateMailMapping)
