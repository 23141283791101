import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';




const PrivateRoute = ({ component: Component,...rest }) => {
    const isAuth = sessionStorage.getItem('isAuthenticated');
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (!isAuth) {
        localStorage.clear();
    }    
    return (
        <Route
            {...rest}   
            render={(props) => (!isAuthenticated ? <Redirect to="/login" /> : <Component {...props} />)}
        />
    );
};



const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(PrivateRoute);
