import React, { useEffect, useState, useRef, Fragment } from 'react'
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Row, Select, Tooltip, Card, Button, Popover, Drawer, Space } from 'antd';
import { PlusOutlined, PlusSquareTwoTone, InfoCircleOutlined, SearchOutlined, FilterFilled, PlayCircleOutlined, PlusCircleOutlined, EyeOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { ApproveTaskRecallUnitList, getApproveTaskRecallList, ApproveTaskRecallSubmit } from './../../Store/Action/Transcations/ApproveTaskRecall'
import DataTable from "react-data-table-component";
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import moment from 'moment/moment';
const mapStateToProps = (state) => ({
    list: state.ApproveTaskRecallCompliance.AssignTaskRecall,
    filterrecall: state.recallfilters.recallfilters
})


const ApproveTaskRecall = ({
    ApproveTaskRecallUnitList,
    getApproveTaskRecallList,
    ApproveTaskRecallSubmit,
    GetRemarksData,
    filterrecall: { recallremarks },
    list
}) => {
    console.log(list, 'list');
    const [open, setOpen] = useState(false);
    // const onClose = () => {
    //     setOpen(false);
    // };
    const style = {
        normal: {
            cursor: "pointer"
        },
        hover: {
            cursor: "pointer",
            color: "blue"
        }
    }
    // const [hover, setHover] = useState(false)

    const location = useLocation();
    const paramid = location.pathname;
    const { Option } = Select;
    let temparray = []
    const { TextArea } = Input;
    let [counts, setcounts] = useState(0)
    const [showdata, Setshowdata] = useState({
        domain: '',
        unit: '',
        frequency: '',
        asignee: '',
        legal_entity: ''
    })
    const [recalldata, Setrecalldata] = useState({
        action: '',
        remarks: ''
    })
    const [finalchild, Setfinalchild] = useState([])

    const [childlist, Setchildlist] = useState([])

    const [entires, setEntires] = useState(0)

    const [temparr, setTemparr] = useState([])

    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const _ = require("lodash");
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [checkedRow, setCheckedRow] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [temp, setTemp] = useState([]);

    const [titleDynamic, setTitleDynamic] = useState([]);

    const [checkedRowData, setCheckedRowData] = useState([{
        "compliance_id": '',
        "compliance_history_id": '',
        "unit_idl": '',
        "download_doc": [],
        "reviewer_doc": [],
        "interm_doc": [],
        "interim_docs_size": '',
        "reviwer_docs_size": '',
        "document_size": '',
        "recall_status": '',
        "recall_id": ''
    }]);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [filteredArray, setFilteredArray] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showValueModal, setShowValueModal] = useState(false)
    const [acccordionTrue, setAccordionTrue] = useState(false);
    const [passwordmodal, Setpasswordmodal] = useState(false);
    const [FormRecall, setFormRecall] = useState(false);
    const [FormShow, setFormShow] = useState(false);
    const formValidator = useRef(new SimpleReactValidator());
    const recallSubmit = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const [isComplainceModalVisible, setIsComplainceModalVisible] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const dataSource = [
        {
            key: '1',
            compliance_task: 'The Contract labour(Regulation Act 1997)The tamilNadu Contact Labour(Regulations)Rule 1975',
            assignee: 'AS121-Assignee',
            compliance_status: 'Compiled',
            due_date: '30-April-2022',
            completion_date: '28-Apri',
        },
    ];
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [remarksvalue, Setremarksvalue] = useState();

    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const [page, setPage] = useState(1);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder='Search'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterFilled
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [entityid, setCurrentEntity] = useState('');

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    useEffect(() => {
        if (temp) {
            let tempARR = []

            for (let i in temp) {
                tempARR.push(temp[i].child)
            }
            Setchildlist(tempARR)
        }
    }, [temp])
    useEffect(() => {
        if (finalchild && finalchild.length > 0) {

            setEntires(finalchild.length)
        }
    }, [finalchild])
    useEffect(() => {
        let tempArr = []
        for (let k in childlist) {

            tempArr.push(childlist[k])
            Setfinalchild(tempArr)

        }

    }, [childlist])
    const columns = [

        {
            title: "#",
            render: (row, index, record) => {
                console.log(record, "recordapprove");

                return (
                    <>
                        <p>{(counts + 1)}</p>
                        <span hidden>{counts = counts + 1}</span>
                        {record && record.compliance_frequency == 'Review' ?
                            <Tooltip title={record.compliance_frequency}><p><i style={{ color: "orange" }} class="bi bi-square-fill"></i></p></Tooltip> :
                            <Tooltip title={record.compliance_frequency}><p><i style={{ color: "green" }} class="bi bi-square-fill"></i></p></Tooltip>
                        }
                    </>
                )
            },
            width: '10px',
            sortable: true,
            align: 'center',
            reorder: true,
            filters: [
                {
                    text: <span><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "green" }} className="bi bi-square-fill"></i> Periodical</span>,
                    value: 'Periodical',
                },
                {
                    text: <span><i style={{ color: "#F78453" }} className="bi bi-square-fill"></i> Flexi Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "#FAF329" }} className="bi bi-square-fill"></i> On Occurance</span>,
                    value: 'On Occurrence',
                },
                {
                    text: <span><i style={{ color: "#F21625" }} className="bi bi-square-fill"></i> One Time</span>,
                    value: 'One Time',
                },
            ],
            onFilter: (value, record) => record.compliance_frequency == value,
        },
        {
            title: <Fragment> <span>
                <input type="checkbox" title='Click here to approve all' name="allchecked" /></span>
            </Fragment>,
            dataIndex: false,
            key: false,
            // ellipsis: true,
            width: '10px',
            align: 'center',
            render: (text, record) => {
                return (
                    <Fragment>
                        <input type="checkbox" title='Click here to Task Recall'
                            name={'checked' + record.parentId}
                            onClick={(e) => {
                                let checked = e.target.checked;
                                addSelectedDataValues(
                                    checked,
                                    record.compliance_history_id,
                                );
                            }}
                        />
                    </Fragment>
                )
            }
        },
        {
            title: "Compliance Task",
            ...getColumnSearchProps('compliance_name'),

            // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
            render: (row, record) => {

                const content = (
                    <table style={{ width: "600px", display: 'block', height: '250px', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Unit Name</th>
                                <td>{record.unit_name}</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>{record.address}</td>
                            </tr>
                            <tr>
                                <th>Division</th>
                                <td>{record.division_name}</td>
                            </tr>
                            <tr>
                                <th>Category</th>
                                <td>{record.category_name}</td>
                            </tr>
                            <tr>
                                <th>Act/Rules</th>
                                <td>{record.statu}</td>
                            </tr>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.compliance_description}</td>
                            </tr>
                            <tr>
                                <th>Penal Consequqnces</th>
                                <td>{record.penal_consequences}</td>
                            </tr>
                        </tbody>
                    </table>

                );

                return (
                    <>
                        <span className="mt-3"
                            onClick={() => {
                                showValue()
                                // setOpen(true)
                                setValueModal({
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.document_reference_number,
                                    next_due_date: record.next_due_date,
                                    address: record.address,
                                    compliance_description: record.compliance_description,
                                    uploaded_document: record.upload_docs,
                                    interim_details: record.interim_docs,
                                    remarks: record.remarks

                                })
                            }}
                            style={{ cursor: "pointer" }}
                        >{record.statu}</span>
                        <div className='approve-task-reacall-tooltip'>
                            <div>
                                <Tooltip placement='top' overlayStyle={{ maxWidth: '420px' }} title={
                                    <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                        <tbody>
                                            <tr>
                                                <th style={{ border: '1px solid #636262' }}>Unit Name</th>
                                                <td style={{ border: '1px solid #636262' }}>{record.unit_name}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ border: '1px solid #636262' }}>Address</th>
                                                <td style={{ border: '1px solid #636262' }}>{record.address}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ border: '1px solid #636262' }}>Division</th>
                                                <td style={{ border: '1px solid #636262' }}>{record.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ border: '1px solid #636262' }}>Category</th>
                                                <td style={{ border: '1px solid #636262' }}>{record.category_name}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ border: '1px solid #636262' }}>Act/Rules</th>
                                                <td style={{ border: '1px solid #636262' }}>{record.statu}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ border: '1px solid #636262' }}>Compliance Description</th>
                                                <td style={{ border: '1px solid #636262' }}>{record.compliance_description}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ border: '1px solid #636262' }}>Penal Consequqnces</th>
                                                <td style={{ border: '1px solid #636262' }}>{record.penal_consequences}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }> <i className="bi-info-circle-fill"></i>
                                </Tooltip>
                                &nbsp;{record.compliance_name}
                            </div>
                        </div>
                    </>
                )
            },
            sortable: true,
            width: '55px',
            reorder: true,

        },
        {
            title: "Assignee",
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            sortable: true,
            width: '25px',
            reorder: true,
            ellipsis: true,
            ...getColumnSearchProps('assignee_name'),



        },
        {
            title: "Compliance Status",
            dataIndex: 'task_status',
            key: 'task_status',
            sortable: true,
            width: '30px',
            reorder: true,
            ...getColumnSearchProps('task_status'),


        },
        {
            title: "Due Date",
            dataIndex: 'due_date',
            key: 'due_date',
            sortable: true,
            align: 'center',
            width: '25px',
            reorder: true,
            // ellipsis: true,
            ...getColumnSearchProps('due_date'),



        },
        {
            title: "Completion Date",
            dataIndex: 'completion_date',
            key: 'completion_date',
            sortable: true,
            width: '30px',
            align: 'center',
            reorder: true,
            // ellipsis: true,
            ...getColumnSearchProps('completion_date'),


        },
    ];

    const [valueModal, setValueModal] = useState({
        unit: '',
        address: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: '',
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: '',
        compliance_description: '',
        interim_details: ''
    })
    const [password, Setpassword] = useState({
        passwordvalue: '',
    })

    const Remarkscolumn = [
        {
            title: 'S.No.',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                Setrecalldata({ ...recalldata, remarks: record.r_description })
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        }
    ]
    const showValue = () => {
        setShowValueModal(true);
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const passwordcancel = () => {
        Setpasswordmodal(false);
        setAddFormSubmit2(false);
    }
    useEffect(() => {
        if (list.approverecallcompliance !== null) {
            let totalList = []
            let arr = [];
            let countrylisttempdata = [];
            let tempSetArray = [];
            let parId = []
            list.approverecallcompliance && list.approverecallcompliance.length > 0 && list.approverecallcompliance.map(function (user) {

                if ((arr.indexOf(user.unit_name) < 0))
                    arr.push(user.unit_name);
            });

            arr.map((pmenu, index) => {
                let entityArray = []
                let tempArray = []

                if (pmenu) {

                    list.approverecallcompliance && list.approverecallcompliance.map((subMenuList, childIndex) => {
                        if (pmenu === subMenuList.unit_name) {

                            entityArray = {
                                'compliance_history_id': subMenuList.compliance_history_id,
                                'compliance_name': subMenuList.compliance_name,
                                'compliance_frequency': subMenuList.compliance_frequency,
                                'domain_name': subMenuList.domain_name,
                                'domain_id': subMenuList.domain_id,
                                'interim_count': subMenuList.interim_count,
                                'unit_id': subMenuList.unit_id,
                                'duration_type': subMenuList.duration_type,
                                'validity_settings_days': subMenuList.validity_settings_days,
                                'assigned_on': subMenuList.assigned_on,
                                'start_date': subMenuList.start_date,
                                'due_date': moment(subMenuList.due_date).format('DD-MMM-YYYY'),
                                'reviwer_docs': subMenuList.reviwer_docs,
                                'validity_date': subMenuList.validity_date,
                                'next_due_date': subMenuList.next_due_date,
                                'assignee_name': subMenuList.assignee_name,
                                'document_size': subMenuList.document_size,
                                'approved_on': subMenuList.approved_on,
                                'format_file_name': subMenuList.format_file_name,
                                'unit_name': subMenuList.unit_name,
                                'remarks_count': subMenuList.remarks_count,
                                'address': subMenuList.address,
                                'compliance_description': subMenuList.compliance_description,
                                'statu': subMenuList.statu,
                                'remarks': subMenuList.remarks,
                                'occurrence_remarks': subMenuList.occurrence_remarks,
                                'compliance_id': subMenuList.compliance_id,
                                'download_url': subMenuList.download_url,
                                'file_names': subMenuList.file_names,
                                'history_count': subMenuList.history_count,
                                'tz_date': subMenuList.tz_date,
                                'tz_name': subMenuList.tz_name,
                                'comp_history_count': subMenuList.comp_history_count,
                                'system_remarks': subMenuList.system_remarks,
                                'division_name': subMenuList.division_name,
                                'category_name': subMenuList.category_name,
                                'document_reference_number': subMenuList.document_reference_number,
                                'upload_docs': subMenuList.upload_docs,
                                'recall_status': subMenuList.recall_status,
                                'penal_consequences': subMenuList.penal_consequences,
                                'interim_docs': subMenuList.interim_docs,
                                'interm_newdoc': subMenuList.interm_newdoc,
                                'reviwer_newdoc': subMenuList.reviwer_newdoc,
                                'country_id': subMenuList.country_id,
                                'recall_id': subMenuList.recall_id,
                                'interim_docs_size': subMenuList.interim_docs_size,
                                'reviwer_docs_size': subMenuList.reviwer_docs_size,
                                'completion_date': moment(subMenuList.completion_date).format('DD-MMM-YYYY'),
                                'approve_status': subMenuList.approve_status,
                                'assignee_remarks': subMenuList.assignee_remarks,
                                'extn_due_date': subMenuList.extn_due_date,
                                'task_status': subMenuList.task_status,
                                'compfielaw_url': subMenuList.compfielaw_url,
                                'parentId': index,
                                'childId': childIndex,


                            }
                            tempArray.push(entityArray)

                        }
                    })

                }
                totalList = {
                    'index': index,
                    'parentData': pmenu,
                    'child': tempArray
                }
                titleDynamic[index] = pmenu;
                countrylisttempdata.push(totalList)


            })
            setTitleDynamic([...titleDynamic])
            var merged = [].concat.apply([], countrylisttempdata);
            setTemp([...temp, ...merged])
            //  setData(tempSetArray)
            //getData()
        }
    }, [list.approverecallcompliance])
    // const submitpassword = (e) => {
    //     e.preventDefault();
    //     const payload = [
    //         authtoken,
    //         {
    //             "session_token": authtoken,
    //             "request": [
    //                 "",
    //                 {
    //                     "le_id": password.le_id,
    //                     "d_id": password.d_id,
    //                     "u_id": password.u_id,
    //                     "lock": password.lock,
    //                     "password": password.passwordvalue
    //                 }
    //             ]
    //         }
    //     ]
    //     // [
    //     //     authtoken,
    //     //     {
    //     //         "session_token": authtoken,
    //     //         "request": [
    //     //             "UpdateTaskRecallStatus",
    //     //             {
    //     //                 "le_id": entityid,
    //     //                 "check_list": [
    //     //                     {
    //     //                         "compliance_id": 8190,
    //     //                         "compliance_history_id": 91,
    //     //                         "unit_idl": 38884,
    //     //                         "download_doc": [],
    //     //                         "reviewer_doc": [],
    //     //                         "interm_doc": [],
    //     //                         "interim_docs_size": 0,
    //     //                         "reviwer_docs_size": 0,
    //     //                         "document_size": 0,
    //     //                         "recall_status": 0,
    //     //                         "recall_id": null
    //     //                     }
    //     //                 ],
    //     //                 "action": "1",
    //     //                 "remarks": "test",
    //     //                 "password": "Info@123",
    //     //                 "pwd_validate": true
    //     //             }
    //     //         ]
    //     //     }
    //     // ]
    //     Setpasswordmodal(false);
    // }
    const submitpassword = (e) => {
        setAddFormSubmit2(true)

        e.preventDefault();
        if (formValidator2.current.allValid()) {

            let temp = []
            for (let i in temparr) {
                temp.push({
                    "compliance_id": temparr[i].compliance_id,
                    "compliance_history_id": temparr[i].compliance_history_id,
                    "unit_idl": temparr[i].unit_id,
                    "download_doc": temparr[i].download_url,
                    "reviewer_doc": temparr[i].reviwer_newdoc,
                    "interm_doc": temparr[i].interm_newdoc,
                    "interim_docs_size": Number(temparr[i].interim_docs_size),
                    "reviwer_docs_size": Number(temparr[i].reviwer_docs_size),
                    "document_size": temparr[i].document_size,
                    "recall_status": temparr[i].recall_status,
                    "recall_id": temparr[i].recall_id
                })
            }

            ApproveTaskRecallSubmit({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateTaskRecallStatus",
                            {
                                "le_id": entityid,
                                "check_list": temp,
                                "action": recalldata.action,
                                "remarks": recalldata.remarks,
                                "password": password.passwordvalue,
                                "pwd_validate": true
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
            Setpasswordmodal(false);
        }
    }

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };
    const Active = () => {
        if (temparr.length > 0) {
            setFormRecall(true)
        }
        else {
            toast.error("Please Select Compliance to recall")
        }
        if (recallSubmit.current.allValid()) {
            if (temparr.length !== 0) {
                Swal.fire({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        Setpasswordmodal(true)
                    }
                })
            }

        }
    }
    // const data = [
    //     'Racing car sprays burning fuel into crowd.',
    //     'Japanese princess to wed commoner.',
    // ];
    const { Panel } = Collapse;
    const { Search } = Input;

    // const showModal = () => {
    //     setIsModalVisible(true);
    // };

    const handleOk = () => {
        setIsModalVisible(false);
        setIsComplainceModalVisible(false);
        setRemarksModal(false);
        setShowValueModal(false)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsComplainceModalVisible(false);
        setRemarksModal(false);
        setShowValueModal(false)
    };
    useEffect(() => {
        formValidator.current.showMessages()
        recallSubmit.current.showMessages()
        formValidator2.current.showMessages()

    }, [])
    useEffect(() => {
        if (isAuth) {
            if (entityid !== '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "TaskRecallFilters",
                            {
                                "le_id": entityid,

                            }
                        ]
                    }
                ]
                if (entityid != "null") {
                    ApproveTaskRecallUnitList({
                        payload: payload,
                        paramid: paramid
                    })
                }
                Setshowdata({
                    ...showdata,
                    domain: '',
                    unit: '',
                    frequency: '',
                    asignee: '',

                })

            }
        }
    }, [isAuth, entityid])

    const showComplianceModal = () => {
        setIsComplainceModalVisible(true);
    };



    const gettaskrecalllist = () => {
        setFormShow(true)
        setTemp([])
        if (formValidator.current.allValid()) {
            setAccordionTrue(true)
            getApproveTaskRecallList({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetTaskRecallList",
                                {
                                    "le_id": entityid,
                                    // "unit_id": Number(showdata.unit),
                                    "unit_id": null,
                                    "d_id": Number(showdata.domain),
                                    "frequency_id": Number(showdata.frequency),
                                    "compl_date": "February-2022",
                                    "parent_id": null,
                                    "user_id": null,
                                    "start_count": 0,
                                    "task_status": "Approver Task Recall"

                                }
                            ]
                        }
                    ],
                paramid: paramid
            })
        }
    }
    useEffect(() => {
        let temparr = []
        for (let i in checkedRow) {
            temparr.push({
                compliance_id: checkedRow[i].compliance_id,
                compliance_history_id: checkedRow[i].compliance_history_id,
                unit_idl: checkedRow[i].unit_id,
                download_doc: [],
                reviewer_doc: checkedRow[i].reviewer_doc,
                interim_docs_size: checkedRow[i].interim_docs_size,
                reviwer_docs_size: checkedRow[i].reviwer_docs_size,
                document_size: checkedRow[i].document_size,
                recall_status: checkedRow[i].recall_status,
                recall_id: checkedRow[i].recall_id
            })
        }
        setCheckedRowData(temparr)
    }, [checkedRow])
    useEffect(() => {
        if (list && list.approverecallcompliance && list.approverecallcompliance.length > 0) {
            let uniqueObjArray = [...new Map(list && list.approverecallcompliance && list.approverecallcompliance.length && list.approverecallcompliance.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(list && list.approverecallcompliance, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([...filteredArray, tempArr])
            }
        }
    }, [list && list.approverecallcompliance])
    const addAllSelectedDataValues = (checked, currentParId, parentData) => {


        let array
        let tempArray = temparr
        var ele = document.getElementsByName('checked' + currentParId);

        if (checked === true) {
            setCheckedRow(true)
            array = _.filter(finalchild[0], { parentId: currentParId });
            for (let i in array) {
                tempArray.push(array[i])
            }
            setTemparr([...tempArray])
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            for (var x = tempArray.length - 1; x >= 0; x--) {
                if (tempArray[x].parentId === currentParId) {
                    tempArray.splice(x, 1)
                }
            }
            setTemparr([...tempArray])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }
    const addSelectedDataValues = (checked, compliance_history_id) => {
        var ele = document.getElementsByName('allchecked');
        let array
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {
                array = _.find(list && list.approverecallcompliance, { compliance_history_id: compliance_history_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].compliance_history_id === compliance_history_id) {
                        tempArray.splice(i, 1)
                    }
                    if (list.approverecallcompliance.length !== temparr.length) {
                        setCheckedRow(false)
                        for (var j = 0; j < ele.length; j++) {
                            if (ele[j].type === 'checkbox')
                                ele[j].checked = false;
                        }
                    }
                }
            }
            setTemparr([...tempArray])
        } else {
            if (checked === true) {
                array = _.find(list && list.approverecallcompliance, { compliance_history_id: compliance_history_id });
                tempArray.push(array)
                if (list.approverecallcompliance.length === temparr.length) {
                    setCheckedRow(true)
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].compliance_history_id === compliance_history_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-0 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Approve Task Recall</span>
                                </li>
                            </ol>
                        </nav>
                        {/* <h2 className="mb-0 fw-bold">Approve Task Recall</h2> */}
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className={"card card-body1-statuatory " + localStorage.getItem('currentTheme')}>
                            <Collapse className='report-collapse' accordion defaultActiveKey={['1']}>
                                <Panel header="Approve Task Recall" key="1">
                                    
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Legal Entity : </b><span style={{ color: "red" }}>*</span></label><br />
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Select Legal Entity"
                                                                    onChange={(data, value) => {
                                                                        if (data !== undefined) {
                                                                            setCurrentEntity(data)
                                                                            Setshowdata({
                                                                                ...showdata,
                                                                                legal_entity: data,
                                                                                domain: '',
                                                                                unit: '',
                                                                                frequency: '',
                                                                                asignee: ''
                                                                            })
                                                                            setFormShow(false)
                                                                        }
                                                                        else {
                                                                            setCurrentEntity('')
                                                                            Setshowdata({
                                                                                ...showdata,
                                                                                legal_entity: '',
                                                                                domain: '',
                                                                                unit: '',
                                                                                frequency: '',
                                                                                asignee: ''
                                                                            })

                                                                        }
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    // value={showdata.legal_entity}
                                                                    style={{ width: '100%', marginTop: "5px" }}
                                                                >
                                                                    {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {formValidator.current.message(
                                                                    'legal_entity',
                                                                    showdata.legal_entity,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Legal Entity Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Domain : </b> <span style={{ "color": "red" }}>*</span></label><br />
                                                        <Select
                                                            // disabled={entityid != "" ? false : true}
                                                            disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Domain"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        domain: data,
                                                                        unit: '',
                                                                        frequency: '',
                                                                        asignee: ''
                                                                    })
                                                                } else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        domain: '',
                                                                        unit: '',
                                                                        frequency: '',
                                                                        asignee: ''
                                                                    })
                                                                }
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.domain || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {list && list.domain.length > 0 && list.domain.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'domain',
                                                            showdata.domain,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Domain Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Unit : </b> </label><br />
                                                        <Select
                                                            // disabled={entityid != "" ||  entityid != null ? false : true}
                                                            disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Unit"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        unit: data,
                                                                        frequency: '',
                                                                        asignee: ''
                                                                    })
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        unit: '',
                                                                        frequency: '',
                                                                        asignee: ''
                                                                    })
                                                                }
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.unit || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {list && list.unitdata.length > 0 && list.unitdata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.unit_id}>
                                                                        {item.unit_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{marginTop:'10px'}}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Frequency : </b></label><br />
                                                        <Select

                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Frequency"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        frequency: data
                                                                    })
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        frequency: ''
                                                                    })
                                                                }
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.frequency || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            <Option key={0}>All</Option>
                                                            {list && list.frequency.length > 0 && list.frequency.map((item, i) => {
                                                                return (
                                                                    <Option key={item.frequency_id}>
                                                                        {item.frequency_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Assignee : </b></label><br />
                                                        <Select
                                                            // disabled={entityid != "" ? false : true}
                                                            disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Assignee"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        asignee: data
                                                                    })
                                                                }
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.asignee || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                        </Select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <Button type="primary" shape="round" className='addbutton mt-2' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                size='default' icon={<EyeOutlined />} onClick={() => { gettaskrecalllist() }} >Show
                                            </Button>
                                        </div>
                                  
                                </Panel>
                            </Collapse>
                            {acccordionTrue == true ?
                                <div className="mt-2" id='only-restricted'>
                                    <Collapse defaultActiveKey={['1']} accordion>
                                        <Panel header="Compliance Table" key="1">
                                            {temp && temp.length > 0 ? temp.map((itemdata, i) => {

                                                return (
                                                    <Fragment key={i}>

                                                        {/* <Table dataSource={dataSource} columns={columns} pagination={false} /> */}

                                                        {/* <DataTable

                                                            columns={columns}
                                                            data={list && list.approverecallcompliance.length && list.approverecallcompliance}
                                                            // defaultSortFieldId={1}
                                                            // pagination
                                                            selectableRows={false}
                                                       
                                                        /> */}
                                                        <Table
                                                            className={localStorage.getItem("currentTheme") + ' userprivclass'}

                                                            columns={columns}
                                                            dataSource={itemdata.child}
                                                            bordered
                                                            title={() => {
                                                                return itemdata.child[0].unit_name
                                                            }}
                                                            scroll={{ y: 1000 }}
                                                            pagination={false}
                                                            onHeaderRow={(columns, index) => {
                                                                return {
                                                                    onClick: (e) => {
                                                                        let checked = e.target.checked;
                                                                        addAllSelectedDataValues(
                                                                            checked,
                                                                            itemdata.index

                                                                        );
                                                                    }

                                                                };
                                                            }}
                                                        />

                                                    </Fragment>
                                                )
                                            }) : <p style={{ textAlign: 'center' }} >No Compliance Available</p>
                                            }
                                        </Panel>

                                    </Collapse>
                                    {temp && temp.length > 0 ? <>
                                        <div>
                                            <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                <div className="row">
                                                    <label htmlFor="">Total Compliance : {list.approverecallcompliance.length}</label>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 m-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="">Action: <span style={{ "color": "red" }}>*</span></label>
                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action"
                                                            onChange={(e) => {
                                                                Setrecalldata({
                                                                    ...recalldata,
                                                                    action: e
                                                                })
                                                            }}
                                                            defaultValue={"Please Select"}
                                                        >
                                                            <Option value=""> Please Select </Option>
                                                            <Option value="1"> Rectify & Retain Document </Option>
                                                            <Option value="2"> Rectify & Remove Document </Option>
                                                            <Option value="3"> Cancel Recall </Option>
                                                        </Select>
                                                        {recallSubmit.current.message(
                                                            'action',
                                                            recalldata.action,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${FormRecall ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Please select Action to Recall',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="">Remarks: <span style={{ "color": "red" }}>*</span></label>
                                                        <div class="d-flex align-items-end">
                                                            <TextArea name="remarks" className='form-control'
                                                                placeholder='Enter Remarks'
                                                                value={recalldata.remarks ? recalldata.remarks || undefined : ""} id="remarks" cols="5" rows="3"
                                                                onChange={(e) => {
                                                                    Setrecalldata({
                                                                        ...recalldata,
                                                                        remarks: e.target.value
                                                                    })
                                                                }}
                                                                maxLength={500}
                                                            ></TextArea>
                                                            <span className="ms-1 text-info  d-flex align-items-center justify-content-center"
                                                                onClick={() => { showRemarksModal() }}>
                                                                <PlusCircleOutlined />
                                                            </span>
                                                        </div>
                                                        {recallSubmit.current.message(
                                                            'remarks',
                                                            recalldata.remarks,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${FormRecall ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Remarks Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                {/* <Modal title="Remarks List" visible={remarksModal} onOk={handleOk} onCancel={handleCancel}>
                                                    <DataTable columns={Remarkscolumn}
                                                        data={recallremarks}
                                                        // defaultSortFieldId={1}
                                                        pagination />
                                                </Modal> */}
                                                <Modal title="Remarks List" className='add-service-prv' visible={remarksModal} footer={null} onOk={handleOk} onCancel={handleCancel}>
                                                    <Table
                                                        className='userprivclass'
                                                        columns={Remarkscolumn}
                                                        dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                                                        bordered
                                                        pagination={{
                                                            defaultPageSize: dataTableProperties.pagesize,
                                                            showSizeChanger: dataTableProperties.sizechanger,
                                                            pageSizeOptions: dataTableProperties.pageSizeOptions
                                                        }}
                                                    />
                                                </Modal>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                                        {/* <a href="javascript:;" onClick={Active} style={{ marginLeft: '10%' }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Approve</a> */}
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '10%', marginTop: '8%', float: 'right' }}
                                                            size='default' onClick={Active}>Approve
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ''}
                                </div>
                                : ''}
                            <Modal title="Domain" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                                <Search
                                    placeholder="Search"
                                    style={{
                                        width: 600,
                                    }}
                                />
                            </Modal>
                            {/* <Modal title="Domain" visible={isComplainceModalVisible} onOk={handleOk} onCancel={handleCancel}>
                                <p>The Contract labour(Regulation Act 1997)The tamilNadu Contact Labour(Regulations)Rule 1975</p>
                            </Modal> */}

                            <Drawer width={500} placement="right" visible={open} onClose={onClose} >
                                {/* <Drawer title="Domain" visible={showValueModal} footer={false} onCancel={handleCancel} width={40} > */}

                                {/* <Card style={{padding:0}}> */}
                                <div className="row" style={{ padding: 0 }}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <label htmlFor=""><h5>Unit: </h5></label>
                                            </div>
                                            <div className="col-md-1 mt-3">
                                                <Tooltip title={valueModal.address}>
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </div>
                                            <div className="col-md-5 mt-3">
                                                <p style={{ float: 'left' }}>{valueModal.unit}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <label htmlFor=""><h5>Compliance Task: </h5></label>
                                            </div>
                                            <div className="col-md-1 mt-3">
                                                <Tooltip title={valueModal.compliance_description}>
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </div>
                                            <div className="col-md-5 mt-3">
                                                <p>{valueModal.compliance_task}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <label htmlFor=""><h5>Compliance Frequency: </h5></label>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <p style={{ float: 'left' }}>{valueModal.compliance_frequency}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <label htmlFor=""><h5>Uploaded File Size: </h5></label>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <p style={{ float: 'left' }}></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <label htmlFor=""><h5>Attached Documents: </h5></label>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <p style={{ float: 'left' }}>{valueModal.uploaded_document}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <label htmlFor=""><h5>Task Completion Date/Document Issued Date: </h5></label>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <p style={{ float: 'left' }}>{valueModal.document_issued_date}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <label htmlFor=""><h5>Document Reference Number: </h5></label>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <p style={{ float: 'left' }}>{valueModal.document_reference_number}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <label htmlFor=""><h5>Remarks: </h5></label>
                                            </div>
                                            <div className="col-md-4 mt-3">
                                                <p style={{ float: 'left' }}>{valueModal.remarks}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <label htmlFor=""><h5>interim details: </h5></label>
                                            </div>
                                            <div className="col-md-4 mt-3">
                                                <p style={{ float: 'left' }}>{valueModal.interim_details}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <label htmlFor=""><h5>Recall Remarks: </h5></label>
                                            </div>
                                            <div className="col-md-4 mt-3">
                                                {/* <p style={{ float: 'left' }}>{valueModal.interim_details}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* {valueModal.action == 'Rectify' ?
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6 mt-3">
                                                        <label htmlFor=""><h5>Remarks</h5></label>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <textarea name="" className='form-control' id="" cols="10" rows="5"></textarea>
                                                    </div>
                                                </div>
                                            </div> : ''
                                        } */}
                                </div>
                                {/* </Card> */}
                            </Drawer>
                            <Modal visible={passwordmodal} footer={null} onCancel={passwordcancel} className="newStyleModalPassword">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-6">
                                            <label className="control-label">Password: </label>
                                            <span style={{ "color": "red" }}>*</span>
                                            <input
                                                style={{ marginTop: "10px" }}
                                                type="password"
                                                id="password"
                                                name='passwordvalue'
                                                className="form-control"
                                                placeholder="Enter password"
                                                onChange={(e) => {
                                                    Setpassword({
                                                        ...password,
                                                        passwordvalue: e.target.value
                                                    })
                                                }}
                                            // value={password.passwordvalue}
                                            />
                                            {formValidator2.current.message(
                                                'Password',
                                                password.passwordvalue,
                                                ['required'],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        regex: 'Password Required'
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="form-actions text-center">
                                    <Button type="primary" shape="round" className='addbutton'
                                        style={{ background: "#198754", borderColor: "#198754" }}
                                        icon={<PlayCircleOutlined />} size='default'
                                        onClick={submitpassword}
                                    >Submit
                                    </Button>
                                    {/* <div className="button2 mt-0" id={localStorage.getItem('currentTheme')} onClick={submitpassword}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        Submit
                                    </div> */}
                                    {/* <button
                                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start"
                                        onClick={passwordcancel}>
                                        <div className="d-flex align-items-center"> 
                                            <i data-feather="save" className="feather-sm me-1 fill-icon"></i> 
                                        Cancel
                                        </div>
                                    </button> */}
                                </div>

                            </Modal>

                            {/* <Modal title="Remarks" visible={remarksModal} onOk={handleOk} onCancel={handleCancel}>
                                <p>Test</p>
                            </Modal> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default connect(mapStateToProps, {
    ApproveTaskRecallUnitList,
    getApproveTaskRecallList,
    GetRemarksData,
    ApproveTaskRecallSubmit
})(ApproveTaskRecall)