import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha
} from "react-simple-captcha";
import SimpleReactValidator from "simple-react-validator";
import { Link, Redirect, useHistory } from 'react-router-dom';
import { login } from './../Store/Action/Login'
import { FastForwardOutlined } from '@ant-design/icons';
const mapStateToProps = (state) => ({
})

const Login = ({
    login
}) => {
    const history = useHistory()
    const [Auth, SetAuth] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [captchaError, SetcaptchaError] = useState(false);
    const [LoginData, setLoginData] = useState({
        userName: '',
        password: '',
        groupName: '',
        captcha: '',
    });
    const [message, setMessage] = useState('');
    const [txt, setTxt] = useState('');
    const validator = useRef(new SimpleReactValidator());


    useEffect(() => {
        loadCaptchaEnginge(4, 'white', 'black', 'numbers');

    }, [])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        SetAuth(localStorage.getItem('isAuthenticated'));
    }, [])



    const submitLogin = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        SetcaptchaError(false);
        if (validateCaptcha(LoginData.captcha) === true) {
            if (validator.current.allValid()) {
                login({
                    payload: LoginData,
                    navigate: history
                })

                // e.target.reset();
                // setTxt(e.target.reset())

            }
        } else {
            SetcaptchaError(true);
        }
    }

    const onInputChange = e => {
        const { value } = e.target;


        const re = /^[0-9a-z]+$/;
        if (value === "" || re.test(value)) {
            setLoginData({
                ...LoginData,
                groupName: e.target.value
            })
            setTxt(value);
        }
    }

    return (

        (Auth && Auth ? <Redirect to='/home' /> : <Fragment>

            <body>
                <div className="main-wrapper-bg auth-wrapper d-flex no-block justify-content-center 
                align-items-center flex-column">
                    <div className="auth-box p-3 bg-white rounded m-0">
                        <center><img src="logo-icon.png" height="50px" alt='Logo' /></center>
                        <div id="loginform">
                            <div className="logo">
                                <center>
                                    <h3 className="box-title mb-1">Login</h3>
                                </center>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <form className="commentForm mt-2 form-material" onSubmit={submitLogin}>
                                        <div className="form-group mb-3">
                                            <div className="">
                                                <input className="form-control" autoFocus type="text" required="" placeholder="Username" onChange={(e) => {
                                                    setLoginData({
                                                        ...LoginData, userName: e.target.value
                                                    })
                                                }} />
                                                {validator.current.message(
                                                    'userName',
                                                    LoginData.userName,
                                                    ['required',
                                                        `max:50`
                                                    ],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Enter Username',
                                                            max: 'Maximum character is 50 only'

                                                        }
                                                    })}
                                            </div>

                                        </div>

                                        <div className="form-group mb-3">

                                            <div className="">

                                                <input className="form-control" type="password" required="" placeholder="Password" onChange={(e) => {
                                                    setLoginData({
                                                        ...LoginData, password: e.target.value
                                                    })
                                                }} />
                                                {validator.current.message(
                                                    'password',
                                                    LoginData.password,
                                                    ['required',
                                                        `max:20`
                                                    ],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Enter Password',
                                                            max: 'Maximum character is 20 only'

                                                        }
                                                    })}
                                            </div>

                                        </div>

                                        <div className="form-group mb-3">

                                            <div className="">

                                                <input className="form-control" type="text"
                                                    placeholder='Group Short Name'
                                                    value={txt}
                                                    onChange={onInputChange}
                                                    required=""
                                                />
                                                {validator.current.message(
                                                    'groupName',
                                                    LoginData.groupName,
                                                    ['required',
                                                        `max:50`
                                                    ],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Enter Group Short Name',
                                                            max: 'Maximum character is 50 only'

                                                        }
                                                    })}
                                            </div>

                                        </div>

                                        <div className="form-group mb-3">
                                            <div className="form-floating mb-2 d-flex">
                                                <div className="col mt-6">
                                                    <LoadCanvasTemplate />
                                                </div>
                                            </div>
                                            <input className="form-control" placeholder='Enter Captcha' maxLength={4} onChange={(e) => {
                                                setLoginData({
                                                    ...LoginData, captcha: e.target.value
                                                })
                                            }} />
                                            {validator.current.message(
                                                'groupName',
                                                LoginData.captcha,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Enter Captcha',

                                                    }
                                                })}
                                        </div>
                                        {
                                            LoginData.captcha != "" && captchaError == true ?
                                                <span style={{ "color": "red" }}>Invalid Captcha</span> : null
                                        }
                                        <div className="form-group mb-3">

                                            <div className="d-flex">
                                                <div className="ms-auto">

                                                    <Link to="/Forgotpassword" id="to" className="

                                                    d-flex

                                                    align-items-center

                                                    link

                                                    font-weight-medium

                                                "><i className="ri-lock-line me-1 fs-4"></i> Forgot your password?</Link>

                                                </div>

                                            </div>

                                        </div>

                                        <div className="form-group text-center mt-2">

                                        {/* <div className="col-xs-12 wrapper"> */}

                                        <button type='submit' className="

                                                        btn btn-primary

                                                        d-block

                                                        w-100

                                                        waves-effect waves-light    

                                                        "
                                                //onClick={submitLogin}
                                                >

                                                    Login

                                                </button>
                                        {/* <div className="wrapper">

                                            <button className="icons twitter" style={{ border: 'none' }}>
                                                <span className="tooltips">Login</span>
                                                <span><i><FastForwardOutlined /></i></span>
                                            </button>

                                        </div> */}
                                        {/* <button  type='submit' class="button2" onClick={submitLogin}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Login
                                        </button> */}

                                        </div>

                                        {/* </div> */}
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>


                    {/* <footer className="footer bg-transparent text-white" style={{ padding: 0 }}>2022© Aparajitha Software Services Private Limited <a className="ms-2 text-white" href="##">Privacy · Terms of Use </a></footer> */}
                    <footer className="footer bg-transparent text-white pt-4 pb-0" >2022© Aparajitha Software Services Private Limited</footer>
                    <footer className="footer bg-transparent text-white pb-1 pt-2">
                        {/* 2022© Aparajitha Software Services Private Limited */}
                        <span className="ms-4" style={{ color: "white" }}>Privacy Policy · Terms & Condition </span>
                    </footer>



                </div>
            </body>

        </Fragment>)


    )
}

export default connect(mapStateToProps, {
    login
})(Login);