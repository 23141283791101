import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from "react-router-dom";
// import Home from './Home';
import Dashboard from './Dashboard';
import Serviceproviderlist from './Master/Serviceproviderlist';
import Serviceprovider from './Master/Addserviceprovider';
import Userprivileges from './Master/Userprivilegeslist';
import Adduserprivileges from './Master/Adduserprivileges';
import AddUserManagement from './Master/AddUserManagement';
import Remarksmaster from './Master/Remarksmaster';
import UnitClosure from './Master/UnitClosure';
import Usermanagement from './Master/Usermanagement';
import ViewOnlyRestricted from './Master/ViewOnlyRestricted';
import Statutorysetting from './Transactions/statutorysettings';
import ApproveTaskRecall from './Transactions/ApproveTaskRecall';
import Compliance from './Transactions/Assigncompliance';
import Reviewsetting from './Transactions/Reviewsettings';
// import Reviewsetting from './Transactions/Reviewsettingsbackup';
import Taskrecall from './Transactions/Taskrecall';
import ReassignComplaince from './Transactions/ReassignComplaince';
import ComplianceApproval from './Transactions/ComplianceApproval';
import CompletedTaskCurrentYear from './Transactions/CompletedTaskCurrentYear';
// import MyProfile from './MyProfile';
// import ChangePassword from './ChangePassword';
import Completedtaskcurrentyearbulkuploadlist from './Transactions/Completedtaskcurrentyearbulkuploadlist'
import Addcompletedtaskcurrentyearbulkupload from './Transactions/Addcompletedtaskcurrentyearbulkupload'
import CriticalitySetting from './Transactions/CriticalitySetting';
import ConsolidatedReport from "./Report/ComplianceReports/ConsolidatedReport"
import DomainReport from './Report/ComplianceReports/DomainReports';
import UnitWiseReport from './Report/ComplianceReports/UnitReport';
import UserWiseReport from './Report/ComplianceReports/UserWiseReport';
import ReassignReport from './Report/ComplianceReports/ReassignReport';
import Notification from './Notifications/Notifications';
import Remindersshowall from './Notifications/Reminders';
import Escalationsshowall from './Notifications/Escalations';
import Messagesshowall from './Notifications/Messages';
import CriticalityReport from './Report/CriticalityReport';
import MonthWiseStatusReport from './Report/ComplianceReports/MonthWiseStatusReport';
import TaskWiseReport from './Report/ComplianceReports/TaskWiseReport';
import TaskCategoryReport from './Report/ComplianceReports/TaskCategoryReport';
import RiskReport from './Report/ComplianceReports/RiskReport';
import UnitList from './Report/UnitlistReport';
import ServiceProviderComplianceReports from './Report/ServiceProviderReports/ServiceProviderComplianceReports';
import LegalentityReport from './Report/ComplianceReports/LegalentityReport';
import UnitWiseReports from './Report/StatutorySettingReports/UnitWiseReports';
import CV_UnitWiseReports from './Report/StatutorySettingReports/CV_UnitWiseReport';
import TaskHistoryReport from './Report/StatutorySettingReports/TaskHistoryReport';
import NotificationReport from './Report/StatutorySettingReports/NotificationReport'
import LegalEntityScoreCard from './Report/Score Cards/LegalEntityScoreCard';
import AuditTrail from './Report/LogReports/AuditTrail';
import Assigneescorecard from './Report/Score Cards/Assigneescorecard';
import DomainCard from './Report/Score Cards/DomainCard';
import ServiceProviderDetails from './Report/ServiceProviderReports/ServiceProviderDetails'
import ApproverScoreCard from './Report/Score Cards/ApproverScoreCard'
import LoginTrace from './Report/LogReports/LoginTrace';
import Settings from './Settings'
import MyProfile from './../Components/MyProfile';
import ChangePassword from './../Components/ChangePassword';
import CriticalityChart from './DashBoard/CriticalityChart';
import ComplianceStatusChart from './DashBoard/ComplianceStatusChart';
import AssigneeWiseCompliance from './DashBoard/Assigneewisecompliance';
import TaskDistribution from './DashBoard/TaskDistribution';
import ComplianceTrend from './DashBoard/ComplianceTrend';
import RiskChart from './DashBoard/RiskChart';
import OverdueChart from './DashBoard/OverdueChart';
import Homevendor from './Homevendor';
import VendorList from './Report/CV_VendorReports/VendorList';
import VendorReport from './Report/ComplianceReports/CV_VendorReport'
import VendorComplianceScore from './Report/CV_CustomizedReports/VendorComplianceScore'
import ComparativeTrend from './DashBoard/ComparativeTrend';
import VendorTempClosureReport from './Report/CV_VendorReports/VendorTempClosureReport';
import MonthlyComplianceReport from './Report/ComplianceReports/CV_MonthlyComplianceReport'
import CVMonthWiseStatusReport from './Report/ComplianceReports/CV_MonthWiseStatusReport';
import CV_ComplianceCertificates from './Transactions/CV_ComplianceCetificates';
import CV_ApproveCompliances from './Transactions/CV_ApproveCompliance';
// import CV_ComplianceCertificate from './Transactions/CV_ComplianceCetificate';
import CV_ComplianceCertificateReport from './Report/CV_ComplianceCertificate';
import CertificateLabel from './Master/CV_CertificateLabel';
import VendorClosure from './Master/CV_VendorClosure';
import CertificateLevelMapping from './Master/CV_CertificateLevelMapping';
import CV_StatutorySettings from './Transactions/CV_StatutorySettings';
import CertificateMailMapping from './Master/CV_CertificateMailMapping'
import CV_Reviewsetting from './Transactions/CV_Reviewsettings';
import CV_Compliance from './Transactions/CV_Assigncompliance';
import CV_ReassignComplaince from './Transactions/CV_Reassignsettings';
import CV_ComplianceApproval from './Transactions/CV_ComplianceAppproval';
import CV_CompletedTaskCurrentYear from './Transactions/CV_CompletedTaskCurrentYear';
import CV_Completedtaskcurrentyearbulkuploadlist from './Transactions/CV_Completedtaskcurrentyearbulkuploadlist'
import CV_Taskrecall from './Transactions/CV_Taskrecall';
import CV_ApproveTaskRecall from './Transactions/CV_ApproveTaskRecall';
import CV_CriticalitySetting from './Transactions/CV_CriticalitySetting';
import CV_ConsolidatedReport from "./Report/ComplianceReports/CV_ConsolidatedReport"
import CV_DomainReport from './Report/ComplianceReports/CV_DomainReports';
import CV_LegalentityReport from './Report/ComplianceReports/CV_LegalentityReport';
import CV_TaskWiseReport from './Report/ComplianceReports/CV_TaskWiseReport';
import CV_TaskCategoryReport from './Report/ComplianceReports/CV_TaskCategoryReport';
import CV_RiskReport from './Report/ComplianceReports/CV_RiskReport';
import CV_ReassignReport from './Report/ComplianceReports/CV_ReassignReport';
import CV_UserWiseReport from './Report/ComplianceReports/CV_UserWiseReport';
import CV_ApproverScoreCard from './Report/Score Cards/CV_ApproverScoreCard';
import CV_DomainCard from './Report/Score Cards/CV_DomainCard';
import CV_Assigneescorecard from './Report/Score Cards/CV_Assigneescorecard';
import CV_LegalEntityScoreCard from './Report/Score Cards/CV_LegalEntityScoreCard';
import CV_TaskHistoryReport from './Report/StatutorySettingReports/CV_TaskHistoryReport';
import CV_ServiceProviderComplianceReports from './Report/ServiceProviderReports/CV_ServiceProviderComplianceReports';
import EXE_OnOccurrenceCompliance from './Transactions/EXE_OnOccurrenceCompliance'; 
import EXE_ComplianceTaskDetails from './Transactions/EXE_ComplianceTaskDetails';
import Landingpage from './DashBoard/Landingpage';
import LandingPage2 from './DashBoard/Landingpage2';
import Landingpage3 from './DashBoard/Landingpage3';

const RoutesLink = () => {
    return (
        <Switch>
            <Route exact path="/page">
                <Landingpage />
            </Route>
            <Route exact path="/page2">
                <LandingPage2 />
            </Route>
            <Route exact path="/page3">
                <Landingpage3 />
            </Route>
            <Route exact path="/home/:id">
                <Dashboard />
            </Route>
            <Route exact path="/dashboard">
                <Dashboard />
            </Route>
            <Route exact path="/cv/home/:id">
                <Dashboard />
            </Route>
            <Route exact path="/cv/dashboard">
                <Dashboard />
            </Route>
            {/* <Route exact path="/dashboard/:id">
                <CriticalityChart />
            </Route> */}
            <Route exact path="/dashboard/:id">
                <ComplianceTrend />
            </Route>
            <Route exact path="/cv/dashboard/:id">
                <ComplianceTrend />
            </Route>
            {/* <Route exact path="/dashboard/:id">
                <ComplianceStatusChart />
            </Route> */}
            <Route exact path="/cv/dashboard/:id/compliance">
                <ComplianceStatusChart />
            </Route>
            <Route exact path="/dashboard/:id/compliance">
                <ComplianceStatusChart />
            </Route>
            <Route exact path="/cv/dashboard/:id/compliance">
                <ComplianceStatusChart />
            </Route>
            <Route exact path="/dashboard/:id/assigneewisecompliance">
                <AssigneeWiseCompliance />
            </Route>
            <Route exact path="/cv/dashboard/:id/assigneewisecompliance">
                <AssigneeWiseCompliance />
            </Route>
            <Route exact path="/dashboard/:id/criticality">
                <CriticalityChart />
            </Route>
            <Route exact path="/cv/dashboard/:id/criticality">
                <CriticalityChart />
            </Route>
            <Route exact path="/dashboard/:id/complianceTrend">
                <ComplianceTrend />
            </Route>
            <Route exact path="/cv/dashboard/:id/complianceTrend">
                <ComplianceTrend />
            </Route>
            <Route exact path="/dashboard/:id/risk">
                <RiskChart />
            </Route>
            <Route exact path="/cv/dashboard/:id/risk">
                <RiskChart />
            </Route>
            <Route exact path="/dashboard/:id/overdue">
                <OverdueChart />
            </Route>
            <Route exact path="/cv/dashboard/:id/overdue">
                <OverdueChart />
            </Route>
            <Route exact path="/dashboard/:id/taskDistribution">
                <TaskDistribution />
            </Route>
            <Route exact path="/cv/dashboard/:id/taskDistribution">
                <TaskDistribution />
            </Route>
            <Route exact path="/dashboard/:id/comparative">
                <ComparativeTrend />
            </Route>
            <Route exact path="/cv/dashboard/:id/comparative">
                <ComparativeTrend />
            </Route>
            <Route exact path="/service-provider/:id">
                <Serviceproviderlist />
            </Route>
            <Route exact path="/addserviceprovider/">
                <Serviceprovider />
            </Route>
            <Route exact path="/cv/addserviceprovider/">
                <Serviceprovider />
            </Route>
            <Route path="/client-user-privilege/:id">
                <Userprivileges />
            </Route>
            <Route path="/cv/client-user-privilege/:id">
                <Userprivileges />
            </Route>
            <Route exact path="/addclient-user-privilege/:id">
                <Adduserprivileges />
            </Route>
            <Route exact path="/cv/addclient-user-privilege/:id">
                <Adduserprivileges />
            </Route>
            <Route exact path="/remarks-master/:id">
                <Remarksmaster />
            </Route>
            <Route exact path="/cv/remarks-master/:id">
                <Remarksmaster />
            </Route>
            <Route exact path="/unit-closure/:id">
                <UnitClosure />
            </Route>
            <Route exact path="/cv/unit-closure/:id">
                <UnitClosure />
            </Route>
            <Route exact path="/client-user-management/:id">
                <Usermanagement />
            </Route>
            <Route exact path="/cv/client-user-management/:id">
                <Usermanagement />
            </Route>
            <Route exact path="/client-user-management/:id">
                <AddUserManagement />
            </Route>
            <Route exact path="/cv/client-user-management/:id">
                <AddUserManagement />
            </Route>
            <Route exact path="/view-only-user-privilege/:id">
                <ViewOnlyRestricted />
            </Route>
            <Route exact path="/cv/view-only-user-privilege/:id">
                <ViewOnlyRestricted />
            </Route>
            <Route exact path="/assign-compliance/:id/:type">
                <Compliance />
            </Route>
            <Route exact path="/cv/assign-compliance/:id/:type">
                <CV_Compliance />
            </Route>
            <Route exact path="/statutory-settings/:id/:type">
                <Statutorysetting />
            </Route>
            <Route path="/review-settings/:id/:type">
                <Reviewsetting />
            </Route>
            <Route path="/cv/review-settings/:id/:type">
                <CV_Reviewsetting />
            </Route>
            <Route exact path="/approve-task-recall/:id/:type">
                <ApproveTaskRecall />
            </Route>
            <Route exact path="/cv/approve-task-recall/:id/:type">
                <CV_ApproveTaskRecall />
            </Route>
            <Route exact path="/criticality-settings/:id/:type">
                <CriticalitySetting />
            </Route>
            <Route exact path="/cv/criticality-settings/:id/:type">
                <CV_CriticalitySetting />
            </Route>
            <Route exact path="/task-recall/:id/:type">
                <Taskrecall />
            </Route>
            <Route exact path="/cv/task-recall/:id/:type">
                <CV_Taskrecall />
            </Route>
            <Route exact path="/reassign-compliance/:id/:type">
                <ReassignComplaince />
            </Route>
            <Route exact path="/cv/reassign-compliance/:id/:type">
                <CV_ReassignComplaince />
            </Route>
            <Route exact path="/compliance-approval/:id/:type">
                <ComplianceApproval />
            </Route>
            <Route exact path="/cv/compliance-approval/:id/:type">
                <CV_ComplianceApproval />
            </Route>
            <Route exact path="/completed-tasks-current-year/:id/:type">
                <CompletedTaskCurrentYear />
            </Route>
            <Route exact path="/cv/completed-tasks-current-year/:id/:type">
                <CV_CompletedTaskCurrentYear />
            </Route>
            <Route exact path="/completed-tasks-current-year-bu/:id/:type">
                <Completedtaskcurrentyearbulkuploadlist />
            </Route>
            <Route exact path="/cv/completed-tasks-current-year-bu/:id/:type">
                <CV_Completedtaskcurrentyearbulkuploadlist />
            </Route>
            {/* <Route exact path="/profile">
                <MyProfile />
            </Route> */}
            {/* <Route exact path="/changePassword">
                <ChangePassword />
            </Route> */}
            <Route exact path="/Addcompletedtaskscurrentyearbu">
                <Addcompletedtaskcurrentyearbulkupload />
            </Route>
            <Route exact path="/cv/Addcompletedtaskscurrentyearbu">
                <Addcompletedtaskcurrentyearbulkupload />
            </Route>
            <Route exact path="/status-report-consolidated/:id">
                <ConsolidatedReport />
            </Route>
            <Route exact path="/cv/status-report-consolidated/:id">
                <CV_ConsolidatedReport />
            </Route>
            <Route exact path="/domain-wise-report/:id">
                <DomainReport />
            </Route>
            <Route exact path="/cv/domain-wise-report/:id">
                <CV_DomainReport />
            </Route>
            <Route exact path="/service-provider-details/:id">
                <ServiceProviderDetails />
            </Route>
            <Route exact path="/cv/service-provider-details/:id">
                <ServiceProviderDetails />
            </Route>
            <Route exact path="/unit-wise-compliance/:id">
                <UnitWiseReport />
            </Route>
            <Route exact path="/cv/unit-wise-compliance/:id">
                <VendorReport />
            </Route>
            <Route exact path="/monthly-compliance-wise-report/:id">
                <MonthWiseStatusReport />
            </Route>
            {/* <Route exact path="/cv/monthly-compliance-wise-report/:id">
                <MonthWiseStatusReport />
            </Route> */}
            <Route exact path="/task-wise-report/:id">
                <TaskWiseReport />
            </Route>
            <Route exact path="/cv/task-wise-report/:id">
                <CV_TaskWiseReport />
            </Route>
            <Route exact path="/user-wise-compliance/:id">
                <UserWiseReport />
            </Route>
            <Route exact path="/cv/user-wise-compliance/:id">
                <CV_UserWiseReport />
            </Route>
            <Route exact path="/reassigned-history-report/:id">
                <ReassignReport />
            </Route>
            <Route exact path="/cv/reassigned-history-report/:id">
                <CV_ReassignReport />
            </Route>
            <Route exact path="/reminders/:id">
                <Remindersshowall />
            </Route>
            <Route exact path="/messages/:id">
                <Messagesshowall />
            </Route>
            <Route exact path="/notifications/:id">
                <Notification />
            </Route>
            <Route exact path="/escalations/:id">
                <Escalationsshowall />
            </Route>
            <Route exact path="/criticality-report/:id">
                <CriticalityReport />
            </Route>
            <Route exact path="/cv/criticality-report/:id">
                <CriticalityReport />
            </Route>
            <Route exact path="/unit-list/:id">
                <UnitList />
            </Route>
            {/* <Route exact path="/cv/unit-list/:id">
                <UnitList />
            </Route> */}
            <Route exact path="/service-provider-wise-compliance/:id">
                <ServiceProviderComplianceReports />
            </Route>
            <Route exact path="/cv/service-provider-wise-compliance/:id">
                <CV_ServiceProviderComplianceReports />
            </Route>
            <Route exact path="/task-category-wise-report/:id">
                <TaskCategoryReport />
            </Route>
            <Route exact path="/cv/task-category-wise-report/:id">
                <CV_TaskCategoryReport />
            </Route>
            <Route exact path="/risk-report/:id">
                <RiskReport />
            </Route>
            <Route exact path="/cv/risk-report/:id">
                <CV_RiskReport />
            </Route>
            <Route exact path="/legal-entity-wise-report/:id">
                <LegalentityReport />
            </Route>
            <Route exact path="/cv/legal-entity-wise-report/:id">
                <CV_LegalentityReport />
            </Route>
            <Route exact path="/statutory-settings-unit-wise-report/:id">
                <UnitWiseReports />
            </Route>
            <Route exact path="/cv/statutory-settings-unit-wise-report/:id">
                <CV_UnitWiseReports />
            </Route>
            <Route exact path="/legal-entity-wise-score-card/:id">
                <LegalEntityScoreCard />
            </Route>
            <Route exact path="/cv/legal-entity-wise-score-card/:id">
                <CV_LegalEntityScoreCard />
            </Route>
            <Route exact path="/audit-trail/:id">
                <AuditTrail />
            </Route>
            <Route exact path="/cv/audit-trail/:id">
                <AuditTrail />
            </Route>
            <Route exact path="/work-flow-score-card/:id">
                <Assigneescorecard />
            </Route>
            <Route exact path="/cv/work-flow-score-card/:id">
                <CV_Assigneescorecard />
            </Route>
            <Route exact path="/statutory-notification-list/:id">
                <NotificationReport />
            </Route>
            <Route exact path="/cv/statutory-notification-list/:id">
                <NotificationReport />
            </Route>
            <Route exact path="/login-trace/:id">
                <LoginTrace />
            </Route>
            <Route exact path="/cv/login-trace/:id">
                <LoginTrace />
            </Route>
            <Route exact path="/statutory-settings-history-report/:id">
                <TaskHistoryReport />
            </Route>
            <Route exact path="/cv/statutory-settings-history-report/:id">
                <CV_TaskHistoryReport />
            </Route>
            <Route exact path="/view-profile/:id">
                <MyProfile />
            </Route>
            <Route exact path="/view-profile/:id">
                <MyProfile />
            </Route>
            <Route exact path="/change-password/:id">
                <ChangePassword />
            </Route>
            <Route exact path="/domain-score-card/:id">
                <DomainCard />
            </Route>
            <Route exact path="/cv/domain-score-card/:id">
                <CV_DomainCard />
            </Route>
            <Route exact path="/approver-score-card/:id">
                <ApproverScoreCard />
            </Route>
            <Route exact path="/cv/approver-score-card/:id">
                <CV_ApproverScoreCard />
            </Route>
            <Route exact path="/settings/:id">
                <Settings />
            </Route>
            <Route exact path="/cv/home/:id">
                <Homevendor />
            </Route>
            <Route exact path="/cv/service-provider/:id">
                <Serviceproviderlist />
            </Route>
            <Route exact path="/cv/status-report-consolidated/:id">
                <ConsolidatedReport />
            </Route>
            <Route exact path="/cv/unit-list/:id">
                <VendorList />
            </Route>
            {/* <Route exact path="/cv/unit-wise-compliance/:id">
                <VendorReport />
            </Route> */}
            {/* <Route exact path="/cv/unit-wise-compliance/:id">
                <CV_VendorReport />
            </Route> */}
            <Route exact path="/cv/vcs/:id">
                <VendorComplianceScore />
            </Route>
            <Route exact path="/cv/vendor-report/:id">
                <VendorTempClosureReport />
            </Route>
            <Route exact path="/cv/monthly-compliance-report/:id">
                <MonthlyComplianceReport />
            </Route>
            <Route exact path="/cv/monthly-compliance-wise-report/:id">
                <CVMonthWiseStatusReport />
            </Route>
            <Route exact path="/cv/compliance/:id/:type">
                <CV_ComplianceCertificates />
            </Route>
            <Route exact path="/cv/approve-compliance-certifications/:id/:type">
                <CV_ApproveCompliances />
            </Route>
            <Route exact path="/cv/compliance-certificate/:id">
                <CV_ComplianceCertificateReport />
            </Route>
            <Route exact path="/cv/certificate-additional-inputs/:id">
                <CertificateLabel />
            </Route>
            <Route exact path="/cv/vendor-closure/:id">
                <VendorClosure />
            </Route>
            <Route exact path="/cv/certification-level/:id">
                <CertificateLevelMapping />
            </Route>
            <Route exact path="/cv/statutory-settings/:id/:type">
                <CV_StatutorySettings />
            </Route>
            <Route exact path="/cv/certification-mail/:id">
                <CertificateMailMapping />
            </Route>
            <Route exact path="/cv/certification-mail/:id">
                <CertificateMailMapping />
            </Route>
            <Route exact path="/on-occurrence-compliances/:id/:type">
                <EXE_OnOccurrenceCompliance />
            </Route>
            <Route exact path="/compliance-task-details/:id/:type">
                <EXE_ComplianceTaskDetails/>
            </Route>
        </Switch>
    )

}


export default connect(null)(RoutesLink);
