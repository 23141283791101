import { STATUTORY_UNITWISE_DATA,STATUTORY_UNITWISE_REPORT,STATUTORY_UNITWISE_EXPORT } from '../../types/index'

const initialState = {
    statutoryUnitWiseReport: {
        data: [],
        getTableData:[],
        downloadPath:[]
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case STATUTORY_UNITWISE_DATA: {
            return {
                ...state,
                statutoryUnitWiseReport: {
                    ...state.statutoryUnitWiseReport,
                    data:payload
                },
            };
        }
        case STATUTORY_UNITWISE_REPORT: {
            return {
                ...state,
                statutoryUnitWiseReport: {
                    ...state.statutoryUnitWiseReport,
                    getTableData:payload
                },
            };
        }
        case STATUTORY_UNITWISE_EXPORT: {
            return {
                ...state,
                statutoryUnitWiseReport: {
                    ...state.statutoryUnitWiseReport,
                    downloadPath:payload
                },
            };
        }
        default:
            return state;
    }
}
