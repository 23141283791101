import { CVMONTHWISEREPORT, CVMONTHWISEREPORTEXPORT, CVMONTHWISEREPORTTABLE, CV_MONTH_WISE_REPORT_EXPORT_RESET } from './../../types/index'
const initialState = {
    monthwisestatusreport: {
        compliancewisereport: [],
        tabledata: [],
        export: []
    }
}
export default function(state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
        case CVMONTHWISEREPORT:
            {
                return {
                    ...state,
                    monthwisestatusreport: {
                        ...state.monthwisestatusreport,
                        compliancewisereport: payload,
                    },
                };
            }
        case CVMONTHWISEREPORTTABLE:
            {
                return {
                    ...state,
                    monthwisestatusreport: {
                        ...state.monthwisestatusreport,
                        tabledata: payload,
                    },
                };
            }
        case CVMONTHWISEREPORTEXPORT:
            {
                return {
                    ...state,
                    monthwisestatusreport: {
                        ...state.monthwisestatusreport,
                        export: payload,
                    },
                };
            }
        case CV_MONTH_WISE_REPORT_EXPORT_RESET:
            return {
                ...state,
                monthwisestatusreport: {
                    ...state.monthwisestatusreport,
                    export: [],
                },
            };
        default:
            return state;
    }
}