import React from 'react'
import { Collapse, Select, Input, DatePicker, Card, Drawer, Button, Pagination, Table, Modal, Tooltip } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, Fragment } from 'react';
import { ExclamationCircleTwoTone, InfoCircleOutlined, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { FilterValues, getServiceProviderReportData, getRemarksData, getserviceProviderExport } from '../../../Store/Action/Report/ServiceProviderComplinaceReport';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import moment from 'moment';
import { Toaster } from '../../../Libs/Toaster';
const mapStateToProps = (state) => ({
    filterValue: state.ServiceProviderComplianceReport.ServiceProviderComplianceReport
})
const ServiceProviderComplianceReports = (({
    FilterValues,
    filterValue,
    getServiceProviderReportData,
    getTaskwiseReportData,
    getUnitData,
    getRemarksData,
    getserviceProviderExport
}) => {
    const _ = require("lodash");
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [RemarksModal, setRemarksModal] = useState(false);
    const [titledata, Settitledata] = useState()
    const [tableshow, setTableShow] = useState(false)
    const [childCompliceId, setChildComplinaceId] = useState([])
    const [key, setKey] = useState("1")
    let [index, setIndex] = useState(0)
    const [complianceID, setComplianceID] = useState([])
    const [uniqueData, setUniqueData] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [consolidatedData, setConsolidatedData] = useState({
        serviceProviderName: "",
        country: "",
        category: "",
        user_type: "",
        legel_entity: "",
        legal_entity_name: "",
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "",
        compliance_task_status: "",
        division: "",
        domainName: "",
        countryName: '',
        complianceName: "",
        serviceProvider: ''

    })

    const [open, setOpen] = useState(false);
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const sessionArr = sessionParsedValue.entity_info
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [domain, setDomain] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [checkboxValue, setCheckBoxValue] = useState([0, 1, 2, 3, 8, 9, 10, 11, 32, 35])
    const [exportButton, setExportButton] = useState(false)
    const [division, setDivision] = useState([])
    const [ComplianceTask, setComplianceTask] = useState([])
    const [Acts, setActs] = useState([])
    const [complianceTaskStatus, setcomplianceTaskStatus] = useState([])
    const [user, setuser] = useState([])
    const [serviceProvider, setServiceProvider] = useState([])
    const [frequency, setfrequency] = useState([])
    const [category, setCategoryList] = useState([])
    const [unitcode, setUnitcode] = useState([])
    const [units, setUnits] = useState([])
    const [disableButton, setDisableButton] = useState(false)

    const { Panel } = Collapse;
    const { Option } = Select;
    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviderWiseReport",
                        {
                            "country_id": 1,
                            "legal_entity_id": entityid,
                            "sp_id": Number(consolidatedData.serviceProvider),
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                            "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                            "due_to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                            "task_status": consolidatedData.compliance_task_status ? Number(consolidatedData.compliance_task_status) : 'All',
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": null
                        }
                    ]
                }
            ]
            getServiceProviderReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onClose = () => {
        setOpen(false);
    };

    const disablecondition = (e) => {
        var currentToDate = moment(consolidatedData.from_date).add(3, 'months')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
        else {
            Toaster.error('Please Select Required Fields')
        }
    };

    const handleOk = () => {
        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    // "request": [
                    //     "GetRiskReportData",
                    //     {
                    //         "country_id": Number(consolidatedData.country),
                    //         // "business_group_id": Number(businessGroup.bg_id),
                    //         "legal_entity_id": entityid,
                    //         "domain_id": Number(consolidatedData.domain),
                    //         "division_id": Number(consolidatedData.division),
                    //         "category_id": Number(consolidatedData.category) ? Number(consolidatedData.category) : 0,
                    //         "unit_id": Number(consolidatedData.unit) ? Number(consolidatedData.unit) : 0,
                    //         "parent_id": Number(consolidatedData.act) ? Number(consolidatedData.act) : 0,
                    //         "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                    //         "task_status": consolidatedData.task_status ? consolidatedData.task_status : 'All Exposed Risk',
                    //         "csv": true,
                    //         "from_count": 0,
                    //         "page_count": 25,
                    //         "list_check": checkboxValue
                    //     }
                    // ]
                    "request": [
                        "GetServiceProviderWiseReport",
                        {
                            "country_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "sp_id": Number(consolidatedData.serviceProvider),
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                            "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                            "due_to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                            "task_status": consolidatedData.compliance_task_status ? Number(consolidatedData.compliance_task_status) : "All",
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            getserviceProviderExport({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
    };
    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([])
        }
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalVisible(false)
        setRemarksModal(false)
    };
    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidatedData.legel_entity)

        }
    }, [entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            setConsolidatedData({
                ...consolidatedData,
                legel_entity: localStorage.getItem('SelectedEntityid')
            })
        }

    }, [localStorage.getItem('SelectedEntity')])

    useEffect(() => {
        if (pageState == true) {

            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviderWiseReport",
                        {
                            "country_id": 1,
                            "legal_entity_id": entityid,
                            "sp_id": Number(consolidatedData.serviceProvider),
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            "parent_id": consolidatedData.act ? Number(consolidatedData.act) : 0,
                            "compliance_task": consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": moment(consolidatedData.from_date).format('DD-MMM-YYYY'),
                            "due_to_date": moment(consolidatedData.to_date._d).format('DD-MMM-YYYY'),
                            "task_status": consolidatedData.compliance_task_status ? Number(consolidatedData.compliance_task_status) : "All",
                            "csv": false,
                            "from_count": Math.max(((current - 1) * pageSize), 0) + 1,
                            "page_count": current != 0 ? current * pageSize : pageSize,
                            "list_check": null
                        }
                    ]
                }
            ]
            getServiceProviderReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])
    const ComplianceTaskOnChange = (data, value) => {
        setConsolidatedData({
            ...consolidatedData,
            compliance_task: data,
            complianceName: value.children
        })
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetTaskWiseActReportFilters",
                    {
                        "country_id": localStorage.getItem('SelectedEntity') !== "All Legal Entity" ? sessionArr && sessionArr[0].c_id : Number(consolidatedData.country),
                        "legal_entity_id": entityid,
                        "domain_id": Number(consolidatedData.domain),
                        "comp_tsk_id": Number(data)
                    }
                ]
            }
        ]
        getUnitData({
            payload: payload,
            paramid: paramid
        })
    }
    useEffect(() => {
        let tempArr = []
        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 25) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length && item.map((items) => [items["compliance_id"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.compliance_id
                })

                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { compliance_id: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })

            setUniqueData(tempArr)

        }
    }, [filteredArrayState])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 25) {

            // setTempeditdata(editdata)
            if (uniqueData && uniqueData.length > 0) {
                const ab = uniqueData && uniqueData.length && uniqueData.map(function (item) {
                    if ((array.indexOf(item.statutory_mapping) < 0))
                        array.push(item.statutory_mapping)
                })

                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        uniqueData && uniqueData.length && uniqueData.map((child, childIndex) => {
                            if (data === child.statutory_mapping) {
                                entityArray = {
                                    "activity_date": child.activity_date,
                                    "activity_status": child.activity_status,
                                    "assignee_name": child.assignee_name,
                                    "category_name": child.category_name,
                                    "completion_date": child.completion_date,
                                    "compliance_activity_id": child.compliance_activity_id,
                                    "compliance_description": child.compliance_description,
                                    "compliance_history_id": child.compliance_history_id,
                                    "compliance_id": child.compliance_id,
                                    "compliance_task": child.compliance_task,
                                    "country_id": child.country_id,
                                    "criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    "doc_ref_num": child.doc_ref_num,
                                    "document_name": child.document_name,
                                    "domain_id": child.domain_id,
                                    "due_date": child.due_date,
                                    "extend_due_date": child.extend_due_date,
                                    "frequency_name": child.frequency_name,
                                    "history_count": child.history_count,
                                    "interim_count": child.interim_count,
                                    "legal_entity_id": child.legal_entity_id,
                                    "logo_url": child.logo_url,
                                    "remarks_count": child.remarks_count,
                                    "start_date": child.start_date,
                                    "statutory_mapping": child.statutory_mapping,
                                    "task_status": child.task_status,
                                    "tz_name": child.tz_name,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    " unit_name": child.unit_name,
                                    "url": child.url,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)

            }
        }
        else {
            if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0) {
                const ab = filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0 && filterValue.ServiceProviderComplianceReportList.sp_compliances.map(function (item) {
                    if ((array.indexOf(item.statutory_mapping) < 0))
                        array.push(item.statutory_mapping)
                })

                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0 && filterValue.ServiceProviderComplianceReportList.sp_compliances.map((child, childIndex) => {
                            if (data === child.statutory_mapping) {
                                entityArray = {
                                    "activity_date": child.activity_date,
                                    "activity_status": child.activity_status,
                                    "assignee_name": child.assignee_name,
                                    "category_name": child.category_name,
                                    "completion_date": child.completion_date,
                                    "compliance_activity_id": child.compliance_activity_id,
                                    "compliance_description": child.compliance_description,
                                    "compliance_history_id": child.compliance_history_id,
                                    "compliance_id": child.compliance_id,
                                    "compliance_task": child.compliance_task,
                                    "country_id": child.country_id,
                                    "criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    "doc_ref_num": child.doc_ref_num,
                                    "document_name": child.document_name,
                                    "domain_id": child.domain_id,
                                    "due_date": child.due_date,
                                    "extend_due_date": child.extend_due_date,
                                    "frequency_name": child.frequency_name,
                                    "history_count": child.history_count,
                                    "interim_count": child.interim_count,
                                    "legal_entity_id": child.legal_entity_id,
                                    "logo_url": child.logo_url,
                                    "remarks_count": child.remarks_count,
                                    "start_date": child.start_date,
                                    "statutory_mapping": child.statutory_mapping,
                                    "task_status": child.task_status,
                                    "tz_name": child.tz_name,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    " unit_name": child.unit_name,
                                    "url": child.url,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)

            }
        }


    }, [filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances, uniqueData])


    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0) {
            let uniqueObjArray = [...new Map(filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0 && filterValue.ServiceProviderComplianceReportList.sp_compliances.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 0 && filterValue.ServiceProviderComplianceReportList.sp_compliances, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances])
    useEffect(() => {
        if (filteredArray.length) {
            let temp = []
            for (let i in filteredArray) {
                temp = filteredArray[i]
            }
            setfilteredArrayState([...filteredArrayState, ...temp])
        }

    }, [filteredArray])
    const chilData = (id, unitId) => {
        setIsModalVisible(true)
        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances.length > 25) {
            let tempArr = []
            for (let i in filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances) {
                if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].compliance_id == id) {
                    if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].unit_id == unitId) {
                        tempArr.push(filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i])
                    }
                }
            }
            setChildComplinaceId(tempArr)

        }
    }
    const columns = [
        {
            title: 'User Name',
            ellipsis: true,
            width: '50px',
            dataIndex: 'assignee_name',
            key: 'assignee_name',
        },
        {
            title: 'Activity Status',
            ellipsis: true,
            width: '50px',
            dataIndex: 'activity_status',
            key: 'activity_status',
        },
        {
            title: 'Activity Date',
            ellipsis: true,
            width: '50px',
            dataIndex: 'activity_date',
            key: 'activity_date',
        },
        {
            title: 'Task Completion Date/Document issued Date',
            ellipsis: true,
            width: '50px',
            dataIndex: 'completion_date',
            key: 'completion_date',
        },

    ];
    const column = [
        {
            title: 'Activity Date',
            ellipsis: true,
            width: '50px',
            dataIndex: 'activity_on',
            key: 'activity_on',
        },
        {
            title: 'User Type',
            ellipsis: true,
            width: '50px',
            dataIndex: 'user_category_id',
            key: 'user_category_id',
            render: (row, index, record) => {

                return (
                    <>
                        <p>Assignee</p>
                    </>
                )
            }
        },
        {
            title: 'Reviewer Report',
            ellipsis: true,
            width: '50px',
            dataIndex: 'activity_date',
            key: 'activity_date',
        },
        {
            title: 'Remarks',
            ellipsis: true,
            width: '50px',
            dataIndex: 'remarks',
            key: 'remarks',
        },

    ];
    const item = [
        {
            compliance: "manoj"
        }
    ]
    const onChange = (key) => {
        console.log(key);
    };
    // useEffect(() => {
    //     if (isAuth && entityid != null) {
    //         const payload = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetServiceProviderWiseReportFilters",
    //                     {
    //                         "country_id": sessionArr && sessionArr.length && sessionArr[0].c_id,
    //                         "legal_entity_id": entityid
    //                     }
    //                 ]
    //             }
    //         ]
    //         FilterValues({
    //             payload: payload,
    //             paramid: paramid
    //         })
    //     }
    // }, [isAuth, authtoken, paramid, entityid])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviderWiseReportFilters",
                        {
                            "country_id": sessionArr && sessionArr.length && sessionArr[0].c_id,
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                FilterValues({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid])

    useEffect(() => {
        validator.current.showMessages()
    }, [])


    useEffect(() => {
        if (filterValue) {
            setServiceProvider(filterValue && filterValue.Data && filterValue.Data.sp_list)
            setComplianceTask(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list)
            setDomain(filterValue && filterValue.Data && filterValue.Data.sp_domains_list)
            setActs(filterValue && filterValue.Data && filterValue.Data.sp_act_task_list)
            setfrequency(filterValue && filterValue.Data && filterValue.Data.compliance_frequency)
            setCategoryList(filterValue && filterValue.Data && filterValue.Data.categories_list)
            setcomplianceTaskStatus(filterValue && filterValue.Data && filterValue.Data.compliance_task_status)
            setUnitcode(filterValue && filterValue.Data && filterValue.Data.unit_code_label_list)
            // setUnits(filterValue && filterValue.Data && filterValue.Data.sp_unit_list)
            setDivision(filterValue && filterValue.Data && filterValue.Data.divisions_list)
            setuser(filterValue && filterValue.Data && filterValue.Data.sp_users_list)
        }


    }, [filterValue])

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>

                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Service Provider Compliances</span>
                                </li>

                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" style={{ position: 'relative', padding: "15px 5px" }}>
                            <Collapse defaultActiveKey={["1"]} onChange={onChange}  >
                                <Panel header="Service Provider Compliances" key={key} >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <div className="form-group">

                                                        <Select
                                                            // showSearch
                                                            // optionFilterProp="children"
                                                            // disabled={unit.legal_entity === "" ? true : false}
                                                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            value={consolidatedData.country || undefined}
                                                            onChange={(data, value) => {
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    country: data,
                                                                    countryName: value.children,
                                                                    serviceProviderName: "",
                                                                    category: "",
                                                                    user_type: "",
                                                                    legel_entity: "",
                                                                    legal_entity_name: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: "",
                                                                    serviceProvider: ''
                                                                })

                                                            }
                                                            }
                                                            // value={compfie.user_Group}
                                                            style={{ width: '100%', marginTop: '5px' }}

                                                        >
                                                            {/* {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })} */}
                                                            <Option key={sessionArr && sessionArr.length && sessionArr[0].c_id}>
                                                                {sessionArr && sessionArr.length && sessionArr[0].c_name}
                                                            </Option>
                                                        </Select>

                                                        {validator.current.message(
                                                            'Country',
                                                            consolidatedData.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required ',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{sessionArr && sessionArr.length && sessionArr[0].c_name}</p>
                                                }

                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Category : </b></label>
                                                <Select
                                                    value={consolidatedData.category || undefined}
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter category"

                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            category: data
                                                        })
                                                        // let unitData = _.filter(units && units.length > 0 && units, { category_id: Number(data) })
                                                        // setUnits(unitData)
                                                    }
                                                    }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    {category && category.length && category.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>

                                            <div className='col-md-4'>
                                                <label><b>From Date : </b><span style={{ color: "red" }}>*</span></label>
                                                <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}
                                                    allowClear={false}
                                                    value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                                    onChange={(date, dateString) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            from_date: dateString,
                                                            to_date: moment(dateString).add(3, 'months')
                                                        })

                                                    }}

                                                />
                                                {validator.current.message(
                                                    'from_date',
                                                    consolidatedData.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required ',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity : </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <div className="form-group">

                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            value={consolidatedData.legel_entity || undefined}
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(data, value) => {
                                                                // setCurrentEntity(data)
                                                                setConsolidatedData({

                                                                    ...consolidatedData,
                                                                    legel_entity: data,
                                                                    legal_entity_name: value.children,
                                                                    serviceProviderName: "",
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: "",
                                                                    serviceProvider: ''
                                                                })
                                                                setCurrentEntity(data)
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            disabled={consolidatedData.country == '' ? true : false}

                                                        >
                                                            [
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}


                                                        </Select>

                                                        {validator.current.message(
                                                            'legalEntity',
                                                            consolidatedData.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }

                                            </div>
                                            <div className='col-md-4'>

                                                <label><b>Vendor : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.unit || undefined}
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter Vendor"
                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            unit: data
                                                        })
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    {units && units.length > 0 && units.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {`${item.unit_code}-${item.unit_name}`}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>

                                                <label><b>To Date : </b> <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '5px' }}

                                                    onChange={(date, dateString) => {
                                                        disablecondition(dateString)
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            to_date: dateString._i
                                                        })
                                                    }}
                                                    value={consolidatedData.to_date}
                                                    disabledDate={(current) => {
                                                        return moment(consolidatedData.from_date).add(1, 'days') >= current ||
                                                            moment(consolidatedData.from_date).add(1, 'year') < current;
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'To-Date',
                                                    consolidatedData.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required ',
                                                        }
                                                    })}
                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4' >
                                                <label><b>Service Provider : </b><span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter Service Provider"

                                                    onChange={(data, value) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            serviceProvider: data,
                                                            serviceProviderName: value.children,
                                                            category: "",
                                                            user_type: "",
                                                            unit: '',
                                                            user: "",
                                                            domain: "",
                                                            act: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "",
                                                            division: "",
                                                            domainName: "",
                                                            complianceName: "",
                                                        })

                                                        let filteredDomain = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_domains_list, { sp_id_optional: Number(data) })
                                                        setDomain(filteredDomain)

                                                        let filteredUnit = _.filter(filterValue && filterValue.Data && filterValue.Data.sp_unit_list, { sp_id_optional: Number(data) })
                                                        setUnits(filteredUnit)
                                                    }
                                                    }
                                                    value={consolidatedData.serviceProvider || undefined}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    disabled={consolidatedData.legel_entity == '' ? true : false}
                                                >
                                                    {serviceProvider && serviceProvider.length && serviceProvider.map((item, i) => {
                                                        return (
                                                            <Option key={item.sp_id}>
                                                                {item.sp_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>

                                                {validator.current.message(
                                                    'serviceprovider',
                                                    consolidatedData.serviceProvider,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Service Provider Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Act : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.act || undefined}
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter Act"
                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            act: data
                                                        })
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', margintop: '5px' }}
                                                >
                                                    {Acts && Acts.length > 0 && Acts.map((item, i) => {
                                                        return (
                                                            <Option key={item.statutory_mapping}>
                                                                {item.statutory_mapping}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>

                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>User : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.user || undefined}
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter User"
                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            user: data
                                                        })
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                >
                                                    {user && user.length && user.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_id}>
                                                                {item.user_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>




                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            {/* <div className='col-md-4'>
                                                <label>Task Category</label>
                                                <Select
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter Domain"
                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            task_category: data
                                                        })
                                                        let subData = _.filter(SubtaskCategory && SubtaskCategory.length > 0 && SubtaskCategory, { task_category_id: Number(data) })
                                                        setSubtaskCategory(subData)
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%' }}
                                                >
                                                    {taskCategory && taskCategory.length > 0 && taskCategory.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_category_id}>
                                                                {item.task_category_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div> */}


                                            <div className='col-md-4'>

                                                <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                {/* <Select
                                                        onChange={(value) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                domain: value
                                                            })
                                                        }}
                                                        placeholder="Enter Domain Name"
                                                        style={{ width: '280px' }}
                                                    >
                                                        <Option key="India">India</Option>
                                                    </Select> */}
                                                <Select
                                                    disabled={consolidatedData.serviceProvider == '' ? true : false}
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    allowClear={false}
                                                    size="default"
                                                    value={consolidatedData.domain || undefined}
                                                    placeholder="Enter Domain"
                                                    onChange={(data, value) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            domain: data,
                                                            domainName: value.children,
                                                            category: "",
                                                            user_type: "",
                                                            unit: '',
                                                            user: "",
                                                            act: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "",
                                                            division: "",
                                                            complianceName: "",
                                                        })
                                                        // let domainData = _.filter(taskCategory && taskCategory.length > 0 && taskCategory, { task_domain_id: Number(data) })
                                                        // setaskCategory(domainData)
                                                    }

                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                >
                                                    {domain && domain.length && domain.map((item, i) => {
                                                        return (
                                                            <Option key={item.domain_id}>
                                                                {item.domain_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidatedData.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}




                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task : </b></label>
                                                {/* <Input placeholder="Enter Compliance Task" style={{ width: '280px' }}
                                                // onChange={(e) => {
                                                //     setConsolidatedDate({
                                                //         ...consolidateddata,
                                                //         compliance_task: e.target.value
                                                //     })
                                                // }}
                                                /> */}
                                                <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '5px' }}

                                                    // onChange={(e) => {
                                                    //     setConsolidatedDate({
                                                    //         ...consolidateddata,
                                                    //         compliance_task: e.target.value
                                                    //     })
                                                    // }}
                                                    disabled={consolidatedData.domain == '' ? true : false} />

                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task Status : </b></label>
                                                <Select
                                                    placeholder='Enter Compliance Task Status'
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.compliance_task_status || undefined}
                                                    onChange={(value) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            compliance_task_status: value
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                >
                                                    <Option key='All'>All</Option>
                                                    {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_status}>
                                                                {item.task_status}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Division : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    allowClear={false}
                                                    size="default"
                                                    value={consolidatedData.division || undefined}
                                                    placeholder="Enter Division"
                                                    onChange={(data) => {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            division: data
                                                        })
                                                        let categorydata = _.filter(category, { div_id: Number(data) })
                                                        setCategoryList(categorydata)
                                                    }
                                                    }
                                                    // onChange={(data) => {
                                                    //     setValue({
                                                    //         ...value,
                                                    //         userGroup: data
                                                    //     })
                                                    //     setCompfie({
                                                    //         ...compfie,
                                                    //         user_Group: data
                                                    //     })
                                                    // }
                                                    // }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>


                                            </div>



                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='row'>



                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                                <Button disabled={disableButton} type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px", width: "100px" }} onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852", width: "100px" }}
                                                    onClick={showModal}     >
                                                    Export
                                                </Button>

                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>

                                </Panel>
                            </Collapse>
                            {/* <Drawer title="Basic Drawer" width={400} placement="right" visible={open} onClose={onClose}>
                                <Card>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>Country : <span style={{ fontWeight: 'bold' }}> {consolidatedData.country}</span> </label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Category : <span style={{ fontWeight: 'bold' }}>{consolidatedData.category}</span></label>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>User Type :<span style={{ fontWeight: 'bold' }}> {consolidatedData.user_type}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Legal Entity :<span style={{ fontWeight: 'bold' }}> {consolidatedData.legel_entity}</span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>Vendor :<span style={{ fontWeight: 'bold' }}>{consolidatedData.unit}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>User : <span style={{ fontWeight: 'bold' }}>{consolidatedData.user}</span></label>
                                            </div>



                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <label>Domain:<span style={{ fontWeight: 'bold' }}> {consolidatedData.domain}</span></label>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label>Act : <span style={{ fontWeight: 'bold' }}>{consolidatedData.act}</span></label>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <label>Task Category : <span style={{ fontWeight: 'bold' }}>{consolidatedData.task_category}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Compliance Task : <span style={{ fontWeight: 'bold' }}>{consolidatedData.compliance_task}</span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <label>To Date :<span style={{ fontWeight: 'bold' }}> {consolidatedData.to_date}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>From Date : <span style={{ fontWeight: 'bold' }}>{consolidatedData.from_date}</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>Task Sub Category :<span style={{ fontWeight: 'bold' }}> {consolidatedData.task_sub_category}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Compliance Frequency : <span style={{ fontWeight: 'bold' }}>{consolidatedData.compliance_frequency}</span></label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <label>Compliance Task Status : <span style={{ fontWeight: 'bold' }}>{consolidatedData.compliance_task_status}</span></label>
                                            </div>
                                            <div className='col-md-6'>
                                                <label>Division : <span style={{ fontWeight: 'bold' }}>{consolidatedData.division}</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Drawer> */}


                            {tableshow == true ?
                                <>

                                    <div className='col-md-12 mt-3 mb-3' >
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidatedData.legal_entity_name : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Country :</b> {sessionArr && sessionArr[0].c_name}</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain :</b> {consolidatedData.domainName}</label>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <div className="row">
                                            <label><b>Service Provider :</b> {consolidatedData.serviceProviderName}</label>
                                        </div>
                                    </div>

                                    <div className='ant-table-wrapper'>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content fh userprivclass1'>
                                                    <table className='widthHeigh table-fixed'>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        {/* <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup> */}
                                                        <thead className='ant-table-thead'>
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: '50px' }}> # </th>
                                                                <th className='ant-table-cell' style={{ width: '250px' }}>Compliance Task</th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}>Criticality</th>
                                                                <th className='ant-table-cell' style={{ width: '140px' }}>Frequency</th>
                                                                <th className='ant-table-cell' style={{ width: '140px' }}><center>Due Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '150px' }}>Compliance Task Status</th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>User Name</center></th>
                                                                <th className='ant-table-cell' style={{ width: '140px' }}><center>Activity Status</center></th>
                                                                <th className='ant-table-cell' style={{ width: '150px' }}><center>Activity Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '200px' }}><center>Uploaded Document</center></th>
                                                                <th className='ant-table-cell' style={{ width: '240px' }}><center>Task Completion Date/Document issued Date</center></th>
                                                            </tr>
                                                        </thead>
                                                        {filteredArrayState && filteredArrayState.length > 0 ?
                                                            filteredArrayState.map((item, i) => {
                                                                return (
                                                                    <tbody className='ant-table-tbody'>
                                                                        <tr className="bg-lightdark">
                                                                            <td colSpan="100%">
                                                                                <table className='white' style={{ tableLayout: 'auto' }}>
                                                                                    <tbody className='ant-table-tbody'>
                                                                                        <div className='row m-3'>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Vendor :</b> {item[0].unit}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Category :</b> {item[0].category_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='row m-3'>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Division :</b> {item[0].division_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3 ' >
                                                                                                <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className='ant-table-cell w6'><b>Vendor :</b></td>
                                                                                            <td className='ant-table-cell w15' title={item[0].unit_name}>{item[0].unit_name}</td>
                                                                                            <td className='ant-table-cell w6'><b>Category :</b></td>
                                                                                            <td className='ant-table-cell w40'><span className="category-name">{item[0].category_name}</span></td>
                                                                                        </tr>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className='ant-table-cell w6'><b>Division :</b></td>
                                                                                            <td className='ant-table-cell w15' >{item[0].division_name}</td>
                                                                                            <td className='ant-table-cell w6'><b>Time Zone :</b></td>
                                                                                            <td className='ant-table-cell w40'><span className="category-name">{item[0].tz_name}</span></td>
                                                                                        </tr> */}
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                        {titledata && titledata.map((itemdata, i) => {
                                                                            let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                            if (data != undefined) {
                                                                                if (data.statutory_mapping == itemdata.parent) {
                                                                                    return <Fragment>

                                                                                        <tr className='ant-table-row ant-table-row-level-0'>

                                                                                            <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                        </tr>

                                                                                        {itemdata.child.map((items) => {
                                                                                            if (item[0].unit_id == items.unit_id) {
                                                                                                let array = []

                                                                                                for (let i in filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances) {
                                                                                                    if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].compliance_id == items.compliance_id) {
                                                                                                        if (filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i].unit_id == items.unit_id) {
                                                                                                            array.push(filterValue && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList && filterValue.ServiceProviderComplianceReportList.sp_compliances && filterValue.ServiceProviderComplianceReportList.sp_compliances[i])
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                return <tr>
                                                                                                    <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                    <td className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                        <ExclamationCircleTwoTone />
                                                                                                    </Tooltip> &nbsp;{array.length > 1 ? <a href='javascript:;' onClick={() => {
                                                                                                        chilData(items.compliance_id, items.unit_id)
                                                                                                    }}>{items.compliance_task}</a> : <a >{items.compliance_task}</a>}</td>
                                                                                                    <td className='ant-table-cell'>{items.criticality_name}</td>
                                                                                                    <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                                    <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                    <td className='ant-table-cell'><center>{items.task_status}</center></td>
                                                                                                    <td className='ant-table-cell'><center>{items.assignee_name}</center></td>
                                                                                                    {items.history_count < 1 ? <td className='ant-table-cell'><center>{items.activity_status}</center></td> :

                                                                                                        <td className='ant-table-cell'><Tooltip title="Click to view Remarks history">
                                                                                                            <ExclamationCircleTwoTone />
                                                                                                        </Tooltip>  <a onClick={() => {
                                                                                                            const payload = [
                                                                                                                authtoken,
                                                                                                                {
                                                                                                                    "session_token": authtoken,
                                                                                                                    "request": [
                                                                                                                        "GetComplianceRemarksHistoryData",
                                                                                                                        {
                                                                                                                            "legal_entity_id": entityid,
                                                                                                                            "unit_id": items.unit_id,
                                                                                                                            "compliance_history_id": items.compliance_history_id
                                                                                                                        }
                                                                                                                    ]
                                                                                                                }
                                                                                                            ]

                                                                                                            getRemarksData({
                                                                                                                payload: payload,
                                                                                                                paramid: paramid
                                                                                                            })
                                                                                                            setRemarksModal(true)
                                                                                                        }}>{items.activity_status}</a></td>
                                                                                                    }
                                                                                                    <td className='ant-table-cell'><center>{items.activity_date}</center></td>
                                                                                                    <td className='ant-table-cell'><center>{items.url}</center></td>
                                                                                                    <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                                    {/* </tr> */}
                                                                                                    <span hidden>{index = index + 1}</span>

                                                                                                </tr>
                                                                                            }

                                                                                        })

                                                                                        }
                                                                                    </Fragment>
                                                                                }
                                                                            }
                                                                        })
                                                                        }

                                                                    </tbody>
                                                                )
                                                            }) :
                                                            <tr style={{ marginTop: '5%' }}>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</td>

                                                            </tr>}
                                                    </table>
                                                    <br />




                                                    <div>
                                                        {/* <button
                                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                                    onClick={submitrec}>Submit</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {index != 0 ? 
                                    <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                        <label style={{ marginLeft: "28px" }}>Showing {pageSize * (current - 1) + 1} to {index} of {filterValue && filterValue.count} entries</label>
                                    </div> : ''}
                                    <div style={{ position: 'absolute', bottom: '-25px', right: 0 }}>
                                        <Pagination
                                            current={current}
                                            showSizeChanger={true}
                                            // showQuickJumper={true}
                                            onShowSizeChange={() => {
                                                setCurrent(1)
                                                // setpageState(true)
                                            }}
                                            pageSizeOptions={[25, 50, 100]}
                                            hideOnSinglePage={true}
                                            defaultPageSize={25}
                                            onChange={(page, pageSizes) => {
                                                setpageState(true)
                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                setPageSize(pageSizes)
                                            }} total={filterValue && filterValue.count}
                                        // showTotal={(total) => `Showing 1 to ${index} of ${filterValue && filterValue.count} entries`}
                                        />

                                    </div>
                                </>
                                : false}
                            {/* <Modal title="Select Fields" visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                                <div id="form">
                                    <input type="hidden" class="popup_mode" autocomplete="off" />
                                    <div id="form-list">
                                        <div class="portlet-body p-t-0">
                                            <div class="table-responsive">
                                                <table id="datatable-responsive2" class="table table-striped dt-responsive nowrap" cellspacing="0" width="50%">
                                                    <thead>
                                                        <tr class="custom-modal-title">
                                                            <th>
                                                                <input id="select_all" width="100%" type="checkbox" autocomplete="off" onClick={(e) => {
                                                                    let checked = e.target.checked
                                                                    selectAllCheckBox(checked)
                                                                }} /> Select All
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody id="tbody-form-list"><tr><th>
                                                        <input id="column_1" name='chk' type="checkbox" disabled="disabled" checked value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> Legal Entity</th>
                                                        <th><input id="column_2" name='chk' type="checkbox" disabled="disabled" checked value='2' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code</th></tr>
                                                        <tr><th><input id="column_3" name='chk' type="checkbox" disabled="disabled" checked value='3' onChange={(e) => checkBoxOnChange(e)} /> Vendor Name</th>
                                                            <th><input id="column_4" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 1</th></tr><tr><th>
                                                                <input id="column_5" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 2</th><th>
                                                                <input id="column_6" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 3</th></tr>
                                                        <tr><th><input id="column_7" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 4</th>
                                                            <th><input id="column_8" name='chk' type="checkbox" disabled="disabled" checked value='8' onChange={(e) => checkBoxOnChange(e)} /> Location</th></tr>
                                                        <tr><th><input id="column_9" name='chk' type="checkbox" disabled="disabled" value='9' checked onChange={(e) => checkBoxOnChange(e)} /> Act / Rules</th>
                                                            <th><input id="column_10" name='chk' type="checkbox" disabled="disabled" value='10' checked onChange={(e) => checkBoxOnChange(e)} /> Compliance Task</th></tr>
                                                        <tr><th><input id="column_11" name='chk' type="checkbox" disabled="disabled" value='11' checked onChange={(e) => checkBoxOnChange(e)} /> Frequency</th>
                                                            <th><input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Periodicity</th></tr>
                                                        <tr><th><input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Criticality</th>
                                                            <th><input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Division</th></tr>
                                                        <tr><th><input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Category</th>
                                                            <th><input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assigned by</th></tr>
                                                        <tr><th><input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned To</th>
                                                            <th><input id="column_18" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date</th></tr>
                                                        <tr><th><input id="column_19" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Assignee</th>
                                                            <th><input id="column_20" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Completed on</th></tr>
                                                        <tr><th><input id="column_21" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurrer</th>
                                                            <th><input id="column_22" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Concurred on</th></tr>
                                                        <tr><th><input id="column_23" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approver</th>
                                                            <th><input id="column_24" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Approved on</th></tr>
                                                        <tr><th><input id="column_25" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Activity Status</th>
                                                            <th><input id="column_26" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Start Date</th></tr>
                                                        <tr><th><input id="column_27" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /> Due Date</th>
                                                            <th><input id="column_28" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> Extend Due Date</th></tr>
                                                        <tr><th><input id="column_29" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} />Task Completion Date / Document Issued Date</th>
                                                            <th><input id="column_30" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} />Month</th></tr>
                                                        <tr><th><input id="column_31" name='chk' type="checkbox" value='31' onChange={(e) => checkBoxOnChange(e)} /> Validity Date </th>
                                                            <th><input id="column_32" name='chk' type="checkbox" disabled="disabled" checked value='32' onChange={(e) => checkBoxOnChange(e)} /> Statutory Status</th></tr>
                                                        <tr><th><input id="column_33" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} />Duration</th>
                                                            <th><input id="column_34" name='chk' type="checkbox" value='34' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number</th></tr>
                                                        <tr><th><input id="column_33" name='chk' type="checkbox" disabled="disabled" checked value='35' onChange={(e) => checkBoxOnChange(e)} />Remarks</th></tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions">
                                    <Button type="primary" shape="round" className='addbutton'
                                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '40%' }}
                                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                    </Button>
                                </div>
                            </Modal> */}
                            <Modal title="Select Fields" visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                                <div className='row'>
                                    <div className='col-lg-3'><input id="select_all" width="100%" type="checkbox" autocomplete="off" onClick={(e) => {
                                        let checked = e.target.checked
                                        selectAllCheckBox(checked)
                                    }} /> Select All</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} />Legal Entity </div>
                                    <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code</div>
                                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Vendor Name</div>
                                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} />Vendor Code 1</div>

                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 2 </div>
                                    <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Vendor Code 3 </div>
                                    <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} />Vendor Code 4</div>
                                    <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" value='8' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} />Location</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='9' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Act / Rules</div>
                                    <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='10' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Compliance Task </div>
                                    <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='11' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Frequency</div>
                                    <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Periodicaly </div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Criticality</div>
                                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Division</div>
                                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Category</div>
                                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assignee By</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned To</div>
                                    <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date</div>
                                    <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Assignee </div>
                                    <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Completed On</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurrer </div>
                                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Concurred On </div>
                                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approver </div>
                                    <div className='col-lg-3'><input id="column_25" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Approved On</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_26" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Activity Status</div>
                                    <div className='col-lg-3'><input id="column_27" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Start Date</div>
                                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} /> Due Date </div>
                                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='28' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} />Extend Due Date </div>

                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date / Document Issued Date</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> Month</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='31' onChange={(e) => checkBoxOnChange(e)} /> Validity Date</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='32' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Validity Date</div>

                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} /> Duration</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='34' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='35' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Remarks</div>


                                </div>
                                <div className="form-actions">
                                    <Button type="primary" shape="round" className='addbutton'
                                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '40%' }}
                                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                    </Button>
                                </div>

                            </Modal>
                            <Modal visible={isModalVisible} onCancel={handleCancel} onOk={() => {
                                setIsModalVisible(false)
                            }} >
                                <Table
                                    className='userprivclass'
                                    columns={columns}
                                    dataSource={childCompliceId}
                                    bordered
                                    pagination={false} />
                            </Modal>
                            <Modal visible={RemarksModal} onCancel={handleCancel} onOk={() => {
                                setRemarksModal(false)
                            }} >
                                <Table
                                    className='userprivclass'
                                    columns={column}
                                    dataSource={filterValue && filterValue.RemarksData && filterValue.RemarksData.remarks_history}
                                    bordered
                                    pagination={false} />
                                {/* <table  >
                                    <tbody className='ant-table-tbody'>
                                        {
                                            console.log(childCompliceId,'childCompliceId'),
                                            childCompliceId && childCompliceId.length > 0 && childCompliceId.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <td>{item.user_name}</td>
                                                        <td>{item.due_date}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table> */}
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default connect(mapStateToProps, {
    FilterValues,
    getServiceProviderReportData,
    getserviceProviderExport,
    getRemarksData
})(ServiceProviderComplianceReports);






