import React, { Fragment } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Collapse, Select, Input, DatePicker, Card, Modal, Button, Table, Tooltip, Pagination } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';
import { AuditTrailReportFilters, AuditTrailReportData, ExportAuditTrailReportData } from '../../../Store/Action/Report/AuditTrail';
import { AUDIT_TRAIL_REPORT_EXPORT_RESET } from '../../../Store/types/index'
import { unionBy, uniqBy } from 'lodash';

const mapStateToProps = (state) => ({
    audittrailreports: state.AuditTrail.audittrailreport,
})

const AuditTrail = ({
    AuditTrailReportFilters,
    AuditTrailReportData,
    ExportAuditTrailReportData,
    audittrailreports: { audittrailfilter, audittrailshow, audittrailexport }
}) => {
    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const dispatch = useDispatch();
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid').replaceAll('"', ''));
    const paramid = location.pathname;
    const _ = require("lodash");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    let [count, setcount] = useState(0)
    const [key, setKey] = useState("1")
    const [legalstate, Setlegalstate] = useState(false)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [totalcount, setTotalcount] = useState("")
    const [disableButton, setDisableButton] = useState(false)
    const [formArray, setFormArray] = useState([])

    const [unitdata, setUnitData] = useState({
        user: 0,
        user_name: '',
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        from_date: "",
        to_date: "",
        form_id: 0,
        form_name: '',
        time_zone_id: '',
        time_zone_name: ''
    })
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;

    const disablecondition = (e) => {
        var currentToDate = moment(unitdata.from_date).add(3, 'months')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                if (entityid != "null") {
                    AuditTrailReportFilters({
                        payload: [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetAuditTrailReportFilters",
                                    {
                                        "legal_entity_id": entityid
                                    }
                                ]
                            }
                        ],
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }
    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        validator.current.showMessages()
    }, [])


    useEffect(() => {
        if (filteredArray.length) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (pageState === true) {
            setcount(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        if (audittrailfilter && audittrailfilter.audit_forms_list && audittrailfilter.audit_forms_list.length > 0) {
            const uniqueArray = _.uniqBy(audittrailfilter.audit_forms_list, function (e) {
                return e.form_id;
            });
            setFormArray(uniqueArray)
        }

    }, [audittrailfilter])



    useEffect(() => {
        if (entityid != null) {
            setUnitData({
                ...unitdata,
                country: sessionArr[0].c_id
            })
        }
    }, [entityid != null])

    useEffect(() => {
        if (unitdata.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [unitdata.legal_entity_name])

    useEffect(() => {
        if (audittrailexport && audittrailexport.link) {
            window.open('https://13.234.100.64/' + audittrailexport.link);
            dispatch({
                type: AUDIT_TRAIL_REPORT_EXPORT_RESET
            });
        }

    }, [audittrailexport])
    useEffect(() => {
        if (audittrailshow && audittrailshow.total_count > 0) {

            setTotalcount(audittrailshow.total_count)
        }
    }, [audittrailshow])

    console.log(audittrailshow, 'audittrailshow');

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            AuditTrailReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetAuditTrailReportData",
                            {
                                "legal_entity_id": entityid,
                                "user_id": Number(unitdata.user),
                                "form_id_optional": Number(unitdata.form_id),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": false,
                                "from_count": 0,
                                "page_count": 25,
                                "check_count": true,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'
                            }
                        ]
                    }
                ],
                paramid: paramid
            })

        }
    }
    useEffect(() => {
        if (pageState == true) {
            AuditTrailReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetAuditTrailReportData",
                            {
                                "legal_entity_id": entityid,
                                "user_id": Number(unitdata.user),
                                "form_id_optional": Number(unitdata.form_id),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": false,
                                "from_count": Math.max(((current - 1) * pageSize), 0),
                                "page_count": current != 0 ? current * pageSize : pageSize,
                                "check_count": true,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'


                            }
                        ]
                    }
                ],
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])
    const onexport = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            setKey(0)
            ExportAuditTrailReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetAuditTrailReportData",
                            {
                                "legal_entity_id": entityid,
                                "user_id": Number(unitdata.user),
                                "form_id_optional": Number(unitdata.form_id),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": true,
                                "from_count": 0,
                                "page_count": 0,
                                "check_count": true,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'
                            }
                        ]
                    }
                ],
                paramid: paramid
            })

        }
    }

    const onChange = (key) => {
        // console.log(key);
    };

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    console.log(audittrailfilter, 'audittrailfilter');

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-0 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                </li>

                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ color: '#1890ff' }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Log Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span style={{ color: '#1890ff' }}>Audit Trail</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" id='only-restricted' style={{ position: 'relative', padding: "10px 3px" }}>
                            <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onChange} style={{ backgroundColor: '#5daae3' }}>
                                <Panel header=" Audit Trail" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Legal Entity: <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(data, value) => {
                                                                Setlegalstate(true)
                                                                setCurrentEntity(data)
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    legal_entity_id: data,
                                                                    legal_entity_name: value.children
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={unitdata.legal_entity_name || undefined}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legal_entity_id',
                                                            unitdata.legal_entity_name,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date: </b><span style={{ color: "red" }}>*</span></label>
                                                <DatePicker
                                                    disabled={unitdata.from_date == '' ? true : false}
                                                    disabledDate={(current) => {
                                                        return moment(unitdata.from_date).format('DD-MMM-YYYY') > current || current > Date.now() || moment(unitdata.from_date).add(1, 'days').format('DD-MMM-YYYY') >= current ||
                                                            moment(unitdata.from_date).add(1, 'year').format('DD-MMM-YYYY') < current || current.year() < 2012;
                                                    }}
                                                    format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                    value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    allowClear={true}
                                                    onChange={(date, dateString) => {
                                                        if (date !== null) {
                                                            disablecondition(dateString)
                                                            setUnitData({
                                                                ...unitdata,
                                                                to_date: moment(dateString).format('DD-MMM-YYYY') > moment(unitdata.from_date).format('DD-MMM-YYYY') ? moment(dateString).format('DD-MMM-YYYY') : moment(unitdata.from_date).format('DD-MMM-YYYY')
                                                            })
                                                        }
                                                        else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                to_date: ''
                                                            })
                                                        }
                                                    }}
                                                />
                                                {/* <label style={{ "fontWeight": "bold" }}>To Date <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker style={{ width: '100%' }} value={unitdata.to_date}
                                                    onChange={(date, dateString) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: dateString._i
                                                        })
                                                    }}
                                                /> */}
                                                {validator.current.message(
                                                    'to_date',
                                                    unitdata.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Form Name:</label>
                                                <Select allowClear={false}
                                                    placeholder='Enter Form Name'
                                                    value={unitdata.form_name || undefined}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            form_id: value,
                                                            form_name: data.children
                                                        })
                                                    }}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                >
                                                    {formArray && formArray.length > 0 && formArray.map((item, i) => {
                                                        return (
                                                            <Option key={item.form_id}>
                                                                {item.form_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                {/* value={unitdata.from_date ? moment(unitdata.from_date) : ''} */}
                                                <label><b>From Date:</b> <span style={{ color: "red" }} >*</span></label>
                                                <DatePicker format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' value={unitdata.from_date ? moment(unitdata.from_date) : ''} style={{ width: '100%', marginTop: '5px' }}
                                                    allowClear={true}
                                                    disabledDate={(current) => {
                                                        return current.year() < 2012 || current > Date.now();
                                                    }}
                                                    onChange={(date, dateString) => {
                                                        if (date !== null) {
                                                            console.log(moment(new Date(dateString)).format('DD-MMM-YYYY'), 'test')
                                                            if (moment(new Date(dateString)).format('DD-MMM-YYYY') > unitdata.to_date) {
                                                                if (unitdata.from_date !== '') {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        from_date: moment(new Date(dateString)).format('DD-MMM-YYYY'),
                                                                        to_date: moment(new Date(dateString)).format('DD-MMM-YYYY')

                                                                    })
                                                                }
                                                                  else {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    from_date: moment(new Date(dateString)).format('DD-MMM-YYYY')
                                                                })
                                                            }
                                                            }
                                                            else {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    from_date: moment(new Date(dateString)).format('DD-MMM-YYYY')
                                                                })
                                                            }

                                                        }
                                                        else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                from_date: ''
                                                            })
                                                        }
                                                    }}
                                                />
                                                {/* <label style={{ "fontWeight": "bold" }}>From Date <span style={{ color: "red" }} value={unitdata.from_date ? moment(unitdata.from_date) : ''}>*</span></label>
                                                <DatePicker style={{ width: '100%' }}
                                                    onChange={(date, dateString) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: dateString,
                                                            to_date: moment(dateString).add(3, 'months')
                                                        })
                                                    }}
                                                /> */}
                                                {validator.current.message(
                                                    'from_date',
                                                    unitdata.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>User:</label>
                                                <Select allowClear={false}
                                                    value={unitdata.user_name || undefined}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            user: value,
                                                            user_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter User"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                >
                                                    {audittrailfilter && audittrailfilter.audit_users_list && audittrailfilter.audit_users_list.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_id}>
                                                                {item.user_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Display in Time Zone:</label>
                                                <Select allowClear={false} placeholder='Enter TimeZone'
                                                    value={unitdata.time_zone_name || undefined}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            time_zone_id: value,
                                                            time_zone_name: data.children
                                                        })
                                                    }}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                >
                                                    {audittrailfilter && audittrailfilter.tz_list && audittrailfilter.tz_list.map((item, i) => {
                                                        return (
                                                            <Option key={item.tz_id}>
                                                                {item.tz_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <Button disabled={disableButton} type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "17%", marginRight: "10px" }}
                                                    onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#32a852", borderColor: "#32a852" }}
                                                    onClick={onexport}
                                                >
                                                    Export
                                                </Button>
                                                {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>

                            {tableshow == true ?
                                <>

                                    {/* <div className='col-md-12 mt-1 mb-3 ml-2' >
                                            <div className='row'>
                                                <div className='col-md-3 ml-2'>
                                                    <label><b>Legal Entity :</b> {unitdata.legal_entity_name}</label>
                                                </div>
                                                <div className='col-md-3 ml-2'>
                                                    <label><b>From Date :</b> {unitdata.from_date}</label>
                                                </div>
                                                <div className='col-md-3 ml-2'>
                                                    <label><b>To Date :</b> {unitdata.to_date}</label>
                                                </div>
                                                <div className='col-md-3 ml-2'>
                                                    <label><b>Time Zone :</b> {unitdata.time_zone_name}</label>
                                                </div>
                                            </div>
                                        </div> */}
                                    <div className="col-md-12 ml-2 mb-1 mt-1">
                                        <div className="row ml-2">
                                            <div className="col-md-3 ml-2">
                                                <label className="ml-2"><b className="ml-2">Legal Entity :</b> {unitdata.legal_entity_name}</label>

                                            </div>
                                            <div className="col-md-3">
                                                <label className="ml-2"><b className="ml-2">From Date :</b> {unitdata.from_date}</label>

                                            </div>
                                            <div className="col-md-3">
                                                <label className="ml-2"><b className="ml-2">To Date :</b> {unitdata.to_date}</label>

                                            </div>
                                            <div className="col-md-3">
                                                <label><b>Time Zone :</b> {unitdata.time_zone_name}</label>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='ant-table-wrapper'>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content userprivclass1'>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '5px' }}></col>
                                                        </colgroup>
                                                        <thead className="ant-table-thead antable-head">
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: '2%', height: '1%' }}> # </th>
                                                                <th className='ant-table-cell' style={{ width: '20px' }}>User</th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}>Date & Time</th>
                                                                <th className='ant-table-cell' style={{ width: '50px' }}>Form Name</th>
                                                                <th className='ant-table-cell' style={{ width: '250px' }}><center>Action</center></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='ant-table-tbody'>
                                                            {audittrailshow && audittrailshow.audit_activities && audittrailshow.audit_activities.length > 0 ?
                                                                audittrailshow && audittrailshow.audit_activities && audittrailshow.audit_activities.map((itemdata, i) => {
                                                                    let data = _.find(audittrailfilter.audit_forms_list, { form_id: itemdata.form_id })
                                                                    return <Fragment>
                                                                        <tr>
                                                                            <td className='ant-table-cell'>{count + 1}</td>
                                                                            <td className='ant-table-cell'>{itemdata.user_name}</td>
                                                                            <td className='ant-table-cell'><center>{itemdata.created_on}</center></td>
                                                                            <td className='ant-table-cell'>{data && data.form_name ? data.form_name : ''}</td>
                                                                            <td className='ant-table-cell'>{itemdata.action}</td>
                                                                            <span hidden>{count = count + 1}</span>
                                                                        </tr>
                                                                    </Fragment>
                                                                }) :
                                                                <>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>

                                                                </>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {count != 0 ?
                                        <>
                                            <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                                <label style={{ marginLeft: "28px" }}>Showing {pageSize * (current - 1) + 1} to {count} of {Number(totalcount)} entries</label>
                                            </div>
                                            <div


                                                style={{ position: 'absolute', bottom: '-25px', right: 0 }}
                                            >
                                                <Pagination
                                                    current={current}
                                                    showSizeChanger={true}
                                                    // showQuickJumper={true}
                                                    onShowSizeChange={() => {
                                                        setCurrent(1)
                                                        // setpageState(true)
                                                    }}
                                                    pageSizeOptions={[25, 50, 100]} hideOnSinglePage={Number(totalcount) > 25 ? false : true} defaultPageSize={25}
                                                    onChange={(page, pageSizes) => {
                                                        setpageState(true)
                                                        setCurrent(pageSize !== pageSizes ? 1 : page);
                                                        setPageSize(pageSizes)
                                                    }} total={Number(totalcount)}
                                                // showTotal={(total) => `Showing 1 to ${count} of ${Number(totalcount)} entries`}
                                                />


                                            </div></> : ''}
                                </> : ''
                                // <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                            }
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}
export default connect(mapStateToProps, {
    AuditTrailReportFilters,
    AuditTrailReportData,
    ExportAuditTrailReportData
})(AuditTrail);