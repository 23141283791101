import HTTP from '../../../Libs/http';
import { Toaster } from '../../../Libs/Toaster';
import url, { getUrl } from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt';
import { decode as base64_decode } from 'base-64';
import { CRITICALITYREPORT, TOGGLE_COMMON_LOADER, CRITICALITYREPORTTABLE, SHOWMOREDATALIST } from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';
import { toast } from 'react-toastify';
const urls = getUrl()


export const criticalityfilterlist = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_reports`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: CRITICALITYREPORT,
                    payload: decryptData,
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const criticalityshowlist = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_reports`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: CRITICALITYREPORTTABLE,
                    payload: decryptData,
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const criticalityexportlist = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_reports`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                toast.success("Export process initiated, You will receive Messages once file generated.")
                //  dispatch({
                //      type: REVIEWSAVECOMPLIANCE,
                //      payload: decryptData[1],
                //  });
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);

            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const showmoredata = ({ payload, executeCancel, paramid }) => async dispatch => {

    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_reports`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            dispatch({
                type: SHOWMOREDATALIST,
                payload: decryptData,
            });

            // }
            // else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};