import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, {getUrl} from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { ASSIGNEE_WISE_COMPLIANCE, TOGGLE_COMMON_LOADER, ASSIGNEE_WISE_COMPLIANCE_SHOW, ASSIGNEE_WISE_COMPLIANCE_EXPORT, ASSIGNEE_WISE_COMPLIANCE_DRILL_DOWN, ASSIGNEE_YEAR_WISE_COMPLIANCE } from './../../types/index';
import alertmessage from './../../../Libs/Altermessage';
const urls = getUrl()

export const AssigneewiseComplianceFilters = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_master_filters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
                // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: ASSIGNEE_WISE_COMPLIANCE,
                    payload: decryptData[1],
                });
            }
            // }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


export const AssigneeWiseCompliancesChart = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_dashboard`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: ASSIGNEE_WISE_COMPLIANCE_SHOW,
                    payload: decryptData[1],
                });
            }
            // }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const AssigneeWiseComplianceDrillDown = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_dashboard`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: ASSIGNEE_WISE_COMPLIANCE_DRILL_DOWN,
                    payload: decryptData[1],
                });
            }
            // }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const AssigneewiseYearwiseCompliances = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_dashboard`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: ASSIGNEE_YEAR_WISE_COMPLIANCE,
                    payload: decryptData[1],
                });
            }
            // }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const AssigneeWiseCompliancesChartexport = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls+`/api/client_dashboard`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                if (decryptData[1].link == null) {
                    Toaster.error("No record to Export!")
                } else {
                    dispatch({
                        type: ASSIGNEE_WISE_COMPLIANCE_EXPORT,
                        payload: decryptData[1],
                    });
                }
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};