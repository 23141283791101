import { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator';
import {PlayCircleOutlined} from '@ant-design/icons';

const mapStateToProps = () => ({});
const Serviceprovider = () => {
    const [serviceProviderDetails, setServiceProviderDetails] = useState({
        provider_name: '',
        contact_no: '',
        short_name: '',
        mobile_no: '',
        Contract_from: '',
        Contract_to: '',
        contact_person: '',
        email: '',
        address: '',
    })
    const formValidator = useRef(new SimpleReactValidator());

    const serviceProviderOnSubmit = (e) => {
        e.preventDefault();
        if (formValidator.current.allValid()) {
            console.log(serviceProviderDetails);
        }
    }

    const serviceProviderOnChange = (e) => {
        setServiceProviderDetails({
            ...serviceProviderDetails,
            [e.target.name]: e.target.value
        })
    }
    const Cleardata = (() => {
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: '',
            contact_no: '',
            short_name: '',
            mobile_no: '',
            Contract_from: '',
            Contract_to: '',
            contact_person: '',
            email: '',
            address: '',
        })
    })
    useEffect(() => {
        formValidator.current.showMessages()
    }, [])

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="page-titles pb-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <a href="index.html" className="link"><i className="ri-home-3-line fs-5"></i></a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Service Provider
                                    </li>
                                </ol>
                            </nav>
                            <h3 className="mb-0 fw-bold">Service Provider</h3>
                        </div>
                        <div className="
                col-lg-4 col-md-6
                d-none d-md-flex
                align-items-center
                justify-content-end
              "
                        >

                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <form>
                                    <div>
                                        <div className="card-body">
                                            <div className="row pt-3">
                                                <div className="col-md-6">
                                                    <div className="mb-2">
                                                        <label className="control-label">Service Provider</label>
                                                        <span style={{ "color": "red" }}>*</span>
                                                        <input
                                                            type="text"
                                                            id="provider_name"
                                                            name='provider_name'
                                                            className="form-control"
                                                            placeholder="Enter Service Provider Name"
                                                            onChange={serviceProviderOnChange}
                                                            value={serviceProviderDetails.provider_name}
                                                            maxlength='50'
                                                        />
                                                        {formValidator.current.message(
                                                            'provider_name',
                                                            serviceProviderDetails.provider_name,
                                                            [
                                                                'required',
                                                                {
                                                                    regex: /^[A-Za-z ]/,
                                                                },
                                                                `max:50`
                                                            ],
                                                            {
                                                                className: `invalid-feedback ${formValidator ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Service Provider Name Required',
                                                                    regex: 'Enter Valid Name',
                                                                    max: 'Maximum character is 50 only'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-2">
                                                        <label className="control-label">Contact Number</label>
                                                        <input
                                                            type="text"
                                                            id="contact_no"
                                                            name='contact_no'
                                                            className="form-control"
                                                            placeholder="Enter Contact Number"
                                                            onChange={serviceProviderOnChange}
                                                            value={serviceProviderDetails.contact_no}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-3">
                                                <div className="col-md-6">
                                                    <div className="mb-2">
                                                        <label className="control-label">Short Name</label>
                                                        <span style={{ "color": "red" }}>*</span>
                                                        <input
                                                            type="text"
                                                            name='short_name'
                                                            id="short_name"
                                                            className="form-control"
                                                            placeholder="Enter Short Name"
                                                            onChange={serviceProviderOnChange}
                                                            value={serviceProviderDetails.short_name}
                                                            maxlength='25'
                                                        />
                                                        {formValidator.current.message(
                                                            'short_name',
                                                            serviceProviderDetails.short_name,
                                                            [
                                                                'required',
                                                                {
                                                                    regex: /^[A-Za-z ]/,
                                                                },
                    
                                                                `max:20`
                                                            ],
                                                            {
                                                                className: `invalid-feedback ${formValidator ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Short Name Required',
                                                                    regex: 'Enter Valid Name',
                                                                    max: 'Maximum character is 20 only'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-2">
                                                        <label className="control-label">Mobile Number</label>
                                                        <input
                                                            type="text"
                                                            id="mobile_no"
                                                            name='mobile_no'
                                                            className="form-control"
                                                            placeholder="Mobile Number"
                                                            onChange={serviceProviderOnChange}
                                                            value={serviceProviderDetails.mobile_no}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-3">
                                                <div className="col-md-6">
                                                    <div className="mb-2">
                                                        <label className="control-label">Contract From</label>
                                                        <span style={{ "color": "red" }}>*</span>
                                                        <input
                                                            type="date"
                                                            id="Contract_from"
                                                            name='Contract_from'
                                                            className="form-control"
                                                            placeholder="Enter Email address"
                                                            onChange={serviceProviderOnChange}
                                                            value={serviceProviderDetails.Contract_from}
                                                        />
                                                        {formValidator.current.message(
                                                            'Contract_from',
                                                            serviceProviderDetails.Contract_from,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${formValidator ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    regex: 'Enter valid email'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-2">
                                                        <label className="control-label">Email Address</label>
                                                        <span style={{ "color": "red" }}>*</span>
                                                        <input
                                                            type="text"
                                                            id="email"
                                                            name='email'
                                                            className="form-control"
                                                            placeholder="Enter Email Address"
                                                            onChange={serviceProviderOnChange}
                                                            value={serviceProviderDetails.email}
                                                        />
                                                        {formValidator.current.message(
                                                            'email',
                                                            serviceProviderDetails.email,
                                                            ['required', { regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ }],
                                                            // ['required',{ regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ }],
                                                            {
                                                                className: `invalid-feedback ${formValidator ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    regex: 'Enter valid email'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-3">
                                                <div className="col-md-6">
                                                    <div className="mb-2">
                                                        <label className="control-label">Contract To</label>
                                                        <span style={{ "color": "red" }}>*</span>
                                                        <input
                                                            type="date"
                                                            id="Contract_to"
                                                            name='Contract_to'
                                                            className="form-control"
                                                            placeholder="User ID"
                                                            onChange={serviceProviderOnChange}
                                                            value={serviceProviderDetails.Contract_to}
                                                        />
                                                        {formValidator.current.message(
                                                            'Contract_to',
                                                            serviceProviderDetails.Contract_to,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${formValidator ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    regex: 'Enter valid email'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-4">
                                                        <label className="control-label">Contact Person</label>
                                                        <input
                                                            type="text"
                                                            id="contact_person"
                                                            name='contact_person'
                                                            className="form-control"
                                                            placeholder="Enter Contact Person"
                                                            onChange={serviceProviderOnChange}
                                                            value={serviceProviderDetails.contact_person}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label>Address</label>
                                                        <textarea className="form-control" rows="4" id="address"
                                                            name='address' onChange={serviceProviderOnChange}
                                                            value={serviceProviderDetails.address}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-actions">
                                            <div className="card-body border-top">
                                                <button
                                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                                    onClick={serviceProviderOnSubmit}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <i
                                                            data-feather="save"
                                                            className="feather-sm me-1 fill-icon"
                                                        ></i>
                                                        Save
                                                    </div>
                                                </button>
                                                <Link to='/serviceproviderlist'
                                                    className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger" onClick={Cleardata}>Cancel</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
})(Serviceprovider);